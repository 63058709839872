import React, { useState } from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { obtenerUsuarioBD, validarDatos } from './functions/FuncLogin'
import { iniciarVariablesSesion } from './functions/FuncGenerales'
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import LoadingLogin from './LoadingLogin';
import { Label } from '@material-ui/icons';

const Nav = styled.div`
  background: #696158;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Login = ({t}) => {

    const { instance, accounts, inProgress } = useMsal();
    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    async function validarUsuario(i, a)
    {
        setLoading(true);
        await validarDatos(i)
        .then( async (response) => {
            if(response) await obtenerUsuarioBD(a, t).then((response) => {
                if (response.cod === 1)
                {
                    iniciarVariablesSesion(response.usuario);
                    history.push("/menu");
                }
                else swal("Error de Login", response.mensaje, "error");
               
            });            
            else
            {                
                sessionStorage.clear();

                swal({
                    title: "Error de ingreso",
                    text: "La cuenta utilizada no cuenta con los privilegios suficientes para acceder a la plataforma.",
                    icon: "warning",                    
                    dangerMode: true,
                    button: {
                        text: "Aceptar",
                        closeModal: true,
                    }
                }).then(() => {
                    instance.logout()
                })
            } 
        })
        .catch((error) => {
        });
    }

    let email = sessionStorage.getItem('email');

    React.useEffect(() => {

        if(email !== null)
        {            
            history.push("/menu");
        } 

        if(accounts.length > 0 && !loading)
        {
            validarUsuario(instance, accounts[0]);
        }
    });

    return (
        <>
        {(!loading) ? (
            <>
            <Nav>
                <div className='nav-imagen'>
                    <div className='divimagenarauco'>
                        <img className="imagen-arauco" src="arauco.svg" alt="Arauco" /> 
                    </div>
                    <div className='divtitulobanerpalabra'>
                        <label className='titulobanner-palabra'> IT Asset Management</label>
                    </div>             
                </div>                
            </Nav>

            <section className="sectionLogin">
                <div>
                    <div className="login">
                        <header><h1><img src="arauco.svg" alt="Arauco" /></h1></header>				
                        <div className="form-wrapper">
                            <div className="form-group">                            
                                <button style={{ color: '#5E5E5E' }} onClick={() => instance.loginPopup()} className="botonMS">
                                    <div className="divlogin" >
                                        <div className="row">
                                            <div className="col-25">
                                                <img src="loginms.svg" alt="loginms" />  
                                            </div>
                                            <div className="col-75">
                                                <label className="titlelogin">Log In</label>
                                            </div>
                                        </div>
                                    </div>
                                </button>                                                       
                            </div>                                      
                        </div>
                    </div>
                </div>
            </section>

            </>
        ) : (
            <>
                <Nav>
                    <div className='nav-imagen'>
                        <div className='divimagenarauco'>
                            <img className="imagen-arauco" src="arauco.svg" alt="Arauco" /> 
                        </div>
                        <div className='divtitulobanerpalabra'>
                            <label className='titulobanner-palabra'> IT Asset Management</label>
                        </div> 
                    </div>                
                </Nav>
                <br></br>
                <br></br>
                <section className="sectionLogin">
                    <LoadingLogin />
                </section>
            </>
        )}
        </>
    )
}

export default Login
