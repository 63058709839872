import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import Sidebar from '../Sidebar'
import * as TiIcons from 'react-icons/ti';
import * as AiIcons from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import FormularioEquipoEscritorio from './FormularioEquipoEscritorio';
import swal from 'sweetalert';
import { eliminarEquipoRequest } from '../functions/FuncEquipoEscritorio'
import { eliminarBitacorasRegistroRequest } from '../functions/FuncBitacora'
import ModalReemplazo from './ModalReemplazo';
import Loading from '../Loading';
import ModalActualizarPlanta from './ModalActualizarPlanta';
import ModalDocumentos from '../ModalDocumentos';
import ModalArchivoEntrega from './ModalArchivoEntrega';
import ModalImpresion from './ModalImpresion';
import ModalUsuarios from './ModalUsuarios/ModalUsuarios';
import ModalClonacion from './ModalClonacion/ModalClonacion';
import Bitacora from '../Bitacora/Bitacora';

import { ContainerForm, ButtonVolver, ButtonEliminacion } from '../Commons/Styles'

const FormEquipoEscritorio = ({instance, t}) => {

    let { id } = useParams();
    const history = useHistory();
    const [redireccionando, setRedireccionando] = useState(false);
    const [modificacion, setmodificacion] = useState(false);
    const [docEntrega, setDocEntrega] = useState(false);
    const [reemplazo, setReemplazo] = useState(false);

    let priv = sessionStorage.getItem('esc_leer');
    let privEliminacion = sessionStorage.getItem('esc_eliminar');

    function cambiarDocEntrega(valor)
    {
        setDocEntrega(valor)
    }

    function validarPermiso(value)
    {
        setmodificacion(value);
    }

    function volver()
    {
        history.push("/equipos");
    }

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    async function redireccion(id)
    {       
        history.push("/redireccion/" + id);
    }

    async function eliminar()
    {
        await eliminarEquipoRequest(id, t).then((response) => {
            if(response.cod === 1)
            {
                eliminarBitacorasRegistroRequest(id, "EquipoEscritorio", t);
                swal("Delete success", "", "success").then(() => {history.push("/equipos")});
            }
            else {
                swal("Delete Error", response.mensaje, "error");
            }
        });
    }

    function eliminarRegistro()
    {
        swal({
            title: "Delete Confirmation",
            text: "You want delete this server?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((afirmacion) => {
            if(afirmacion)
            {
                eliminar();       
            }
        })
    }

    const validarEstado = (valor) => {
        setReemplazo(valor);
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });
    
    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else if(redireccionando) return (<Loading />)
    else return (
        <>        
            <Sidebar instance={instance} despliegue={false}/>
            <ContainerForm>
                <div className="headerformulario">
                    <div className="headerform-izq-esc">
                        <ButtonVolver onClick={volver} className="tooltipboton">
                        <TiIcons.TiArrowBack/>             
                        <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>
                    </div>
                    <div className="headerform-mid-esc">
                        <div className="divtituloform">
                            <label className="tituloformulario">Desktop Form</label>
                        </div>                        
                    </div>
                    <div className="headerform-der-esc">
                        
                       
                            {
                                (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>)
                                : (
                                    <ButtonEliminacion onClick={eliminarRegistro} className="tooltipboton">
                                        <AiIcons.AiFillDelete/>
                                        <span className="tooltiptextboton">{"Delete"}</span> 
                                    </ButtonEliminacion>)
                            }                        
                        
                            {
                                (!modificacion || !reemplazo) ? (<></>)
                                : (<ModalReemplazo token={t} redireccion={redireccion} id={id}/>)
                            }                        
                        
                            {
                                (!modificacion) ? (<></>)
                                : (<ModalActualizarPlanta token={t} redireccion={redireccion} id={id}/>)
                            }                        
                       
                            <ModalDocumentos tipo={"Equipo"} id={id} token={t}/>
                            
                            <ModalArchivoEntrega tipo={"Equipo"} id={id} cambiarDocEntrega={cambiarDocEntrega} token={t}/>

                            <ModalImpresion id={id} token={t}/>

                            <ModalUsuarios token={t}/>  

                            <Bitacora tipo={"EquipoEscritorio"} id={id} token={t} />

                            {
                                (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>)
                                : (<ModalClonacion token={t} id_registro={id} redireccion={redireccion}/>)
                            }   
                        
                    </div>
                </div>
                <FormularioEquipoEscritorio 
                    token={t} 
                    validarPermiso={validarPermiso} 
                    cambiarDocEntrega={cambiarDocEntrega} 
                    docEntrega={docEntrega} 
                    validarEstado={validarEstado}
                    id={id}
                />
                
            </ContainerForm>            
        </>    
    )
}

export default FormEquipoEscritorio
