import * as Rutas from '../RutasServicios'

async function filtros(token)
{ 
    const url = Rutas.URL_EQUIPOS + "filtros"; 
    //const url = "https://localhost:44347/api/equipo/filtros";    
    
    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function valores(nivel, pais, localidad, modulo, idioma, token)
{ 
    const url = Rutas.URL_EQUIPOS + "valores";    
    //const url = "https://localhost:44347/api/equipo/valores"; 

    console.log(url)

    const request= {        
        "nivel": nivel,        
        "pais": pais,
        "localidad": localidad,
        "modulo": modulo,
        "idioma": idioma 
    };

    console.log(url)
    console.log(request)


    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function valoresModal(nivel, pais, localidad, token)
{ 
    const url = Rutas.URL_EQUIPOS + "valores";    
    //const url = "https://localhost:44347/api/equipo/valores"; 

    const request= {        
        "nivel": nivel,        
        "pais": pais,
        "localidad": localidad   
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function obtenerUsuario(usuario, idusuario, token)
{ 
    const url = Rutas.URL_EQUIPOS + "usuario";    
    //const url = "https://localhost:44347/api/equipo/usuario"; 

    const request= {        
        "usuario": usuario,        
        "idusuario": idusuario      
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function obtenerUsuarios(usuario, correo, token)
{ 
    const url = Rutas.URL_EQUIPOS + "usuarios";    
    //const url = "https://localhost:44347/api/equipo/usuarios"; 

    const request= {        
        "usuario": usuario,        
        "correo": correo      
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function registrosTabla(estado, condicion, pais, localidad, planta, tipo, modelo, numeroserie, nivel, paisuser, localidaduser,token)
{ 
    const url = Rutas.URL_EQUIPOS + "tabla";    
    //const url = "https://localhost:44347/api/equipo/tabla"; 

    const request= {        
        "estado": estado,
        "condicion": condicion,
        "pais": pais,
        "localidad": localidad,
        "planta": planta,
        "tipo": tipo,
        "modelo": modelo,
        "numeroserie": numeroserie,
        "nivel": nivel,
        "paisuser": paisuser,
        "localidaduser": localidaduser      
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

async function registrosTablaSccm(numeroserie, token)
{ 
    const url = Rutas.URL_EQUIPOS + "tablasccm";    
    //const url = "https://localhost:44347/api/equipo/tablasccm"; 

    const request= {      
        "numeroserie": numeroserie
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

async function crear(data, token){

    const url = Rutas.URL_EQUIPOS + "crear";    
    //const url = "https://localhost:44347/api/equipo/crear"; 

    const create = { 
        "numeroserie": data.numeroserie.toString().toUpperCase(),
        "estado": data.estado.value.toString(),
        "condicion": data.condicion.value.toString(),
        "empresa": data.empresa.value.toString(),
        "planta": data.planta.value.toString(),
        "nombreequipo": data.nombreequipo.toString().toUpperCase(),
        "tipoequipo": data.tipoequipo.value.toString().toUpperCase(),
        "marca": data.marca.value.toString().toUpperCase(),
        "modelo": data.modelo.value.toString().toUpperCase(),
        "condicionred": data.condicionred.value.toString().toUpperCase(),
        "excluidoparches": (data.excluidoparches) ? "1" : "0",
        "usbdesbloqueado": (data.usbdesbloqueado) ? "1" : "0",
    };

    //USUARIO
    create.idusuario = (data.idusuario !== "" && data.idusuario !== null && data.idusuario !== undefined) ? data.idusuario.toString().toUpperCase() : null;
    create.nombreusuario = (data.nombreusuario !== "" && data.nombreusuario !== null && data.nombreusuario !== undefined) ? data.nombreusuario.toString().toUpperCase() : null;

    create.correo = (data.correo !== "" && data.correo !== null && data.correo !== undefined) ? data.correo.toString().toUpperCase() : null;
    create.licenciaprincipal = (data.licenciaprincipal !== "" && data.licenciaprincipal !== null && data.licenciaprincipal !== undefined) ? data.licenciaprincipal.toString().toUpperCase() : null;
    create.division = (data.division !== "" && data.division !== null && data.division !== undefined) ? data.division.value.toString().toUpperCase() : null;
    create.divisionplanta = (data.divisionplanta !== "" && data.divisionplanta !== null && data.divisionplanta !== undefined) ? data.divisionplanta.toString().toUpperCase() : null;
    create.divisionfecha = (data.divisionfecha !== "" && data.divisionfecha !== null && data.divisionfecha !== undefined) ? data.divisionfecha.toString().toUpperCase() : null;
    create.gerenciaprincipal = (data.gerenciaprincipal !== "" && data.gerenciaprincipal !== null && data.gerenciaprincipal !== undefined) ? data.gerenciaprincipal.value.toString().toUpperCase() : null;
    create.cargo = (data.cargo !== "" && data.cargo !== null && data.cargo !== undefined) ? data.cargo.value.toString().toUpperCase() : null;
    create.cargorol = (data.cargorol !== "" && data.cargorol !== null && data.cargorol !== undefined) ? data.cargorol.toString().toUpperCase() : null;
    create.centrocosto = (data.centrocosto !== "" && data.centrocosto !== null && data.centrocosto !== undefined) ? data.centrocosto.value.toString().toUpperCase() : null;
    create.gerenciadirecta = (data.gerenciadirecta !== "" && data.gerenciadirecta !== null && data.gerenciadirecta !== undefined) ? data.gerenciadirecta.value.toString().toUpperCase() : null;
    create.subgerenciaarea = (data.subgerenciaarea !== "" && data.subgerenciaarea !== null && data.subgerenciaarea !== undefined) ? data.subgerenciaarea.value.toString().toUpperCase() : null;
    create.vip = (data.vip) ? "1" : "0";
    create.administrador = (data.administrador) ? "1" : "0";    
    create.usuariomaestropersonal = (data.usuariomaestropersonal) ? "1" : "0";    
    create.justificacionadmin = (data.justificacionadmin !== "" && data.justificacionadmin !== null && data.justificacionadmin !== undefined) ? data.justificacionadmin.toString().toUpperCase() : null;
    
    
    //EQUIPO
    create.motivoexcluidoparches = (data.motivoexcluidoparches !== "" && data.motivoexcluidoparches !== null) ? data.motivoexcluidoparches.toString().toUpperCase() : null;
    create.motivousbdesbloqueado = (data.motivousbdesbloqueado !== "" && data.motivousbdesbloqueado !== null) ? data.motivousbdesbloqueado.toString().toUpperCase() : null;
    create.ordencompra = (data.ordencompra !== "" && data.ordencompra !== null && data.ordencompra !== undefined) ? data.ordencompra.value.toString().toUpperCase() : null;
    create.direccionip = (data.direccionip !== "" && data.direccionip !== null && data.direccionip !== undefined) ? data.direccionip.toString().toUpperCase() : null;
    create.discoduro = (data.discoduro !== "" && data.discoduro !== null && data.discoduro !== undefined) ? data.discoduro.value.toString().toUpperCase() : null;
    create.memoriaram = (data.memoriaram !== "" && data.memoriaram !== null && data.memoriaram !== undefined) ? data.memoriaram.value.toString().toUpperCase() : null;
    create.tipoprocesador = (data.tipoprocesador !== "" && data.tipoprocesador !== null && data.tipoprocesador !== undefined) ? data.tipoprocesador.value.toString().toUpperCase() : null;
    create.velocidadprocesador = (data.velocidadprocesador !== "" && data.velocidadprocesador !== null && data.velocidadprocesador !== undefined) ? data.velocidadprocesador.value.toString().toUpperCase() : null;
    create.docking = (data.docking) ? "1" : "0";
    create.monitor = (data.monitor) ? "1" : "0";    
    create.fecharecepcion = (data.fecharecepcion !== "" && data.fecharecepcion !== null && data.fecharecepcion !== undefined) ? new Date(data.fecharecepcion).toISOString().substring(0, 10) : null;
    create.fechaentrega = (data.fechaentrega !== "" && data.fechaentrega !== null && data.fechaentrega !== undefined) ? new Date(data.fechaentrega).toISOString().substring(0, 10) : null;
    create.fechaexpiracion = (data.fechaexpiracion !== "" && data.fechaexpiracion !== null && data.fechaexpiracion !== undefined) ? new Date(data.fechaexpiracion).toISOString().substring(0, 10) : null;
    create.fechamantencion = (data.fechamantencion !== "" && data.fechamantencion !== null && data.fechamantencion !== undefined) ? new Date(data.fechamantencion).toISOString().substring(0, 10) : null;
    create.fechafueralinea = (data.fechafueralinea !== "" && data.fechafueralinea !== null && data.fechafueralinea !== undefined) ? new Date(data.fechafueralinea).toISOString().substring(0, 10) : null;
    create.ubicacion = (data.ubicacion !== "" && data.ubicacion !== null && data.ubicacion !== undefined) ? data.ubicacion.toString().toUpperCase() : null;
    create.observaciones = (data.observaciones !== "" && data.observaciones !== null && data.observaciones !== undefined) ? data.observaciones.toString().toUpperCase() : null;
    

    create.modificadopor = (data.modificadopor !== "" && data.modificadopor !== null) ? data.modificadopor.toString().toUpperCase() : null;
    create.fechaupdate = (data.fechaupdate !== "" && data.fechaupdate !== null) ? new Date(data.fechaupdate).toISOString().substring(0, 10) : null;
    

    var bRequest = JSON.stringify(create);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido !== null){        
        res = convertido;
    }

    return res;     
}

async function actualizar(data, dataAnterior, id, token){

    const url = Rutas.URL_EQUIPOS + "actualizar";    
    //const url = "https://localhost:44347/api/equipo/actualizar"; 

    const request = { 
        "id_equipoescritorio": id,
        "numeroserie": data.numeroserie.toString().toUpperCase(),
        "docking": (data.docking !== dataAnterior.docking) ? (data.docking) ? "1" : "0" : null,
        "monitor": (data.monitor !== dataAnterior.monitor) ? (data.monitor) ? "1" : "0" : null,
        "vip": (data.vip !== dataAnterior.vip) ? (data.vip) ? "1" : "0" : null,
        "administrador": (data.administrador !== dataAnterior.administrador) ? (data.administrador) ? "1" : "0" : null, 
        "usuariomaestropersonal": (data.usuariomaestropersonal !== dataAnterior.usuariomaestropersonal) ? (data.usuariomaestropersonal) ? "1" : "0" : null,    
        "excluidoparches": (data.excluidoparches !== dataAnterior.excluidoparches) ? (data.excluidoparches) ? "1" : "0" : null,
        "usbdesbloqueado": (data.usbdesbloqueado !== dataAnterior.usbdesbloqueado) ? (data.usbdesbloqueado) ? "1" : "0" : null,
    };

    request.modificadopor = data.modificadopor;
    request.fechaupdate = new Date(data.fechaupdate).toISOString().substring(0, 10);

    if(data.estado !== undefined) request.estado = (data.estado !== dataAnterior.estado) ? data.estado.value.toString().toUpperCase() : "";
    if(data.condicion !== undefined) request.condicion = (data.condicion !== dataAnterior.condicion) ? data.condicion.value.toString().toUpperCase() : "";
    if(data.empresa !== undefined) request.empresa = (data.empresa !== dataAnterior.empresa) ? data.empresa.value.toString().toUpperCase() : "";
    if(data.planta !== undefined) request.planta = (data.planta !== dataAnterior.planta) ? data.planta.value.toString().toUpperCase() : "";
    if(data.nombreequipo !== undefined) request.nombreequipo = (data.nombreequipo !== dataAnterior.nombreequipo) ? data.nombreequipo.toString().toUpperCase() : "";
    if(data.tipoequipo !== undefined) request.tipoequipo = (data.tipoequipo !== dataAnterior.tipoequipo) ? data.tipoequipo.value.toString().toUpperCase() : "";
    if(data.marca !== undefined) request.marca = (data.marca !== dataAnterior.marca) ? data.marca.value.toString().toUpperCase() : "";
    if(data.modelo !== undefined) request.modelo = (data.modelo !== dataAnterior.modelo) ? data.modelo.value.toString().toUpperCase() : "";
    if(data.condicionred !== undefined) request.condicionred = (data.condicionred !== dataAnterior.condicionred) ? data.condicionred.value.toString().toUpperCase() : "";
   
    if(data.motivoexcluidoparches !== undefined) request.motivoexcluidoparches = (data.motivoexcluidoparches !== dataAnterior.motivoexcluidoparches) ? (data.motivoexcluidoparches !== "" && data.motivoexcluidoparches !== null) ? data.motivoexcluidoparches.toString().toUpperCase() : "ESNULL" : "";
    if(data.motivousbdesbloqueado !== undefined) request.motivousbdesbloqueado = (data.motivousbdesbloqueado !== dataAnterior.motivousbdesbloqueado) ? (data.motivousbdesbloqueado !== "" && data.motivousbdesbloqueado !== null) ? data.motivousbdesbloqueado.toString().toUpperCase() : "ESNULL" : "";
    if(data.ordencompra !== undefined) request.ordencompra = (data.ordencompra !== dataAnterior.ordencompra) ? (data.ordencompra !== "" && data.ordencompra !== null) ? data.ordencompra.value.toString().toUpperCase() : "ESNULL" : "";
    request.direccionip = (data.direccionip !== dataAnterior.direccionip) ? (data.direccionip !== "") ? data.direccionip.toString().toUpperCase() : "ESNULL" : "";
    if(data.discoduro !== undefined) request.discoduro = (data.discoduro !== dataAnterior.discoduro) ? (data.discoduro !== "" && data.discoduro !== null) ? data.discoduro.value.toString().toUpperCase() : "ESNULL" : "";
    if(data.memoriaram !== undefined) request.memoriaram = (data.memoriaram !== dataAnterior.memoriaram) ? (data.memoriaram !== ""  && data.memoriaram !== null) ? data.memoriaram.value.toString().toUpperCase() : "ESNULL" : "";
    if(data.tipoprocesador !== undefined) request.tipoprocesador = (data.tipoprocesador !== dataAnterior.tipoprocesador) ? (data.tipoprocesador !== ""  && data.tipoprocesador !== null) ? data.tipoprocesador.value.toString().toUpperCase() : "ESNULL" : "";
    if(data.velocidadprocesador !== undefined) request.velocidadprocesador = (data.velocidadprocesador !== dataAnterior.velocidadprocesador) ? (data.velocidadprocesador !== ""  && data.velocidadprocesador !== null) ? data.velocidadprocesador.value.toString().toUpperCase() : "ESNULL" : "";
    request.ubicacion = (data.ubicacion !== dataAnterior.ubicacion) ? (data.ubicacion !== "") ? data.ubicacion.toString().toUpperCase() : "ESNULL" : "";
    request.observaciones = (data.observaciones !== dataAnterior.observaciones) ? (data.observaciones !== "") ? data.observaciones.toString().toUpperCase() : "ESNULL" : "";
    request.fecharecepcion = (data.fecharecepcion !== dataAnterior.fecharecepcion) ? (data.fecharecepcion !== null && dataAnterior.fecharecepcion !== null) ? new Date(data.fecharecepcion).toISOString().substring(0, 10): "ESNULL" : "";
    request.fechaentrega = (data.fechaentrega !== dataAnterior.fechaentrega) ? (data.fechaentrega !== null && dataAnterior.fechaentrega !== null) ? new Date(data.fechaentrega).toISOString().substring(0, 10): "ESNULL" : "";
    request.fechaexpiracion = (data.fechaexpiracion !== dataAnterior.fechaexpiracion) ? (data.fechaexpiracion !== null && dataAnterior.fechaexpiracion !== null) ? new Date(data.fechaexpiracion).toISOString().substring(0, 10): "ESNULL" : "";
    request.fechafueralinea = (data.fechafueralinea !== dataAnterior.fechafueralinea) ? (data.fechafueralinea !== null && dataAnterior.fechafueralinea !== null) ? new Date(data.fechafueralinea).toISOString().substring(0, 10): "ESNULL" : "";
    request.fechamantencion = (data.fechamantencion !== dataAnterior.fechamantencion) ? (data.fechamantencion !== null && dataAnterior.fechamantencion !== null) ? new Date(data.fechamantencion).toISOString().substring(0, 10): "ESNULL" : "";

    if(data.idusuario !== undefined) request.idusuario = (data.idusuario !== dataAnterior.idusuario) ? data.idusuario.toString().toUpperCase() : "";
    if(data.nombreusuario !== undefined) request.nombreusuario = (data.nombreusuario !== dataAnterior.nombreusuario) ? data.nombreusuario.toString().toUpperCase() : "";
    request.correo = (data.correo !== dataAnterior.correo) ? (data.correo !== "") ? data.correo.toString().toUpperCase() : "ESNULL" : "";
    request.licenciaprincipal = (data.licenciaprincipal !== dataAnterior.licenciaprincipal) ? (data.licenciaprincipal !== "") ? data.licenciaprincipal.toString().toUpperCase() : "ESNULL" : "";
    if(data.division !== undefined) request.division = (data.division !== dataAnterior.division) ? (data.division !== "" && data.division !== null) ? data.division.value.toString().toUpperCase() : "ESNULL" : "";
    request.divisionplanta = (data.divisionplanta !== dataAnterior.divisionplanta) ? (data.divisionplanta !== "") ? data.divisionplanta.toString().toUpperCase() : "ESNULL" : "";
    request.divisionfecha = (data.divisionfecha !== dataAnterior.divisionfecha) ? (data.divisionfecha !== "") ? data.divisionfecha.toString().toUpperCase() : "ESNULL" : "";
    if(data.cargo !== undefined) request.cargo = (data.cargo !== dataAnterior.cargo) ? (data.cargo !== "" && data.cargo !== null) ? data.cargo.value.toString().toUpperCase() : "ESNULL" : "";
    request.cargorol = (data.cargorol !== dataAnterior.cargorol) ? (data.cargorol !== "") ? data.cargorol.toString().toUpperCase() : "ESNULL" : "";
    if(data.centrocosto !== undefined) request.centrocosto = (data.centrocosto !== dataAnterior.centrocosto) ? (data.centrocosto !== "" && data.centrocosto !== null) ? data.centrocosto.value.toString().toUpperCase() : "ESNULL" : "";
    if(data.gerenciaprincipal !== undefined) request.gerenciaprincipal = (data.gerenciaprincipal !== dataAnterior.gerenciaprincipal) ? (data.gerenciaprincipal !== "" && data.gerenciaprincipal !== null) ? data.gerenciaprincipal.value.toString().toUpperCase() : "ESNULL" : "";
    if(data.gerenciadirecta !== undefined) request.gerenciadirecta = (data.gerenciadirecta !== dataAnterior.gerenciadirecta) ? (data.gerenciadirecta !== "" && data.gerenciadirecta !== null) ? data.gerenciadirecta.value.toString().toUpperCase() : "ESNULL" : "";
    if(data.subgerenciaarea !== undefined) request.subgerenciaarea = (data.subgerenciaarea !== dataAnterior.subgerenciaarea) ? (data.subgerenciaarea !== "" && data.subgerenciaarea !== null) ? data.subgerenciaarea.value.toString().toUpperCase() : "ESNULL" : "";    
    request.justificacionadmin = (data.justificacionadmin !== dataAnterior.justificacionadmin) ? (data.justificacionadmin !== "") ? data.justificacionadmin.toString().toUpperCase() : "ESNULL" : "";



    var bRequest = JSON.stringify(request);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido !== null){        
        res = convertido;
    }

    return res;     
}

async function obtenerEquipo(id, token)
{ 
    const url = Rutas.URL_EQUIPOS + "equipo";    
    //const url = "https://localhost:44347/api/equipo/equipo"; 

    const request= {
        "id": id           
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function subirArchivoExcel(file64, nombre, usuario, container, tipo, token)
{ 
    const url = Rutas.URL_EQUIPOS + "subirexcel";
    //const url = "https://localhost:44347/api/equipo/subirexcel";

    const data = {
        "title": nombre,
        "file": file64,
        "desc": "archivo de carga excel",
        "usuario": usuario,
        "tipo": tipo,
        "container": container
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function obtenerCargasSccm(token)
{ 
    const url = Rutas.URL_EQUIPOS + "listacargassccm"; 
    //const url = "https://localhost:44347/api/equipo/listacargassccm"; 
        
    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3}; 
}

async function obtenerCargasExcel(usuario, token)
{ 
    const url = Rutas.URL_EQUIPOS + "cargas";    
    //const url = "https://localhost:44347/api/equipo/cargas";  

    const data = {        
        "usuario": usuario,
        "tipo": "equipoescritorio"
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function obtenerCargasExcelSccm(usuario, token)
{ 
    const url = Rutas.URL_EQUIPOS + "cargas";    
    //const url = "https://localhost:44347/api/equipo/cargas";  

    const data = {        
        "usuario": usuario,
        "tipo": "equipoescritoriosccm"
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function aplicaciones(equipo, token)
{ 
    const url = Rutas.URL_EQUIPOS + "aplicaciones";    
    //const url = "https://localhost:44347/api/equipo/aplicaciones";  

    const data = {        
        "equipo": equipo
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function aplicacionesBO(equipo, token)
{
    const url = Rutas.URL_EQUIPOS + "aplicacionesback";
    //const url = "https://localhost:44347/api/equipo/aplicacionesback";

    const data = {
        "equipo": equipo
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){
        res = convertido;
    }

    return res;
}

async function aplicacionesSccm(equipo, token)
{ 
    const url = Rutas.URL_EQUIPOS + "aplicacionessccm";    
    //const url = "https://localhost:44347/api/equipo/aplicacionessccm";  

    const data = {        
        "equipo": equipo
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function eliminarEquipo(id, token)
{ 
    const url = Rutas.URL_EQUIPOS + "eliminar";    
    //const url = "https://localhost:44347/api/equipo/eliminar";  
    
    const data = {        
        "id": id
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function validarEquipo(equipo, privilegio, pais, localidad, token)
{ 
    const url = Rutas.URL_EQUIPOS + "validarequipo";    
    //const url = "https://localhost:44347/api/equipo/validarequipo";  
    
    const data = {        
        "equipo": equipo.toString().toUpperCase(),
        "privilegio": privilegio,
        "pais": pais,
        "localidad": localidad
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function reemplazarEquipo(id_reemplazado, numeroserial, nombreequipo, condicion_antiguo, modificadopor, fechaupdate, token)
{ 
    const url = Rutas.URL_EQUIPOS + "reemplazarequipo";    
    //const url = "https://localhost:44347/api/equipo/reemplazarequipo";  
    
    const data = {        
        "id_reemplazado": id_reemplazado,
        "numeroserial": numeroserial.toString().toUpperCase(),
        "nombreequipo": nombreequipo.toString().toUpperCase(),
        "condicion_antiguo": condicion_antiguo.value.toString().toUpperCase(),
        "condicion_nuevo": "OPERATIVE",
        "modificadopor": modificadopor,
        "fechaupdate": new Date(fechaupdate).toISOString().substring(0, 10)
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function actualizarLocalidadEquipo(id_equipoescritorio, planta, modificadopor, fechaupdate, correo, usuario1, usuario2, token)
{ 
    const url = Rutas.URL_EQUIPOS + "actualizarlocalidad";    
    //const url = "https://localhost:44347/api/equipo/actualizarlocalidad";  
    
    const equipo = {        
        "id_equipoescritorio": id_equipoescritorio,
        "planta": planta.value.toString().toUpperCase(),               
        "modificadopor": modificadopor,
        "fechaupdate": new Date(fechaupdate).toISOString().substring(0, 10)
    }

    const data = {
        "equipo": equipo,
        "destinatario_accion": correo,
        "destinatario_principal": usuario1,
        "destinatario_secundario": usuario2,
    }

    console.log(data);

    var bRequest = JSON.stringify(data, null, 2);

    console.log(bRequest);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function tablaPrincipalAplicaciones(estado, condicion, pais, localidad, planta, tipo, numeroserie, nivel, paisuser, localidaduser,token)
{
    const request= {        
        "estado": estado,
        "condicion": condicion,
        "pais": pais,
        "localidad": localidad,
        "planta": planta,
        "tipo": tipo,
        "numeroserie": numeroserie,
        "nivel": nivel,
        "paisuser": paisuser,
        "localidaduser": localidaduser      
    };

    var bRequest = JSON.stringify(request, null, 2);

    const url = Rutas.URL_EQUIPOS + "resumenaplicaciones";    
    //const url = "https://localhost:44347/api/equipo/resumenaplicaciones";    
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }        
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function clonar(id_registro, numeroserie, usuario, token)
{
    const request= {        
        "id_registro": id_registro,
        "numeroserie": numeroserie,
        "usuario": usuario
    };

    var bRequest = JSON.stringify(request, null, 2);

    const url = Rutas.URL_EQUIPOS + "clonacion";    
    //const url = "https://localhost:44347/api/equipo/clonacion";    
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }        
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function clonarEquipo(id_registro, numeroserie, usuario, token) {
    try {
        var respuesta = await clonar(id_registro, numeroserie, usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function requestRelacionesAplicacionesTabla(estado, condicion, pais, localidad, planta, tipo, numeroserie, nivel, paisuser, localidaduser,token) {
    try {
        var respuesta = await tablaPrincipalAplicaciones(estado, condicion, pais, localidad, planta, tipo, numeroserie, nivel, paisuser, localidaduser,token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function actualizarLocalidadEquipoModal(id_equipoescritorio, planta, modificadopor, fechaupdate, correo, usuario1, usuario2, token) {
    try {
        var respuesta = await actualizarLocalidadEquipo(id_equipoescritorio, planta, modificadopor, fechaupdate, correo, usuario1, usuario2, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function reemplazarEquipoModal(id_reemplazado, numeroserial, nombreequipo, condicion_antiguo, modificadopor, fechaupdate, token) {
    try {
        var respuesta = await reemplazarEquipo(id_reemplazado, numeroserial, nombreequipo, condicion_antiguo, modificadopor, fechaupdate, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function validarEquipoModal(id, privilegio, pais, localidad, token) {
    try {
        var respuesta = await validarEquipo(id, privilegio, pais, localidad, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function eliminarEquipoRequest(id, token) {
    try {
        var respuesta = await eliminarEquipo(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerAplicacionesSccm(equipo, token) {
    try {
        var respuesta = await aplicacionesSccm(equipo, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function obtenerAplicaciones(equipo, token) {
    try {
        var respuesta = await aplicaciones(equipo, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function obtenerAplicacionesBO(equipo, token) {
    try {
        var respuesta = await aplicacionesBO(equipo, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function cargasSccm(token) {
    try {
        var respuesta = await obtenerCargasSccm(token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function cargasExcel(usuario, token) {
    try {
        var respuesta = await obtenerCargasExcel(usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function cargasExcelSccm(usuario, token) {
    try {
        var respuesta = await obtenerCargasExcelSccm(usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function uploadExcel(file64, nombre, usuario, container, tipo, token) {
    try {
        var respuesta = await subirArchivoExcel(file64, nombre, usuario, container, tipo, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function actualizaEquipoEscritorio(data, dataAnterior, id, token) {
    try {
        var respuesta = await actualizar(data, dataAnterior, id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerEquipoEscritorio(id, token) {
    try {
        var respuesta = await obtenerEquipo(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearEquipo(data, token) {
    try {
        var respuesta = await crear(data, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

export async function valoresUsuario(usuario, idusuario, token) {
    try {
        var respuesta = await obtenerUsuario(usuario, idusuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

export async function valoresUsuarios(usuario, correo, token) {
    try {
        var respuesta = await obtenerUsuarios(usuario, correo, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

export async function obtenerValoresModal(nivel, pais, localidad, token) {
    try {
        var respuesta = await valoresModal(nivel, pais, localidad, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerValores(nivel, pais, localidad, modulo, idioma, token) {
    try {
        var respuesta = await valores(nivel, pais, localidad, modulo, idioma, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerTabla(estado, condicion, pais, localidad, planta, tipo, modelo, numeroserie, nivel, paisuser, localidaduser, token) {
    try {
        var respuesta = await registrosTabla(estado, condicion, pais, localidad, planta, tipo, modelo, numeroserie, nivel, paisuser, localidaduser, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerTablaSccm(numeroserie, token) {
    try {
        var respuesta = await registrosTablaSccm(numeroserie, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerFiltros(token) {
    try {
        var respuesta = await filtros(token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function bitacorasTabla(estado, condicion, pais, localidad, planta, tipo, modelo, numeroserie, nivel, paisuser, localidaduser,token)
{ 
    const url = Rutas.URL_EQUIPOS + "bitacoras";    
    //const url = "https://localhost:44347/api/equipo/tabla"; 

    const request= {        
        "estado": estado,
        "condicion": condicion,
        "pais": pais,
        "localidad": localidad,
        "planta": planta,
        "tipo": tipo,
        "modelo": modelo,
        "numeroserie": numeroserie,
        "nivel": nivel,
        "paisuser": paisuser,
        "localidaduser": localidaduser      
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

export async function obtenerTablaBitacoras(estado, condicion, pais, localidad, planta, tipo, modelo, numeroserie, nivel, paisuser, localidaduser, token) {
    try {
        var respuesta = await bitacorasTabla(estado, condicion, pais, localidad, planta, tipo, modelo, numeroserie, nivel, paisuser, localidaduser, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}