import React from 'react'
import { obtenerFiltros } from '../functions/FuncEquipoEscritorio'
import { obtenerEquiposTablaModal } from '../functions/FuncAplicacion'
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FiltroBase from '../FiltroBase';
import swal from 'sweetalert';

const ContainerFiltros= styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
`;

const Button = styled.button`
    margin-left: 10px;
    margin-right: auto;
    background-color: #EA7600;
    color: white;    
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    vertical-align: middle;
    color: white;
`;

const useStyles = makeStyles((theme) => ({
    formControl: {        
      marginLeft: '10px',
      marginRight: 'auto',
      minWidth: 50,
      width: '13%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const opcionesFiltros = (arreglo, setConjunto, setCarga) => {
    var array = [];
    if(arreglo.length > 0){
        arreglo.map((p) => array.push({label: p, value: p}));    
        setConjunto(array);
        setCarga(true); 
    }
}

const FiltrosTablaEquipoModal = ({aplicarFiltro, load, id, token}) => {

    const classes = useStyles();

    const [localidadesFiltro, setLocalidadesFiltro] = React.useState(null);

    const [condiciones, setCondiciones] = React.useState(null);
    const [condicion, setCondicion] = React.useState("");
    const [ccondicion, setCCondicion] = React.useState(false);

    const [estados, setEstados] = React.useState(null);
    const [estado, setEstado] = React.useState("");
    const [cestado, setCEstado] = React.useState(false);

    const [plantas, setPlantas] = React.useState(null);
    const [planta, setPlanta] = React.useState("");
    const [cplanta, setCPlanta] = React.useState(false);

    const [paises, setPaises] = React.useState(null);
    const [pais, setPais] = React.useState("");
    const [cpais, setCPais] = React.useState(false);

    const [localidades, setLocalidades] = React.useState(null);
    const [localidad, setLocalidad]  = React.useState("");
    const [clocalidad, setCLocalidad]  = React.useState(false);

    const [numeroserie, setNumeroSerie] = React.useState("");

    const cambioLocalidad = (valor) => {
        setLocalidad(valor);
    };

    const cambioPlanta = (valor) => {
        setPlanta(valor);
    };

    const cambioCondicion= (valor) => {
        setCondicion(valor);
    };

    const cambioEstado = (valor) => {
        setEstado(valor);
    };

    const cambioPais = (valor) => {
        setPais(valor);

        if(valor !== null && valor !== undefined && valor !== "") filtrarLocalidad(valor);
        else{            
            var array = [];
            if(localidadesFiltro.length > 0){
                localidadesFiltro.map((p) => {
                    array.push({label: p.localidad, value: p.localidad});
                });   
                
                setLocalidades(array);                
                
            }
        }
    };

    function filtrarLocalidad(filtro)
    {
        var array = [];
        if(localidadesFiltro.length > 0){
            localidadesFiltro.map((p) => {
                if(p.pais === filtro) array.push({label: p.localidad, value: p.localidad});
            });   
            setLocalidad(""); 
            setLocalidades(array);            
        }
    }

    React.useEffect(() =>{
        obtenerFiltrosTabla();
    }, []);

    async function obtenerFiltrosTabla(){
        await obtenerFiltros(token)
        .then((response) => {
            if(response.cod === 1)
            {                
                opcionesFiltros(response.condiciones, setCondiciones, setCCondicion);
                opcionesFiltros(response.paises, setPaises, setCPais);
                opcionesFiltros(response.localidades, setLocalidades, setCLocalidad);
                opcionesFiltros(response.plantas, setPlantas, setCPlanta);
                setLocalidadesFiltro(response.filtrolocalidad);
                opcionesFiltros(response.estados, setEstados, setCEstado);
            }
        }); 
    }

    async function obtenerRegistros()
    {
        load(true);
        aplicarFiltro(null);        
        await obtenerEquiposTablaModal(estado, condicion, pais, localidad, planta, numeroserie, id, token)
        .then((response) => { 

            if (response.cod === 1)
            {
                aplicarFiltro(response.equipos);
            }
            else {
                aplicarFiltro(null);
                swal ("Search error" , response.mensaje , "warning");
            }
                
            load(false);
        });
    }

    return (
        <>
            <ContainerFiltros>                
                
                <FiltroBase 
                    opciones={condiciones} 
                    cargado={ccondicion}
                    value={condicion}
                    nombre="Condition"
                    cambio={cambioCondicion}
                    classes={classes}
                />
                 <FiltroBase 
                    opciones={paises} 
                    cargado={cpais}
                    value={pais}
                    nombre="Country"
                    cambio={cambioPais}
                    classes={classes}
                />
                <FiltroBase 
                    opciones={localidades} 
                    cargado={clocalidad}
                    value={localidad}
                    nombre="Location"
                    cambio={cambioLocalidad}
                    classes={classes}
                />
                <FiltroBase 
                    opciones={plantas} 
                    cargado={cplanta}
                    value={planta}
                    nombre="Plant"
                    cambio={cambioPlanta}
                    classes={classes}
                />
                <FormControl variant="outlined" className={classes.formControl}>  
                    {
                        <TextField 
                            id="outlined-basic" 
                            value={numeroserie} 
                            onChange={(e) => setNumeroSerie(e.target.value)} 
                            label="Desktop Name" 
                            variant="outlined" 
                        />  
                    }                 
                </FormControl>

                <Button 
                    onClick={obtenerRegistros} 
                    className={classes.formControl} 
                    style={{verticalAlign: 'middle'}}
                >Display</Button>
                
            </ContainerFiltros>            
            
            
        </>
    )
}

export default FiltrosTablaEquipoModal
