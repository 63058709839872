import React, { useState, useRef } from 'react'
import styled from 'styled-components';
import Modal from 'react-modal'
import * as FaIcons from 'react-icons/fa';
import "../../css/modalclonacion.css";
import FormClonacion from './FormClonacion';

const ButtonModal = styled.button`
    float:right;
    background-color: #696158;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 15px; 
    border-radius: 5px;  
    border-color: #453F38;
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
    color: white;    
    margin-right: 10px;
    height: 40px;

    :hover {
        background: #AB9883;
    }
`;


const ModalClonacion = ({token, id_registro, redireccion}) => {

    const [modalOpen, setModalOpen] = useState(false);    

    function abrirModal()
    {        
        setModalOpen(true);
    }

    function cerrarModal()
    {
        setModalOpen(false);
    }

  return (
    <>
        <ButtonModal type="button" onClick={abrirModal} className="tooltipboton">
            <FaIcons.FaClone/>
            <span className="tooltiptextboton">{"Clone"}</span> 
        </ButtonModal>

        <Modal 
            isOpen={modalOpen}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.75)'
                },
                content: {
                    width: '65%',   
                    height: 'auto',     
                    marginTop: '200px',
                    marginBottom: 'auto',          
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    position: 'relative',                        
                    border: '1px solid #ccc',
                    background: '#fff',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '10px'
                }
            }}
        >
            <div className="containerclonacion">   
                <FormClonacion token={token} id_registro={id_registro} cerrarModal={cerrarModal} redireccion={redireccion}/>
            </div>
        </Modal>
    </>
  )
}

export default ModalClonacion