import * as Rutas from '../RutasServicios'

async function filtrosSala(token)
{ 
    const url = Rutas.URL_Sala + "filtros"; 
    //const url = "https://localhost:44372/api/sala/filtros";    
    
    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function salasTabla(pais, localidad, planta, sala, nivel, paisuser, localidaduser, token)
{ 
    const url = Rutas.URL_Sala + "tabla";    
    //const url = "https://localhost:44372/api/sala/tabla"; 

    const request= {
        "pais": pais,
        "localidad": localidad,
        "planta": planta,
        "sala": sala,
        "nivel": nivel,
        "paisuser": paisuser,
        "localidaduser": localidaduser       
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

async function valoresSala(nivel, pais, localidad, modulo, idioma, token)
{ 
    const url = Rutas.URL_Sala + "valores";    
    //const url = "https://localhost:44372/api/sala/valores"; 

    const request= {        
        "nivel": nivel,        
        "pais": pais,
        "localidad": localidad,
        "modulo": modulo,
        "idioma": idioma
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function crear(data, token){

    const url = Rutas.URL_Sala + "crear";    
    //const url = "https://localhost:44372/api/sala/crear"; 

    const create = { 
        "nombre": data.nombre.toString().toUpperCase(),
        "empresa": data.empresa.value.toString(),
        "planta": data.planta.value.toString(),        
        "extincionincendios": data.extincionincendios.toString().toUpperCase(),
        "equiposaire": data.equiposaire.toString().toUpperCase(),
        "capacidadareablanca": data.capacidadareablanca.toString().toUpperCase(),
        "grupoelectrogeno": (data.grupoelectrogeno) ? "1" : "0",
        "deteccionincendio": (data.deteccionincendio) ? "1" : "0",
        "alarmacentral": (data.alarmacentral) ? "1" : "0",
        "controlacceso": data.controlacceso.toString().toUpperCase(),
        "sensortemperatura": (data.sensortemperatura) ? "1" : "0",
        "observacion": data.observacion.toString().toUpperCase() 
    };
    
    //UPS 1
    create.ups1_uso = (data.ups1_uso !== "" && data.ups1_uso !== null && data.ups1_uso !== undefined) ? data.ups1_uso.toString().toUpperCase() : null;
    create.ups1_marca = (data.ups1_marca !== "" && data.ups1_marca !== null && data.ups1_marca !== undefined) ? data.ups1_marca.value.toString().toUpperCase() : null;
    create.ups1_direccionip = (data.ups1_direccionip !== "" && data.ups1_direccionip !== null && data.ups1_direccionip !== undefined) ? data.ups1_direccionip.toString().toUpperCase() : null;
    create.ups1_capacidad = (data.ups1_capacidad !== "" && data.ups1_capacidad !== null && data.ups1_capacidad !== undefined) ? data.ups1_capacidad : null;
    create.ups1_autonomia = (data.ups1_autonomia !== "" && data.ups1_autonomia !== null && data.ups1_autonomia !== undefined) ? data.ups1_autonomia : null;
    create.ups1_monitoreo = (data.ups1_monitoreo) ? "1" : "0";
    create.ups1_tarjetared = (data.ups1_tarjetared) ? "1" : "0";
    create.ups1_fechapruebaups = (data.ups1_fechapruebaups !== "" && data.ups1_fechapruebaups !== null) ? new Date(data.ups1_fechapruebaups).toISOString().substring(0, 10) : null;
    create.ups1_fechaultimamantencion = (data.ups1_fechaultimamantencion !== "" && data.ups1_fechaultimamantencion !== null) ? new Date(data.ups1_fechaultimamantencion).toISOString().substring(0, 10) : null;

     //UPS 2
    create.ups2_uso = (data.ups2_uso !== "" && data.ups2_uso !== null && data.ups2_uso !== undefined) ? data.ups2_uso.toString().toUpperCase() : null;
    create.ups2_marca = (data.ups2_marca !== "" && data.ups2_marca !== null && data.ups2_marca !== undefined) ? data.ups2_marca.value.toString().toUpperCase() : null;
    create.ups2_direccionip = (data.ups2_direccionip !== "" && data.ups2_direccionip !== null && data.ups2_direccionip !== undefined) ? data.ups2_direccionip.toString().toUpperCase() : null;
    create.ups2_capacidad = (data.ups2_capacidad !== "" && data.ups2_capacidad !== null && data.ups2_capacidad !== undefined) ? data.ups2_capacidad : null;
    create.ups2_autonomia = (data.ups2_autonomia !== "" && data.ups2_autonomia !== null && data.ups2_autonomia !== undefined) ? data.ups2_autonomia : null;
    create.ups2_monitoreo = (data.ups2_monitoreo) ? "1" : "0";
    create.ups2_tarjetared = (data.ups2_tarjetared) ? "1" : "0";
    create.ups2_fechapruebaups = (data.ups2_fechapruebaups !== "" && data.ups2_fechapruebaups !== null) ? new Date(data.ups2_fechapruebaups).toISOString().substring(0, 10) : null;
    create.ups2_fechaultimamantencion = (data.ups2_fechaultimamantencion !== "" && data.ups2_fechaultimamantencion !== null) ? new Date(data.ups2_fechaultimamantencion).toISOString().substring(0, 10) : null;


     //UPS 3
    create.ups3_uso = (data.ups3_uso !== "" && data.ups3_uso !== null && data.ups3_uso !== undefined) ? data.ups3_uso.toString().toUpperCase() : null;
    create.ups3_marca = (data.ups3_marca !== "" && data.ups3_marca !== null && data.ups3_marca !== undefined) ? data.ups3_marca.value.toString().toUpperCase() : null;
    create.ups3_direccionip = (data.ups3_direccionip !== "" && data.ups3_direccionip !== null && data.ups3_direccionip !== undefined) ? data.ups3_direccionip.toString().toUpperCase() : null;
    create.ups3_capacidad = (data.ups3_capacidad !== "" && data.ups3_capacidad !== null && data.ups3_capacidad !== undefined) ? data.ups3_capacidad : null;
    create.ups3_autonomia = (data.ups3_autonomia !== "" && data.ups3_autonomia !== null && data.ups3_autonomia !== undefined) ? data.ups3_autonomia : null;
    create.ups3_monitoreo = (data.ups3_monitoreo) ? "1" : "0";
    create.ups3_tarjetared = (data.ups3_tarjetared) ? "1" : "0";

    create.modificadopor = (data.modificadopor !== "" && data.modificadopor !== null) ? data.modificadopor.toString().toUpperCase() : null;
    create.fechaupdate = (data.fechaupdate !== "" && data.fechaupdate !== null) ? new Date(data.fechaupdate).toISOString().substring(0, 10) : null;

    var bRequest = JSON.stringify(create, null, 2);
    console.log(bRequest)

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido !== null){        
        res = convertido;
    }

    return res;     
}

async function obtener(id, token)
{ 
    const url = Rutas.URL_Sala + "sala";    
    //const url = "https://localhost:44372/api/sala/sala"; 

    const request= {
        "id_sala": id           
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function actualizar(data, dataAnterior, id, token){

    const url = Rutas.URL_Sala + "actualizar";    
    //const url = "https://localhost:44372/api/sala/actualizar"; 

    const request = { 
        "id_sala": id,
        "nombre": data.nombre.toString().toUpperCase(),
        "grupoelectrogeno": (data.grupoelectrogeno !== dataAnterior.grupoelectrogeno) ? (data.grupoelectrogeno) ? "1" : "0" : null,
        "deteccionincendio": (data.deteccionincendio !== dataAnterior.deteccionincendio) ? (data.deteccionincendio) ? "1" : "0" : null,
        "alarmacentral": (data.alarmacentral !== dataAnterior.alarmacentral) ? (data.alarmacentral) ? "1" : "0" : null,        
        "sensortemperatura": (data.sensortemperatura !== dataAnterior.sensortemperatura) ? (data.sensortemperatura) ? "1" : "0" : null,
        "ups1_tarjetared": (data.ups1_tarjetared !== dataAnterior.ups1_tarjetared) ? (data.ups1_tarjetared) ? "1" : "0" : null,  
        "ups1_monitoreo": (data.ups1_monitoreo !== dataAnterior.ups1_monitoreo) ? (data.ups1_monitoreo) ? "1" : "0" : null,
        "ups2_tarjetared": (data.ups2_tarjetared !== dataAnterior.ups2_tarjetared) ? (data.ups2_tarjetared) ? "1" : "0" : null,  
        "ups2_monitoreo": (data.ups2_monitoreo !== dataAnterior.ups2_monitoreo) ? (data.ups2_monitoreo) ? "1" : "0" : null,
        "ups3_tarjetared": (data.ups3_tarjetared !== dataAnterior.ups3_tarjetared) ? (data.ups3_tarjetared) ? "1" : "0" : null,  
        "ups3_monitoreo": (data.ups3_monitoreo !== dataAnterior.ups3_monitoreo) ? (data.ups3_monitoreo) ? "1" : "0" : null    
    };

    request.modificadopor = data.modificadopor;
    request.fechaupdate = new Date(data.fechaupdate).toISOString().substring(0, 10);

    if(data.empresa !== undefined) request.empresa = (data.empresa !== dataAnterior.empresa) ? data.empresa.value.toString().toUpperCase() : "";
    if(data.planta !== undefined) request.planta = (data.planta !== dataAnterior.planta) ? data.planta.value.toString().toUpperCase() : "";

    if(data.equiposaire !== undefined) request.equiposaire = (data.equiposaire !== dataAnterior.equiposaire) ? data.equiposaire.toString().toUpperCase() : "";
    if(data.extincionincendios !== undefined) request.extincionincendios = (data.extincionincendios !== dataAnterior.extincionincendios) ? data.extincionincendios.toString().toUpperCase() : "";
    if(data.capacidadareablanca !== undefined) request.capacidadareablanca = (data.capacidadareablanca !== dataAnterior.capacidadareablanca) ? data.capacidadareablanca.toString().toUpperCase() : "";
    
    request.observacion = (data.observacion !== dataAnterior.observacion) ? (data.observacion !== "") ? data.observacion.toString().toUpperCase() : "ESNULL" : "";
    request.controlacceso = (data.controlacceso !== dataAnterior.controlacceso) ? (data.controlacceso !== "") ? data.controlacceso.toString().toUpperCase() : "ESNULL" : "";  

    request.ups1_uso = (data.ups1_uso !== dataAnterior.ups1_uso) ? (data.ups1_uso !== "") ? data.ups1_uso.toString().toUpperCase() : "ESNULL" : "";
    if(data.ups1_marca !== undefined) request.ups1_marca = (data.ups1_marca !== dataAnterior.ups1_marca) ? (data.ups1_marca !== "" && data.ups1_marca !== null) ? data.ups1_marca.value.toString().toUpperCase() : "ESNULL" : "";
    request.ups1_direccionip = (data.ups1_direccionip !== dataAnterior.ups1_direccionip) ? (data.ups1_direccionip !== "") ? data.ups1_direccionip.toString().toUpperCase() : "ESNULL" : "";
    request.ups1_capacidad = (data.ups1_capacidad !== dataAnterior.ups1_capacidad) ? (data.ups1_capacidad !== "") ? data.ups1_capacidad.toString().toUpperCase() : "ESNULL" : "";
    request.ups1_autonomia = (data.ups1_autonomia !== dataAnterior.ups1_autonomia) ? (data.ups1_autonomia !== "") ? data.ups1_autonomia.toString().toUpperCase() : "ESNULL" : "";
    request.ups1_fechapruebaups = (data.ups1_fechapruebaups !== dataAnterior.ups1_fechapruebaups) ? (data.ups1_fechapruebaups !== null) ? new Date(data.ups1_fechapruebaups).toISOString().substring(0, 10) : "ESNULL" : "";
    request.ups1_fechaultimamantencion = (data.ups1_fechaultimamantencion !== dataAnterior.ups1_fechaultimamantencion) ? (data.ups1_fechaultimamantencion !== null) ? new Date(data.ups1_fechaultimamantencion).toISOString().substring(0, 10) : "ESNULL" : "";


    request.ups2_uso = (data.ups2_uso !== dataAnterior.ups2_uso) ? (data.ups2_uso !== "") ? data.ups2_uso.toString().toUpperCase() : "ESNULL" : "";
    if(data.ups2_marca !== undefined) request.ups2_marca = (data.ups2_marca !== dataAnterior.ups2_marca) ? (data.ups2_marca !== "" && data.ups2_marca !== null) ? data.ups2_marca.value.toString().toUpperCase() : "ESNULL" : "";
    request.ups2_direccionip = (data.ups2_direccionip !== dataAnterior.ups2_direccionip) ? (data.ups2_direccionip !== "") ? data.ups2_direccionip.toString().toUpperCase() : "ESNULL" : "";
    request.ups2_capacidad = (data.ups2_capacidad !== dataAnterior.ups2_capacidad) ? (data.ups2_capacidad !== "") ? data.ups2_capacidad.toString().toUpperCase() : "ESNULL" : "";
    request.ups2_autonomia = (data.ups2_autonomia !== dataAnterior.ups2_autonomia) ? (data.ups2_autonomia !== "") ? data.ups2_autonomia.toString().toUpperCase() : "ESNULL" : "";
    request.ups2_fechapruebaups = (data.ups2_fechapruebaups !== dataAnterior.ups2_fechapruebaups) ? (data.ups2_fechapruebaups !== null) ? new Date(data.ups2_fechapruebaups).toISOString().substring(0, 10) : "ESNULL" : "";
    request.ups2_fechaultimamantencion = (data.ups2_fechaultimamantencion !== dataAnterior.ups2_fechaultimamantencion) ? (data.ups2_fechaultimamantencion !== null) ? new Date(data.ups2_fechaultimamantencion).toISOString().substring(0, 10) : "ESNULL" : "";

   
    var bRequest = JSON.stringify(request);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido !== null){        
        res = convertido;
    }

    return res;
}

async function servidoresTabla(id, token)
{ 
    const url = Rutas.URL_Sala + "relacionservidor";    
    //const url = "https://localhost:44372/api/sala/relacionservidor"; 

    const request= {
        "id_sala": id              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function servidoresTablaModal(id, localidad, pais, negocio, servidor, token)
{ 
    const url = Rutas.URL_Sala + "tablamodal";    
    //const url = "https://localhost:44372/api/sala/tablamodal"; 

    const request= {
        "id_sala": id,
        "filtrolocalidad": localidad,
        "filtropais": pais,
        "filtronegocio": negocio,
        "filtroservidor": servidor              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function crearRelacionServidor(id, servidores, token)
{ 
    const url = Rutas.URL_Sala + "crearrelacionservidor";    
    //const url = "https://localhost:44372/api/sala/crearrelacionservidor"; 

    const request= {
        "id_sala": id,
        "servidores": servidores            
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function eliminarRelacionServidor(id, servidores, token)
{ 
    const url = Rutas.URL_Sala + "eliminarrelacionservidor";    
    //const url = "https://localhost:44372/api/sala/eliminarrelacionservidor"; 

    const request= {
        "id_sala": id,
        "servidores": servidores            
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function eliminar(id, token)
{ 
    const url = Rutas.URL_Sala + "eliminar";    
    //const url = "https://localhost:44372/api/sala/eliminar"; 
    
    const data = {        
        "id": id
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function eliminarSala(id, token) {
    try {
        var respuesta = await eliminar(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function eliminarRelacionesServidor(id, servidores, token) {
    try {
        var respuesta = await eliminarRelacionServidor(id, servidores, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearRelacionesServidor(id, servidores, token) {
    try {
        var respuesta = await crearRelacionServidor(id, servidores, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerServidoresModal(id, localidad, pais, negocio, servidor, token) {
    try {
        var respuesta = await servidoresTablaModal(id, localidad, pais, negocio, servidor, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerServidores(id, token) {
    try {
        var respuesta = await servidoresTabla(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function actualizarSala(data, dataAnterior, id, token) {
    try {
        var respuesta = await actualizar(data, dataAnterior, id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

export async function obtenerSala(id, token) {
    try {
        var respuesta = await obtener(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

export async function crearSala(data, token) {
    try {
        var respuesta = await crear(data, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

export async function obtenerValores(nivel, pais, localidad, modulo, idioma, token) {
    try {
        var respuesta = await valoresSala(nivel, pais, localidad, modulo, idioma, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerTabla(pais, localidad, planta, sala, nivel, paisuser, localidaduser, token) {
    try {
        var respuesta = await salasTabla(pais, localidad, planta, sala, nivel, paisuser, localidaduser, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerFiltros(token) {
    try {
        var respuesta = await filtrosSala(token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}