import { useState } from 'react';
import { makeStyles, useTheme, withStyles  } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';


import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';

import * as MdIcons from 'react-icons/md';
import swal from 'sweetalert';

import ExportarExcel from '../ExportarExcel';

import { construirFecha } from '../functions/MetodosComunes'

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {

    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export const TablaRegistros  = ({headCells, rows, url, idFieldName, initialSortByField, initialSortDirection, fieldToExport}) => {


    const history = useHistory();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [order, setOrder] = useState(initialSortDirection);
    const [orderBy, setOrderBy] = useState(initialSortByField);

    const classes = useStyles();

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    function stableSort(array) {

        var arrayOrdenado = (order === 'desc') ? array.sort((a,b) => (a[orderBy] > b[orderBy]) ? 1 : (b[orderBy] > a[orderBy]) ? -1 : 0) : array.sort((a,b) => (a[orderBy] < b[orderBy]) ? 1 : (b[orderBy] < a[orderBy]) ? -1 : 0);

        return arrayOrdenado;
    }
    
    const StyledTableCell = withStyles((theme) => ({
        head: {
          color: theme.palette.common.white,
          
        },
        body: {
            textDecoration: 'none',
          fontSize: 12,
        },
    }))(TableCell);

    const handleRowClick = (rowUrl) => {
        history.push(rowUrl);
      }

    const renderCellContent = ( dataRow, fieldName ) => {      
      
      if ( fieldToExport?.fieldName === fieldName ) {

        return (
          <ExportarExcel
            rows = { dataRow[fieldName] }
            columnas = { fieldToExport.columns }
            nombreSheet = { fieldToExport.sheetName }
            nombreArchivo = { fieldToExport.fileName }
            subtitulo = { fieldToExport.subtitulo } />
        );
      }

      if ( fieldName === "observacion" ) {

        return (
          <button 
            onClick={() => {
                swal({
                text: dataRow.observaciones,
                className: 'sweetalert-observacion'
              });
            }}
        className="boton-observacion">
            <MdIcons.MdDescription/>
        </button>    
        );
      }

      return (
        (fieldName.includes('fecha') && !dataRow.hasOwnProperty('id_cargaexcel')) ? construirFecha(dataRow[fieldName]) : dataRow[fieldName]
      );       
    } 

    return (
        <>
            <TableContainer component={Paper}>
                <Table 
                    className={classes.table} 
                    size="small" 
                    aria-label="enhanced table">
                    <TableHead style={{ background: '#696158', color: '#fff' }}>
                        <TableRow>
                            {
                                headCells.map((headCell) => (
                                    <StyledTableCell 
                                        key={headCell.id}
                                        align={'center'}   
                                        sortDirection={orderBy === headCell.id ? order : false}
                                        style={{ width: headCell.size, color: '#fff !important' }}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                            style={{ background: '#696158', color: '#fff' }}>
                                            {headCell.label}
                                        </TableSortLabel>                                        
                                    </StyledTableCell>
                                ))
                            }                                
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {   
                          stableSort(rows).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (                                                
                            <TableRow
                              hover
                              key = { row[idFieldName] }
                              style={{  cursor: ((url === null) ? 'default' : 'pointer'), textDecoration: 'none', fontSize: '10px !important', backgroundColor: (row['estado'] !== "INACTIVE") ? '' : '#efbbbb'  }}>
                              {
                                headCells.map(( headCell ) => (
                                  <StyledTableCell
                                    key = { headCell.id }
                                    onClick = { () => (url === null || headCell.id === "observacion") ? null : handleRowClick(url+row[idFieldName]) }
                                    align = {(headCell.id === "observacion") ? "center" : "left" }
                                    >
                                    {
                                      renderCellContent( row, headCell.id )
                                    }
                                  </StyledTableCell>
                                ))
                              }                                           
                            </TableRow>
                          ))
                        }   
                    </TableBody>                        
                </Table>
                
            </TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TablePagination 
                            style={{ display: 'flex'}}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            colSpan={3}
                            count={(rows !== null) ? rows.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'Registros por pagina' },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions} />
                    </TableRow>
                </TableBody> 
            </Table>
        </>                     
    )
}