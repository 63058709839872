import React, { useState } from 'react'

import Modal from 'react-modal'
import swal from 'sweetalert';

import * as AiIcons from 'react-icons/ai';

import TablaBitacora from './TablaBitacora';
import { obtenerBitacoras } from '../functions/FuncBitacora'
import Loading from '../Loading';
import '../css/bitacora.css'

import { ButtonRelacion } from '../Commons/Styles'

const Bitacora = ({id, tipo, token}) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [modalCarga, setModalCarga] = useState(false);
    const [bitacoras, setBitacoras] = useState(null);

    function abrirModal()
    {
        buscarBitacoras();
        setModalOpen(true);
    }

    function cerrarModal()
    {       
        setBitacoras(null);
        setModalOpen(false);
    }

    async function buscarBitacoras(year, month, day, campo){

        console.log(year, month, day, campo)

        await obtenerBitacoras(id, tipo, year, month, day, campo, token)
        .then((response) => {

            console.log(response)

            if(response.cod === 1) setBitacoras(response.bitacoras);
            else if(response.cod == 3) {
                setBitacoras([]);
                swal ("There are no records", "No information is recorded with the filters used", "info");
            }
            else {
                setBitacoras([]);
                swal ("Error", response.mensaje, "error");
            }            
        })
    }

    async function buscarBitacorasInicial(year, month, day, campo){

        console.log(year, month, day, campo)

        await obtenerBitacoras(id, tipo, year, month, day, campo, token)
        .then((response) => {

            console.log(response)

            if(response.cod === 1) setBitacoras(response.bitacoras);
            else setBitacoras([]);  
        })
    }

    async function cargarBitacoras() {
        
        setModalCarga(true);
        
        await buscarBitacorasInicial("", "", "", "")

        setModalCarga(false);
    }

    React.useEffect(() => {
        if (bitacoras !== null) {
           
        }
        else cargarBitacoras();
    }, [])

  return (
    <>
        <ButtonRelacion type="button" onClick={abrirModal}  className="tooltipboton">
            
            <AiIcons.AiOutlineAudit/>
            <span className="tooltiptextboton">{"Change log"}</span> 
                         
        </ButtonRelacion>

        <Modal 
            isOpen={modalOpen}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.75)'
                },
                content: {
                    width: '95%',
                    height: 'auto',
                    minHeight: '450px',
                    marginLeft: '10px',
                    marginRight: '10px',
                    position: 'relative',                        
                    border: '1px solid #ccc',
                    background: '#fff',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '10px'
                }
            }}
        >
            {
                (modalCarga) ? (<Loading />)
                : (
                    <>
                        <div className="containerbitacora">
                            <TablaBitacora rows={bitacoras} buscarBitacoras={buscarBitacoras} token={token} cerrarModal={cerrarModal}/>
                        </div>
                    </>
                )
            }            
        </Modal>
    </>
  )
}

export default Bitacora