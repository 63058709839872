export const camposExcel = [
    {nombre:"NAME ACCOUNT", valor:"nombre"},
    {nombre:"STATE", valor:"estado"},
    {nombre:"DESCRIPTION", valor:"descripcion"},
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"BUSINESS", valor:"negocio"},
    {nombre:"RESPONSABLE", valor:"responsable"},
    {nombre:"CREATION DATE", valor:"fechacreacion"},
    {nombre:"REQUESTED BY", valor:"solicitadapor"},
    {nombre:"OBSERVATIONS", valor:"observaciones"},
    {nombre:"ACCOUNT TYPE", valor:"tipocuenta"},
    {nombre:"SERVERS", valor:"servidores"},
    {nombre:"APPLICATIONS", valor:"aplicaciones"},
    {nombre:"MODIFIED BY", valor:"modificadopor"},
    {nombre:"MODIFICATION DATE", valor:"fechaupdate"}
]

export const camposExcelServidores = [
    {nombre:"SERVER", valor:"servidor"},
    {nombre:"CONDITION", valor:"estado"},    
    {nombre:"ENVIRONMENT", valor:"ambiente"},
    {nombre:"SERVER TYPE", valor:"tiposervidor"},
    {nombre:"DESCRIPTION", valor:"descripcion"}
]

export const camposExcelAplicaciones = [
    {nombre:"APPLICATION", valor:"nombre"},
    {nombre:"VERSION", valor:"version"},   
    {nombre:"ENVIRONMENT", valor:"ambiente"},
    {nombre:"TYPE APPLICATION", valor:"tiposoftware"},    
    {nombre:"OBSERVATIONS", valor:"observaciones"}
]

export const camposExcelCarga = [   
    {nombre:"FILA", valor:"fila"},
    {nombre:"DETALLE", valor:"detalle"}    
]

export const campos = [
    "id_cuentaservicio",
    "nombre",
    "estado",
    "descripcion",
    "pais",
    "negocio",
    "responsable",
    "solicitadapor",
    "fechacreacion",
    "observaciones",
    "tipocuenta",
    "modificadopor",
    "fechaupdate"    
]