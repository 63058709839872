import React from 'react'
import LoadingCarga from './LoadingCarga'
import TablaDocumentos from './TablaDocumentos'
import Modal from 'react-modal'

import * as TiIcons from 'react-icons/ti';

import { ButtonVolver } from './Commons/Styles'

const Documentos = ({documentos, deletedocumento, updatedocumento, modalCarga, abrirModalCarga, cerrarModalCarga, cerrarModal, token}) => {   

    return (
        <>
            
            <div className="containersharepoint">
                <div style={{width: "100%", marginBottom: '35px'}}>
                    <ButtonVolver onClick={cerrarModal} className="tooltipboton">
                        <TiIcons.TiArrowBack/>                
                        <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>                                    
                </div>
                <TablaDocumentos 
                    rows={documentos} 
                    deletedocumento={deletedocumento} 
                    updatedocumento={updatedocumento}
                    abrirModalCarga={abrirModalCarga}
                    cerrarModalCarga={cerrarModalCarga}
                />
                <br></br>
                <br></br>

            </div>
            
            <Modal 
                isOpen={modalCarga}
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0                      
                    },
                    content: {        
                        background: 'none',
                        border: 'none',
                        width: '250px',
                        height: '250px',                
                        marginLeft: 'auto',                        
                        marginRight: 'auto',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        position: 'absolute',                        
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',                        
                        outline: 'none',
                        
                    }
                  }}
            >
                <LoadingCarga />
            </Modal>

        </>
    )
}

export default Documentos
