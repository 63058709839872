import React, {useState} from 'react'
import * as Metodos from '../functions/MetodosComunes'
import { useForm, Controller } from "react-hook-form";
import * as Opciones from './OpcionesEquiposEscritorio'
import { obtenerValores, valoresUsuario, obtenerEquipoEscritorio, actualizaEquipoEscritorio, obtenerAplicaciones, obtenerAplicacionesBO, obtenerAplicacionesSccm} 
from '../functions/FuncEquipoEscritorio'

import "../css/formularioAplicacion.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import Loading from '../Loading';
import Select from 'react-select'
import * as FcIcons from 'react-icons/fc';
import TablaAplicacionesEquipo from './TablaAplicacionesEquipo';
import TablaAplicacionesBackEquipo from './TablaAplicacionesBackEquipo';
import TablaAplicacionesSccmEquipo from './TablaAplicacionesSccmEquipo';
import { registrarBitacoraEquipoEscritorio } from "../functions/FuncBitacora";
import { validarTextoVacio } from '../functions/MetodosComunes'

import { validarPrivilegioModificacion } from '../Commons/Functions'
import { style, styleNormal, styleDisable } from '../Commons/Styles'

const FormularioEquipoEscritorio = ({id, validarPermiso, cambiarDocEntrega, docEntrega, token, validarEstado}) => {

    let privEscribir = sessionStorage.getItem('esc_escribir');
    let privLeer = sessionStorage.getItem('esc_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');
    let idiomaUser = sessionStorage.getItem('idioma');
    const [privModificar, setPrivModificar] = React.useState(false);
    const [privModificarEstado, setPrivModificarEstado] = React.useState(false);

    const [registro, setRegistro] = useState(null);    
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const [aplicaciones, setAplicaciones] = useState(null);
    const [aplicacionesBO, setAplicacionesBO] = useState(null);
    const [aplicacionesSccm, setAplicacionesSccm] = useState(null);

    const [busqueda, setBusqueda] = useState(true);
    const [generico, setGenerico] = useState(false);
    const [idUsuario, setIdUsuario] = useState(null);
    const [nombreUsuario, setNombreUsuario] = useState(null);
    const [division, setDivision] = useState("");
    const [divisionplanta, setDivisionPlanta] = useState(null);
    const [divisionfecha, setDivisionFecha] = useState(null);
    const [cargo, setCargo] = useState(null);
    const [cargorol, setCargoRol] = useState(null);
    const [correo, setCorreo] = useState(null);
    const [centrocosto, setCentroCosto] = useState(null);
    const [gerenciaprincipal, setGerenciaPrincipal] = useState(null);
    const [gerenciadirecta, setGerenciaDirecta] = useState(null);
    const [subGerenciaArea, setSubGerenciaArea] = useState(null);
    const [licenciaprincipal, setLicenciaPrincipal] = useState(null);
    const [usuarioMaestroPersonal, setUsuarioMaestroPersonal] = useState(false);

    const [estado, setEstado] = useState("");
    const [estadofiltro, setEstadofiltro] = useState("");
    const [condicion, setCondicion] = useState("");
    const [empresa, setEmpresa] = useState("");
    const [planta, setPlanta] = useState(""); 
    const [marca, setMarca] = useState("");
    const [modelo, setModelo] = useState("");
    

    const [sccm, setSccm] = useState(true);
    const [tabla, setTabla] = useState(false);
    const [tablaBO, setTablaBO] = useState(false);
    const [tablasccm, setTablaSccm] = useState(false);

    const [errorNumeroSerie, setErrorNumeroSerie] = useState(false);
    const [errorEstado, setErrorEstado] = useState(false);
    const [errorEmpresa, setErrorEmpresa] = useState(false);
    const [errorCondicion, setErrorCondicion] = useState(false);
    const [errorPlanta, setErrorPlanta] = useState(false);
    const [errorNombreEquipo, setErrorNombreEquipo] = useState(false);
    const [errorTipoEquipo, setErrorTipoEquipo] = useState(false);
    const [errorMarca, setErrorMarca] = useState(false);
    const [errorModelo, setErrorModelo] = useState(false);
    const [errorCondicionRed, setErrorCondicionRed] = useState(false);
    const [errorUsuario, setErrorUsuario] = useState(false);
    const [errorIdUsuario, setErrorIdUsuario] = useState(false);

    const [cambioExcluidoparches, setCambioExcluidoparches] = useState(null);
    const [cambioUsbdesbloqueado, setCambioUsbdesbloqueado] = useState(null);
    const [cambioDocking, setCambioDocking] = useState(null);
    const [cambioMonitor, setCambioMonitor] = useState(null);
    const [cambioVip, setCambioVip] = useState(null);
    const [cambioAdministrador, setCambioAdministrador] = useState(null);

    const eCambioExcluidoparches = (e) => setCambioExcluidoparches(e.target.checked);
    const eCambioUsbdesbloqueado = (e) => setCambioUsbdesbloqueado(e.target.checked);
    const eCambioDocking = (e) => setCambioDocking(e.target.checked);
    const eCambioMonitor = (e) => setCambioMonitor(e.target.checked);
    const eCambioVip = (e) => setCambioVip(e.target.checked);
    const eCambioAdministrador = (e) => setCambioAdministrador(e.target.checked);


    function mapResponseToRegistro(response){

        setIdUsuario(Metodos.validarTextoVacio(response.equipo.idusuario) ? response.equipo.idusuario : null);
        setNombreUsuario(Metodos.validarTextoVacio(response.equipo.nombreusuario) ? response.equipo.nombreusuario : null);
        setCorreo(Metodos.validarTextoVacio(response.equipo.correo) ? response.equipo.correo : null);
        setLicenciaPrincipal(Metodos.validarTextoVacio(response.equipo.licenciaprincipal) ? response.equipo.licenciaprincipal : null);
        setDivision(Metodos.validarTextoVacio(response.equipo.division) ?  {"label": response.equipo.division, "value": response.equipo.division} : null);
        setDivisionPlanta(Metodos.validarTextoVacio(response.equipo.divisionplanta) ? response.equipo.divisionplanta : null);
        setDivisionFecha(Metodos.validarTextoVacio(response.equipo.divisionfecha) ? response.equipo.divisionfecha : null);
        setCargo(Metodos.validarTextoVacio(response.equipo.cargo) ?  {"label": response.equipo.cargo, "value": response.equipo.cargo} : null);
        setCargoRol(Metodos.validarTextoVacio(response.equipo.cargorol) ? response.equipo.cargorol : null);
        setCentroCosto(Metodos.validarTextoVacio(response.equipo.centrocosto) ?  {"label": response.equipo.centrocosto, "value": response.equipo.centrocosto} : null);
        setGerenciaPrincipal(Metodos.validarTextoVacio(response.equipo.gerenciaprincipal) ?  {"label": response.equipo.gerenciaprincipal, "value": response.equipo.gerenciaprincipal} : null);
        setGerenciaDirecta(Metodos.validarTextoVacio(response.equipo.gerenciadirecta) ?  {"label": response.equipo.gerenciadirecta, "value": response.equipo.gerenciadirecta} : null);
        setSubGerenciaArea(Metodos.validarTextoVacio(response.equipo.subgerenciaarea) ?  {"label": response.equipo.subgerenciaarea, "value": response.equipo.subgerenciaarea} : null);
        setUsuarioMaestroPersonal(Metodos.validarTextoVacio(response.equipo.usuariomaestropersonal) ? response.equipo.usuariomaestropersonal : null);
        
        setEmpresa(Metodos.validarTextoVacio(response.equipo.empresa) ? {"label": response.equipo.empresa, "value": response.equipo.empresa} : null);
        setPlanta(Metodos.validarTextoVacio(response.equipo.planta) ? {"label": response.equipo.planta, "value": response.equipo.planta} : null);
        setMarca(Metodos.validarTextoVacio(response.equipo.marca) ? {"label": response.equipo.marca, "value": response.equipo.marca} : null);
        setModelo(Metodos.validarTextoVacio(response.equipo.modelo) ? {"label": response.equipo.modelo, "value": response.equipo.modelo} : null);
        setEstado(Metodos.validarTextoVacio(response.equipo.estado) ? {"label": response.equipo.estado, "value": response.equipo.estado} : null);
        setEstadofiltro(Metodos.validarTextoVacio(response.equipo.estado) ? {"label": response.equipo.estado, "value": response.equipo.estado} : null);
        setCondicion(Metodos.validarTextoVacio(response.equipo.condicion) ? {"label": response.equipo.condicion, "value": response.equipo.condicion} : null);

        if(response.equipo.idusuario === "GENERIC" || response.equipo.idusuario === "EXTERN"){
            setGenerico(true);
        }

        setRegistro({
            "id_equipoescritorio": Metodos.validarTextoVacio(response.equipo.id_equipoescritorio) ? response.equipo.id_equipoescritorio : null,
            "numeroserie": Metodos.validarTextoVacio(response.equipo.numeroserie) ? response.equipo.numeroserie : null,
            "estado": Metodos.validarTextoVacio(response.equipo.estado) ? {"label": response.equipo.estado, "value": response.equipo.estado} : null,
            "condicion": Metodos.validarTextoVacio(response.equipo.condicion) ? {"label": response.equipo.condicion, "value": response.equipo.condicion} : null,
            "empresa": Metodos.validarTextoVacio(response.equipo.empresa) ? {"label": response.equipo.empresa, "value": response.equipo.empresa} : null,
            "planta": Metodos.validarTextoVacio(response.equipo.planta) ? {"label": response.equipo.planta, "value": response.equipo.planta} : null,
            "negocio": Metodos.validarTextoVacio(response.equipo.negocio) ? response.equipo.negocio : null,
            "localidad": Metodos.validarTextoVacio(response.equipo.localidad) ? response.equipo.localidad : null,
            "pais": Metodos.validarTextoVacio(response.equipo.pais) ? response.equipo.pais : null,
            "nombreequipo": Metodos.validarTextoVacio(response.equipo.nombreequipo) ? response.equipo.nombreequipo : null,
            "tipoequipo": Metodos.validarTextoVacio(response.equipo.tipoequipo) ? {"label": response.equipo.tipoequipo, "value": response.equipo.tipoequipo} : null,
            "marca": Metodos.validarTextoVacio(response.equipo.marca) ? {"label": response.equipo.marca, "value": response.equipo.marca} : null,
            "modelo": Metodos.validarTextoVacio(response.equipo.modelo) ? {"label": response.equipo.modelo, "value": response.equipo.modelo} : null,
            "condicionred": Metodos.validarTextoVacio(response.equipo.condicionred) ? {"label": response.equipo.condicionred, "value": response.equipo.condicionred} : null,

            "idusuario": Metodos.validarTextoVacio(response.equipo.idusuario) ? response.equipo.idusuario  : null,
            "nombreusuario": Metodos.validarTextoVacio(response.equipo.nombreusuario) ? response.equipo.nombreusuario : null,
            "correo": Metodos.validarTextoVacio(response.equipo.correo) ? response.equipo.correo : null,
            "division": Metodos.validarTextoVacio(response.equipo.division) ? {"label": response.equipo.division, "value": response.equipo.division} : null,
            "divisionplanta": Metodos.validarTextoVacio(response.equipo.divisionplanta) ? response.equipo.divisionplanta : null,
            "divisionfecha": Metodos.validarTextoVacio(response.equipo.divisionfecha) ? response.equipo.divisionfecha : null,
            "gerenciaprincipal": Metodos.validarTextoVacio(response.equipo.gerenciaprincipal) ? {"label": response.equipo.gerenciaprincipal, "value": response.equipo.gerenciaprincipal} : null,
            "cargo": Metodos.validarTextoVacio(response.equipo.cargo) ? {"label": response.equipo.cargo, "value": response.equipo.cargo} : null,
            "cargorol": Metodos.validarTextoVacio(response.equipo.cargorol) ? response.equipo.cargorol : null,
            "centrocosto": Metodos.validarTextoVacio(response.equipo.centrocosto) ? {"label": response.equipo.centrocosto, "value": response.equipo.centrocosto} : null,
            "gerenciadirecta": Metodos.validarTextoVacio(response.equipo.gerenciadirecta) ? {"label": response.equipo.gerenciadirecta, "value": response.equipo.gerenciadirecta} : null,
            "subgerenciaarea": Metodos.validarTextoVacio(response.equipo.subgerenciaarea) ? {"label": response.equipo.subgerenciaarea, "value": response.equipo.subgerenciaarea} : null,
            "vip": Metodos.validarTextoVacio(response.equipo.vip) ? (response.equipo.vip === "YES") ? true : false : false,
            "administrador": Metodos.validarTextoVacio(response.equipo.administrador) ? (response.equipo.administrador === "YES") ? true : false : false,
            "justificacionadmin": Metodos.validarTextoVacio(response.equipo.justificacionadmin) ? response.equipo.justificacionadmin : null,
            "licenciaprincipal": Metodos.validarTextoVacio(response.equipo.licenciaprincipal) ? response.equipo.licenciaprincipal : null,
            "usuariomaestropersonal": Metodos.validarTextoVacio(response.equipo.usuariomaestropersonal) ? (response.equipo.usuariomaestropersonal === "YES") ? true : false : false,
                                
            "excluidoparches": Metodos.validarTextoVacio(response.equipo.excluidoparches) ? (response.equipo.excluidoparches === "YES") ? true : false : false,                    
            "motivoexcluidoparches": Metodos.validarTextoVacio(response.equipo.motivoexcluidoparches) ? response.equipo.motivoexcluidoparches : null,
            "usbdesbloqueado": Metodos.validarTextoVacio(response.equipo.usbdesbloqueado) ? (response.equipo.usbdesbloqueado === "YES") ? true : false : false,
            "motivousbdesbloqueado": Metodos.validarTextoVacio(response.equipo.motivousbdesbloqueado) ? response.equipo.motivousbdesbloqueado : null,

            "ordencompra": Metodos.validarTextoVacio(response.equipo.ordencompra) ? {"label": response.equipo.ordencompra, "value": response.equipo.ordencompra} : null,
            "direccionip": Metodos.validarTextoVacio(response.equipo.direccionip) ? response.equipo.direccionip : null,
            "discoduro": Metodos.validarTextoVacio(response.equipo.discoduro) ? {"label": response.equipo.discoduro, "value": response.equipo.discoduro} : null,
            "memoriaram": Metodos.validarTextoVacio(response.equipo.memoriaram) ? {"label": response.equipo.memoriaram, "value": response.equipo.memoriaram} : null,
            "tipoprocesador": Metodos.validarTextoVacio(response.equipo.tipoprocesador) ? {"label": response.equipo.tipoprocesador, "value": response.equipo.tipoprocesador} : null,
            "velocidadprocesador": Metodos.validarTextoVacio(response.equipo.velocidadprocesador) ? {"label": response.equipo.velocidadprocesador, "value": response.equipo.velocidadprocesador} : null,
            "docking": Metodos.validarTextoVacio(response.equipo.docking) ? (response.equipo.docking === "YES") ? true : false : false,
            "monitor": Metodos.validarTextoVacio(response.equipo.monitor) ? (response.equipo.monitor === "YES") ? true : false : false,
            "fecharecepcion": Metodos.validarTextoVacio(response.equipo.fecharecepcion) ? new Date(response.equipo.fecharecepcion) : null,
            "fechaentrega": Metodos.validarTextoVacio(response.equipo.fechaentrega) ? new Date(response.equipo.fechaentrega) : null,
            "fechaexpiracion": Metodos.validarTextoVacio(response.equipo.fechaexpiracion) ? new Date(response.equipo.fechaexpiracion) : null,
            "fechamantencion": Metodos.validarTextoVacio(response.equipo.fechamantencion) ? new Date(response.equipo.fechamantencion) : null,  
            "fechafueralinea": Metodos.validarTextoVacio(response.equipo.fechafueralinea) ? new Date(response.equipo.fechafueralinea) : null,  
            "ubicacion": Metodos.validarTextoVacio(response.equipo.ubicacion) ? response.equipo.ubicacion : null,                  
            "observaciones": Metodos.validarTextoVacio(response.equipo.observaciones) ? response.equipo.observaciones : null,

            "ultimousuario": Metodos.validarTextoVacio(response.equipo.ultimousuario) ? response.equipo.ultimousuario : null,
            "sistemaoperativo": Metodos.validarTextoVacio(response.equipo.sistemaoperativo) ? response.equipo.sistemaoperativo : null,
            "compilacion": Metodos.validarTextoVacio(response.equipo.compilacion) ? response.equipo.compilacion : null,
            "fechainvenratiosccm": Metodos.validarTextoVacio(response.equipo.fechainvenratiosccm) ? new Date(response.equipo.fechainvenratiosccm) : null,                    
            "diasultimoinventario": Metodos.validarTextoVacio(response.equipo.diasultimoinventario) ? response.equipo.diasultimoinventario : null,
            "nombreequiposccm": Metodos.validarTextoVacio(response.equipo.nombreequiposccm) ? response.equipo.nombreequiposccm : null,
            "modelosccm": Metodos.validarTextoVacio(response.equipo.modelosccm) ? response.equipo.modelosccm : null,
            "memoriaramsccm": Metodos.validarTextoVacio(response.equipo.memoriaramsccm) ? response.equipo.memoriaramsccm : null,
            "espaciolibresccm": Metodos.validarTextoVacio(response.equipo.espaciolibresccm) ? response.equipo.espaciolibresccm : null,
            "espaciototalsccm": Metodos.validarTextoVacio(response.equipo.espaciototalsccm) ? response.equipo.espaciototalsccm : null,
            "espaciodiscossccm": Metodos.validarTextoVacio(response.equipo.espaciodiscossccm) ? response.equipo.espaciodiscossccm : null,
            "mac": Metodos.validarTextoVacio(response.equipo.mac) ? response.equipo.mac : null,
            "ipsccm": Metodos.validarTextoVacio(response.equipo.ipsccm) ? response.equipo.ipsccm : null,
            "ultimoreinicio": Metodos.validarTextoVacio(response.equipo.ultimoreinicio) ? response.equipo.ultimoreinicio : null,

            "avestado": Metodos.validarTextoVacio(response.equipo.avestado) ? response.equipo.avestado : null,
            "avfechacontacto": Metodos.validarTextoVacio(response.equipo.avfechacontacto) ? response.equipo.avfechacontacto : null,
            "avpolitica": Metodos.validarTextoVacio(response.equipo.avpolitica) ? response.equipo.avpolitica : null,
            "avversionsensor": Metodos.validarTextoVacio(response.equipo.avversionsensor) ? response.equipo.avversionsensor : null,
            "ubestado": Metodos.validarTextoVacio(response.equipo.ubestado) ? response.equipo.ubestado : null,
            "ubfecha": Metodos.validarTextoVacio(response.equipo.ubfecha) ? response.equipo.ubfecha : null,
            "documentoentrega": Metodos.validarTextoVacio(response.equipo.documentoentrega) ? (response.equipo.documentoentrega === "YES") ? true : false : false,

            "modificadopor": response.equipo.modificadopor,
            "fechaupdate": Metodos.validarTextoVacio(response.equipo.fechaupdate) ? new Date(response.equipo.fechaupdate) : null,
            "fechaupdatesccm": Metodos.validarTextoVacio(response.equipo.fechaupdatesccm) ? new Date(response.equipo.fechaupdatesccm) : null                    
        });
    }
    async function obtenerDatosBD()
    {
        var empresaEquipo= "";
        var marcaEquipo = "";
        var numeroSerieEquipo = "";
        var nombreEquipoApi = "";
        var paisEquipo = "";
        var localidadEquipo = "";
        var estadoEquipo = "";

        await obtenerEquipoEscritorio(id, token).then((response) => {
            if(response.cod === 1)
            {
                validarPrivilegioModificacion(privEscribir, paisUser, localidadUser, "", response.equipo, setPrivModificar );
                validarPrivilegioModificacion(privEscribir, paisUser, localidadUser, "", response.equipo, validarPermiso );

                empresaEquipo = response.equipo.empresa;
                marcaEquipo = response.equipo.marca;
                numeroSerieEquipo = response.equipo.numeroserie;
                nombreEquipoApi = response.equipo.nombreequipo;
                paisEquipo = response.equipo.pais;
                localidadEquipo = response.equipo.localidad;
                estadoEquipo = response.equipo.estado;

                if(estadoEquipo === "ACTIVE") 
                {
                    validarEstado(true);
                    setPrivModificarEstado(true);
                }
                else {
                    validarEstado(false);
                    if(privEscribir === "2" || privEscribir === "3") setPrivModificarEstado(true);
                    else setPrivModificarEstado(false);
                }

                var doc = Metodos.validarTextoVacio(response.equipo.documentoentrega) ? (response.equipo.documentoentrega === "YES") ? true : false : false;
                cambiarDocEntrega(doc);

                mapResponseToRegistro(response);

                
            }
            else {
                swal("Error", response.mensaje, "error");
            }
        });

        await obtenerValores(privEscribir, paisUser, localidadUser, "DESKTOP", idiomaUser, token).then((response) => {
            if(response.cod === 1)
            {
                const val = {
                    "estados": response.estados,
                    "condiciones": response.condiciones,
                    "filtrocondiciones": response.filtrocondiciones,
                    "tiposequipo": response.tiposequipo,
                    "condicionesred": response.condicionesred,  
                    "empresas": response.empresas,
                    "todasempresas": response.todasempresas,
                    "plantas": response.plantas,
                    "filtroplantas": response.filtroplantas,  
                    "marcas": response.marcas,
                    "modelos": response.modelos,
                    "filtromodelos": response.filtromodelos, 

                    "ordenescompra": response.ordenescompra,
                    "discosduros": response.discosduros,
                    "memoriasram": response.memoriasram,
                    "tiposprocesador": response.tiposprocesador,
                    "velocidadesprocesador": response.velocidadesprocesador,

                    "divisiones": response.divisiones,
                    "gerenciasprincipales": response.gerenciasprincipales,
                    "gerenciasdirectas": response.gerenciasdirectas,
                    "subgerenciasarea": response.subgerenciasarea,
                    "cargos": response.cargos,
                    "centroscosto": response.centroscosto,
                    "traducciones": response.traducciones                  
                }; 

                let array = [];

                const listas = ["estados", "condiciones", "tiposequipo", "condicionesred", "empresas", "marcas", "ordenescompra",
                "discosduros", "memoriasram", "tiposprocesador", "velocidadesprocesador", "divisiones", "gerenciasprincipales", "gerenciasdirectas", "subgerenciasarea",
                "cargos", "centroscosto"];

                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                //CONDICIONES
                array = [];
                val.filtrocondiciones.map((u) => {
                    if(u.estado === estadoEquipo) array.push({"label": u.condicion, "value": u.condicion});
                })
                val.condiciones = array;

                //PLANTAS
                array = [];
                val.filtroplantas.map((u) => {
                    if(u.empresa === empresaEquipo) array.push({"label": u.planta, "value": u.planta});
                })
                val.plantas = array;

                //MODELOS
                array = [];
                val.filtromodelos.map((u) => {
                    if(u.marca === marcaEquipo) array.push({"label": u.modelo, "value": u.modelo});
                })
                val.modelos = array;


                if(privEscribir === "1" && privLeer == "2")
                {
                    if(localidadEquipo !== localidadUser){
                        array = [];
                        val["todasempresas"].map((u) => {
                            array.push({"label": u, "value": u});
                        })
                        val["empresas"] = array;
                    } 
                }

                if(privEscribir === "2" && privLeer == "3")
                {
                    if(paisEquipo !== paisUser){
                        array = [];
                        val["todasempresas"].map((u) => {
                            array.push({"label": u, "value": u});
                        })
                        val["empresas"] = array;
                    } 
                }

                setValores(val);
            }
        });

        await obtenerAplicaciones(numeroSerieEquipo, token).then((response) => {
            if(response.cod === 1)
            {
                setAplicaciones(response.aplicaciones);
            }
        });

        await obtenerAplicacionesBO(numeroSerieEquipo, token).then((response) => {
            if(response.cod === 1)
            {
                setAplicacionesBO(response.aplicacionesback);
            }
        });

        await obtenerAplicacionesSccm(nombreEquipoApi, token).then((response) => {
            if(response.cod === 1)
            {
                setAplicacionesSccm(response.aplicaciones);
            }
        });
    }

    async function obtenerRegistro()
    {
        var nombreEquipoApi = "";
        var estadoEquipo = "";

        await obtenerEquipoEscritorio(id, token).then((response) => {
            if(response.cod === 1)
            {
                nombreEquipoApi = response.equipo.nombreequipo;
                estadoEquipo = response.equipo.estado;

                if(estadoEquipo === "ACTIVE") 
                {
                    validarEstado(true);
                    setPrivModificarEstado(true);
                }
                else {
                    validarEstado(false);
                    if(privEscribir === "2" || privEscribir === "3") setPrivModificarEstado(true);
                    else setPrivModificarEstado(false);
                }

                validarPrivilegioModificacion(privEscribir, paisUser, localidadUser, "", response.equipo, setPrivModificar );
                validarPrivilegioModificacion(privEscribir, paisUser, localidadUser, "", response.equipo, validarPermiso );

                mapResponseToRegistro(response);

                
            }
            else {
                swal("Error", response.mensaje, "error");
            }
        });

        await obtenerAplicacionesSccm(nombreEquipoApi, token).then((response) => {
            if(response.cod === 1)
            {
                setAplicacionesSccm(response.aplicaciones);
            }
        });
    }

    async function buscarUsuario()
    {
        setGenerico(false);
        setBusqueda(true);
        setUsuarioMaestroPersonal(false);

        await valoresUsuario("", idUsuario, token).then((response) => {
            if(response.cod == 1)
            {
                const usuario = response.personal;
                setCorreo(usuario.correo)
                setNombreUsuario(usuario.nombre)
                setLicenciaPrincipal(usuario.licenciaprincipal)
                setDivision({"label": usuario.division, "value": usuario.division})
                setDivisionPlanta(usuario.divisionplanta)
                setDivisionFecha(usuario.divisionfecha)
                setCargo({"label": usuario.cargo, "value": usuario.cargo})
                setCargoRol(usuario.cargorol)
                setCentroCosto({"label": usuario.centrocosto, "value": usuario.centrocosto})
                setGerenciaPrincipal({"label": usuario.gerenciaprincipal, "value": usuario.gerenciaprincipal})
                setGerenciaDirecta({"label": usuario.gerenciadirecta, "value": usuario.gerenciadirecta})
                setSubGerenciaArea({"label": usuario.subgerenciaarea, "value": usuario.subgerenciaarea})
                setUsuarioMaestroPersonal(true);              
            }
            else swal("Search", response.mensaje, "warning");
        });
    }

    const busquedaUsuario = () => {
        if(idUsuario === "")
        {
            swal("Search Error", "No value has been entered to perform the search", "warning");
        }
        else if(idUsuario.toUpperCase() === "GENERIC" || idUsuario.toUpperCase() === "EXTERN"){   
            setGenerico(true);         
            setBusqueda(true);
            setUsuarioMaestroPersonal(false);
            swal("", "A Generic user has been selected", "info");
        }
        else buscarUsuario();        
    }

    const OnChangeIdUsuario = (event) => {
        setIdUsuario(event.target.value);        
    }

    async function registrarBitacora(datosActuales, dataAnterior) {
        let nombreusuario = sessionStorage.getItem("nombre");
    
        registrarBitacoraEquipoEscritorio(
          datosActuales,
          dataAnterior,
          id,
          nombreusuario,
          token
        ).then((response) => {
          console.log(response);
        });
      }

    const onSubmit = (data) => {
        

        if(validarDatos(data)){
            swal("Update Error", "Mandatory fields were submitted within the form with no value", "warning");
        }
        else {
            swal({
                title: "Update confirmation",
                text: "Do you want to update the registry? ",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion)
                {
                    const datosform = data;
                    let nombreusuariosesion = sessionStorage.getItem('nombre');
                    datosform.modificadopor = nombreusuariosesion;
                    datosform.fechaupdate = new Date();

                    datosform.idusuario = idUsuario;
                    datosform.nombreusuario = nombreUsuario;

                    datosform.correo = correo;
                    datosform.division = division;
                    datosform.divisionplanta = divisionplanta;
                    datosform.divisionfecha = divisionfecha;
                    datosform.cargo = cargo;
                    datosform.cargorol = cargorol;
                    datosform.centrocosto = centrocosto;
                    datosform.gerenciaprincipal = gerenciaprincipal;
                    datosform.gerenciadirecta = gerenciadirecta;
                    datosform.subgerenciaarea = subGerenciaArea;
                    datosform.licenciaprincipal = licenciaprincipal;
                    datosform.usuariomaestropersonal = usuarioMaestroPersonal;

                    datosform.excluidoparches = cambioExcluidoparches;
                    datosform.usbdesbloqueado = cambioUsbdesbloqueado;
                    datosform.docking = cambioDocking;
                    datosform.monitor = cambioMonitor;
                    datosform.vip = cambioVip;
                    datosform.administrador = cambioAdministrador;
                    
                    await actualizaEquipoEscritorio(datosform, registro, id, token).then((response) => {
                        if(response.cod === 1)
                        {
                            registrarBitacora(datosform, registro);
                            obtenerRegistro();                            
                            swal("Successful update", "", "success");
                        }
                        else if(response.cod === 0){
                            swal("Update error", response.mensaje, "error");
                        }
                        else {
                            swal("Update error", "An error occurred during the update. Please contact the administrator.", "error");
                        }
                    });  

                }
            })
        }



    }

    function validarDatos(data)
    {
        var errores = false;       
        
        if(idUsuario === "")
        {
            setErrorIdUsuario(true);
            errores = true;
        }
        else setErrorIdUsuario(false);

        if(nombreUsuario === "")
        {
            setErrorUsuario(true);
            errores = true;
        }
        else setErrorUsuario(false);

        if(data.numeroserie === null || data.numeroserie === "" || data.numeroserie === undefined) 
        {
            setErrorNumeroSerie(true);
            errores = true;
        }
        else setErrorNumeroSerie(false);

        if(data.empresa === null || data.empresa === "" || data.empresa === undefined) 
        {
            setErrorEmpresa(true);
            errores = true;
        }
        else setErrorEmpresa(false);

        if(data.planta === null || data.planta === "" || data.planta === undefined) 
        {
            setErrorPlanta(true);
            errores = true;
        }
        else setErrorPlanta(false);

        if(!validarTextoVacio(estado)) 
        {
            setErrorEstado(true);
            errores = true;
        }
        else setErrorEstado(false);

        if(!validarTextoVacio(condicion))
        {
            setErrorCondicion(true);
            errores = true;
        }
        else setErrorCondicion(false);

        if(data.nombreequipo === null || data.nombreequipo === "" || data.nombreequipo === undefined) 
        {
            setErrorNombreEquipo(true);
            errores = true;
        }
        else setErrorNombreEquipo(false);

        if(data.tipoequipo === null || data.tipoequipo === "" || data.tipoequipo === undefined) 
        {
            setErrorTipoEquipo(true);
            errores = true;
        }
        else setErrorTipoEquipo(false);

        if(data.marca === null || data.marca === "" || data.marca === undefined) 
        {
            setErrorMarca(true);
            errores = true;
        }
        else setErrorMarca(false);

        if(data.modelo === null || data.modelo === "" || data.modelo === undefined) 
        {
            setErrorModelo(true);
            errores = true;
        }
        else setErrorModelo(false);

        if(data.condicionred === null || data.condicionred === "" || data.condicionred === undefined) 
        {
            setErrorCondicionRed(true);
            errores = true;
        }
        else setErrorCondicionRed(false);

        return errores;
    }

    const cambioEstadoFiltro = (value) => {
        if(validarTextoVacio(value)){
            setEstadofiltro(value.value);            
            filtrarCondiciones(value.value);            
        } else {
            setEstadofiltro(""); 
            setCondicion("");           
        }
    }

    const cambioCondicion= (value) => { 
        if(validarTextoVacio(value)){
            setCondicion({"label": value.value, "value": value.value});
        } else {
            setCondicion("");
        }
    }

    function filtrarCondiciones(estado)
    {
        if(validarTextoVacio(estado))
        {
            console.log(estado)
            const filtrotipoArray = valores.filtrocondiciones;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.estado === estado) {
                        array.push({"label": p.condicion, "value": p.condicion});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.condiciones = array; 
            console.log(valoresNuevo.condiciones)
            setCondicion("");           
            setValores(valoresNuevo);
        }
    }

    const cambioEmpresa= (value) => { 
        if(value !== "" && value !== null){
            setEmpresa(value.value);            
            filtrarPlantas(value.value);            
        } else {
            setEmpresa(""); 
            setPlanta("");           
        }
    }

    const cambioPlanta= (value) => { 
        if(value !== "" && value !== null){
            setPlanta({"label": value.value, "value": value.value});
        } else {
            setPlanta("");
        }
    }

    function filtrarPlantas(empresa)
    {
        if(empresa !== "")
        {
            const filtrotipoArray = valores.filtroplantas;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.empresa === empresa) {
                        array.push({"label": p.planta, "value": p.planta});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.plantas = array; 
            setPlanta("");           
            setValores(valoresNuevo);
        }
    }

    const cambioMarca = (value) => { 
        if(value !== "" && value !== null){
            setMarca(value.value);            
            filtrarModelos(value.value);            
        } else {
            setMarca(""); 
            setModelo("");           
        }
    }

    const cambioModelo= (value) => { 
        if(value !== "" && value !== null){
            setModelo({"label": value.value, "value": value.value});
        } else {
            setModelo("");
        }
    }

    const cambioEstado= (value) => { 
        if(value !== "" && value !== null){
            setEstado({"label": value.value, "value": value.value});
        } else {
            setEstado("");
        }
    }

    function filtrarModelos(marca)
    {
        if(marca !== "")
        {
            const filtrotipoArray = valores.filtromodelos;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.marca === marca) {
                        array.push({"label": p.modelo, "value": p.modelo});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.modelos = array; 
            setModelo("");           
            setValores(valoresNuevo);
        }
    }

    const cambioNombreUsuario = (event) => {
        setNombreUsuario(event.target.value);
    }

    const cambioLicenciaPrincipal = (event) => {
        setLicenciaPrincipal(event.target.value);
    }

    const cambioCorreo = (event) => {
        setCorreo(event.target.value);
    }

    const cambioDivision = (value) => {
        if(value === "" || value === null || value === undefined) setDivision(null);
        else setDivision({"label": value.value, "value": value.value});        
    }

    const cambioDivisionPlanta = (event) => {
        setDivisionPlanta(event.target.value);
    }

    const cambioDivisionFecha = (event) => {
        setDivisionFecha(event.target.value);
    }

    const cambioCargo = (value) => {        
        if(value === "" || value === null || value === undefined) setCargo(null);
        else setCargo({"label": value.value, "value": value.value});       
    }

    const cambioCargoRol = (event) => {
        setCargoRol(event.target.value);
    }

    const cambioCentroCosto = (value) => {
        if(value === "" || value === null || value === undefined) setCentroCosto(null);
        else setCentroCosto({"label": value.value, "value": value.value});        
    }

    const cambioGerenciaPrincipal = (value) => {
        if(value === "" || value === null || value === undefined) setGerenciaPrincipal(null);
        else setGerenciaPrincipal({"label": value.value, "value": value.value});         
    }

    const cambioGerenciaDirecta = (value) => {
        if(value === "" || value === null || value === undefined) setGerenciaDirecta(null);
        else setGerenciaDirecta({"label": value.value, "value": value.value});         
    }

    const cambioSubGerencia = (value) => {
        if(value === "" || value === null || value === undefined) setSubGerenciaArea(null);
        else setSubGerenciaArea({"label": value.value, "value": value.value});  
    }

    const seccionSccm = () => {
        setSccm(true);
        setTabla(false);
        setTablaSccm(false);
        setTablaBO(false);
    }

    const seccionTabla = () => {
        setTabla(true);
        setSccm(false);
        setTablaSccm(false);
        setTablaBO(false);
    }

    const seccionTablaBO = () => {
        setTablaBO(true);
        setTabla(false);
        setSccm(false);
        setTablaSccm(false);
    }

    const seccionTablaSccm = () => {
        setTablaSccm(true);
        setTabla(false);
        setSccm(false);
        setTablaBO(false);
    }

    React.useEffect(() => {
        if (registro !== null) {            

            Opciones.campos.forEach(c => {
                setValue(c, registro[c], {shouldValidate: true,shouldDirty: true})
            });
            
        }
        else obtenerDatosBD();
    }, [registro, valores]);

    if(registro === null || valores === null) return (<Loading />)
    else
    return (
        <>
            <div className="divimagen">
                <form
                    style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                    autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                >
                    <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}}>
                        <div className="divizq-eq-prin">
                            <div className="containerequipoprin-izq" style={{float: 'left !important'}}>

                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-id_equipoescritorio">ID Desktop
                                        {
                                            (valores.traducciones.id_equipoescritorio === "" || valores.traducciones.id_equipoescritorio === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.id_equipoescritorio}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                            control={control}
                                            id="i-id_equipoescritorio"
                                            key="k-id_equipoescritorio"
                                            defaultValue={registro ? registro.id_equipoescritorio : ''}
                                            name='id_equipoescritorio'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-tipoequipo">Serial Number
                                        {
                                            (valores.traducciones.tipoequipo === "" || valores.traducciones.tipoequipo === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.tipoequipo}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                            control={control}
                                            id="i-numeroserie"
                                            key="k-numeroserie"
                                            defaultValue={registro ? registro.numeroserie : ''}
                                            name='numeroserie'
                                            style={(errorNumeroSerie) ? { borderColor: '#f90000'} : {}} 
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-estado">State<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.estado === "" || valores.traducciones.estado === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.estado}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.estados}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value);
                                                    cambioEstado(value);
                                                    cambioEstadoFiltro(value);                                                
                                                }}  
                                                value={estado}
                                                isClearable={true}
                                                isDisabled={(privModificar && privModificarEstado) ? false : true}
                                                defaultValue={(estado) ? estado : ""}
                                                styles={(errorEstado) ? style : (privModificarEstado) ? styleNormal : styleDisable}
                                            />} 
                                            control={control}
                                            id="i-estado"
                                            key="k-estado"                                    
                                            name='estado'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-condicion">Condition<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.condicion === "" || valores.traducciones.condicion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.condicion}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                            options={valores.condiciones}
                                            placeholder=""
                                            onChange={(value) => {
                                                props.onChange(value);
                                                cambioCondicion(value);                                                
                                            }}  
                                            isClearable={true}
                                            value={condicion}
                                            isDisabled={(validarTextoVacio(estadofiltro)) ? (privModificar) ? false : true : true}
                                            defaultValue={(registro) ? registro.condicion : ""}
                                            styles={(errorCondicion) ? style : (privModificar) ? styleNormal : styleDisable}                                          
                                            />} 
                                            control={control}
                                            id="i-condicion"
                                            key="k-condicion"                                    
                                            name='condicion'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-empresa">Enterprise<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.empresa === "" || valores.traducciones.empresa === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.empresa}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.empresas}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value);
                                                    cambioEmpresa(value);
                                                    
                                                }}  
                                                isClearable={true}
                                                isDisabled={(privModificar) ? false : true}
                                                defaultValue={(registro) ? registro.empresa : ""}
                                                styles={(errorEmpresa) ? style : (privModificar) ? styleNormal : styleDisable}
                                            />} 
                                            control={control}
                                            id="i-empresa"
                                            key="k-empresa"                                    
                                            name='empresa'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-planta">Plant<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.planta === "" || valores.traducciones.planta === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.planta}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.plantas}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value)
                                                    cambioPlanta(value);  
                                                    
                                                }}  
                                                isClearable={true}
                                                //isDisabled={(privModificar) ? false : true}
                                                value={planta}
                                                defaultValue={(planta) ? planta : ""}
                                                isDisabled={(empresa !== "") ? (privModificar) ? false : true : true}
                                                styles={(errorPlanta) ? style : (privModificar) ? styleNormal : styleDisable}
                                            />} 
                                            control={control}
                                            id="i-planta"
                                            key="k-planta"                                    
                                            name='planta'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-negocio">Business
                                            {
                                                (valores.traducciones.negocio === "" || valores.traducciones.negocio === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.negocio}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-negocio"
                                                key="k-negocio"
                                                defaultValue={registro ? registro.negocio : ''}
                                                name='negocio'                                                
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-localidad">Location
                                            {
                                                (valores.traducciones.localidad === "" || valores.traducciones.localidad === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.localidad}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-localidad"
                                                key="k-localidad"
                                                defaultValue={registro ? registro.localidad : ''}
                                                name='localidad'                                                
                                            /> 
                                        </div>
                                    </div>
                                <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-pais">Country
                                            {
                                                (valores.traducciones.pais === "" || valores.traducciones.pais === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.pais}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-pais"
                                                key="k-pais"
                                                defaultValue={registro ? registro.pais : ''}
                                                name='pais'                                                
                                            /> 
                                        </div>
                                </div>
                               
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-tipoequipo">Desktop Type<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.tipoequipo === "" || valores.traducciones.tipoequipo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.tipoequipo}</span>
                                                    </div>
                                                )
                                            }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.tiposequipo}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value)                                        
                                                }}  
                                                isClearable={true}
                                                isDisabled={(privModificar && privModificarEstado) ? false : true}
                                                defaultValue={(registro) ? registro.tipoequipo : ""}
                                                styles={(errorTipoEquipo) ? style : (privModificar && privModificarEstado) ? styleNormal : styleDisable}
                                            />} 
                                            control={control}
                                            id="i-tipoequipo"
                                            key="k-tipoequipo"                                    
                                            name='tipoequipo'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-marca">Brand<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.marca === "" || valores.traducciones.marca === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.marca}</span>
                                                    </div>
                                                )
                                            }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.marcas}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value)
                                                    cambioMarca(value); 
                                                    
                                                }}  
                                                isClearable={true}
                                                isDisabled={(privModificar && privModificarEstado) ? false : true}
                                                defaultValue={(registro) ? registro.marca : ""}
                                                styles={(errorMarca) ? style : (privModificar && privModificarEstado) ? styleNormal : styleDisable}
                                            />} 
                                            control={control}
                                            id="i-marca"
                                            key="k-marca"                                    
                                            name='marca'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-modelo">Model<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.modelo === "" || valores.traducciones.modelo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.modelo}</span>
                                                    </div>
                                                )
                                            }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.modelos}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value)
                                                    cambioModelo(value);                                            
                                                }}  
                                                value={modelo}
                                                defaultValue={modelo}
                                                isDisabled={(marca === "") ? false : (privModificar && privModificarEstado) ? false : true}
                                                isClearable={true}
                                                styles={(errorModelo) ? style : (privModificar && privModificarEstado) ? styleNormal : styleDisable}
                                            />} 
                                            control={control}
                                            id="i-modelo"
                                            key="k-modelo"                                    
                                            name='modelo'
                                            defaultValue={registro ? registro.modelo : ''}
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-nombreequipo">Desktop Name<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.nombreequipo === "" || valores.traducciones.nombreequipo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.nombreequipo}</span>
                                                    </div>
                                                )
                                            }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                            control={control}
                                            id="i-nombreequipo"
                                            key="k-nombreequipo"
                                            defaultValue={registro ? registro.nombreequipo : ''}
                                            name='nombreequipo'
                                            style={(errorNombreEquipo) ? { borderColor: '#f90000'} : {}} 
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-condicionred">Network Condition<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.condicionred === "" || valores.traducciones.condicionred === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.condicionred}</span>
                                                    </div>
                                                )
                                            }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                            options={valores.condicionesred}
                                            placeholder=""
                                            onChange={(value) => {
                                                props.onChange(value)
                                                
                                            }}  
                                            isClearable={true}
                                            isDisabled={(privModificar) ? false : true}
                                            defaultValue={(registro) ? registro.condicionred : ""}
                                            styles={(errorCondicionRed) ? style : (privModificar) ? styleNormal : styleDisable}
                                            />} 
                                            control={control}
                                            id="i-condicionred"
                                            key="k-condicionred"                                    
                                            name='condicionred'
                                        />                              
                                    </div>
                                </div>
                        
                                <br></br>                        
                                <hr style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}}></hr>

                                <div className="row">
                                    <div className="col-der-servidor-doble">
                                        <div className="col-doble-servidor-nom">
                                            <label htmlFor="i-excluidoparches">Excluded patches
                                            {
                                                (valores.traducciones.excluidoparches === "" || valores.traducciones.excluidoparches === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.excluidoparches}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-doble-servidor-campo">
                                            <input
                                                type="checkbox"
                                                id="i-excluidoparches"
                                                key="k-excluidoparches" 
                                                name="excluidoparches"
                                                placeholder="luo"
                                                value={true}                                    
                                                ref={register}
                                                onChange={eCambioExcluidoparches}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-izq-servidor-doble">
                                        <div className="col-25-servidor">
                                            <label htmlFor="i-motivoexcluidoparches">Cause
                                            </label>
                                        </div>
                                        <div className="col-75-servidor">
                                            <Controller
                                                as={<input className="bootstrap-input" type='text' autoComplete="off"  />}                                
                                                control={control}
                                                id="i-motivoexcluidoparches"
                                                key="k-motivoexcluidoparches"
                                                defaultValue={registro ? registro.motivoexcluidoparches : ''}
                                                name='motivoexcluidoparches'                                        
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-der-servidor-doble">
                                        <div className="col-doble-servidor-nom">
                                            <label htmlFor="i-usbdesbloqueado">Unlocked USB
                                            {
                                                (valores.traducciones.usbdesbloqueado === "" || valores.traducciones.usbdesbloqueado === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.usbdesbloqueado}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-doble-servidor-campo">
                                            <input
                                                type="checkbox"
                                                id="i-usbdesbloqueado"
                                                key="k-usbdesbloqueado" 
                                                name="usbdesbloqueado"
                                                placeholder="luo"
                                                value={true}                                    
                                                ref={register}
                                                onChange={eCambioUsbdesbloqueado}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-izq-servidor-doble">
                                        <div className="col-25-servidor">
                                            <label htmlFor="i-motivousbdesbloqueado">Cause
                                            </label>
                                        </div>
                                        <div className="col-75-servidor">
                                            <Controller
                                                as={<input className="bootstrap-input" type='text' autoComplete="off"  />}                                
                                                control={control}
                                                id="i-motivousbdesbloqueado"
                                                key="k-motivousbdesbloqueado"
                                                defaultValue={registro ? registro.motivousbdesbloqueado : ''}
                                                name='motivousbdesbloqueado'                                        
                                            />
                                        </div>
                                    </div>
                                </div>
                                                
                                <hr style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}}></hr>                        
                                <br></br>

                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ordencompra">Purchase order
                                        {
                                            (valores.traducciones.ordencompra === "" || valores.traducciones.ordencompra === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ordencompra}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.ordenescompra}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value)
                                                    
                                                }}  
                                                isClearable={true}
                                                isDisabled={(privModificar) ? false : true}
                                                defaultValue={(registro) ? registro.ordencompra : ""}
                                                styles={(privModificar) ? styleNormal : styleDisable}                                       
                                            />} 
                                            control={control}
                                            id="i-ordencompra"
                                            key="k-ordencompra"                                    
                                            name='ordencompra'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-direccionip">IP Address
                                        {
                                            (valores.traducciones.direccionip === "" || valores.traducciones.direccionip === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ordencompra}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                            control={control}
                                            id="i-direccionip"
                                            key="k-direccionip"
                                            defaultValue={registro ? registro.direccionip : ''}
                                            name='direccionip'                                
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-discoduro">HDD
                                        {
                                            (valores.traducciones.discoduro === "" || valores.traducciones.discoduro === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.discoduro}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.discosduros}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value)
                                                    
                                                }}  
                                                isClearable={true}
                                                isDisabled={(privModificar) ? false : true}
                                                defaultValue={(registro) ? registro.discoduro : ""}
                                                styles={(privModificar) ? styleNormal : styleDisable}                                       
                                            />} 
                                            control={control}
                                            id="i-discoduro"
                                            key="k-discoduro"                                    
                                            name='discoduro'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-memoriaram">Ram
                                        {
                                            (valores.traducciones.memoriaram === "" || valores.traducciones.memoriaram === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.memoriaram}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.memoriasram}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value)
                                                    
                                                }}  
                                                isClearable={true}
                                                isDisabled={(privModificar) ? false : true}
                                                defaultValue={(registro) ? registro.memoriaram : ""}
                                                styles={(privModificar) ? styleNormal : styleDisable}                                       
                                            />} 
                                            control={control}
                                            id="i-memoriaram"
                                            key="k-memoriaram"                                    
                                            name='memoriaram'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-tipoprocesador">Processor Type
                                        {
                                            (valores.traducciones.tipoprocesador === "" || valores.traducciones.tipoprocesador === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.tipoprocesador}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.tiposprocesador}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value)
                                                    
                                                }}  
                                                isClearable={true}
                                                isDisabled={(privModificar) ? false : true}
                                                defaultValue={(registro) ? registro.tipoprocesador : ""}
                                                styles={(privModificar) ? styleNormal : styleDisable}                                      
                                            />} 
                                            control={control}
                                            id="i-tipoprocesador"
                                            key="k-tipoprocesador"                                    
                                            name='tipoprocesador'
                                        />                              
                                    </div>
                                </div>
                                <div className="row" style={{display: 'none'}}>
                                    <div className="col-25">
                                        <label htmlFor="i-velocidadprocesador">Processor Speed
                                        
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.velocidadesprocesador}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value)
                                                    
                                                }}  
                                                isClearable={true}
                                                isDisabled={(privModificar) ? false : true}
                                                defaultValue={(registro) ? registro.velocidadprocesador : ""}
                                                styles={(privModificar) ? styleNormal : styleDisable}                                     
                                            />} 
                                            control={control}
                                            id="i-velocidadprocesador"
                                            key="k-velocidadprocesador"                                    
                                            name='velocidadprocesador'
                                        />                              
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-docking">Docking
                                        {
                                            (valores.traducciones.docking === "" || valores.traducciones.docking === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.docking}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <input
                                            disabled={(!privModificar) ? 'disabled' : ''}
                                            type="checkbox"
                                            id="i-docking"
                                            key="k-docking" 
                                            name="docking"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                            onChange={eCambioDocking}
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-monitor">Monitor
                                        {
                                            (valores.traducciones.monitor === "" || valores.traducciones.monitor === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.monitor}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <input     
                                            disabled={(!privModificar) ? 'disabled' : ''}                                     
                                            type="checkbox"
                                            id="i-monitor"
                                            key="k-monitor" 
                                            name="monitor"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                            onChange={eCambioMonitor}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-fecharecepcion">Reception Date (YYYY/MM/DD)
                                        {
                                            (valores.traducciones.fecharecepcion === "" || valores.traducciones.fecharecepcion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.fecharecepcion}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            name="fecharecepcion"
                                            id="i-fecharecepcion"
                                            className="bootstrap-input"
                                            control={control}
                                            defaultValue={registro ? registro.fecharecepcion : ''}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    disabled={(!privModificar) ? 'disabled' : ''}
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}
                                                    locale="es"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-fechaentrega">Delivery Date (YYYY/MM/DD)
                                        {
                                            (valores.traducciones.fechaentrega === "" || valores.traducciones.fechaentrega === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.fechaentrega}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            name="fechaentrega"
                                            id="i-fechaentrega"
                                            className="bootstrap-input"
                                            control={control}
                                            defaultValue={registro ? registro.fechaentrega : ''}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    disabled={(!privModificar) ? 'disabled' : ''}
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}
                                                    locale="es"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-fechaentrega">Warranty Expiration Date (YYYY/MM/DD)
                                        {
                                            (valores.traducciones.fechaexpiracion === "" || valores.traducciones.fechaexpiracion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.fechaexpiracion}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            name="fechaexpiracion"
                                            id="i-fechaexpiracion"
                                            className="bootstrap-input"
                                            control={control}
                                            defaultValue={registro ? registro.fechaexpiracion : ''}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    disabled={(!privModificar) ? 'disabled' : ''}
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}
                                                    locale="es"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                            
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-fechamantencion">Maintenance Date (YYYY/MM/DD)
                                        {
                                            (valores.traducciones.fechamantencion === "" || valores.traducciones.fechamantencion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.fechamantencion}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            name="fechamantencion"
                                            id="i-fechamantencion"
                                            className="bootstrap-input"
                                            control={control}
                                            defaultValue={registro ? registro.fechamantencion : ''}
                                            render={({ onChange, value }) => (
                                                <DatePicker                                            
                                                    disabled={(!privModificar) ? 'disabled' : ''}
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}
                                                    locale="es"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-fechafueralinea">Offline Date (YYYY/MM/DD)
                                        {
                                            (valores.traducciones.fechafueralinea === "" || valores.traducciones.fechafueralinea === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.fechafueralinea}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            name="fechafueralinea"
                                            id="i-fechafueralinea"
                                            className="bootstrap-input"
                                            control={control}
                                            defaultValue={registro ? registro.fechafueralinea : ''}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    disabled={(!privModificar) ? 'disabled' : ''}
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}
                                                    locale="es"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ubicacion">Ubication
                                        {
                                            (valores.traducciones.ubicacion === "" || valores.traducciones.ubicacion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ubicacion}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                            control={control}
                                            id="i-ubicacion"
                                            key="k-ubicacion"
                                            defaultValue={registro ? registro.ubicacion : ''}
                                            name='ubicacion'                                
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-observaciones">Observation
                                        {
                                            (valores.traducciones.observaciones === "" || valores.traducciones.observaciones === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.observaciones}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<textarea disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} rows="3" cols="50"></textarea>}
                                            control={control}
                                            id="i-observaciones"
                                            key="k-observaciones"
                                            defaultValue={registro ? registro.observaciones : ''}
                                            name='observaciones'
                                        />                             
                                    </div>
                                </div> 

                            </div>
                        </div>

                        <div className="divder-eq-prin">
                            <div className="containerequipoprin-der" style={{float: 'right !important'}}>

                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-idusuario">User ID<label style={{color: 'red'}}>{"*"}</label> (GENERIC / EXTERN)
                                        {
                                            (valores.traducciones.idusuario === "" || valores.traducciones.idusuario === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.idusuario}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <>
                                        <Controller
                                            render={(props) =>
                                                <input 
                                                    disabled={(!privModificar) ? 'disabled' : ''} 
                                                    className={((privModificar) ? "bootstrap-input-busqueda" : "bootstrap-input-busqueda-disabled")}
                                                    type='text'  
                                                    onChange={(value) => {
                                                        props.onChange(value);
                                                        OnChangeIdUsuario(value);                                             
                                                    }}
                                                    style={(errorIdUsuario) ? { borderColor: '#f90000'} : {}} 
                                                    defaultValue={registro ? registro.idusuario : ''}
                                                />                                                                           
                                            }                                                                          
                                            control={control}
                                            id="i-idusuario"
                                            key="k-idusuario"                                    
                                            name='idusuario'                 
                                        />
                                        {
                                            (privModificar) ? (
                                                <button type="button" className="boton-buscarusuario tooltipboton" onClick={busquedaUsuario}>
                                                    <FcIcons.FcSearch />
                                                    <span className="tooltiptextboton">{"Search"}</span> 
                                                </button>
                                            ) : (<></>)
                                        }
                                        </>                      
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-nombreusuario">User Name<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.nombreusuario === "" || valores.traducciones.nombreusuario === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.nombreusuario}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) =>
                                                <input 
                                                    disabled={(!privModificar) ? 'disabled' : ''} 
                                                    className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}
                                                    type='text'  
                                                    onChange={(value) => {
                                                        props.onChange(value);  
                                                        cambioNombreUsuario(value);                                                                                          
                                                    }}
                                                    style={(errorUsuario) ? { borderColor: '#f90000'} : {}} 
                                                    value={nombreUsuario}               
                                                    //defaultValue={(registro) ? registro.nombreusuario : ""}                             
                                                />                                                                           
                                            }                                       
                                            control={control}
                                            id="i-nombreusuario"
                                            key="k-nombreusuario"                                    
                                            name='nombreusuario'
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-correo">Email
                                        {
                                            (valores.traducciones.correo === "" || valores.traducciones.correo === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.correo}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) =>
                                                <input 
                                                    disabled={(!privModificar) ? 'disabled' : ''} 
                                                    className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}
                                                        type='text'  
                                                    onChange={(value) => {
                                                        props.onChange(value);  
                                                        cambioCorreo(value);                                                                                          
                                                    }}
                                                    value={correo}                                            
                                                />                                                                           
                                            }                                                                          
                                            control={control}
                                            id="i-correo"
                                            key="k-correo"                                    
                                            name='correo'
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-division">Division
                                        {
                                            (valores.traducciones.division === "" || valores.traducciones.division === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.division}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.divisiones}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value);
                                                    cambioDivision(value);                                                    
                                                }}  
                                                isClearable={true}
                                                isDisabled={(generico) ? (privModificar) ? false : true : true}
                                                styles={(generico) ? (privModificar) ? styleNormal : styleDisable : styleDisable}  
                                                value={division}
                                            />} 
                                            control={control}
                                            id="i-division"
                                            key="k-division"                                    
                                            name='division'
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-divisionplanta">Division Plant
                                        {
                                            (valores.traducciones.divisionplanta === "" || valores.traducciones.divisionplanta === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.divisionplanta}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) =>
                                                <input 
                                                    disabled={(!privModificar) ? 'disabled' : ''} 
                                                    className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}
                                                        type='text'  
                                                    onChange={(value) => {
                                                        props.onChange(value);  
                                                        cambioDivisionPlanta(value);                                                                                          
                                                    }}
                                                    value={divisionplanta}                                            
                                                />                                                                           
                                            }                                                                          
                                            control={control}
                                            id="i-divisionplanta"
                                            key="k-divisionplanta"                                    
                                            name='divisionplanta'
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-divisionfecha">Copy Date
                                        {
                                            (valores.traducciones.divisionfecha === "" || valores.traducciones.divisionfecha === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.divisionfecha}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) =>
                                                <input 
                                                    disabled={(!privModificar) ? 'disabled' : ''} 
                                                    className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}
                                                        type='text'  
                                                    onChange={(value) => {
                                                        props.onChange(value);  
                                                        cambioDivisionFecha(value);                                                                                          
                                                    }}
                                                    value={divisionfecha}                                            
                                                />                                                                           
                                            }                                                                          
                                            control={control}
                                            id="i-divisionfecha"
                                            key="k-divisionfecha"                                    
                                            name='divisionfecha'
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-gerenciaprincipal">Principal Management
                                        {
                                            (valores.traducciones.gerenciaprincipal === "" || valores.traducciones.gerenciaprincipal === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.gerenciaprincipal}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.gerenciasprincipales}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value);
                                                    cambioGerenciaPrincipal(value);
                                                }}  
                                                isDisabled={(generico) ? (privModificar) ? false : true : true}
                                                isClearable={true}
                                                styles={(generico) ? (privModificar) ? styleNormal : styleDisable : styleDisable} 
                                                value={gerenciaprincipal}                                                                                      
                                            />} 
                                            control={control}
                                            id="i-gerenciaprincipal"
                                            key="k-gerenciaprincipal"                                    
                                            name='gerenciaprincipal'
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-gerenciadirecta">Direct Management
                                        {
                                            (valores.traducciones.gerenciadirecta === "" || valores.traducciones.gerenciadirecta === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.gerenciadirecta}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.gerenciasdirectas}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value);
                                                    cambioGerenciaDirecta(value);
                                                }}  
                                                isClearable={true}
                                                isDisabled={(generico) ? (privModificar) ? false : true : true}
                                                styles={(generico) ? (privModificar) ? styleNormal : styleDisable : styleDisable}        
                                                value={gerenciadirecta}                                
                                            />} 
                                            control={control}
                                            id="i-gerenciadirecta"
                                            key="k-gerenciadirecta"                                    
                                            name='gerenciadirecta'
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-subgerenciasarea">Area Sub Management
                                        {
                                            (valores.traducciones.subgerenciaarea === "" || valores.traducciones.subgerenciaarea === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.subgerenciaarea}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.subgerenciasarea}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value);
                                                    cambioSubGerencia(value);
                                                }}  
                                                isClearable={true}
                                                isDisabled={(generico) ? (privModificar) ? false : true : true}
                                                styles={(generico) ? (privModificar) ? styleNormal : styleDisable : styleDisable} 
                                                value={subGerenciaArea}                                       
                                            />} 
                                            control={control}
                                            id="i-subgerenciaarea"
                                            key="k-subgerenciaarea"                                    
                                            name='subgerenciaarea'
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-cargorol">Position Role
                                        {
                                            (valores.traducciones.cargorol === "" || valores.traducciones.cargorol === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.cargorol}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) =>
                                                <input 
                                                    disabled={(!privModificar) ? 'disabled' : ''} 
                                                    className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}
                                                        type='text'  
                                                    onChange={(value) => {
                                                        props.onChange(value);  
                                                        cambioCargoRol(value);                                                                                          
                                                    }}
                                                    value={cargorol}                                            
                                                />                                                                           
                                            }                                                                          
                                            control={control}
                                            id="i-cargorol"
                                            key="k-cargorol"                                    
                                            name='cargorol'
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-cargo">Position
                                        {
                                            (valores.traducciones.cargo === "" || valores.traducciones.cargo === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.cargo}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.cargos}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value);
                                                    cambioCargo(value);
                                                }}  
                                                isClearable={true}
                                                isDisabled={(generico) ? (privModificar) ? false : true : true}
                                                styles={(generico) ? (privModificar) ? styleNormal : styleDisable : styleDisable}  
                                                value={cargo}                                        
                                            />} 
                                            control={control}
                                            id="i-cargo"
                                            key="k-cargo"                                    
                                            name='cargo'
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-centrocosto">Cost Center
                                        {
                                            (valores.traducciones.centrocosto === "" || valores.traducciones.centrocosto === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.centrocosto}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.centroscosto}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value);
                                                    cambioCentroCosto(value);
                                                }}  
                                                isClearable={true}
                                                isDisabled={(generico) ? (privModificar) ? false : true : true}
                                                styles={(generico) ? (privModificar) ? styleNormal : styleDisable : styleDisable}  
                                                value={centrocosto}                                        
                                            />} 
                                            control={control}
                                            id="i-centrocosto"
                                            key="k-centrocosto"                                    
                                            name='centrocosto'
                                        />                           
                                    </div>
                                </div>
                                {/*<div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-licenciaprincipal">O365 License
                                        {
                                            (valores.traducciones.licenciaprincipal === "" || valores.traducciones.licenciaprincipal === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.licenciaprincipal}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) =>
                                                <input 
                                                    disabled={(!generico) ? 'disabled' : privModificar ? '' : 'disabled'} 
                                                    className={((generico) ? privModificar ? "bootstrap-input" : "bootstrap-input-disable" : "bootstrap-input-disable")}
                                                        type='text'  
                                                    onChange={(value) => {
                                                        props.onChange(value);  
                                                        cambioLicenciaPrincipal(value);                                                                                          
                                                    }}
                                                    value={licenciaprincipal}                                            
                                                />                                                                           
                                            }                                                                          
                                            control={control}
                                            id="i-licenciaprincipal"
                                            key="k-licenciaprincipal"                                    
                                            name='licenciaprincipal'
                                        />                           
                                    </div>
                                        </div>*/}
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-vip">VIP
                                        {
                                            (valores.traducciones.vip === "" || valores.traducciones.vip === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.vip}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <input
                                            disabled={(!privModificar) ? 'disabled' : ''}
                                            type="checkbox"
                                            id="i-vip"
                                            key="k-vip" 
                                            name="vip"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                            onChange={eCambioVip}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-administrador">Administrator
                                        {
                                            (valores.traducciones.administrador === "" || valores.traducciones.administrador === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.administrador}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <input
                                            disabled={(!privModificar) ? 'disabled' : ''}
                                            type="checkbox"
                                            id="i-administrador"
                                            key="k-administrador" 
                                            name="administrador"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                            onChange={eCambioAdministrador}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-justificacionadmin">Administrator Justification
                                        {
                                            (valores.traducciones.justificacionadmin === "" || valores.traducciones.justificacionadmin === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.justificacionadmin}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<textarea disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} rows="3" cols="50"></textarea>}
                                            control={control}
                                            id="i-justificacionadmin"
                                            key="k-justificacionadmin"
                                            defaultValue={registro ? registro.justificacionadmin : ''}
                                            name='justificacionadmin'                                    
                                        />                           
                                    </div>
                                </div>                                 
                                <div className="row" style={{display: "none"}}>
                                    <div className="col-25">
                                        <label htmlFor="i-usuariomaestropersonal">Employee Master User
                                        {
                                            (valores.traducciones.usuariomaestropersonal === "" || valores.traducciones.usuariomaestropersonal === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.usuariomaestropersonal}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <input
                                            disabled={'disabled'}
                                            type="checkbox"
                                            id="i-usuariomaestropersonal"
                                            key="k-usuariomaestropersonal" 
                                            name="usuariomaestropersonal"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-documentoentrega">Assignment Document</label>
                                    </div>
                                    <div className="col-75">
                                        {
                                            (docEntrega) ? (<input disabled={'disabled'} type="checkbox" id="i-documentoentrega" checked/> )
                                            : (<input disabled={'disabled'} type="checkbox" id="i-documentoentrega" /> )
                                        }
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="row">
                                    <div className="col-25">
                                        <label  htmlFor="i-modificadopor">Modified by</label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                          
                                            control={control}
                                            id="i-modificadopor"
                                            key="k-modificadopor"
                                            defaultValue={registro ? registro.modificadopor : ''}
                                            name='modificadopor'                                                        
                                        />                              
                                    </div>
                                </div>    
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-fechaupdate">Modification Date (YYYY/MM/DD)</label>
                                    </div>
                                    <div className="col-75">
                                         <Controller
                                            name="fechaupdate"
                                            id="i-fechaupdate"
                                            className="bootstrap-input-disable"
                                            control={control}
                                            defaultValue={registro ? registro.fechaupdate : ''}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    disabled={'disabled'}
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className="bootstrap-input-disable"
                                                    locale="es"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                                       
                                <br></br>
                                <br></br>
                                <div className="divizq-eq-boton">
                                    <div className="divboton-eq-boton">
                                        <button type="button" className="boton-sccm" onClick={seccionSccm}>Other Sources</button>
                                    </div>                                
                                </div>
                                <div className="divizq-eq-boton">
                                    <div className="divboton-eq-boton">
                                        <button type="button" className="boton-sccm" onClick={seccionTabla}>Apps</button>
                                    </div>
                                </div>
                                <div className="divder-eq-boton">
                                    <div className="divboton-eq-boton">
                                        <button type="button" className="boton-sccm" onClick={seccionTablaBO}>AppsBO</button>
                                    </div>
                                </div>
                                <div className="divder-eq-boton">
                                    <div className="divboton-eq-boton">
                                        <button type="button" className="boton-sccm" onClick={seccionTablaSccm}>SCCM Apps</button>
                                    </div>
                                </div>
                                <br></br>
                                {
                                    (sccm) ? (
                                        <div className="containerotros">
                                            <div className="info-equiposccm">
                                                <label style={{fontWeight: 'bold'}}>SCCM Information</label>
                                                <hr></hr>
                                                <div className="divizq-eq">
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-fechaupdatesccm">Copy Date
                                                            {
                                                                (valores.traducciones.fechaupdatesccm === "" || valores.traducciones.fechaupdatesccm === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.fechaupdatesccm}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label>
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                name="fechaupdatesccm"
                                                                id="i-fechaupdatesccm"
                                                                className="bootstrap-input-disable"
                                                                control={control}
                                                                defaultValue={registro ? registro.fechaupdatesccm : ''}
                                                                render={({ onChange, value }) => (
                                                                    <DatePicker
                                                                        disabled={'disabled'}
                                                                        selected={value}
                                                                        dateFormat="yyyy/MM/dd"
                                                                        autoComplete="off"
                                                                        onChange={onChange}
                                                                        className="bootstrap-input-disable"
                                                                        locale="es"
                                                                    />
                                                                )}
                                                            /> 
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-fechainvenratiosccm">Inventory Date
                                                            {
                                                                (valores.traducciones.fechainvenratiosccm === "" || valores.traducciones.fechainvenratiosccm === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.fechainvenratiosccm}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label>
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                name="fechainvenratiosccm"
                                                                id="i-fechainvenratiosccm"
                                                                className="bootstrap-input-disable"
                                                                control={control}
                                                                defaultValue={registro ? registro.fechainvenratiosccm : ''}
                                                                render={({ onChange, value }) => (
                                                                    <DatePicker
                                                                        disabled={'disabled'}
                                                                        selected={value}
                                                                        dateFormat="yyyy/MM/dd"
                                                                        autoComplete="off"
                                                                        onChange={onChange}
                                                                        className="bootstrap-input-disable"
                                                                        locale="es"
                                                                    />
                                                                )}
                                                            /> 
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-sistemaoperativo">OS
                                                            {
                                                                (valores.traducciones.sistemaoperativo === "" || valores.traducciones.sistemaoperativo === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.sistemaoperativo}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-sistemaoperativo"
                                                                key="k-sistemaoperativo"
                                                                defaultValue={registro ? registro.sistemaoperativo : ''}
                                                                name='sistemaoperativo'                                                        
                                                            />                              
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-nombreequiposccm">Desktop Name
                                                            {
                                                                (valores.traducciones.nombreequiposccm === "" || valores.traducciones.nombreequiposccm === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.nombreequiposccm}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-nombreequiposccm"
                                                                key="k-nombreequiposccm"
                                                                defaultValue={registro ? registro.nombreequiposccm : ''}
                                                                name='nombreequiposccm'                                                        
                                                            />                              
                                                        </div>
                                                    </div> 
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-ipsccm">IP SCCM                                                            
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-ipsccm"
                                                                key="k-ipsccm"
                                                                defaultValue={registro ? registro.ipsccm : ''}
                                                                name='ipsccm'                                                        
                                                            />                              
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-espaciolibresccm">Free Space (GB)                                                          
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-espaciolibresccm"
                                                                key="k-espaciolibresccm"
                                                                defaultValue={registro ? registro.espaciolibresccm : ''}
                                                                name='espaciolibresccm'                                                        
                                                            />                              
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-espaciodiscossccm">Disk Space (GB)                                                          
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-espaciodiscossccm"
                                                                key="k-espaciodiscossccm"
                                                                defaultValue={registro ? registro.espaciodiscossccm : ''}
                                                                name='espaciodiscossccm'                                                        
                                                            />                              
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-memoriaramsccm">Memory Ram (GB)                                                          
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-memoriaramsccm"
                                                                key="k-memoriaramsccm"
                                                                defaultValue={registro ? registro.memoriaramsccm : ''}
                                                                name='memoriaramsccm'                                                        
                                                            />                              
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="divder-eq">                                                
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-ultimousuario">Last User
                                                            {
                                                                (valores.traducciones.ultimousuario === "" || valores.traducciones.ultimousuario === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.ultimousuario}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-ultimousuario"
                                                                key="k-ultimousuario"
                                                                defaultValue={registro ? registro.ultimousuario : ''}
                                                                name='ultimousuario'                                                        
                                                            />                              
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-compilacion">Compilation
                                                            {
                                                                (valores.traducciones.compilacion === "" || valores.traducciones.compilacion === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.compilacion}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                         
                                                                control={control}
                                                                id="i-compilacion"
                                                                key="k-compilacion"
                                                                defaultValue={registro ? registro.compilacion : ''}
                                                                name='compilacion'                                                       
                                                            />                              
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label  htmlFor="i-diasultimoinventario">Inventory Days
                                                            {
                                                                (valores.traducciones.diasultimoinventario === "" || valores.traducciones.diasultimoinventario === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.diasultimoinventario}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                          
                                                                control={control}
                                                                id="i-diasultimoinventario"
                                                                key="k-diasultimoinventario"
                                                                defaultValue={registro ? registro.diasultimoinventario : ''}
                                                                name='diasultimoinventario'                                                        
                                                            />                              
                                                        </div>
                                                    </div> 
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-modelosccm">Model
                                                            {
                                                                (valores.traducciones.modelosccm === "" || valores.traducciones.modelosccm === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.modelosccm}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-modelosccm"
                                                                key="k-modelosccm"
                                                                defaultValue={registro ? registro.modelosccm : ''}
                                                                name='modelosccm'                                                        
                                                            />                              
                                                        </div>
                                                    </div> 
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-mac">MAC                                                            
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-mac"
                                                                key="k-mac"
                                                                defaultValue={registro ? registro.mac : ''}
                                                                name='mac'                                                        
                                                            />                              
                                                        </div>
                                                    </div>                                                    
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-espaciototalsccm">Total Space (GB)                                                           
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-espaciototalsccm"
                                                                key="k-espaciototalsccm"
                                                                defaultValue={registro ? registro.espaciototalsccm : ''}
                                                                name='espaciototalsccm'                                                        
                                                            />                              
                                                        </div>
                                                    </div>   
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-ultimoreinicio">Last Reset                                                          
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-ultimoreinicio"
                                                                key="k-ultimoreinicio"
                                                                defaultValue={registro ? registro.ultimoreinicio : ''}
                                                                name='ultimoreinicio'                                                        
                                                            />                              
                                                        </div>
                                                    </div>   
                                                </div>
                                            </div>
                                            <div className="info-equipoav">
                                                <br></br>
                                                <label style={{fontWeight: 'bold'}}>AD Information</label>
                                                <hr></hr>
                                                <div className="divizq-eq">                                                     
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-avestado">State
                                                            {
                                                                (valores.traducciones.avestado === "" || valores.traducciones.avestado === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.avestado}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-avestado"
                                                                key="k-avestado"
                                                                defaultValue={registro ? registro.avestado : ''}
                                                                name='avestado'                                                        
                                                            />                              
                                                        </div>
                                                    </div>  
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-avfechacontacto">Date
                                                            {
                                                                (valores.traducciones.avfechacontacto === "" || valores.traducciones.avfechacontacto === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.avfechacontacto}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-avfechacontacto"
                                                                key="k-avfechacontacto"
                                                                defaultValue={registro ? registro.avfechacontacto : ''}
                                                                name='avfechacontacto'                                                        
                                                            />                              
                                                        </div>
                                                    </div>    
                                                        
                                                </div>
                                                <div className="divder-eq">                                                    
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-avpolitica">OU
                                                            {
                                                                (valores.traducciones.avpolitica === "" || valores.traducciones.avpolitica === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.avpolitica}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-avpolitica"
                                                                key="k-avpolitica"
                                                                defaultValue={registro ? registro.avpolitica : ''}
                                                                name='avpolitica'                                                        
                                                            />                              
                                                        </div>
                                                    </div>  
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-avversionsensor">Operation system (OS)
                                                            {
                                                                (valores.traducciones.avversionsensor === "" || valores.traducciones.avversionsensor === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.avversionsensor}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-avversionsensor"
                                                                key="k-avversionsensor"
                                                                defaultValue={registro ? registro.avversionsensor : ''}
                                                                name='avversionsensor'                                                        
                                                            />                              
                                                        </div>
                                                    </div>    
                                                </div>
                                            </div>
                                            <div className="info-equipoub">
                                                <br></br>
                                                <label style={{fontWeight: 'bold'}}>Umbrella Information</label>
                                                <hr></hr>
                                                <div className="divizq-eq">
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-ubestado">State
                                                            {
                                                                (valores.traducciones.ubestado === "" || valores.traducciones.ubestado === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.ubestado}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-ubestado"
                                                                key="k-ubestado"
                                                                defaultValue={registro ? registro.ubestado : ''}
                                                                name='ubestado'                                                        
                                                            />                              
                                                        </div>
                                                    </div>  
                                                </div>
                                                <div className="divder-eq">                                                     
                                                    <div className="row">
                                                        <div className="col-45-equipo">
                                                            <label htmlFor="i-ubfecha">Date
                                                            {
                                                                (valores.traducciones.ubfecha === "" || valores.traducciones.ubfecha === null) ? (<></>)
                                                                : (
                                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                                        <span class="tooltiptext">{valores.traducciones.ubfecha}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            </label> 
                                                        </div>
                                                        <div className="col-55">
                                                            <Controller
                                                                as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                      
                                                                control={control}
                                                                id="i-ubfecha"
                                                                key="k-ubfecha"
                                                                defaultValue={registro ? registro.ubfecha : ''}
                                                                name='ubfecha'                                                        
                                                            />                              
                                                        </div>
                                                    </div> 
                                                </div> 
                                            </div>          
                                                                                                          
                                        </div>
                                    ) : (<></>)
                                }

                                {
                                    (tabla) ? (
                                        <>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <TablaAplicacionesEquipo rows={aplicaciones}/>
                                        </>
                                    ) : (<></>)
                                }

                                {
                                    (tablaBO) ? (
                                        <>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <TablaAplicacionesBackEquipo rows={aplicacionesBO}/>
                                        </>
                                    ) : (<></>)
                                }

                                {
                                    (tablasccm) ? (
                                        <>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <TablaAplicacionesSccmEquipo rows={aplicacionesSccm}/>
                                        </>
                                    ) : (<></>)
                                }

                            </div>
                        </div>
                    </div>

                    <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px', float: 'left'}}>  
                        <div style={{width: '30%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px'}}>
                            {
                                (privModificar) ? (<button className="boton-from">Save</button> )
                                : (<></>)
                            }

                            <br></br>
                            <br></br>
                            <br></br>              
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default FormularioEquipoEscritorio
