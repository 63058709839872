import React, { useState } from 'react'

import swal from 'sweetalert';

import "../../css/modalusuarios.css";
import { clonarEquipo } from '../../functions/FuncEquipoEscritorio'

import { ContainerFiltros, FilaContainerFiltros, Button, ButtonVolver } from '../../Commons/Styles'


const FormClonacion = ({token, id_registro, cerrarModal, redireccion}) => {

    const [numeroserie, SetNumeroserie] = useState("");

    async function clonar()
    {
        let usuario = sessionStorage.getItem('nombre');

        swal({
            title: "Clone Confirmation",
            text: "You want clone this desktop?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then( async (afirmacion) => {
            if(afirmacion)
            {
                await clonarEquipo(id_registro, numeroserie, usuario, token)
                .then((response) => {
                    if(response.cod === 1){
                        swal("Successful clonning", "", "success")
                        .then(() => {
                            cerrarModal();
                            redireccion(response.id);
                        });
                    }
                    else swal("Clonning error", response.mensaje, "error");
                });      
            }
        });        
    }

  return (
        <>
        
            <FilaContainerFiltros>
                <ContainerFiltros>                    
                    <div style={{width: "50%", marginLeft: 'auto', marginRight: 'auto',}}>
                        <input className="bootstrap-input-filtro-clonacion" value={numeroserie} type='text' onChange={(e) => SetNumeroserie(e.target.value)} placeholder={"Serial Number"}  />
                    </div>
                   
                    <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>                        
                        <Button onClick={clonar}>Clone</Button>  
                    </div>

                    <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>                        
                        <ButtonVolver onClick={cerrarModal}>Return</ButtonVolver>
                    </div>
                </ContainerFiltros>
            </FilaContainerFiltros>           
        </>
  )
}

export default FormClonacion