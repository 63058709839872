import React from 'react'
import { obtenerFiltros, obtenerTabla, obtenerTablaBitacoras } from '../functions/FuncAplicacion'
import styled from 'styled-components';
import swal from 'sweetalert';
import FiltroBaseNuevo from '../FiltroBaseNuevo';
import  * as Metodos from '../functions/MetodosComunes'

import { ContainerFiltros, FilaContainerFiltros, Button } from '../Commons/Styles'
/*
const Button = styled.button`
    margin-right: auto;
    background-color: #EA7600;
    color: white;
    font-size: 20px;    
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    width: 100%;
    height: 50px;  
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    vertical-align: middle;
    color: white;
`;*/

const FiltrosAplicaciones = ({aplicarFiltro, obtenerBitacoras, load, token}) => {

    const [valores, setValores] = React.useState(null);

    let privilegio = sessionStorage.getItem('app_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');

    const [negocios, setNegocios] = React.useState(null);
    const [negocio, setNegocio] = React.useState("");

    const [fabricantes, setFabricantes] = React.useState(null);
    const [fabricante, setFabricante] = React.useState("");

    const [ambientes, setAmbientes] = React.useState(null);
    const [ambiente, setAmbiente] = React.useState("");

    const [estados, setEstados] = React.useState(null);
    const [estado, setEstado] = React.useState("");

    const [paises, setPaises] = React.useState(null);
    const [pais, setPais] = React.useState("");

    const [tipos, setTipos] = React.useState(null);
    const [tipo, setTipo] = React.useState("");

    const [aplicacion, setAplicacion] = React.useState("");

    async function obtenerRegistros()
    {
        load(true);
        aplicarFiltro(null);

        var paisApi = (pais !== null && pais !== undefined && pais !== "") ? pais.value : null;        
        var negocioApi = (negocio !== null && negocio !== undefined && negocio !== "") ? negocio.value : null;
        var estadoApi = (estado !== null && estado !== undefined && estado !== "") ? estado.value : null;  
        var ambienteApi = (ambiente !== null && ambiente !== undefined && ambiente !== "") ? ambiente.value : null;  
        var fabricanteApi = (fabricante !== null && fabricante !== undefined && fabricante !== "") ? fabricante.value : null;  
        var tipoApi = (tipo !== null && tipo !== undefined && tipo !== "") ? tipo.value : null;   
        var aplicacionApi = (aplicacion !== null && aplicacion !== undefined && aplicacion !== "") ? aplicacion : null;        
        
        (paisApi !== null) ? sessionStorage.setItem('filtrosaplicacion_pais', paisApi) : sessionStorage.setItem('filtrosaplicacion_pais', null);
        (negocioApi !== null) ? sessionStorage.setItem('filtrosaplicacion_negocio', negocioApi) : sessionStorage.setItem('filtrosaplicacion_negocio', null);
        (estadoApi !== null) ? sessionStorage.setItem('filtrosaplicacion_estado', estadoApi) : sessionStorage.setItem('filtrosaplicacion_estado', null);
        (ambienteApi !== null) ? sessionStorage.setItem('filtrosaplicacion_ambiente', ambienteApi) : sessionStorage.setItem('filtrosaplicacion_ambiente', null);
        (fabricanteApi !== null) ? sessionStorage.setItem('filtrosaplicacion_fabricante', fabricanteApi) : sessionStorage.setItem('filtrosaplicacion_fabricante', null);
        (tipoApi !== null) ? sessionStorage.setItem('filtrosaplicacion_tipoaplicacion', tipoApi) : sessionStorage.setItem('filtrosaplicacion_tipoaplicacion', null);
        (aplicacionApi !== null) ? sessionStorage.setItem('filtrosaplicacion_aplicacion', aplicacionApi) : sessionStorage.setItem('filtrosaplicacion_aplicacion', null);
        
        await obtenerTablaBitacoras(paisApi, negocioApi, fabricanteApi, ambienteApi, estadoApi, aplicacionApi, tipoApi, privilegio, paisUser, token)
        .then((response) => {
            if (response.cod === 1) obtenerBitacoras(response.bitacoras);            
            else obtenerBitacoras(null);    
        });
        
        await obtenerTabla(paisApi, negocioApi, fabricanteApi, ambienteApi, estadoApi, aplicacionApi, privilegio, tipoApi, paisUser, token)
        .then((response) => {
            if(response.cod === 1) {
                aplicarFiltro(response.aplicaciones);
            }
            else {
                aplicarFiltro(null);
                swal ("Search error" , response.mensaje , "warning");
            }
            
            load(false);
        });
    }

    function filtrosAnteriores()
    {

        let filtrosaplicacion_pais = sessionStorage.getItem('filtrosaplicacion_pais');        
        if(filtrosaplicacion_pais !== "null") setPais({label: filtrosaplicacion_pais, value: filtrosaplicacion_pais})

        let filtrosaplicacion_negocio = sessionStorage.getItem('filtrosaplicacion_negocio');        
        if(filtrosaplicacion_negocio !== "null") setNegocio({label: filtrosaplicacion_negocio, value: filtrosaplicacion_negocio})

        let filtrosaplicacion_estado = sessionStorage.getItem('filtrosaplicacion_estado');        
        if(filtrosaplicacion_estado !== "null") setEstado({label: filtrosaplicacion_estado, value: filtrosaplicacion_estado})

        let filtrosaplicacion_ambiente = sessionStorage.getItem('filtrosaplicacion_ambiente');        
        if(filtrosaplicacion_ambiente !== "null") setAmbiente({label: filtrosaplicacion_ambiente, value: filtrosaplicacion_ambiente})

        let filtrosaplicacion_fabricante = sessionStorage.getItem('filtrosaplicacion_fabricante');        
        if(filtrosaplicacion_fabricante !== "null") setFabricante({label: filtrosaplicacion_fabricante, value: filtrosaplicacion_fabricante})

        let filtrosaplicacion_tipoaplicacion = sessionStorage.getItem('filtrosaplicacion_tipoaplicacion');        
        if(filtrosaplicacion_tipoaplicacion !== "null") setTipo({label: filtrosaplicacion_tipoaplicacion, value: filtrosaplicacion_tipoaplicacion})

        let filtrosaplicacion_aplicacion = sessionStorage.getItem('filtrosaplicacion_aplicacion');        
        if(filtrosaplicacion_aplicacion !== "null") setAplicacion(filtrosaplicacion_aplicacion)        
        
    }

    async function obtenerFiltrosApp(){
        await obtenerFiltros(token)
        .then((response) => {
            if(response.cod === 1)
            {
                const val = {          
                    "paises": response.paises,
                    "estados": response.estados,
                    "negocios": response.negocios,
                    "fabricantes": response.fabricantes,
                    "ambientes": response.ambientes,
                    "tipos": response.tipos
                }; 


                setNegocios(Metodos.opcionesFiltros(val.negocios));
                setPaises(Metodos.opcionesFiltros(val.paises));
                setEstados(Metodos.opcionesFiltros(val.estados));
                setAmbientes(Metodos.opcionesFiltros(val.ambientes));
                setFabricantes(Metodos.opcionesFiltros(val.fabricantes));
                setTipos(Metodos.opcionesFiltros(val.tipos));

                if(privilegio === "2") setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));  
                else setPaises(Metodos.opcionesFiltros(val.paises));

                setValores(val);
            }
        }); 
    }

    React.useEffect(() =>{
        if(valores === null) obtenerFiltrosApp();

        filtrosAnteriores();

    }, []);

    const cambioNegocio = (valor) => {
        setNegocio(valor);
    };

    const cambioFabricante =  (valor) => {
        setFabricante(valor);
    };

    const cambioEstado=  (valor) => {
        setEstado(valor);
    };

    const cambioAmbiente =  (valor) => {
        setAmbiente(valor);
    };

    const cambioPais =  (valor) => {
        setPais(valor);
    };

    const cambioTipo =  (valor) => {
        setTipo(valor);
    };
    
    if(valores === null) return (<></>)
    else return (
        <>
            <FilaContainerFiltros>
            <ContainerFiltros>

                <FiltroBaseNuevo
                    opciones={paises}
                    valor={pais}
                    nombre={"Country"}
                    namecampo="pais"
                    cambio={cambioPais}
                    proporcion={"25%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={negocios}
                    valor={negocio}
                    nombre={"Business"}
                    namecampo="negocio"
                    cambio={cambioNegocio}
                    proporcion={"25%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={fabricantes}
                    valor={fabricante}
                    nombre={"Producer"}
                    namecampo="fabricante"
                    cambio={cambioFabricante}
                    proporcion={"25%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={estados}
                    valor={estado}
                    nombre={"State"}
                    namecampo="estado"
                    cambio={cambioEstado}
                    proporcion={"25%"}
                    error={false}
                />

            </ContainerFiltros>            
            <ContainerFiltros> 

                <FiltroBaseNuevo
                    opciones={ambientes}
                    valor={ambiente}
                    nombre={"Environment"}
                    namecampo="ambiente"
                    cambio={cambioAmbiente}
                    proporcion={"25%"}
                    error={false}
                />

                <FiltroBaseNuevo 
                    opciones={tipos}
                    valor={tipo}
                    nombre={"Type Application"}
                    namecampo="tipo"
                    cambio={cambioTipo}
                    proporcion={"25%"}
                    error={false}
                />

                <div style={{width: "25%", marginLeft: '10px', marginRight: 'auto',}}>
                    <input className="bootstrap-input-filtro" value={aplicacion} type='text' onChange={(e) => setAplicacion(e.target.value)} placeholder={"Application"}  />
                </div>

                <div style={{width: "25%", marginLeft: '10px', marginRight: 'auto',}}>
                    <Button onClick={obtenerRegistros}>Display</Button>
                </div>
                
            </ContainerFiltros>
            </FilaContainerFiltros>
            <br></br>
            <hr style={{width: '80%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
        </>
    )
}

export default FiltrosAplicaciones
