import React from 'react'
import { useParams } from "react-router-dom";

import Sidebar from '../Sidebar'
import * as TiIcons from 'react-icons/ti';
import * as AiIcons from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import FormularioSalaServidor from './FormularioSalaServidor';
import { eliminarSala } from '../functions/FuncSalaServidor'
import ModalDocumentos from '../ModalDocumentos';

import { ContainerForm, ButtonVolver, ButtonEliminacion } from '../Commons/Styles'

const FormSalaServidor = ({instance, t}) => {

    let { id } = useParams();
    const history = useHistory();
    let priv = sessionStorage.getItem('sala_leer');
    let privEliminacion = sessionStorage.getItem('sala_eliminar');

    function volver()
    {
        history.push("/salasservidores");
    }

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    async function eliminar()
    {
        await eliminarSala(id, t).then((response) => {
            if(response.cod === 1)
            {
                swal("Delete success", "", "success").then(() => {history.push("/salasservidores")});
            }
            else {
                swal("Delete Error", response.mensaje, "error");
            }
        });
    }

    function eliminarRegistro()
    {
        swal({
            title: "Delete Confirmation",
            text: "You want delete this application?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((afirmacion) => {
            if(afirmacion)
            {
                eliminar();       
            }
        })
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });

    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <>        
            <Sidebar instance={instance} despliegue={false}/>
            <ContainerForm>
                <div className="headerformulario">
                    <div className="headerform-izq">
                        <ButtonVolver onClick={volver} className="tooltipboton">
                        <TiIcons.TiArrowBack/>             
                        <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>
                    </div>
                    <div className="headerform-mid">
                        <div className="divtituloform">
                            <label className="tituloformulario">Server Room Form</label>
                        </div>                        
                    </div>
                    <div className="headerform-der">
                    {
                        (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>)
                        : (
                        <ButtonEliminacion onClick={eliminarRegistro} className="tooltipboton">
                            <AiIcons.AiFillDelete/>
                            <span className="tooltiptextboton">{"Delete"}</span> 
                        </ButtonEliminacion>)
                    }
                    <ModalDocumentos tipo={"SalaServidor"} id={id} token={t}/>
                    </div>
                </div>
                <FormularioSalaServidor token={t} id={id}/>
                
            </ContainerForm>            
        </>    
    )
}

export default FormSalaServidor
