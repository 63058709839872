import { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

import * as HiIcons from 'react-icons/hi';

import styled from 'styled-components';

import Loading from '../Loading';

import { TablaRegistros } from './TablaRegistros';



const headCells = [
    { id: 'id_cargaexcel', numeric: false, disablePadding: true, label: 'ID', size: '10%' },
    { id: 'estado', numeric: false, disablePadding: true, label: 'State', size: '10%' },
    { id: 'usuario', numeric: false, disablePadding: true, label: 'User', size: '20%' },
    { id: 'fechainicio', numeric: false, disablePadding: true, label: 'Start date', size: '10%' },
    { id: 'fechatermino', numeric: false, disablePadding: true, label: 'End date', size: '10%' },
    { id: 'exitosos', numeric: false, disablePadding: true, label: 'Successful', size: '10%' },
    { id: 'creados', numeric: false, disablePadding: true, label: 'Created', size: '5%' },
    { id: 'actualizados', numeric: false, disablePadding: true, label: 'Updated', size: '10%' },
    { id: 'omitidos', numeric: false, disablePadding: true, label: 'Omitted', size: '5%' },
    { id: 'errores', numeric: false, disablePadding: true, label: 'Errors', size: '5%' },
    { id: 'detalleerrores', numeric: false, disablePadding: true, label: '', size: '5%' }    
];

export const camposExcelCarga = [   
    {nombre:"FILA", valor:"fila"},
    {nombre:"DETALLE", valor:"detalle"}    
]

const ContainerBusqueda = styled.div`
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
`;

const ContainerTable = styled.div`
    width: 95%;
    margin-left: auto;
    margin-right: auto;
`;

const TablaCargas = ({cargasExcel, token, exportErrorsFileName}) => {

    const [rows, setRows] = useState(null);
    const [filter, setFilter] = useState("");
    const [loading, setLoading] = useState(false);

    async function obtenerCargas()
    {
        let usuario = sessionStorage.getItem('nombre');
        if(usuario !== null || usuario !== "")
        {
            cargasExcel(usuario, token).then((response) => {
                if(response.cod === 1)
                {
                    setRows(response.cargas);
                }
            });
        }
    }

    function actualizartabla()
    {
        obtenerCargas();      
    }

    useEffect(() => {
        if(rows == null)
        {
            obtenerCargas();
            setLoading(true);
        }
    });

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.estado !== "") ? (row.estado.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;   
            
            r = (row.usuario !== "") ? (row.usuario.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
        })
    }

    return (
        <>
            {
                (rows !== null)
                ? 
                (
                    <>
                        <ContainerBusqueda> 
                            <br></br> 
                            <br></br> 
                            <br></br>
                            <br></br>                         
                            <TextField 
                                id="outlined-search" label="Search" size="small" type="search" variant="outlined" 
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <button className="boton-actualizar" onClick={actualizartabla}>
                                <HiIcons.HiRefresh/>
                            </button>
                            <br></br>                  
                        </ContainerBusqueda>
                        <ContainerTable>
                            <TablaRegistros
                                headCells = { headCells }
                                rows = { buscar(rows) }
                                url = { null }
                                idFieldName = { 'id_cargaexcel' }
                                initialSortByField = { 'id_cargaexcel' }
                                initialSortDirection = { 'asc' }
                                fieldToExport = {{ fieldName: 'detalleerrores',
                                                    columns: camposExcelCarga,
                                                    sheetName: 'Detalles',
                                                    fileName: exportErrorsFileName,
                                                    subtitulo: 'Ver errores'
                                                }}
                                />
                        </ContainerTable>
                
                    </>
                )
                :
                (
                    (loading) ? (
                        <>
                            <br></br>
                            <br></br>
                            <ContainerTable>
                                <Loading />
                            </ContainerTable>
                        </>
                    )  : null 
                )

            }
        </>
    )
}

export default TablaCargas
