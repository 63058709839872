import React from 'react'

import swal from 'sweetalert';

import { obtenerFiltros, obtenerTabla, obtenerTablaBitacoras, requestRelacionesAplicacionesTabla } from '../functions/FuncEquipoEscritorio'

import FiltroBaseNuevo from '../FiltroBaseNuevo';
import  * as Metodos from '../functions/MetodosComunes'

import { ContainerFiltros, FilaContainerFiltros, Button } from '../Commons/Styles'

const FiltrosEquiposEscritorio = ({aplicarFiltro, load, obtenerRelaciones, obtenerBitacoras, token}) => {

    let priv = sessionStorage.getItem('esc_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');

    const [valores, setValores] = React.useState(null);

    const [condiciones, setCondiciones] = React.useState(null);
    const [condicion, setCondicion] = React.useState("");
    const [estados, setEstados] = React.useState(null);
    const [estado, setEstado] = React.useState("");
    const [plantas, setPlantas] = React.useState(null);
    const [planta, setPlanta] = React.useState("");
    const [paises, setPaises] = React.useState(null);
    const [pais, setPais] = React.useState("");
    const [localidades, setLocalidades] = React.useState(null);
    const [localidad, setLocalidad]  = React.useState("");
    const [tipos, setTipos] = React.useState(null);
    const [tipo, setTipo]  = React.useState("");
    const [modelo, setModelo] = React.useState("");
    const [numeroserie, setNumeroSerie] = React.useState("");

    const cambioLocalidad = (valor) => {
        setLocalidad(valor);
    };

    const cambioPlanta = (valor) => {
        setPlanta(valor);
    };

    const cambioCondicion= (valor) => {
        setCondicion(valor);
    };

    const cambioEstado = (valor) => {
        setEstado(valor);
    };

    const cambioTipo = (valor) => {
        setTipo(valor);
    };

    const cambioPais = (valor) => {

        if(valor !== null && valor !== undefined && valor !== ""){
            setPais(valor);
            filtrarLocalidad(valor.value);
        } 
        else{      
            setPais(null);      
            var array = [];
            if(valores.filtrolocalidad.length > 0){

                valores.filtrolocalidad.map((p) => {
                    array.push({label: p.localidad, value: p.localidad});
                });  
                setLocalidades(array);  
            }
        }
    };

    function filtrarLocalidad(filtro)
    {
        var array = [];
        if(valores.filtrolocalidad.length > 0){
            valores.filtrolocalidad.map((p) => {
                if(p.pais === filtro) array.push({label: p.localidad, value: p.localidad});
            });  

            setLocalidad(null);
            setLocalidades(array); 
        }
    }

    async function obtenerFiltrosTabla(){
        await obtenerFiltros(token)
        .then((response) => {

            if(response.cod === 1)
            { 
                const val = {          
                    "paises": response.paises,
                    "filtrolocalidad": response.filtrolocalidad,
                    "localidades": response.localidades,                    
                    "plantas": response.plantas,
                    "filtroplantas": response.filtroplantas,
                    "condiciones": response.condiciones,
                    "tipos": response.tipos,
                    "estados": response.estados
                }; 

                setEstados(Metodos.opcionesFiltros(val.estados));
                setTipos(Metodos.opcionesFiltros(val.tipos));
                setCondiciones(Metodos.opcionesFiltros(val.condiciones));     
                
                if(priv === "1"){
                    setLocalidades(Metodos.opcionesFiltrosValor(val.localidades, localidadUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser)); 
                    setPlantas(Metodos.opcionesFiltrosPlantaLocalidad(val.filtroplantas, localidadUser));                   
                }

                if(priv === "2"){
                    setLocalidades(Metodos.opcionesFiltrosLocalidad(val.filtroplantas, paisUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));  
                    setPlantas(Metodos.opcionesFiltrosPlantaPais(val.filtroplantas, paisUser));                   
                }

                if(priv === "3"){
                    setLocalidades(val.localidades = Metodos.opcionesFiltros(val.localidades));
                    setPaises(Metodos.opcionesFiltros(val.paises));     
                    setPlantas(Metodos.opcionesFiltros(val.plantas));   
                    
                }

                setValores(val);               
                
            }
        }); 
    }

    async function obtenerRegistros()
    {
        load(true);
        aplicarFiltro(null);  

        var paisApi = (pais !== null && pais !== undefined && pais !== "") ? pais.value : null;
        var localidadApi = (localidad !== null && localidad !== undefined && localidad !== "") ? localidad.value : null;
        var estadoApi = (estado !== null && estado !== undefined && estado !== "") ? estado.value : null;
        var condicionApi = (condicion !== null && condicion !== undefined && condicion !== "") ? condicion.value : null;
        var plantaApi = (planta !== null && planta !== undefined && planta !== "") ? planta.value : null;
        var tipoApi = (tipo !== null && tipo !== undefined && tipo !== "") ? tipo.value : null;
        var modeloApi = (modelo !== null && modelo !== undefined && modelo !== "") ? modelo : null;
        var numeroserieApi = (numeroserie !== null && numeroserie !== undefined && numeroserie !== "") ? numeroserie : null;

        (paisApi !== null) ? sessionStorage.setItem('filtrosequipos_pais', paisApi) : sessionStorage.setItem('filtrosequipos_pais', null);
        (localidadApi !== null) ? sessionStorage.setItem('filtrosequipos_localidad', localidadApi) : sessionStorage.setItem('filtrosequipos_localidad', null);
        (estadoApi !== null) ? sessionStorage.setItem('filtrosequipos_estado', estadoApi) : sessionStorage.setItem('filtrosequipos_estado', null);
        (condicionApi !== null) ? sessionStorage.setItem('filtrosequipos_condicion', condicionApi) : sessionStorage.setItem('filtrosequipos_condicion', null);
        (plantaApi !== null) ? sessionStorage.setItem('filtrosequipos_planta', plantaApi) : sessionStorage.setItem('filtrosequipos_planta', null);
        (tipoApi !== null) ? sessionStorage.setItem('filtrosequipos_tipo', tipoApi) : sessionStorage.setItem('filtrosequipos_tipo', null);
        (modeloApi !== null) ? sessionStorage.setItem('filtrosequipos_modelo', modeloApi) : sessionStorage.setItem('filtrosequipos_modelo', null);
        (numeroserieApi !== null) ? sessionStorage.setItem('filtrosequipos_equipo', numeroserieApi) : sessionStorage.setItem('filtrosequipos_equipo', null);

        await requestRelacionesAplicacionesTabla(estadoApi, condicionApi, paisApi, localidadApi, plantaApi, tipoApi, numeroserieApi, priv, paisUser, localidadUser, token)
        .then((response) => { 

            console.log(response)

            if (response.cod === 1) obtenerRelaciones(response.relaciones);            
            else obtenerRelaciones(null);    
        });

        await obtenerTablaBitacoras(estadoApi, condicionApi, paisApi, localidadApi, plantaApi, tipoApi, modeloApi, numeroserieApi, priv, paisUser, localidadUser, token)
        .then((response) => {            
            if (response.cod === 1) obtenerBitacoras(response.bitacoras);            
            else obtenerBitacoras(null);    
        });

        await obtenerTabla(estadoApi, condicionApi, paisApi, localidadApi, plantaApi, tipoApi, modeloApi, numeroserieApi, priv, paisUser, localidadUser, token)
        .then((response) => { 

            if (response.cod === 1)
            {
                aplicarFiltro(response.equipos);
            }
            else {
                aplicarFiltro(null);
                swal ("Search Error" , response.mensaje , "warning");
            }
                
            load(false);
        });
    }

    function filtrosAnteriores()
    {
        let filtrosequipos_localidad = sessionStorage.getItem('filtrosequipos_localidad');       
        if(filtrosequipos_localidad !== "null") setLocalidad({label: filtrosequipos_localidad, value: filtrosequipos_localidad})

        let filtrosequipos_pais = sessionStorage.getItem('filtrosequipos_pais');        
        if(filtrosequipos_pais !== "null") setPais({label: filtrosequipos_pais, value: filtrosequipos_pais})       

        let filtrosequipos_estado = sessionStorage.getItem('filtrosequipos_estado');        
        if(filtrosequipos_estado !== "null") setEstado({label: filtrosequipos_estado, value: filtrosequipos_estado})

        let filtrosequipos_condicion = sessionStorage.getItem('filtrosequipos_condicion');        
        if(filtrosequipos_condicion !== "null") setCondicion({label: filtrosequipos_condicion, value: filtrosequipos_condicion})

        let filtrosequipos_tipo = sessionStorage.getItem('filtrosequipos_tipo');        
        if(filtrosequipos_tipo !== "null") setTipo({label: filtrosequipos_tipo, value: filtrosequipos_tipo})

        let filtrosequipos_planta = sessionStorage.getItem('filtrosequipos_planta');        
        if(filtrosequipos_planta !== "null") setPlanta({label: filtrosequipos_planta, value: filtrosequipos_planta})
        
        let filtrosequipos_modelo = sessionStorage.getItem('filtrosequipos_modelo');        
        if(filtrosequipos_modelo !== "null") setModelo(filtrosequipos_modelo)      
        
        let filtrosequipos_equipo = sessionStorage.getItem('filtrosequipos_equipo');        
        if(filtrosequipos_equipo !== "null") setNumeroSerie(filtrosequipos_equipo)       
        
    }
    

    React.useEffect(() =>{
        if(valores === null) obtenerFiltrosTabla();
            
        filtrosAnteriores(); 
    }, []);

    if(valores === null) return (<></>)
    else return (
        <> 
            <FilaContainerFiltros>
            <ContainerFiltros>  

                <FiltroBaseNuevo
                    opciones={estados}
                    valor={estado}
                    nombre={"State"}
                    namecampo="estado"
                    cambio={cambioEstado}
                    proporcion={"25%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={condiciones}
                    valor={condicion}
                    nombre={"Condition"}
                    namecampo="condicion"
                    cambio={cambioCondicion}
                    proporcion={"25%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={paises}
                    valor={pais}
                    nombre={"Country"}
                    namecampo="pais"
                    cambio={cambioPais}
                    proporcion={"25%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={localidades}
                    valor={localidad}
                    nombre={"Location"}
                    namecampo="localidad"
                    cambio={cambioLocalidad}
                    proporcion={"25%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={tipos}
                    valor={tipo}
                    nombre={"Type"}
                    namecampo="tipo"
                    cambio={cambioTipo}
                    proporcion={"25%"}
                    error={false}
                />
                
            </ContainerFiltros>            
            <ContainerFiltros> 

                <FiltroBaseNuevo
                    opciones={plantas}
                    valor={planta}
                    nombre={"Plant"}
                    namecampo="planta"
                    cambio={cambioPlanta}
                    proporcion={"25%"}
                    error={false}
                />

                <div style={{width: "25%", marginLeft: '10px', marginRight: 'auto',}}>
                    <input className="bootstrap-input-filtro" value={modelo} type='text' onChange={(e) => setModelo(e.target.value)} placeholder={"Model"}  />
                </div>

                <div style={{width: "25%", marginLeft: '10px', marginRight: 'auto',}}>
                    <input className="bootstrap-input-filtro" value={numeroserie} type='text' onChange={(e) => setNumeroSerie(e.target.value)} placeholder={"Serial / User / Desktop Name / Desktop ID / OC"}  />
                </div>
                
                <div style={{width: "25%", marginLeft: '10px', marginRight: 'auto',}}>
                    <Button onClick={obtenerRegistros}>Display</Button>
                </div>
                
            </ContainerFiltros>
            </FilaContainerFiltros>            
            <br></br>
            <hr style={{width: '90%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
            
        </>
    )
}

export default FiltrosEquiposEscritorio
