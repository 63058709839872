import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Select from "react-select";
import { crearAplicacionPermitida, actualizarAplicacionPermitida } from "../../functions/FuncAplicacionPermitida";

import { style, styleNormal } from '../../Commons/Styles'

const FormAplicacionPermitida = ({ registro, valores, id, actualizarTabla, token }) => {
   
  const { handleSubmit } = useForm();

  const [nombre, setNombre] = useState(null);    
  const [familia, setFamilia] = useState(null);    
  const [condicion, setCondicion] = useState(null);

  const [errorNombre, setErrorNombre] = useState(false);
  const [errorFamilia, setErrorFamilia] = useState(false);
  const [errorCondicion, setErrorCondicion] = useState(false);


  useEffect(() => {
    if (id !== 0) {      
      setNombre(registro.nombre);
      setFamilia({label: registro.familia, value: registro.familia});
      setCondicion({label: registro.condicion, value: registro.condicion});
    }
    else {      
      setNombre("");
      setFamilia(null);
      setCondicion(null);
    }
  }, [registro]);

  const onSubmit = () => {

    const datos = {
      "nombre": nombre,
      "familia": familia,
      "condicion": condicion
    }

    if(validar(datos)) {
      swal("Form data", "Mandatory data must be entered", "warning");
    }
    else {
      if(id === 0) {
        swal({
          title: "Create confirmation",
          text: "Do you want to create the registry?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (afirmacion) => {
          if (afirmacion) {
            await crearAplicacionPermitida(datos, token).then((response) => {
              if(response.cod === 1) {
                actualizarTabla();
                swal("Successful create", "", "success");
              }
              else swal("Create error" , response.mensaje ,"error");
            })
          }
        });
      }
      else {
        swal({
          title: "Update confirmation",
          text: "Do you want to update the registry?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (afirmacion) => {
          if (afirmacion) {
            await actualizarAplicacionPermitida(datos, id, token).then((response) => {
              if(response.cod === 1) {
                actualizarTabla();
                swal("Successful update", "", "success");
              }
              else swal("Update error" , response.mensaje ,"error");
            })
          }
        });
      }
    }

  };
  
  const validar = (datos) => {
    let errores = false;

    if(datos.nombre === null || datos.nombre === undefined || datos.nombre === ""){
      errores = true;
      setErrorNombre(true);
    } else setErrorNombre(false);

    if(datos.familia === null || datos.familia === undefined || datos.familia === ""){
      errores = true;
      setErrorFamilia(true);
    } else setErrorFamilia(false);

    if(datos.condicion === null || datos.condicion === undefined || datos.condicion === ""){
      errores = true;
      setErrorCondicion(true);
    } else setErrorCondicion(false);

    return errores;
  }

  return (
    <>
      <form
        style={{ width: "90%", marginRight: "auto", marginLeft: "auto" }}   
        onSubmit={handleSubmit(onSubmit)}     
      >
        <div className="container">
          <div className="row">
            <div className="col-25">
              <label style={{ fontWeight: "bold" }} htmlFor="i-nombre">Name<label style={{ color: "red" }}>{"*"}</label> </label>
            </div>
            <div className="col-75">
                <input 
                    className={errorNombre ? "bootstrap-input-appser-error" : "bootstrap-input-appser"}
                    type="text" 
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label style={{ fontWeight: "bold" }} htmlFor="i-familia">
                Family<label style={{ color: "red" }}>{"*"}</label>                
              </label>
            </div>
            <div className="col-75">
                <Select
                  options={valores.familias}
                  onChange={e => setFamilia(e)}
                  placeholder=""                 
                  value={familia !== null ? familia : null}
                  styles={(errorFamilia) ? style :  styleNormal }
                />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label style={{ fontWeight: "bold" }} htmlFor="i-condicion">
                Condition<label style={{ color: "red" }}>{"*"}</label>                
              </label>
            </div>
            <div className="col-75">
                <Select
                  options={valores.condiciones}
                  onChange={e => setCondicion(e)}
                  placeholder=""                 
                  value={condicion !== null ? condicion : null}
                  styles={(errorCondicion) ? style :  styleNormal }
                />
            </div>
          </div>
          <br></br>
          <div className="divbotonnot">
            <button className="boton-from">Save</button>
            <br></br>
            <br></br>
          </div>
        </div>
      </form>
    </>
  );
};

export default FormAplicacionPermitida;
