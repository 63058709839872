import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import '../css/aplicaciones.css'
import swal from 'sweetalert';

import ExportarExcel from '../ExportarExcel';
import FiltrosAplicaciones from './FiltrosAplicaciones';
import * as Opciones from './OpcionesAplicacion';
import Loading from '../Loading';
import ExportarExcelBitacoras from '../ExportarExcelBitacoras';

import { TablaRegistros } from '../Commons/TablaRegistros';

import { ContainerBusqueda, ContainerTable } from '../Commons/Styles'

const headCells = [
    { id: 'nombre', ordertype: 'text', numeric: false, disablePadding: true, label: 'Application', size: '10%' },
    { id: 'version', ordertype: 'text', numeric: false, disablePadding: true, label: 'Version', size: '10%' },
    { id: 'ambiente', ordertype: 'text', numeric: false, disablePadding: true, label: 'Environment', size: '10%' },
    { id: 'tipo', ordertype: 'text', numeric: false, disablePadding: true, label: 'Type', size: '10%' },
    { id: 'fabricante', ordertype: 'text', numeric: false, disablePadding: true, label: 'Producer', size: '10%' },
    { id: 'estado', ordertype: 'text', numeric: false, disablePadding: true, label: 'State', size: '10%' },
    { id: 'negocio', ordertype: 'text', numeric: false, disablePadding: true, label: 'Business', size: '15%' },
    { id: 'cantidadservidores', ordertype: 'int', numeric: false, disablePadding: true, label: 'Servers', size: '10%' },
    { id: 'tipolicencia', ordertype: 'text', numeric: false, disablePadding: true, label: 'Type License', size: '10%' },
    { id: 'fechaupdate', ordertype: 'text', numeric: false, disablePadding: true, label: 'Update Date', size: '5%' },
    { id: 'observacion', ordertype: 'text', numeric: false, disablePadding: true, label: 'Observations', size: '5%' },    
];

const TablaAplicaciones = ({token}) => {
    
    const [loading, setLoading] = React.useState(false);
    const [rows, setRows] = useState(null);
    const [filter, setFilter] = useState("");

    let privEliminacion = sessionStorage.getItem('ser_eliminar');
    const [bitacoras, setBitacoras] = useState(null);

    function aplicarFiltro(registros)
    {
        if(registros == null) setRows(registros); 
        else if(registros.length === 0)
        {
            setRows(registros)            
            swal ("There are no records" ,
                    "No information is recorded with the filters used" ,
                    "info");
        }
        else setRows(registros);
    }

    function obtenerBitacoras(registros)
    {        
        setBitacoras(registros);
    }

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.nombre !== "") ? (row.nombre.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.version !== "") ? (row.version.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.tiposoftware !== "") ? (row.tiposoftware.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.fabricante !== "") ? (row.fabricante.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.estado !== "") ? (row.estado.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.ambiente !== "") ? (row.ambiente.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.negocio !== "") ? (row.negocio.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.tipolicencia !== "") ? (row.tipolicencia.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.fechaupdate !== "") ? (row.fechaupdate.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
           
        })
    }

    return (
        <>
            <FiltrosAplicaciones aplicarFiltro={aplicarFiltro} load={setLoading} obtenerBitacoras={obtenerBitacoras} token={token} />
            {
                (rows !== null)
                ? 
                (<>
                    
                    
                    <ContainerBusqueda> 
                        <br></br> 
                        
                            <TextField id="outlined-search" label="Search" size="small" type="search" variant="outlined" 
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                
                            />
                            <ExportarExcel rows={rows} columnas={Opciones.camposExcel} nombreSheet="Aplicaciones" nombreArchivo="AplicacionesExport"/>
                            {
                                (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>) :
                                (bitacoras === null) ? (<></>)
                                : (                 
                                    <ExportarExcelBitacoras subtitulo={"Logs"} rows={bitacoras} columnas={Opciones.camposExcelBitacora} nombreSheet="LogsApplication" nombreArchivo="LogsApplicationExport"/>
                                )
                            }
                        <br></br>                  
                    </ContainerBusqueda>
                    <ContainerTable>
                        <TablaRegistros
                            headCells = { headCells }
                            rows = { buscar(rows) }
                            url = { '/aplicaciones/' }
                            idFieldName = { 'id_aplicacion' }
                            initialSortByField = { 'nombre' }
                            initialSortDirection = { 'desc' }
                            />
                    </ContainerTable>                    
                </>)
                :
                (
                    (loading) ? (
                        <>
                            <br></br>
                            <br></br>
                            <ContainerTable>
                                <Loading />
                            </ContainerTable>
                        </>
                    )  : null 
                )
            }
        </>
    )
}

export default TablaAplicaciones
