import React from 'react'
import { useParams } from "react-router-dom";
import Sidebar from '../Sidebar'
import FormularioServidor from './FormularioServidor';
import * as TiIcons from 'react-icons/ti';
import * as AiIcons from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import { eliminarServidorRequest } from '../functions/FuncServidor'
import { eliminarBitacorasRegistroRequest } from '../functions/FuncBitacora'
import ModalDocumentos from '../ModalDocumentos';
import Bitacora from '../Bitacora/Bitacora';

import { ContainerForm, ButtonVolver, ButtonEliminacion } from '../Commons/Styles'

const FormServidor = ({instance, t}) => {

    let { id } = useParams();
    const history = useHistory();
    let priv = sessionStorage.getItem('ser_leer');
    let privEliminacion = sessionStorage.getItem('ser_eliminar');

    function volver()
    {
        history.push("/servidores");
    }

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    async function eliminar()
    {
        await eliminarServidorRequest(id, t).then((response) => {
            if(response.cod === 1)
            {
                eliminarBitacorasRegistroRequest(id, "Servidor", t);
                swal("Delete success", "", "success").then(() => {history.push("/servidores")});
            }
            else {
                swal("Delete Error", response.mensaje, "error");
            }
        });
    }

    function eliminarRegistro()
    {
        swal({
            title: "Delete Confirmation",
            text: "You want delete this server?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((afirmacion) => {
            if(afirmacion)
            {
                eliminar();       
            }
        })
    }


    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });

    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <div>
            <Sidebar instance={instance} despliegue={false}/>            
            <ContainerForm>
                <div className="headerformulario">
                        <div className="headerform-izq">

                            
                            <ButtonVolver onClick={volver} className="tooltipboton">                    
                                <TiIcons.TiArrowBack/>
                                <span className="tooltiptextboton">{"Back"}</span> 
                            </ButtonVolver>
                        </div>
                        <div className="headerform-mid">
                            <div className="divtituloform">
                                <label className="tituloformulario">Server Form</label>
                            </div>                        
                        </div>
                        
                        <div className="headerform-der">
                        {
                            (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>)
                            : (
                                <ButtonEliminacion onClick={eliminarRegistro} className="tooltipboton">
                                    <AiIcons.AiFillDelete/>
                                    <span className="tooltiptextboton">{"Delete"}</span> 
                                </ButtonEliminacion>)
                        }                    
                        <Bitacora tipo={"Servidor"} id={id} token={t} />
                        <ModalDocumentos tipo={"Servidor"} id={id} token={t}/>
                        </div>
                </div>
                <FormularioServidor id={id} token={t}/>
                <br></br>
            </ContainerForm>
            
        </div>
    )
}

export default FormServidor
