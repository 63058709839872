import React from 'react'
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import Sidebar from '../Sidebar'
import * as TiIcons from 'react-icons/ti';
import * as AiIcons from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import { eliminarAplicacionBack } from '../functions/FuncAplicacionBack'
import { eliminarBitacorasRegistroRequest } from '../functions/FuncBitacora'
import FormularioAplicacionBack from './FormularioAplicacionBack';
import Bitacora from '../Bitacora/Bitacora';
import ModalDocumentos from '../ModalDocumentos';

import { ContainerForm, ButtonVolver, ButtonEliminacion } from '../Commons/Styles'

const FormAplicacionBack = ({instance, t}) => {

  let { id } = useParams();
  const history = useHistory();
  let priv = sessionStorage.getItem('appback_leer');
  let privEliminacion = sessionStorage.getItem('appback_eliminar');

  function volver()
    {
        history.push("/aplicacionesback");
    }

    function rolInvalido()
    {
      swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
        history.push("/")
      });
    }

    async function eliminar()
    {
        await eliminarAplicacionBack(id, t).then((response) => {
            if(response.cod === 1)
            {
                eliminarBitacorasRegistroRequest(id, "AplicacionBack", t);
                swal("Delete success", "", "success").then(() => {history.push("/aplicacionesback")});
            }
            else {
                swal("Delete Error", response.mensaje, "error");
            }
        });
    }

    function eliminarRegistro()
    {
        swal({
            title: "Delete Confirmation",
            text: "You want delete this certificate?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((afirmacion) => {
            if(afirmacion)
            {
              eliminar();       
            }
        })
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });

    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <>        
        <Sidebar instance={instance} despliegue={false}/>
        <ContainerForm>
            <div className="headerformulario">
                <div className="headerform-izq">
                    <ButtonVolver onClick={volver} className="tooltipboton">
                        <TiIcons.TiArrowBack/>             
                        <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>
                </div>
                <div className="headerform-mid">
                    <div className="divtituloform">
                    <label className="tituloformulario">Application BO Form</label>
                    </div>                        
                </div>
                <div className="headerform-der">
                {
                    (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>)
                    : (
                        <ButtonEliminacion onClick={eliminarRegistro} className="tooltipboton">
                            <AiIcons.AiFillDelete/>
                            <span className="tooltiptextboton">{"Delete"}</span> 
                        </ButtonEliminacion>)
                }
                <Bitacora tipo={"AplicacionBack"} id={id} token={t} />
                <ModalDocumentos tipo={"AplicacionBack"} id={id} token={t}/>
                </div>
            </div>
            
            <FormularioAplicacionBack id={id} token={t}/>
            
        </ContainerForm>            
        </>
    )
}

export default FormAplicacionBack