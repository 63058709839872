import React, {useState} from 'react'
import * as Metodos from '../functions/MetodosComunes'
import { useForm, Controller } from "react-hook-form";
import * as Opciones from './OpcionesOtrosDispositivos'
import { obtenerValores, obtenerDispositivo, actualizarDispositivo} from '../functions/FuncOtrosDispositivos'
import { registrarBitacoraOtroDispositivo } from "../functions/FuncBitacora";
import "../css/formularioAplicacion.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import Loading from '../Loading';
import Select from 'react-select'
import { validarTextoVacio } from '../functions/MetodosComunes'

import { validarPrivilegioModificacion } from '../Commons/Functions'
import { style, styleNormal, styleDisable } from '../Commons/Styles'

const FormularioOtroDispositivo = ({id, token}) => {
    
    let privEscribir = sessionStorage.getItem('dis_escribir');
    let privLeer = sessionStorage.getItem('dis_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');
    let idiomaUser = sessionStorage.getItem('idioma');
    const [privModificar, setPrivModificar] = React.useState(false);

    const [registro, setRegistro] = useState(null);    
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const [tipoform, setTipoform] = useState("");
    const [marca, setMarca] = useState("");
    const [modelo, setModelo] = useState("");

    const [empresa, setEmpresa] = useState("");
    const [planta, setPlanta] = useState(""); 

    const [sistema, setSistema] = useState(null);
    const [version, setVersion] = useState(null);    

    const [estado, setEstado] = useState("");
    const [condicion, setCondicion] = useState("");

    const [errorTipo, setErrorTipo] = useState(false);
    const [errorNumeroSerie, setErrorNumeroSerie] = useState(false);
    const [errorEstado, setErrorEstado] = useState(false); 
    const [errorCondicion, setErrorCondicion] = useState(false);
    const [errorPropiedad, setErrorPropiedad] = useState(false);
    const [errorEmpresa, setErrorEmpresa] = useState(false);
    const [errorPlanta, setErrorPlanta] = useState(false);     
    const [errorMarca, setErrorMarca] = useState(false);
    const [errorModelo, setErrorModelo] = useState(false);
    const [errorDescripcion, setErrorDescripcion] = useState(false); 

    function mapResponseToRegistro(response){
        setTipoform(Metodos.validarTextoVacio(response.dispositivo.tipoequipo) ? {"label": response.dispositivo.tipoequipo, "value": response.dispositivo.tipoequipo} : null);
        setMarca(Metodos.validarTextoVacio(response.dispositivo.marca) ? {"label": response.dispositivo.marca, "value": response.dispositivo.marca} : null);
        setModelo(Metodos.validarTextoVacio(response.dispositivo.modelo) ? {"label": response.dispositivo.modelo, "value": response.dispositivo.modelo} : null);
        setEmpresa(Metodos.validarTextoVacio(response.dispositivo.empresa) ? {"label": response.dispositivo.empresa, "value": response.dispositivo.empresa} : null);
        setPlanta(Metodos.validarTextoVacio(response.dispositivo.planta) ? {"label": response.dispositivo.planta, "value": response.dispositivo.planta} : null);
        setSistema(Metodos.validarTextoVacio(response.dispositivo.sistemaoperativo) ? {"label": response.dispositivo.sistemaoperativo, "value": response.dispositivo.sistemaoperativo} : null);
        setVersion(Metodos.validarTextoVacio(response.dispositivo.version) ? {"label": response.dispositivo.version, "value": response.dispositivo.version} : null);
        setEstado(Metodos.validarTextoVacio(response.dispositivo.estado) ? {"label": response.dispositivo.estado, "value": response.dispositivo.estado} : null);   
        setCondicion(Metodos.validarTextoVacio(response.dispositivo.condicion) ? {"label": response.dispositivo.condicion, "value": response.dispositivo.condicion} : null);

        setRegistro({
            "numeroserie": Metodos.validarTextoVacio(response.dispositivo.numeroserie) ? response.dispositivo.numeroserie : null,
            "estado": Metodos.validarTextoVacio(response.dispositivo.estado) ? {"label": response.dispositivo.estado, "value": response.dispositivo.estado} : null,
            "condicion": Metodos.validarTextoVacio(response.dispositivo.condicion) ? {"label": response.dispositivo.condicion, "value": response.dispositivo.condicion} : null,
            "propiedadde": Metodos.validarTextoVacio(response.dispositivo.propiedadde) ? {"label": response.dispositivo.propiedadde, "value": response.dispositivo.propiedadde} : null,
            "empresa": Metodos.validarTextoVacio(response.dispositivo.empresa) ? {"label": response.dispositivo.empresa, "value": response.dispositivo.empresa} : null,
            "planta": Metodos.validarTextoVacio(response.dispositivo.planta) ? {"label": response.dispositivo.planta, "value": response.dispositivo.planta} : null,
            "marca": Metodos.validarTextoVacio(response.dispositivo.marca) ? {"label": response.dispositivo.marca, "value": response.dispositivo.marca} : null,
            "modelo": Metodos.validarTextoVacio(response.dispositivo.modelo) ? {"label": response.dispositivo.modelo, "value": response.dispositivo.modelo} : null,
            "descripcion": Metodos.validarTextoVacio(response.dispositivo.descripcion) ? response.dispositivo.descripcion : null,
            "localidad": Metodos.validarTextoVacio(response.dispositivo.localidad) ? response.dispositivo.localidad : null,
            "pais": Metodos.validarTextoVacio(response.dispositivo.pais) ? response.dispositivo.pais : null,
            "negocio": Metodos.validarTextoVacio(response.dispositivo.negocio) ? response.dispositivo.negocio : null,
            
            "sistemaoperativo": Metodos.validarTextoVacio(response.dispositivo.sistemaoperativo) ? {"label": response.dispositivo.sistemaoperativo, "value": response.dispositivo.sistemaoperativo} : null,
            "version": Metodos.validarTextoVacio(response.dispositivo.version) ? {"label": response.dispositivo.version, "value": response.dispositivo.version} : null,

            "fechacompra": Metodos.validarTextoVacio(response.dispositivo.fechacompra) ? new Date(response.dispositivo.fechacompra) : null,
            "fechaarriendo": Metodos.validarTextoVacio(response.dispositivo.fechaarriendo) ? new Date(response.dispositivo.fechaarriendo) : null,
            "fecharevision": Metodos.validarTextoVacio(response.dispositivo.fecharevision) ? new Date(response.dispositivo.fecharevision) : null,                

            "mac": Metodos.validarTextoVacio(response.dispositivo.mac) ? response.dispositivo.mac : null,
            "ipequipo": Metodos.validarTextoVacio(response.dispositivo.ipequipo) ? response.dispositivo.ipequipo : null,
            "condicionrevision": Metodos.validarTextoVacio(response.dispositivo.condicionrevision) ? response.dispositivo.condicionrevision : null,
            "observaciones": Metodos.validarTextoVacio(response.dispositivo.observaciones) ? response.dispositivo.observaciones : null,
            "hostname": Metodos.validarTextoVacio(response.dispositivo.hostname) ? response.dispositivo.hostname : null,
            "arrendadoa": Metodos.validarTextoVacio(response.dispositivo.arrendadoa) ? response.dispositivo.arrendadoa : null,
            "modificadopor": Metodos.validarTextoVacio(response.dispositivo.modificadopor) ? response.dispositivo.modificadopor : null,
            "fechaupdate": Metodos.validarTextoVacio(response.dispositivo.fechaupdate) ? new Date(response.dispositivo.fechaupdate) : null
        });
    }
    async function obtenerDatosBD()
    {

        var marcaDispo = "";
        var sistemaDispo = "";
        var empresaDispo = "";
        var paisDispo = "";
        var localidadDispo = "";
        var estadoDispo = "";

        await obtenerDispositivo(id, token)
        .then((response) => {

            validarPrivilegioModificacion(privEscribir, paisUser, localidadUser, "", response.dispositivo, setPrivModificar );
                
        
            marcaDispo = response.dispositivo.marca;
            sistemaDispo = response.dispositivo.sistemaoperativo;
            empresaDispo = response.dispositivo.empresa;
            paisDispo = response.dispositivo.pais;
            localidadDispo = response.dispositivo.localidad;
            estadoDispo = response.dispositivo.estado;

            mapResponseToRegistro(response);
        })


        await obtenerValores(privEscribir, paisUser, localidadUser, "OTHER DEVICE", idiomaUser, token)
        .then((response) => {
            
            console.log(response.traducciones)

            if(response.cod === 1)
            {
                const val = {
                    "estados": response.estados,
                    "condiciones": response.condiciones,
                    "filtrocondiciones": response.filtrocondiciones,
                    "propiedades": response.propiedades,
                    "paises": response.paises,
                    "localidades": response.localidades,
                    "filtrolocalidad": response.filtrolocalidad,
                    "empresas": response.empresas,
                    "todasempresas": response.todasempresas,
                    "plantas": response.plantas,
                    "filtroplanta": response.filtroplanta,
                    "tipos": response.tipos,
                    "sistemasoperativos": response.sistemasoperativos,
                    "versiones": response.versiones,
                    "filtroversion": response.filtroversion,
                    "marcas": response.marcas,
                    "modelos": response.modelos,
                    "filtromodelo": response.filtromodelo,
                    "traducciones": response.traducciones                
                }; 

                let array = [];

                const listas = ["estados", "condiciones","propiedades",
                "paises","localidades","empresas",
                "plantas","tipos","sistemasoperativos",
                "versiones","marcas","modelos"];

                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                //CONDICIONES
                array = [];
                val.filtrocondiciones.map((u) => {
                    if(u.estado === estadoDispo) array.push({"label": u.condicion, "value": u.condicion});
                })
                val.condiciones = array;

                //PLANTAS                
                array = [];
                val.filtroplanta.map((u) => {
                    if(u.empresa === empresaDispo) array.push({"label": u.planta, "value": u.planta});
                })
                val.plantas = array;

                //MODELOS
                array = [];
                val.filtromodelo.map((u) => {
                    if(u.marca === marcaDispo) array.push({"label": u.modelo, "value": u.modelo});
                })
                val.modelos = array;

                if(privEscribir === "1" && privLeer == "2")
                {
                    if(localidadDispo !== localidadUser){
                        array = [];
                        val["todasempresas"].map((u) => {
                            array.push({"label": u, "value": u});
                        })
                        val["empresas"] = array;
                    } 
                }

                if(privEscribir === "2" && privLeer == "3")
                {
                    if(paisDispo!== paisUser){
                        array = [];
                        val["todasempresas"].map((u) => {
                            array.push({"label": u, "value": u});
                        })
                        val["empresas"] = array;
                    } 
                }
                
                setValores(val);
            }
        });
    }    

    const cambioFormulario = (value) => { 
        if(value !== "" && value !== null){
            setTipoform(value.value);            
        } else {
            setTipoform("");            
        }
    }

    const cambioMarca = (value) => { 
        if(value !== "" && value !== null){
            setMarca({"label": value.value, "value": value.value});            
            filtrarModelos(value.value);            
        } else {
            setMarca(""); 
            setModelo("");           
        }
    }

    const cambioModelo= (value) => { 
        if(value !== "" && value !== null){
            setModelo({"label": value.value, "value": value.value});
        } else {
            setModelo("");
        }
    }

    function filtrarModelos(marca)
    {
        if(marca !== "")
        {
            const filtrotipoArray = valores.filtromodelo;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.marca === marca) {
                        array.push({"label": p.modelo, "value": p.modelo});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.modelos = array; 
            setModelo("");           
            setValores(valoresNuevo);
        }
    }

    const cambioSistema= (value) => { 
        if(value !== "" && value !== null){
            setSistema({"label": value.value, "value": value.value});            
            filtrarVersiones(value.value);            
        } else {
            setSistema(""); 
            setVersion("");           
        }
    }

    const cambioVersion= (value) => { 
        if(value !== "" && value !== null){
            setVersion({"label": value.value, "value": value.value});
        } else {
            setVersion("");
        }
    }

    function filtrarVersiones(sistemaoperativo)
    {
        if(sistemaoperativo !== "")
        {
            const filtrotipoArray = valores.filtroversion;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.sistemaoperativo === sistemaoperativo) {
                        array.push({"label": p.version, "value": p.version});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.versiones = array; 
            setVersion("");           
            setValores(valoresNuevo);
        }
    }

    const cambioEmpresa= (value) => { 
        if(value !== "" && value !== null){
            setEmpresa({"label": value.value, "value": value.value});            
            filtrarPlantas(value.value);            
        } else {
            setEmpresa(""); 
            setPlanta("");           
        }
    }

    const cambioPlanta= (value) => { 
        if(value !== "" && value !== null){
            setPlanta({"label": value.value, "value": value.value});
        } else {
            setPlanta("");
        }
    }

    function filtrarPlantas(empresa)
    {
        if(empresa !== "")
        {
            const filtrotipoArray = valores.filtroplanta;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.empresa === empresa) {
                        array.push({"label": p.planta, "value": p.planta});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.plantas = array; 
            setPlanta("");           
            setValores(valoresNuevo);
        }
    }

    const cambioEstado = (value) => {
        if(value !== "" && value !== null){
            setEstado(value.value);            
            filtrarCondiciones(value.value);            
        } else {
            setEstado(""); 
            setCondicion("");           
        }
    }

    const cambioCondicion= (value) => { 
        if(value !== "" && value !== null){
            setCondicion({"label": value.value, "value": value.value});
        } else {
            setCondicion("");
        }
    }

    function filtrarCondiciones(estado)
    {
        if(validarTextoVacio(estado))
        {
            console.log(estado)
            const filtrotipoArray = valores.filtrocondiciones;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.estado === estado) {
                        array.push({"label": p.condicion, "value": p.condicion});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.condiciones = array; 
            console.log(valoresNuevo.condiciones)
            setCondicion("");           
            setValores(valoresNuevo);
        }
    }

    React.useEffect(() => {
        if (valores !== null) {
            Opciones.campos.forEach(c => {
                setValue(c, registro[c], {shouldValidate: true,shouldDirty: true})
            });
        }
        else obtenerDatosBD();
    }, [registro]);

    function validarDatos(data)
    {
        var errores = false;
        if(tipoform === "") 
        {   
            setErrorTipo(true);
            errores = true;
        }
        else setErrorTipo(false);

        if(data.numeroserie === null || data.numeroserie === "" || data.numeroserie === undefined) 
        {
            setErrorNumeroSerie(true);
            errores = true;
        }
        else setErrorNumeroSerie(false);

        if(estado === null || estado === "") 
        {
            setErrorEstado(true);
            errores = true;
        }
        else setErrorEstado(false);

        if(condicion === null || condicion === "") 
        {
            setErrorCondicion(true);
            errores = true;
        }
        else setErrorCondicion(false);

        if(data.propiedadde === null || data.propiedadde === "") 
        {
            setErrorPropiedad(true);
            errores = true;
        }
        else setErrorPropiedad(false);

        if(planta === null || planta === "") 
        {
            setErrorPlanta(true);
            errores = true;
        }
        else setErrorPlanta(false);

        if(empresa === null || empresa === "") 
        {
            setErrorEmpresa(true);
            errores = true;
        }
        else setErrorEmpresa(false);

        if(data.marca === null || data.marca === "") 
        {
            setErrorMarca(true);
            errores = true;
        }
        else setErrorMarca(false);

        if(data.modelo === null || data.modelo === "") 
        {
            setErrorModelo(true);
            errores = true;
        }
        else setErrorModelo(false);

        if(data.descripcion === null || data.descripcion === "" || data.descripcion === undefined) 
        {
            setErrorDescripcion(true);
            errores = true;
        }
        else setErrorDescripcion(false);

        return errores;
    }

    async function registrarBitacora(datosActuales, dataAnterior) {
        let nombreusuario = sessionStorage.getItem("nombre");
    
        registrarBitacoraOtroDispositivo(
          datosActuales,
          dataAnterior,
          id,
          nombreusuario,
          token
        ).then((response) => {
          console.log(response);
        });
      }

    const onSubmit = (data) => {
        
        const validacion = validarDatos(data);
        if(validacion)
        {
            swal("Form data", "Mandatory data must be entered", "warning");
        }
        else {
            swal({
                title: "Update confirmation",
                text: "Do you want to update the registry?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion)
                {
                    const datosform = data;

                    let nombreusuario = sessionStorage.getItem('nombre');
                    datosform.modificadopor = nombreusuario;
                    datosform.fechaupdate = new Date();
                    datosform.tipoequipo = tipoform;   
                    datosform.sistemaoperativo = (sistema === "") ? null : sistema;    
                    datosform.version = (version === "") ? null : version;             
                    
                    await actualizarDispositivo(datosform, registro, id, token)
                    .then((response) => {
                        if(response.cod === 1)
                        {
                            registrarBitacora(datosform, registro);
                            obtenerregistro();                            
                            swal("Successful update", "", "success");
                        }
                        else if(response.cod === 0){
                            swal("Update error", response.mensaje, "error");
                        }
                        else {
                            swal("Update error", "An error occurred during the update. Please contact the administrator.", "error");
                        }
                    })
                }
            })
        }
    }

    async function obtenerregistro()
    {
        await obtenerDispositivo(id, token)
        .then((response) => {

            mapResponseToRegistro(response);
        })
    }

    if(registro === null || valores === null) return (<Loading />)
    else
    return (
        <>
            <div className="divizq">
                <div className="container">
                    <form 
                        style={{width: '70%', marginRight:'auto', marginLeft: 'auto'}} 
                        autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-tipoequipo">Device Type<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.tipoequipo === "" || valores.traducciones.tipoequipo === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.tipoequipo}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.tipos}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value);
                                            cambioFormulario(value);
                                        }}  
                                        value={tipoform}
                                        defaultValue={tipoform}
                                        isClearable={true}
                                        isDisabled={true}
                                        styles={styleDisable}                                        
                                    />} 
                                    control={control}
                                    id="i-tipoequipo"
                                    key="k-tipoequipo"                                    
                                    name='tipoequipo'
                                />                              
                            </div>
                        </div>
                        {
                            (tipoform === "") ? <></>
                            :
                            (
                                <>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-numeroserie">Serial Number<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.numeroserie === "" || valores.traducciones.numeroserie === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.numeroserie}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-numeroserie"
                                                key="k-numeroserie"
                                                defaultValue={registro ? registro.numeroserie : ''}
                                                name='numeroserie'
                                                style={(errorNumeroSerie) ? { borderColor: '#f90000'} : {}} 
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-estado">State<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.estado === "" || valores.traducciones.estado === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.estado}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.estados}
                                                    onChange={(value) => {
                                                        props.onChange(value);  
                                                        cambioEstado(value);                                                      
                                                    }}
                                                    placeholder=""
                                                    defaultValue={(registro) ? registro.estado : ""}
                                                    isClearable={true} 
                                                    isDisabled={(privModificar) ? false : true}
                                                    styles={(errorEstado) ? style : (privModificar) ? styleNormal : styleDisable}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-estado"
                                                key="k-estado"                                                
                                                name='estado'
                                            /> 
                                        </div>
                                    </div> 
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-condicion">Condition<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.condicion === "" || valores.traducciones.condicion === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.condicion}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.condiciones}
                                                    onChange={(value) => {
                                                        props.onChange(value);  
                                                        cambioCondicion(value);                                                      
                                                    }}
                                                    placeholder=""
                                                    value={condicion}
                                                    isClearable={true} 
                                                    isDisabled={(validarTextoVacio(estado)) ? (privModificar) ? false : true : true}
                                                    defaultValue={(registro) ? registro.condicion : ""}
                                                    styles={(errorCondicion) ? style : (privModificar) ? styleNormal : styleDisable}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-condicion"
                                                key="k-condicion"                                                
                                                name='condicion'
                                            /> 
                                        </div>
                                    </div> 
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-propiedadde">Property of<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.propiedadde === "" || valores.traducciones.propiedadde === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.propiedadde}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.propiedades}
                                                    onChange={(value) => {
                                                        props.onChange(value);                                                        
                                                    }}
                                                    placeholder=""
                                                    defaultValue={(registro) ? registro.propiedadde : ""}
                                                    isClearable={true} 
                                                    isDisabled={(privModificar) ? false : true}
                                                    styles={(errorPropiedad) ? style : (privModificar) ? styleNormal : styleDisable}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-propiedadde"
                                                key="k-propiedadde"                                                
                                                name='propiedadde'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-empresa">Enterprise<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.empresa === "" || valores.traducciones.empresa === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.empresa}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.empresas}
                                                    onChange={(value) => {
                                                        props.onChange(value); 
                                                        cambioEmpresa(value);                                                         
                                                    }}
                                                    placeholder=""
                                                    value={empresa}
                                                    defaultValue={empresa}
                                                    isClearable={true} 
                                                    isDisabled={(privModificar) ? false : true}
                                                    styles={(errorEmpresa) ? style : (privModificar) ? styleNormal : styleDisable}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-empresa"
                                                key="k-empresa"                                                
                                                name='empresa'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-planta">Plant/Office<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.planta === "" || valores.traducciones.planta === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.planta}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.plantas}
                                                    onChange={(value) => {
                                                        props.onChange(value); 
                                                        cambioPlanta(value);                                                       
                                                    }}
                                                    placeholder=""
                                                    value={planta}
                                                    defaultValue={planta}                                                    
                                                    isClearable={true} 
                                                    isDisabled={(privModificar) ? false : true}
                                                    styles={(errorPlanta) ? style : (privModificar) ? styleNormal : styleDisable}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-planta"
                                                key="k-planta"                                                
                                                name='planta'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-negocio">Business
                                            {
                                                (valores.traducciones.negocio === "" || valores.traducciones.negocio === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.negocio}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-negocio"
                                                key="k-negocio"
                                                defaultValue={registro ? registro.negocio : ''}
                                                name='negocio'                                                
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-localidad">Location
                                            {
                                                (valores.traducciones.localidad === "" || valores.traducciones.localidad === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.localidad}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-localidad"
                                                key="k-localidad"
                                                defaultValue={registro ? registro.localidad : ''}
                                                name='localidad'                                                
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-pais">Country
                                            {
                                                (valores.traducciones.pais === "" || valores.traducciones.pais === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.pais}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-pais"
                                                key="k-pais"
                                                defaultValue={registro ? registro.pais : ''}
                                                name='pais'                                                
                                            /> 
                                        </div>
                                    </div>                                    
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-marca">Brand<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.marca === "" || valores.traducciones.marca === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.marca}</span>
                                                    </div>
                                                )
                                            }                                            
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.marcas}
                                                    placeholder=""
                                                    onChange={(value) => {
                                                        props.onChange(value);
                                                        cambioMarca(value);                                                        
                                                    }}
                                                    isClearable={true} 
                                                    isDisabled={(privModificar) ? false : true}
                                                    value={marca}
                                                    defaultValue={marca}
                                                    styles={(errorMarca) ? style : (privModificar) ? styleNormal : styleDisable}
                                                    control={control}
                                                />} 
                                                control={control} 
                                                id="i-marca"
                                                key="k-marca"                                                
                                                name='marca'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-modelo">Model<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.modelo === "" || valores.traducciones.modelo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.modelo}</span>
                                                    </div>
                                                )
                                            }  
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.modelos}
                                                    onChange={(value) => {
                                                        props.onChange(value);
                                                        cambioModelo(value);                                                         
                                                    }}
                                                    placeholder=""                                                    
                                                    isClearable={true} 
                                                    value={modelo}
                                                    defaultValue={modelo}
                                                    isDisabled={(marca !== "") ? (privModificar) ? false : true : true}
                                                    styles={(errorModelo) ? style : (privModificar) ? styleNormal : styleDisable} 
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-modelo"
                                                key="k-modelo"                                                
                                                name='modelo'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-descripcion">Description / User<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.descripcion === "" || valores.traducciones.descripcion === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.descripcion}</span>
                                                    </div>
                                                )
                                            }  
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                                control={control}
                                                id="i-descripcion"
                                                key="k-descripcion"
                                                defaultValue={registro ? registro.descripcion : ''}
                                                name='descripcion'
                                                style={(errorDescripcion) ? { borderColor: '#f90000'} : {}} 
                                            /> 
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            (tipoform === "") ? <></>
                            :
                            (
                                <>
                                <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-sistemaoperativo">Operating System
                                            {
                                                (valores.traducciones.sistemaoperativo === "" || valores.traducciones.sistemaoperativo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.sistemaoperativo}</span>
                                                    </div>
                                                )
                                            } 
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.sistemasoperativos}
                                                    onChange={(value) => {
                                                        props.onChange(value);
                                                        cambioSistema(value);                                                        
                                                    }}
                                                    placeholder=""
                                                    value={sistema}
                                                    defaultValue={sistema}
                                                    isClearable={true} 
                                                    isDisabled={(privModificar) ? false : true}
                                                    styles={(privModificar) ? styleNormal : styleDisable} 
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-sistemaoperativo"
                                                key="k-sistemaoperativo"                                                
                                                name='sistemaoperativo'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-version">Operating System Version
                                            {
                                                (valores.traducciones.version === "" || valores.traducciones.version === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.version}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.versiones}
                                                    onChange={(value) => {
                                                        props.onChange(value); 
                                                        cambioVersion(value);                                                         
                                                    }}
                                                    placeholder=""
                                                    value={version}
                                                    isDisabled={(sistema !== "") ? (privModificar) ? false : true : true}
                                                    isClearable={true} 
                                                    styles={(privModificar) ? styleNormal : styleDisable} 
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-version"
                                                key="k-version"                                                
                                                name='version'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-mac">MAC Address
                                            {
                                                (valores.traducciones.mac === "" || valores.traducciones.mac === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.mac}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                                control={control}
                                                id="i-mac"
                                                key="k-mac"
                                                defaultValue={registro ? registro.mac : ''}
                                                name='mac'                                                 
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-ipequipo">IP / Number
                                            {
                                                (valores.traducciones.ipequipo === "" || valores.traducciones.ipequipo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.ipequipo}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                                control={control}
                                                id="i-ipequipo"
                                                key="k-ipequipo"
                                                defaultValue={registro ? registro.ipequipo : ''}
                                                name='ipequipo'                                                 
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-fechacompra">Purchase Date (YYYY/MM/DD)
                                            {
                                                (valores.traducciones.fechacompra === "" || valores.traducciones.fechacompra === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.fechacompra}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                name="fechacompra"
                                                id="i-fechacompra"
                                                className="bootstrap-input"
                                                control={control}
                                                defaultValue={registro ? registro.fechacompra : ''}
                                                render={({ onChange, value }) => (
                                                    <DatePicker
                                                        disabled={(!privModificar) ? 'disabled' : ''}
                                                        selected={value}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        onChange={onChange}
                                                        className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}                                                       
                                                    />
                                                )}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-fechaarriendo">Lease Date (YYYY/MM/DD)
                                            {
                                                (valores.traducciones.fechaarriendo === "" || valores.traducciones.fechaarriendo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.fechaarriendo}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                name="fechaarriendo"
                                                id="i-fechaarriendo"
                                                className="bootstrap-input"
                                                control={control}
                                                defaultValue={registro ? registro.fechaarriendo : ''}
                                                render={({ onChange, value }) => (
                                                    <DatePicker
                                                        disabled={(!privModificar) ? 'disabled' : ''}
                                                        selected={value}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        onChange={onChange}
                                                        className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}                                                       
                                                    />
                                                )}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-fecharevision">Review Date(YYYY/MM/DD)
                                            {
                                                (valores.traducciones.fecharevision === "" || valores.traducciones.fecharevision === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.fecharevision}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                name="fecharevision"
                                                id="i-fecharevision"
                                                className="bootstrap-input"
                                                control={control}
                                                defaultValue={registro ? registro.fecharevision : ''}
                                                render={({ onChange, value }) => (
                                                    <DatePicker
                                                        disabled={(!privModificar) ? 'disabled' : ''}   
                                                        selected={value}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        onChange={onChange}
                                                        className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}                                                       
                                                    />
                                                )}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-condicionrevision">Review Condition
                                            {
                                                (valores.traducciones.condicionrevision === "" || valores.traducciones.condicionrevision === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.condicionrevision}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                                control={control}
                                                id="i-condicionrevision"
                                                key="k-condicionrevision"
                                                defaultValue={registro ? registro.condicionrevision : ''}
                                                name='condicionrevision'                                                 
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-observaciones">Observations
                                            {
                                                (valores.traducciones.observaciones === "" || valores.traducciones.observaciones === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.observaciones}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<textarea disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} rows="3" cols="50"></textarea>}
                                                control={control}
                                                id="i-observaciones"
                                                key="k-observaciones"
                                                defaultValue={registro ? registro.observaciones : ''}
                                                name='observaciones'
                                            />                             
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-hostname">Hostname
                                            {
                                                (valores.traducciones.hostname === "" || valores.traducciones.hostname === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.hostname}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                                control={control}
                                                id="i-hostname"
                                                key="k-hostname"
                                                defaultValue={registro ? registro.hostname : ''}
                                                name='hostname'                                                 
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-arrendadoa">Leased to
                                            {
                                                (valores.traducciones.arrendadoa === "" || valores.traducciones.arrendadoa === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.arrendadoa}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                                control={control}
                                                id="i-arrendadoa"
                                                key="k-arrendadoa"
                                                defaultValue={registro ? registro.arrendadoa : ''}
                                                name='arrendadoa'                                                 
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-modificadopor">Modified by</label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-modificadopor"
                                                key="k-modificadopor"
                                                defaultValue={registro ? registro.modificadopor : ''}
                                                name='modificadopor'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-fechaarriendo">Modification Date (YYYY/MM/DD)</label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                name="fechaupdate"
                                                id="i-fechaupdate"
                                                className="bootstrap-input-disable"
                                                control={control}
                                                defaultValue={registro ? registro.fechaarriendo : ''}
                                                render={({ onChange, value }) => (
                                                    <DatePicker
                                                        disabled={'disabled'}
                                                        selected={value}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        onChange={onChange}
                                                        className="bootstrap-input-disable"                                                        
                                                    />
                                                )}
                                            /> 
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            (tipoform !== "") ? (
                                <>
                                    <br></br>
                                    <div className="row">
                                        {                            
                                            (privModificar)
                                            ? 
                                            (
                                                
                                                <div style={{width: '60%', marginRight:'auto', marginLeft: 'auto'}}>
                                                    <button className="boton-from">Save</button>
                                                </div>
                                            ) 
                                            :
                                            (
                                                <br></br>
                                            )
                                        }                        
                                    </div>  
                                </>
                            )
                            : (<></>)
                        }
                    </form>
                </div>
            </div>
        </>
    )
}

export default FormularioOtroDispositivo
