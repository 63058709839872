import * as Rutas from '../RutasServicios'

async function cuentas(aplicacion, token)
{
    const request= {
        "id_aplicacion": aplicacion
    }

    var bRequest = JSON.stringify(request, null, 2);
    
    const url = Rutas.URL_Aplicacion + "cuentas";    
    //const url = "https://localhost:44367/api/aplicacion/cuentas";    
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }        
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function certificados(aplicacion, token)
{
    const request= {
        "id_aplicacion": aplicacion
    }

    var bRequest = JSON.stringify(request, null, 2);
    
    const url = Rutas.URL_Aplicacion + "certificados";    
    //const url = "https://localhost:44367/api/aplicacion/certificados";    
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }        
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function filtrosAplicacion(token)
{ 
    const url = Rutas.URL_Aplicacion + "filtros";  
    console.log(url);  
    //const url = "https://localhost:44367/api/aplicacion/filtros";    
    
    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function obtenerCargasExcel(usuario, token)
{ 
    const url = Rutas.URL_Carga_Aplicacion + "cargas";    
    //const url = "https://localhost:44343/api/cargaservidor/cargas"; 

    const data = {        
        "usuario": usuario
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function subirArchivoExcel(file64, nombre, usuario, token)
{ 
    const url = Rutas.URL_Carga_Aplicacion + "subirexcel";      
    //const url = "https://localhost:44330/api/cargaaplicacion/subirexcel"; 
    

    const data = {
        "title": nombre,
        "file": file64,
        "desc": "archivo de carga excel",
        "usuario": usuario
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function valoresAplicacion(modulo, idioma, token)
{ 
    const url = Rutas.URL_Aplicacion + "valores";    
    //const url = "https://localhost:44367/api/aplicacion/valores";    

    const data = {
        "modulo": modulo,
        "idioma": idioma
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function aplicacionesTabla(paisfiltro, negocio, fabricante, ambiente, estado, aplicacion, tipo, privilegio, pais, token)
{ 
    const url = Rutas.URL_Aplicacion + "tabla";   
    console.log(url);   
    //const url = "https://localhost:44367/api/aplicacion/tabla"; 

    const request= {
        "tabla_neg": negocio,
        "tabla_fab": fabricante,
        "tabla_amb": ambiente,
        "tabla_est": estado,
        "tabla_app": aplicacion,
        "tabla_pais": paisfiltro,
        "tabla_tipo": tipo,
        "privilegio": privilegio,
        "pais": pais      
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function integraciones(id, token)
{ 
    const url = Rutas.URL_Aplicacion + "integraciones";    
    //const url = "https://localhost:44367/api/aplicacion/relacionservidor"; 

    const request= {
        "id_aplicacion": id              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function servidoresTabla(id, token)
{ 
    const url = Rutas.URL_Aplicacion + "relacionservidor";    
    //const url = "https://localhost:44367/api/aplicacion/relacionservidor"; 

    const request= {
        "id_aplicacion": id              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function servidoresTablaModal(id, localidad, pais, negocio, servidor, token)
{ 
    const url = Rutas.URL_Aplicacion + "tablamodal";    
    //const url = "https://localhost:44367/api/aplicacion/tablamodal"; 

    const request= {
        "id_aplicacion": id,
        "filtrolocalidad": localidad,
        "filtropais": pais,
        "filtronegocio": negocio,
        "filtroservidor": servidor              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function crearRelacionServidor(id, criticidad, servidores, token)
{ 
    const url = Rutas.URL_Aplicacion + "crearrelacionservidor";    
    //const url = "https://localhost:44367/api/aplicacion/crearrelacionservidor"; 

    const request= {
        "id_aplicacion": id,
        "criticidad": criticidad,
        "servidores": servidores            
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function eliminarRelacionServidor(id, servidores, token)
{ 
    const url = Rutas.URL_Aplicacion + "eliminarrelacionservidor";    
    //const url = "https://localhost:44367/api/aplicacion/eliminarrelacionservidor"; 

    const request= {
        "id_aplicacion": id,
        "servidores": servidores            
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function crearRelacionIntegracion(id, integraciones, token)
{ 
    const url = Rutas.URL_Aplicacion + "crearrelacionintegracion";    
    //const url = "https://localhost:44367/api/aplicacion/crearrelacionservidor"; 

    const request= {
        "id_aplicacion": id,
        "integraciones": integraciones            
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function eliminarRelacionIntegracion(id, integraciones, token)
{ 
    const url = Rutas.URL_Aplicacion + "eliminarrelacionintegracion";    
    //const url = "https://localhost:44367/api/aplicacion/eliminarrelacionservidor"; 

    const request= {
        "id_aplicacion": id,
        "integraciones": integraciones            
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function aplicacionesTablaSccm(aplicacion, servidor, token)
{ 
    const url = Rutas.URL_Aplicacion + "tablasccm";    
    //const url = "https://localhost:44367/api/aplicacion/tablasccm"; 

    const request= {
        "aplicacion": aplicacion,
        "servidor": servidor      
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function aplicacionesTablaSccmEquipos(aplicacion, equipo, token)
{ 
    const url = Rutas.URL_Aplicacion + "tablasccmequipo";    
    //const url = "https://localhost:44367/api/aplicacion/tablasccmequipo"; 

    const request= {
        "aplicacion": aplicacion,
        "equipo": equipo      
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function equiposTablaModal(estado, condicion, pais, localidad, planta, numeroserie, id_aplicacion, token)
{ 
    const url = Rutas.URL_Aplicacion + "tablamodalequipo";    
    //const url = "https://localhost:44367/api/aplicacion/tablamodalequipo";

    const request= {   
        "id_aplicacion": id_aplicacion,     
        "estado": estado,
        "condicion": condicion,
        "pais": pais,
        "localidad": localidad,
        "planta": planta,
        "numeroserie": numeroserie      
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

async function equiposTabla(id, token)
{ 
    const url = Rutas.URL_Aplicacion + "relacionequipo";    
    //const url = "https://localhost:44367/api/aplicacion/relacionequipo"; 

    const request= {
        "id_aplicacion": id              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function crearRelacionEquipo(id, equipos, token)
{ 
    const url = Rutas.URL_Aplicacion + "crearrelacionequipo";    
    //const url = "https://localhost:44367/api/aplicacion/crearrelacionequipo"; 

    const request= {
        "id_aplicacion": id,
        "equipos": equipos            
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function eliminarRelacionEquipo(id, equipos, token)
{ 
    const url = Rutas.URL_Aplicacion + "eliminarrelacionequipo";    
    //const url = "https://localhost:44367/api/aplicacion/eliminarrelacionequipo"; 

    const request= {
        "id_aplicacion": id,
        "equipos": equipos            
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function aplicacion(id, token)
{ 
    const url = Rutas.URL_Aplicacion + "aplicacion";    
    //const url = "https://localhost:44367/api/aplicacion/aplicacion"; 

    const request= {
        "id": id           
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function actualizar(data, dataAnterior, id, token)
{
    const url = Rutas.URL_Aplicacion + "actualizar";    
    //const url = "https://localhost:44367/api/aplicacion/actualizar"; 

    const update = { 
        "id_aplicacion": id.toString(),
        "drp": (data.drp !== dataAnterior.drp) ? (data.drp) ? "1" : "0" : null,
        "respaldos": (data.respaldos !== dataAnterior.respaldos) ? (data.respaldos) ? "1" : "0" : null,
        "procedimientoadmin": (data.procedimientoadmin !== dataAnterior.procedimientoadmin) ? (data.procedimientoadmin) ? "1" : "0" : null,
        "redundancia": (data.redundancia !== dataAnterior.redundancia) ? (data.redundancia) ? "1" : "0" : null,
        "waf": (data.waf !== dataAnterior.waf) ? (data.waf) ? "1" : "0" : null,       
    };

    update.nombre = (data.nombre !== undefined) ? data.nombre.value.toString().toUpperCase() : dataAnterior.nombre.value;
    update.ambiente = data.ambiente.value.toString().toUpperCase();
    update.version = data.version.toString().toUpperCase();
    update.comentario = (data.comentario !== dataAnterior.comentario) ? (data.comentario !== "") ? data.comentario.toString().toUpperCase() : "esnull" : null;
    update.tiposoftware = data.tiposoftware.value.toString().toUpperCase();

    if(data.tiposoftware.value !== "EXTERNAL" && data.tiposoftware.value !== "INTERNAL")
    {
        update.numerofactura = data.numerofactura.toString().toUpperCase();        
    } 
    else{
        update.numerofactura = (data.numerofactura !== dataAnterior.numerofactura) ? (data.numerofactura !== "") ? data.numerofactura.toString().toUpperCase() : "esnull" : null;
    } 
    update.costoanual = (data.costoanual !== dataAnterior.costoanual) ? (data.costoanual !== "") ? data.costoanual.toString().toUpperCase() : "esnull" : null;

    if(data.negocio !== undefined) update.negocio = (data.negocio !== dataAnterior.negocio) ? data.negocio.value.toString().toUpperCase() : "";
    if(data.estado !== undefined) update.estado = (data.estado !== dataAnterior.estado) ? data.estado.value.toString().toUpperCase() : "";
    if(data.pais !== undefined) update.pais = (data.pais !== dataAnterior.pais) ? data.pais.value.toString().toUpperCase() : "";
    
    if(data.basedatos !== undefined) update.basedatos = (data.basedatos !== dataAnterior.basedatos) ? data.basedatos.value.toString().toUpperCase() : "";
    if(data.versionbd !== undefined) update.versionbd = (data.versionbd !== dataAnterior.versionbd) ? data.versionbd.value.toString().toUpperCase() : "";
    
    if(data.fabricante !== undefined) update.fabricante = (data.fabricante !== dataAnterior.fabricante) ? (data.fabricante !== "" && data.fabricante !== null) ? data.fabricante.value.toString().toUpperCase() : "esnull" : "";
    if(data.proveedor !== undefined) update.proveedor = (data.proveedor !== dataAnterior.proveedor) ? (data.proveedor !== "" && data.proveedor !== null) ? data.proveedor.value.toString().toUpperCase() : "esnull" : "";
    if(data.lenguaje !== undefined) update.lenguaje = (data.lenguaje !== dataAnterior.lenguaje) ? (data.lenguaje !== "" && data.lenguaje !== null) ? data.lenguaje.value.toString().toUpperCase() : "esnull" : "";
    if(data.tipolicencia !== undefined) update.tipolicencia = (data.tipolicencia !== dataAnterior.tipolicencia) ? (data.tipolicencia !== "" && data.tipolicencia !== null) ? data.tipolicencia.value.toString().toUpperCase() : "esnull" : "";

    update.modificadopor = data.modificadopor;
    update.fechaupdate = new Date(data.fechaupdate).toISOString().substring(0, 10);

    update.software = (data.software !== dataAnterior.software) ? (data.software !== "") ? data.software.toString().toUpperCase() : "esnull" : null;    
    update.gerencia = (data.gerencia !== dataAnterior.gerencia) ? (data.gerencia !== "") ? data.gerencia.toString().toUpperCase() : "esnull" : null;
    update.observaciones = (data.observaciones !== dataAnterior.observaciones) ? (data.observaciones !== "") ? data.observaciones.toString().toUpperCase() : "esnull" : null;
    update.link = (data.link !== dataAnterior.link) ? (data.link !== "") ? data.link.toString() : "esnull" : null;
    update.observacionwaf = (data.observacionwaf !== dataAnterior.observacionwaf) ? (data.observacionwaf !== "") ? data.observacionwaf.toString().toUpperCase() : "esnull" : null;
    update.numerocontrato = (data.numerocontrato !== dataAnterior.numerocontrato) ? (data.numerocontrato !== "") ? data.numerocontrato.toString().toUpperCase() : "esnull" : null;    
    update.numerolicencia = (data.numerolicencia !== dataAnterior.numerolicencia) ? (data.numerolicencia !== "") ? data.numerolicencia.toString().toUpperCase() : "esnull" : null;    

    if(data.criticidad !== undefined) update.criticidad = (data.criticidad !== dataAnterior.criticidad) ? (data.criticidad !== "" && data.criticidad !== null) ? data.criticidad.value.toString().toUpperCase() : "esnull" : "";
    if(data.responsable !== undefined) update.responsable = (data.responsable !== dataAnterior.responsable) ? (data.responsable !== "" && data.responsable !== null) ? data.responsable.value.toString().toUpperCase() : "esnull" : "";
    if(data.plataforma !== undefined) update.plataforma = (data.plataforma !== dataAnterior.plataforma) ? (data.plataforma !== "" && data.plataforma !== null) ? data.plataforma.value.toString().toUpperCase() : "esnull" : "";
    if(data.plataformapublicacion !== undefined) update.plataformapublicacion = (data.plataformapublicacion !== dataAnterior.plataformapublicacion) ? (data.plataformapublicacion !== "" && data.plataformapublicacion !== null) ? data.plataformapublicacion.value.toString().toUpperCase() : "esnull" : "";
    if(data.sistemaoperativo !== undefined) update.sistemaoperativo = (data.sistemaoperativo !== dataAnterior.sistemaoperativo) ? (data.sistemaoperativo !== "" && data.sistemaoperativo !== null) ? data.sistemaoperativo.value.toString().toUpperCase() : "esnull" : "";
    if(data.autenticacion !== undefined) update.autenticacion = (data.autenticacion !== dataAnterior.autenticacion) ? (data.autenticacion !== "" && data.autenticacion !== null) ? data.autenticacion.value.toString().toUpperCase() : "esnull" : "";

    update.adminacceso = (data.adminacceso !== dataAnterior.adminacceso) ? (data.adminacceso !== "") ? data.adminacceso.toString().toUpperCase() : "esnull" : null;
    update.centralizada = (data.centralizada !== dataAnterior.centralizada) ? (data.centralizada !== "") ? data.centralizada.toString().toUpperCase() : "esnull" : null;

    update.fechadrp = (data.fechadrp !== dataAnterior.fechadrp) ? (data.fechadrp !== null) ? new Date(data.fechadrp).toISOString().substring(0, 10) : "esnull" : null;
    update.fecharespaldos = (data.fecharespaldos !== dataAnterior.fecharespaldos) ? (data.fecharespaldos !== null) ? new Date(data.fecharespaldos).toISOString().substring(0, 10) : "esnull" : null;

    update.fechafactura = (data.fechafactura !== dataAnterior.fechafactura) ? (data.fechafactura !== null) ? new Date(data.fechafactura).toISOString().substring(0, 10) : "esnull" : null;
    update.vencimientocontrato = (data.vencimientocontrato !== dataAnterior.vencimientocontrato) ? (data.vencimientocontrato !== null) ? new Date(data.vencimientocontrato).toISOString().substring(0, 10) : "esnull" : null;

    update.informara = (data.informara !== dataAnterior.informara) ? (data.informara !== "") ? data.informara.toString().toUpperCase() : "esnull" : null;

    var bRequest = JSON.stringify(update);
    

    var respuesta = await fetch(url, {
        method: "PUT",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function crear(data, token)
{
    const url = Rutas.URL_Aplicacion + "crear";    
    //const url = "https://localhost:44367/api/aplicacion/crear"; 

    const create = { 
        "nombre": data.nombre.toString().toUpperCase()        
    };

    //update.nombre = data.nombre.toString().toUpperCase();
    create.tiposoftware = data.tiposoftware.value.toString().toUpperCase();
    create.negocio = data.negocio.value.toString().toUpperCase();
    create.ambiente = data.ambiente.value.toString().toUpperCase();
    create.estado = data.estado.value.toString().toUpperCase();
    create.pais = data.pais.value.toString().toUpperCase();
    create.modificadopor = data.modificadopor;
    create.fechaupdate = new Date(data.fechaupdate).toISOString().substring(0, 10);
    create.basedatos = data.basedatos.value.toString().toUpperCase();
    create.versionbd = data.versionbd.value.toString().toUpperCase();

    create.software = (data.software !== "" && data.software !== null) ? data.software.toString().toUpperCase() : null;
    create.version = (data.version !== "" && data.version !== null) ? data.version.toString().toUpperCase() : null;
    create.comentario = (data.comentario !== "" && data.comentario !== null) ? data.comentario.toString().toUpperCase() : null;
    create.gerencia = (data.gerencia !== "" && data.gerencia !== null) ? data.gerencia.toString().toUpperCase() : null;
    create.observaciones = (data.observaciones !== "" && data.observaciones !== null) ? data.observaciones.toString().toUpperCase() : null;
    create.link = (data.link !== "" && data.link !== null) ? data.link.toString() : null;
    create.waf = (data.waf) ? "1" : "0";
    create.observacionwaf = (data.observacionwaf !== "" && data.observacionwaf !== null) ? data.observacionwaf.toString().toUpperCase() : null;
    create.numerocontrato = (data.numerocontrato !== "" && data.numerocontrato !== null) ? data.numerocontrato.toString().toUpperCase() : null;
    create.tipolicencia = (data.tipolicencia !== "" && data.tipolicencia !== null && data.tipolicencia !== undefined) ? data.tipolicencia.value.toString().toUpperCase() : null;
    create.numerofactura = (data.numerofactura !== "" && data.numerofactura !== null) ? data.numerofactura.toString().toUpperCase() : null;
    create.costoanual = (data.costoanual !== "" && data.costoanual !== null) ? data.costoanual.toString().toUpperCase() : null;
    create.numerolicencia = (data.numerolicencia !== "" && data.numerolicencia !== null) ? data.numerolicencia.toString().toUpperCase() : null;
    create.fabricante = (data.fabricante !== "" && data.fabricante !== null && data.fabricante !== undefined) ? data.fabricante.value.toString().toUpperCase() : null;
    create.proveedor = (data.proveedor !== "" && data.proveedor !== null && data.proveedor !== undefined) ? data.proveedor.value.toString().toUpperCase() : null;
    create.lenguaje = (data.lenguaje !== "" && data.lenguaje !== null && data.lenguaje !== undefined) ? data.lenguaje.value.toString().toUpperCase() : null;
    
    create.plataforma = (data.plataforma !== "" && data.plataforma !== null && data.plataforma !== undefined) ? data.plataforma.value.toString().toUpperCase() : null;
    create.plataformapublicacion = (data.plataformapublicacion !== "" && data.plataformapublicacion !== null && data.plataformapublicacion !== undefined) ? data.plataformapublicacion.value.toString().toUpperCase() : null;
    create.responsable = (data.responsable !== "" && data.responsable !== null && data.responsable !== undefined) ? data.responsable.value.toString().toUpperCase() : null;
    create.sistemaoperativo = (data.sistemaoperativo !== "" && data.sistemaoperativo !== null && data.sistemaoperativo !== undefined) ? data.sistemaoperativo.value.toString().toUpperCase() : null;
    create.criticidad = (data.criticidad !== "" && data.criticidad !== null && data.criticidad !== undefined) ? data.criticidad.value.toString().toUpperCase() : null;
    create.autenticacion = (data.autenticacion !== "" && data.autenticacion !== null && data.autenticacion !== undefined) ? data.autenticacion.value.toString().toUpperCase() : null;

    create.redundancia = (data.redundancia) ? "1" : "0";
    create.procedimientoadmin = (data.procedimientoadmin) ? "1" : "0";
    create.drp = (data.drp) ? "1" : "0";   
    create.respaldos = (data.respaldos) ? "1" : "0";   
    
    create.fechadrp = (data.fechadrp !== null && data.fechadrp !== "" && data.fechadrp !== undefined) ? new Date(data.fechadrp).toISOString().substring(0, 10) : null;
    create.fecharespaldos = (data.fecharespaldos !== null && data.fecharespaldos !== "" && data.fecharespaldos !== undefined) ? new Date(data.fecharespaldos).toISOString().substring(0, 10) : null;

    create.adminacceso = (data.adminacceso !== "" && data.adminacceso !== null) ? data.adminacceso.toString().toUpperCase() : null;  
    create.centralizada = (data.centralizada !== "" && data.centralizada !== null) ? data.centralizada.toString().toUpperCase() : null;  
    
    create.fechafactura = (data.fechafactura !== null && data.fechafactura !== "" && data.fechafactura !== undefined) ? new Date(data.fechafactura).toISOString().substring(0, 10) : null;
    create.vencimientocontrato = (data.vencimientocontrato !== null && data.vencimientocontrato !== "" && data.vencimientocontrato !== undefined) ? new Date(data.vencimientocontrato).toISOString().substring(0, 10) : null;    
    create.informara = (data.informara !== "" && data.informara !== null) ? data.informara.toString().toUpperCase() : null;

    var bRequest = JSON.stringify(create);
    

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function eliminarAplicacion(id, token)
{ 
    const url = Rutas.URL_Aplicacion + "eliminar";    
    //const url = "https://localhost:44367/api/aplicacion/eliminar"; 
    
    const data = {        
        "id": id
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function eliminarAplicacionrequest(id, token) {
    try {
        var respuesta = await eliminarAplicacion(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function eliminarRelacionesEquipo(id, equipos, token) {
    try {
        var respuesta = await eliminarRelacionEquipo(id, equipos, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerEquiposTablaModal(estado, condicion, pais, localidad, planta, numeroserie, id_aplicacion, token) {
    try {
        var respuesta = await equiposTablaModal(estado, condicion, pais, localidad, planta, numeroserie, id_aplicacion, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearRelacionesEquipo(id, equipos, token) {
    try {
        var respuesta = await crearRelacionEquipo(id, equipos, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerServidoresModal(id, localidad, pais, negocio, servidor, token) {
    try {
        var respuesta = await servidoresTablaModal(id, localidad, pais, negocio, servidor, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearRelacionesServidor(id, criticidad, servidores, token) {
    try {
        var respuesta = await crearRelacionServidor(id, criticidad, servidores, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearRelacionesIntegracion(id, integraciones, token) {
    try {
        var respuesta = await crearRelacionIntegracion(id, integraciones, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function eliminarRelacionesIntegracion(id, integraciones, token) {
    try {
        var respuesta = await eliminarRelacionIntegracion(id, integraciones, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function eliminarRelacionesServidor(id, servidores, token) {
    try {
        var respuesta = await eliminarRelacionServidor(id, servidores, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearAplicacion(data, token) {
    try {
        var respuesta = await crear(data, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

export async function actualizarAplicacion(data, dataAnterior, id, token) {
    try {
        var respuesta = await actualizar(data, dataAnterior, id, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerFiltros(token) {
    try {
        var respuesta = await filtrosAplicacion(token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerEquipos(id, token) {
    try {
        var respuesta = await equiposTabla(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerIntegraciones(id, token) {
    try {
        var respuesta = await integraciones(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerServidores(id, token) {
    try {
        var respuesta = await servidoresTabla(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerValores(modulo, idioma, token) {
    try {
        var respuesta = await valoresAplicacion(modulo, idioma, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerAplicacion(id, token) {
    try {
        var respuesta = await aplicacion(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerTablaSccm(aplicacion, servidor, token) {
    try {
        var respuesta = await aplicacionesTablaSccm(aplicacion, servidor, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerTablaSccmEquipos(aplicacion, equipo, token) {
    try {
        var respuesta = await aplicacionesTablaSccmEquipos(aplicacion, equipo, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerTabla(paisfiltro, negocio, fabricante, ambiente, estado, aplicacion, privilegio, tipo, pais, token) {
    try {
        var respuesta = await aplicacionesTabla(paisfiltro, negocio, fabricante, ambiente, estado, aplicacion, tipo, privilegio, pais, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function uploadExcel(file64, nombre, usuario, token) {
    try {
        var respuesta = await subirArchivoExcel(file64, nombre, usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function cargasExcel(usuario, token) {
    try {
        var respuesta = await obtenerCargasExcel(usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function obtenerCuentas(aplicacion, token) {
    try {
        var respuesta = await cuentas(aplicacion, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function obtenerCertificados(aplicacion, token) {
    try {
        var respuesta = await certificados(aplicacion, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

async function tablaBitacoras(paisfiltro, negocio, fabricante, ambiente, estado, aplicacion, tipo, privilegio, pais, token)
{ 
    const url = Rutas.URL_Aplicacion + "bitacoras";   
    console.log(url);   
    //const url = "https://localhost:44367/api/aplicacion/tabla"; 

    const request= {
        "tabla_neg": negocio,
        "tabla_fab": fabricante,
        "tabla_amb": ambiente,
        "tabla_est": estado,
        "tabla_app": aplicacion,
        "tabla_pais": paisfiltro,
        "tabla_tipo": tipo,
        "privilegio": privilegio,
        "pais": pais      
    };

    console.log(request)

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function obtenerTablaBitacoras(paisfiltro, negocio, fabricante, ambiente, estado, aplicacion, tipo, privilegio, pais, token) {
    try {
        var respuesta = await tablaBitacoras(paisfiltro, negocio, fabricante, ambiente, estado, aplicacion, tipo, privilegio, pais, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}