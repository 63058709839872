import React, {useState} from 'react'
import { obtenerValores, obtenerFiltrosEquipos } from '../functions/FuncReportes'
import swal from 'sweetalert';
import Loading from '../Loading';
import Select from 'react-select'
import "../css/reportes.css";
import styled from 'styled-components';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SeccionesEE from './SeccionesEE';

import { styleNormal } from '../Commons/Styles'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
        border: '1px solid #bfbfbf',
      },
      roottotales: {
          display: 'flex',
          width: '100%',
          border: '1px solid #bfbfbf',
          marginTop: '10px',
          marginBottom: '10px'
      },
      rootseleccionado: {
          display: 'flex',
          width: '100%',
          marginTop: '10px',
          marginBottom: '10px',
          backgroundColor: '#f9854b',
          border: '2px solid #9a3807',
          marginLeft: '0px',
          color: 'white'        
      },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '80%'
    },
    detailsseleccionado: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        color: 'white'  
    },
    divboton: {
        display: 'flex',
        flexDirection: 'column',
        width: '10%'
    },    
    content: {
      flex: '1 0 auto',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important'
    },
    cover: {
      width: 100,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
  }));

  const Button = styled.button`
    float:right;
    border-radius: 5px;
    height: 100%;
`;


const ReporteEE = ({token}) => {

    const [valores, setValores] = useState(null);
    const [opciones, setOpciones] = useState(null);
    const [seleccion, setSeleccion] = useState("");
    const [gerencia, setGerencia] = useState("");
    const [filtros, setFiltros] = useState(null);
    const [total, setTotal] = useState("");

    const classes = useStyles();
    const theme = useTheme();

    function seleccionarPais(valor)
    {
        setSeleccion(valor)
    }

    async function obtenerFiltrosReporte(){
        await obtenerFiltrosEquipos(token)
        .then((response) => {
            if(response.cod === 1)
            {
                const val = {
                    "gerencias": response.gerencias                    
                };

                let array = [];
                const listas = ["gerencias"];

                listas.forEach(lista => {   
                    array = [];                 
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                setFiltros(val);

            }
            else {
                swal("Report", response.mensaje, "warning");
            }
        })
    }

    async function obtenerValoresReporte()
    {
        await obtenerValores(gerencia, token)
        .then((response) => {
            if(response.cod === 1)
            {
                setTotal(response.cantidadtotal);
                setValores(response.resultados)
                console.log(response)
            }
            else {
                swal("Report", response.mensaje, "warning");
            }
        })
    }

    function cambioGerencia(value){
        if(value !== null && value !== "" && value !== undefined)
        {
            setGerencia(value.value);
            setValores(null);
        }
        else{
            setGerencia("");
            setValores(null);
        } 
    }
    
    React.useEffect(() => {
        
        if(filtros === null) obtenerFiltrosReporte();

        obtenerValoresReporte();

    }, [gerencia])

    if(filtros === null) return (<Loading />)
    else return (
        <>
            <br></br>
            <br></br>
            <div className="div-selector">
                <div className="div-selector-options">
                    <Select
                        options={filtros.gerencias}
                        onChange={(value) => {
                            cambioGerencia(value)
                        }}
                        placeholder="Select Master"
                        isClearable={true}                         
                        defaultValue={null}
                        styles={styleNormal}
                    />
                </div>
            </div>

            {
                (valores === null) ? (<Loading />) :
                (
                    <div className="divgeneral-reporte">
                        <div className="divder-reporte">
                            <SeccionesEE valores={valores} pais={seleccion}/>
                        </div>
                        <div className="divizq-reporte">
                            <div className="divcartas">

                            <Card className={classes.roottotales}>
                                    
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography component="h4" variant="h4">{"TOTAL"}</Typography>
                                        <Typography variant="h5" color="textSecondary">{total}</Typography>
                                    </CardContent>
                                </div>
                            </Card>

                            {
                                valores.map((v) => 
                                
                                <Card className={(seleccion !== v.pais) ? classes.root : classes.rootseleccionado} onClick={() => {seleccionarPais(v.pais)}}>
                                    <div className={classes.details}>
                                        <CardContent className={classes.content}>
                                            <Typography component="h5" variant="h5">{v.pais}</Typography>
                                            <Typography variant="subtitle1" color="white">{v.cantidadtotal}</Typography>
                                        </CardContent>
                                    </div>
                                </Card>
                                
                                )
                            }
                            </div>
                        </div>
                    </div>
                )
            }
            
        </>
    )
}

export default ReporteEE
