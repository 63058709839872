import * as Rutas from '../RutasServicios'
var graph = require('@microsoft/microsoft-graph-client');

async function usuarioBD(account, token)
{
    var d = new Date();
    var mes = d.getMonth()+1;
    var fecha = d.getFullYear() + "/" + mes + "/" + d.getDate() + " - " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

    const request ={
        "Username": account.username,
        "Password": null,
        "Correo": account.username,
        "Nombre": account.name.toString().toUpperCase(),
        "Rol": "SIN ASIGNAR",
        "Pais": "CHILE",
        "Idioma": "ENGLISH",
        "Localidad": "SANTIAGO",
        "Planta": "ARAUCO BIOENERGIA - OFICINAS EL GOLF",
        "TipoLogin": "ms",
        "FechaIngreso": fecha,
        "Notificacion": "0",
        "Privilegios": null
    }

    var bRequest = JSON.stringify(request, null, 2);
    const url = Rutas.URL_Usuario + "usuarioms";
    //const url = "https://localhost:44358/api/login/usuarioms"; 

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }        
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

const accessTokenRequest = {    
    navigateToLoginRequestUrl: true,
    account : null
}

function getAuthenticatedClient(accessToken) {

    const client = graph.Client.init({
      authProvider: (done) => {
        done(null, accessToken);
      }
    });
  
    return client;
}

async function obtenerGrupos (publicClientApplication, token, id) {
    try {

        const client = await getAuthenticatedClient(token.accessToken);
        var req = "/groups?$filter=startswith(displayName,'a')";
        var request = "/users/" + id + "/transitiveMemberOf/microsoft.graph.group";
        var request2 = "/me/memberOf/$/microsoft.graph.group";
        var request3 = "/users/" + id + "/memberOf/microsoft.graph.group?$count=true&$orderby=displayName&$filter=startswith(displayName,'a')";

        const groups = await client.api(request2).select("displayName").top(999).get();
        
        return groups; 
    } catch (error) {
        return null;
    }
}

export async function validarDatos(publicClientApplication) {

    try {
        const accounts = publicClientApplication.getAllAccounts();
        if (accounts.length <= 0) return false;
        accessTokenRequest.account = accounts[0];

        var token =  await publicClientApplication.acquireTokenSilent(accessTokenRequest);
        var grupos = await obtenerGrupos(publicClientApplication, token, accounts[0]["localAccountId"]);

        var respuesta = false;

        if(grupos.value !== null)
        {
            grupos.value.forEach(g => {    
                if(g.displayName === "GRP-G-Inventarios_TI")
                {
                    respuesta = true;
                }        
            });
        }
        else return false;

        return respuesta;
        
    } catch (error) {
        return false;
    }
        
}


export async function obtenerUsuarioBD(account, token) {
    try {
        var respuesta = await usuarioBD(account, token);        
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}