import React from 'react'
import Sidebar from '../../Sidebar'
import MAplicacion from './MAplicacion'



const Maestroaplicacion = ({instance, t}) => {
    return (
        <>
        <Sidebar instance={instance} despliegue={false}/>
        <br></br>        
        <MAplicacion token={t}/>
        </>
    )
}

export default Maestroaplicacion
