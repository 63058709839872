import React from 'react'
import Sidebar from '../Sidebar'
import * as TiIcons from 'react-icons/ti';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import CreacionSalaServidor from './CreacionSalaServidor';


import { ContainerForm, ButtonVolver } from '../Commons/Styles'


const FormCreacionSalaServidor = ({instance, t}) => {
    let priv = sessionStorage.getItem('sala_crear');
    const history = useHistory();

    function volver()
    {
        history.push("/salasservidores");
    }

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });
    
    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <>        
            <Sidebar instance={instance} despliegue={false}/>
            <ContainerForm>
                <div className="headerformulario">
                    <div className="headerform-izq">
                        <ButtonVolver onClick={volver} className="tooltipboton">
                        <TiIcons.TiArrowBack/>             
                        <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>
                    </div>
                    <div className="headerform-mid">
                        <div className="divtituloform">
                            <label className="tituloformulario">Server Room Creation Form</label>
                        </div>                        
                    </div>
                </div>
                <CreacionSalaServidor token={t}/>
            </ContainerForm> 
            <br></br>           
            <br></br>           
        </>    
    )
}

export default FormCreacionSalaServidor
