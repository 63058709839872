import * as Rutas from '../RutasServicios'

async function cuentasServidor(servidor, token)
{
    const request= {
        "servidor": servidor
    }

    var bRequest = JSON.stringify(request, null, 2);
    
    const url = Rutas.URL_Servicio + "cuentas";    
    //const url = "https://localhost:44325/api/servidor/cuentas";    
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }        
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function certificadosServidor(servidor, token)
{
    const request= {
        "servidor": servidor
    }

    var bRequest = JSON.stringify(request, null, 2);
    
    const url = Rutas.URL_Servicio + "certificados";    
    //const url = "https://localhost:44325/api/servidor/certificados";    
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }        
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function aplicacionesServidor(servidor, token)
{
    const request= {
        "servidor": servidor
    }

    var bRequest = JSON.stringify(request, null, 2);
    
    const url = Rutas.URL_Servicio + "aplicaciones";    
    //const url = "https://localhost:44325/api/servidor/aplicaciones";    
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }        
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function aplicacionesServidorSccm(servidor, token)
{
    const url = Rutas.URL_Servicio + "aplicacionessccm";    
    //const url = "https://localhost:44325/api/servidor/aplicacionessccm";

    const request= {
        "servidor": servidor
    }

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }        
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function tablaServidores(estado, localidad, negocio, pais, sistemaOperativo, servidor, red, token)
{
    let nivelUser = sessionStorage.getItem('ser_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');

    const request= {
        "nivel": nivelUser,
        "pais": paisUser,
        "localidad": localidadUser,
        "filtrolocalidad": localidad,
        "filtropais": pais,
        "filtronegocio": negocio,
        "filtrosistemaop": sistemaOperativo,
        "filtroservidor": servidor,
        "filtroestado": estado,
        "filtrored": red
    }

    var bRequest = JSON.stringify(request, null, 2);

    const url = Rutas.URL_Servicio + "tabla";    
    //const url = "https://localhost:44325/api/servidor/tabla";    
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }        
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function tablaServidoresAplicaciones(estado, localidad, negocio, pais, sistemaOperativo, servidor, token)
{
    let nivelUser = sessionStorage.getItem('ser_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');

    const request= {
        "nivel": nivelUser,
        "pais": paisUser,
        "localidad": localidadUser,
        "filtrolocalidad": localidad,
        "filtropais": pais,
        "filtronegocio": negocio,
        "filtrosistemaop": sistemaOperativo,
        "filtroservidor": servidor,
        "filtroestado": estado,
    }

    var bRequest = JSON.stringify(request, null, 2);

    const url = Rutas.URL_Servicio + "resumenaplicaciones";    
    //const url = "https://localhost:44325/api/servidor/resumenaplicaciones";    
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }        
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function filtroServidores(token)
{ 
    const url = Rutas.URL_Servicio + "filtros";  
    //const url = "https://localhost:44325/api/servidor/filtros";   

    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function obtenerServidor(id, token)
{ 
    const url = Rutas.URL_Servicio + "servidor";    
    //const url = "https://localhost:44325/api/servidor/servidor"; 

    const request= {
        "servidor": id.toString()        
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function subirArchivoExcel(file64, nombre, usuario, token)
{ 
    const url = Rutas.URL_Carga_Servidor + "subirexcel";
    //const url = "https://localhost:44343/api/cargaservidor/subirexcel"; 

    const data = {
        "title": nombre,
        "file": file64,
        "desc": "archivo de carga excel",
        "usuario": usuario
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function obtenerCargasExcel(usuario, token)
{ 
    const url = Rutas.URL_Carga_Servidor + "cargas";    
    //const url = "https://localhost:44343/api/cargaservidor/cargas"; 

    const data = {        
        "usuario": usuario
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function actualizarServidor(data, dataAnterior, id, token)
{ 
    const url = Rutas.URL_Servicio + "actualizar"; 
    //const url = "https://localhost:44325/api/servidor/actualizar"; 

    const update = { 
        "id_servidor": id.toString(),
        "fileserver": (data.fileserver !== dataAnterior.fileserver) ? (data.fileserver) ? "1" : "0" : null,
        "printserver": (data.printserver !== dataAnterior.printserver) ? (data.printserver) ? "1" : "0" : null,
        "webserver": (data.webserver !== dataAnterior.webserver) ? (data.webserver) ? "1" : "0" : null,
        "aplicaciones": (data.aplicaciones !== dataAnterior.aplicaciones) ? (data.aplicaciones) ? "1" : "0" : null,
        "servidorbd": (data.servidorbd !== dataAnterior.servidorbd) ? (data.servidorbd) ? "1" : "0" : null,
        "respaldodata": (data.respaldodata !== dataAnterior.respaldodata) ? (data.respaldodata) ? "1" : "0" : null,
        "respaldoserver": (data.respaldoserver !== dataAnterior.respaldoserver) ? (data.respaldoserver) ? "1" : "0" : null,
        //"drp": (data.drp !== dataAnterior.drp) ? (data.drp) ? "1" : "0" : null,
        "pachadosccm": (data.pachadosccm !== dataAnterior.pachadosccm) ? (data.pachadosccm) ? "1" : "0" : null
    };

    if(data.servidor !== dataAnterior.servidor) update.servidor = data.servidor.toString().toUpperCase();

    //if(data.propietariored !== undefined) update.propietariored = (data.propietariored !== dataAnterior.propietariored) ? data.propietariored.value.toString().toUpperCase() : "";
    if(data.localidad !== undefined) update.localidad = (data.localidad !== dataAnterior.localidad) ? data.localidad.value.toString().toUpperCase() : "";
    if(data.plataforma !== undefined) update.plataforma = (data.plataforma !== dataAnterior.plataforma) ? data.plataforma.value.toString().toUpperCase() : "";
    if(data.estado !== undefined) update.estado = (data.estado !== dataAnterior.estado) ? data.estado.value.toString().toUpperCase() : "";
    if(data.criticidad !== undefined) update.criticidad = (data.criticidad !== dataAnterior.criticidad) ? data.criticidad.value.toString().toUpperCase() : "";

    if(data.negocio !== undefined) update.negocio = (data.negocio !== dataAnterior.negocio) ? (data.negocio !== "" && data.negocio !== null) ? data.negocio.value.toString().toUpperCase() : "esnull" : "";
    if(data.ambiente !== undefined) update.ambiente = (data.ambiente !== dataAnterior.ambiente) ? (data.ambiente !== "" && data.ambiente !== null) ? data.ambiente.value.toString().toUpperCase() : "esnull" : "";
    if(data.responsable !== undefined) update.responsable = (data.responsable !== dataAnterior.responsable) ? (data.responsable !== "" && data.responsable !== null) ? data.responsable.value.toString().toUpperCase() : "esnull" : "";
    if(data.soservidor !== undefined) update.soservidor = (data.soservidor !== dataAnterior.soservidor) ? (data.soservidor !== "" && data.soservidor !== null) ? data.soservidor.value.toString().toUpperCase() : "esnull" : "";
    if(data.tiposervidor !== undefined) update.tiposervidor = (data.tiposervidor !== dataAnterior.tiposervidor) ? (data.tiposervidor !== "" && data.tiposervidor !== null) ? data.tiposervidor.value.toString().toUpperCase() : "esnull" : "";
    if(data.antivirus !== undefined) update.antivirus = (data.antivirus !== dataAnterior.antivirus) ? (data.antivirus !== "" && data.antivirus !== null) ? data.antivirus.value.toString().toUpperCase() : "esnull" : "";
    if(data.versionservidor !== undefined) update.versionservidor = (data.versionservidor !== dataAnterior.versionservidor) ? (data.versionservidor !== "" && data.versionservidor !== null) ? data.versionservidor.value.toString().toUpperCase() : "esnull" : "";
    if(data.tipobd !== undefined) update.tipobd = (data.tipobd !== dataAnterior.tipobd) ? (data.tipobd !== "" && data.tipobd !== null) ? data.tipobd.value.toString().toUpperCase() : "esnull" : "";
    if(data.tiporeinicio !== undefined) update.tiporeinicio = (data.tiporeinicio !== dataAnterior.tiporeinicio) ? (data.tiporeinicio !== "" && data.tiporeinicio !== null) ? data.tiporeinicio.value.toString().toUpperCase() : "esnull" : "";
    if(data.marca !== undefined) update.marca = (data.marca !== dataAnterior.marca) ? (data.marca !== "" && data.marca !== null) ? data.marca.value.toString().toUpperCase() : "esnull" : ""; 
    if(data.propiedadde !== undefined) update.propiedadde = (data.propiedadde !== dataAnterior.propiedadde) ? (data.propiedadde !== "" && data.propiedadde !== null) ? data.propiedadde.value.toString().toUpperCase() : "esnull" : "";    
    
    update.ubicacionrespaldodata = (data.ubicacionrespaldodata !== dataAnterior.ubicacionrespaldodata) ? (data.ubicacionrespaldodata !== "") ? update.ubicacionrespaldodata = data.ubicacionrespaldodata.toString().toUpperCase() : "esnull" : null;
    update.ubicacionrespaldoserver = (data.ubicacionrespaldoserver !== dataAnterior.ubicacionrespaldoserver) ? (data.ubicacionrespaldoserver !== "") ? update.ubicacionrespaldoserver = data.ubicacionrespaldoserver.toString().toUpperCase() : "esnull" : null;

    update.ipservidor = (data.ipservidor !== dataAnterior.ipservidor) ? (data.ipservidor !== "") ? update.ipservidor = data.ipservidor.toString().toUpperCase() : "esnull" : null;
    update.ippublica = (data.ippublica !== dataAnterior.ippublica) ? (data.ippublica !== "") ? update.ippublica = data.ippublica.toString().toUpperCase() : "esnull" : null;
    update.vlan = (data.vlan !== dataAnterior.vlan) ? (data.vlan !== "") ? update.vlan = data.vlan.toString().toUpperCase() : "esnull" : null;
    update.numeroserie = (data.numeroserie !== dataAnterior.numeroserie) ? (data.numeroserie !== "") ? update.numeroserie = data.numeroserie.toString().toUpperCase() : "esnull" : null;
    update.descripcion = (data.descripcion !== dataAnterior.descripcion) ? (data.descripcion !== "") ? update.descripcion = data.descripcion.toString().toUpperCase() : "esnull" : null;
    update.comentario = (data.comentario !== dataAnterior.comentario) ? (data.comentario !== "") ? update.comentario = data.comentario.toString().toUpperCase() : "esnull" : null;
    update.administradortexto = (data.administradortexto !== dataAnterior.administradortexto) ? (data.administradortexto !== "") ? update.administradortexto = data.administradortexto.toString().toUpperCase() : "esnull" : null;       

    update.modificadopor = data.modificadopor;
    update.fechaupdate = new Date(data.fechaupdate).toISOString().substring(0, 10);

    update.fechahabilitacion = (data.fechahabilitacion !== dataAnterior.fechahabilitacion) ? (data.fechahabilitacion !== null) ? update.fechahabilitacion = new Date(data.fechahabilitacion).toISOString().substring(0, 10) : "esnull" : null;
    update.fechabaja = (data.fechabaja !== dataAnterior.fechabaja) ? (data.fechabaja !== null) ? update.fechabaja = new Date(data.fechabaja).toISOString().substring(0, 10) : "esnull" : null;
    update.fechaexpiracion = (data.fechaexpiracion !== dataAnterior.fechaexpiracion) ? (data.fechaexpiracion !== null) ? update.fechaexpiracion = new Date(data.fechaexpiracion).toISOString().substring(0, 10) : "esnull" : null;
    update.ultimoreinicio = (data.ultimoreinicio !== dataAnterior.ultimoreinicio) ? (data.ultimoreinicio !== null) ? update.ultimoreinicio = new Date(data.ultimoreinicio).toISOString().substring(0, 10) : "esnull" : null;
    //update.fechadrp = (data.fechadrp !== dataAnterior.fechadrp) ? (data.fechadrp !== null) ? update.fechadrp = new Date(data.fechadrp).toISOString().substring(0, 10) : "esnull" : null;

    update.informara = (data.informara !== dataAnterior.informara) ? (data.informara !== "") ? data.informara.toString().toUpperCase() : "esnull" : null;
    //update.umbral = (data.umbral !== dataAnterior.umbral) ? (data.umbral !== "") ? update.umbral = data.umbral.toString().toUpperCase() : "esnull" : null;

    var bRequest = JSON.stringify(update);

    var respuesta = await fetch(url, {
        method: "PUT",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function crearServidor(data, token){

    const url = Rutas.URL_Servicio + "crearform";    
    //const url = "https://localhost:44325/api/servidor/crearform";

    const create = { 
        //"propietariored": data.propietariored.value.toString(),
        "servidor": data.servidor.toString().toUpperCase(),
        "localidad": data.localidad.value.toString(),
        "plataforma": data.plataforma.value.toString(),        
        "criticidad": data.criticidad.value.toString(),
        "estado": data.estado.value.toString(),
        "fileserver": (data.fileserver) ? "1" : "0",
        "printserver": (data.printserver) ? "1" : "0",
        "webserver": (data.webserver) ? "1" : "0",
        "aplicaciones": (data.aplicaciones) ? "1" : "0",
        "servidorbd": (data.servidorbd) ? "1" : "0",
        "respaldodata": (data.respaldodata) ? "1" : "0",
        "respaldoserver": (data.respaldoserver) ? "1" : "0",
        //"drp": (data.drp) ? "1" : "0",
        "pachadosccm": (data.pachadosccm) ? "1" : "0"   
    };

    create.tiposervidor = (data.tiposervidor !== "" && data.tiposervidor !== null && data.tiposervidor !== undefined) ? data.tiposervidor.value.toString().toUpperCase() : null;
    create.negocio = (data.negocio !== "" && data.negocio !== null && data.negocio !== undefined) ? data.negocio.value.toString().toUpperCase() : null;
    create.ambiente = (data.ambiente !== "" && data.ambiente !== null && data.ambiente !== undefined) ? data.ambiente.value.toString().toUpperCase() : null;
    create.responsable = (data.responsable !== "" && data.responsable !== null && data.responsable !== undefined) ? data.responsable.value.toString().toUpperCase() : null;
    create.soservidor = (data.soservidor !== "" && data.soservidor !== null && data.soservidor !== undefined) ? data.soservidor.value.toString().toUpperCase() : null;
    create.antivirus = (data.antivirus !== "" && data.antivirus !== null && data.antivirus !== undefined) ? data.antivirus.value.toString().toUpperCase() : null;
    create.versionservidor = (data.versionservidor !== "" && data.versionservidor !== null && data.versionservidor !== undefined) ? data.versionservidor.value.toString().toUpperCase() : null;
    create.tipobd = (data.tipobd !== "" && data.tipobd !== null && data.tipobd !== undefined) ? data.tipobd.value.toString().toUpperCase() : null;
    create.tiporeinicio = (data.tiporeinicio !== "" && data.tiporeinicio !== null && data.tiporeinicio !== undefined) ? data.tiporeinicio.value.toString().toUpperCase() : null;
    create.marca = (data.marca !== "" && data.marca !== null && data.marca !== undefined) ? data.marca.value.toString().toUpperCase() : null;
    create.propiedadde = (data.propiedadde !== "" && data.propiedadde !== null && data.propiedadde !== undefined) ? data.propiedadde.value.toString().toUpperCase() : null;
    
    //create.negocio = (data.negocio !== "" && data.negocio !== null) ? data.negocio.toString().toUpperCase() : null;
    //create.ambiente = (data.ambiente !== "" && data.ambiente !== null) ? data.ambiente.toString().toUpperCase() : null;    
    //create.responsable = (data.responsable !== "" && data.responsable !== null) ? data.responsable.toString().toUpperCase() : null;
    //create.soservidor = (data.soservidor !== "" && data.soservidor !== null) ? data.soservidor.toString().toUpperCase() : null;
    //create.tiposervidor = (data.tiposervidor !== "" && data.tiposervidor !== null) ? data.tiposervidor.toString().toUpperCase() :  null;    
    //create.antivirus = (data.antivirus !== "" && data.antivirus !== null) ? data.antivirus.toString().toUpperCase() : null;
    //create.versionservidor = (data.versionservidor !== "" && data.versionservidor !== null) ? data.versionservidor.toString().toUpperCase() : null;
    //create.tipobd = (data.tipobd !== "" && data.tipobd !== null) ? data.tipobd.toString().toUpperCase() : null;
    //create.marca = (data.marca !== "" && data.marca !== null) ? data.marca.toString().toUpperCase() : null;
    //create.propiedadde = (data.propiedadde !== "" && data.propiedadde !== null) ? data.propiedadde.toString().toUpperCase() : null;

    create.ubicacionrespaldodata = (data.ubicacionrespaldodata !== "" && data.ubicacionrespaldodata !== null) ? data.ubicacionrespaldodata.toString().toUpperCase() : null;
    create.ubicacionrespaldoserver = (data.ubicacionrespaldoserver !== "" && data.ubicacionrespaldoserver !== null) ? data.ubicacionrespaldoserver.toString().toUpperCase() : null;

    create.administradortexto = (data.administradortexto !== "" && data.administradortexto !== null) ? data.administradortexto.toString().toUpperCase() : null;
    create.ipservidor = (data.ipservidor !== "" && data.ipservidor !== null) ? data.ipservidor.toString().toUpperCase() : null;
    create.ippublica = (data.ippublica !== "" && data.ippublica !== null) ? data.ippublica.toString().toUpperCase() : null;
    create.vlan = (data.vlan !== "" && data.vlan !== null) ? data.vlan.toString().toUpperCase() : null;
    create.numeroserie = (data.numeroserie !== "" && data.numeroserie !== null) ? data.numeroserie.toString().toUpperCase() : null;
    create.descripcion = (data.descripcion !== "" && data.descripcion !== null) ? data.descripcion.toString().toUpperCase() : null;
    create.comentario = (data.comentario !== "" && data.comentario !== null) ? data.comentario.toString().toUpperCase() : null;
    
    create.modificadopor = (data.modificadopor !== "" && data.modificadopor !== null) ? data.modificadopor.toString().toUpperCase() : null;

    create.fechaupdate = (data.fechaupdate !== "" && data.fechaupdate !== null) ? new Date(data.fechaupdate).toISOString().substring(0, 10) : null;
    create.fechahabilitacion = (data.fechahabilitacion !== "" && data.fechahabilitacion !== null) ? new Date(data.fechahabilitacion).toISOString().substring(0, 10) : null;
    create.fechabaja = (data.fechabaja !== "" && data.fechabaja !== null) ? new Date(data.fechabaja).toISOString().substring(0, 10) : null;
    create.fechaexpiracion = (data.fechaexpiracion !== "" && data.fechaexpiracion !== null) ? new Date(data.fechaexpiracion).toISOString().substring(0, 10) : null;
    create.ultimoreinicio = (data.ultimoreinicio !== "" && data.ultimoreinicio !== null) ? new Date(data.ultimoreinicio).toISOString().substring(0, 10) : null;
    //create.fechadrp = (data.fechadrp !== "" && data.fechadrp !== null) ? new Date(data.fechadrp).toISOString().substring(0, 10) : null;

    create.informara = (data.informara !== "" && data.informara !== null) ? data.informara.toString().toUpperCase() : null;
    //create.umbral = (data.umbral !== "" && data.umbral !== null) ? data.umbral.toString().toUpperCase() : null;

    var bRequest = JSON.stringify(create);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido !== null){        
        res = convertido;
    }

    return res;    
}

async function eliminarServidor(id, token)
{ 
    const url = Rutas.URL_Servicio + "eliminarservidor";    
    //const url = "https://localhost:44325/api/servidor/eliminarservidor";
    
    const data = {        
        "id": id
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function valoresServidores(modulo, idioma, token)
{ 
    const url = Rutas.URL_Servicio + "valores"; 
    //const url = "https://localhost:44325/api/servidor/valores";   
    
    const request= { 
        "modulo": modulo,
        "idioma": idioma 
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};
}

export async function eliminarServidorRequest(id, token) {
    try {
        var respuesta = await eliminarServidor(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function obtenerdatosFiltros(token) {
    try {
        var respuesta = await filtroServidores(token);
        return respuesta;
    } catch (error) {
        return null;
    }
}

export async function obtenerdatosTabla(estado, localidad, negocio, pais, sistemaOperativo, servidor, red, token) {
    try {
        var respuesta = await tablaServidores(estado, localidad, negocio, pais, sistemaOperativo, servidor, red, token);
        return respuesta;
    } catch (error) {        
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function obtenerdatosTablaRelApps(estado, localidad, negocio, pais, sistemaOperativo, servidor, token) {
    try {
        var respuesta = await tablaServidoresAplicaciones(estado, localidad, negocio, pais, sistemaOperativo, servidor, token);
        return respuesta;
    } catch (error) {        
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function obtenerServidorForm(id, token) {
    try {
        var respuesta = await obtenerServidor(id, token);
        return respuesta;
    } catch (error) {
        return null;
    }
}

export async function obtenerValoresForm(modulo, idioma, token) {
    try {
        var respuesta = await valoresServidores(modulo, idioma, token);
        return respuesta;
    } catch (error) {
        return null;
    }
}

export async function actualizarForm(data, dataAnterior, id, token) {
    try {
        var respuesta = await actualizarServidor(data, dataAnterior, id, token);
        return respuesta;
    } catch (error) {        
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function crearForm(data, token) {
    try {
        var respuesta = await crearServidor(data, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function obtenerAplicaciones(servidor, token) {
    try {
        var respuesta = await aplicacionesServidor(servidor, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function obtenerCuentas(servidor, token) {
    try {
        var respuesta = await cuentasServidor(servidor, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function obtenerCertificados(servidor, token) {
    try {
        var respuesta = await certificadosServidor(servidor, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function obtenerAplicacionesSccm(servidor, token) {
    try {
        var respuesta = await aplicacionesServidorSccm(servidor, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function uploadExcel(file64, nombre, usuario, token) {
    try {
        var respuesta = await subirArchivoExcel(file64, nombre, usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function cargasExcel(usuario, token) {
    try {
        var respuesta = await obtenerCargasExcel(usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

async function tablaBitacoras(estado, localidad, negocio, pais, sistemaOperativo, servidor, token)
{
    let nivelUser = sessionStorage.getItem('ser_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');

    const request= {
        "nivel": nivelUser,
        "pais": paisUser,
        "localidad": localidadUser,
        "filtrolocalidad": localidad,
        "filtropais": pais,
        "filtronegocio": negocio,
        "filtrosistemaop": sistemaOperativo,
        "filtroservidor": servidor,
        "filtroestado": estado,
    }

    var bRequest = JSON.stringify(request, null, 2);

    const url = Rutas.URL_Servicio + "bitacoras";    
    //const url = "https://localhost:44325/api/servidor/resumenaplicaciones";    
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }        
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function obtenerTablaBitacoras(estado, localidad, negocio, pais, sistemaOperativo, servidor, token) {
    try {
        var respuesta = await tablaBitacoras(estado, localidad, negocio, pais, sistemaOperativo, servidor, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}


