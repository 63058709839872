import React, { useState } from 'react';
import { makeStyles, useTheme, withStyles  } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import ExportarExcel from '../../ExportarExcel';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as AiIcons from 'react-icons/ai';
import * as MdIcons from 'react-icons/md';
import Loading from '../../Loading';
import MaestroGeneralFiltro from './MaestroGeneralFiltro';
import { eliminarRegistroGeneral, obtenerRegistroTablaGeneral } from '../../functions/FuncMaestroGeneral'

const useStyles = makeStyles({
    table: {
      minWidth: 650
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5)
    },
}));

const headCells = [
    { id: 'texto', numeric: false, disablePadding: true, label: 'Name', size: '90%' },
    { id: 'eliminar', numeric: false, disablePadding: true, label: '', size: '10%' }
];

const ContainerBusqueda = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
`;

const ContainerTable = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
`;

const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.white
    },
    body: {
        textDecoration: 'none',
        fontSize: 12,
    },
}))(TableCell);

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const MaestroGeneralTabla = ({maestro, token, rows, aplicarFiltro, filtro, cambioFiltro, busquedaEliminacion, camposExcel}) => {

    const classes = useStyles();    
    const [loading, setLoading] = React.useState(false);

    //const [rows, setRows] = useState(null);
    const [filter, setFilter] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('nombre');

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.texto !== "") ? (row.texto.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function stableSort(array) {
        if(order === 'desc')
        {
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] > b[orderBy]) ? 1 : (b[orderBy] > a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
        else{
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] < b[orderBy]) ? 1 : (b[orderBy] < a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
    }

    async function eliminar(id, nombre){
        
        swal({
            title: "Delete Confirmation",
            text: "Do you want delete this master " + nombre + " ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (afirmacion) => {
            if(afirmacion){  

                await eliminarRegistroGeneral(maestro, nombre, token)
                .then((response) => {  
                    
                    if(response.cod === 1){
                        swal("Delete Success" , "" ,"success");
                    } 
                    else swal ("Delete Error" , response.mensaje , "warning");
                });

                await obtenerRegistroTablaGeneral(maestro, filtro, token)
                .then((response) => {
                    if(response.cod === 1) busquedaEliminacion(response.maestros);
                });
            }
        });
    }

    return (
        <>
            {
                (!loading)
                ?
                (
                    <>                   
                        <MaestroGeneralFiltro  maestro={maestro} aplicarFiltro={aplicarFiltro} load={setLoading} token={token} filtro={filtro} cambioFiltro={cambioFiltro}/>
                        <ContainerBusqueda> 
                        <br></br>                         
                            <TextField 
                                id="outlined-search" 
                                label="Search" 
                                size="small" 
                                type="search" 
                                variant="outlined" 
                                style={{"backgroundColor": "white"}}
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />       
                            {
                                (rows === null) ? (<></>)
                                : (<ExportarExcel subtitulo={"ExportMaster"} rows={rows} columnas={camposExcel} nombreSheet="ExportMaster" nombreArchivo="ExportMaster"/>)
                            }           
                        <br></br>                  
                        </ContainerBusqueda>
                        <ContainerTable>
                            <TableContainer component={Paper}>
                                <Table 
                                    className={classes.table} 
                                    size="small" 
                                    aria-label="enhanced table">
                                <TableHead style={{ background: '#696158', color: '#fff' }}>
                                    <TableRow>
                                    {headCells.map((headCell) => (
                                        <StyledTableCell 
                                            key={headCell.id}
                                            align={'center'}   
                                            sortDirection={orderBy === headCell.id ? order : false}
                                            style={{ width: headCell.size, color: '#fff !important' }}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id)}
                                                style={{ background: '#696158', color: '#fff' }}
                                            >
                                                {headCell.label}
                                            </TableSortLabel>                                        
                                        </StyledTableCell>
                                    ))}                                
                                    </TableRow>
                                </TableHead>
                                {
                                    (rows === null || rows === undefined) 
                                    ? (
                                        <TableBody> 
                                        </TableBody> 
                                    ) : (
                                        <TableBody>  
                                        {   stableSort(buscar(rows))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)                                    
                                            .map((row) => (                                            
                                                <TableRow hover key={row.id}  style={{ textDecoration: 'none', fontSize: '10px !important' }}>
                                                    <StyledTableCell align="left">{row.texto}</StyledTableCell>
                                                    
                                                    <StyledTableCell align="center">
                                                        <button 
                                                            onClick={() => {                                                                
                                                                eliminar(row.id, row.texto);
                                                            }}
                                                            className="boton-eliminar tooltipboton">
                                                            <AiIcons.AiFillDelete/>
                                                            <span className="tooltiptextboton">{"Delete"}</span> 
                                                        </button>     
                                                    </StyledTableCell>                                            
                                                </TableRow>
                                            ))
                                        }        
                                        </TableBody>
                                    )
                                }
                                <TableFooter>
                                </TableFooter>                           
                                </Table>
                                <Table>
                                    <TableBody> 
                                    <TableRow>
                                    <TablePagination  style={{ display: 'flex'}}
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            colSpan={3}
                                            count={(rows === null || rows === undefined) ? 0 : rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}                                    
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            />
                                    </TableRow>
                                    </TableBody> 
                                </Table>
                            </TableContainer>
                        </ContainerTable> 
                    </>
                )
                :
                (
                    <>
                        <MaestroGeneralFiltro maestro={maestro} aplicarFiltro={aplicarFiltro} load={setLoading} token={token} filtro={filtro} cambioFiltro={cambioFiltro}/>
                        <br></br>
                        <br></br>
                        <ContainerTable>
                            <Loading />
                        </ContainerTable>
                    </> 
                )
            }
        </>
    )
}

export default MaestroGeneralTabla
