import React from 'react'
import { useParams } from "react-router-dom";
import Sidebar from '../Sidebar'
import FormularioAplicacion from './FormularioAplicacion'
import * as TiIcons from 'react-icons/ti';
import * as AiIcons from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import { eliminarAplicacionrequest } from '../functions/FuncAplicacion'
import { eliminarBitacorasRegistroRequest } from '../functions/FuncBitacora'
import ModalDocumentos from '../ModalDocumentos';
import Bitacora from '../Bitacora/Bitacora';

import { ContainerForm, ButtonVolver, ButtonEliminacion } from '../Commons/Styles'

const FormAplicacion = ({instance, t}) => {

    let { id } = useParams();
    const history = useHistory();
    let priv = sessionStorage.getItem('app_leer');
    let privEliminacion = sessionStorage.getItem('app_eliminar');

    function volver()
    {
        history.push("/aplicaciones");
    }

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    async function eliminar()
    {
        await eliminarAplicacionrequest(id, t).then((response) => {
            if(response.cod === 1)
            {
                eliminarBitacorasRegistroRequest(id, "Aplicacion", t);
                swal("Delete success", "", "success").then(() => {history.push("/aplicaciones")});
            }
            else {
                swal("Delete Error", response.mensaje, "error");
            }
        });
    }

    function eliminarRegistro()
    {
        swal({
            title: "Delete Confirmation",
            text: "You want delete this application?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((afirmacion) => {
            if(afirmacion)
            {
                eliminar();       
            }
        })
    }


    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });

    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <>        
            <Sidebar instance={instance} despliegue={false}/>
            <ContainerForm>
                <div className="headerformulario">
                    <div className="headerform-izq">
                        <ButtonVolver onClick={volver} className="tooltipboton">
                            <TiIcons.TiArrowBack/>             
                            <span className="tooltiptextboton">{"Back"}</span>
                        </ButtonVolver>
                    </div>
                    <div className="headerform-mid">
                        <div className="divtituloform">
                            <label className="tituloformulario">Application Form</label>
                        </div>                        
                    </div>
                    <div className="headerform-der">
                        
                        {
                            (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>)
                            : (                            
                                <ButtonEliminacion onClick={eliminarRegistro} className="tooltipboton">
                                    
                                    <AiIcons.AiFillDelete/>
                                    <span className="tooltiptextboton">{"Delete"}</span> 
                                    
                                </ButtonEliminacion>
                            )
                        }
                        <Bitacora tipo={"Aplicacion"} id={id} token={t} />
                        <ModalDocumentos tipo={"Aplicacion"} id={id} token={t}/>
                    </div>
                </div>
                <FormularioAplicacion id={id} token={t}/>
            </ContainerForm>            
        </>    
    )
}

export default FormAplicacion
