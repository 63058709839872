import React from 'react'
import styled from 'styled-components';
import Sidebar from '../Sidebar'
import * as TiIcons from 'react-icons/ti';

import { useHistory } from "react-router-dom";
import CreacionCertificado from './CreacionCertificado';
import swal from 'sweetalert';

import { ContainerForm, ButtonVolver, ContainerFormSmall } from '../Commons/Styles'

const FormCreateCertificado = ({instance, t}) => {

    let priv = sessionStorage.getItem('cert_crear');
    const history = useHistory();

    function volver()
    {
        history.push("/certificado");
    }

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });

    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <>        
            <Sidebar instance={instance} despliegue={false}/>
            <ContainerForm>
                <div className="headerformulario">
                    <div className="headerform-izq">
                        <ButtonVolver onClick={volver} className="tooltipboton">
                        <TiIcons.TiArrowBack/>             
                        <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>
                    </div>
                    <div className="headerform-mid">
                        <div className="divtituloform">
                            <label className="tituloformulario">Certificate Creation Form</label>
                        </div>                        
                    </div>
                </div>
                <ContainerFormSmall>
                    <CreacionCertificado token={t}/>
                </ContainerFormSmall>
                
            </ContainerForm>            
        </>    
    )
}

export default FormCreateCertificado