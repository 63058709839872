export const Antivirus = {
    container: "archivosmaestroav",
    activo: "antivirusequipo",
    cabeceras: [
        {nombre: "NAME", index: 0},
        {nombre: "STATUS", index: 6},
        {nombre: "LASTCONTACTTIME", index: 9},
        {nombre: "POLICYNAME", index: 13},
        {nombre: "SENSORVERSION", index: 16}
    ]
}

export const Aplicacion = {
    container: "archivosmaestroapp",
    activo: "maestroaplicacion",
    cabeceras: [
        {nombre: "NAME", index: 0},
        {nombre: "PRODUCER", index: 1}
    ]
}

export const Umbrella = {
    container: "archivosmaestroub",
    activo: "umbrella",
    cabeceras: [
        {nombre: "LABEL", index: 1},
        {nombre: "STATUS", index: 4},
        {nombre: "LASTSYNC", index: 6}
    ]
}

export const Personal = {
    container: "archivosmaestroper",
    activo: "maestropersonal",
    cabeceras: [
        {nombre: "N° PERSONAL", index: 0},
        {nombre: "LEVEL 1", index: 2},
        {nombre: "LEVEL 2", index: 3},
        {nombre: "LEVEL 3", index: 4},
        {nombre: "PRIMER NOMBRE", index: 7},
        {nombre: "APELLIDO PATERNO", index: 8},
        {nombre: "APELLIDO MATERNO", index: 9},
        {nombre: "DIRECCIÓN DE CORREO ELECTRÓNICO", index: 10},
        {nombre: "CARGO", index: 11},
        {nombre: "COMPAÑÍA (ID SOCIEDAD)", index: 12},
        {nombre: "CENTRO DE COSTO (CÓDIGO CENTRO DE COSTO)", index: 13},

        {nombre: "UBICACIÓN (NOMBRE DE UBICACIÓN)", index: 15},
        {nombre: "ÁREA DE PERSONAL (PICKLIST LABEL)", index: 16},
        {nombre: "DATE", index: 17}
    ]
}