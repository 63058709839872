import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
//import * as Opciones from './OpcionesComunicacion'
import { obtenerValores, crearCuenta } from '../functions/FuncCuentaServicio'

import "../css/formularioAplicacion.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import Loading from '../Loading';
import Select from 'react-select'


import { style, styleNormal } from '../Commons/Styles'

const CreacionCuentaSer = ({token}) => {

  const history = useHistory();
  const [registro, setRegistro] = useState(null);    
  const [valores, setValores] = useState(null);
  const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

  let privilegio = sessionStorage.getItem('cuentaser_crear');
  let paisUser = sessionStorage.getItem('pais');
  let idiomaUser = sessionStorage.getItem('idioma');

  const [errorEstado, setErrorEstado] = useState(false);
  const [errorNombre, setErrorNombre] = useState(false);
  const [errorTipoCuenta, setErrorTipoCuenta] = useState(false);
  const [errorPais, setErrorPais] = useState(false);
  const [errorNegocio, setErrorNegocio] = useState(false);
  const [errorFechaCreacion, setErrorFechaCreacion] = useState(false);
  const [errorResponsable, setErrorResponsable] = useState(false);
  const [errorSolicitadapor, setErrorSolicitadapor] = useState(false);
  const [errorDescripcion, setErrorDescripcion] = useState(false);

  async function obtenerDatosBD()
  {
    await obtenerValores(privilegio, paisUser, "ACCOUNT SERVICE", idiomaUser, token)
    .then((response) => {
            
      if(response.cod === 1)
      {
        const val = {
          "tipos": response.tipos,
          "paises": response.paises,
          "estados": response.estados,
          "negocios": response.negocios,
          "responsables": response.responsables,
          "traducciones": response.traducciones
        };

        let array = [];
        const listas = ["tipos", "paises", "negocios", "responsables", "estados"];

        listas.forEach(lista => {
          array = [];
          val[lista].map((u) => { array.push({"label": u, "value": u});})
          val[lista] = array;
        });

        setValores(val);
      }
    });
  }

  function validarDatos(data)
    {
        var errores = false;

        if(data.nombre === null || data.nombre === "" || data.nombre === undefined) 
        {
            setErrorNombre(true);
            errores = true;
        }
        else setErrorNombre(false);

        if(data.tipocuenta === null || data.tipocuenta === "" || data.tipocuenta === undefined) 
        {
            setErrorTipoCuenta(true);
            errores = true;
        }
        else setErrorTipoCuenta(false);

        if(data.estado === null || data.estado === "" || data.estado === undefined) 
        {
            setErrorEstado(true);
            errores = true;
        }
        else setErrorEstado(false);

        if(data.pais === null || data.pais === "" || data.pais === undefined) 
        {
          setErrorPais(true);
            errores = true;
        }
        else setErrorPais(false);

        if(data.negocio === null || data.negocio === "" || data.negocio === undefined) 
        {
            setErrorNegocio(true);
            errores = true;
        }
        else setErrorNegocio(false);

        if(data.responsable === null || data.responsable === "" || data.responsable === undefined) 
        {
            setErrorResponsable(true);
            errores = true;
        }
        else setErrorResponsable(false);

        if(data.solicitadapor === null || data.solicitadapor === "" || data.solicitadapor === undefined) 
        {
            setErrorSolicitadapor(true);
            errores = true;
        }
        else setErrorSolicitadapor(false);

        if(data.fechacreacion === null || data.fechacreacion === "" || data.fechacreacion === undefined) 
        {
            setErrorFechaCreacion(true);
            errores = true;
        }
        else setErrorFechaCreacion(false);

        if(data.descripcion === null || data.descripcion === "" || data.descripcion === undefined) 
        {
            setErrorDescripcion(true);
            errores = true;
        }
        else setErrorDescripcion(false);

        return errores;
    }

  const onSubmit = (data) => {
    const validacion = validarDatos(data);
    console.log(data);
    if(validacion)
    {
      swal("Form data", "Mandatory data must be entered", "warning");
    }
    else {
      swal({
        title: "Creation confirmation",
        text: "Do you want to create the record?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async (afirmacion) => {
        if(afirmacion)
        {
          const datosform = data;
          let nombreusuario = sessionStorage.getItem('nombre');
          datosform.modificadopor = nombreusuario;
          datosform.fechaupdate = new Date();

          await crearCuenta(datosform, token).then((response) => {
                        
            if(response.cod === 1)
            {
                swal("Successful creation", "", "success").then(() => {
                    history.push("/cuentasservicio/"+response.id);
                });
            }
            else swal("Creation Error", response.mensaje, "error");                        
          });
        }
      });
    }
  }



  React.useEffect(() => {
    if (valores !== null) {

    }
    else obtenerDatosBD();
  }, []);

  if(valores === null) return (<Loading />)
  else
  return (
    <div className="divizq">
      <form 
        style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
        autoComplete="off" onSubmit={handleSubmit(onSubmit)}
      >
        <div className="container">
          <div className="row">
            <div className="col-25">
              <label style={{fontWeight: 'bold'}} htmlFor="i-nombre">Account Name<label style={{color: 'red'}}>{"*"}</label>
                {
                  (valores.traducciones.nombre === "" || valores.traducciones.nombre === null) ? (<></>)
                  : (
                    <div class="tooltip">&nbsp;&#x1F6C8;
                      <span class="tooltiptext">{valores.traducciones.nombre}</span>
                    </div>
                  )
                }
              </label> 
            </div>
            <div className="col-75">
              <Controller
                as={<input className="bootstrap-input" type='text'  />}                                      
                control={control}
                id="i-nombre"
                key="k-nombre"
                defaultValue={registro ? registro.nombre : ''}
                name='nombre'
                style={(errorNombre) ? { borderColor: '#f90000'} : {}} 
              /> 
            </div>
          </div>

          <div className="row">
            <div className="col-25">
              <label style={{fontWeight: 'bold'}} htmlFor="i-tipocuenta">Account Type<label style={{color: 'red'}}>{"*"}</label>
                {
                  (valores.traducciones.tipocuenta === "" || valores.traducciones.tipocuenta === null) ? (<></>)
                  : (
                    <div class="tooltip">&nbsp;&#x1F6C8;
                      <span class="tooltiptext">{valores.traducciones.tipocuenta}</span>
                    </div>
                  )
                }
              </label> 
            </div>
            <div className="col-75">
              <Controller
                render={(props) => <Select 
                  options={valores.tipos}
                  onChange={(value) => {
                      props.onChange(value);                                                        
                  }}
                  placeholder=""
                  isClearable={true} 
                  styles={(errorTipoCuenta) ? style : styleNormal}
                  control={control}
                />}                                       
                control={control}
                id="i-tipocuenta"
                key="k-tipocuenta"
                defaultValue={registro ? registro.tipocuenta : ''}
                name='tipocuenta'
              /> 
            </div>
          </div>

          <div className="row">
            <div className="col-25">
              <label style={{fontWeight: 'bold'}} htmlFor="i-estado">State<label style={{color: 'red'}}>{"*"}</label>
                {
                  (valores.traducciones.estado === "" || valores.traducciones.estado === null) ? (<></>)
                  : (
                    <div class="tooltip">&nbsp;&#x1F6C8;
                      <span class="tooltiptext">{valores.traducciones.estado}</span>
                    </div>
                  )
                }
              </label> 
            </div>
            <div className="col-75">
              <Controller
                render={(props) => <Select 
                  options={valores.estados}
                  onChange={(value) => {
                      props.onChange(value);                                                        
                  }}
                  placeholder=""
                  isClearable={true} 
                  styles={(errorEstado) ? style : styleNormal}
                  control={control}
                />}                                       
                control={control}
                id="i-estado"
                key="k-estado"
                defaultValue={registro ? registro.estado : ''}
                name='estado'
              /> 
            </div>
          </div>

          <div className="row">
            <div className="col-25">
              <label style={{fontWeight: 'bold'}} htmlFor="i-pais">Country<label style={{color: 'red'}}>{"*"}</label>
                {
                  (valores.traducciones.pais === "" || valores.traducciones.pais === null) ? (<></>)
                  : (
                    <div class="tooltip">&nbsp;&#x1F6C8;
                      <span class="tooltiptext">{valores.traducciones.pais}</span>
                    </div>
                  )
                }
              </label> 
            </div>
            <div className="col-75">
              <Controller
                render={(props) => <Select 
                  options={valores.paises}
                  onChange={(value) => {
                      props.onChange(value);                                                        
                  }}
                  placeholder=""
                  isClearable={true} 
                  styles={(errorPais) ? style : styleNormal}
                  control={control}
                />}                                       
                control={control}
                id="i-pais"
                key="k-pais"
                defaultValue={registro ? registro.pais : ''}
                name='pais'
              /> 
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label style={{fontWeight: 'bold'}} htmlFor="i-pais">Business<label style={{color: 'red'}}>{"*"}</label>
                {
                  (valores.traducciones.negocio === "" || valores.traducciones.negocio === null) ? (<></>)
                  : (
                    <div class="tooltip">&nbsp;&#x1F6C8;
                      <span class="tooltiptext">{valores.traducciones.negocio}</span>
                    </div>
                  )
                }
              </label> 
            </div>
            <div className="col-75">
              <Controller
                render={(props) => <Select 
                  options={valores.negocios}
                  onChange={(value) => {
                      props.onChange(value);                                                        
                  }}
                  placeholder=""
                  isClearable={true} 
                  styles={(errorNegocio) ? style : styleNormal}
                  control={control}
                />}                                       
                control={control}
                id="i-negocio"
                key="k-negocio"
                defaultValue={registro ? registro.negocio : ''}
                name='negocio'
              /> 
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label style={{fontWeight: 'bold'}} htmlFor="i-responsable">Responsable<label style={{color: 'red'}}>{"*"}</label>
                {
                  (valores.traducciones.responsable === "" || valores.traducciones.responsable === null) ? (<></>)
                  : (
                    <div class="tooltip">&nbsp;&#x1F6C8;
                      <span class="tooltiptext">{valores.traducciones.responsable}</span>
                    </div>
                  )
                }
              </label> 
            </div>
            <div className="col-75">
              <Controller
                render={(props) => <Select 
                  options={valores.responsables}
                  onChange={(value) => {
                      props.onChange(value);                                                        
                  }}
                  placeholder=""
                  isClearable={true} 
                  styles={(errorResponsable) ? style : styleNormal}
                  control={control}
                />}                                       
                control={control}
                id="i-responsable"
                key="k-responsable"
                defaultValue={registro ? registro.responsable : ''}
                name='responsable'
              /> 
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label style={{fontWeight: 'bold'}} htmlFor="i-solicitadapor">Requested by<label style={{color: 'red'}}>{"*"}</label>
                {
                  (valores.traducciones.solicitadapor === "" || valores.traducciones.solicitadapor === null) ? (<></>)
                  : (
                    <div class="tooltip">&nbsp;&#x1F6C8;
                      <span class="tooltiptext">{valores.traducciones.solicitadapor}</span>
                    </div>
                  )
                }
              </label> 
            </div>
            <div className="col-75">
              <Controller
                as={<input className="bootstrap-input" type='text'  />}                                      
                control={control}
                id="i-solicitadapor"
                key="k-solicitadapor"
                defaultValue={registro ? registro.solicitadapor : ''}
                name='solicitadapor'
                style={(errorSolicitadapor) ? { borderColor: '#f90000'} : {}} 
              /> 
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label style={{fontWeight: 'bold'}} htmlFor="i-fechacreacion">Creation Date (YYYY/MM/DD)<label style={{color: 'red'}}>{"*"}</label>
                {
                  (valores.traducciones.fechacreacion === "" || valores.traducciones.fechacreacion === null) ? (<></>)
                  : (
                    <div class="tooltip">&nbsp;&#x1F6C8;
                      <span class="tooltiptext">{valores.traducciones.fechacreacion}</span>
                    </div>
                  )
                }
              </label> 
            </div>
            <div className="col-75">
              <Controller
                name="fechacreacion"
                id="i-fechacreacion"
                className="bootstrap-input"
                control={control}
                render={({ onChange, value }) => (
                    <DatePicker                                                        
                        selected={value}
                        dateFormat="yyyy/MM/dd"
                        autoComplete="off"
                        onChange={onChange}
                        className={(errorFechaCreacion) ? "bootstrap-input-invalid" : "bootstrap-input"}
                        //style={(errorFechaCreacion) ? { borderColor: '#f90000'} : {}}                                                       
                    />
                )}
              /> 
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label style={{fontWeight: 'bold'}} htmlFor="i-descripcion">Description<label style={{color: 'red'}}>{"*"}</label>
                {
                  (valores.traducciones.descripcion === "" || valores.traducciones.descripcion === null) ? (<></>)
                  : (
                    <div class="tooltip">&nbsp;&#x1F6C8;
                      <span class="tooltiptext">{valores.traducciones.descripcion}</span>
                    </div>
                  )
                }
              </label> 
            </div>
            <div className="col-75">
              <Controller
                as={<textarea className="bootstrap-input" rows="3" cols="50"></textarea>}                                     
                control={control}
                id="i-descripcion"
                key="k-descripcion"
                defaultValue={registro ? registro.descripcion : ''}
                name='descripcion'
                style={(errorDescripcion) ? { borderColor: '#f90000'} : {}} 
              /> 
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label htmlFor="i-observaciones">Observations
                {
                  (valores.traducciones.observaciones === "" || valores.traducciones.observaciones === null) ? (<></>)
                  : (
                    <div class="tooltip">&nbsp;&#x1F6C8;
                      <span class="tooltiptext">{valores.traducciones.observaciones}</span>
                    </div>
                  )
                }
              </label> 
            </div>
            <div className="col-75">
              <Controller
                as={<textarea className="bootstrap-input" rows="3" cols="50"></textarea>}                                     
                control={control}
                id="i-observaciones"
                key="k-observaciones"
                defaultValue={registro ? registro.observaciones : ''}
                name='observaciones'
                //style={(errorNombre) ? { borderColor: '#f90000'} : {}} 
              /> 
            </div>
          </div>
        </div>
        <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px', float: 'left'}}>  
          <div style={{width: '30%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px'}}>                
              <button className="boton-from">Save</button>   
              <br></br>
              <br></br>
              <br></br>              
            </div>
          </div>
      </form>
    </div>
  )
}

export default CreacionCuentaSer