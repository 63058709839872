import React from 'react'


import { uploadExcel, cargasExcel } from '../functions/FuncDispositivoOT'
import Carga from '../Commons/Carga';


const CargaDispositivoOT = ({instance, t}) => {

    return (
        <Carga
            instance = { instance }
            t = { t }
            privilegio = { 'ser_ot_cargamasiva' }
            uploadExcel = { uploadExcel }
            cargasExcel = { cargasExcel }
            exportErrorsFileName = { 'DetallesDispositivoOTExport' }
        />
    )
}

export default CargaDispositivoOT
