import React from 'react'

import swal from 'sweetalert';
import { obtenerValores, tablaCuenta } from '../functions/FuncCuentaServicio'
import FiltroBaseNuevo from '../FiltroBaseNuevo';
import  * as Metodos from '../functions/MetodosComunes'

import { ContainerFiltros, FilaContainerFiltros, Button } from '../Commons/Styles'

const FiltrosCuentaServicio = ({aplicarFiltro, load, token}) => {
  
    let priv = sessionStorage.getItem('cuentaser_leer');
    let paisUser = sessionStorage.getItem('pais');
    let idiomaUser = sessionStorage.getItem('idioma');

    const [valores, setValores] = React.useState(null);

    const [estados, setEstados] = React.useState(null);
    const [estado, setEstado] = React.useState(null);
    const [paises, setPaises] = React.useState(null);
    const [pais, setPais] = React.useState(null);
    const [negocios, setNegocios] = React.useState(null);
    const [negocio, setNegocio] = React.useState(null);
    const [responsables, setResponsables] = React.useState(null);
    const [nombre, setNombre] = React.useState("");
  
    const cambioEstado = (valor) => {
        setEstado(valor);
    };

    const cambioPais = (valor) => {
        setPais(valor);
    };

    const cambioNegocio = (valor) => {
        setNegocio(valor);
    };

    async function obtenerValoresBd()
    {
        await obtenerValores(priv, paisUser, "ACCOUNT SERVICE", idiomaUser, token)
        .then((response) => {
            if(response.cod === 1)
            {
                const val = {          
                    "paises": response.paises,                    
                    "negocios": response.negocios,
                    "estados": response.estados,
                    "responsables": response.responsables
                };

                setEstados(Metodos.opcionesFiltros(val.estados));
                setNegocios(Metodos.opcionesFiltros(val.negocios));
                setResponsables(Metodos.opcionesFiltros(val.responsables));

                if(priv === "2") setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));
                else setPaises(Metodos.opcionesFiltros(val.paises));

                setValores(val);
                console.log(val)
            }
            else {
                console.log(response);
            }
        });
    }

    async function obtenerRegistros()
    {
        load(true);
        aplicarFiltro(null);

        var paisApi = (pais !== null && pais !== undefined && pais !== "") ? pais.value : null;       
        var estadoApi = (estado !== null && estado !== undefined && estado !== "") ? estado.value : null;
        var negocioApi = (negocio !== null && negocio !== undefined && negocio !== "") ? negocio.value : null;
        var nombreApi = (nombre !== null && nombre !== undefined && nombre !== "") ? nombre : null;

        (estadoApi !== null) ? sessionStorage.setItem('filtroscuentas_estado', estadoApi) : sessionStorage.setItem('filtroscuentas_estado', null);
        (paisApi !== null) ? sessionStorage.setItem('filtroscuentas_pais', paisApi) : sessionStorage.setItem('filtroscuentas_pais', null);
        (negocioApi !== null) ? sessionStorage.setItem('filtroscuentas_negocio', negocioApi) : sessionStorage.setItem('filtroscuentas_negocio', null);
        (nombreApi !== null) ? sessionStorage.setItem('filtroscuentas_nombre', nombreApi) : sessionStorage.setItem('filtroscuentas_nombre', null);

        await tablaCuenta(nombreApi, paisApi, negocioApi, estadoApi, priv, paisUser, token)
        .then((response) => {
            if(response.cod === 1){
                aplicarFiltro(response.cuentas);
            }
            else {
                aplicarFiltro(null);
                swal ("Search Error" , response.mensaje , "warning");
                console.log(response);
            }

            load(false);
        });
    }

    function filtrosAnteriores()
    {
        let filtroscuentas_estado = sessionStorage.getItem('filtroscuentas_estado');       
        if(filtroscuentas_estado !== "null") setEstado({label: filtroscuentas_estado, value: filtroscuentas_estado})

        let filtroscuentas_pais = sessionStorage.getItem('filtroscuentas_pais');       
        if(filtroscuentas_pais !== "null") setPais({label: filtroscuentas_pais, value: filtroscuentas_pais})

        let filtroscuentas_negocio = sessionStorage.getItem('filtroscuentas_negocio');       
        if(filtroscuentas_negocio !== "null") setNegocio({label: filtroscuentas_negocio, value: filtroscuentas_negocio})

        let filtroscuentas_nombre = sessionStorage.getItem('filtroscuentas_nombre');       
        if(filtroscuentas_nombre !== "null") setNombre(filtroscuentas_nombre)
    }

    React.useEffect(() =>{
        if(valores === null) obtenerValoresBd();
        filtrosAnteriores();
    }, []);

    if(valores === null) return (<></>)
    else return (
        <>
            <FilaContainerFiltros>
                <ContainerFiltros>
                    <FiltroBaseNuevo
                        opciones={estados}
                        valor={estado}
                        nombre={"State"}
                        namecampo="estado"
                        cambio={cambioEstado}
                        proporcion={"20%"}
                        error={false}
                    />
                    <FiltroBaseNuevo
                        opciones={paises}
                        valor={pais}
                        nombre={"Country"}
                        namecampo="pais"
                        cambio={cambioPais}
                        proporcion={"20%"}
                        error={false}
                    />
                    <FiltroBaseNuevo
                        opciones={negocios}
                        valor={negocio}
                        nombre={"Business"}
                        namecampo="negocio"
                        cambio={cambioNegocio}
                        proporcion={"20%"}
                        error={false}
                    />

                    <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>
                        <input className="bootstrap-input-filtro" value={nombre} type='text' onChange={(e) => setNombre(e.target.value)} placeholder={"Name"}  />
                    </div>

                    <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>
                        <Button onClick={obtenerRegistros}>Display</Button>
                    </div>
                </ContainerFiltros>
            </FilaContainerFiltros>
            <br></br>            
            <hr style={{width: '90%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
        </>
    )
}

export default FiltrosCuentaServicio