import * as Rutas from '../RutasServicios'
import * as Metodos from './MetodosComunes'

async function filtrosComunicacion(token)
{ 
    const url = Rutas.URL_Comunicaciones + "filtros"; 
    //const url = "https://localhost:44329/api/comunicacion/filtros";    
    
    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function obtenerEquipo(id, token)
{ 
    const url = Rutas.URL_Comunicaciones + "equipo";    
    //const url = "https://localhost:44329/api/comunicacion/equipo"; 

    const request= {
        "id": id           
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function equiposTabla(familia, tipoequipo, pais, localidad, planta, numeroserie, estado, condicion, nivel, paisuser, localidaduser, token)
{ 
    const url = Rutas.URL_Comunicaciones + "tabla";    
    //const url = "https://localhost:44329/api/comunicacion/tabla"; 

    const request= {
        "familia": familia,
        "tipoequipo": tipoequipo,
        "pais": pais,
        "localidad": localidad,
        "planta": planta,
        "numeroserie": numeroserie,
        "estado": estado,
        "condicion": condicion,
        "nivel": nivel,
        "paisuser": paisuser,
        "localidaduser": localidaduser       
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

async function crear(data, token)
{
    const url = Rutas.URL_Comunicaciones + "crear";    
    //const url = "https://localhost:44329/api/comunicacion/crear";  

    const reg = { 
        "familia": data.familia.toString().toUpperCase(),
        "usabilidad": data.usabilidad.toString().toUpperCase(),
        "tipoequipo": data.tipoequipo.toString().toUpperCase(),
        "numeroserie": data.numeroserie.toString().toUpperCase()        
    };

    reg.modificadopor = data.modificadopor.toString().toUpperCase();
    reg.fechaupdate = (data.fechaupdate !== null && data.fechaupdate !== "") ? new Date(data.fechaupdate).toISOString().substring(0, 10) : null;

    if(data.familia.toString().toUpperCase() === "EQUIPMENT")
    {
        if(data.marca !== null && data.marca !== undefined && data.marca !== "") reg.marca = data.marca.value.toString().toUpperCase();
        if(data.modelo !== null && data.modelo !== undefined && data.modelo !== "") reg.modelo = data.modelo.value.toString().toUpperCase();
        if(data.planta !== null && data.planta !== undefined && data.planta !== "") reg.planta = data.planta.value.toString().toUpperCase();
        if(data.empresa !== null && data.empresa !== undefined && data.empresa !== "") reg.empresa = data.empresa.value.toString().toUpperCase();        
        if(data.proveedor !== null && data.proveedor !== undefined && data.proveedor !== "") reg.proveedor = data.proveedor.value.toString().toUpperCase();
        if(data.servicio !== null && data.servicio !== undefined && data.servicio !== "") reg.servicio = data.servicio.value.toString().toUpperCase();
        if(data.estado !== null && data.estado !== undefined && data.estado !== "") reg.estado = data.estado.value.toString().toUpperCase();
        if(data.condicion !== null && data.condicion !== undefined && data.condicion !== "") reg.condicion = data.condicion.value.toString().toUpperCase();
        if(data.tipoenlace !== null && data.tipoenlace !== undefined && data.tipoenlace !== "") reg.tipoenlace = data.tipoenlace.value.toString().toUpperCase();        

        reg.fechaentrega = (Metodos.validarTextoVacio(data.fechaentrega)) ? new Date(data.fechaentrega).toISOString().substring(0, 10) : null;
        reg.hostname = (Metodos.validarTextoVacio(data.hostname)) ? data.hostname.toString().toUpperCase() : null;
        reg.ordencompra = (Metodos.validarTextoVacio(data.ordencompra)) ? data.ordencompra.toString().toUpperCase() : null;
        reg.descripcion = (Metodos.validarTextoVacio(data.descripcion)) ? data.descripcion.toString().toUpperCase() : null;
        reg.ipequipo = (Metodos.validarTextoVacio(data.ipequipo)) ? data.ipequipo.toString().toUpperCase() : null;
    }

    if(data.familia.toString().toUpperCase() === "DATA LINK")
    {
        if(data.estado !== null && data.estado !== undefined && data.estado !== "") reg.estado = data.estado.value.toString().toUpperCase();
        if(data.condicion !== null && data.condicion !== undefined && data.condicion !== "") reg.condicion = data.condicion.value.toString().toUpperCase();        
        if(data.planta !== null && data.planta !== undefined && data.planta !== "") reg.planta = data.planta.value.toString().toUpperCase();
        if(data.empresa !== null && data.empresa !== undefined && data.empresa !== "") reg.empresa = data.empresa.value.toString().toUpperCase();
        if(data.servicio !== null && data.servicio !== undefined && data.servicio !== "") reg.servicio = data.servicio.value.toString().toUpperCase();
        if(data.proveedor !== null && data.proveedor !== undefined && data.proveedor !== "") reg.proveedor = data.proveedor.value.toString().toUpperCase();
        if(data.tipoenlace !== null && data.tipoenlace !== undefined && data.tipoenlace !== "") reg.tipoenlace = data.tipoenlace.value.toString().toUpperCase();

        reg.direccion = (Metodos.validarTextoVacio(data.direccion)) ? data.direccion.toString().toUpperCase() : null;
        reg.anchobanda = (Metodos.validarTextoVacio(data.anchobanda)) ? data.anchobanda.toString().toUpperCase() : null;
        reg.descripcion = (Metodos.validarTextoVacio(data.descripcion)) ? data.descripcion.toString().toUpperCase() : null;
        reg.ipequipo = (Metodos.validarTextoVacio(data.ipequipo)) ? data.ipequipo.toString().toUpperCase() : null;
    }
    
    var bRequest = JSON.stringify(reg);
 
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function valoresComunicaciones(nivel, pais, localidad, modulo, idioma, token)
{ 
    const url = Rutas.URL_Comunicaciones + "valores";    
    //const url = "https://localhost:44329/api/comunicacion/valores";    
    
    const request= {        
        "nivel": nivel,        
        "pais": pais,
        "localidad": localidad,
        "modulo": modulo,
        "idioma": idioma   
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function actualizar(data, dataAnterior, id, token)
{ 
    const url = Rutas.URL_Comunicaciones + "actualizar";    
    //const url = "https://localhost:44329/api/comunicacion/actualizar"; 

    const request = {
        "id_equipocomunicacion": id
    };

    if(data.familia !== undefined) request.familia = data.familia.value.toString().toUpperCase();
    if(data.usabilidad !== undefined) request.usabilidad = data.usabilidad.value.toString().toUpperCase();
    if(data.tipoequipo !== undefined) request.tipoequipo = data.tipoequipo.value.toString().toUpperCase();
    
    if(data.numeroserie !== null) request.numeroserie = data.numeroserie.toString().toUpperCase();
    if(data.estado.value !== dataAnterior.estado.value) request.estado = data.estado.value.toString().toUpperCase();

    
    if(data.condicion !== dataAnterior.condicion) request.condicion = data.condicion.value.toString().toUpperCase();

    request.empresa = (data.empresa != null && data.empresa != undefined) ? (data.empresa.value !== dataAnterior.empresa.value) ? data.empresa.value.toString().toUpperCase() : "" : "";
    request.planta = (data.planta != null && data.planta != undefined) ? (data.planta.value !== dataAnterior.planta.value) ? data.planta.value.toString().toUpperCase() : "" : "";
        
    request.modificadopor = data.modificadopor.toString().toUpperCase();
    request.fechaupdate = new Date().toISOString().substring(0, 10);
    
    if(data.familia.value === "EQUIPMENT")
    {
        request.hostname = (data.hostname !== dataAnterior.hostname) ? (data.hostname !== "") ? data.hostname.toString().toUpperCase() : "esnull" : "";
        request.ipequipo = (data.ipequipo !== dataAnterior.ipequipo) ? (data.ipequipo !== "") ? data.ipequipo.toString().toUpperCase() : "esnull" : "";
        request.ordencompra = (data.ordencompra !== dataAnterior.ordencompra) ? (data.ordencompra !== "") ? data.ordencompra.toString().toUpperCase() : "esnull" : "";
        request.descripcion = (data.descripcion !== dataAnterior.descripcion) ? (data.descripcion !== "") ? data.descripcion.toString().toUpperCase() : "esnull" : "";
        request.marca = (data.marca !== dataAnterior.marca) ? (data.marca !== null && data.marca !== "") ? data.marca.value.toString().toUpperCase() : "esnull" : "";
        request.modelo = (data.modelo !== dataAnterior.modelo) ? (data.modelo !== null && data.modelo !== "") ? data.modelo.value.toString().toUpperCase() : "esnull" : "";
        request.fechaentrega = (data.fechaentrega !== dataAnterior.fechaentrega) ? (data.fechaentrega !== null && dataAnterior.fechaentrega !== null) ? new Date(data.fechaentrega).toISOString().substring(0, 10): "esnull" : "";
    }

    if(data.familia.value === "DATA LINK")
    {
        request.direccion = (data.direccion !== dataAnterior.direccion) ? (data.direccion !== "") ? data.direccion.toString().toUpperCase() : "esnull" : "";
        request.ipequipo = (data.ipequipo !== dataAnterior.ipequipo) ? (data.ipequipo !== "") ? data.ipequipo.toString().toUpperCase() : "esnull" : "";
        request.anchobanda = (data.anchobanda !== dataAnterior.anchobanda) ? (data.anchobanda !== "") ? data.anchobanda.toString().toUpperCase() : "esnull" : "";
        request.descripcion = (data.descripcion !== dataAnterior.descripcion) ? (data.descripcion !== "") ? data.descripcion.toString().toUpperCase() : "esnull" : "";
        request.servicio = (data.servicio !== dataAnterior.servicio) ? (data.servicio !== null && data.servicio !== "") ? data.servicio.value.toString().toUpperCase() : "esnull" : "";
        request.proveedor = (data.proveedor !== dataAnterior.proveedor) ? (data.proveedor !== null && data.proveedor !== "") ? data.proveedor.value.toString().toUpperCase() : "esnull" : "";
        request.tipoenlace = (data.tipoenlace !== dataAnterior.tipoenlace) ? (data.tipoenlace !== null && data.tipoenlace !== "") ? data.tipoenlace.value.toString().toUpperCase() : "esnull" : "";
    }

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};     
}

async function subirArchivoExcel(file64, nombre, usuario, token)
{ 
    const url = Rutas.URL_Comunicaciones + "subirexcel";      
    //const url = "https://localhost:44329/api/comunicacion/subirexcel"; 
    

    const data = {
        "title": nombre,
        "file": file64,
        "desc": "archivo de carga excel",
        "usuario": usuario
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function obtenerCargasExcel(usuario, token)
{ 
    const url = Rutas.URL_Comunicaciones + "cargas";      
    //const url = "https://localhost:44329/api/comunicacion/cargas";    

    const data = {        
        "usuario": usuario
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function eliminarEquipo(id, token)
{ 
    const url = Rutas.URL_Comunicaciones + "eliminar";    
    //const url = "https://localhost:44329/api/comunicacion/eliminar";   
    
    const data = {        
        "id": id
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function tablaBitacoras(familia, tipoequipo, pais, localidad, planta, numeroserie, estado, condicion, nivel, paisuser, localidaduser, token)
{ 
    const url = Rutas.URL_Comunicaciones + "bitacoras";    
    //const url = "https://localhost:44329/api/comunicacion/bitacoras";

    const request= {
        "familia": familia,
        "tipoequipo": tipoequipo,
        "pais": pais,
        "localidad": localidad,
        "planta": planta,
        "numeroserie": numeroserie,
        "estado": estado,
        "condicion": condicion,
        "nivel": nivel,
        "paisuser": paisuser,
        "localidaduser": localidaduser       
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

export async function obtenerTablaBitacoras(familia, tipoequipo, pais, localidad, planta, numeroserie, estado, condicion, nivel, paisuser, localidaduser, token) {
    try {
        var respuesta = await tablaBitacoras(familia, tipoequipo, pais, localidad, planta, numeroserie, estado, condicion, nivel, paisuser, localidaduser, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}   

export async function eliminarEquipoRequest(id, token) {
    try {
        var respuesta = await eliminarEquipo(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function cargasExcel(usuario, token) {
    try {
        var respuesta = await obtenerCargasExcel(usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function uploadExcel(file64, nombre, usuario, token) {
    try {
        var respuesta = await subirArchivoExcel(file64, nombre, usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function actualizarEquipoComunicacion(data, dataAnterior, id, token) {
    try {
        var respuesta = await actualizar(data, dataAnterior, id, token);
        return respuesta;
    } catch (error) {
        console.log(error)      
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerEquipoComunicacion(id, token) {
    try {
        var respuesta = await obtenerEquipo(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearAplicacion(data, token) {
    try {
        var respuesta = await crear(data, token);
        return respuesta;
    } catch (error) { 
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerValores(nivel, pais, localidad, modulo, idioma, token) {
    try {
        var respuesta = await valoresComunicaciones(nivel, pais, localidad, modulo, idioma,token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerTabla(familia, tipoequipo, pais, localidad, planta, numeroserie, estado, condicion, nivel, paisuser, localidaduser, token) {
    try {
        var respuesta = await equiposTabla(familia, tipoequipo, pais, localidad, planta, numeroserie, estado, condicion, nivel, paisuser, localidaduser, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerFiltros(token) {
    try {
        var respuesta = await filtrosComunicacion(token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}