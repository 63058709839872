import React from 'react'
import * as TiIcons from 'react-icons/ti';

import { ContainerFormBot, ContainerFormTop, ButtonVolver } from './Styles'

const FormCreate = ({ volverUrlFunction, componenteFormName, creacionComponent}) => {

    return (
        <>
            <ContainerFormTop>
                <div className="headerformulario">
                    <div className="headerform-izq">
                        <ButtonVolver onClick={ volverUrlFunction } className="tooltipboton">
                            <TiIcons.TiArrowBack/> <span className="tooltiptextboton">Back</span>
                        </ButtonVolver>
                    </div>
                    <div className="headerform-mid">
                        <div className="divtituloform">
                            <label className="tituloformulario">{ componenteFormName + " Creation Form"}</label>
                        </div>                        
                    </div>
                </div>
                <ContainerFormBot>
                    { creacionComponent }
                </ContainerFormBot>
                <br></br>
            </ContainerFormTop>
        </>
    )
}

export default FormCreate
