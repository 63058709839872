import React, { useState } from 'react'

import Modal from 'react-modal'
import swal from 'sweetalert';
import * as MdIcons from 'react-icons/md';


import Loading from './Loading';
import Documentos from './Documentos';
import { obtenerDocumentos, eliminarDocumento, crearDocumento } from './functions/FuncDocumentos'

import { ButtonRelacion } from './Commons/Styles'

const ModalDocumentos = ({id, tipo, token}) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [modalCarga, setModalCarga] = useState(false);
    const [documentos, setDocumentos] = useState(null);

    function abrirModalCarga()
    {
        setModalCarga(true);
    }

    function cerrarModalCarga()
    {
        setModalCarga(false);
    }

    function abrirModal()
    {       
        getdocumentos();
        setModalOpen(true);
    }

    function cerrarModal()
    {       
        setDocumentos(null);
        setModalOpen(false);
    }

    async function getdocumentos(){
        await obtenerDocumentos(id, tipo, token)
        .then((response) => {
            if(response.cod === 1){
                setDocumentos(response.archivos);
                console.log(response.archivos)
            }
        })
    }

    async function updatedocumento(archivo, nombre){
        abrirModalCarga();

        let nombreusuario = sessionStorage.getItem('nombre');

        await crearDocumento(archivo, nombre, nombreusuario, id, tipo, token)
        .then(async (response) => {
            if(response.cod === 1){
                await getdocumentos();
                cerrarModalCarga();
                swal("Upload success", "", "success")
            }
            else 
            {
                cerrarModalCarga();
                swal("Upload error" , response.mensaje ,"error");
            }
        })
    }

    function filtrarDocumentos(idfile)
    {
        var array = [];

        documentos.forEach( d => {
            if(d.id !== idfile) array.push(d);
        });

        setDocumentos(array);
    }

    async function deletedocumento(idfile){
        swal({
            title: "Deletion confirmation",
            text: "Do you want to delete the file?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (afirmacion) => {

            if(afirmacion){
                abrirModalCarga();
                await eliminarDocumento(idfile, id, tipo, token)
                .then((response) => {
                    if(response.cod === 1){
                        filtrarDocumentos(idfile);
                        cerrarModalCarga();
                        swal("Deletion Success" , "" ,"success");
                    }
                    else {
                        swal("Deletion error" , response.mensaje ,"error");
                    }
                })
            }
        })
    }

    return (
        <>
            <ButtonRelacion type="button" onClick={abrirModal} className="tooltipboton">
                <MdIcons.MdAttachFile/>
                <span className="tooltiptextboton">{"Associate documents"}</span> 
            </ButtonRelacion>
            <Modal 
                isOpen={modalOpen}
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.75)'
                    },
                    content: {
                        width: '90%',
                        height: 'auto',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        position: 'relative',                        
                        border: '1px solid #ccc',
                        background: '#fff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '10px'
                    }
                  }}
            >
                <div>

                    {
                        (documentos === null) ? (<Loading />)
                        : (
                            <>
                                <Documentos 
                                    documentos={documentos} 
                                    modalCarga={modalCarga} 
                                    deletedocumento={deletedocumento}
                                    updatedocumento={updatedocumento}
                                    abrirModalCarga={abrirModalCarga}
                                    cerrarModalCarga={cerrarModalCarga}
                                    cerrarModal={cerrarModal}
                                 />
                            </>
                            
                        )
                    }
                    
                </div>

            </Modal>
        </>
    )
}

export default ModalDocumentos
