import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import '../css/aplicaciones.css'
import swal from 'sweetalert';

import ExportarExcel from '../ExportarExcel';

import Loading from '../Loading';
import FiltroTablaOtrosDispositivos from './FiltroTablaOtrosDispositivos';
import * as Opciones from './OpcionesOtrosDispositivos';

import ExportarExcelBitacoras from '../ExportarExcelBitacoras';

import { TablaRegistros } from '../Commons/TablaRegistros';

import { ContainerBusqueda, ContainerTable } from '../Commons/Styles'

const headCells = [
    { id: 'tipoequipo', numeric: false, disablePadding: true, label: 'Type', size: '15%' }, 
    { id: 'estado', numeric: false, disablePadding: true, label: 'State', size: '5%' },
    { id: 'condicion', numeric: false, disablePadding: true, label: 'Condition', size: '5%' },
    { id: 'pais', numeric: false, disablePadding: true, label: 'Country', size: '5%' },
    { id: 'localidad', numeric: false, disablePadding: true, label: 'Location', size: '5%' },
    { id: 'planta', numeric: false, disablePadding: true, label: 'Plant', size: '20%' },
    { id: 'hostname', numeric: false, disablePadding: true, label: 'Hostname', size: '15%' },
    { id: 'marca', numeric: false, disablePadding: true, label: 'Brand', size: '5%' },
    { id: 'modelo', numeric: false, disablePadding: true, label: 'Model', size: '10%' },
    { id: 'numeroserie', numeric: false, disablePadding: true, label: 'Serial Number', size: '10%' },       
    { id: 'fechaupdate', numeric: false, disablePadding: true, label: 'Update Date', size: '5%' },
    { id: 'info', numeric: false, disablePadding: true, label: '', size: '5%' }    
];


const TablaOtrosDispositivos = ({token}) => {

    const [loading, setLoading] = React.useState(false); 

    const [rows, setRows] = useState(null);
    const [rowsTotal, setRowsTotal] = useState(null);
    const [filter, setFilter] = useState("");


    let privEliminacion = sessionStorage.getItem('ser_eliminar');

    const [bitacoras, setBitacoras] = useState(null);

    function obtenerBitacoras(registros)
    {
        setBitacoras(registros);
    }

    function aplicarFiltro(registros)
    {
        if(registros == null) setRows(registros); 
        else if(registros.length === 0)
        {
            setRows(registros)            
            swal ("There are no records" ,
                    "No information is recorded with the filters used" ,
                    "info");
        }
        else setRows(registros);
    }

    function aplicarFiltroCompleto(registros)
    {
        if(registros == null) setRowsTotal(registros); 
        else if(registros.length === 0)
        {
            setRowsTotal(registros);   
        }
        else setRowsTotal(registros);
    }
    

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.tipoequipo !== "") ? (row.tipoequipo.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.estado !== "") ? (row.estado.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.condicion !== "") ? (row.condicion.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.pais !== "") ? (row.pais.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.localidad !== "") ? (row.localidad.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.descripcion !== "") ? (row.descripcion.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.planta !== "") ? (row.planta.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.marca !== "") ? (row.marca.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.modelo !== "") ? (row.modelo.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.numeroserie !== "") ? (row.numeroserie.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.ipequipo !== "") ? (row.ipequipo.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.fechaupdate !== "") ? (row.fechaupdate.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
           
        })
    }

    return (
        <>
            <FiltroTablaOtrosDispositivos aplicarFiltro={aplicarFiltro} aplicarFiltroCompleto={aplicarFiltroCompleto} obtenerBitacoras={obtenerBitacoras} load={setLoading} token={token}/>         
            {
                (rows !== null)
                ?
                (
                    <>                     
                        <ContainerBusqueda> 
                        <br></br> 
                        
                            <TextField id="outlined-search" label="Busqueda" size="small" type="search" variant="outlined" 
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}                                
                            />
                            
                            <ExportarExcel rows={rows} columnas={Opciones.camposExcel} subtitulo={"Filtered records"} nombreSheet="OtherDevice" nombreArchivo="OtherDeviceExport"/>
                            {
                                (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>) :
                                (bitacoras === null) ? (<></>)
                                : (     
                                    <ExportarExcelBitacoras subtitulo={"Logs"} rows={bitacoras} columnas={Opciones.camposExcelBitacora} nombreSheet="LogsOtherDevice" nombreArchivo="LogsOtherDeviceExport"/>
                                )
                            }
                        <br></br>                  
                        </ContainerBusqueda>
                        <ContainerTable>
                        <TablaRegistros
                            headCells = { headCells }
                            rows = { buscar(rows) }
                            url = { '/dispositivos/' }
                            idFieldName = { 'id_otrodispositivo' }
                            initialSortByField = { 'tipoequipo' }
                            initialSortDirection = { 'desc' }
                            />
                        </ContainerTable>                    
                </>)
                :
                (
                    (loading) ? (
                        <>
                            <br></br>
                            <br></br>
                            <ContainerTable>
                                <Loading />
                            </ContainerTable>
                        </>
                    )  : null 
                )
            }
        </>
    )
}

export default TablaOtrosDispositivos
