import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import * as Opciones from './OpcionesUsuario'
import { obtenerUserForm, obtenerValoresUser, actualizarForm } from '../../functions/FuncUsuario'
import "../../css/formularioUsuario.css";

import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import Loading from '../../Loading';
import Select from 'react-select'
import { useHistory } from "react-router-dom";


import { style, styleNormal } from '../../Commons/Styles'

const FormularioUsuario = ({id, token}) => {

    const history = useHistory();
    let privAdmin = sessionStorage.getItem('admin_usuarios');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');

    const [registro, setRegistro] = useState(null);    
    const [valores, setValores] = useState(null);

    const [pais, setPais] = useState(null);
    const [localidad, setLocalidad] = useState(null);
    const [planta, setPlanta] = useState(null);

    const [errorPais, setErrorPais] = useState(false);
    const [errorLocalidad, setErrorLocalidad] = useState(false);
    const [errorIdioma, setErrorIdioma] = useState(false);

    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    function validarAcceso(usuario)
    {
      var res = validarPrivilegio(usuario);
      if(!res) {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
          history.push("/users")
        });
      }
    }

    function validarPrivilegio(response)
    {
      var val = false;

      if(privAdmin === "1" && localidadUser === response.localidad) val = true;
      if(privAdmin === "2" && paisUser === response.pais) val = true;
      if(privAdmin === "3") val = true;

      return val;
    }

    function mapResponseToRegistro(response){
    
      setRegistro({
        "username": response.usuario.username,
        "nombre": response.usuario.nombre,
        "correo": response.usuario.correo,
        "area": response.usuario.area,
        "rol": response.usuario.rol,
        "fechaingreso": response.usuario.fechaIngreso,
        "idioma": (response.usuario.idioma !== null && response.usuario.idioma !== "") ? {"label": response.usuario.idioma, "value": response.usuario.idioma} : null,                   
        "pais": (response.usuario.pais !== null && response.usuario.pais !== "") ? {"label": response.usuario.pais, "value": response.usuario.pais} : null,
        "localidad": (response.usuario.localidad !== null && response.usuario.localidad !== "") ? {"label": response.usuario.localidad, "value": response.usuario.localidad} : null,            
        "planta": (response.usuario.planta !== null && response.usuario.planta !== "") ? {"label": response.usuario.planta, "value": response.usuario.planta} : null,  
        "estado": (response.usuario.estado !== null) ? (response.usuario.estado === "ENABLE") ? true : false : false,
        "notificacion": (response.usuario.notificacion !== null) ? (response.usuario.notificacion === "TRUE") ? true : false : false,

        "privilegio_id": response.usuario.privilegios.privilegio_id,     

        "ser_leer": {"label": response.usuario.privilegios.ser_leer, "value": response.usuario.privilegios.ser_leer},
        "ser_escribir": {"label": response.usuario.privilegios.ser_escribir, "value": response.usuario.privilegios.ser_escribir},
        "ser_crear": {"label": response.usuario.privilegios.ser_crear, "value": response.usuario.privilegios.ser_crear},
        "ser_cargamasiva": {"label": response.usuario.privilegios.ser_cargamasiva, "value": response.usuario.privilegios.ser_cargamasiva},
        "ser_eliminar": {"label": response.usuario.privilegios.ser_eliminar, "value": response.usuario.privilegios.ser_eliminar},

        "ser_ot_leer": {"label": response.usuario.privilegios.ser_ot_leer, "value": response.usuario.privilegios.ser_ot_leer},
        "ser_ot_escribir": {"label": response.usuario.privilegios.ser_ot_escribir, "value": response.usuario.privilegios.ser_ot_escribir},
        "ser_ot_crear": {"label": response.usuario.privilegios.ser_ot_crear, "value": response.usuario.privilegios.ser_ot_crear},
        "ser_ot_cargamasiva": {"label": response.usuario.privilegios.ser_ot_cargamasiva, "value": response.usuario.privilegios.ser_ot_cargamasiva},
        "ser_ot_eliminar": {"label": response.usuario.privilegios.ser_ot_eliminar, "value": response.usuario.privilegios.ser_ot_eliminar},

        "esc_leer": {"label": response.usuario.privilegios.esc_leer, "value": response.usuario.privilegios.esc_leer},            
        "esc_escribir": {"label": response.usuario.privilegios.esc_escribir, "value": response.usuario.privilegios.esc_escribir},
        "esc_crear": {"label": response.usuario.privilegios.esc_crear, "value": response.usuario.privilegios.esc_crear},
        "esc_cargamasiva": {"label": response.usuario.privilegios.esc_cargamasiva, "value": response.usuario.privilegios.esc_cargamasiva},
        "esc_eliminar": {"label": response.usuario.privilegios.esc_eliminar, "value": response.usuario.privilegios.esc_eliminar},

        "app_leer": {"label": response.usuario.privilegios.app_leer, "value": response.usuario.privilegios.app_leer},            
        "app_escribir": {"label": response.usuario.privilegios.app_escribir, "value": response.usuario.privilegios.app_escribir},
        "app_crear": {"label": response.usuario.privilegios.app_crear, "value": response.usuario.privilegios.app_crear},
        "app_cargamasiva": {"label": response.usuario.privilegios.app_cargamasiva, "value": response.usuario.privilegios.app_cargamasiva},
        "app_eliminar": {"label": response.usuario.privilegios.app_eliminar, "value": response.usuario.privilegios.app_eliminar},
        "app_maestro": {"label": response.usuario.privilegios.app_maestro, "value": response.usuario.privilegios.app_maestro},

        "com_leer": {"label": response.usuario.privilegios.com_leer, "value": response.usuario.privilegios.com_leer},            
        "com_escribir": {"label": response.usuario.privilegios.com_escribir, "value": response.usuario.privilegios.com_escribir},
        "com_crear": {"label": response.usuario.privilegios.com_crear, "value": response.usuario.privilegios.com_crear},
        "com_cargamasiva": {"label": response.usuario.privilegios.com_cargamasiva, "value": response.usuario.privilegios.com_cargamasiva},
        "com_eliminar": {"label": response.usuario.privilegios.com_eliminar, "value": response.usuario.privilegios.com_eliminar},

        "dis_leer": {"label": response.usuario.privilegios.dis_leer, "value": response.usuario.privilegios.dis_leer},            
        "dis_escribir": {"label": response.usuario.privilegios.dis_escribir, "value": response.usuario.privilegios.dis_escribir},
        "dis_crear": {"label": response.usuario.privilegios.dis_crear, "value": response.usuario.privilegios.dis_crear},
        "dis_cargamasiva": {"label": response.usuario.privilegios.dis_cargamasiva, "value": response.usuario.privilegios.dis_cargamasiva},
        "dis_eliminar": {"label": response.usuario.privilegios.dis_eliminar, "value": response.usuario.privilegios.dis_eliminar},

        "sala_leer": {"label": response.usuario.privilegios.sala_leer, "value": response.usuario.privilegios.sala_leer},            
        "sala_escribir": {"label": response.usuario.privilegios.sala_escribir, "value": response.usuario.privilegios.sala_escribir},
        "sala_crear": {"label": response.usuario.privilegios.sala_crear, "value": response.usuario.privilegios.sala_crear},
        "sala_cargamasiva": {"label": response.usuario.privilegios.sala_cargamasiva, "value": response.usuario.privilegios.sala_cargamasiva},
        "sala_eliminar": {"label": response.usuario.privilegios.sala_eliminar, "value": response.usuario.privilegios.sala_eliminar},

        "cuentaser_leer": {"label": response.usuario.privilegios.cuentaser_leer, "value": response.usuario.privilegios.cuentaser_leer},            
        "cuentaser_escribir": {"label": response.usuario.privilegios.cuentaser_escribir, "value": response.usuario.privilegios.cuentaser_escribir},
        "cuentaser_crear": {"label": response.usuario.privilegios.cuentaser_crear, "value": response.usuario.privilegios.cuentaser_crear},
        "cuentaser_cargamasiva": {"label": response.usuario.privilegios.cuentaser_cargamasiva, "value": response.usuario.privilegios.cuentaser_cargamasiva},
        "cuentaser_eliminar": {"label": response.usuario.privilegios.cuentaser_eliminar, "value": response.usuario.privilegios.cuentaser_eliminar},

        "cert_leer": {"label": response.usuario.privilegios.cert_leer, "value": response.usuario.privilegios.cert_leer},            
        "cert_escribir": {"label": response.usuario.privilegios.cert_escribir, "value": response.usuario.privilegios.cert_escribir},
        "cert_crear": {"label": response.usuario.privilegios.cert_crear, "value": response.usuario.privilegios.cert_crear},
        "cert_cargamasiva": {"label": response.usuario.privilegios.cert_cargamasiva, "value": response.usuario.privilegios.cert_cargamasiva},
        "cert_eliminar": {"label": response.usuario.privilegios.cert_eliminar, "value": response.usuario.privilegios.cert_eliminar},

        "appback_leer": {"label": response.usuario.privilegios.appback_leer, "value": response.usuario.privilegios.appback_leer},            
        "appback_escribir": {"label": response.usuario.privilegios.appback_escribir, "value": response.usuario.privilegios.appback_escribir},
        "appback_crear": {"label": response.usuario.privilegios.appback_crear, "value": response.usuario.privilegios.appback_crear},
        "appback_cargamasiva": {"label": response.usuario.privilegios.appback_cargamasiva, "value": response.usuario.privilegios.appback_cargamasiva},
        "appback_eliminar": {"label": response.usuario.privilegios.appback_eliminar, "value": response.usuario.privilegios.appback_eliminar},

        "mas_leer": {"label": response.usuario.privilegios.mas_leer, "value": response.usuario.privilegios.mas_leer},            
        "mas_escribir": {"label": response.usuario.privilegios.mas_escribir, "value": response.usuario.privilegios.mas_escribir},
        "admin_personal": {"label": response.usuario.privilegios.admin_personal, "value": response.usuario.privilegios.admin_personal},
        "admin_otrasfuentes": {"label": response.usuario.privilegios.admin_otrasfuentes, "value": response.usuario.privilegios.admin_otrasfuentes},
        "admin_usuarios": {"label": response.usuario.privilegios.admin_usuarios, "value": response.usuario.privilegios.admin_usuarios}

        
      });
    }

    async function obtenerDatosBD(){

      var paisUsuario = "";
      var localidadUsuario = "";

      await obtenerUserForm(id, token).then((response) => {
        if(response.cod === 1){

          validarAcceso(response.usuario);

          paisUsuario = response.usuario.pais;
          localidadUsuario = response.usuario.localidad;
          setPais( {"label": response.usuario.pais, "value": response.usuario.pais});
          setLocalidad({"label": response.usuario.localidad, "value": response.usuario.localidad});
          setPlanta({"label": response.usuario.planta, "value": response.usuario.planta});

          mapResponseToRegistro(response);
        }
      });

      await obtenerValoresUser(token).then((response) => {
        if(response.cod === 1){

          const val = {
            "paises": response.paises,
            "localidades": response.localidades,
            "filtrolocalidad": response.filtrolocalidad,
            "plantas": response.plantas,
            "filtroplanta": response.filtroplanta
          }; 

          let array = [];

          const listas = ["paises","localidades","plantas"];

          listas.forEach(lista => {
            array = [];
            val[lista].map((u) => { array.push({"label": u, "value": u});})
            val[lista] = array;
          });

          array = [];
          val.filtrolocalidad.map((u) => { if(u.pais === paisUsuario) array.push({"label": u.localidad, "value": u.localidad});})
          val.localidades = array;

          array = [];
          val.filtroplanta.map((u) => { if(u.localidad === localidadUsuario) array.push({"label": u.planta, "value": u.planta});})
          val.plantas = array;

          setValores(val);
        }
      });
  }

  const cambioPais= (value) => { 
      if(value !== "" && value !== null){
          setPais(value);            
          filtrarLocalidades(value.value);   
          filtrarPlantas(null);            
      } else {
        setPais(null); 
        setLocalidad(null);
        setPlanta(null);         
      }
  }

  const cambioLocalidad= (value) => { 
    if(value !== "" && value !== null){
      setLocalidad({"label": value.value, "value": value.value});        
      filtrarPlantas(value.value);            
    } else {
      setLocalidad(null); 
      setPlanta(null);           
    }     
  }

  const cambioPlanta = (value) => { 
    if(value !== "" && value !== null) setPlanta({"label": value.value, "value": value.value});
    else setPlanta(null);      
  }

  function filtrarLocalidades(pais)
  {
      if(pais !== null && pais !== "")
      {
          const filtrolocalidadArray = valores.filtrolocalidad;
          var array = [];
          if(filtrolocalidadArray.length > 0){
            filtrolocalidadArray.map((p) => {
                  if(p.pais === pais) {
                      array.push({"label": p.localidad, "value": p.localidad});
                  }                
              });        
          }

          const valoresNuevo = valores;
          valoresNuevo.localidades = array; 
          setLocalidad(null);           
          setValores(valoresNuevo);
      }
  }

  function filtrarPlantas(localidad)
  {
      if(localidad !== null && localidad !== "")
      {
          const filtroplantaArray = valores.filtroplanta;
          var array = [];
          if(filtroplantaArray.length > 0){
            filtroplantaArray.map((p) => {
                  if(p.localidad === localidad) {
                      array.push({"label": p.planta, "value": p.planta});
                  }                
              });        
          }

          const valoresNuevo = valores;
          valoresNuevo.plantas = array; 
          setPlanta(null);           
          setValores(valoresNuevo);
      } else {

        const valoresSinPlantas = valores;
        valoresSinPlantas.plantas = []; 
        setPlanta(null);           
        setValores(valoresSinPlantas);
      }
  }

  const onSubmit = (data) => {

    if(validarValores(data)){
      swal("Form data", "Mandatory data must be entered", "warning");
    }
    else {
      const datosform = data;

      datosform.planta = planta;
      datosform.localidad = localidad;
      datosform.pais = pais;

      swal({
        title: "Update confirmation",
        text: "Do you want to update the registry?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async (afirmacion) => {
      
        if(afirmacion){
          await actualizarForm(datosform, registro, registro.privilegio_id, registro.username, token)
          .then((response) => {
            if(response.cod === 1)
            {
              obtenerregistro();
              swal("Successful update", "", "success");
            }
            else if(response.cod === 0){
              swal("Update error", response.mensaje, "error");
            }
            else {
                swal("Update error", "An error occurred during the update. Please contact the administrator.", "error");
            }
          })
        }
      })
    }
  }

  async function obtenerregistro()
  {
    await obtenerUserForm(id, token).then((response) => {
      if(response.cod === 1){

        setPais( {"label": response.usuario.pais, "value": response.usuario.pais});
        setLocalidad({"label": response.usuario.localidad, "value": response.usuario.localidad});
        setPlanta({"label": response.usuario.planta, "value": response.usuario.planta});

        mapResponseToRegistro(response);
      }
    });
  }

  function validarValores(data){
    var errores = false;

    if(localidad === null || localidad === "" || localidad === undefined) 
    {
        setErrorLocalidad(true);
        errores = true;
    }
    else setErrorLocalidad(false);

    if(data.idioma === null || data.idioma === "" || data.idioma === undefined) 
    {
        setErrorIdioma(true);
        errores = true;
    }
    else setErrorIdioma(false);

    return errores;
  }

  function opcionesPrivilegios(campo, valorpriv)
  {
    if(campo === undefined || campo === null ) return Opciones.privilegiosUsuario_Error;
    var valorcampo = campo.value;

    console.log(valorcampo, valorpriv)

    if(valorpriv === "3") {      
      return Opciones.privilegiosUsuario;
    }

    if(valorpriv === "2") {      
      if(valorcampo === "GLOBAL") return Opciones.privilegiosUsuario_Global;
      if(valorcampo === "COUNTRY") return Opciones.privilegiosUsuario_Nivel2;
      if(valorcampo === "LOCATION") return Opciones.privilegiosUsuario_Nivel2;
      if(valorcampo === "DENIED") return Opciones.privilegiosUsuario_Nivel2;
    }

    if(valorpriv === "1") {      
      if(valorcampo === "GLOBAL") return Opciones.privilegiosUsuario_Global;
      if(valorcampo === "COUNTRY") return Opciones.privilegiosUsuario_Country;
      if(valorcampo === "LOCATION") return Opciones.privilegiosUsuario_Nivel1;
      if(valorcampo === "DENIED") return Opciones.privilegiosUsuario_Nivel1;
    }

    return Opciones.privilegiosUsuario_Error;

  }


  React.useEffect(() => {
    if (registro !== null) {    

      Opciones.campos.forEach(c => {
        setValue(c, registro[c], {shouldValidate: true,shouldDirty: true})
      });   
      
    }
    else obtenerDatosBD();      
  }, [registro, valores])

  if(registro === null || valores === null) return (<Loading />)
  else return (
        <>
          <form
            style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
            autoComplete="off" onSubmit={handleSubmit(onSubmit)}
          >
            <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}}>
              <div className="containerinfo-usuario">              
                <div className="divinfo-usuario">
                  <div className="divinfo-usuario-izq">
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-nombre">Name</label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                          control={control}
                          id="i-nombre"
                          key="k-nombre"
                          defaultValue={registro ? registro.nombre : ''}
                          name='nombre'
                        />                              
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-correo">Email</label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                          control={control}
                          id="i-correo"
                          key="k-correo"
                          defaultValue={registro ? registro.correo : ''}
                          name='correo'
                        />                              
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label htmlFor="i-area">Area</label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          as={<input className="bootstrap-input" type='text'  />}                                      
                          control={control}
                          id="i-area"
                          key="k-area"
                          defaultValue={registro ? registro.area : ''}
                          name='area'
                        />                              
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label htmlFor="i-rol">Rol</label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          as={<input className="bootstrap-input" type='text'  />}                                      
                          control={control}
                          id="i-rol"
                          key="k-rol"
                          defaultValue={registro ? registro.rol : ''}
                          name='rol'
                        />                              
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label htmlFor="i-fechaingreso">Login Date</label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                          control={control}
                          id="i-fechaingreso"
                          key="k-fechaingreso"
                          defaultValue={registro ? registro.fechaingreso : ''}
                          name='fechaingreso'
                        />                              
                      </div>
                    </div>
                  </div>
                  <div className="divinfo-usuario-der">
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-pais">Country<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                          <Controller
                            render={(props) => <Select 
                              options={valores.paises}
                              placeholder=""
                              onChange={(value) => {
                                props.onChange(value);
                                cambioPais(value);                                                
                              }}  
                              value={pais}
                              isClearable={false}
                              defaultValue={(registro) ? registro.pais : null}
                              styles={(errorPais) ? style : styleNormal}  
                            />} 
                            control={control}
                            id="i-pais"
                            key="k-pais"                                    
                            name='pais'
                          />                              
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-localidad">Location<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                          <Controller
                            render={(props) => <Select 
                              options={valores.localidades}
                              placeholder=""
                              onChange={(value) => {
                                props.onChange(value);
                                cambioLocalidad(value);                                                
                              }}  
                              value={localidad}
                              isClearable={false}
                              defaultValue={(registro) ? registro.localidad : null} 
                              styles={(errorLocalidad) ? style : styleNormal}     
                            />} 
                            control={control}
                            id="i-localidad"
                            key="k-localidad"                                    
                            name='localidad'
                          />                              
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label htmlFor="i-planta">Plant</label> 
                      </div>
                      <div className="col-75">
                          <Controller
                            render={(props) => <Select 
                              options={valores.plantas}
                              placeholder=""
                              onChange={(value) => {
                                props.onChange(value);
                                cambioPlanta(value);                                                
                              }}  
                              value={planta}
                              isClearable={true}
                              defaultValue={(registro) ? registro.planta : null} 
                              styles={styleNormal}     
                            />} 
                            control={control}
                            id="i-planta"
                            key="k-planta"                                    
                            name='planta'
                          />                              
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-idioma">Language<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                          <Controller
                            render={(props) => <Select 
                              options={Opciones.idiomas}
                              placeholder=""
                              onChange={(value) => {
                                props.onChange(value);                                                                                
                              }}                                
                              isClearable={false}
                              defaultValue={(registro) ? registro.idioma : null}
                              styles={(errorIdioma) ? style : styleNormal}  
                            />} 
                            control={control}
                            id="i-idioma"
                            key="k-idioma"                                    
                            name='idioma'
                          />                              
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label htmlFor="i-estado">State</label> 
                      </div>
                      <div className="col-75">
                        <input                          
                          type="checkbox"
                          id="i-estado"
                          key="k-estado" 
                          name="estado"
                          placeholder="luo"
                          value={true}
                          ref={register}
                        />                           
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label htmlFor="i-notificacion">Notification</label> 
                      </div>
                      <div className="col-75">
                        <input                          
                          type="checkbox"
                          id="i-notificacion"
                          key="k-notificacion" 
                          name="notificacion"
                          placeholder="luo"
                          value={true}
                          ref={register}
                        />                           
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
               


              </div> 

              <div>
                {/*SERVIDOR*/}
                <div className="pemiso-30">
                  <div className="container-permiso"> 
                    <div className="titulo-permiso">
                      <label style={{fontWeight: 'bold'}}>Servers</label>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-ser_leer">Read<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.ser_leer, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.ser_leer : ""} 
                            styles={styleNormal}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-ser_leer"
                          key="k-ser_leer"                                                
                          name='ser_leer'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-ser_escribir">Write<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.ser_escribir, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.ser_escribir : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-ser_escribir"
                          key="k-ser_escribir"                                                
                          name='ser_escribir'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-ser_crear">Create<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.ser_crear, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.ser_crear : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-ser_crear"
                          key="k-ser_crear"                                                
                          name='ser_crear'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-ser_cargamasiva">Load<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.ser_cargamasiva, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.ser_cargamasiva : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-ser_cargamasiva"
                          key="k-ser_cargamasiva"                                                
                          name='ser_cargamasiva'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-ser_eliminar">Delete<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.ser_eliminar, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.ser_eliminar : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-ser_eliminar"
                          key="k-ser_eliminar"                                                
                          name='ser_eliminar'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="permiso-espacio">

                      </div>
                    </div>
                  </div>
                </div>
                {/*EQUIPOS*/}
                <div style={{marginLeft: '5%'}} className="pemiso-30">
                  <div className="container-permiso"> 
                    <div className="titulo-permiso">
                      <label style={{fontWeight: 'bold'}}>Desktop</label>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-esc_leer">Read<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.esc_leer, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.esc_leer : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-esc_leer"
                          key="k-esc_leer"                                                
                          name='esc_leer'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-esc_escribir">Write<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.esc_escribir, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.esc_escribir : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-esc_escribir"
                          key="k-esc_escribir"                                                
                          name='esc_escribir'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-esc_crear">Create<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.esc_crear, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.esc_crear : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-esc_crear"
                          key="k-esc_crear"                                                
                          name='esc_crear'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-esc_cargamasiva">Load<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.esc_cargamasiva, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.esc_cargamasiva : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-esc_cargamasiva"
                          key="k-esc_cargamasiva"                                                
                          name='esc_cargamasiva'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-esc_eliminar">Delete<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.esc_eliminar, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.esc_eliminar : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-esc_eliminar"
                          key="k-esc_eliminar"                                                
                          name='esc_eliminar'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="permiso-espacio">

                      </div>
                    </div>
                  </div>
                </div>
                {/*APLICACIONES*/}
                <div style={{marginLeft: '5%'}} className="pemiso-30">
                  <div className="container-permiso"> 
                    <div className="titulo-permiso">
                      <label style={{fontWeight: 'bold'}}>Application</label>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-app_leer">Read<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.app_leer, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.app_leer : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-app_leer"
                          key="k-app_leer"                                                
                          name='app_leer'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-app_escribir">Write*</label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.app_escribir, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.app_escribir : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-app_escribir"
                          key="k-app_escribir"                                                
                          name='app_escribir'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-app_crear">Create*</label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.app_crear, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.app_crear : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-app_crear"
                          key="k-app_crear"                                                
                          name='app_crear'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-app_cargamasiva">Load*</label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.app_cargamasiva, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.app_cargamasiva : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-app_cargamasiva"
                          key="k-app_cargamasiva"                                                
                          name='app_cargamasiva'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-app_eliminar">Delete*</label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.app_eliminar, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.app_eliminar : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-app_eliminar"
                          key="k-app_eliminar"                                                
                          name='app_eliminar'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-app_maestro">Master*</label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.app_maestro, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.app_maestro : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-app_maestro"
                          key="k-app_maestro"                                                
                          name='app_maestro'
                        /> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                {/*SALAS*/}
                <div className="pemiso-30">
                  <div className="container-permiso"> 
                    <div className="titulo-permiso">
                      <label style={{fontWeight: 'bold'}}>Room Servers</label>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-sala_leer">Read<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.sala_leer, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.sala_leer : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-sala_leer"
                          key="k-sala_leer"                                                
                          name='sala_leer'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-sala_escribir">Write<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.sala_escribir, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.sala_escribir : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-sala_escribir"
                          key="k-sala_escribir"                                                
                          name='sala_escribir'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-sala_crear">Create<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.sala_crear, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.sala_crear : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-sala_crear"
                          key="k-sala_crear"                                                
                          name='sala_crear'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-sala_cargamasiva">Load<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.sala_cargamasiva, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.sala_cargamasiva : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-sala_cargamasiva"
                          key="k-sala_cargamasiva"                                                
                          name='sala_cargamasiva'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-sala_eliminar">Delete<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.sala_eliminar, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.sala_eliminar : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-sala_eliminar"
                          key="k-sala_eliminar"                                                
                          name='sala_eliminar'
                        /> 
                      </div>
                    </div>
                  </div>
                </div>                
                {/*DISPOSITIVOS*/}
                <div style={{marginLeft: '5%'}} className="pemiso-30">
                  <div className="container-permiso"> 
                    <div className="titulo-permiso">
                      <label style={{fontWeight: 'bold'}}>Other Devices</label>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-dis_leer">Read<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.dis_leer, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.dis_leer : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-dis_leer"
                          key="k-dis_leer"                                                
                          name='dis_leer'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-dis_escribir">Write<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.dis_escribir, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.dis_escribir : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-dis_escribir"
                          key="k-dis_escribir"                                                
                          name='dis_escribir'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-dis_crear">Create<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.dis_crear, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.dis_crear : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-dis_crear"
                          key="k-dis_crear"                                                
                          name='dis_crear'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-dis_cargamasiva">Load<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.dis_cargamasiva, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.dis_cargamasiva : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-dis_cargamasiva"
                          key="k-dis_cargamasiva"                                                
                          name='dis_cargamasiva'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-dis_eliminar">Delete<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.dis_eliminar, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.dis_eliminar : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-dis_eliminar"
                          key="k-dis_eliminar"                                                
                          name='dis_eliminar'
                        /> 
                      </div>
                    </div>
                  </div>
                </div>
                {/*APLICACIONES BACK*/}
                <div style={{marginLeft: '5%'}} className="pemiso-30">
                  <div className="container-permiso"> 
                    <div className="titulo-permiso">
                      <label style={{fontWeight: 'bold'}}>Application BO</label>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-appback_leer">Read<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.appback_leer, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.appback_leer : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-appback_leer"
                          key="k-appback_leer"                                                
                          name='appback_leer'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-appback_escribir">Write<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.appback_escribir, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.appback_escribir : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-appback_escribir"
                          key="k-appback_escribir"                                                
                          name='appback_escribir'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-appback_crear">Create<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.appback_crear, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.appback_crear : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-appback_crear"
                          key="k-appback_crear"                                                
                          name='appback_crear'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-appback_cargamasiva">Load<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.appback_cargamasiva, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.appback_cargamasiva : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-appback_cargamasiva"
                          key="k-appback_cargamasiva"                                                
                          name='appback_cargamasiva'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-appback_eliminar">Delete<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.appback_eliminar, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.appback_eliminar : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-appback_eliminar"
                          key="k-appback_eliminar"                                                
                          name='appback_eliminar'
                        /> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                {/*DISPOSITIVOS*/}
                <div className="pemiso-20">
                  <div className="container-permiso"> 
                    <div className="titulo-permiso">
                      <label style={{fontWeight: 'bold'}}>Account Control</label>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-cuentaser_leer">Read<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.cuentaser_leer, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.cuentaser_leer : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-cuentaser_leer"
                          key="k-cuentaser_leer"                                                
                          name='cuentaser_leer'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-cuentaser_escribir">Write<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.cuentaser_escribir, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.cuentaser_escribir : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-cuentaser_escribir"
                          key="k-cuentaser_escribir"                                                
                          name='cuentaser_escribir'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-cuentaser_crear">Create<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.cuentaser_crear, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.cuentaser_crear : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-cuentaser_crear"
                          key="k-cuentaser_crear"                                                
                          name='cuentaser_crear'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-cuentaser_cargamasiva">Load<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.cuentaser_cargamasiva, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.cuentaser_cargamasiva : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-cuentaser_cargamasiva"
                          key="k-cuentaser_cargamasiva"                                                
                          name='cuentaser_cargamasiva'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-cuentaser_eliminar">Delete<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.cuentaser_eliminar, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.cuentaser_eliminar : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-cuentaser_eliminar"
                          key="k-cuentaser_eliminar"                                                
                          name='cuentaser_eliminar'
                        /> 
                      </div>
                    </div>
                  </div>
                </div>
                {/*COMUNICACIONES*/}
                <div style={{marginLeft: '3%'}} className="pemiso-20">
                  <div className="container-permiso"> 
                    <div className="titulo-permiso">
                      <label style={{fontWeight: 'bold'}}>Comunications</label>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-com_leer">Read<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.com_leer, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.com_leer : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-com_leer"
                          key="k-com_leer"                                                
                          name='com_leer'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-com_escribir">Write<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.com_escribir, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.com_escribir : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-com_escribir"
                          key="k-com_escribir"                                                
                          name='com_escribir'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-com_crear">Create<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.com_crear, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.com_crear : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-com_crear"
                          key="k-com_crear"                                                
                          name='com_crear'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-com_cargamasiva">Load<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.com_cargamasiva, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.com_cargamasiva : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-com_cargamasiva"
                          key="k-com_cargamasiva"                                                
                          name='com_cargamasiva'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-com_eliminar">Delete<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.com_eliminar, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.com_eliminar : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-com_eliminar"
                          key="k-com_eliminar"                                                
                          name='com_eliminar'
                        /> 
                      </div>
                    </div>
                  </div>
                </div>
                {/*CERTIFICADOS*/}
                <div style={{marginLeft: '2.5%'}} className="pemiso-20">
                  <div className="container-permiso"> 
                    <div className="titulo-permiso">
                      <label style={{fontWeight: 'bold'}}>Certificate</label>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-cert_leer">Read<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.cert_leer, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.cert_leer : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-cert_leer"
                          key="k-cert_leer"                                                
                          name='cert_leer'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-cert_escribir">Write<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.cert_escribir, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.cert_escribir : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-cert_escribir"
                          key="k-cert_escribir"                                                
                          name='cert_escribir'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-cert_crear">Create<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.cert_crear, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.cert_crear : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-cert_crear"
                          key="k-cert_crear"                                                
                          name='cert_crear'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-cert_cargamasiva">Load<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.cert_cargamasiva, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.cert_cargamasiva : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-cert_cargamasiva"
                          key="k-cert_cargamasiva"                                                
                          name='cert_cargamasiva'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-cert_eliminar">Delete<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.cert_eliminar, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.cert_eliminar : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-cert_eliminar"
                          key="k-cert_eliminar"                                                
                          name='cert_eliminar'
                        /> 
                      </div>
                    </div>
                  </div>
                </div>
                {/*Administrador*/}
                <div style={{marginLeft: '2.5%'}} className="pemiso-20">
                  <div className="container-permiso"> 
                    <div className="titulo-permiso">
                      <label style={{fontWeight: 'bold'}}>Administration</label>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-mas_leer">Masters R<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={Opciones.privilegiosUsuario}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.mas_leer : ""}                            
                            control={control}
                            isDisabled={(privAdmin > 2) ? false : true}
                          />} 
                          control={control} 
                          id="i-mas_leer"
                          key="k-mas_leer"                                                
                          name='mas_leer'
                        /> 
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-mas_escribir">Masters W<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={Opciones.privilegiosUsuario}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.mas_escribir : ""}                            
                            control={control}
                            isDisabled={(privAdmin > 2) ? false : true}
                          />} 
                          control={control} 
                          id="i-mas_escribir"
                          key="k-mas_escribir"                                                
                          name='mas_escribir'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-admin_personal">Employees<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={Opciones.privilegiosUsuario}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.admin_personal : ""}                            
                            control={control}
                            isDisabled={(privAdmin > 2) ? false : true}
                          />} 
                          control={control} 
                          id="i-admin_personal"
                          key="k-admin_personal"                                                
                          name='admin_personal'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-admin_otrasfuentes">Other Sources<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={Opciones.privilegiosUsuario}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.admin_otrasfuentes : ""}                            
                            control={control}
                            isDisabled={(privAdmin > 2) ? false : true}
                          />} 
                          control={control} 
                          id="i-admin_otrasfuentes"
                          key="k-admin_otrasfuentes"                                                
                          name='admin_otrasfuentes'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-admin_usuarios">Users<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={Opciones.privilegiosUsuario}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}                            
                            placeholder=""
                            defaultValue={(registro) ? registro.admin_usuarios : ""}                            
                            control={control}
                            isDisabled={(privAdmin > 2) ? false : true}
                          />} 
                          control={control} 
                          id="i-admin_usuarios"
                          key="k-admin_usuarios"                                                
                          name='admin_usuarios'
                        /> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*aca empieza*/}

              <div>
                {/*DISPOSITIVOS OT*/}
                <div className="pemiso-20">
                  <div className="container-permiso"> 
                    <div className="titulo-permiso">
                      <label style={{fontWeight: 'bold'}}>Devices OT</label>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-ser_ot_leer">Read<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.ser_ot_leer, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.ser_ot_leer : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-ser_ot_leer"
                          key="k-ser_ot_leer"                                                
                          name='ser_ot_leer'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-ser_ot_escribir">Write<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.ser_ot_escribir, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.ser_ot_escribir : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-ser_ot_escribir"
                          key="k-ser_ot_escribir"                                                
                          name='ser_ot_escribir'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-ser_ot_crear">Create<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.ser_ot_crear, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.ser_ot_crear : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-ser_ot_crear"
                          key="k-ser_ot_crear"                                                
                          name='ser_ot_crear'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-ser_ot_cargamasiva">Load<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.ser_ot_cargamasiva, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.ser_ot_cargamasiva : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-ser_ot_cargamasiva"
                          key="k-ser_ot_cargamasiva"                                                
                          name='ser_ot_cargamasiva'
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-ser_ot_eliminar">Delete<label style={{color: 'red'}}>{"*"}</label></label> 
                      </div>
                      <div className="col-75">
                        <Controller
                          render={(props) => <Select 
                            options={opcionesPrivilegios(registro.ser_ot_eliminar, privAdmin)}
                            onChange={(value) => {
                              props.onChange(value);                                                        
                            }}
                            placeholder=""
                            defaultValue={(registro) ? registro.ser_ot_eliminar : ""}                            
                            control={control}
                          />} 
                          control={control} 
                          id="i-ser_ot_eliminar"
                          key="k-ser_ot_eliminar"                                                
                          name='ser_ot_eliminar'
                        /> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto', marginBottom: '30px', marginTop: '20px', float: 'left'}}>
                <div style={{width: '20%', marginRight:'auto', marginLeft: 'auto'}}>
                    <button className="boton-from">Save</button>
                </div>           
              </div>



            </div>
          </form>
        </>
    )
}

export default FormularioUsuario
