export const ambientesFiltro = [    
    {label: "PRODUCTIVO", value: "PRODUCTIVO"},
    {label: "DESARROLLO", value: "DESARROLLO"},
    {label: "QAS", value: "QAS"},
    {label: "PRE-PRODUCTIVO", value: "PRE-PRODUCTIVO"}
]

export const ambientesFiltroTabla = [    
    "PRODUCTIVO",
    "DESARROLLO",
    "QAS",
    "PRE-PRODUCTIVO"
]

export const estadosFiltro = [    
    {label: "OPERATIVO", value: "OPERATIVO"},
    {label: "DE BAJA", value: "DE BAJA"}  
]

export const estadosFiltroTabla = [    
    "OPERATIVO",
    "DE BAJA"  
]

export const estados = [
    "OPERATIVO",
    "DE BAJA"
]

export const ambientes = [
    "PRODUCTIVO",
    "DESARROLLO",
    "QAS",
    "PRE-PRODUCTIVO"   
]

export const tipolicencias = [
    "PERPETUA",
    "POR SERVIDOR",
    "TOKEN",
    "POR EQUIPO"  
]

export const tiposw = [
    "EXTERNO",
    "LEGADO"
]

export const camposExcelCarga = [   
    {nombre:"FILA", valor:"fila"},
    {nombre:"DETALLE", valor:"detalle"}    
]

export const camposExcelServidores = [
    {nombre:"SERVER", valor:"servidor"},
    {nombre:"CONDTITION", valor:"estado"},    
    {nombre:"ENVIERONMENT", valor:"ambiente"},
    {nombre:"SERVER TYPE", valor:"tiposervidor"},
    {nombre:"DESCRIPTION", valor:"descripcion"}
]

export const camposExcelEquipos = [
    {nombre:"Desktop ID", valor:"id_equipoescritorio"},
    {nombre:"STATE", valor:"estado"},    
    {nombre:"CONDITION", valor:"condicion"},
    {nombre:"DESKTOP NAME", valor:"nombreequipo"},
    {nombre:"USER NAME", valor:"nombreusuario"},
    {nombre:"OBSERVATIONS", valor:"observaciones"}
]

export const camposExcel = [
    {nombre:"APPLICATION", valor:"nombre"},
    {nombre:"TYPE APPLICATION", valor:"tiposoftware"},    
    {nombre:"VERSION", valor:"version"},
    {nombre:"COMMENT", valor:"comentario"},
    {nombre:"CONDITION", valor:"estado"},
    {nombre:"BUSINESS", valor:"negocio"},
    {nombre:"ENVIRONMENT", valor:"ambiente"},
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"OWNER", valor:"gerencia"},
    {nombre:"LINK", valor:"link"},
    {nombre:"WAF", valor:"waf"},
    {nombre:"OBSERVATION WAF", valor:"observacionwaf"},
    {nombre:"CONTRACT NUMBER", valor:"numerocontrato"},
    {nombre:"TYPE LICENSE", valor:"tipolicencia"},
    {nombre:"INVOICE DATE", valor:"fechafactura"},
    {nombre:"EXPIRATION DATE", valor:"vencimientocontrato"},
    {nombre:"PURCHASE INVOICE NUMBER", valor:"numerofactura"},
    {nombre:"ANNUAL COST", valor:"costoanual"},
    {nombre:"NUMBER LICENSES", valor:"numerolicencia"},    
    {nombre:"PROVIDER", valor:"proveedor"},
    {nombre:"OBSERVATIONS", valor:"observaciones"},
    {nombre:"DATABASE", valor:"basedatos"},
    {nombre:"BD VERSION", valor:"versionbd"},
    {nombre:"LANGUAGE", valor:"lenguaje"},

    {nombre:"RESPONSABLE", valor:"responsable"},
    {nombre:"OPERATING SYSTEM", valor:"sistemaoperativo"},
    {nombre:"CLIENT PLATFORM", valor:"plataforma"},
    {nombre:"PUBLISHING PLATFORM", valor:"plataformapublicacion"},
    {nombre:"CRITICALITY", valor:"criticidad"},
    {nombre:"AUTHENTICATION MECHANISM", valor:"autenticacion"},
    {nombre:"CENTRALIZED/DISTRIBUTED", valor:"centralizada"},
    {nombre:"ADMINISTRATION PROCEDURE", valor:"procedimientoadmin"},
    {nombre:"REDUNDANCY", valor:"redundancia"},
    {nombre:"ACCESS MANAGER", valor:"adminacceso"},
    {nombre:"DRP", valor:"drp"},
    {nombre:"DRP TEST DATE", valor:"fechadrp"},
    {nombre:"BACKRESTS", valor:"respaldos"},
    {nombre:"BACKRESTS TEST DATE", valor:"fecharespaldos"},
    {nombre:"INFORM TO", valor:"informara"},
    
    {nombre:"PRODUCER", valor:"fabricante"},
    {nombre:"ID APP", valor:"id_aplicacion"},
    {nombre:"INTEGRATIONS", valor:"integraciones"}
]

export const camposExcelSccm = [
    {nombre:"SERVER", valor:"nombre"},
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"LOCATION", valor:"localidad"},
    {nombre:"BUSINESS", valor:"negocio"},
    {nombre:"ENVIRONMENT", valor:"ambiente"},
    {nombre:"OPERATING SYSTEM", valor:"soservidor"},
    {nombre:"APPLICATION", valor:"nombreaplicacion"},
    {nombre:"VERSION", valor:"version"},
    {nombre:"DATE SCCM", valor:"fechaupdate"}
]

export const camposExcelSccmEquipo = [    
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"LOCATION", valor:"localidad"},
    {nombre:"BUSINESS", valor:"negocio"},
    {nombre:"DESKTOP", valor:"nombre"},
    {nombre:"USER NAME", valor:"nombreusuario"},    
    {nombre:"APPLICATION", valor:"nombreaplicacion"},
    {nombre:"VERSION", valor:"version"},
    {nombre:"INSTALL DATE", valor:"fechaupdate"}
]

export const camposExcelBitacora = [
    {nombre:"ID APP", valor:"id_registro"},
    {nombre:"ID LOG", valor:"id_bitacora"},
    {nombre:"IOD MODIFICATION", valor:"id_modificacion"},
    {nombre:"DATE", valor:"fecha"},
    {nombre:"RESPONSABLE", valor:"responsable"},
    {nombre:"FIELD", valor:"campo"},
    {nombre:"AFTER VALUE", valor:"valor_anterior"},
    {nombre:"BEFORE VALUE", valor:"valor_actual"}    
]

export const campos = [
    "nombre",
    "tiposoftware",
    "localidad",
    "software",
    "version",
    "comentario",
    "estado",
    "negocio",
    "ambiente",
    "gerencia",
    "observaciones",
    "link",
    "waf",
    "observacionwaf",
    "numerocontrato",
    "tipolicencia",
    "fechafactura",
    "vencimientocontrato",
    "numerofactura",
    "costoanual",
    "numerolicencia",
    "fabricante",
    "proveedor",
    "modificadopor",
    "fechaupdate",
    "pais",
    "lenguaje",
    
    "criticidad",
    "plataforma",
    "responsable",
    "sistemaoperativo",
    "autenticacion",
    "centralizada",
    "adminacceso",
    "drp",
    "respaldos",
    "redundancia",
    "procedimientoadmin",
    "fechadrp",
    "fecharespaldos",
    "informara",
    "plataformapublicacion"
]

export const info_spanish = {
    "aplicacion": "Nombre de aplicación",
    "version": "Versión en número o planta",
    "ambiente": "Ambiente",
    "tiposoftware": "Tipo de Aplicación ",
    "estado": "¿Aplicación en uso?",
    "pais": "Pais",
    "negocio": "Negocio",
    "responsable": "Area TI responsable",
    "gerencia": "Propietario del negocio",
    "sistemaoperativo": "Sistema Operativo sobre el cual funciona la aplicación",
    "plataforma": "Plataforma cliente sobre la cual funciona la aplicación",
    "plataformapublicacion": "Plataforma residente sobre la cual funciona la aplicación",
    "criticidad": "Criticidad",
    "fabricante": "info de version en español",
    "proveedor": "info de version en español",
    "lenguaje": "info de version en español",
    "basedatos": "info de version en español",
    "versionbd": "info de version en español",
    "link": "info de version en español",
    "numerocontrato": "info de version en español",
    "tipolicencia": "info de version en español",
    "fechafactura": "info de version en español",
    "vencimientocontrato": "info de version en español",
    "numerofactura": "info de version en español",
    "numerolicencia": "info de version en español",
    "autenticacion": "info de version en español",
    "centralizada": "info de version en español",
    "procedimientoadmin": "info de version en español",
    "redundancia": "info de version en español",
    "adminacceso": "info de version en español",
    "drp": "info de version en español",
    "fechadrp": "info de version en español",
    "respaldos": "info de version en español",
    "fecharespaldos": "info de version en español",
    "observaciones": "info de version en español"    
}

export const info_english = {
    "aplicacion": "info de version en ingles",
    "version": "info de version en ingles",
    "ambiente": "info de version en ingles",
    "tiposoftware": "info de version en ingles",
    "estado": "info de version en ingles",
    "pais": "info de version en ingles",
    "negocio": "info de version en ingles",
    "responsable": "info de version en ingles",
    "gerencia": "info de version en ingles",
    "sistemaoperativo": "info de version en ingles",
    "plataforma": "info de version en ingles",
    "plataformapublicacion": "info de version en ingles",
    "criticidad": "info de version en ingles",
    "fabricante": "info de version en ingles",
    "proveedor": "info de version en ingles",
    "lenguaje": "info de version en ingles",
    "basedatos": "info de version en ingles",
    "versionbd": "info de version en ingles",
    "link": "info de version en ingles",
    "numerocontrato": "info de version en ingles",
    "tipolicencia": "info de version en ingles",
    "fechafactura": "info de version en ingles",
    "vencimientocontrato": "info de version en ingles",
    "numerofactura": "info de version en ingles",
    "numerolicencia": "info de version en ingles",
    "autenticacion": "info de version en ingles",
    "centralizada": "info de version en ingles",
    "procedimientoadmin": "info de version en ingles",
    "redundancia": "info de version en ingles",
    "adminacceso": "info de version en ingles",
    "drp": "info de version en ingles",
    "fechadrp": "info de version en ingles",
    "respaldos": "info de version en ingles",
    "fecharespaldos": "info de version en ingles",
    "observaciones": "info de version en ingles"    
}
