import React, {Fragment, useState} from 'react'
import { useForm } from "react-hook-form";

import {
    obtenerValoresForm, 
    crearForm } 
from '../functions/FuncServidor'

import * as Opciones from './OpcionesServidor'

import "react-datepicker/dist/react-datepicker.css";
import "../css/formularioServidor.css";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import Loading from '../Loading';
import { validarEmail } from '../functions/MetodosComunes';

import { Formulario } from '../Commons/Formulario';

const CreacionServidor = ({token}) => {

    let privilegio = sessionStorage.getItem('ser_crear');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');
    let idiomaUser = sessionStorage.getItem('idioma');

    const history = useHistory();
    const [registro, setRegistro] = useState(null);
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const [errorServidor, setErrorServidor] = useState(false);
    const [errorLocalidad, setErrorLocalidad] = useState(false);
    const [errorPlataforma, setErrorPlataforma] = useState(false);
    const [errorEstado, setErrorEstado] = useState(false);
    const [errorCriticidad, setErrorCriticidad] = useState(false);

    const [errorInformara, setErrorInformara] = useState(false);

    async function obtenerDatosBD()
    {
        await obtenerValoresForm("SERVER", idiomaUser,token).then((response) => {
            console.log(response)
            const val = {
                "localidades": response.localidades,
                "propiedades": response.propiedades,
                "ambientes": response.ambientes,
                "criticidades": response.criticidades,
                "estados": response.estados,
                "marcas": response.marcas,
                "tiposbd": response.tiposbd,
                "versionesws": response.versionesws,
                "antivirus": response.antivirus,
                "negocios": response.negocios,
                "soservidor": response.soservidor,
                "tiposservidor": response.tiposservidor,
                "plataformas": response.plataformas,
                "responsables": response.responsables,
                "filtrolocalidad": response.filtrolocalidad,
                "traducciones": response.traducciones,
                "propietariosred": response.propietariosred,
                "tiposreinicio": response.tiposreinicio
            };

            const listas = ["localidades", "propietariosred", "propiedades", "ambientes", "criticidades", "estados", "marcas", "tiposbd", "versionesws", "antivirus", "negocios", "soservidor", "tiposservidor", "plataformas", "responsables", "tiposreinicio"];

            let array = [];
            listas.forEach(lista => {
                array = [];
                val[lista].map((u) => {
                    array.push({"label": u, "value": u});
                })
                val[lista] = array;
            });

            if(privilegio === "1"){
                array = [];
                val["localidades"].map((u) => {                    
                    if(u.value === localidadUser) array.push({"label": u.label, "value": u.value});
                })
                val["localidades"] = array;
            }

            if(privilegio === "2")
            {
                array = [];
                val["filtrolocalidad"].map((u) => {                    
                    if(u.pais === paisUser) array.push({"label": u.localidad, "value": u.localidad});
                })
                val["localidades"] = array;
            }

            setValores(val);
        });
    }

    React.useEffect(() => {
        if (valores !== null) {

            if(registro == null)
            {
                Opciones.campos.forEach(c => {
                    setValue(c, "", {shouldValidate: true,shouldDirty: true})
                });
            }
            else
            {
                Opciones.campos.forEach(c => {
                    setValue(c, registro[c], {shouldValidate: true,shouldDirty: true})
                });

            }
        }
        else obtenerDatosBD();
    })

    function validarServidor(reg)
    {
        let errors = {
            "error_type_01": "Mandatory data must be entered",
            "error_type_02": "Invalid email",
        }
        let error = "";

        if(reg.servidor === null || reg.servidor === "") 
        {   
            setErrorServidor(true);
            error = errors["error_type_01"];
        }
        else setErrorServidor(false);

        if(reg.localidad === null || reg.localidad === "" || reg.localidad === undefined) 
        {   
            setErrorLocalidad(true);
             error = errors["error_type_01"];
        }
        else setErrorLocalidad(false);

        if(reg.plataforma === null || reg.plataforma === "" || reg.plataforma === undefined) 
        {   
            setErrorPlataforma(true);
            error = errors["error_type_01"];
        }
        else setErrorPlataforma(false);

        if(reg.estado === null || reg.estado === "" || reg.estado === undefined) 
        {   
            setErrorEstado(true);
            error = errors["error_type_01"];
        }
        else setErrorEstado(false);

        if(reg.criticidad === null || reg.criticidad === "" || reg.criticidad === undefined) 
        {   
            setErrorCriticidad(true);
            error = errors["error_type_01"];
        }
        else setErrorCriticidad(false);
        
        if (reg.fechaexpiracion != null && reg.fechaexpiracion != ""){
            if (reg.informara === null || reg.informara === "") {
                setErrorInformara(true);
                error = errors["error_type_01"];
            } else setErrorInformara(false);
        } else setErrorInformara(false);  

        if(reg.informara.length > 0)
        {
            if(!validarEmail(reg.informara))
            {
                setErrorInformara(true);
                error = errors["error_type_02"];
            }
            else setErrorInformara(false);
        }

        return error;
    }

    const onSubmit = (data) => {

        setRegistro(data);
        const datosform = data;

        let nombreusuario = sessionStorage.getItem('nombre');
        datosform.modificadopor = nombreusuario;
        datosform.fechaupdate = new Date();


        let errorMessage = validarServidor(datosform);

        if(errorMessage.length > 0)
        {
            swal("Form data", errorMessage, "warning");     
        }
        else{
            swal({
                title: "Creation confirmation",
                text: "Do you want to create the record?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion){
                    
                    await crearForm(datosform, token)
                    .then((response) => {
                        if(response !== null){                            
                            if(response.cod === 1){
                                swal("Successful creation", "", "success").then(() => {
                                    history.push("/servidores/"+response.id);
                                });
                            }
                            else{
                                swal("Creation error", response.mensaje, "error");
                            }
                        }
                        else{
                            swal("Creation error", "An error occurred during the update. Please contact the administrator.", "error");
                        }
                    })
                }
            })
        }
    }

    let fields = [
        { group: 1, name: 'plataforma', label: 'Network', disabled: false, hasErrorValidation: errorPlataforma, type: 'select', valuesNameField: 'plataformas' },  
        { group: 1, name: 'servidor', label: 'Server', disabled: false, hasErrorValidation: errorServidor, type: 'text' },  
        { group: 1, name: 'descripcion', label: 'Description', disabled: false, hasErrorValidation: null, type: 'textarea' },
        { group: 1, name: 'localidad', label: 'Location', disabled: false, hasErrorValidation: errorLocalidad, type: 'select', valuesNameField: 'localidades' },
        { group: 1, name: 'propiedadde', label: 'Property of', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'propiedades' },
        { group: 1, name: 'negocio', label: 'Business', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'negocios' },
        { group: 1, name: 'estado', label: 'State', disabled: false, hasErrorValidation: errorEstado, type: 'select', valuesNameField: 'estados' },
        { group: 1, name: 'criticidad', label: 'Criticality', disabled: false, hasErrorValidation: errorCriticidad, type: 'select', valuesNameField: 'criticidades' },
        { group: 1, name: 'ambiente', label: 'Environment', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'ambientes' },
        { group: 1, name: 'responsable', label: 'Responsable', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'responsables' },
        { group: 1, name: 'administradortexto', label: 'Administrator', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 1, name: 'ipservidor', label: 'Server IP', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 1, name: 'ippublica', label: 'Public IP', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 1, name: 'tiposervidor', label: 'Server Type', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposservidor' },  
        { group: 1, name: 'soservidor', label: 'Operating System', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'soservidor' },
        { group: 1, name: 'antivirus', label: 'Antivirus', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'antivirus' },
        { group: 1, name: 'fileserver', label: 'File Server', disabled: false, hasErrorValidation: null, type: 'checkbox' },
        { group: 1, name: 'printserver', label: 'Print Server', disabled: false, hasErrorValidation: null, type: 'checkbox' },
        { group: 1, name: 'webserver', label: 'Web Server', disabled: false, hasErrorValidation: null, type: 'checkbox' },
        { group: 1, name: 'versionservidor', label: 'Version WebServer', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'versionesws' },
        { group: 1, name: 'aplicaciones', label: 'Applications', disabled: false, hasErrorValidation: null, type: 'checkbox' },
        { group: 1, name: 'servidorbd', label: 'DB Server', disabled: false, hasErrorValidation: null, type: 'checkbox' },
        { group: 1, name: 'tipobd', label: 'Type DB', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposbd' },

        { group: 2, name: 'respaldoserver', label: 'Server Backup', disabled: false, hasErrorValidation: null, type: 'checkbox' },
        { group: 2, name: 'ubicacionrespaldoserver', label: 'Ubication', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 2, name: 'respaldodata', label: 'Data Backup', disabled: false, hasErrorValidation: null, type: 'checkbox' },
        { group: 2, name: 'ubicacionrespaldodata', label: 'Ubication', disabled: false, hasErrorValidation: null, type: 'text' },

        { group: 3, name: 'tiporeinicio', label: 'Type Reset Threshold', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposreinicio' },
        { group: 3, name: 'ultimoreinicio', label: 'Last Restart Date (YYYY/MM/DD)', disabled: true, hasErrorValidation: null, type: 'text' },
        
        { group: 4, name: 'pachadosccm', label: 'SCCM Patching', disabled: false, hasErrorValidation: null, type: 'checkbox' },
        { group: 4, name: 'marca', label: 'Brand', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'marcas' },
        { group: 4, name: 'vlan', label: 'VLAN', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 4, name: 'numeroserie', label: 'Serial Number', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 4, name: 'fechahabilitacion', label: 'Enable Date (YYYY/MM/DD)', disabled: false, hasErrorValidation: null, type: 'datepicker' },
        { group: 4, name: 'fechabaja', label: 'Down Date (YYYY/MM/DD)', disabled: false, hasErrorValidation: null, type: 'datepicker' },
        { group: 4, name: 'fechaexpiracion', label: 'Warranty Expiration Date (YYYY/MM/DD)', disabled: false, hasErrorValidation: null, type: 'datepicker' },
        { group: 4, name: 'informara', label: 'Inform To', disabled: false, hasErrorValidation: errorInformara, type: 'text' },
        { group: 4, name: 'comentario', label: 'Observation', disabled: false, hasErrorValidation: null, type: 'textarea' },
    ];

    if(valores === null) return (<Loading />)
    else
    return (
        <Fragment>
            <div className="container-servidor">
                <Formulario
                    fields = { fields }
                    registro = { registro }
                    valores = { valores }
                    privModificar = { true }
                    onSubmit = { onSubmit }
                    register = { register }
                    handleSubmit = { handleSubmit }
                    control = { control }
                />    
            </div>
        </Fragment>
    )
}

export default CreacionServidor
