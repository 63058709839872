import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import swal from 'sweetalert';
import { crearTraduccion } from '../../functions/FuncTraduccion'

const CreacionTraduccion = ({modulo, token, errorNombre, errorSpanish, errorEnglish, errorPortuguese, creacionFinalizada, validarDatos}) => {
    
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});
        
    const onSubmit = (data) => {

        if(validarDatos(data)){
            swal("Create Error", "Mandatory fields were submitted within the form with no value", "warning");
        }
        else {
            swal({
                title: "Create confirmation",
                text: "Do you want to create the registry? ",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion)
                {
                    await crearTraduccion(modulo, data.nombre, data.spanish, data.english, data.portuguese, token).then((response) => {

                        console.log(response)

                        if(response.cod === 1)
                        {
                            creacionFinalizada();
                            setValue("nombre", "");
                            setValue("spanish", "");
                            setValue("english", "");
                            swal("Successful update", "", "success");
                        }
                        else if(response.cod === 0){
                            swal("Update error", response.mensaje, "error");
                        }
                        else {
                            swal("Update error", "An error occurred during the update. Please contact the administrator.", "error");
                        }
                    });  

                }
            })
        }
    }

    React.useEffect(() => {
        setValue("nombre", "");
        setValue("spanish", "");
        setValue("english", "");
    }, [modulo]);

    return (
        <>
            <form
                style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                autoComplete="off" onSubmit={handleSubmit(onSubmit)}
            >
                <div className="container-traducciones">
                    <div className="row">
                        <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-nombre">Name</label> 
                        </div>
                        <div className="col-75">
                            <Controller
                                as={<input className="bootstrap-input" type='text'  />}                                      
                                control={control}
                                id="i-nombre"
                                key="k-nombre"
                                defaultValue=""                                
                                name='nombre'  
                                style={(errorNombre) ? { borderColor: '#f90000'} : {}}
                            />                              
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-spanish">Spanish</label> 
                        </div>
                        <div className="col-75">
                            <Controller
                                as={<input className="bootstrap-input" type='text'  />}                                      
                                control={control}
                                id="i-spanish"
                                key="k-spanish"
                                defaultValue=""                                
                                name='spanish'     
                                style={(errorSpanish) ? { borderColor: '#f90000'} : {}}
                            />                              
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-english">English</label> 
                        </div>
                        <div className="col-75">
                            <Controller
                                as={<input className="bootstrap-input" type='text'  />}                                      
                                control={control}
                                id="i-english"
                                key="k-english"
                                defaultValue=""                                
                                name='english'
                                style={(errorEnglish) ? { borderColor: '#f90000'} : {}}                                
                            />                              
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-portuguese">Portuguese</label> 
                        </div>
                        <div className="col-75">
                            <Controller
                                as={<input className="bootstrap-input" type='text'  />}                                      
                                control={control}
                                id="i-portuguese"
                                key="k-portuguese"
                                defaultValue=""                                
                                name='portuguese'
                                style={(errorPortuguese) ? { borderColor: '#f90000'} : {}}                                
                            />                              
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div style={{width: '60%', marginRight:'auto', marginLeft: 'auto'}}>
                            <button className="boton-from">Save</button>
                        </div>                   
                    </div>
                </div>
            </form>
            
        </>
    )
}

export default CreacionTraduccion
