import React, { useState } from 'react'
import styled from 'styled-components';
import Modal from 'react-modal'
import "../css/modalservidores.css";
import * as RiIcons from 'react-icons/ri';
import swal from 'sweetalert';
import Loading from '../Loading';
import TablaSeleccionadosIntegracion from './TablaSeleccionadosIntegracion';
import TablaModalIntegracion from './TablaModalIntegracion';
import { crearRelacionesIntegracion } from '../functions/FuncAplicacion'

Modal.setAppElement('#root');

const ButtonRelacion = styled.button`
    width: 50px;    
    height: 40px;
    background-color: #EA7600;
    color: white;
    font-size: 20px;
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;   
    float:right; 
`;

const ButtonConfirmar = styled.button`
    margin-left: auto;
    margin-right: auto;
    background-color: #EA7600; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #D35400;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    color: white;
    float:right;
`;

const ButtonCancelar = styled.button`
    margin-left: auto;
    margin-right: auto;
    background-color: #EA7600; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #D35400;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    color: white;
    
`;

const ModalRelacionIntegracion = ({id, maestro, actualizarIntegraciones, nombreApp, token}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [registros, setRegistros] = useState(null);
    const [seleccionados, setSeleccionados] = useState(null);

    function filtrarSeleccionados(rows)
    { 
        if(rows === null) return null;
        if(rows.length === 0) return null;

        return rows.filter((row) => {
            var r = true;

            if(seleccionados !== null)
            {
                seleccionados.map((s) => {
                    if(s.integracion === row.nombre) r = false;
                });

                return r;
            }
            else return r;
        })
    }

    function seleccionaIntegracionModal(selec){
        setSeleccionados(selec);
        setRegistros(filtrarSeleccionados(maestro));
    }

    async function relacionar(integraciones)
    {
        var array = [];
        integraciones.forEach(i => {
            array.push({"nombre": i.integracion})
        });

        await crearRelacionesIntegracion(id, array, token)
        .then((response) => {
            if(response.cod === 1){

                actualizarIntegraciones();

                swal ("Successful operation" ,
                    "Servers have been successfully linked" ,
                    "success")
                .then(() => {
                    //setRegistros(null);
                    setSeleccionados(null);
                    setModalOpen(false);
                });
            }
            else {
                swal ("Operation error",
                    response.mensaje,
                    "warning");
            }
        });
    }

    function confirmar()
    {
        if(seleccionados === null)
        {
            swal ("A integration has not been selected" ,
                    "At least one integration must be selected to continue" ,
                    "info");
        }
        else if (seleccionados.length === 0)
        {
            swal ("A integration has not been selected" ,
                    "At least one integration must be selected to continue" ,
                    "info");
        }
        else {
            swal({
                title: "Confirmation of changes",
                text: "Do you want to make the changes?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if (afirmacion) {
                    relacionar(seleccionados);
                }                
            });
        }
    }

    function abrirModal()
    {
        setRegistros(filtrarSeleccionados(maestro));
        setSeleccionados(null);
        setModalOpen(true);
    }

    function cancelar(){
        swal({
            title: "Cancellation confirmation",
            text: "Do you want to cancel the changes?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((afirmacion) => {
            if (afirmacion) {
                setRegistros(null);
                setSeleccionados(null);
                setModalOpen(false);
            }                   
        });
    }

    React.useEffect(() => {  
        setRegistros(filtrarSeleccionados(maestro));
    }, [seleccionados])
 
    return (
        <>
            
            <ButtonRelacion type="button" onClick={abrirModal}>
                <RiIcons.RiAddCircleFill />
            </ButtonRelacion>            
            <Modal 
                isOpen={modalOpen}
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.75)'
                    },
                    content: {
                        width: '50%',
                        height: '70%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        position: 'absolute',                        
                        border: '1px solid #ccc',
                        background: '#fff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '20px'        
                    }
                  }}
            >
                <br></br>
                <div style={{width: '100%', height: '90%'}}>
                    <div className="bodymodal">
                        <div className="divizq-modalint">
                            <div className="subtitulo-modal"> 
                                <div className="subtitulo-modalser">
                                    <label className="textotitulo-modal">{"INTEGRATIONS"}</label>
                                </div>
                            </div>
                            {
                                (!loading) ? (
                                    <TablaModalIntegracion rows={registros} seleccionados={seleccionados} seleccionar={seleccionaIntegracionModal}/>
                                ) : (<Loading />)
                            }
                        </div>
                        <div className="divder-modalint">
                            <div className="subtitulo-modal">                                
                                <div className="subtitulo-modalser">
                                    <label className="textotitulo-modal">{"SELECTED"}</label>
                                </div>
                            </div>
                            <TablaSeleccionadosIntegracion rows={seleccionados} seleccionar={seleccionaIntegracionModal}/>
                        </div> 
                    </div> 
                    <br></br>
                    <br></br>
                    <div className="footermodal-int">                        
                        <div className="divbotonmodal-int">
                            <ButtonCancelar onClick={cancelar}>Return</ButtonCancelar>
                            <ButtonConfirmar onClick={confirmar}>Confirm</ButtonConfirmar>
                        </div>
                    </div>
                </div>
                            
            </Modal>
        </>
    )
}

export default ModalRelacionIntegracion