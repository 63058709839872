import React, {Fragment, useState} from 'react'
import { useForm } from "react-hook-form";
import { obtenerServidorForm, obtenerValoresForm, actualizarForm, obtenerAplicaciones, obtenerAplicacionesSccm, obtenerCuentas, obtenerCertificados } from '../functions/FuncServidor'
import { registrarBitacoraServidor } from "../functions/FuncBitacora"
import * as Opciones from './OpcionesServidor'
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../css/formularioServidor.css";
import swal from 'sweetalert';
import TablaAplicaciones from './TablaAplicaciones';
import es from "date-fns/locale/es";
import TablaAplicacionesSccm from './TablaAplicacionesSccm';
import Loading from '../Loading';

import TablaCuentas from './TablaCuentas';
import TablaCertificados from './TablaCertificados';
import { validarEmail } from '../functions/MetodosComunes';


import { Formulario } from '../Commons/Formulario';
import { validarPrivilegioModificacion } from '../Commons/Functions'


registerLocale("es", es);


const FormularioServidor = ({id, token}) => {

    let privEscribir = sessionStorage.getItem('ser_escribir');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');    
    let idiomaUser = sessionStorage.getItem('idioma');

    const [privModificar, setPrivModificar] = React.useState(false);

    const [registro, setRegistro] = useState(null);
    const [valores, setValores] = useState(null);
    const [aplicaciones, setAplicaciones] = useState(null);
    const [cuentas, setCuentas] = useState(null);
    const [certificados, setCertificados] = useState(null);
    const [aplicacionesSccm, setAplicacioneSccm] = useState(null);
    const [errorServidor, setErrorServidor] = useState(false);
    const [errorLocalidad, setErrorLocalidad] = useState(false);
    const [errorPlataforma, setErrorPlataforma] = useState(false);
    const [errorEstado, setErrorEstado] = useState(false);
    const [errorCriticidad, setErrorCriticidad] = useState(false);
    const { handleSubmit, control, register, setValue, errors} = useForm({mode: 'onBlur'});

    const [cambioFileserver, setCambioFileserver] = useState(null);
    const [cambioPrintserver, setCambioPrintserver] = useState(null);
    const [cambioWebserver, setCambioWebserver] = useState(null);
    const [cambioApplications, setCambioApplications] = useState(null);
    const [cambioDbserver, setCambioDbserver] = useState(null);
    const [cambioServerbackup, setCambioServerbackup] = useState(null);
    const [cambioDatabackup, setCambioDatabackup] = useState(null);
    const [cambioSccmpatching, setCambioSccmpatching] = useState(null);
    const [errorInformara, setErrorInformara] = useState(false);
    //const [cambioDrp, setCambioDrp] = useState(null);

    //const eCambioDrp = (e) => {
    //    setCambioDrp(e.target.checked)
    //}

    const eCambioFileserver = (e) => {
        setCambioFileserver(e.target.checked)
    }

    const eCambioPrintserver = (e) => {
        setCambioPrintserver(e.target.checked)
    }

    const eCambioWebserver = (e) => {
        setCambioWebserver(e.target.checked)
    }

    const eCambioApplications = (e) => {
        setCambioApplications(e.target.checked)
    }

    const eCambioDbserver = (e) => {
        setCambioDbserver(e.target.checked)
    }

    const eCambioServerbackuper = (e) => {
        setCambioServerbackup(e.target.checked)
    }

    const eCambioDatabackup = (e) => {
        setCambioDatabackup(e.target.checked)
    }

    const eCambioSccmpatching = (e) => {
        setCambioSccmpatching(e.target.checked)
    }

    async function registrarBitacora(datosActuales, dataAnterior)
    {
        let nombreusuario = sessionStorage.getItem('nombre');

        datosActuales.fileserver = cambioFileserver;
        datosActuales.printserver = cambioPrintserver;
        datosActuales.webserver = cambioWebserver;
        datosActuales.aplicaciones = cambioApplications;
        datosActuales.servidorbd = cambioDbserver;
        datosActuales.respaldoserver = cambioServerbackup;
        datosActuales.respaldodata = cambioDatabackup;
        datosActuales.pachadosccm = cambioSccmpatching;
        //datosActuales.drp = cambioDrp;

        registrarBitacoraServidor(datosActuales, dataAnterior, id, nombreusuario, token)
        .then((response) => {
            console.log(response)
        })
    }

    async function actualizar(datosform, dataAnterior)
    {
        let nombreusuario = sessionStorage.getItem('nombre');
        datosform.modificadopor = nombreusuario;
        datosform.fechaupdate = new Date();

        if(datosform.servidor !== null) datosform.servidor = datosform.servidor.toUpperCase();
        if(datosform.descripcion !== null) datosform.descripcion = datosform.descripcion.toUpperCase();
        if(datosform.comentario !== null) datosform.comentario = datosform.comentario.toUpperCase();
        if(datosform.ipservidor !== null) datosform.ipservidor = datosform.ipservidor.toUpperCase();
        if(datosform.ippublica !== null) datosform.ippublica = datosform.ippublica.toUpperCase();
        if(datosform.vlan !== null) datosform.vlan = datosform.vlan.toUpperCase();
        if(datosform.numeroserie !== null) datosform.numeroserie = datosform.numeroserie.toUpperCase();
        //if(datosform.umbral !== null) datosform.umbral = datosform.umbral.toUpperCase();

        await actualizarForm(datosform, dataAnterior, id, token).then((response) => {
            if(response.cod === 1)
            {
                registrarBitacora(datosform, dataAnterior);

                swal("Successful update", "", "success")
                .then( async() => {                                                    
                    await actualizarDatosForm();
                });
            }
            else swal ("Update error", response.mensaje, "error");
        });
    }

    function mapResponseToRegistro(response){
        setRegistro({
            servidor: response.servidor, 
            pais: response.pais, 
            localidad: (response.localidad !== null && response.localidad !== "") ? {"label": response.localidad, "value": response.localidad} : null,
            ambiente: (response.ambiente !== null && response.ambiente !== "") ? {"label": response.ambiente, "value": response.ambiente} : null,
            estado: (response.estado !== null && response.estado !== "") ? {"label": response.estado, "value": response.estado} : null,
            administrador: response.administrador,
            responsable: (response.responsable !== null && response.responsable !== "") ? {"label": response.responsable, "value": response.responsable} : null,
            negocio: (response.negocio !== null && response.negocio !== "") ? {"label": response.negocio, "value": response.negocio} : null,
            plataforma: (response.plataforma !== null && response.plataforma !== "") ? {"label": response.plataforma, "value": response.plataforma} : null,
            soservidor: (response.soservidor !== null && response.soservidor !== "") ? {"label": response.soservidor, "value": response.soservidor} : null,
            tiposervidor: (response.tiposervidor !== null && response.tiposervidor !== "") ? {"label": response.tiposervidor, "value": response.tiposervidor} : null,
            ipservidor: response.ipservidor,
            ippublica: response.ippublica,

            fileserver: (response.fileserver !== null) ? (response.fileserver === "YES") ? true : false : false,    
            printserver: (response.printserver !== null) ? (response.printserver === "YES") ? true : false : false,
            webserver: (response.webserver !== null) ? (response.webserver === "YES") ? true : false : false,
            aplicaciones: (response.aplicaciones !== null) ? (response.aplicaciones === "YES") ? true : false : false,
            servidorbd: (response.servidorbd !== null) ? (response.servidorbd === "YES") ? true : false : false,
            respaldodata: (response.respaldodata !== null) ? (response.respaldodata === "YES") ? true : false : false,
            respaldoserver: (response.respaldoserver !== null) ? (response.respaldoserver === "YES") ? true : false : false,             
            pachadosccm: (response.pachadosccm !== null) ? (response.pachadosccm === "YES") ? true : false : false,
            //drp: (response.drp !== null) ? (response.drp === "YES") ? true : false : false,
            
            versionservidor: (response.versionservidor !== null && response.versionservidor !== "") ? {"label": response.versionservidor, "value": response.versionservidor} : null,
            criticidad: (response.criticidad !== null && response.criticidad !== "") ? {"label": response.criticidad, "value": response.criticidad} : null,
            antivirus: (response.antivirus !== null && response.antivirus !== "") ? {"label": response.antivirus, "value": response.antivirus} : null,
            tipobd: (response.tipobd !== null && response.tipobd !== "") ? {"label": response.tipobd, "value": response.tipobd} : null,
            tiporeinicio: (response.tiporeinicio !== null && response.tiporeinicio !== "") ? {"label": response.tiporeinicio, "value": response.tiporeinicio} : null,
            marca: (response.marca !== null && response.marca !== "") ? {"label": response.marca, "value": response.marca} : null,
            modelo: response.modelo,
            vlan: response.vlan,
            numeroserie: response.numeroserie,
            descripcion: response.descripcion,
            comentario: response.comentario,
            modificadopor: response.modificadopor,

            ubicacionrespaldodata: response.ubicacionrespaldodata,
            ubicacionrespaldoserver: response.ubicacionrespaldoserver,
            memoriaramsccm: response.memoriaramsccm,
            espaciolibresccm: response.espaciolibresccm,
            espaciototalsccm: response.espaciototalsccm,
            espaciodiscossccm: response.espaciodiscossccm,
            ultimoreinicio: response.ultimoreinicio,

            administradortexto: response.administradortexto,
            propiedadde: (response.propiedadde !== null && response.propiedadde !== "") ? {"label": response.propiedadde, "value": response.propiedadde} : null,

            fechahabilitacion: (response.fechahabilitacion !== null) ? new Date(response.fechahabilitacion) : null,
            fechabaja: (response.fechabaja !== null) ? new Date(response.fechabaja) : null,
            fechaexpiracion: (response.fechaexpiracion !== null) ? new Date(response.fechaexpiracion) : null,
            fechaupdate: (response.fechaupdate !== null) ? new Date(response.fechaupdate) : null,
            fechasccm: (response.fechasccm !== null) ? new Date(response.fechasccm) : null,
            //fechadrp: (response.fechadrp !== null) ? new Date(response.fechadrp) : null,
            informara: response.informara,
            //umbral: response.umbral,
        })
    }

    async function actualizarDatosForm(){
        await obtenerServidorForm(id, token).then((response) => {
            mapResponseToRegistro(response);
        });
    }

    const onSubmit = (data) => {
        swal({
            title: "Update confirmation",
            text: "Do you want to update the registry? ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (afirmacion) => {
            if (afirmacion) {

                let errors = {
                    "error_type_01": "Mandatory data must be entered",
                    "error_type_02": "Invalid email",
                }

                let error = "";

                if(data.servidor === null || data.servidor === "") 
                {   
                    setErrorServidor(true);
                    error = errors["error_type_01"];
                }
                else setErrorServidor(false);

                if(data.localidad === null || data.localidad === "") 
                {   
                    setErrorLocalidad(true);
                    error = errors["error_type_01"];
                }
                else setErrorLocalidad(false);

                if(data.plataforma === null || data.plataforma === "") 
                {   
                    setErrorPlataforma(true);
                    error = errors["error_type_01"];
                }
                else setErrorPlataforma(false);

                if(data.estado === null || data.estado === "") 
                {   
                    setErrorEstado(true);
                    error = errors["error_type_01"];
                }
                else setErrorEstado(false);

                if(data.criticidad === null || data.criticidad === "") 
                {   
                    setErrorCriticidad(true);
                    error = errors["error_type_01"];
                }
                else setErrorCriticidad(false);

                if (data.fechaexpiracion != null){
                    if (data.informara === null || data.informara === "") {
                        setErrorInformara(true);
                        error = errors["error_type_01"];
                    } else setErrorInformara(false);
                } else setErrorInformara(false);

                if(data.informara.length > 0)
                {
                    if(!validarEmail(data.informara))
                    {
                        setErrorInformara(true);
                        error = errors["error_type_02"];
                    }
                    else setErrorInformara(false);
                }

                if(error.length > 0)
                {
                    swal ("Form data" ,
                    error ,
                    "warning")
                }else await actualizar(data, registro);                                
            } else {
              setRegistro(data);
            }
        });
    };

    async function obtenerDatosBD()
    {
        await obtenerValoresForm("SERVER", idiomaUser, token).then((response) => {

            if(response.cod === 1){
                const val = {
                    "localidades": response.localidades,
                    "propiedades": response.propiedades,
                    "ambientes": response.ambientes,
                    "criticidades": response.criticidades,
                    "estados": response.estados,
                    "marcas": response.marcas,
                    "tiposbd": response.tiposbd,
                    "versionesws": response.versionesws,
                    "antivirus": response.antivirus,
                    "negocios": response.negocios,
                    "soservidor": response.soservidor,
                    "tiposservidor": response.tiposservidor,
                    "plataformas": response.plataformas,
                    "responsables": response.responsables,
                    "filtrolocalidad": response.filtrolocalidad,
                    "traducciones": response.traducciones,
                    "propietariosred": response.propietariosred,
                    "tiposreinicio": response.tiposreinicio
                };
    
                const listas = ["localidades", "propiedades", "ambientes", "criticidades", "estados", "marcas", "tiposbd", "versionesws", "antivirus", "negocios", "soservidor", "tiposservidor", "plataformas", "responsables", "propietariosred", "tiposreinicio"];
    
                let array = [];
                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });
    
                if(privEscribir === "1"){
                    array = [];
                    val["localidades"].map((u) => {                    
                        if(u.value === localidadUser) array.push({"label": u.label, "value": u.value});
                    })
                    val["localidades"] = array;
                }
    
                if(privEscribir === "2")
                {
                    array = [];
                    val["filtrolocalidad"].map((u) => {                    
                        if(u.pais === paisUser) array.push({"label": u.localidad, "value": u.localidad});
                    })
                    val["localidades"] = array;
                }
    
                setValores(val);
            }            
        });

        await obtenerAplicaciones(id, token).then((response) => {
            if(response.cod === 1)
            {
                setAplicaciones(response.aplicaciones);
            }
        });

        await obtenerCuentas(id, token).then((response) => {
            if(response.cod === 1)
            {
                setCuentas(response.cuentas);
            }
        });

        await obtenerCertificados(id, token).then((response) => {
            if(response.cod === 1)
            {
                setCertificados(response.certificados);
            }
        });

        await obtenerAplicacionesSccm(id, token).then((response) => {
            if(response.cod === 1)
            {                
                setAplicacioneSccm(response.aplicaciones);
            }
        });

        await obtenerServidorForm(id, token).then((response) => {

            validarPrivilegioModificacion(privEscribir, paisUser, localidadUser, "",  response, setPrivModificar);


            mapResponseToRegistro(response);
        });
    }

    let fields = [
        { group: 1, name: 'estado', label: 'State', disabled: false, hasErrorValidation: errorEstado, type: 'select', valuesNameField: 'estados' },
        { group: 1, name: 'criticidad', label: 'Criticality', disabled: false, hasErrorValidation: errorCriticidad, type: 'select', valuesNameField: 'criticidades' },
        { group: 1, name: 'plataforma', label: 'Network', disabled: false, hasErrorValidation: errorPlataforma, type: 'select', valuesNameField: 'plataformas' },  
        { group: 1, name: 'servidor', label: 'Server', disabled: false, hasErrorValidation: errorServidor, type: 'text' },  
        { group: 1, name: 'descripcion', label: 'Description', disabled: false, hasErrorValidation: null, type: 'textarea' },
        { group: 1, name: 'localidad', label: 'Location', disabled: false, hasErrorValidation: errorLocalidad, type: 'select', valuesNameField: 'localidades' },
        { group: 1, name: 'pais', label: 'Country', disabled: true, hasErrorValidation: null, type: 'text' },
        { group: 1, name: 'propiedadde', label: 'Property of', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'propiedades' },
        { group: 1, name: 'negocio', label: 'Business', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'negocios' },
        { group: 1, name: 'ambiente', label: 'Environment', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'ambientes' },
        { group: 1, name: 'responsable', label: 'Responsable', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'responsables' },
        { group: 1, name: 'administradortexto', label: 'Administrator', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 1, name: 'ipservidor', label: 'Server IP', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 1, name: 'ippublica', label: 'Public IP', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 1, name: 'tiposervidor', label: 'Server Type', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposservidor' },  
        { group: 1, name: 'soservidor', label: 'Operating System', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'soservidor' },
        { group: 1, name: 'antivirus', label: 'Antivirus', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'antivirus' },
        { group: 1, name: 'fileserver', label: 'File Server', disabled: false, hasErrorValidation: null, type: 'checkbox', setFunction: eCambioFileserver },
        { group: 1, name: 'printserver', label: 'Print Server', disabled: false, hasErrorValidation: null, type: 'checkbox', setFunction: eCambioPrintserver },
        { group: 1, name: 'webserver', label: 'Web Server', disabled: false, hasErrorValidation: null, type: 'checkbox', setFunction: eCambioWebserver },
        { group: 1, name: 'versionservidor', label: 'Version WebServer', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'versionesws' },
        { group: 1, name: 'aplicaciones', label: 'Applications', disabled: false, hasErrorValidation: null, type: 'checkbox', setFunction: eCambioApplications },
        { group: 1, name: 'servidorbd', label: 'DB Server', disabled: false, hasErrorValidation: null, type: 'checkbox', setFunction: eCambioDbserver },
        { group: 1, name: 'tipobd', label: 'Type DB', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposbd' },

        { group: 2, name: 'respaldoserver', label: 'Server Backup', disabled: false, hasErrorValidation: null, type: 'checkbox', setFunction: eCambioServerbackuper },
        { group: 2, name: 'ubicacionrespaldoserver', label: 'Ubication', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 2, name: 'respaldodata', label: 'Data Backup', disabled: false, hasErrorValidation: null, type: 'checkbox', setFunction: eCambioDatabackup },
        { group: 2, name: 'ubicacionrespaldodata', label: 'Ubication', disabled: false, hasErrorValidation: null, type: 'text' },

        { group: 3, name: 'tiporeinicio', label: 'Type Reset Threshold', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposreinicio' },
        { group: 3, name: 'ultimoreinicio', label: 'Last Restart Date (YYYY/MM/DD)', disabled: true, hasErrorValidation: null, type: 'text' },
        
        { group: 4, name: 'pachadosccm', label: 'SCCM Patching', disabled: false, hasErrorValidation: null, type: 'checkbox', setFunction: eCambioSccmpatching },
        { group: 4, name: 'espaciolibresccm', label: 'Free Space (GB)', disabled: true, hasErrorValidation: null, type: 'text' },
        { group: 4, name: 'espaciototalsccm', label: 'Total Space (GB)', disabled: true, hasErrorValidation: null, type: 'text' },
        { group: 4, name: 'espaciodiscossccm', label: 'Disk Spaces (GB)', disabled: true, hasErrorValidation: null, type: 'textarea' },
        { group: 4, name: 'memoriaramsccm', label: 'Memory Ram (GB)', disabled: true, hasErrorValidation: null, type: 'text' },
        { group: 4, name: 'marca', label: 'Brand', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'marcas' },
        { group: 4, name: 'vlan', label: 'VLAN', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 4, name: 'numeroserie', label: 'Serial Number', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 4, name: 'fechahabilitacion', label: 'Enable Date (YYYY/MM/DD)', disabled: false, hasErrorValidation: null, type: 'datepicker' },
        { group: 4, name: 'fechabaja', label: 'Down Date (YYYY/MM/DD)', disabled: false, hasErrorValidation: null, type: 'datepicker' },
        { group: 4, name: 'fechaexpiracion', label: 'Warranty Expiration Date (YYYY/MM/DD)', disabled: false, hasErrorValidation: null, type: 'datepicker' },
        { group: 4, name: 'informara', label: 'Inform To', disabled: false, hasErrorValidation: errorInformara, type: 'text' },
        { group: 4, name: 'comentario', label: 'Observation', disabled: false, hasErrorValidation: null, type: 'textarea' },
        { group: 4, name: 'fechaupdate', label: 'Modification date (YYYY/MM/DD)', disabled: true, hasErrorValidation: null, type: 'datepicker' },
        { group: 4, name: 'modificadopor', label: 'Modified by', disabled: true, hasErrorValidation: null, type: 'text' },
        { group: 4, name: 'fechasccm', label: 'SCCM Date (YYYY/MM/DD)', disabled: true, hasErrorValidation: null, type: 'datepicker' },

    ];

    React.useEffect(() => {
        if (registro !== null) {

            Opciones.campos.forEach(c => {
                setValue(c, registro[c], {shouldValidate: true,shouldDirty: true})
            });
            
        }
        else obtenerDatosBD();
    }, [registro])

    if(registro === null) return (
        <>
            <Loading />
        </>
    )
    else
    return (
        <Fragment>
            <div className="divizq-serv">                       
            <div className="container">
                <Formulario
                        fields = { fields }
                        registro = { registro }
                        valores = { valores }
                        privModificar = { privModificar }
                        onSubmit = { onSubmit }
                        register = { register }
                        handleSubmit = { handleSubmit }
                        control = { control }
                    />    
            </div>     
            </div> 
            <div className="divder-serv">
                <div className="container">  
                    <div className='subtitulo-box'>
                        <div className='subtitulo'>
                            <label >Applications</label>
                        </div>                    
                    </div> 
                    <TablaAplicaciones rows={aplicaciones}/>
                    <br></br>                    
                </div>
                <br></br>
                <br></br>
                <div className="container">
                    <div className='subtitulo-box'>
                        <div className='subtitulo'>
                            <label >SCCM Applications</label>
                        </div>                    
                    </div>                
                    <TablaAplicacionesSccm rows={aplicacionesSccm} />
                </div>
                <br></br>
                <br></br>
                <div className="container">
                    <div className='subtitulo-box'>
                        <div className='subtitulo'>
                            <label >Accounts Control</label>
                        </div>                    
                    </div>
                    <TablaCuentas rows={cuentas} />
                </div>
                <br></br>
                <br></br>
                <div className="container">
                    <div className='subtitulo-box'>
                        <div className='subtitulo'>
                            <label >Certificates</label>
                        </div>                    
                    </div>                   
                    <TablaCertificados rows={certificados} />
                </div>
            </div>   
        </Fragment>   
    )
}

export default FormularioServidor
