import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';

import { TablaRegistros } from '../Commons/TablaRegistros';

import { ContainerBusqueda, ContainerTable } from '../Commons/Styles'

const headCells = [
    { id: 'nombre', numeric: false, disablePadding: true, label: 'Account', size: '40%' },
    { id: 'negocio', numeric: false, disablePadding: true, label: 'Business', size: '30%' },
    { id: 'responsable', numeric: false, disablePadding: true, label: 'Responsable', size: '30%' }
];

const TablaCuentas = ({rows}) => {

    const [filter, setFilter] = useState("");

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.nombre !== "") ? (row.nombre.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.negocio !== "") ? (row.negocio.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.responsable !== "") ? (row.responsable.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
          
        })
    }

    return (
        <>
          <ContainerBusqueda>                                    
              <TextField 
                  style={{backgroundColor: '#fff'}}
                  id="outlined-search" 
                  label="Search" 
                  size="small" 
                  type="search" 
                  variant="outlined" 
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
              />                            
          </ContainerBusqueda>
          <ContainerTable>
            <TablaRegistros
                headCells = { headCells }
                rows = { buscar(rows) }
                url = { '/cuentasservicio/' }
                idFieldName = { 'id_cuentaservicio' }
                initialSortByField = { 'nombre' }
                initialSortDirection = { 'desc' }
                />
          </ContainerTable>        
        </>
    )
}

export default TablaCuentas