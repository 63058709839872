import React from 'react'
import swal from 'sweetalert';
import { obtenerValores, tablaCertificado } from '../functions/FuncCertificado'
import FiltroBaseNuevo from '../FiltroBaseNuevo';
import  * as Metodos from '../functions/MetodosComunes'

import { ContainerFiltros, FilaContainerFiltros, Button } from '../Commons/Styles'

const FiltrosCertificado = ({aplicarFiltro, load, token}) => {

    let priv = sessionStorage.getItem('cert_leer');
    let paisUser = sessionStorage.getItem('pais');
    let idiomaUser = sessionStorage.getItem('idioma');

    const [valores, setValores] = React.useState(null);

    const [estados, setEstados] = React.useState(null);
    const [estado, setEstado] = React.useState(null);

    const [paises, setPaises] = React.useState(null);
    const [pais, setPais] = React.useState(null);

    const [proveedores, setProveedores] = React.useState(null);
    const [proveedor, setProveedor] = React.useState(null);

    const [entidades, setEntidades] = React.useState(null);
    const [entidad, setEntidad] = React.useState(null);

    const [expiracion, setExpiracion] = React.useState("");
    const [nombre, setNombre] = React.useState("");

    const cambioEstado = (valor) => {
        setEstado(valor);
    };

    const cambioPais = (valor) => {
        setPais(valor);
    };

    const cambioProveedor = (valor) => {
        setProveedor(valor);
    };

    const cambioEntidad = (valor) => {
        setEntidad(valor);
    };

    async function obtenerValoresBd()
    {
        await obtenerValores(priv, paisUser, "CERTIFICATE", idiomaUser, token)
        .then((response) => {
            if(response.cod === 1)
            {
                const val = {          
                    "paises": response.paises,                    
                    "proveedores": response.proveedores,
                    "estados": response.estados,
                    "entidades": response.entidades
                };

                setEstados(Metodos.opcionesFiltros(val.estados));
                setProveedores(Metodos.opcionesFiltros(val.proveedores));
                setEntidades(Metodos.opcionesFiltros(val.entidades));

                if(priv === "2") setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));
                else setPaises(Metodos.opcionesFiltros(val.paises));

                setValores(val);
            }
            else {
                console.log(response);
            }
        });
    }

    async function obtenerRegistros()
    {
        load(true);
        aplicarFiltro(null);

        var paisApi = (pais !== null && pais !== undefined && pais !== "") ? pais.value : null;       
        var estadoApi = (estado !== null && estado !== undefined && estado !== "") ? estado.value : null;
        var proveedorApi = (proveedor !== null && proveedor !== undefined && proveedor !== "") ? proveedor.value : null;
        var entidadApi = (entidad !== null && entidad !== undefined && entidad !== "") ? entidad.value : null;
        var expiracionApi = (expiracion !== null && expiracion !== undefined && expiracion !== "") ? expiracion : null;
        var nombreApi = (nombre !== null && nombre !== undefined && nombre !== "") ? nombre : null;

        await tablaCertificado(nombreApi, paisApi, proveedorApi, expiracionApi, entidadApi, estadoApi, priv, paisUser, token)
        .then((response) => {
            if(response.cod === 1){
                aplicarFiltro(response.certificados);
            }
            else {
                aplicarFiltro(null);
                swal ("Search Error" , response.mensaje , "warning");
                console.log(response);
            }

            load(false);
        });
    }

    React.useEffect(() =>{
        if(valores === null) obtenerValoresBd();
    }, []);

    if(valores === null) return (<></>)
    else return (
        <>
            <FilaContainerFiltros>
                <ContainerFiltros>
                    <FiltroBaseNuevo
                        opciones={estados}
                        valor={estado}
                        nombre={"State"}
                        namecampo="estado"
                        cambio={cambioEstado}
                        proporcion={"25%"}
                        error={false}
                    />
                    <FiltroBaseNuevo
                        opciones={paises}
                        valor={pais}
                        nombre={"Country"}
                        namecampo="pais"
                        cambio={cambioPais}
                        proporcion={"25%"}
                        error={false}
                    />
                    <FiltroBaseNuevo
                        opciones={proveedores}
                        valor={proveedor}
                        nombre={"Provider"}
                        namecampo="provedor"
                        cambio={cambioProveedor}
                        proporcion={"25%"}
                        error={false}
                    />

                    <FiltroBaseNuevo
                        opciones={entidades}
                        valor={entidad}
                        nombre={"Entity"}
                        namecampo="entidad"
                        cambio={cambioEntidad}
                        proporcion={"25%"}
                        error={false}
                    />

                   
                </ContainerFiltros>
                <ContainerFiltros>
                    <div style={{width: "60%", marginLeft: '10px', marginRight: 'auto',}}>
                        <input className="bootstrap-input-filtro" value={nombre} type='text' onChange={(e) => setNombre(e.target.value)} placeholder={"Name"}  />
                    </div>

                    <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>
                        <input className="bootstrap-input-filtro" value={expiracion} type='text' onChange={(e) => setExpiracion(e.target.value)} placeholder={"Expiration Year"}  />
                    </div>

                    <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>
                        <Button onClick={obtenerRegistros}>Display</Button>
                    </div>
                </ContainerFiltros>
            </FilaContainerFiltros>
            <br></br>            
            <hr style={{width: '90%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
        </>
    )
}

export default FiltrosCertificado