import React, { useState } from 'react'

import swal from 'sweetalert';

import FiltroBaseNuevo from '../FiltroBaseNuevo';
import { obtenerFiltros, obtenerTabla } from '../functions/FuncSalaServidor'
import  * as Metodos from '../functions/MetodosComunes'

import { ContainerFiltros, FilaContainerFiltros, Button } from '../Commons/Styles'

const FiltrosSalaServidor = ({aplicarFiltro, load, token}) => {

    const [valores, setValores] = useState(null);
    const [proporcion, setProporcion] = useState("20%");
    
    const [localidades, setLocalidades] = useState(null);
    const [localidad, setLocalidad] = useState(null);

    const [paises, setPaises] = useState(null);
    const [pais, setPais] = useState(null);

    const [plantas, setPlantas] = useState(null);
    const [planta, setPlanta] = useState(null);

    const [sala, setSala] = useState(null);

    let priv = sessionStorage.getItem('sala_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');

    async function obtenerDatosBD()
    {
        await obtenerFiltros(token)
        .then((response) => {
            
            if(response.cod === 1)
            {
                const val = {          
                    "paises": response.paises,
                    "filtrolocalidad": response.filtrolocalidad,
                    "localidades": response.localidades,
                    "plantas": response.plantas,
                    "filtroplantas": response.filtroplantas,
                }; 

                if(priv === "1"){
                    setLocalidades(Metodos.opcionesFiltrosValor(val.localidades, localidadUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));
                    setPlantas(Metodos.opcionesFiltrosPlantaLocalidad(val.filtroplantas, localidadUser));
                }

                if(priv === "2"){
                    setLocalidades(Metodos.opcionesFiltrosLocalidad(val.filtroplantas, paisUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));
                    setPlantas(Metodos.opcionesFiltrosPlantaPais(val.filtroplantas, paisUser));
                }

                if(priv === "3"){
                    setLocalidades(val.localidades = Metodos.opcionesFiltros(val.localidades));
                    setPaises(Metodos.opcionesFiltros(val.paises));
                    setPlantas(Metodos.opcionesFiltros(val.plantas));
                }
               
                setValores(val);
            }
        });
    }

    const cambioLocalidad = (value) => {
        if(value === null || value === undefined || value === "") setLocalidad(null);
        else setLocalidad(value);
    };

    const cambioPlanta = (value) => {
        if(value === null || value === undefined || value === "") setPlanta(null);
        else setPlanta(value);
    };

    const cambioSala = (event) => {
        setSala(event.target.value);
    };

    const cambioPais = (valor) => {

        if(valor !== null && valor !== undefined && valor !== ""){
            setPais(valor);
            filtrarLocalidad(valor.value);
        } 
        else{      
            setPais(null);      
            var array = [];
            if(valores.filtrolocalidad.length > 0){

                valores.filtrolocalidad.map((p) => {
                    array.push({label: p.localidad, value: p.localidad});
                });  
                setLocalidades(array);  
            }
        }
    };

    function filtrarLocalidad(filtro)
    {
        var array = [];
        if(valores.filtrolocalidad.length > 0){
            valores.filtrolocalidad.map((p) => {
                if(p.pais === filtro) array.push({label: p.localidad, value: p.localidad});
            });  

            setLocalidad(null);
            setLocalidades(array); 
        }
    }

    async function obtenerRegistros()
    {
        var paisApi = (pais !== null && pais !== undefined && pais !== "") ? pais.value : null;
        var localidadApi = (localidad !== null && localidad !== undefined && localidad !== "") ? localidad.value : null;
        var plantaApi = (planta !== null && planta !== undefined && planta !== "") ? planta.value : null;
        var salaApi = (sala !== null && sala !== undefined && sala !== "") ? sala : null;

        (localidadApi !== null) ? sessionStorage.setItem('filtrosala_localidad', localidadApi) : sessionStorage.setItem('filtrosala_localidad', null);
        (paisApi !== null) ? sessionStorage.setItem('filtrosala_pais', paisApi) : sessionStorage.setItem('filtrosala_pais', null);
        (plantaApi !== null) ? sessionStorage.setItem('filtrosala_planta', plantaApi) : sessionStorage.setItem('filtrosala_planta', null);
        (salaApi !== null) ? sessionStorage.setItem('filtrosala_sala', salaApi) : sessionStorage.setItem('filtrosala_sala', null);

        await obtenerTabla( paisApi, localidadApi, plantaApi, salaApi, priv, paisUser, localidadUser, token)
        .then((response) => { 

            if (response.cod === 1)
            {
                aplicarFiltro(response.salas);
            }
            else {
                aplicarFiltro(null);
                swal ("Search Error" , response.mensaje , "warning");
            }
                
            load(false);
        });
    }

    function filtrosAnteriores()
    {
        let filtro_localidad = sessionStorage.getItem('filtrosala_localidad');
        console.log(filtro_localidad)
        if(filtro_localidad !== "null") setLocalidad({label: filtro_localidad, value: filtro_localidad})

        let filtro_pais = sessionStorage.getItem('filtrosala_pais');
        console.log(filtro_pais)
        if(filtro_pais !== "null") setPais({label: filtro_pais, value: filtro_pais})

        let filtro_planta = sessionStorage.getItem('filtrosala_planta');
        console.log(filtro_planta)
        if(filtro_planta !== "null") setPlanta({label: filtro_planta, value: filtro_planta})

        let filtro_sala = sessionStorage.getItem('filtrosala_sala');
        console.log(filtro_sala)
        if(filtro_sala !== "null") setSala(filtro_sala)
    }

    React.useEffect(() =>{

        if(valores === null) obtenerDatosBD();
            
        filtrosAnteriores(); 
    }, []);

    if(valores === null) return (<></>)
    else return (
        <FilaContainerFiltros>
            <ContainerFiltros>
               
                <FiltroBaseNuevo 
                    opciones={paises}
                    valor={pais}
                    nombre="Country"
                    namecampo="pais"
                    cambio={cambioPais}
                    proporcion={proporcion}     
                    error={false}                 
                />

                <FiltroBaseNuevo 
                    opciones={localidades}
                    valor={localidad}
                    nombre="Location"
                    namecampo="localidad"
                    cambio={cambioLocalidad}
                    proporcion={proporcion} 
                    error={false}                     
                />

                <FiltroBaseNuevo 
                    opciones={plantas}
                    valor={planta}
                    nombre="Plant"
                    namecampo="planta"
                    cambio={cambioPlanta}
                    proporcion={proporcion}   
                    error={false}                    
                />

                <div style={{width: proporcion, marginLeft: '10px', marginRight: 'auto'}}>
                    <input className="bootstrap-input-filtro" value={sala} type='text' onChange={cambioSala} placeholder={"Server Room"}  />
                </div>    

                <div style={{width: "25%", marginLeft: '10px', marginRight: 'auto'}}>
                    <Button onClick={obtenerRegistros}>Display</Button>
                </div>
                 
               
            </ContainerFiltros>
            <br></br>
            <br></br>
            <hr style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
        </FilaContainerFiltros>
    )
}

export default FiltrosSalaServidor
