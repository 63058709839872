import React from 'react'
import "../css/modalimpresion.css";
import Logo from '../img/logocafe.jpg';
import Loading from '../Loading';


const FormularioImpresion = React.forwardRef((prop, ref) => {

    var [datos, setDatos] = React.useState(null);
    var fecha = formatDate(new Date());

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [day, month, year].join('/');
    }

    React.useEffect(() => {
        setDatos(prop.registro);
    })
    
    if(datos === null) return (<Loading />)
    else return (
    <div className='formularioimpresion' ref={ref}>
        <div className='formularioimpresion-header'>
            <div><img src={Logo} alt="Arauco" width="15%"/></div>
            <hr className='separadorimpresion'></hr>
            <h2 style={{margin: '0px'}}>DESKTOP ENTRY FORM</h2>
            <h3 style={{margin: '0px'}}>USERNAME: {datos.nombreusuario}</h3>
            <h3 style={{margin: '0px'}}>ID: {datos.idregistro} - DATE PRINTING: {fecha}</h3>
        </div>
        <br></br>
        <div className='formularioimpresion-body'>
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>Location</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.localidad}</label>
                </div>
            </div>
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>Plant/Office Name</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.planta}</label>
                </div>
            </div>
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>Username</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.nombreusuario}</label>
                </div>
            </div>
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>User ID</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.idusuario}</label>
                </div>
            </div>
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>Email User</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.correo}</label>
                </div>
            </div>
            <hr className='separadorimpresion'></hr>
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>Deliver date</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.fechaentrega}</label>
                </div>
            </div>
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>Desktop Name</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.nombreequipo}</label>
                </div>
            </div>
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>Desktop Type</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.tipoequipo}</label>
                </div>
            </div>            
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>Desktop Brand</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.marca}</label>
                </div>
            </div>
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>Desktop Model</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.modelo}</label>
                </div>
            </div>
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>Processor Type</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.tipoprocesador}</label>
                </div>
            </div>
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>HDD</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.discoduro}</label>
                </div>
            </div>
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>RAM</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.memoriaram}</label>
                </div>
            </div>
            <div className="row">
                <div className='lineaform-label'>
                    <label className='labelimpresion'>N° CPU Series</label>
                </div>
                <div className='lineaform-value'>
                    <label className='valueimpresion'>{datos.numeroserie}</label>
                </div>
            </div>
        </div>
        <hr className='separadorimpresion'></hr>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className='formularioimpresion-footer'>
            <div className='formularioimpresion-footer-1'>
                <hr className='firma'></hr>
                <div className='labelfirma-footer-1'>
                    <label className='labelfirma'>USER SIGNATURE</label>
                </div>
            </div>
            <div className='formularioimpresion-footer-2'>
                <hr className='firma'></hr>
                <div className='labelfirma-footer-2'>
                    <label className='labelfirma'>SIGNATURE TECHNICAL SUPPORT</label>
                </div>
            </div>
            <div className='formularioimpresion-footer-3'>
                <hr className='firma'></hr>
                <div className='labelfirma-footer-3'>
                    <label className='labelfirma'>LOCAL LEADING FIRM</label>
                </div>
            </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        
    </div>
  )
})

export default FormularioImpresion