import React, { useState } from 'react'
import Select from 'react-select'

const FiltroBaseUser = ({opciones, valor, nombre, namecampo, cambio, proporcion, error, limpieza}) => {

    const styleNormal = {
        control: base => ({
          ...base,          
          height: "50px",          
          verticalAlign: 'middle',
          backgroundColor: "#FFFFFF",
          '&:hover': {
            border:"1px solid #2684FF !important",
            boxShadow: "0px 0px 0px 1px #56b4ef !important"
          },
          '&:focus': {
            border:"1px solid #2684FF !important",
            boxShadow: "0px 0px 0px 1px #2684FF !important"
          }
        })
        
    };

    const style = {
      control: base => ({
        ...base,
        height: "50px", 
        verticalAlign: 'middle',
        borderColor: "#f90000",
        '&:hover': {
          border:"1px solid #56b4ef !important",
          boxShadow: "0px 0px 0px 1px #56b4ef !important"
        },
        '&:focus': {
          border:"1px solid #56b4ef !important",
          boxShadow: "0px 0px 0px 1px #56b4ef !important"
        }
      })
    };

    return (
        <>
            <div style={{width: proporcion, marginLeft: '10px', marginRight: 'auto',}}>
            <Select 
                options={opciones}
                placeholder={nombre}
                onChange={(value) => {                            
                    cambio(value, namecampo); 
                }}  
                isClearable={limpieza}
                styles={(error) ? style : styleNormal}   
                id={"i-"+namecampo}
                key={"k-"+namecampo}                                   
                name={namecampo} 
                value={valor}                        
            />
            </div>          
        </>
    )
}

export default FiltroBaseUser