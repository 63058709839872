import { Fragment, useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { obtenerDispositivoOTForm, obtenerValoresForm, actualizarForm } from '../functions/FuncDispositivoOT'
import { registrarBitacoraDispositivoOT } from "../functions/FuncBitacora"
import * as Opciones from './OpcionesDispositivoOT'

import "react-datepicker/dist/react-datepicker.css";
import "../css/formularioServidor.css";
import swal from 'sweetalert';

import Loading from '../Loading';

import { Formulario } from '../Commons/Formulario';

import { validarPrivilegioModificacion } from '../Commons/Functions'

const FormularioDispositivoOT = ({id, token}) => {

    let privEscribir = sessionStorage.getItem('ser_ot_escribir');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');  
    let plantaUser = sessionStorage.getItem('planta');  
    let idiomaUser = sessionStorage.getItem('idioma');

    const [privModificar, setPrivModificar] = useState(false);

    const [registro, setRegistro] = useState(null);
    const [valores, setValores] = useState(null);
    const [errorDispositivoOT, setErrorDispositivoOT] = useState(false);
    const [errorTipoActivo, setErrorTipoActivo] = useState(false);
    const [errorNumeroSerie, setErrorNumeroSerie] = useState(false);
    const [errorPlantaIndustrial, setErrorPlantaIndustrial] = useState(false);
    const [errorAreaResponsable, setErrorAreaResponsable] = useState(false);
    const [errorContactoResponsable, setErrorContactoResponsable] = useState(false);
    const [errorEstado, setErrorEstado] = useState(false);
    const [errorCriticidad, setErrorCriticidad] = useState(false);
    const { handleSubmit, control, register, setValue } = useForm({mode: 'onBlur'});

    async function registrarBitacora(datosActuales, dataAnterior)
    {
        let nombreusuario = sessionStorage.getItem('nombre');

        registrarBitacoraDispositivoOT(datosActuales, dataAnterior, id, nombreusuario, token)
        .then((response) => {
            console.log(response)
        })
    }

    async function actualizar(datosform, dataAnterior)
    {
        let nombreusuario = sessionStorage.getItem('nombre');
        datosform.modificadopor = nombreusuario;
        datosform.fechaactualizacion = new Date();

        if(datosform.dispositivoot !== null) datosform.dispositivoot = datosform.dispositivoot.toUpperCase();
        if(datosform.descripcion !== null) datosform.descripcion = datosform.descripcion.toUpperCase();
        if(datosform.numeroserie !== null) datosform.numeroserie = datosform.numeroserie.toUpperCase();

        await actualizarForm(datosform, dataAnterior, id, token).then((response) => {
            if(response.cod === 1)
            {
                registrarBitacora(datosform, dataAnterior);

                swal("Successful update", "", "success")
                .then( async() => {                                                    
                    await actualizarDatosForm();
                });
            }
            else swal ("Update error", response.mensaje, "error");
        });
    }

    function mapResponseToRegistro(response){
        setRegistro({

            tipoactivo: (response.tipoactivo !== null && response.tipoactivo !== "") ? {"label": response.tipoactivo, "value": response.tipoactivo} : null,
            dispositivoot: response.dispositivoot,
            numeroserie: response.numeroserie,
            descripcion: response.descripcion,
            ambiente: (response.ambiente !== null && response.ambiente !== "") ? {"label": response.ambiente, "value": response.ambiente} : null,
            criticidad: (response.criticidad !== null && response.criticidad !== "") ? {"label": response.criticidad, "value": response.criticidad} : null,
    
            ubicacion: response.ubicacion,
            zona: response.zona,
            piso: response.piso,
            gabinete: response.gabinete,
            propiedadde: (response.propiedadde !== null && response.propiedadde !== "") ? {"label": response.propiedadde, "value": response.propiedadde} : null,
            plantaindustrial: (response.plantaindustrial !== null && response.plantaindustrial !== "") ? {"label": response.plantaindustrial, "value": response.plantaindustrial} : null,
            negocio: response.negocio,
            localidad: response.localidad,
            pais: response.pais,
    
    
            proveedor: response.proveedor,
            modelo: response.modelo,
            sodispositivoot: (response.sodispositivoot !== null && response.sodispositivoot !== "") ? {"label": response.sodispositivoot, "value": response.sodispositivoot} : null,
            firmware: response.firmware,
            tiposervidor: (response.tiposervidor !== null && response.tiposervidor !== "") ? {"label": response.tiposervidor, "value": response.tiposervidor} : null,
            antivirus:  (response.antivirus !== null && response.antivirus !== "") ? {"label": response.antivirus, "value": response.antivirus} : null,
            arearesponsable: (response.arearesponsable !== null && response.arearesponsable !== "") ? {"label": response.arearesponsable, "value": response.arearesponsable} : null,
            contactoresponsable: response.contactoresponsable,
            estado: (response.estado !== null && response.estado !== "") ? {"label": response.estado, "value": response.estado} : null,
            tipoparchado: (response.tipoparchado !== null && response.tipoparchado !== "") ? {"label": response.tipoparchado, "value": response.tipoparchado} : null,
            observacion: response.observacion,
            tipobd: (response.tipobd !== null && response.tipobd !== "") ? {"label": response.tipobd, "value": response.tipobd} : null,
    
            tiporespaldo: response.tiporespaldo,
            tiporespaldofrecuencia: (response.tiporespaldofrecuencia !== null && response.tiporespaldofrecuencia !== "") ? {"label": response.tiporespaldofrecuencia, "value": response.tiporespaldofrecuencia} : null,
    
            tipopuerto: (response.tipopuerto !== null && response.tipopuerto !== "") ? {"label": response.tipopuerto, "value": response.tipopuerto} : null,
            nombrered: response.nombrered,
            idvlan: response.idvlan,
            direccionip: response.direccionip,
            direccionred: response.direccionred,
            direccionmac: response.direccionmac,
            tipoaccesoremoto: (response.tipoaccesoremoto !== null && response.tipoaccesoremoto !== "") ? {"label": response.tipoaccesoremoto, "value": response.tipoaccesoremoto} : null,
            accesoremotoaplicacion: (response.accesoremotoaplicacion !== null && response.accesoremotoaplicacion !== "") ? {"label": response.accesoremotoaplicacion, "value": response.accesoremotoaplicacion} : null,
            protocolo: (response.protocolo !== null && response.protocolo !== "") ? {"label": response.protocolo, "value": response.protocolo} : null,
    
            modificadopor: response.modificadopor,
            fechaactualizacion: (response.fechaactualizacion !== null) ? new Date(response.fechaactualizacion) : null,
        })
    }

    async function actualizarDatosForm(){
        await obtenerDispositivoOTForm(id, token).then((response) => {
            mapResponseToRegistro(response);
        });
    }

    const onSubmit = (data) => {
        swal({
            title: "Update confirmation",
            text: "Do you want to update the registry? ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (afirmacion) => {
            if (afirmacion) {

                let errors = {
                    "error_type_01": "Mandatory data must be entered",
                }

                let error = "";

                if(data.dispositivoot === null || data.dispositivoot === "") 
                {   
                    setErrorDispositivoOT(true);
                    error = errors["error_type_01"];
                }
                else setErrorDispositivoOT(false);

                if(data.tipoactivo === null || data.tipoactivo === "") 
                {   
                    setErrorTipoActivo(true);
                    error = errors["error_type_01"];
                }
                else setErrorTipoActivo(false);

                if(data.numeroserie === null || data.numeroserie === "") 
                {   
                    setErrorNumeroSerie(true);
                    error = errors["error_type_01"];
                }
                else setErrorNumeroSerie(false);

                if(data.plantaindustrial === null || data.plantaindustrial === "") 
                {   
                    setErrorPlantaIndustrial(true);
                    error = errors["error_type_01"];
                }
                else setErrorPlantaIndustrial(false);

                if(data.arearesponsable === null || data.arearesponsable === "") 
                {   
                    setErrorAreaResponsable(true);
                    error = errors["error_type_01"];
                }
                else setErrorAreaResponsable(false);

                if(data.contactoresponsable === null || data.contactoresponsable === "") 
                {   
                    setErrorContactoResponsable(true);
                    error = errors["error_type_01"];
                }
                else setErrorContactoResponsable(false);

                if(data.estado === null || data.estado === "") 
                {   
                    setErrorEstado(true);
                    error = errors["error_type_01"];
                }
                else setErrorEstado(false);

                if(data.criticidad === null || data.criticidad === "") 
                {   
                    setErrorCriticidad(true);
                    error = errors["error_type_01"];
                }
                else setErrorCriticidad(false);

                if(error.length > 0)
                {
                    swal ("Form data" ,
                    error ,
                    "warning")
                }else await actualizar(data, registro);                                
            } else {
              setRegistro(data);
            }
        });
    };

    async function obtenerDatosBD()
    {
        await obtenerValoresForm("DEVICE OT", idiomaUser, token).then((response) => {
            
            if(response.cod === 1){
                const val = Opciones.getVal(response);

                let array = [];
                Opciones.listas.forEach(lista => {
                    array = [];
                    val[lista].forEach((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                if(privEscribir === "1"  || (privEscribir === "4" && plantaUser === ""))
                {
                    array = [];
                    val["filtroplanta"].map((u) => {                    
                        if(u.localidad === localidadUser) array.push({"label": u.planta, "value": u.planta});
                    })
                    val["plantasindustriales"] = array;
                }
                else if(privEscribir === "2")
                {
                    array = [];
                    val["filtroplanta"].map((u) => {                    
                        if(u.pais === paisUser) array.push({"label": u.planta, "value": u.planta});
                    })
                    val["plantasindustriales"] = array;
                }
                else if(privEscribir === "4"){
                    array = [];
                    val["plantasindustriales"].map((u) => {                    
                        if(u.value === plantaUser) array.push({"label": u.label, "value": u.value});
                    })
                    val["plantasindustriales"] = array;
                }
    
                setValores(val);
            }            
        });


        await obtenerDispositivoOTForm(id, token).then((response) => {

            validarPrivilegioModificacion(privEscribir, paisUser, localidadUser, plantaUser, response, setPrivModificar);
            
            mapResponseToRegistro(response);
        });
    }

    let fields = [
        { group: 1, name: 'tipoactivo', label: 'Asset Type', disabled: true, hasErrorValidation: errorTipoActivo, type: 'select', valuesNameField: 'tiposactivo' }, 
        { group: 1, name: 'numeroserie', label: 'Serial Number / Tag', disabled: true, hasErrorValidation: errorNumeroSerie, type: 'text' }, 
        { group: 1, name: 'dispositivoot', label: 'Device Name', disabled: false, hasErrorValidation: errorDispositivoOT, type: 'text' },  
        { group: 1, name: 'descripcion', label: 'Description', disabled: false, hasErrorValidation: null, type: 'textarea' },
        { group: 1, name: 'ambiente', label: 'Environment', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'ambientes' },
        { group: 1, name: 'criticidad', label: 'Criticality', disabled: false, hasErrorValidation: errorCriticidad, type: 'select', valuesNameField: 'criticidades' },

        { group: 2, name: 'ubicacion', label: 'Ubication', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 2, name: 'zona', label: 'Zone', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 2, name: 'piso', label: 'Floor', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 2, name: 'gabinete', label: 'Cabinet or Rack', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 2, name: 'propiedadde', label: 'Property of', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'propiedades' },
        { group: 2, name: 'plantaindustrial', label: 'Industrial Plant', disabled: false, hasErrorValidation: errorPlantaIndustrial, type: 'select' , valuesNameField: 'plantasindustriales' },
        { group: 2, name: 'negocio', label: 'Business', disabled: true, hasErrorValidation: null, type: 'text' },
        { group: 2, name: 'localidad', label: 'Location', disabled: true, hasErrorValidation: null, type: 'text' },
        { group: 2, name: 'pais', label: 'Country', disabled: true, hasErrorValidation: null, type: 'text' },

        { group: 3, name: 'proveedor', label: 'Vendor', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 3, name: 'modelo', label: 'Model', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 3, name: 'sodispositivoot', label: 'Operating System', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'sistemasoperativos' },
        { group: 3, name: 'firmware', label: 'Firmware Version', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 3, name: 'tiposervidor', label: 'Server Type', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposservidor' },  
        { group: 3, name: 'antivirus', label: 'Antivirus', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'antivirus' },
        { group: 3, name: 'arearesponsable', label: 'Responsable Area', disabled: false, hasErrorValidation: errorAreaResponsable, type: 'select', valuesNameField: 'arearesponsables' },
        { group: 3, name: 'contactoresponsable', label: 'Responsable Contact', disabled: false, hasErrorValidation: errorContactoResponsable, type: 'text' },
        { group: 3, name: 'estado', label: 'State', disabled: false, hasErrorValidation: errorEstado, type: 'select', valuesNameField: 'estados' },
        { group: 3, name: 'tipoparchado', label: 'Patching Type', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposparchado' },
        { group: 3, name: 'observacion', label: 'Observation', disabled: false, hasErrorValidation: null, type: 'textarea' },  
        { group: 3, name: 'tipobd', label: 'Type DB', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposbd' },

        { group: 4, name: 'tiporespaldo', label: 'Backup Type', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 4, name: 'tiporespaldofrecuencia', label: 'Backup Frequency Type', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposrespaldofrecuencia' },

        { group: 5, name: 'tipopuerto', label: 'Port Type', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tipospuerto' },
        { group: 5, name: 'nombrered', label: 'Network Name', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 5, name: 'idvlan', label: 'ID VLAN', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 5, name: 'direccionip', label: 'IP Address', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 5, name: 'direccionred', label: 'Network Address', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 5, name: 'direccionmac', label: 'MAC Address', disabled: false, hasErrorValidation: null, type: 'text' }, 
        { group: 5, name: 'tipoaccesoremoto', label: 'Remote Access Type', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposaccesoremoto'  }, 
        { group: 5, name: 'accesoremotoaplicacion', label: 'Remote Access Application', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'accesoremotoaplicaciones'  }, 
        { group: 5, name: 'protocolo', label: 'Protocol', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'protocolos' }, 

        { group: 6, name: 'fechaactualizacion', label: 'Modification date (YYYY/MM/DD)', disabled: true, hasErrorValidation: null, type: 'datepicker' }, 
        { group: 6, name: 'modificadopor', label: 'Modified By', disabled: true, hasErrorValidation: null, type: 'text' }, 

    ];

    useEffect(() => {
        if (registro !== null) {

            Opciones.campos.forEach(c => {
                setValue(c, registro[c], {shouldValidate: true,shouldDirty: true})
            });

          }
          else obtenerDatosBD();
      }, [registro])

    if(registro === null) return (
        <>
            <Loading />
        </>
    )
    else
    return (
        <>                 
            <div className="container">
                <Formulario
                    fields = { fields }
                    registro = { registro }
                    valores = { valores }
                    privModificar = { privModificar }
                    onSubmit = { onSubmit }
                    register = { register }
                    handleSubmit = { handleSubmit }
                    control = { control }
                /> 
            </div>      
        </>   
    )
}

export default FormularioDispositivoOT
