import swal from 'sweetalert';

export async function validarPrivilegioModificacion(privEscribir, paisUser, localidadUser, plantaUser, registro, setPrivModificar) {
    console.log(privEscribir)
    console.log(registro)
    //DENIED
    if (privEscribir === "0" || privEscribir === null || privEscribir === undefined) setPrivModificar(false);
    //GLOBAL
    else if (privEscribir === "3") setPrivModificar(true);
    //COUNTRY
    else if (privEscribir === "2" && paisUser === registro.pais) setPrivModificar(true);
    //LOCATION
    else if (privEscribir === "1" && localidadUser === registro.localidad) setPrivModificar(true);
    //PLANT
    else if (privEscribir === "4" && (plantaUser === registro.plantaindustrial || (plantaUser === "" && localidadUser === registro.localidad))) setPrivModificar(true);

    else setPrivModificar(false);
}


export function rolInvalido(history)
{
    swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
        history.push("/")
    });
}