import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import Logo from './img/arauco.svg';
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


const Nav = styled.div`
  background: #696158;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  margin-right: 2rem;
  color: #fff;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;


//Menu desplegado
const SidebarNav = styled.nav`
  background: #696158; 
  border: 2px solid #6f6f6f;
  width: 270px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 100ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const ContainerTitulo = styled.div`
  width: 75%;
`;

const Button = styled.button`
  background-color: #EA7600;
  color: white;
  font-size: 30px;
  padding: 2px 8px;
  border-radius: 5px;  
  border-color: #EA7600;
  cursor: pointer;

  :hover {
    background: #ffa549;
}
`;

const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      width: '40%',
      marginTop: '10px',
      marginBottom: '10px',
      border: '0px solid #bfbfbf',
      marginRight: '10px',
      backgroundColor: '#696158',
      boxShadow: 'none !important'
  },
  usuario:{
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'white',    
    textAlign: 'right'
  },
  pais:{
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    fontSize: '13px',
    color: 'white',
    textAlign: 'right'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-end'
  },    
  divboton: {
        display: 'flex',
        flexDirection: 'column',
        width: '10%'
  },    
  content: {
    flex: '1 0 auto',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    paddingBottom: '0px !important',
    paddingTop: '0px !important'
  },
  cover: {
      width: 100,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  }
}));

const SESSION_IDEL_MINUTES = 1;

const Sidebar = ({instance, despliegue}) => {

    const [sidebar, setSidebar] = useState(despliegue);
    const showSidebar = () => setSidebar(!sidebar);

    const history = useHistory();
    const classes = useStyles();

    function cierreSesion(){
        sessionStorage.clear();
        instance.logout();
    }

    

    /*
    const handleOnIdle = (event) => {
      // SHOW YOUR MODAL HERE AND LAGOUT
      //console.log('user is idle', event)
      //console.log('last active', getLastActiveTime())

      swal('Time Out Session', 'prueba?', 'warning')
      .then(() => {
        history.push("/menu")
      })
    }

    const {getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
        onIdle: handleOnIdle,
        debounce: 500,
    })
    */
    React.useEffect(() => {
      let email = sessionStorage.getItem('email');
      if(email === null) history.push("/");
    });

    return ( 
        <>
                <IconContext.Provider value={{ color: '#fff' }}>        
                    <Nav>
                        <ContainerTitulo>                            
                            <div style={{ marginLeft: '10px' }}><img src={Logo} alt="Arauco" /> </div>
                        </ContainerTitulo>

                        <Card className={classes.root}>                        
                            <div className={classes.details}>
                                <CardContent className={classes.content}>
                                    <Typography className={classes.usuario} component="h6" variant="h6">{sessionStorage.getItem('nombre')}</Typography>
                                    <Typography className={classes.pais} variant="subtitle2" color="textSecondary">{sessionStorage.getItem('localidad') + ", " +  sessionStorage.getItem('pais')}</Typography>
                                </CardContent>
                            </div>
                        </Card>
                        
                        <Button onClick={cierreSesion}><IoIcons.IoMdLogOut /></Button>

                        <NavIcon to='#'>
                            <FaIcons.FaBars onClick={showSidebar}/>
                        </NavIcon>                                          
                    </Nav>
                    <SidebarNav sidebar={sidebar}>
                        <SidebarWrap>
                            <NavIcon to='#'>
                                <FaIcons.FaBars onClick={showSidebar}/>
                            </NavIcon>
                            {SidebarData.map((item, index) => {
                                
                              let priv = sessionStorage.getItem(item.privilegio); 
                              let maestro = item.maestro;

                              if(item.global) return <SubMenu item={item} key={index} />;                              

                              if(maestro) {

                                var priv2 = "";
                                priv2 = sessionStorage.getItem("admin_personal");
                                console.log(priv2)
                                if(priv2 !== "0" && priv2 !== null) return <SubMenu item={item} key={index} />;

                                priv2 = sessionStorage.getItem("admin_otrasfuentes");
                                console.log(priv2)
                                if(priv2 !== "0" && priv2 !== null) return <SubMenu item={item} key={index} />; 
                                
                                priv2 = sessionStorage.getItem("mas_escribir");
                                console.log(priv2)
                                if(priv2 !== "0" && priv2 !== null) return <SubMenu item={item} key={index} />;  

                              }

                              if(priv !== null)
                              {
                                if(priv !== "0") return <SubMenu item={item} key={index} />;
                              }
                            })}
                        </SidebarWrap>                
                    </SidebarNav>
                </IconContext.Provider>
            
        </>
     );
}
 
export default Sidebar;