
//Filtros
export const familiasFiltro = [    
    {label: "ENLACE", value: "ENLACE"},
    {label: "EQUIPO", value: "EQUIPO"}    
]

export const familiasFiltroTabla = [    
    "ENLACE",
    "EQUIPO"  
]


//Valores
export const familiasValores= [    
    {label: "ENLACE", value: "ENLACE"},
    {label: "EQUIPO", value: "EQUIPO"}      
]

export const estadosValores= [ 
    {label: "OPERATIVO", value: "OPERATIVO"},
    {label: "DE BAJA", value: "DE BAJA"} 
]

export const servicioValores= [
    {label: "PRIMARY", value: "PRIMARY"},
    {label: "SECONDARY", value: "SECONDARY"}    
]

export const tiposEncaleValores= [ 
    {label: "MPLS", value: "MPLS"},
    {label: "MPLS (SAT)", value: "MPLS (SAT)"},
    {label: "FO", value: "FO"},
    {label: "INTERNET", value: "INTERNET"}  
]

//Excel
export const camposExcel = [
    {nombre:"FAMILY", valor:"familia"},
    {nombre:"USABILITY", valor:"usabilidad"},
    {nombre:"EQUIPMENT TYPE", valor:"tipoequipo"},
    {nombre:"SERIAL NUMBER", valor:"numeroserie"},
    {nombre:"STATE", valor:"estado"},
    {nombre:"CONDITION", valor:"condicion"},
    {nombre:"PLANT", valor:"planta"},
    {nombre:"DESCRIPTION", valor:"descripcion"},
    {nombre:"IP", valor:"ipequipo"},

    {nombre:"HOSTNAME", valor:"hostname"},
    {nombre:"PURCHASE ORDER", valor:"ordencompra"},
    {nombre:"BRAND", valor:"marca"},
    {nombre:"MODEL", valor:"modelo"},
    {nombre:"DELIVERY DATE", valor:"fechaentrega"},

    {nombre:"SERVICE", valor:"servicio"},
    {nombre:"ADDRESS", valor:"direccion"},
    {nombre:"BANDWIDTH", valor:"anchobanda"},
    {nombre:"LINK TYPE", valor:"tipoenlace"},
    {nombre:"PROVIDER", valor:"proveedor"},
    
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"LOCATION", valor:"localidad"},
    {nombre:"BUSINESS", valor:"negocio"},
]

export const camposExcelCarga = [   
    {nombre:"FILA", valor:"fila"},
    {nombre:"DETALLE", valor:"detalle"}    
]

export const camposExcelBitacora = [
    {nombre:"ID COMUNICATION", valor:"id_registro"},
    {nombre:"ID LOG", valor:"id_bitacora"},
    {nombre:"IOD MODIFICATION", valor:"id_modificacion"},
    {nombre:"DATE", valor:"fecha"},
    {nombre:"RESPONSABLE", valor:"responsable"},
    {nombre:"FIELD", valor:"campo"},
    {nombre:"AFTER VALUE", valor:"valor_anterior"},
    {nombre:"BEFORE VALUE", valor:"valor_actual"}    
]

export const campos = [    
    "usabilidad",
    "tipoequipo",
    "numeroserie",
    "estado",
    "condicion",
    "planta",
    "empresa",
    "negocio",
    "localidad",
    "pais",

    "hostname",
    "ordencompra",
    "marca",
    "modelo",
    "descripcion",
    "fechaentrega",  
    
    "direccion",
    "servicio",
    "proveedor",
    "tipoenlace",
    "anchobanda",
    
    "ipequipo",
    "modificadopor", 
    "fechaupdate"    
]
    