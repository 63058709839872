import React, { useState } from 'react'
import styled from 'styled-components';
import Sidebar from '../../Sidebar'
import swal from 'sweetalert';
import '../../css/cargaFile.css'
import * as RiIcons from 'react-icons/ri';
import * as GrIcons from 'react-icons/gr';
import readXlsxFile from 'read-excel-file'
import MyExcel from '../../formatos/FormatoUsuario.xlsx';
import { uploadExcel } from '../../functions/FuncUsuario'
import TablaCargasExcelUsuario from './TablaCargasExcelUsuario';

const ContainerForm = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
`;

const ButtonExcel = styled.button`
    width: 50px;
    height: 45px;
    background-color: #098524;
    color: white;
    font-size: 20px;
    border-radius: 5px;  
    border-color: #098524;
    cursor: pointer;
    margin-left: 20px;    
`;

const CargaUsuarios = ({instance, t}) => {

    const [selectedFile, setSelectedFile] = useState( {file:null, fileField: "", file64: null});
    const ref = React.useRef();

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            
        };
    }

    function cargarArchivo()
    {
        let Base64 = '';

        if(selectedFile.file === null)
        {
            swal ("You have not selected a file" ,
                    "",
                    "warning")
        }
        else {
            swal({
                title: "Upload confirmation",
                text: "Do you want to upload the selected file?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((afirmacion) => {
                if(afirmacion)
                {
                    getBase64(selectedFile.file, (result) => {
                        Base64 = result;
                        subirArchivo(Base64);   
                   });
                }
            });
        }
    }

    async function subirArchivo(Base64)
    {
        await uploadExcel(Base64, selectedFile.file.name, sessionStorage.getItem('nombre'), t).then((response) => {
           
            if(response.cod === 1) 
            {
                swal("Upload request has been sent", "", "success")
                .then(() => {                               
                    setSelectedFile({file:null, fileField: ""});
                    ref.current.value = "";
                });
            }
            else {
                swal("Failed to load", response.mensaje, "error")
                .then(() => {                               
                    setSelectedFile({file:null, fileField: ""});
                    ref.current.value = "";
                });
            }
        }); 
    }

    const handleFileInput = (e) => {
        // handle validations
        const f = e.target.files[0];
        const ff = e.target.files;       

        setSelectedFile({file: f, fileField: ff, file64: null});
    }

    return (
        <>
            <Sidebar instance={instance} despliegue={false}/>

            <div className="containerfile">
                <div>
                    <input type="file" ref={ref} className="upload-box" onChange={handleFileInput}></input>
                    <a href={MyExcel} download="FormatoUsuario.xlsx">
                        <button className="boton-formato" href={MyExcel} download="FormatoUsuario.xlsx">
                            <RiIcons.RiFileExcel2Fill/>
                        </button>
                    </a>
                </div>
                <div>
                    <button className="boton-upload" onClick={cargarArchivo}>Update</button>
                </div>    
                <div className="containerTablaExcel">
                    <TablaCargasExcelUsuario token={t}/>
                </div>  
                <br></br>
                <br></br> 
            </div>
        </>
    )
}

export default CargaUsuarios
