import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import * as Opciones from './OpcionesComunicacion'
import * as Metodos from '../functions/MetodosComunes'
import { obtenerValores, obtenerEquipoComunicacion, actualizarEquipoComunicacion} from '../functions/FuncComunicacion'

import "../css/formularioAplicacion.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import Loading from '../Loading';
import Select from 'react-select'
import { registrarBitacoraComunicacion } from '../functions/FuncBitacora';

import { validarPrivilegioModificacion } from '../Commons/Functions'
import { style, styleNormal, styleDisable } from '../Commons/Styles'


const FormularioEquipoComunicacion = ({id, token}) => {

    let privEscribir = sessionStorage.getItem('com_escribir');
    let privLeer = sessionStorage.getItem('com_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');
    let idiomaUser = sessionStorage.getItem('idioma');
    const [privModificar, setPrivModificar] = React.useState(false);

    const [registro, setRegistro] = useState(null);    
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const [tipoform, setTipoform] = useState("");
    const [usabilidad, setUsabilidad] = useState("");
    const [tipoequipo, setTipoequipo] = useState("");

    const [empresa, setEmpresa] = useState("");
    const [planta, setPlanta] = useState(""); 

    const [marca, setMarca] = useState("");
    const [modelo, setModelo] = useState("");

    const [errorFamilia, setErrorFamilia] = useState(false);
    const [errorUsabilidad, setErrorUsabilidad] = useState(false);
    const [errorTipoEquipo, setErrorTipoEquipo] = useState(false);
    const [errorNumeroSerie, setErrorNumeroSerie] = useState(false);    
    const [errorEstado, setErrorEstado] = useState(false);    
    const [errorEmpresa, setErrorEmpresa] = useState(false);
    const [errorPlanta, setErrorPlanta] = useState(false);   
    const [errorCondicion, setErrorCondicion] = useState(false); 

    function mapResponseToRegistro(response){

        setRegistro({
            "usabilidad": (Metodos.validarTextoVacio(response.equipo.usabilidad)) ? {"label": response.equipo.usabilidad, "value": response.equipo.usabilidad} : null,
            "tipoequipo": (Metodos.validarTextoVacio(response.equipo.tipoequipo)) ? {"label": response.equipo.tipoequipo, "value": response.equipo.tipoequipo} : null,
            "numeroserie": (Metodos.validarTextoVacio(response.equipo.numeroserie)) ? response.equipo.numeroserie : null,
            "planta": (Metodos.validarTextoVacio(response.equipo.planta)) ? {"label": response.equipo.planta, "value": response.equipo.planta} : null,
            "empresa": (Metodos.validarTextoVacio(response.equipo.empresa)) ? {"label": response.equipo.empresa, "value": response.equipo.empresa} : null,
            "estado": (Metodos.validarTextoVacio(response.equipo.estado)) ? {"label": response.equipo.estado, "value": response.equipo.estado} : null,
            "condicion": (Metodos.validarTextoVacio(response.equipo.condicion)) ? {"label": response.equipo.condicion, "value": response.equipo.condicion}: null,
            "hostname": (Metodos.validarTextoVacio(response.equipo.hostname)) ? response.equipo.hostname : null,
            "ordencompra": (Metodos.validarTextoVacio(response.equipo.ordencompra)) ? response.equipo.ordencompra : null,
            "marca": (Metodos.validarTextoVacio(response.equipo.marca)) ? {"label": response.equipo.marca, "value": response.equipo.marca} : null,
            "modelo": (Metodos.validarTextoVacio(response.equipo.modelo)) ?{"label": response.equipo.modelo, "value": response.equipo.modelo} : null,
            "descripcion": (Metodos.validarTextoVacio(response.equipo.descripcion)) ? response.equipo.descripcion : null,
            "fechaentrega": (Metodos.validarTextoVacio(response.equipo.fechaentrega)) ? new Date(response.equipo.fechaentrega) : null,

            "direccion": (Metodos.validarTextoVacio(response.equipo.direccion)) ? response.equipo.direccion : null,
            "servicio": (Metodos.validarTextoVacio(response.equipo.servicio)) ? {"label": response.equipo.servicio, "value": response.equipo.servicio} : null,
            "proveedor": (Metodos.validarTextoVacio(response.equipo.proveedor)) ? {"label": response.equipo.proveedor, "value": response.equipo.proveedor} : null,
            "tipoenlace": (Metodos.validarTextoVacio(response.equipo.tipoenlace)) ? {"label": response.equipo.tipoenlace, "value": response.equipo.tipoenlace} : null,
            "anchobanda": (Metodos.validarTextoVacio(response.equipo.anchobanda)) ? response.equipo.anchobanda : null,
            "ipequipo": (Metodos.validarTextoVacio(response.equipo.ipequipo)) ? response.equipo.ipequipo : null,

            "negocio": response.equipo.negocio,
            "localidad": response.equipo.localidad,
            "pais": response.equipo.pais,
            "modificadopor": response.equipo.modificadopor,
            "fechaupdate": (response.equipo.fechaupdate !== null) ? new Date(response.equipo.fechaupdate) : null,
        });
    
    }
    async function obtenerDatosBD()
    {
        var familiaEquipo = "";
        var usabilidadEquipo = "";
        var tipoequipoEquipo = "";
        var empresaDispo = "";
        var marcaDispo = "";
        var paisEquipo = "";
        var localidadEquipo = "";

        await obtenerEquipoComunicacion(id, token)
        .then((response) => {

            console.log(response.equipo)

            validarPrivilegioModificacion(privEscribir, paisUser, localidadUser, "", response.equipo, setPrivModificar);


            familiaEquipo = response.equipo.familia;
            usabilidadEquipo = response.equipo.usabilidad;
            tipoequipoEquipo = response.equipo.tipoequipo;
            empresaDispo = response.equipo.empresa;
            marcaDispo = response.equipo.marca;
            paisEquipo = response.equipo.pais;
            localidadEquipo = response.equipo.localidad;

            setTipoform((Metodos.validarTextoVacio(response.equipo.familia)) ? {"label": response.equipo.familia, "value": response.equipo.familia} : null);
            setUsabilidad((Metodos.validarTextoVacio(response.equipo.usabilidad)) ? {"label": response.equipo.usabilidad, "value": response.equipo.usabilidad} : null);
            setTipoequipo((Metodos.validarTextoVacio(response.equipo.tipoequipo)) ? {"label": response.equipo.tipoequipo, "value": response.equipo.tipoequipo} : null);
            setEmpresa((Metodos.validarTextoVacio(response.equipo.empresa)) ? {"label": response.equipo.empresa, "value": response.equipo.empresa} : null);
            setPlanta((Metodos.validarTextoVacio(response.equipo.planta)) ? {"label": response.equipo.planta, "value": response.equipo.planta} : null);
            setMarca((Metodos.validarTextoVacio(response.equipo.marca)) ? {"label": response.equipo.marca, "value": response.equipo.marca} : null);
            setModelo((Metodos.validarTextoVacio(response.equipo.modelo)) ?{"label": response.equipo.modelo, "value": response.equipo.modelo} : null);            

            mapResponseToRegistro(response);
        });

        await obtenerValores(privEscribir, paisUser, localidadUser, "COMUNICATION", idiomaUser, token)
        .then((response) => {
            
            if(response.cod === 1)
            {
                const val = {
                    "usabilidades": response.usabilidades,
                    "filtrousabilidad": response.filtrousabilidad,
                    "tiposequipos": response.tiposequipos,
                    "filtrotipoequipo": response.filtrotipoequipo,
                    "marcas": response.marcas,
                    "modelos": response.modelos,
                    "filtromodelos": response.filtromodelos,
                    "negocios": response.negocios,
                    "empresas": response.empresas,
                    "todasempresas": response.todasempresas,
                    "plantas": response.plantas,
                    "filtroplantas": response.filtroplantas,
                    "localidades": response.localidades,
                    "proveedores": response.proveedores  ,
                    "familias": response.familias,
                    "estados": response.estados,
                    "condiciones": response.condiciones,
                    "servicios": response.servicios,
                    "tiposenlace": response.tiposenlace,
                    "traducciones": response.traducciones               
                };

                let array = [];
                const listas = ["usabilidades", "tiposequipos", "marcas", "modelos", "empresas", "negocios",
                 "plantas", "localidades", "proveedores", "familias", "estados", "tiposenlace", "servicios", "condiciones"];

                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                //PLANTAS
                array = [];
                val.filtroplantas.map((u) => {
                    if(u.empresa === empresaDispo) array.push({"label": u.planta, "value": u.planta});
                })
                val.plantas = array;

                //MODELOS
                array = [];
                val.filtromodelos.map((u) => {
                    if(u.marca === marcaDispo) array.push({"label": u.modelo, "value": u.modelo});
                })
                val.modelos = array;

                if(privEscribir === "1" && privLeer == "2")
                {
                    if(localidadEquipo !== localidadUser){
                        array = [];
                        val["todasempresas"].map((u) => {
                            array.push({"label": u, "value": u});
                        })
                        val["empresas"] = array;
                    } 
                }

                if(privEscribir === "2" && privLeer == "3")
                {
                    if(paisEquipo !== paisUser){
                        array = [];
                        val["todasempresas"].map((u) => {
                            array.push({"label": u, "value": u});
                        })
                        val["empresas"] = array;
                    } 
                }
                
                setValores(val);
            }
        });

        
    }

    React.useEffect(() => {
        if (registro !== null) {

            Opciones.campos.forEach(c => {
                setValue(c, registro[c], {shouldValidate: true,shouldDirty: true})
            });
            
        }
        else obtenerDatosBD();
    }, [registro]);

    const cambioFormulario = (value) => {  
        
        if(value !== "" && value !== null){
            
            filtrarPorFamilia(value.value);            
            setTipoform(value);            
        } else {
            setTipoform("");
            setUsabilidad("");
            setTipoequipo("");
        }
    }

    const cambioUsabilidad = (value) => { 
        
        if(value !== "" && value !== null){
            
            const valorUsabiliad = value.value;
            filtrarTipoEquipo(value.value)
            setUsabilidad({"label": valorUsabiliad, "value": valorUsabiliad});
        } else {
            setUsabilidad("");
            setTipoequipo("");
        }
    }

    const cambioTipoEquipo = (value) => { 
        
        if(value !== "" && value !== null){
            setTipoequipo({"label": value.value, "value": value.value});
        } else {
            setTipoequipo("");
        }
    }

    function filtrarPorFamilia(familia)
    {
        
        if(familia !== "")
        {
            const usabilidadesArray = valores.filtrousabilidad;

            var array = [];
            if(usabilidadesArray.length > 0){
                usabilidadesArray.map((p) => {
                    if(p.familia === familia) {
                        array.push({"label": p.usabilidad, "value": p.usabilidad});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.usabilidades = array;
            setUsabilidad("");
            setTipoequipo("");
            setValores(valoresNuevo);
        }
    }

    function filtrarTipoEquipo(usabilidad)
    {
        if(usabilidad !== "")
        {
            const filtrotipoArray = valores.filtrotipoequipo;
            

            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.usabilidad === usabilidad) {
                        array.push({"label": p.tipoequipo, "value": p.tipoequipo});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.tiposequipos = array; 
            setTipoequipo("");           
            setValores(valoresNuevo);
        }
    }

    const cambioEmpresa= (value) => { 
        if(value !== "" && value !== null){
            setEmpresa({"label": value.value, "value": value.value});            
            filtrarPlantas(value.value);            
        } else {
            setEmpresa(""); 
            setPlanta("");           
        }
    }

    const cambioPlanta= (value) => { 
        if(value !== "" && value !== null){
            setPlanta({"label": value.value, "value": value.value});
        } else {
            setPlanta("");
        }
    }

    function filtrarPlantas(empresa)
    {
        if(empresa !== "")
        {
            const filtrotipoArray = valores.filtroplantas;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.empresa === empresa) {
                        array.push({"label": p.planta, "value": p.planta});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.plantas = array; 
            setPlanta("");           
            setValores(valoresNuevo);
        }
    }

    const cambioMarca = (value) => { 
        if(value !== "" && value !== null){
            setMarca({"label": value.value, "value": value.value});            
            filtrarModelos(value.value);            
        } else {
            setMarca(null); 
            setModelo(null);           
        }
    }

    const cambioModelo= (value) => { 
        if(value !== "" && value !== null){
            setModelo({"label": value.value, "value": value.value});
        } else {
            setModelo(null);
        }
    }

    function filtrarModelos(marca)
    {
        if(marca !== null)
        {
            const filtrotipoArray = valores.filtromodelos;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.marca === marca) {
                        array.push({"label": p.modelo, "value": p.modelo});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.modelos = array; 
            setModelo(null);           
            setValores(valoresNuevo);
        }
    }

    function validarDatos(data)
    {
        var errores = false;
        if(tipoform === "") 
        {   
            setErrorFamilia(true);
            errores = true;
        }
        else setErrorFamilia(false);

        if(usabilidad === "") 
        {   
            setErrorUsabilidad(true);
            errores = true;
        }
        else setErrorUsabilidad(false);

        if(tipoequipo === "") 
        {   
            setErrorTipoEquipo(true);
            errores = true;
        }
        else setErrorTipoEquipo(false);

        if(data.numeroserie === null || data.numeroserie === "" || data.numeroserie === undefined) 
        {
            setErrorNumeroSerie(true);
            errores = true;
        }
        else setErrorNumeroSerie(false);

        if(data.empresa === null || data.empresa === "") 
        {
            setErrorEmpresa(true);
            errores = true;
        }
        else setErrorEmpresa(false);

        if(data.planta === null || data.planta === "") 
        {
            setErrorPlanta(true);
            errores = true;
        }
        else setErrorPlanta(false);

        if(data.estado === null || data.estado === "") 
        {
            setErrorEstado(true);
            errores = true;
        }
        else setErrorEstado(false);

        if(data.condicion === null || data.condicion === "" || data.condicion === undefined) 
        {
            setErrorCondicion(true);
            errores = true;
        }
        else setErrorCondicion(false);

        return errores;
    }

    const onSubmit = (data) => {

        if(validarDatos(data))
        {
            swal("Form data", "Mandatory data must be entered", "warning");
        }
        else{
            swal({
                title: "Update confirmation",
                text: "Do you want to update the registry? ",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if (afirmacion) {

                    data.familia = tipoform;
                    data.usabilidad = usabilidad;
                    data.tipoequipo = tipoequipo;
                    data.empresa = empresa;
                    data.marca = marca;
                    data.modelo = modelo                    
                    data.modificadopor = sessionStorage.getItem('nombre');

                    await actualizarEquipoComunicacion(data, registro, id, token)
                    .then((response) => {
                        if(response.cod === 1)
                        {
                            registrarBitacora(data, registro);  
                            obtenerregistro(); 
                            swal("Successful update", "", "success");
                        }
                        else if(response.cod === 0){
                            swal("Update error", response.mensaje, "error");
                        }
                        else {
                            swal("Update error", "An error occurred during the update. Please contact the administrator.", "error");
                        }
                    })
                }
                else setRegistro(data);      
            });
        }
    }

    async function registrarBitacora(datosActuales, dataAnterior) {
        let nombreusuario = sessionStorage.getItem("nombre");    
        
        registrarBitacoraComunicacion(
          datosActuales,
          dataAnterior,
          id,
          nombreusuario,
          token
        ).then((response) => {
          console.log(response);
        });
    }

    async function obtenerregistro()
    {
        await obtenerEquipoComunicacion(id, token)
        .then((response) => {

            mapResponseToRegistro(response);
        });
    }

    if(registro === null || valores === null) return (<Loading />)
    else
    return (
        <>
            <div className="divizq">
                <div className="container">
                    <form 
                        style={{width: '90%', marginRight:'auto', marginLeft: 'auto'}} 
                        autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-familia">Family<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.familia === "" || valores.traducciones.familia === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.familia}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.familias}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value);
                                            cambioFormulario(value);
                                        }}  
                                        isDisabled={true}
                                        isClearable={true}
                                        value={tipoform}
                                        defaultValue={tipoform}
                                        styles={styleDisable}                                        
                                    />} 
                                    control={control}
                                    id="i-familia"
                                    key="k-familia"                                    
                                    name='familia'
                                    defaultValue={registro ? registro.familia : ''}
                                />                              
                            </div>
                        </div>
                        {
                            (tipoform.value === "") ? (<br></br>)
                            :
                            (
                                <>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-usabilidad">Usability<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.usabilidad === "" || valores.traducciones.usabilidad === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.usabilidad}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.usabilidades}
                                                    placeholder=""
                                                    onChange={(value) => {
                                                        props.onChange(value);
                                                        cambioUsabilidad(value);
                                                    }}
                                                    isClearable={true} 
                                                    isDisabled={(privModificar) ? false : true}
                                                    value={usabilidad}
                                                    defaultValue={(usabilidad) ? usabilidad : ""}
                                                    styles={(errorUsabilidad) ? style : (privModificar) ? styleNormal : styleDisable}
                                                />}                                                                                                                               
                                                control={control}                                            
                                                id="i-usabilidad"
                                                key="k-usabilidad"                                            
                                                name='usabilidad'
                                                defaultValue={registro ? registro.usabilidad : ''}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-tipoequipo">Equipment Type<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.tipoequipo === "" || valores.traducciones.tipoequipo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.tipoequipo}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.tiposequipos}
                                                    onChange={(value) => {
                                                        props.onChange(value);
                                                        cambioTipoEquipo(value);
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    isDisabled={(privModificar && usabilidad !== "") ? false : true}
                                                    value={tipoequipo}                                                    
                                                    styles={(errorTipoEquipo) ? style : (privModificar) ? styleNormal : styleDisable}
                                                />}                                                                                                                                   
                                                control={control}                                            
                                                id="i-tipoequipo"
                                                key="k-tipoequipo"                                                
                                                name='tipoequipo'    
                                                defaultValue={registro ? registro.tipoequipo : ''}                                            
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-numeroserie">Serial / Service Number<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.numeroserie === "" || valores.traducciones.numeroserie === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.numeroserie}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                                control={control}
                                                id="i-numeroserie"
                                                key="k-numeroserie"
                                                defaultValue={registro ? registro.numeroserie : ''}
                                                name='numeroserie'
                                                style={(errorNumeroSerie) ? { borderColor: '#f90000'} : {}} 
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-estado">State<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.estado === "" || valores.traducciones.estado === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.estado}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.estados}
                                                    onChange={(value) => {
                                                        props.onChange(value);                                                        
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    isDisabled={(privModificar) ? false : true}
                                                    defaultValue={(registro) ? registro.estado : ""}
                                                    styles={(errorEstado) ? style : (privModificar) ? styleNormal : styleDisable}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-estado"
                                                key="k-estado"                                                
                                                name='estado'
                                                defaultValue={registro ? registro.estado : ''}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-condicion">Condition<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.condicion === "" || valores.traducciones.condicion === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.condicion}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.condiciones}
                                                    onChange={(value) => {
                                                        props.onChange(value);                                                        
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    isDisabled={(privModificar) ? false : true}
                                                    defaultValue={(registro) ? registro.condicion : ""}
                                                    styles={(errorCondicion) ? style : (privModificar) ? styleNormal : styleDisable}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-condicion"
                                                key="k-condicion"                                                
                                                name='condicion'
                                                defaultValue={registro ? registro.condicion : ''}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-empresa">Enterprise<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.empresa === "" || valores.traducciones.empresa === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.empresa}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.empresas}
                                                    onChange={(value) => {
                                                        props.onChange(value); 
                                                        cambioEmpresa(value);                                                         
                                                    }}
                                                    placeholder=""
                                                    value={empresa}
                                                    defaultValue={empresa}
                                                    isClearable={true} 
                                                    isDisabled={(privModificar) ? false : true}
                                                    styles={(errorEmpresa) ? style : (privModificar) ? styleNormal : styleDisable}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-empresa"
                                                key="k-empresa"                                                
                                                name='empresa'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-planta">Plant/Office<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.planta === "" || valores.traducciones.planta === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.planta}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.plantas}
                                                    onChange={(value) => {
                                                        props.onChange(value);
                                                        cambioPlanta(value);                                                        
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    isDisabled={(empresa === "" || empresa === null) ? true : (privModificar) ? false : true}
                                                    value={planta}
                                                    defaultValue={planta}
                                                    styles={(errorPlanta) ? style : (privModificar) ? styleNormal : styleDisable}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-planta"
                                                key="k-planta"                                                
                                                name='planta'
                                                defaultValue={registro ? registro.planta : ''}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-negocio">Business
                                            {
                                                (valores.traducciones.negocio === "" || valores.traducciones.negocio === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.negocio}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-negocio"
                                                key="k-negocio"
                                                defaultValue={registro ? registro.negocio : ''}
                                                name='negocio'                                                
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-localidad">Location
                                            {
                                                (valores.traducciones.localidad === "" || valores.traducciones.localidad === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.localidad}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-localidad"
                                                key="k-localidad"
                                                defaultValue={registro ? registro.localidad : ''}
                                                name='localidad'                                                
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-pais">Country
                                            {
                                                (valores.traducciones.pais === "" || valores.traducciones.pais === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.pais}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-pais"
                                                key="k-pais"
                                                defaultValue={registro ? registro.pais : ''}
                                                name='pais'                                                
                                            /> 
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                        (tipoform.value === "") ? (<br></br>)
                        :
                        (
                            
                            (tipoform.value=== "DATA LINK")
                            ?
                            (
                                <>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-direccion">Address
                                            {
                                                (valores.traducciones.direccion === "" || valores.traducciones.direccion === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.direccion}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                                                                  
                                                control={control}                                            
                                                id="i-direccion"
                                                key="k-direccion"
                                                defaultValue={registro ? registro.direccion : ''}
                                                name='direccion'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-servicio">Service
                                            {
                                                (valores.traducciones.servicio === "" || valores.traducciones.servicio === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.servicio}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.servicios}
                                                    onChange={(value) => {
                                                        props.onChange(value);                                                        
                                                    }}
                                                    placeholder=""
                                                    isClearable={true}
                                                    isDisabled={(privModificar) ? false : true}
                                                    defaultValue={(registro) ? registro.servicio : ""}
                                                    styles={(privModificar) ? styleNormal : styleDisable} 
                                                    control={control}
                                                />} 
                                                control={control} 
                                                id="i-servicio"
                                                key="k-servicio"                                                
                                                name='servicio'
                                                defaultValue={registro ? registro.servicio : ''}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-proveedor">Provider
                                            {
                                                (valores.traducciones.proveedor === "" || valores.traducciones.proveedor === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.proveedor}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.proveedores}
                                                    onChange={(value) => {
                                                        props.onChange(value);                                                        
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    isDisabled={(privModificar) ? false : true}
                                                    defaultValue={(registro) ? registro.proveedor : ""}
                                                    styles={(privModificar) ? styleNormal : styleDisable}  
                                                    control={control}
                                                />} 
                                                control={control} 
                                                id="i-proveedor"
                                                key="k-proveedor"                                                
                                                name='proveedor'
                                                defaultValue={registro ? registro.proveedor : ''}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-tipoenlace">Link Type
                                            {
                                                (valores.traducciones.tipoenlace === "" || valores.traducciones.tipoenlace === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.tipoenlace}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.tiposenlace}
                                                    onChange={(value) => {
                                                        props.onChange(value);                                                        
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    isDisabled={(privModificar) ? false : true}
                                                    defaultValue={(registro) ? registro.tipoenlace : ""}
                                                    styles={(privModificar) ? styleNormal : styleDisable}  
                                                    control={control}
                                                />} 
                                                control={control} 
                                                id="i-tipoenlace"
                                                key="k-tipoenlace"                                                
                                                name='tipoenlace'
                                                defaultValue={registro ? registro.tipoenlace : ''}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-anchobanda">Bandwidth (Mbps)
                                            {
                                                (valores.traducciones.anchobanda === "" || valores.traducciones.anchobanda === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.anchobanda}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                                                                  
                                                control={control}
                                                id="i-anchobanda"
                                                key="k-anchobanda"
                                                defaultValue={registro ? registro.anchobanda : ''}
                                                name='anchobanda'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-ipequipo">IP
                                            {
                                                (valores.traducciones.ipequipo === "" || valores.traducciones.ipequipo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.ipequipo}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                                                                  
                                                control={control}
                                                id="i-ipequipo"
                                                key="k-ipequipo"
                                                defaultValue={registro ? registro.ipequipo : ''}
                                                name='ipequipo'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-descripcion">Description
                                            {
                                                (valores.traducciones.descripcion === "" || valores.traducciones.descripcion === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.descripcion}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<textarea disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} rows="3" cols="50"></textarea>}
                                                control={control}
                                                id="i-descripcion"
                                                key="k-descripcion"
                                                defaultValue={registro ? registro.descripcion : ''}
                                                name='descripcion'
                                            />                             
                                        </div>
                                    </div>
                                    
                                </>
                            )
                            :
                            (
                                <>                                    
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-hostname">Hostname
                                            {
                                                (valores.traducciones.hostname === "" || valores.traducciones.hostname === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.hostname}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                                control={control}
                                                id="i-hostname"
                                                key="k-hostname"
                                                defaultValue={registro ? registro.hostname : ''}
                                                name='hostname'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-ordencompra">Purchase order
                                            {
                                                (valores.traducciones.ordencompra === "" || valores.traducciones.ordencompra === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.ordencompra}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                                control={control}
                                                id="i-ordencompra"
                                                key="k-ordencompra"
                                                defaultValue={registro ? registro.ordencompra : ''}
                                                name='ordencompra'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-marca">Brand
                                            {
                                                (valores.traducciones.marca === "" || valores.traducciones.marca === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.marca}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.marcas}
                                                    placeholder=""
                                                    onChange={(value) => {
                                                        props.onChange(value);     
                                                        cambioMarca(value);                                                    
                                                    }}
                                                    isClearable={true} 
                                                    isDisabled={(privModificar) ? false : true}
                                                    value={marca}
                                                    defaultValue={marca}
                                                    styles={(privModificar) ? styleNormal : styleDisable} 
                                                    control={control}
                                                />} 
                                                control={control} 
                                                id="i-marca"
                                                key="k-marca"                                                
                                                name='marca'
                                                defaultValue={registro ? registro.marca : ''}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-modelo">Model
                                            {
                                                (valores.traducciones.modelo === "" || valores.traducciones.modelo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.modelo}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.modelos}
                                                    onChange={(value) => {
                                                        props.onChange(value);   
                                                        cambioModelo(value);                                                      
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    
                                                    value={modelo}
                                                    defaultValue={modelo}
                                                    isDisabled={(marca === "" || marca === null) ? true : (privModificar) ? false : true}
                                                    styles={(privModificar) ? styleNormal : styleDisable} 
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-modelo"
                                                key="k-modelo"                                                
                                                name='modelo'
                                                defaultValue={registro ? registro.modelo : ''}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-ipequipo">IP
                                            {
                                                (valores.traducciones.ipequipo === "" || valores.traducciones.ipequipo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.ipequipo}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                                                                  
                                                control={control}
                                                id="i-ipequipo"
                                                key="k-ipequipo"
                                                defaultValue={registro ? registro.ipequipo : ''}
                                                name='ipequipo'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-fechaentrega">Delivery Date (YYYY/MM/DD)
                                            {
                                                (valores.traducciones.fechaentrega === "" || valores.traducciones.fechaentrega === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.fechaentrega}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                name="fechaentrega"
                                                id="i-fechaentrega"
                                                className="bootstrap-input"
                                                control={control}
                                                defaultValue={registro ? registro.fechaentrega : ''}
                                                render={({ onChange, value }) => (
                                                    <DatePicker
                                                        disabled={(!privModificar) ? 'disabled' : ''} 
                                                        selected={value}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        onChange={onChange}
                                                        className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}                                                       
                                                    />
                                                )}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-descripcion">Description
                                            {
                                                (valores.traducciones.descripcion === "" || valores.traducciones.descripcion === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.descripcion}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<textarea disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} rows="3" cols="50"></textarea>}
                                                control={control}
                                                id="i-descripcion"
                                                key="k-descripcion"
                                                defaultValue={registro ? registro.descripcion : ''}
                                                name='descripcion'
                                            />                             
                                        </div>
                                    </div>
                                </>
                            )
                        )
                        }
                        {
                            (tipoform === "") ? (<></>)
                            :
                            (
                                <>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-modificadopor">Modified by</label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-modificadopor"
                                                key="k-modificadopor"
                                                defaultValue={registro ? registro.modificadopor : ''}
                                                name='modificadopor'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-fechaupdate">Modification Date (YYYY/MM/DD)</label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                name="fechaupdate"
                                                id="i-fechaupdate"
                                                className="bootstrap-input"
                                                control={control}
                                                defaultValue={registro ? registro.fechaupdate : ''}
                                                render={({ onChange, value }) => (
                                                    <DatePicker
                                                        disabled="disabled"
                                                        selected={value}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        onChange={onChange}
                                                        className="bootstrap-input-disable"
                                                    />
                                                )}
                                            /> 
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        <br></br>
                        <div className="row">
                            {                            
                                (privModificar)
                                ? 
                                (
                                    
                                    <div style={{width: '60%', marginRight:'auto', marginLeft: 'auto'}}>
                                        <button className="boton-from">Save</button>
                                    </div>
                                ) 
                                :
                                (
                                    <br></br>
                                )
                            }                        
                        </div>
                    </form>
                </div>
            </div>
            <br></br>
        </>
    )
}

export default FormularioEquipoComunicacion
