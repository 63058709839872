import React from 'react'
import Sidebar from '../Sidebar'
import { useHistory } from "react-router-dom";
import ReporteEE from './ReporteEE';
import swal from 'sweetalert';

import { ContainerForm } from '../Commons/Styles'

const ReporteEquipoEscritorio = ({instance, t}) => {

    let priv = sessionStorage.getItem('esc_leer');
    const history = useHistory();

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });

    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <>        
            <Sidebar instance={instance} despliegue={false}/>
            <ContainerForm>
                <ReporteEE token={t}/>
            </ContainerForm>            
        </>    
    )
}

export default ReporteEquipoEscritorio
