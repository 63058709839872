import React from 'react'
import { useParams } from "react-router-dom";
import Sidebar from '../Sidebar'
import * as TiIcons from 'react-icons/ti';
import * as AiIcons from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import FormularioCuentaServicio from './FormularioCuentaServicio';
import swal from 'sweetalert';
import { eliminarCuenta } from '../functions/FuncCuentaServicio'

import { ContainerForm, ButtonVolver, ButtonEliminacion } from '../Commons/Styles'

const FormCuentaServicio = ({instance, t}) => {

  let { id } = useParams();
  const history = useHistory();
  let priv = sessionStorage.getItem('cuentaser_leer');
  let privEliminacion = sessionStorage.getItem('cuentaser_eliminar');

  function volver()
  {
    history.push("/cuentasservicio");
  }

  function rolInvalido()
  {
    swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
      history.push("/")
    });
  }

  async function eliminar()
    {
        await eliminarCuenta(id, t).then((response) => {
            if(response.cod === 1)
            {
                swal("Delete success", "", "success").then(() => {history.push("/cuentasservicio")});
            }
            else {
                swal("Delete Error", response.mensaje, "error");
            }
        });
    }

  function eliminarRegistro()
    {
        swal({
            title: "Delete Confirmation",
            text: "You want delete this account?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((afirmacion) => {
            if(afirmacion)
            {
              eliminar();       
            }
        })
    }

  React.useEffect(() => {        
    if(priv === null) rolInvalido();
    if(priv === undefined) rolInvalido();
    if(priv === "0") rolInvalido();        
  });

  if(priv === "0" || priv  === null || priv === undefined) return (<></>)
  else return (
    <>        
      <Sidebar instance={instance} despliegue={false}/>
      <ContainerForm>
        <div className="headerformulario">
          <div className="headerform-izq">
            <ButtonVolver onClick={volver} className="tooltipboton">
                        <TiIcons.TiArrowBack/>             
                        <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>
          </div>
          <div className="headerform-mid">
            <div className="divtituloform">
              <label className="tituloformulario">Account Control Form</label>
             </div>                        
          </div>
          <div className="headerform-der">
          {
            (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>)
            : (
                        <ButtonEliminacion onClick={eliminarRegistro} className="tooltipboton">
                            <AiIcons.AiFillDelete/>
                            <span className="tooltiptextboton">{"Delete"}</span> 
                        </ButtonEliminacion>)
          }
          
          </div>
        </div>
        
        <FormularioCuentaServicio id={id} token={t}/>
        
      </ContainerForm>            
    </>
  )
}

export default FormCuentaServicio