import React, { useState, useRef } from 'react'
import styled from 'styled-components';
import Modal from 'react-modal'
import swal from 'sweetalert';
import * as FaIcons from 'react-icons/fa';
import { valoresUsuarios } from '../../functions/FuncEquipoEscritorio'
import "../../css/modalusuarios.css";
import TablaUsuarios from './TablaUsuarios';

const ButtonModal = styled.button`
    float:right;
    background-color: #696158;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 15px; 
    border-radius: 5px;  
    border-color: #453F38;
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
    color: white;    
    margin-right: 10px;
    height: 40px;

    :hover {
        background: #AB9883;
    }
`;

const ModalUsuarios = ({token}) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [usuarios, setUsuarios] = useState(null); 

    function abrirModal()
    {        
        setModalOpen(true);
    }

    function cerrarModal()
    {       
        setUsuarios(null);
        setModalOpen(false);
    }

    async function buscarUsuarios(usuario, correo){        

        await valoresUsuarios(usuario, correo, token)
        .then((response) => {
            if(response.cod === 1) {
                
                if(response.personal.length == 0)  
                    swal ("There are no records" ,
                    "No information is recorded with the filters used" ,
                    "info");

                setUsuarios(response.personal);
            }
            else if(response.cod == 3) {
                setUsuarios([]);
                swal ("There are no records", "No information is recorded with the filters used", "info");
            }
            else {
                setUsuarios([]);
                swal ("Error", response.mensaje, "error");
            }            
        })
    }

  return (
    <>
        <ButtonModal type="button" onClick={abrirModal} className="tooltipboton">  
                <FaIcons.FaUserFriends/>
                <span className="tooltiptextboton">{"Users"}</span>            
        </ButtonModal>

        <Modal 
            isOpen={modalOpen}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.75)'
                },
                content: {
                    width: '95%',
                    height: 'auto',
                    minHeight: '450px',
                    marginLeft: '10px',
                    marginRight: '10px',
                    position: 'relative',                        
                    border: '1px solid #ccc',
                    background: '#fff',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '10px'
                }
            }}
        >
            <div className="containerusuarios">
                <TablaUsuarios rows={usuarios} buscarUsuarios={buscarUsuarios} token={token} cerrarModal={cerrarModal}/>
            </div>
        </Modal>
    </>
  )
}

export default ModalUsuarios