export const camposExcel = [   
    {nombre:"ROOM", valor:"nombre"},
    {nombre:"ENTERPRISE", valor:"empresa"},
    {nombre:"PLANT", valor:"planta"},
    {nombre:"LOCATION", valor:"localidad"},
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"BUSINESS", valor:"negocio"},
    {nombre:"GENERATOR GROUP", valor:"grupoelectrogeno"},
    {nombre:"FIRE DETECTION SYSTEM", valor:"deteccionincendio"},
    {nombre:"CENTRAL ALARM", valor:"alarmacentral"},
    {nombre:"FIRE EXTINGUISING SYSTEM", valor:"extincionincendios"},
    {nombre:"ACCESS CONTROL", valor:"controlacceso"},
    {nombre:"AIR EQUIPMENT", valor:"equiposaire"},
    {nombre:"TEMPERATURE SENSOR", valor:"sensortemperatura"},
    {nombre:"AREA CAPACITY", valor:"capacidadareablanca"},
    {nombre:"OBSERVATION", valor:"observacion"},
    {nombre:"UPS 1 USE", valor:"ups1_uso"},
    {nombre:"UPS 1 BRAND", valor:"ups1_marca"},
    {nombre:"UPS 1 CAPACITY", valor:"ups1_capacidad"},
    {nombre:"UPS 1 AUTONOMY", valor:"ups1_autonomia"},
    {nombre:"UPS 1 NETWORK CARD", valor:"ups1_tarjetared"},
    {nombre:"UPS 1 IP", valor:"ups1_direccionip"},
    {nombre:"UPS 1 MONITORING", valor:"ups1_monitoreo"},
    {nombre:"UPS 1 TEST DATE UPS", valor:"ups1_fechapruebaups"},
    {nombre:"UPS 1 LAST MAINTENANCE DATE", valor:"ups1_fechaultimamantencion"},
    {nombre:"UPS 2 USE", valor:"ups2_uso"},
    {nombre:"UPS 2 BRAND", valor:"ups2_marca"},
    {nombre:"UPS 2 CAPACITY", valor:"ups2_capacidad"},
    {nombre:"UPS 2 AUTONOMY", valor:"ups2_autonomia"},
    {nombre:"UPS 2 NETWORK CARD", valor:"ups2_tarjetared"},
    {nombre:"UPS 2 IP", valor:"ups2_direccionip"},
    {nombre:"UPS 2 MONITORING", valor:"ups2_monitoreo"},
    {nombre:"UPS 2 TEST DATE UPS", valor:"ups2_fechapruebaups"},
    {nombre:"UPS 2 LAST MAINTENANCE DATE", valor:"ups2_fechaultimamantencion"},
    {nombre:"MODIFIED BY", valor:"modificadopor"},
    {nombre:"MODIFIED ON", valor:"fechaupdate"}
]

export const campos = [    
    "id_sala",
"nombre",
"empresa",
"planta",
"localidad",
"pais",
"negocio",
"grupoelectrogeno",
"deteccionincendio",
"alarmacentral",
"extincionincendios",
"controlacceso",
"equiposaire",
"sensortemperatura",
"capacidadcrecimiento",
"capacidadareablanca",
"observacion",

"ups1_uso",
"ups1_marca",
"ups1_modelo",
"ups1_capacidad",
"ups1_autonomia",
"ups1_tarjetared",
"ups1_direccionip",
"ups1_monitoreo",
"ups1_fechapruebaups",
"ups1_fechaultimamantencion",

"ups2_uso",
"ups2_marca",
"ups2_modelo",
"ups2_capacidad",
"ups2_autonomia",
"ups2_tarjetared",
"ups2_direccionip",
"ups2_monitoreo",
"ups2_fechapruebaups",
"ups2_fechaultimamantencion",

"modificadopor",
"fechaupdate",
]