import React, {useState} from 'react'
import { obtenerFiltros, crearMaestro } from '../../functions/FuncMaestroApp'
import { useForm, Controller } from "react-hook-form";
import "../../css/formularioAplicacion.css";
import swal from 'sweetalert';
import Select from 'react-select'

import { style, styleNormal } from '../../Commons/Styles'

const CreacionMaestroApp = ({token}) => {

    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});
    const [registro, setRegistro] = useState(null);
    const [opcionesFabricante, setOpcionesFabricante] = useState(null);
    const [errorNombre, setErrorNombre] = useState(false);
    const [errorFabricante, setErrorFabricante] = useState(false);

    async function obtenerFiltrosApp(){
        await obtenerFiltros(token)
        .then((response) => {
            if(response.cod == 1)
            {
                const opciones = [];
                response.fabricantes.map((p) => opciones.push({value: p, label: p}));
                setOpcionesFabricante(opciones);
            }
        }); 
    }

    function validarServidor(data)
    {
        var errores = false;
        if(data.nombre === null || data.nombre === "" || data.nombre === undefined) 
        {   
            setErrorNombre(true);
            errores = true;
        }
        else setErrorNombre(false);

        if(data.fabricante === null || data.fabricante === "" || data.fabricante === undefined) 
        {   
            setErrorFabricante(true);
            errores = true;
        }
        else setErrorFabricante(false);

        return errores;
    }

    const onSubmit = (data) => {
        setRegistro(data);

        if(validarServidor(data))
        {
            swal("Form data", "Mandatory data must be entered", "warning");
        }
        else{
            swal({
                title: "Creation confirmation",
                text: "Do you want to create the record?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion){  
                    const datosform = data;

                    let nombreusuario = sessionStorage.getItem('nombre');
                    datosform.modificadopor = nombreusuario;
                    datosform.fechaupdate = new Date();                    
                    datosform.fabricante = datosform.fabricante.value; 
                    
                    await crearMaestro(datosform, token).then((response) => {
                        
                        if(response.cod === 1) swal("Successful creation", "", "success");
                        else swal("Creation Error", response.mensaje, "error");                        
                    });                    
                }
            });
        }
    }

    React.useEffect(() =>{
        obtenerFiltrosApp();
    }, []);

    //if(opcionesFabricante === null) return (<></>)
    //else
    return (
        
        <>
            
            <div className="containermaestro">
                <div className='subtitulo-box'>
                    <div className='subtitulo'>
                        <label >Master Application Creation</label>
                    </div>                    
                </div> 
                <br></br>
                <form 
                    style={{width: '90%', marginRight:'auto', marginLeft: 'auto'}} 
                    autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="row">
                        <div className="col-25">
                            <label style={{fontWeight: 'bold'}} className="label-form" htmlFor="i-nombre">Name</label> 
                        </div>
                        <div className="col-75">
                            <Controller
                                as={<input className="bootstrap-input" type='text'  />}   
                                style={(errorNombre) ? { borderColor: '#f90000'} : {}}                                  
                                control={control}
                                id="i-nombre"
                                key="k-nombre"
                                defaultValue={registro ? registro.nombre : ''}
                                name='nombre'
                            />                                 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label style={{fontWeight: 'bold'}}  className="label-form" htmlFor="i-fabricante">Producer</label> 
                        </div>
                        <div className="col-75">
                                
                            <Controller
                                as={<Select/>}
                                name="fabricante"
                                options={(opcionesFabricante !== null) ? opcionesFabricante : {}}                                
                                isDisabled={false}
                                isClearable={true}
                                placeholder=""                                    
                                control={control}
                                styles={(errorFabricante) ? style : styleNormal}
                            /> 
                        </div>
                    </div>
                        
                    <br></br>
                    <div className="row">
                        <div style={{width: '60%', marginRight:'auto', marginLeft: 'auto'}}>
                            <button className="boton-from">Save</button>
                        </div>     
                    </div>
                </form>
            </div>
        </>
        
    )
}

export default CreacionMaestroApp
