import React, { useState } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import "../../css/aplicaciones.css";
import swal from "sweetalert";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as MdIcons from "react-icons/md";
import * as AiIcons from 'react-icons/ai';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const headCells = [
  {
    id: "nombre",
    ordertype: "text",
    numeric: false,
    disablePadding: true,
    label: "Name",
    size: "25%",
  },
  {
    id: "cc1",
    ordertype: "text",
    numeric: false,
    disablePadding: true,
    label: "CC1",
    size: "35%",
  },
  {
    id: "cc2",
    ordertype: "text",
    numeric: false,
    disablePadding: true,
    label: "CC2",
    size: "35%",
  },
  {
    id: "information",
    ordertype: "text",
    numeric: false,
    disablePadding: true,
    label: "",
    size: "5%",
  },
];

const ContainerBusqueda = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
`;

const ContainerTable = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
  },
  body: {
    textDecoration: "none",
    fontSize: 12,
  },
}))(TableCell);

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TablaCorreoNotificaciones = ({ rows, seleccionar }) => {
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("nombre");
  const [orderType, setOrderType] = useState("text");
  const classes = useStyles();

  function buscar(rows) {
    let filtro = filter.toLowerCase();
    return rows.filter((row) => {
      var r = false;

      r =
        row.nombre !== ""
          ? row.nombre.toString().toLowerCase().indexOf(filtro) > -1
            ? true
            : false
          : false;
      if (r) return true;

      r =
        row.cc1 !== ""
          ? row.cc1.toString().toLowerCase().indexOf(filtro) > -1
            ? true
            : false
          : false;
      if (r) return true;

      r =
        row.cc2 !== ""
          ? row.cc2.toString().toLowerCase().indexOf(filtro) > -1
            ? true
            : false
          : false;
      if (r) return true;
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property, ordertype) => (event) => {
    handleRequestSort(event, property, ordertype);
  };

  const handleRequestSort = (event, property, ordertype) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setOrderType(ordertype);
  };

  function stableSort(array) {
    if (orderType === "text") {
      if (order === "desc") {
        var arrayOrdenado = array.sort((a, b) =>
          a[orderBy] > b[orderBy] ? 1 : b[orderBy] > a[orderBy] ? -1 : 0
        );
        return arrayOrdenado;
      } else {
        var arrayOrdenado = array.sort((a, b) =>
          a[orderBy] < b[orderBy] ? 1 : b[orderBy] < a[orderBy] ? -1 : 0
        );
        return arrayOrdenado;
      }
    } else {
      if (order === "desc") {
        var arrayOrdenado = array.sort(function (a, b) {
          return a[orderBy] - b[orderBy];
        });
        return arrayOrdenado;
      } else {
        var arrayOrdenado = array.sort(function (a, b) {
          return b[orderBy] - a[orderBy];
        });
        return arrayOrdenado;
      }
    }
  }

  return (
    <>
      <ContainerBusqueda>
        <br></br>
        <TextField
          id="outlined-search"
          label="Busqueda"
          size="small"
          type="search"
          variant="outlined"
          value={filter}
          style={{background: "#FFFFFF"}}
          onChange={(e) => setFilter(e.target.value)}
        />
        <br></br>
      </ContainerBusqueda>
      <ContainerTable>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="enhanced table"
          >
            <TableHead style={{ background: "#696158", color: "#fff" }}>
              <TableRow>
                {headCells.map((headCell) => (
                  <StyledTableCell
                    key={headCell.id}
                    align={"center"}
                    sortDirection={orderBy === headCell.id ? order : false}
                    style={{ width: headCell.size, color: "#fff !important" }}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(
                        headCell.id,
                        headCell.ordertype
                      )}
                      style={{ background: "#696158", color: "#fff" }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(buscar(rows))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    key={row.id_correonofiticacion}
                    style={{
                      textDecoration: "none",
                      fontSize: "10px !important",
                    }}
                  >
                    <StyledTableCell align="left">{row.nombre}</StyledTableCell>
                    <StyledTableCell align="left">{row.cc1}</StyledTableCell>
                    <StyledTableCell align="left">{row.cc2}</StyledTableCell>
                    <StyledTableCell align="left">{
                        <button 
                            onClick={() => {
                                seleccionar({
                                    "id": row.id_correonofiticacion,
                                    "nombre": row.nombre,
                                    "cc1": row.cc1,
                                    "cc2": row.cc2,
                                });                                                        
                            }}
                            className="boton-observacion">
                            <AiIcons.AiFillEdit />
                        </button> 
                    }</StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Table>
            <TableBody>
              <TableRow>
                <TablePagination
                  style={{ display: "flex" }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "Registros por pagina" },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ContainerTable>
    </>
  );
};

export default TablaCorreoNotificaciones;
