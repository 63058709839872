import React from 'react'
import swal from 'sweetalert';

import { obtenerFiltros, obtenerTabla, obtenerTablaBitacoras } from '../functions/FuncOtrosDispositivos'
import FiltroBaseNuevo from '../FiltroBaseNuevo';
import  * as Metodos from '../functions/MetodosComunes';

import { ContainerFiltros, FilaContainerFiltros, Button } from '../Commons/Styles'

const FiltroTablaOtrosDispositivos = ({aplicarFiltro, aplicarFiltroCompleto, obtenerBitacoras, load, token}) => {

    let priv = sessionStorage.getItem('dis_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');

    const [valores, setValores] = React.useState(null);
    const [errorTipo, setErrorTipo] = React.useState(false);

    const [condiciones, setCondiciones] = React.useState(null);
    const [condicion, setCondicion] = React.useState("");
    const [tipos, setTipos] = React.useState(null);
    const [tipo, setTipo] = React.useState("");
    const [estados, setEstados] = React.useState(null);
    const [estado, setEstado] = React.useState("");
    const [plantas, setPlantas] = React.useState(null);
    const [planta, setPlanta] = React.useState("");
    const [paises, setPaises] = React.useState(null);
    const [pais, setPais] = React.useState("");
    const [localidades, setLocalidades] = React.useState(null);
    const [localidad, setLocalidad]  = React.useState("");
    const [numeroserie, setNumeroSerie] = React.useState("");    

    const cambioTipo = (valor) => {
        setTipo(valor);
    };

    const cambioLocalidad = (valor) => {
        setLocalidad(valor);
    };

    const cambioPlanta = (valor) => {
        setPlanta(valor);
    };

    const cambioCondicion= (valor) => {
        setCondicion(valor);
    };

    const cambioEstado = (valor) => {
        setEstado(valor);
    };

    const cambioPais = (valor) => {

        if(valor !== null && valor !== undefined && valor !== ""){
            setPais(valor);
            filtrarLocalidad(valor.value);
        } 
        else{      
            setPais(null);      
            var array = [];
            if(valores.filtrolocalidad.length > 0){

                valores.filtrolocalidad.map((p) => {
                    array.push({label: p.localidad, value: p.localidad});
                });  
                setLocalidades(array);  
            }
        }
    };

    function filtrarLocalidad(filtro)
    {
        var array = [];
        if(valores.filtrolocalidad.length > 0){
            valores.filtrolocalidad.map((p) => {
                if(p.pais === filtro) array.push({label: p.localidad, value: p.localidad});
            });  

            setLocalidad(null);
            setLocalidades(array); 
        }
    }

    async function obtenerFiltrosCom(){
        await obtenerFiltros(token)
        .then((response) => {
            if(response.cod === 1)
            {
                const val = {          
                    "paises": response.paises,
                    "filtrolocalidad": response.filtrolocalidad,
                    "localidades": response.localidades,                    
                    "plantas": response.plantas,
                    "filtroplantas": response.filtroplantas,
                    "estados": response.estados,
                    "condiciones": response.condiciones,
                    "tipos": response.tipos
                }; 

                setEstados(Metodos.opcionesFiltros(val.estados));               
                setCondiciones(Metodos.opcionesFiltros(val.condiciones)); 
                setTipos(Metodos.opcionesFiltros(val.tipos));

                if(priv === "1"){
                    setLocalidades(Metodos.opcionesFiltrosValor(val.localidades, localidadUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser)); 
                    setPlantas(Metodos.opcionesFiltrosPlantaLocalidad(val.filtroplantas, localidadUser));                   
                }

                if(priv === "2"){
                    setLocalidades(Metodos.opcionesFiltrosLocalidad(val.filtroplantas, paisUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));  
                    setPlantas(Metodos.opcionesFiltrosPlantaPais(val.filtroplantas, paisUser));                   
                }

                if(priv === "3"){
                    setLocalidades(val.localidades = Metodos.opcionesFiltros(val.localidades));
                    setPaises(Metodos.opcionesFiltros(val.paises));     
                    setPlantas(Metodos.opcionesFiltros(val.plantas));  
                }

                setValores(val);
            }
        }); 
    }

    React.useEffect(() =>{
        if(valores === null) obtenerFiltrosCom();

        filtrosAnteriores();
    }, []);

    async function obtenerRegistros()
    {
        //if(tipo === null || tipo === undefined || tipo === "")
        if(false)
        {
            setErrorTipo(true);
            swal("Filter Error","A value must be entered in Type", "warning")
        }
        else {

            setErrorTipo(false);
            load(true);
            aplicarFiltro(null);
            
            var paisApi = (pais !== null && pais !== undefined && pais !== "") ? pais.value : null;
            var localidadApi = (localidad !== null && localidad !== undefined && localidad !== "") ? localidad.value : null;
            var estadoApi = (estado !== null && estado !== undefined && estado !== "") ? estado.value : null;
            var condicionApi = (condicion !== null && condicion !== undefined && condicion !== "") ? condicion.value : null;            
            var plantaApi = (planta !== null && planta !== undefined && planta !== "") ? planta.value : null;
            var tipoApi = (tipo !== null && tipo !== undefined && tipo !== "") ? tipo.value : null;            
            var numeroserieApi = (numeroserie !== null && numeroserie !== undefined && numeroserie !== "") ? numeroserie : null;

            (paisApi !== null) ? sessionStorage.setItem('filtrosdispositivo_pais', paisApi) : sessionStorage.setItem('filtrosdispositivo_pais', null);
            (localidadApi !== null) ? sessionStorage.setItem('filtrosdispositivo_localidad', localidadApi) : sessionStorage.setItem('filtrosdispositivo_localidad', null);
            (estadoApi !== null) ? sessionStorage.setItem('filtrosdispositivo_estado', estadoApi) : sessionStorage.setItem('filtrosdispositivo_estado', null);
            (condicionApi !== null) ? sessionStorage.setItem('filtrosdispositivo_condicion', condicionApi) : sessionStorage.setItem('filtrosdispositivo_condicion', null);
            (plantaApi !== null) ? sessionStorage.setItem('filtrosdispositivo_planta', plantaApi) : sessionStorage.setItem('filtrosdispositivo_planta', null);
            (tipoApi !== null) ? sessionStorage.setItem('filtrosdispositivo_tipo', tipoApi) : sessionStorage.setItem('filtrosdispositivo_tipo', null);            
            (numeroserieApi !== null) ? sessionStorage.setItem('filtrosdispositivo_dispositivo', numeroserieApi) : sessionStorage.setItem('filtrosdispositivo_dispositivo', null);

            await obtenerTabla("", "", "", "", "", "", "", "3", paisUser, localidadUser, token)
            .then((response) => { 

                if (response.cod === 1)
                {
                    aplicarFiltroCompleto(response.dispositivos);
                }
                else {
                    aplicarFiltroCompleto(null);
                    swal ("Search Error" , response.mensaje , "warning");
                }
                
                load(false);
            });
            
            await obtenerTablaBitacoras(tipoApi, estadoApi, condicionApi, paisApi, localidadApi, plantaApi, numeroserieApi, priv, paisUser, localidadUser, token)
            .then((response) => {            
                if (response.cod === 1) obtenerBitacoras(response.bitacoras);            
                else obtenerBitacoras(null);    
            });

            await obtenerTabla(tipoApi, estadoApi, condicionApi, paisApi, localidadApi, plantaApi, numeroserieApi, priv, paisUser, localidadUser, token)
            .then((response) => { 

                if (response.cod === 1)
                {
                    aplicarFiltro(response.dispositivos);
                }
                else {
                    aplicarFiltro(null);
                    swal ("Search Error" , response.mensaje , "warning");
                }
                
                load(false);
            });          
        }
    }

    function filtrosAnteriores()
    {

        let filtrosdispositivo_tipo = sessionStorage.getItem('filtrosdispositivo_tipo');        
        if(filtrosdispositivo_tipo !== "null") setTipo({label: filtrosdispositivo_tipo, value: filtrosdispositivo_tipo})       

        let filtrosdispositivo_estado = sessionStorage.getItem('filtrosdispositivo_estado');        
        if(filtrosdispositivo_estado !== "null") setEstado({label: filtrosdispositivo_estado, value: filtrosdispositivo_estado})

        let filtrosdispositivo_condicion = sessionStorage.getItem('filtrosdispositivo_condicion');        
        if(filtrosdispositivo_condicion !== "null") setCondicion({label: filtrosdispositivo_condicion, value: filtrosdispositivo_condicion})

        let filtrosdispositivo_pais = sessionStorage.getItem('filtrosdispositivo_pais');        
        if(filtrosdispositivo_pais !== "null") setPais({label: filtrosdispositivo_pais, value: filtrosdispositivo_pais})

        let filtrosdispositivo_localidad = sessionStorage.getItem('filtrosdispositivo_localidad');        
        if(filtrosdispositivo_localidad !== "null") setLocalidad({label: filtrosdispositivo_localidad, value: filtrosdispositivo_localidad})        
        
        let filtrosdispositivo_planta = sessionStorage.getItem('filtrosdispositivo_planta');        
        if(filtrosdispositivo_planta !== "null") setPlanta({label: filtrosdispositivo_planta, value: filtrosdispositivo_planta})        
        
        let filtrosdispositivo_dispositivo = sessionStorage.getItem('filtrosdispositivo_dispositivo');        
        if(filtrosdispositivo_dispositivo !== "null") setNumeroSerie(filtrosdispositivo_dispositivo)
    }

    if(valores === null) return (<></>)
    else return (
        <>
            <FilaContainerFiltros>
            <ContainerFiltros>
                <FiltroBaseNuevo
                    opciones={tipos}
                    valor={tipo}
                    nombre={"Type"}
                    namecampo="tipo"
                    cambio={cambioTipo}
                    proporcion={"25%"}
                    error={errorTipo}
                />

                <FiltroBaseNuevo
                    opciones={estados}
                    valor={estado}
                    nombre={"State"}
                    namecampo="estado"
                    cambio={cambioEstado}
                    proporcion={"25%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={condiciones}
                    valor={condicion}
                    nombre={"Condition"}
                    namecampo="condicion"
                    cambio={cambioCondicion}
                    proporcion={"25%"}
                    error={false}
                />
                
                <div style={{width: "25%", marginLeft: '10px', marginRight: 'auto',}}>
                    <input className="bootstrap-input-filtro" value={numeroserie} type='text' onChange={(e) => setNumeroSerie(e.target.value)} placeholder={"IP / Description / Serial / Hostname"}  />
                </div>                   
            </ContainerFiltros>            
            <ContainerFiltros>
            <FiltroBaseNuevo
                    opciones={paises}
                    valor={pais}
                    nombre={"Country"}
                    namecampo="pais"
                    cambio={cambioPais}
                    proporcion={"25%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={localidades}
                    valor={localidad}
                    nombre={"Location"}
                    namecampo="localidad"
                    cambio={cambioLocalidad}
                    proporcion={"25%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={plantas}
                    valor={planta}
                    nombre={"Plant"}
                    namecampo="planta"
                    cambio={cambioPlanta}
                    proporcion={"25%"}
                    error={false}
                />

                <div style={{width: "25%", marginLeft: '10px', marginRight: 'auto',}}>
                    <Button onClick={obtenerRegistros}>Display</Button>
                </div>
                
            </ContainerFiltros>
            </FilaContainerFiltros>
            
            <br></br>
            <hr style={{width: '90%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
            
        </>
    )
}

export default FiltroTablaOtrosDispositivos
