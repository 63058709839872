export const iniciarVariablesSesion = (u) =>{
    sessionStorage.setItem('nombre', u.nombre);
    sessionStorage.setItem('idioma', u.idioma);
    sessionStorage.setItem('email', u.correo);
    sessionStorage.setItem('pais', u.pais);
    sessionStorage.setItem('localidad', u.localidad);
    sessionStorage.setItem('planta', u.planta);

    sessionStorage.setItem('filtrosala_pais', null);
    sessionStorage.setItem('filtrosala_planta', null);
    sessionStorage.setItem('filtrosala_sala', null);
    sessionStorage.setItem('filtrosala_localidad', null);

    sessionStorage.setItem('filtroservidor_pais', null);
    sessionStorage.setItem('filtroservidor_servidor', null);
    sessionStorage.setItem('filtroservidor_localidad', null);
    sessionStorage.setItem('filtroservidor_estado', null);    
    sessionStorage.setItem('filtroservidor_negocio', null);
    sessionStorage.setItem('filtroservidor_sistemaoperativo', null);
    sessionStorage.setItem('filtroservidor_red', null);

    sessionStorage.setItem('filtrodispositivoot_pais', null);
    sessionStorage.setItem('filtrodispositivoot_localidad', null);
    sessionStorage.setItem('filtrodispositivoot_planta', null);
    sessionStorage.setItem('filtrodispositivoot_tipoactivo', null);
    sessionStorage.setItem('filtrodispositivoot_estado', null);
    sessionStorage.setItem('filtrodispositivoot_negocio', null);
    sessionStorage.setItem('filtrodispositivoot_dispositivoot', null);
    
    sessionStorage.setItem('filtrosaplicacion_pais', null);
    sessionStorage.setItem('filtrosaplicacion_negocio', null);
    sessionStorage.setItem('filtrosaplicacion_fabricante', null);
    sessionStorage.setItem('filtrosaplicacion_estado', null);
    sessionStorage.setItem('filtrosaplicacion_ambiente', null);
    sessionStorage.setItem('filtrosaplicacion_tipoaplicacion', null);
    sessionStorage.setItem('filtrosaplicacion_aplicacion', null);

    sessionStorage.setItem('filtrosequipos_estado', null);
    sessionStorage.setItem('filtrosequipos_condicion', null);
    sessionStorage.setItem('filtrosequipos_pais', null);
    sessionStorage.setItem('filtrosequipos_localidad', null);
    sessionStorage.setItem('filtrosequipos_planta', null);
    sessionStorage.setItem('filtrosequipos_tipo', null);
    sessionStorage.setItem('filtrosequipos_equipo', null);

    sessionStorage.setItem('filtroscomunicacion_familia', null);
    sessionStorage.setItem('filtroscomunicacion_tipo', null);
    sessionStorage.setItem('filtroscomunicacion_estado', null);
    sessionStorage.setItem('filtroscomunicacion_condicion', null);
    sessionStorage.setItem('filtroscomunicacion_comunicacion', null);
    sessionStorage.setItem('filtroscomunicacion_pais', null);
    sessionStorage.setItem('filtroscomunicacion_localidad', null);
    sessionStorage.setItem('filtroscomunicacion_planta', null);

    sessionStorage.setItem('filtrosdispositivo_tipo', null);
    sessionStorage.setItem('filtrosdispositivo_estado', null);
    sessionStorage.setItem('filtrosdispositivo_condicion', null);
    sessionStorage.setItem('filtrosdispositivo_dispositivo', null);
    sessionStorage.setItem('filtrosdispositivo_pais', null);
    sessionStorage.setItem('filtrosdispositivo_localidad', null);
    sessionStorage.setItem('filtrosdispositivo_planta', null);

    sessionStorage.setItem('filtroscuentas_estado', null);
    sessionStorage.setItem('filtroscuentas_pais', null);
    sessionStorage.setItem('filtroscuentas_negocio', null);
    sessionStorage.setItem('filtroscuentas_nombre', null);

    if(Object.keys(u.privilegios).length !== 0)
    {      
      sessionStorage.setItem('ser_leer', u.privilegios.ser_leer);      
      sessionStorage.setItem('ser_escribir', u.privilegios.ser_escribir);
      sessionStorage.setItem('ser_crear', u.privilegios.ser_crear);
      sessionStorage.setItem('ser_cargamasiva', u.privilegios.ser_cargamasiva);
      sessionStorage.setItem('ser_eliminar', u.privilegios.ser_eliminar);

      sessionStorage.setItem('ser_ot_leer', u.privilegios.ser_ot_leer);      
      sessionStorage.setItem('ser_ot_escribir', u.privilegios.ser_ot_escribir);
      sessionStorage.setItem('ser_ot_crear', u.privilegios.ser_ot_crear);
      sessionStorage.setItem('ser_ot_cargamasiva', u.privilegios.ser_ot_cargamasiva);
      sessionStorage.setItem('ser_ot_eliminar', u.privilegios.ser_ot_eliminar);

      sessionStorage.setItem('app_leer', u.privilegios.app_leer);
      sessionStorage.setItem('app_escribir', u.privilegios.app_escribir);
      sessionStorage.setItem('app_crear', u.privilegios.app_crear);
      sessionStorage.setItem('app_cargamasiva', u.privilegios.app_cargamasiva);
      sessionStorage.setItem('app_eliminar', u.privilegios.app_eliminar);
      sessionStorage.setItem('app_maestro', u.privilegios.app_maestro);

      sessionStorage.setItem('esc_leer', u.privilegios.esc_leer);
      sessionStorage.setItem('esc_escribir', u.privilegios.esc_escribir);
      sessionStorage.setItem('esc_crear', u.privilegios.esc_crear);
      sessionStorage.setItem('esc_cargamasiva', u.privilegios.esc_cargamasiva);      
      sessionStorage.setItem('esc_eliminar', u.privilegios.esc_eliminar);

      sessionStorage.setItem('com_leer', u.privilegios.com_leer);
      sessionStorage.setItem('com_escribir', u.privilegios.com_escribir);
      sessionStorage.setItem('com_crear', u.privilegios.com_crear);
      sessionStorage.setItem('com_cargamasiva', u.privilegios.com_cargamasiva);
      sessionStorage.setItem('com_eliminar', u.privilegios.com_eliminar);

      sessionStorage.setItem('dis_leer', u.privilegios.dis_leer);
      sessionStorage.setItem('dis_escribir', u.privilegios.dis_escribir);
      sessionStorage.setItem('dis_crear', u.privilegios.dis_crear);
      sessionStorage.setItem('dis_cargamasiva', u.privilegios.dis_cargamasiva);
      sessionStorage.setItem('dis_eliminar', u.privilegios.dis_eliminar);

      sessionStorage.setItem('sala_leer', u.privilegios.sala_leer);
      sessionStorage.setItem('sala_escribir', u.privilegios.sala_escribir);
      sessionStorage.setItem('sala_crear', u.privilegios.sala_crear);
      sessionStorage.setItem('sala_cargamasiva', u.privilegios.sala_cargamasiva);
      sessionStorage.setItem('sala_eliminar', u.privilegios.sala_eliminar);

      sessionStorage.setItem('cuentaser_leer', u.privilegios.cuentaser_leer);
      sessionStorage.setItem('cuentaser_escribir', u.privilegios.cuentaser_escribir);
      sessionStorage.setItem('cuentaser_crear', u.privilegios.cuentaser_crear);
      sessionStorage.setItem('cuentaser_cargamasiva', u.privilegios.cuentaser_cargamasiva);
      sessionStorage.setItem('cuentaser_eliminar', u.privilegios.cuentaser_eliminar);

      sessionStorage.setItem('cert_leer', u.privilegios.cert_leer);
      sessionStorage.setItem('cert_escribir', u.privilegios.cert_escribir);
      sessionStorage.setItem('cert_crear', u.privilegios.cert_crear);
      sessionStorage.setItem('cert_cargamasiva', u.privilegios.cert_cargamasiva);
      sessionStorage.setItem('cert_eliminar', u.privilegios.cert_eliminar);

      sessionStorage.setItem('appback_leer', u.privilegios.appback_leer);
      sessionStorage.setItem('appback_escribir', u.privilegios.appback_escribir);
      sessionStorage.setItem('appback_crear', u.privilegios.appback_crear);
      sessionStorage.setItem('appback_cargamasiva', u.privilegios.appback_cargamasiva);
      sessionStorage.setItem('appback_eliminar', u.privilegios.appback_eliminar);

      sessionStorage.setItem('mas_leer', u.privilegios.mas_leer);
      sessionStorage.setItem('mas_escribir', u.privilegios.mas_escribir);
      sessionStorage.setItem('admin_personal', u.privilegios.admin_personal);
      sessionStorage.setItem('admin_otrasfuentes', u.privilegios.admin_otrasfuentes);
      sessionStorage.setItem('admin_usuarios', u.privilegios.admin_usuarios);


    }  
}