import React from 'react'
import { obtenerFiltros } from '../functions/FuncAplicacion'
import { obtenerAplicacionesModal } from '../functions/FuncCuentaServicio'

import swal from 'sweetalert';
import FiltroBaseNuevo from '../FiltroBaseNuevo';
import  * as Metodos from '../functions/MetodosComunes'

import { ContainerFiltros, Button } from '../Commons/Styles'

const FiltrosTablaAplicacionModalCuenta = ({aplicarFiltro, load, id, token}) => {

    const [valores, setValores] = React.useState(null);
    let privilegio = sessionStorage.getItem('app_leer');
    let paisUser = sessionStorage.getItem('pais');

    const [negocios, setNegocios] = React.useState(null);
    const [negocio, setNegocio] = React.useState("");
    const [ambientes, setAmbientes] = React.useState(null);
    const [ambiente, setAmbiente] = React.useState("");
    const [paises, setPaises] = React.useState(null);
    const [pais, setPais] = React.useState("");
    const [tipos, setTipos] = React.useState(null);
    const [tipo, setTipo] = React.useState("");
    const [aplicacion, setAplicacion] = React.useState("");

    async function registrosTabla()
    {
        var paisApi = (pais !== null && pais !== undefined && pais !== "") ? pais.value : null;        
        var negocioApi = (negocio !== null && negocio !== undefined && negocio !== "") ? negocio.value : null; 
        var ambienteApi = (ambiente !== null && ambiente !== undefined && ambiente !== "") ? ambiente.value : null;     
        var tipoApi = (tipo !== null && tipo !== undefined && tipo !== "") ? tipo.value : null;   
        var aplicacionApi = (aplicacion !== null && aplicacion !== undefined && aplicacion !== "") ? aplicacion : null;        

        load(true);
        aplicarFiltro(null);
        await obtenerAplicacionesModal(id, negocioApi, ambienteApi, aplicacionApi, paisApi, tipoApi, paisUser, privilegio, token)
        .then((r) => {   
            if(r.cod === 1) {
                aplicarFiltro(r.aplicaciones); 
            }
            else {
                aplicarFiltro(null);
                swal ("Search error" , r.mensaje , "warning");
            }       
              
            load(false);         
        });
    }

    async function obtenerFiltrosTabla(){
        await obtenerFiltros(token)
        .then((response) => {
            if(response.cod === 1)
            {
                const val = {          
                    "paises": response.paises,
                    "estados": response.estados,
                    "negocios": response.negocios,
                    "fabricantes": response.fabricantes,
                    "ambientes": response.ambientes,
                    "tipos": response.tipos
                };

                setNegocios(Metodos.opcionesFiltros(val.negocios));
                setPaises(Metodos.opcionesFiltros(val.paises));                
                setAmbientes(Metodos.opcionesFiltros(val.ambientes));               
                setTipos(Metodos.opcionesFiltros(val.tipos));

                if(privilegio === "2") setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));  
                else setPaises(Metodos.opcionesFiltros(val.paises));

                setValores(val);
            }
        }); 
    }

    const cambioPais =  (valor) => {
        setPais(valor);
    };

    const cambioNegocio =  (valor) => {
        setNegocio(valor);
    };

    const cambioAmbiente =  (valor) => {
        setAmbiente(valor);
    };

    const cambioTipo =  (valor) => {
        setTipo(valor);
    };

    React.useEffect(() =>{
        obtenerFiltrosTabla();        
    }, []);

    return (
        <>
            <ContainerFiltros>
                <FiltroBaseNuevo
                    opciones={paises}
                    valor={pais}
                    nombre={"Country"}
                    namecampo="pais"
                    cambio={cambioPais}
                    proporcion={"15%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={negocios}
                    valor={negocio}
                    nombre={"Business"}
                    namecampo="negocio"
                    cambio={cambioNegocio}
                    proporcion={"15%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={ambientes}
                    valor={ambiente}
                    nombre={"Environment"}
                    namecampo="ambiente"
                    cambio={cambioAmbiente}
                    proporcion={"15%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={tipos}
                    valor={tipo}
                    nombre={"Type"}
                    namecampo="tipo"
                    cambio={cambioTipo}
                    proporcion={"15%"}
                    error={false}
                />

                <div style={{width: "15%", marginLeft: '10px', marginRight: 'auto',}}>
                    <input className="bootstrap-input-filtro" value={aplicacion} type='text' onChange={(e) => setAplicacion(e.target.value)} placeholder={"Application"}  />
                </div>

                <div style={{width: "15%", marginLeft: '10px', marginRight: 'auto',}}>
                    <Button onClick={registrosTabla}>Display</Button>
                </div>
            </ContainerFiltros>
        </>
    )
}

export default FiltrosTablaAplicacionModalCuenta