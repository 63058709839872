 export var valores =[
    {
        "indice": "Protocol",
        "tabla": "ges.Protocolo",
        "id": "id_protocolo",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.DispositivoOT",
                "campo": "protocolo"
            }
        ]
    },
    {
        "indice": "Port Type",
        "tabla": "ges.TipoPuerto",
        "id": "id_tipopuerto",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.DispositivoOT",
                "campo": "tipopuerto"
            }
        ]
    },
    {
        "indice": "Backup Frequency Type",
        "tabla": "ges.TipoRespaldoFrecuencia",
        "id": "id_tiporespaldofrecuencia",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.DispositivoOT",
                "campo": "tiporespaldofrecuencia"
            }
        ]
    },
    {
        "indice": "Patching Type",
        "tabla": "ges.TipoParchado",
        "id": "id_tipoparchado",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.DispositivoOT",
                "campo": "tipoparchado"
            }
        ]
    },
    {
        "indice": "Access Remote Application",
        "tabla": "ges.AccesoRemotoAplicacion",
        "id": "id_accesoremotoaplicacion",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.DispositivoOT",
                "campo": "accesoremotoaplicacion"
            }
        ]
    },
    {
        "indice": "Asset Type",
        "tabla": "ges.TipoActivo",
        "id": "id_tipoactivo",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.DispositivoOT",
                "campo": "tipoactivo"
            }
        ]
    },
    {
        "indice": "Access Remote Type",
        "tabla": "ges.TipoAccesoRemoto",
        "id": "id_tipoaccesoremoto",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.DispositivoOT",
                "campo": "tipoaccesoremoto"
            }
        ]
    },
    {
        "indice": "Environment",
        "tabla": "ges.Ambiente",
        "id": "id_ambiente",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Servidor",
                "campo": "ambiente"
            },
            {
                "tabla": "ges.Aplicacion",
                "campo": "ambiente"
            },
            {
                "tabla": "ges.DispositivoOT",
                "campo": "ambiente"
            }
        ]
    },
    {
        "indice": "Authentication Mechanism",
        "tabla": "ges.Autenticacion",
        "id": "id_autenticacion",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [            
            {
                "tabla": "ges.Aplicacion",
                "campo": "autenticacion"
            }
        ]
    },
    {
        "indice": "Database Application",
        "tabla": "ges.BaseDatos",
        "id": "id_basedatos",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [            
            {
                "tabla": "ges.Aplicacion",
                "campo": "basedatos"
            }
        ]
    },    
    {
        "indice": "Client Platform",
        "tabla": "ges.PlataformaAplicacion",
        "id": "id_plataformaaplicacion",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [            
            {
                "tabla": "ges.Aplicacion",
                "campo": "plataforma"
            }
        ]
    },
    {
        "indice": "Antivirus",
        "tabla": "ges.Antivirus",
        "id": "id_antivirus",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Servidor",
                "campo": "antivirus"
            },
            {
                "tabla": "ges.DispositivoOT",
                "campo": "antivirus"
            }
        ]
    },
    {
        "indice": "Responsable",
        "tabla": "ges.AreaResponsable",
        "id": "id_responsable",
        "texto": "arearesponsable",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Servidor",
                "campo": "responsable"
            },
            {
                "tabla": "ges.DispositivoOT",
                "campo": "arearesponsable"
            }
        ]
    },
    {
        "indice": "Network Condition",
        "tabla": "ges.CondicionRed",
        "id": "id_condicionred",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "condicionred"
            }
        ]
    },
    {
        "indice": "Criticality",
        "tabla": "ges.Criticidad",
        "id": "id_criticidad",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Servidor",
                "campo": "criticidad"
            },
            {
                "tabla": "ges.DispositivoOT",
                "campo": "criticidad"
            }
        ]
    },
    {
        "indice": "Hard Drive",
        "tabla": "ges.DiscoDuro",
        "id": "id_discoduro",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "discoduro"
            }
        ]
    },
    {
        "indice": "Enterprise",
        "tabla": "ges.Empresa",
        "id": "id_empresa",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "empresa"
            },
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "empresa"
            },
            {
                "tabla": "ges.OtroDispositivo",
                "campo": "empresa"
            }
        ]
    },
    {
        "indice": "State",
        "tabla": "ges.Estado",
        "id": "id_estado",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Servidor",
                "campo": "estado"
            },
            {
                "tabla": "ges.Aplicacion",
                "campo": "estado"
            },
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "estado"
            },
            {
                "tabla": "ges.OtroDispositivo",
                "campo": "estado"
            },
            {
                "tabla": "ges.DispositivoOT",
                "campo": "estado"
            }
        ]
    },
    {
        "indice": "State Desktop",
        "tabla": "ges.EstadoEquipo",
        "id": "id_estadoequipo",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [            
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "estado"
            }
        ]
    },
    {
        "indice": "Fabricator",
        "tabla": "ges.Fabricante",
        "id": "id_fabricante",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Aplicacion",
                "campo": "fabricante"
            }
        ]
    },    
    {
        "indice": "Family",
        "tabla": "ges.Familia",
        "id": "id_familia",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "familia"
            }
        ]
    }, 
    {
        "indice": "Language",
        "tabla": "ges.Lenguaje",
        "id": "id_lenguaje",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Aplicacion",
                "campo": "lenguaje"
            }
        ]
    }, 
    {
        "indice": "Brand",
        "tabla": "ges.Marca",
        "id": "id_marca",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Servidor",
                "campo": "marca"
            },
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "marca"
            },
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "marca"
            },
            {
                "tabla": "ges.OtroDispositivo",
                "campo": "marca"
            }
        ]
    },    
    {
        "indice": "Memory Ram",
        "tabla": "ges.MemoriaRam",
        "id": "id_memoriaram",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "memoriaram"
            }
        ]
    },
    {
        "indice": "Business",
        "tabla": "ges.Negocio",
        "id": "id_negocio",
        "texto": "negocio",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Aplicacion",
                "campo": "negocio"
            },
            {
                "tabla": "ges.Servidor",
                "campo": "negocio"
            },
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "negocio"
            },
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "negocio"
            },
            {
                "tabla": "ges.OtroDispositivo",
                "campo": "negocio"
            }
        ]
    }, 
    {
        "indice": "Purchase Order",
        "tabla": "ges.OrdenCompra",
        "id": "id_ordencompra",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "ordencompra"
            }
        ]
    }, 
    {
        "indice": "Country",
        "tabla": "ges.Pais",
        "id": "pais_id",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Aplicacion",
                "campo": "pais"
            },
            {
                "tabla": "ges.Servidor",
                "campo": "pais"
            },
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "pais"
            },
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "pais"
            },
            {
                "tabla": "ges.OtroDispositivo",
                "campo": "pais"
            }
        ]
    }, 
    {
        "indice": "Platform",
        "tabla": "ges.Plataforma",
        "id": "id_plataforma",
        "texto": "plataforma",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Servidor",
                "campo": "plataforma"
            }
        ]
    }, 
    {
        "indice": "Publishing Platform",
        "tabla": "ges.PlataformaPublicacion",
        "id": "id_plataformapublicacion",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Aplicacion",
                "campo": "plataformapublicacion"
            }
        ]
    }, 
    {
        "indice": "Property of",
        "tabla": "ges.Propiedad",
        "id": "id_propiedad",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Servidor",
                "campo": "propiedadde"
            },
            {
                "tabla": "ges.OtroDispositivo",
                "campo": "propiedadde"
            },
            {
                "tabla": "ges.DispositivoOT",
                "campo": "propiedadde"
            },
        ]
    }, 
    {
        "indice": "Provider",
        "tabla": "ges.Proveedor",
        "id": "id_proveedor",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Aplicacion",
                "campo": "proveedor"
            },
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "proveedor"
            }
        ]
    }, 
    {
        "indice": "Service",
        "tabla": "ges.Servicio",
        "id": "id_servicio",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "servicio"
            }            
        ]
    }, 
    {
        "indice": "Operating System",
        "tabla": "ges.SistemaOperativo",
        "id": "id_so",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Servidor",
                "campo": "soservidor"
            },
            {
                "tabla": "ges.DispositivoOT",
                "campo": "sodispositivoot"
            }
        ]
    }, 
    {
        "indice": "Operating System Other Devices",
        "tabla": "ges.SistemaOperativoOtros",
        "id": "id_sootros",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.OtroDispositivo",
                "campo": "sistemaoperativo"
            }
        ]
    }, 
    {
        "indice": "Application Type",
        "tabla": "ges.TipoAplicacion",
        "id": "id_tipoaplicacion",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Aplicacion",
                "campo": "tiposoftware"
            }
        ]
    }, 
    {
        "indice": "Database Server",
        "tabla": "ges.TipoBD",
        "id": "id_tipobd",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Servidor",
                "campo": "tipobd"
            },
            {
                "tabla": "ges.DispositivoOT",
                "campo": "tipobd"
            }
        ]
    }, 
    {
        "indice": "Link Type",
        "tabla": "ges.TipoEnlace",
        "id": "id_tipoenlace",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "tipoenlace"
            }
        ]
    }, 
    {
        "indice": "Desktop Type",
        "tabla": "ges.TipoEquipoEscritorio",
        "id": "id_tipoequipoescritorio",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "tipoequipo"
            }
        ]
    }, 
    {
        "indice": "License Type",
        "tabla": "ges.TipoLicencia",
        "id": "id_tipolicencia",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Aplicacion",
                "campo": "tipolicencia"
            }
        ]
    }, 
    {
        "indice": "Device Type",
        "tabla": "ges.TipootroDispositivo",
        "id": "id_tipootrodispositivo",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.OtroDispositivo",
                "campo": "tipoequipo"
            }
        ]
    }, 
    {
        "indice": "Proccesor Type",
        "tabla": "ges.TipoProcesador",
        "id": "id_tipoprocesador",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "tipoprocesador"
            }
        ]
    },
    {
        "indice": "Server Type",
        "tabla": "ges.TipoServidor",
        "id": "id_tiposervidor",
        "texto": "tiposervidor",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Servidor",
                "campo": "tiposervidor"
            },
            {
                "tabla": "ges.DispositivoOT",
                "campo": "tiposervidor"
            }
        ]
    },
    {
        "indice": "Proccesor Speed",
        "tabla": "ges.VelocidadProcesador",
        "id": "id_velocidadprocesador",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "velocidadprocesador"
            }
        ]
    },
    {
        "indice": "Version WebServer",
        "tabla": "ges.VersionServidor",
        "id": "id_version",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Servidor",
                "campo": "versionservidor"
            }
        ]
    },
    {
        "indice": "Location",
        "tabla": "ges.Localidad",
        "id": "localidad_id",
        "texto": "nombre",
        "campos": [
            {
                "label": "Country",
                "nombre": "pais",
                "opciones": "paises",
                "size": "45%",
                "valor": ""
            }
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "localidad"
            },
            {
                "tabla": "ges.Servidor",
                "campo": "localidad"
            },
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "localidad"
            },
            {
                "tabla": "ges.OtroDispositivo",
                "campo": "localidad"
            }
        ],
        "filtros": null
    },
    {
        "indice": "Model",
        "tabla": "ges.Modelo",
        "id": "id_modelo",
        "texto": "nombre",
        "campos": [
            {
                "label": "Brand",
                "nombre": "marca",
                "opciones": "marcas",
                "size": "45%",
                "valor": ""
            }
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "modelo"
            },            
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "modelo"
            },
            {
                "tabla": "ges.OtroDispositivo",
                "campo": "modelo"
            }
        ],
        "filtros": null
    },
    {
        "indice": "Usability",
        "tabla": "ges.Usabilidad",
        "id": "id_usabilidad",
        "texto": "nombre",
        "campos": [
            {
                "label": "Family",
                "nombre": "familia",
                "opciones": "familias",
                "size": "45%",
                "valor": ""
            }
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "usabilidad"
            }
        ],
        "filtros": null
    },
    {
        "indice": "Operating System Version",
        "tabla": "ges.VersionSO",
        "id": "id_versionso",
        "texto": "nombre",
        "campos": [
            {
                "label": "Operating System",
                "nombre": "sistemaoperativo",
                "opciones": "sistemasoperativosotros",
                "size": "45%",
                "valor": ""
            }
        ],
        "relaciones": [
            {
                "tabla": "ges.OtroDispositivo",
                "campo": "version"
            }
        ],
        "filtros": null
    },
    {
        "indice": "Database Version App",
        "tabla": "ges.VersionBaseDatos",
        "id": "id_versionbasedatos",
        "texto": "nombre",
        "campos": [
            {
                "label": "Database",
                "nombre": "basedatos",
                "opciones": "basesdatos",
                "size": "45%",
                "valor": ""
            }
        ],
        "relaciones": [
            {
                "tabla": "ges.Aplicacion",
                "campo": "versionbd"
            }
        ],
        "filtros": null
    },
    {
        "indice": "Equipment Type",
        "tabla": "ges.TipoEquipoComunicacion",
        "id": "id_tipoequipocomunicacion",
        "texto": "nombre",
        "campos": [
            {
                "label": "Family",
                "nombre": "familia",
                "opciones": "familias",
                "size": "20%",
                "valor": ""
            },
            {
                "label": "Usability",
                "nombre": "usabilidad",
                "opciones": "usabilidades",
                "size": "25%",
                "valor": ""
            }
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "tipoequipo"
            }
        ],
        "filtros": [
            {
                "campo": "usabilidad",
                "filtrado": "familia",
                "valores": "usabilidadesfiltro",
                "opciones": "usabilidades"
            }
        ]
    },
    {
        "indice": "Plant",
        "tabla": "ges.Planta",
        "id": "id_planta",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.EquipoComunicacion",
                "campo": "localidad"
            },            
            {
                "tabla": "ges.EquipoEscritorio",
                "campo": "localidad"
            },
            {
                "tabla": "ges.OtroDispositivo",
                "campo": "localidad"
            },
            {
                "tabla": "ges.DispositivoOT",
                "campo": "plantaindustrial"
            }
        ]
    },
    {
        "indice": "Net Owner",
        "tabla": "ges.PropietarioRed",
        "id": "id_propietariored",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [            
            {
                "tabla": "ges.Servidor",
                "campo": "propietariored"
            }
        ]
    },
    {
        "indice": "Integration",
        "tabla": "ges.Integracion",
        "id": "id_integracion",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [            
            {
                "tabla": "ges.IntegracionesAplicacion",
                "campo": "integracion"
            }
        ]
    },
    {
        "indice": "Certificate Type",
        "tabla": "ges.TipoCertificado",
        "id": "id_tipocertificado",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [            
            {
                "tabla": "ges.Certificado",
                "campo": "tipo"
            }
        ]
    },
    {
        "indice": "Certificate Provider",
        "tabla": "ges.ProveedorCertificado",
        "id": "id_proveedor",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [            
            {
                "tabla": "ges.Certificado",
                "campo": "proveedor"
            }
        ]
    },
    {
        "indice": "Certifying Entity",
        "tabla": "ges.EntidadCertificadora",
        "id": "id_entidad",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [            
            {
                "tabla": "ges.Certificado",
                "campo": "entidadcertificadora"
            }
        ]
    },
    {
        "indice": "Account Control Type",
        "tabla": "ges.TipoCuentaServicio",
        "id": "id_tipocuentaservicio",
        "texto": "tipocuentaservicio",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.CuentaServicio",
                "campo": "tipocuenta"
            }
        ]
    },
    {
        "indice": "Family Application Control",
        "tabla": "ges.FamiliaAplicacionPermitida",
        "id": "id_familiaap",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.AplicacionPermitida",
                "campo": "familia"
            }
        ]
    },
    {
        "indice": "Condition Application Control",
        "tabla": "ges.CondicionAplicacionPermitida",
        "id": "id_condicionap",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.AplicacionPermitida",
                "campo": "condicion"
            }
        ]
    },
    {
        "indice": "Reset Type",
        "tabla": "ges.TipoReinicio",
        "id": "id_tiporeinicio",
        "texto": "nombre",
        "campos": [
            
        ],
        "relaciones": [
            {
                "tabla": "ges.Servidor",
                "campo": "tiporeinicio"
            }
        ]
    },    
    {
        "indice": "Condition",
        "tabla": "ges.Condicion",
        "id": "id_condicion",
        "texto": "nombre",
        "campos": [
            {
                "label": "State",
                "nombre": "estado",
                "opciones": "estadosequipos",
                "size": "45%",
                "valor": ""
            }
        ],
        "relaciones": [
            {
            "tabla": "ges.EquipoEscritorio",
            "campo": "condicion"
            },
            {
                "tabla": "ges.OtroDispositivo",
                "campo": "condicion"
            }
        ],
        "filtros": null
        },
]