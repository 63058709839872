import React from 'react'
import { obtenerdatosFiltros } from '../../functions/FuncServidor'
import { obtenerServidoresModal } from '../../functions/FuncAplicacionBack'
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FiltroBase from '../../FiltroBase';
import swal from 'sweetalert';

const ContainerFiltros= styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
`;

const Button = styled.button`
    margin-left: 10px;
    margin-right: auto;
    background-color: #EA7600;
    color: white;    
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    vertical-align: middle;
    color: white;
`;

const useStyles = makeStyles((theme) => ({
    formControl: {
      marginLeft: '10px',
      marginRight: 'auto',
      minWidth: '10px',
      width: '15%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const opcionesFiltros = (arreglo, setConjunto, setCarga) => {
    var array = [];
    if(arreglo.length > 0){
        arreglo.map((p) => array.push({label: p, value: p}));    
        setConjunto(array);
        setCarga(true); 
    }
}

const FiltrosTablaPOServidorModal = ({aplicarFiltro, load, id, token}) => {

    const [localidadesFiltro, setLocalidadesFiltro] = React.useState(null);

    const [localidad, setLocalidad] = React.useState("");
    const [clocalidad, setCLocalidad] = React.useState(false);
    const [localidades, setLocalidades] = React.useState(null);

    const [pais, setPais] = React.useState("");
    const [cpais, setCPais] = React.useState(false);
    const [paises, setPaises] = React.useState(null);

    const [negocio, setNegocio] = React.useState("");
    const [cnegocio, setCNegocio] = React.useState(false);
    const [negocios, setNegocios] = React.useState(null);    
    const [servidor, setServidor] = React.useState("");

    const classes = useStyles();

    async function registrosTabla(){

        load(true);
        aplicarFiltro(null);
        await obtenerServidoresModal(id, localidad, pais, negocio, servidor, token)
        .then((r) => {   
            if(r.cod === 1) {
                aplicarFiltro(r.servidores); 
            }
            else {
                aplicarFiltro(null);
                swal ("Search error" , r.mensaje , "warning");
            }       
              
            load(false);         
        });
    }

    async function obtenerFiltros(){
        await obtenerdatosFiltros(token)
        .then((r) => {
            opcionesFiltros(r.localidades, setLocalidades, setCLocalidad);
            opcionesFiltros(r.paises, setPaises, setCPais);
            opcionesFiltros(r.negocios, setNegocios, setCNegocio);
            setLocalidadesFiltro(r.filtrolocalidad);

        }); 
    }

    const cambioLocalidad = (valor) => {
        setLocalidad(valor);
    };

    const cambioPais = (valor) => {
        setPais(valor);

        if(valor !== null && valor !== undefined && valor !== "") filtrarLocalidad(valor);
        else{            
            var array = [];
            if(localidadesFiltro.length > 0){
                localidadesFiltro.map((p) => {
                    array.push({label: p.localidad, value: p.localidad});
                });    
                //setLocalidad("");
                setLocalidades(array);                
                
            }
        }
    };

    function filtrarLocalidad(filtro)
    {
        var array = [];
        if(localidadesFiltro.length > 0){
            localidadesFiltro.map((p) => {
                if(p.pais === filtro) array.push({label: p.localidad, value: p.localidad});
            });   
            setLocalidad(""); 
            setLocalidades(array);            
        }
    }

    const cambioNegocio = (valor) => {
        setNegocio(valor);
    };

    React.useEffect(() =>{
        obtenerFiltros();        
    }, []);

    return (
        <>
            <ContainerFiltros>
                <FiltroBase 
                    opciones={paises} 
                    cargado={cpais}
                    value={pais}
                    nombre="Country"
                    cambio={cambioPais}
                    classes={classes}
                />

                <FiltroBase 
                    opciones={localidades} 
                    cargado={clocalidad}
                    value={localidad}
                    nombre="Location"
                    cambio={cambioLocalidad}
                    classes={classes}
                />

                <FiltroBase 
                    opciones={negocios} 
                    cargado={cnegocio}
                    value={negocio}
                    nombre="Business"
                    cambio={cambioNegocio}
                    classes={classes}
                />

                <FormControl variant="outlined" className={classes.formControl}>  
                    {
                        <TextField 
                            id="outlined-basic" 
                            value={servidor} 
                            style={{minWidth: '100px'}}
                            onChange={(e) => setServidor(e.target.value)} 
                            label="Server" 
                            variant="outlined" 
                        />  
                    }                 
                </FormControl>
                
                <Button onClick={registrosTabla} className={classes.formControl} style={{verticalAlign: 'middle'}}>Display</Button>
            
            </ContainerFiltros>
        </>
    )
}

export default FiltrosTablaPOServidorModal
