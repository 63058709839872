import React, { useState } from 'react';
import { makeStyles, useTheme, withStyles  } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import './css/aplicaciones.css'
import './css/sharepoint.css'
import swal from 'sweetalert';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as AiIcons from 'react-icons/ai';
import * as MdIcons from 'react-icons/md';

import * as GrIcons from 'react-icons/gr';
import * as FiIcons from 'react-icons/fi';

import { triggerBase64Download } from 'react-base64-downloader';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
}));

const headCells = [
    { id: 'nombre', numeric: false, disablePadding: true, label: 'Name', size: '40%' },
    { id: 'usuario', numeric: false, disablePadding: true, label: 'User', size: '30%' },
    { id: 'fecha', numeric: false, disablePadding: true, label: 'Load Date', size: '20%' },
    { id: 'bajar', numeric: false, disablePadding: true, label: '', size: '5%' }, 
    { id: 'eliminar', numeric: false, disablePadding: true, label: '', size: '5%' }    
];

const ContainerBusqueda = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
`;

const ContainerTable = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

const ButtonEliminacion = styled.button`
    float:right;
    background-color: #EA7600;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 15px; 
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
    color: white;    
    height: 40px;

    :hover {
        background: #ffa549;
    }
`;


const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.white,
      
    },
    body: {
        textDecoration: 'none',
      fontSize: 12,
    },
}))(TableCell);

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const TablaDocumentos = ({rows, deletedocumento, updatedocumento, cerrarModalCarga, abrirModalCarga}) => {

    const [filter, setFilter] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('nombre');
    const classes = useStyles();

    const [selectedFile, setSelectedFile] = useState( {file:null, fileField: "", file64: null});
    const ref = React.useRef();

    const [nombrefile, setNombreFile] = useState("Select File ..")

    const handleFileInput = (e) => {
        // handle validations
        abrirModalCarga();
        const f = e.target.files[0];
        const ff = e.target.files;     
        
        if(e.target.files !== null && e.target.files.length !== 0){
            setNombreFile(e.target.files[0].name)

            var nombreFile = e.target.files[0].name;
            var arrayTipos = [".xlsx", ".pdf", ".pptx", ".docx"]

            var val = false;

            arrayTipos.forEach(t => {
                if(nombreFile.includes(t)) val = true;
            });

            if(val) setSelectedFile({file: f, fileField: ff, file64: null});
            else {
                swal("Type of File", "Only the following document types are supported: .xlsx, .pdf, .pptx, .docx", "warning");
                setNombreFile("Select File ..")
                setSelectedFile({file:null, fileField: ""});
                ref.current.value = "";
            }
        }         
        else
        {
            setNombreFile("Select File ..")
            setSelectedFile({file:null, fileField: ""});
            ref.current.value = "";
        }        
        cerrarModalCarga();
    }

    async function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            
        };
    }

    function cargarArchivo()
    {
        let Base64 = '';

        if(selectedFile.file === null)
        {
            swal ("You have not selected a file" ,
                    "",
                    "warning")
        }
        else {
            swal({
                title: "Upload confirmation",
                text: "Do you want to upload the selected file?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((afirmacion) => {
                if(afirmacion)
                {
                    getBase64(selectedFile.file, async(result) => {
                        Base64 = result;   
                        await updatedocumento(Base64, selectedFile.file.name).then(() => {
                            setSelectedFile({file:null, fileField: ""});
                            ref.current.value = "";
                            setNombreFile("Select File ..");
                        }); 
                    });
                }
                else {
                    setSelectedFile({file:null, fileField: ""});
                    ref.current.value = "";
                    setNombreFile("Select File ..");
                }
            });
        }
    }

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.nombre !== "") ? (row.nombre.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
            
            r = (row.usuario !== "") ? (row.usuario.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.fecha !== "") ? (row.fecha.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function stableSort(array) {
        if(order === 'desc')
        {
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] > b[orderBy]) ? 1 : (b[orderBy] > a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
        else{
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] < b[orderBy]) ? 1 : (b[orderBy] < a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
    }

    if(rows === null) return (<></>)
    else return (
        <>
            <ContainerBusqueda> 
                <br></br>
                        
                <TextField id="outlined-search" style={{float: 'left', backgroundColor: '#fff'}} label="Busqueda" size="small" type="search" variant="outlined" 
                    value={filter}                    
                    onChange={(e) => setFilter(e.target.value)}
                /> 

                <div className="divcompletoinput">
                    <div className="divinoputfile">
                        <label for="file-upload" class="custom-file-upload">
                            {nombrefile}
                        </label>
                        <input id="file-upload" ref={ref} onChange={handleFileInput} style={{display: 'none'}} type="file"/>
                    </div>
                    <div className="divbotonfile">
                        <ButtonEliminacion onClick={() => {cargarArchivo()}}>                            
                            <FiIcons.FiUpload />                             
                        </ButtonEliminacion>  
                    </div>
                                        
                </div>                           
                <br></br>                  
            </ContainerBusqueda>
            <ContainerTable>
            <TableContainer component={Paper}>
                <Table 
                    className={classes.table} 
                    size="small" 
                    aria-label="enhanced table"
                >
                    <TableHead style={{ background: '#696158', color: '#fff' }}>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <StyledTableCell 
                                    key={headCell.id}
                                    align={'center'}   
                                    sortDirection={orderBy === headCell.id ? order : false}
                                    style={{ width: headCell.size, color: '#fff !important' }}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                        style={{ background: '#696158', color: '#fff' }}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>                                        
                                </StyledTableCell>
                            ))}                                
                        </TableRow>
                    </TableHead>
                    <TableBody>  
                        {   stableSort(buscar(rows))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)                                    
                            .map((row) => (                                           
                                <TableRow hover key={row.nombre}  style={{ textDecoration: 'none', fontSize: '10px !important' }}>
                                    <StyledTableCell  align="left">{row.nombre}</StyledTableCell>
                                    <StyledTableCell  align="left">{row.usuario}</StyledTableCell>
                                    <StyledTableCell  align="center">{row.fecha}</StyledTableCell>                                                
                                    <StyledTableCell align="center">
                                        <button 
                                            onClick={() => {

                                                var anchor_href = "";
                                                if(row.nombre.includes(".xlsx")) anchor_href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + row.archivo;
                                                if(row.nombre.includes(".pdf")) anchor_href = "data:application/pdf;base64," + row.archivo;
                                                if(row.nombre.includes(".pptx")) anchor_href = "data:image/png;base64," + row.archivo;
                                                if(row.nombre.includes(".docx")) anchor_href = "data:text/plain;base64," + row.archivo;                                                
                                                
                                                const exportLinkElement = document.createElement('a');
                                                exportLinkElement.hidden = true;
                                                exportLinkElement.download = row.nombre;
                                                exportLinkElement.href = anchor_href;
                                                document.body.appendChild(exportLinkElement);
                                                exportLinkElement.click();
                                                exportLinkElement.remove();
                                            }}
                                            className="boton-observacion"
                                        >
                                            <FiIcons.FiDownload/>
                                        </button>    
                                    </StyledTableCell> 
                                    <StyledTableCell align="center">
                                        <button 
                                            onClick={() => {
                                                deletedocumento(row.id);
                                            }}
                                            className="boton-eliminar tooltipboton">
                                            <AiIcons.AiFillDelete/>
                                            <span className="tooltiptextboton">{"Delete"}</span> 
                                        </button>
                                    </StyledTableCell>                                           
                                </TableRow>
                            ))
                        }        
                    </TableBody>
                    <TableFooter>
                    </TableFooter>                           
                </Table>
                <Table>
                    <TableBody> 
                        <TableRow>
                            <TablePagination  style={{ display: 'flex'}}
                                rowsPerPageOptions={[5, 8]}
                                colSpan={3}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'Registros por pagina' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableBody> 
                </Table>
            </TableContainer>
                
            </ContainerTable> 
        </>
    )
}

export default TablaDocumentos
