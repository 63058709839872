import React, {Fragment, useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import * as Opciones from './OpcionesAplicacion'
import { crearAplicacion, obtenerValores} 
from '../functions/FuncAplicacion'

import "../css/formularioAplicacion.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import Loading from '../Loading';
import Select from 'react-select'
import { validarEmail } from '../functions/MetodosComunes';

import { style, styleNormal } from '../Commons/Styles'

const CreacionAplicacion = ({token}) => {

    const history = useHistory();
    const [registro, setRegistro] = useState(null);
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const [errorNombre, setErrorNombre] = useState(false);
    const [errorTipoSW, setErrorTipoSW] = useState(false);
    const [errorVersion, setErrorVersion] = useState(false);
    const [errorEstado, setErrorEstado] = useState(false);
    const [errorNegocio, setErrorNegocio] = useState(false);
    const [errorAmbiente, setErrorAmbiente] = useState(false);
    const [errorPais, setErrorPais] = useState(false);
    const [errorCriticidad, setErrorCriticidad] = useState(false);
    const [errorGerencia, serErrorGerencia] = useState(false);
    const [errorInformara, setErrorInformara] = useState(false);

    const [errorTipoLicencia, setErrorTipoLicencia] = useState(false);
    const [errorLenguaje, setErrorLenguaje] = useState(false);
    const [errorBaseDatos, setErrorBaseDatos] = useState(false);
    const [errorVersionBD, setErrorVersionBD] = useState(false);

    const [bd, setBD] = useState("");
    const [versionbd, setVersionBD] = useState(""); 
    
    let privilegio = sessionStorage.getItem('app_crear');
    let paisUser = sessionStorage.getItem('pais');
    let idiomaUser = sessionStorage.getItem('idioma');

    const cambioBaseDatos= (value) => { 
        if(value !== "" && value !== null){
            setBD(value.value);            
            filtrarVersiones(value.value);            
        } else {
            setBD(""); 
            setVersionBD("");           
        }
    }

    const cambioVersionBD= (value) => { 
        if(value !== "" && value !== null){
            setVersionBD({"label": value.value, "value": value.value});
        } else {
            setVersionBD("");
        }
    }

    function filtrarVersiones(basedato)
    {
        if(basedato !== "")
        {
            const filtrotipoArray = valores.filtroversionesbd;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.basedatos === basedato) {
                        array.push({"label": p.version, "value": p.version});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.versionesbasesdatos = array; 
            setVersionBD("");           
            setValores(valoresNuevo);
        }
    }

    async function obtenerDatosBD()
    {
        await obtenerValores("APPLICATION", idiomaUser,token)
        .then((response) => {            
            if(response.cod === 1)
            {
                const val = {
                    "negocios": response.negocios,
                    "fabricantes": response.fabricantes,
                    "ambientes": response.ambientes,
                    "paises": response.paises,
                    "lenguajes": response.lenguajes,
                    "estados": response.estados,
                    "tiposw": response.tiposaplicacion,
                    "tipolicencias": response.tiposlicencia,
                    "proveedores": response.proveedores,
                    "criticidades": response.criticidades,
                    "plataformas": response.plataformas,
                    "autenticaciones": response.autenticaciones,
                    "sistemasoperativos": response.sistemasoperativos,
                    "plataformaspublicacion": response.plataformaspublicacion,
                    "responsables": response.responsables,
                    "maestroapp": response.maestroapp,
                    "basesdatos": response.basesdatos,
                    "versionesbasesdatos": response.versionesbasesdatos,
                    "filtroversionesbd": response.filtroversionesbd,
                    "traducciones": response.traducciones    
                };

                let array = [];

                const listas = ["paises", "lenguajes", "ambientes", "tiposw", "negocios", "estados", "fabricantes", "proveedores", "tipolicencias"
                , "criticidades", "plataformas", "autenticaciones", "sistemasoperativos", "responsables", "basesdatos", "versionesbasesdatos", "plataformaspublicacion"];

                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                if(privilegio === "2")
                {
                    array = [];
                    val["paises"].map((u) => {                    
                        if(u.value === paisUser) array.push({"label": u.value, "value": u.value});
                    })
                    val["paises"] = array;
                }

                setValores(val);
            }
        });
    }

    function validarServidor(data)
    {
        let errors = {
            "error_type_01": "Mandatory data must be entered",
            "error_type_02": "Invalid email",
        }
        let error = "";

        if(data.nombre === null || data.nombre === "" || data.nombre === undefined) 
        {   
            setErrorNombre(true);
            error = errors["error_type_01"];
        }
        else setErrorNombre(false);

        if(data.tiposoftware === null || data.tiposoftware === "" || data.tiposoftware === undefined) 
        {   
            setErrorTipoSW(true);
            error = errors["error_type_01"];
        }
        else {

            setErrorTipoSW(false);

            if(data.tiposoftware.value === "INTERNAL"){

                if(data.lenguaje === null || data.lenguaje === "" || data.lenguaje === undefined) {
    
                    setErrorLenguaje(true);
                    error = errors["error_type_01"];
                }
                else setErrorLenguaje(false);
            }
            else setErrorLenguaje(false);

            if(data.tiposoftware.value === "INTERNAL" || data.tiposoftware.value === "EXTERNAL"){

                if(data.criticidad === null || data.criticidad === "" || data.criticidad === undefined) {
    
                    setErrorCriticidad(true);
                    error = errors["error_type_01"];
                }
                else setErrorCriticidad(false);

                if(data.gerencia === null || data.gerencia === "" || data.gerencia === undefined) {
    
                    serErrorGerencia(true);
                    error = errors["error_type_01"];
                }
                else serErrorGerencia(false);
            }
            else {
                setErrorCriticidad(false);
                serErrorGerencia(false);
            }
        }

        if(data.version === null || data.version === "") 
        {   
            setErrorVersion(true);
            error = errors["error_type_01"];
        }
        else setErrorVersion(false);

        if(data.estado === null || data.estado === "" || data.estado === undefined) 
        {   
            setErrorEstado(true);
            error = errors["error_type_01"];
        }
        else setErrorEstado(false);

        if(data.negocio === null || data.negocio === "" || data.negocio === undefined) 
        {   
            setErrorNegocio(true);
            error = errors["error_type_01"];
        }
        else setErrorNegocio(false);

        if(data.ambiente === null || data.ambiente === "" || data.ambiente === undefined) 
        {   
            setErrorAmbiente(true);
            error = errors["error_type_01"];
        }
        else setErrorAmbiente(false);

        if(data.pais === null || data.pais === "" || data.pais === undefined) 
        {   
            setErrorPais(true);
            error = errors["error_type_01"];
        }
        else setErrorPais(false);

        if(data.tipolicencia === null || data.tipolicencia === "" || data.tipolicencia === undefined) 
        {   
            setErrorTipoLicencia(true);
            error = errors["error_type_01"];
        }
        else setErrorTipoLicencia(false);

        if(data.basedatos === null || data.basedatos === "" || data.basedatos === undefined) 
        {   
            setErrorBaseDatos(true);
            error = errors["error_type_01"];
        }
        else setErrorBaseDatos(false);

        if(data.versionbd === null || data.versionbd === "" || data.versionbd === undefined) 
        {   
            setErrorVersionBD(true);
            error = errors["error_type_01"];
        }
        else setErrorVersionBD(false);

        if (data.vencimientocontrato != null){
            if (data.informara === null || data.informara === "") {
                setErrorInformara(true);
                error = errors["error_type_01"];
            } else setErrorInformara(false);
        } else setErrorInformara(false);

        if(data.informara.length > 0)
        {
            if(!validarEmail(data.informara))
            {
                setErrorInformara(true);
                error = errors["error_type_02"];
            }
            else setErrorInformara(false);
        }

        return error;
    }

    const onSubmit = (data) => {

        setRegistro(data);

        let errorMessage = validarServidor(data);

        if(errorMessage.length > 0)
        {
            swal("Form data", errorMessage, "warning");     
        }
        else{
            swal({
                title: "Creation confirmation",
                text: "Do you want to create the record?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion){  
                    const datosform = data;

                    let nombreusuario = sessionStorage.getItem('nombre');
                    datosform.modificadopor = nombreusuario;
                    datosform.fechaupdate = new Date();

                    datosform.software = datosform.nombre.value + " - " + datosform.version + " - " + datosform.ambiente.value;
                    datosform.nombre = datosform.nombre.value; 

                    console.log(datosform)
                    
                    await crearAplicacion(datosform, token).then((response) => {                        
                        if(response.cod === 1)
                        {
                            swal("Successful creation", "", "success").then(() => {
                                history.push("/aplicaciones/"+response.id);
                            });
                        }
                        else swal("Creation error", response.mensaje, "error");                        
                    });
                    
                }
            })
        }
    }

    React.useEffect(() => {
        if (valores !== null) {
            
        }
        else obtenerDatosBD();
    }, [])

    if(valores === null) return (<Loading />)
    else
    return (
        <>
            <form 
                style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                autoComplete="off" onSubmit={handleSubmit(onSubmit)}
            >
                <div className="divizq-eq">
                <div className="container">                    
                         <div className="row" style={{display: "none"}}>
                            <div className="col-25">
                                <label className="label-form" htmlFor="i-software">Software</label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input disabled='disabled' className="bootstrap-input" type='text'  />}                                      
                                    control={control}
                                    id="i-software"
                                    key="k-software"
                                    defaultValue={registro ? registro.software : ''}
                                    name='software'
                                /> 
                            </div>
                        </div>                        
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-nombre">Application<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.aplicacion === "" || valores.traducciones.aplicacion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.aplicacion}</span>
                                                </div>
                                            )
                                        }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<Select/>}
                                    name="nombre"
                                    options={valores.maestroapp}
                                    isClearable={true}
                                    placeholder=""
                                    defaultValue={registro ? registro.nombre : ''}
                                    control={control}
                                    styles={(errorNombre) ? style : styleNormal}
                                />                                
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-version">Version<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.version === "" || valores.traducciones.version === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.version}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input className="bootstrap-input" type='text'  />}  
                                    style={(errorVersion) ? { borderColor: '#f90000'} : {}}                                    
                                    control={control}
                                    id="i-version"
                                    key="k-version"
                                    defaultValue={registro ? registro.version : ''}
                                    name='version'
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-comentario">Comments
                                {
                                    (valores.traducciones.comentario === "" || valores.traducciones.comentario === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.comentario}</span>
                                        </div>
                                    )
                                }
                                </label>
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<textarea className="bootstrap-input" rows="2" cols="50"></textarea>}
                                    control={control}
                                    id="i-comentario"
                                    key="k-comentario"                                
                                    name='comentario'
                                    defaultValue={registro ? registro.comentario : ''}
                                    
                                />                             
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-ambiente">Environment<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.ambiente === "" || valores.traducciones.ambiente === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.ambiente}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.ambientes}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={true} 
                                        styles={(errorAmbiente) ? style : styleNormal}
                                        control={control}
                                    />} 
                                    control={control} 
                                    id="i-ambiente"
                                    key="k-ambiente"                                                
                                    name='ambiente'
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}}  htmlFor="i-tiposoftware">Type of Application<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.tiposoftware === "" || valores.traducciones.tiposoftware === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.tiposoftware}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.tiposw}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={true} 
                                        styles={(errorTipoSW) ? style : styleNormal}
                                        control={control}
                                    />} 
                                    control={control} 
                                    id="i-tiposoftware"
                                    key="k-tiposoftware"                                                
                                    name='tiposoftware'
                                /> 
                            </div>
                        </div> 
                        
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-estado">State<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.estado === "" || valores.traducciones.estado === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.estado}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.estados}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={true} 
                                        styles={(errorEstado) ? style : styleNormal}
                                        control={control}
                                    />} 
                                    control={control} 
                                    id="i-estado"
                                    key="k-estado"                                                
                                    name='estado'
                                />
                            </div>
                        </div> 
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-pais">Country<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.pais === "" || valores.traducciones.pais === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.pais}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.paises}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={true} 
                                        styles={(errorPais) ? style : styleNormal}
                                        control={control}
                                    />} 
                                    control={control} 
                                    id="i-pais"
                                    key="k-pais"                                                
                                    name='pais'
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-negocio">Business<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.negocio === "" || valores.traducciones.negocio === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.negocio}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.negocios}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={true} 
                                        styles={(errorNegocio) ? style : styleNormal}
                                        control={control}
                                    />} 
                                    control={control} 
                                    id="i-negocio"
                                    key="k-negocio"                                                
                                    name='negocio'
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-responsable">Responsable
                                {
                                    (valores.traducciones.responsable === "" || valores.traducciones.responsable === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.responsable}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.responsables}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value)
                                        
                                    }}  
                                    isClearable={true}
                                    styles={styleNormal} 
                                    />} 
                                    control={control}
                                    id="i-responsable"
                                    key="k-responsable"                                    
                                    name='responsable'
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-gerencia">Owner
                                {
                                    (valores.traducciones.gerencia === "" || valores.traducciones.gerencia === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.gerencia}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                    control={control}
                                    id="i-gerencia"
                                    key="k-gerencia"
                                    defaultValue={registro ? registro.gerencia : ''}
                                    style={(errorGerencia) ? { borderColor: '#f90000'} : {}} 
                                    name='gerencia'
                                /> 
                            </div>
                        </div>  
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-sistemaoperativo">Operating System
                                {
                                    (valores.traducciones.sistemaoperativo === "" || valores.traducciones.sistemaoperativo === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.sistemaoperativo}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.sistemasoperativos}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value)
                                        
                                    }}  
                                    isClearable={true}
                                    styles={styleNormal} 
                                    />} 
                                    control={control}
                                    id="i-sistemaoperativo"
                                    key="k-sistemaoperativo"                                    
                                    name='sistemaoperativo'
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-plataforma">Client Platform
                                {
                                    (valores.traducciones.plataforma === "" || valores.traducciones.plataforma === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.plataforma}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.plataformas}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value)
                                        
                                    }}  
                                    isClearable={true}
                                    styles={styleNormal}
                                    />} 
                                    control={control}
                                    id="i-plataforma"
                                    key="k-plataforma"                                    
                                    name='plataforma'
                                />                              
                            </div>
                        </div> 
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-plataformapublicacion">Publishing Platform
                                {
                                    (valores.traducciones.plataformapublicacion === "" || valores.traducciones.plataformapublicacion === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.plataformapublicacion}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.plataformaspublicacion}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value)
                                        
                                    }}  
                                    isClearable={true}
                                    styles={styleNormal}
                                    />} 
                                    control={control}
                                    id="i-plataformapublicacion"
                                    key="k-plataformapublicacion"                                    
                                    name='plataformapublicacion'
                                />                              
                            </div>
                        </div>                        
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-criticidad">Criticality
                                {
                                    (valores.traducciones.criticidad === "" || valores.traducciones.criticidad === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.criticidad}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.criticidades}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value)
                                        
                                    }}  
                                    isClearable={true}
                                    styles={(errorCriticidad) ? style : styleNormal}
                                    />} 
                                    control={control}
                                    id="i-criticidad"
                                    key="k-criticidad"                                    
                                    name='criticidad'
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-fabricante">Fabricator
                                {
                                    (valores.traducciones.fabricante === "" || valores.traducciones.fabricante === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.fabricante}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.fabricantes}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={true} 
                                        styles={styleNormal}
                                        control={control}
                                    />} 
                                    control={control} 
                                    id="i-fabricante"
                                    key="k-fabricante"                                                
                                    name='fabricante'
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-proveedor">Provider
                                {
                                    (valores.traducciones.proveedor === "" || valores.traducciones.proveedor === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.proveedor}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.proveedores}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={true} 
                                        styles={styleNormal}
                                        control={control}
                                    />} 
                                    control={control} 
                                    id="i-proveedor"
                                    key="k-proveedor"                                                
                                    name='proveedor'
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-lenguaje">Language
                                {
                                    (valores.traducciones.lenguaje === "" || valores.traducciones.lenguaje === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.lenguaje}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.lenguajes}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={true} 
                                        styles={(errorLenguaje) ? style : styleNormal}
                                        control={control}
                                    />} 
                                    control={control} 
                                    id="i-lenguaje"
                                    key="k-lenguaje"                                                
                                    name='lenguaje'
                                /> 
                            </div>
                        </div> 
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-link">Link
                                {
                                    (valores.traducciones.link === "" || valores.traducciones.link === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.link}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                    control={control}
                                    id="i-link"
                                    key="k-link"
                                    defaultValue={registro ? registro.link : ''}
                                    name='link'
                                /> 
                            </div>
                        </div> 
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-waf">WAF
                                {
                                    (valores.traducciones.waf === "" || valores.traducciones.waf === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.waf}</span>
                                        </div>
                                    )
                                }
                                </label>
                            </div>
                            <div className="col-75">
                                <input
                                    type="checkbox"
                                    id="i-waf"
                                    key="k-waf" 
                                    name="waf"
                                    placeholder="luo"
                                    value={true}
                                    ref={register}
                                />
                            </div>
                        </div>   
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-observacionwaf">Observations WAF
                                {
                                    (valores.traducciones.observacionwaf === "" || valores.traducciones.observacionwaf === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.observacionwaf}</span>
                                        </div>
                                    )
                                }
                                </label>
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<textarea className="bootstrap-input" rows="5" cols="50"></textarea>}
                                    control={control}
                                    id="i-observacionwaf"
                                    key="k-observacionwaf"
                                    defaultValue={registro ? registro.observacionwaf : ''}
                                    name='observacionwaf'
                                />                             
                            </div>
                        </div>                   
                    </div>
                </div>
                <div className="divder-eq">  
                    <div className="container">
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-observaciones">Prerequisites and Observations
                                {
                                    (valores.traducciones.observaciones === "" || valores.traducciones.observaciones === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.observaciones}</span>
                                        </div>
                                    )
                                }
                                </label>
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<textarea className="bootstrap-input" rows="5" cols="50"></textarea>}
                                    control={control}
                                    id="i-observaciones"
                                    key="k-observaciones"
                                    defaultValue={registro ? registro.observaciones : ''}
                                    name='observaciones'
                                />                             
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-25">
                                <label  htmlFor="i-autenticacion">Authentication Mechanism
                                {
                                    (valores.traducciones.autenticacion === "" || valores.traducciones.autenticacion === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.autenticacion}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.autenticaciones}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={true} 
                                        styles={styleNormal}
                                        control={control}
                                    />} 
                                    control={control} 
                                    id="i-autenticacion"
                                    key="k-autenticacion"                                                
                                    name='autenticacion'
                                /> 
                            </div>
                        </div> 
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-centralizada">Centralized / Distributed
                                {
                                    (valores.traducciones.centralizada === "" || valores.traducciones.centralizada === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.centralizada}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                    control={control}
                                    id="i-centralizada"
                                    key="k-centralizada"
                                    defaultValue={registro ? registro.centralizada : ''}
                                    name='centralizada'                                    
                                />                              
                            </div>
                        </div> 
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-procedimientoadmin">Administration Procedure
                                {
                                    (valores.traducciones.procedimientoadmin === "" || valores.traducciones.procedimientoadmin === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.procedimientoadmin}</span>
                                        </div>
                                    )
                                }
                                </label>
                            </div>
                            <div className="col-75">
                                <input
                                    type="checkbox"
                                    id="i-procedimientoadmin"
                                    key="k-procedimientoadmin" 
                                    name="procedimientoadmin"
                                    placeholder="luo"
                                    value={true}
                                    ref={register}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-redundancia">Redundancy
                                {
                                    (valores.traducciones.redundancia === "" || valores.traducciones.redundancia === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.redundancia}</span>
                                        </div>
                                    )
                                }
                                </label>
                            </div>
                            <div className="col-75">
                                <input
                                    type="checkbox"
                                    id="i-redundancia"
                                    key="k-redundancia" 
                                    name="redundancia"
                                    placeholder="luo"
                                    value={true}
                                    ref={register}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-adminacceso">Access Manager
                                {
                                    (valores.traducciones.adminacceso === "" || valores.traducciones.adminacceso === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.adminacceso}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                    control={control}
                                    id="i-adminacceso"
                                    key="k-adminacceso"
                                    defaultValue={registro ? registro.adminacceso : ''}
                                    name='adminacceso'                                    
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="rowdoble-izq">
                                <div className="col-75">
                                    <label htmlFor="i-drp">DRP
                                    {
                                        (valores.traducciones.drp === "" || valores.traducciones.drp === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.drp}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="col-25">
                                    <input
                                        type="checkbox"
                                        id="i-drp"
                                        key="k-drp" 
                                        name="drp"
                                        placeholder="luo"
                                        value={true}
                                        ref={register}
                                        style={{float: 'right'}}
                                    />
                                </div>
                            </div>
                            <div className="rowdoble-der">
                                <div className="fechaapp-izq">
                                    <label htmlFor="i-fechadrp">DRP Test Date (YYYY/MM/DD)
                                    {
                                        (valores.traducciones.fechadrp === "" || valores.traducciones.fechadrp === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.fechadrp}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="fechaapp-der">
                                    <Controller
                                        name="fechadrp"
                                        id="i-fechadrp"
                                        className="bootstrap-input"
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <DatePicker
                                                disabled={(privilegio < 2) ? 'disabled' : ''}
                                                selected={value}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                onChange={onChange}
                                                className="bootstrap-input"                                            
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="rowdoble-izq">
                                <div className="col-75">
                                    <label htmlFor="i-respaldos">Backrests
                                    {
                                        (valores.traducciones.respaldos === "" || valores.traducciones.respaldos === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.respaldos}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="col-25">
                                    <input
                                        type="checkbox"
                                        id="i-respaldos"
                                        key="k-respaldos" 
                                        name="respaldos"
                                        placeholder="luo"
                                        value={true}
                                        ref={register}
                                        style={{float: 'right'}}
                                    />
                                </div>
                            </div>
                            <div className="rowdoble-der">
                                <div className="fechaapp-izq">
                                    <label htmlFor="i-fecharespaldos">Backrests Test Date (YYYY/MM/DD)
                                    {
                                        (valores.traducciones.fecharespaldos === "" || valores.traducciones.fecharespaldos === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.fecharespaldos}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="fechaapp-der">
                                    <Controller
                                        name="fecharespaldos"
                                        id="i-fecharespaldos"
                                        className="bootstrap-input"
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <DatePicker
                                                disabled={(privilegio < 2) ? 'disabled' : ''}
                                                selected={value}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                onChange={onChange}
                                                className="bootstrap-input" 
                                                                                           
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>
                        </div>                       
                        
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-basedatos">Database<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.basedatos === "" || valores.traducciones.basedatos === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.basedatos}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.basesdatos}
                                        onChange={(value) => {
                                            props.onChange(value); 
                                            cambioBaseDatos(value);                                                       
                                        }}
                                        placeholder=""
                                        isClearable={true} 
                                        styles={(errorBaseDatos) ? style : styleNormal}
                                        control={control}
                                    />} 
                                    control={control} 
                                    id="i-basedatos"
                                    key="k-basedatos"                                                
                                    name='basedatos'
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-versionbd">DB Version<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.versionbd === "" || valores.traducciones.versionbd === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.versionbd}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.versionesbasesdatos}
                                        onChange={(value) => {
                                            props.onChange(value); 
                                            cambioVersionBD(value);                                                       
                                        }}
                                        placeholder=""
                                        value={versionbd}
                                        isClearable={true} 
                                        isDisabled={(bd !== "") ? false : true}
                                        styles={(errorVersionBD) ? style : styleNormal}
                                        control={control}
                                    />} 
                                    control={control} 
                                    id="i-versionbd"
                                    key="k-versionbd"                                                
                                    name='versionbd'
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-numerocontrato">Contract number
                                {
                                    (valores.traducciones.numerocontrato === "" || valores.traducciones.numerocontrato === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.numerocontrato}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                    control={control}
                                    id="i-numerocontrato"
                                    key="k-numerocontrato"
                                    defaultValue={registro ? registro.numerocontrato : ''}
                                    name='numerocontrato'
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-tipolicencia">Type of license<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.tipolicencia === "" || valores.traducciones.tipolicencia === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.tipolicencia}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.tipolicencias}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={true} 
                                        styles={(errorTipoLicencia) ? style : styleNormal}
                                        control={control}
                                    />} 
                                    control={control} 
                                    id="i-tipolicencia"
                                    key="k-tipolicencia"                                                
                                    name='tipolicencia'
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-fechafactura">Invoice date (YYYY/MM/DD)
                                {
                                    (valores.traducciones.fechafactura === "" || valores.traducciones.fechafactura === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.fechafactura}</span>
                                        </div>
                                    )
                                }
                                </label>
                            </div>
                            <div className="col-75">
                                <Controller
                                    name="fechafactura"
                                    id="i-fechafactura"
                                    className="bootstrap-input"
                                    control={control}
                                    render={({ onChange, value }) => (
                                        <DatePicker
                                            disabled={(privilegio < 2) ? 'disabled' : ''}
                                            selected={value}
                                            dateFormat="yyyy/MM/dd"
                                            autoComplete="off"
                                            onChange={onChange}
                                            className="bootstrap-input"                                            
                                        />
                                    )}
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-vencimientocontrato">Expiration date (YYYY/MM/DD)
                                {
                                    (valores.traducciones.vencimientocontrato === "" || valores.traducciones.vencimientocontrato === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.vencimientocontrato}</span>
                                        </div>
                                    )
                                }
                                </label>
                            </div>
                            <div className="col-75">
                                <Controller
                                    name="vencimientocontrato"
                                    id="i-vencimientocontrato"
                                    className="bootstrap-input"
                                    control={control}
                                    render={({ onChange, value }) => (
                                        <DatePicker
                                            disabled={(privilegio < 2) ? 'disabled' : ''}
                                            selected={value}
                                            autoComplete="off"
                                            dateFormat="yyyy/MM/dd"
                                            onChange={onChange}
                                            className="bootstrap-input"
                                        />
                                    )}
                                /> 
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-25">                
                                <label htmlFor="i-informara">
                                    Inform To<label style={{ color: "red" }}>{"*"}</label>
                                </label>
                            </div>
                            <div className="col-75">
                                <Controller
                                as={<input className="bootstrap-input" type="text" />}
                                style={errorInformara ? { borderColor: "#f90000" } : {}}
                                control={control}
                                id="i-informara"
                                key="k-informara"
                                defaultValue={registro ? registro.informara : ""}   
                                name="informara"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-numerofactura">Purchase invoice number
                                {
                                    (valores.traducciones.numerofactura === "" || valores.traducciones.numerofactura === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.numerofactura}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                    control={control}
                                    id="i-numerofactura"
                                    key="k-numerofactura"
                                    defaultValue={registro ? registro.numerofactura : ''}
                                    name='numerofactura'
                                /> 
                            </div>
                        </div> 
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-costoanual">Annual Cost US$
                                    {
                                        (valores.traducciones.costoanual === "" || valores.traducciones.costoanual === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.costoanual}</span>
                                            </div>
                                        )
                                    }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                    control={control}
                                    id="i-costoanual"
                                    key="k-costoanual"
                                    defaultValue={registro ? registro.costoanual : ''}
                                    name='costoanual'
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-numerolicencia">Number of Licenses
                                {
                                    (valores.traducciones.numerolicencia === "" || valores.traducciones.numerolicencia === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.numerolicencia}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input disabled={(privilegio < 2) ? 'disabled' : ''} className="bootstrap-input" type='number'  
                                    
                                    />}                                      
                                    control={control}
                                    id="i-numerolicencia"
                                    key="k-numerolicencia"
                                    defaultValue={registro ? registro.numerolicencia : ''}
                                    name='numerolicencia'
                                /> 
                            </div>
                        </div>      
                    </div>
                </div>  

                <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px', float: 'left'}}>  
                    <div style={{width: '20%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px'}}>                
                        <button className="boton-from">Save</button>   
                        <br></br>
                        <br></br>
                        <br></br>              
                    </div>
                </div>
            </form>
        </>
    )
}

export default CreacionAplicacion
