import React from 'react'
import  Sidebar  from '../Sidebar'
import CreacionDispositivoOT from './CreacionDispositivoOT';

import { useHistory } from "react-router-dom";


import { rolInvalido } from '../Commons/Functions'


import FormCreate from '../Commons/FormCreate'

const FormCreateDispositivoOT = ({instance, t}) => {

    let priv = sessionStorage.getItem('ser_ot_crear');
    const history = useHistory();

    function volver()
    {
        history.push("/dispositivosot");
    }

    React.useEffect(() => {        
        if(priv === null || priv === undefined || priv === "0") rolInvalido(history);  
    });
    
    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <>
            <Sidebar instance={instance} despliegue={false}/>
            <FormCreate
                volverUrlFunction = { volver }
                componenteFormName = { "Device OT" }
                creacionComponent = { <CreacionDispositivoOT token= { t } /> }
            />
        </>
    )
}

export default FormCreateDispositivoOT
