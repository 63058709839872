import React, {useState} from 'react'
import * as Opciones from './OpcionesAplicacion'
import { useForm, Controller } from "react-hook-form";
import { obtenerAplicacion, obtenerValores, actualizarAplicacion, obtenerServidores, obtenerEquipos, obtenerCuentas, obtenerIntegraciones, obtenerCertificados} from '../functions/FuncAplicacion'
import { registrarBitacoraAplicacion } from "../functions/FuncBitacora"
import "../css/formularioAplicacion.css";
import "../css/informaciones.css";
import DatePicker from "react-datepicker";
import swal from 'sweetalert';
import Loading from '../Loading';
import Select from 'react-select'
import TablaRelacionServidor from './TablaRelacionServidor';
import TablaRelacionEquipo from './TablaRelacionEquipo';
import TablaCuentas from './TablaCuentas';
import TablaRelacionIntegracion from './TablaRelacionIntegracion';
import TablaCertificados from './TablaCertificados';
import { validarEmail } from '../functions/MetodosComunes';

import { validarPrivilegioModificacion } from '../Commons/Functions'
import { style, styleNormal, styleDisable } from '../Commons/Styles'


const FormularioAplicacion = ({id, token}) => {

    const [registro, setRegistro] = useState(null);
    const [servidores, setServidores] = useState(null);
    const [equipos, setEquipos] = useState(null);
    const [cuentas, setCuentas] = useState(null);
    const [certificados, setCertificados] = useState(null);
    const [integraciones, setIntegraciones] = useState(null);
    const [maestroIntegraciones, setMaestroIntegraciones] = useState(null);
    const [privModificar, setPrivModificar] = React.useState(false);

    const [nombreestado, setNombreEstado] = useState("");
    const [valores, setValores] = useState(null);
    const [errorNombre, setErrorNombre] = useState(false);
    const [errorTipoSW, setErrorTipoSW] = useState(false);
    const [errorVersion, setErrorVersion] = useState(false);
    const [errorEstado, setErrorEstado] = useState(false);
    const [errorNegocio, setErrorNegocio] = useState(false);
    const [errorAmbiente, setErrorAmbiente] = useState(false);
    const [errorFactura, setErrorFactura] = useState(false);
    const [errorPais, setErrorPais] = useState(false);
    const [errorTipoLicencia, setErrorTipoLicencia] = useState(false);
    const [errorLenguaje, setErrorLenguaje] = useState(false);
    const [errorBaseDatos, setErrorBaseDatos] = useState(false);
    const [errorVersionBD, setErrorVersionBD] = useState(false);
    const [errorCriticidad, setErrorCriticidad] = useState(false);
    const [errorGerencia, serErrorGerencia] = useState(false);
    const [errorInformara, setErrorInformara] = useState(false);
    const { handleSubmit, control, register, setValue, errors} = useForm({mode: 'onBlur'});

    const [waf, setCambioWaf] = useState(null);
    const [cambioDrp, setCambioDrp] = useState(null);
    const [cambioAdminPro, setCambioAdminPro] = useState(null);
    const [cambioRedundancia, setCambioRedundancia] = useState(null);
    const [cambioRespaldo, setCambioRespaldo] = useState(null);

    

    const [bd, setBD] = useState("");
    const [versionbd, setVersionBD] = useState(""); 

    let privEscribir = sessionStorage.getItem('app_escribir');
    let privilegioLeer = sessionStorage.getItem('app_leer');
    let paisUser = sessionStorage.getItem('pais');
    let idiomaUser = sessionStorage.getItem('idioma');

    const eCambioWaf = (e) => {
        setCambioWaf(e.target.checked)
        console.log(e.target.checked)
    }

    const eCambioDrp = (e) => {
        setCambioDrp(e.target.checked)
        console.log(e.target.checked)
    }

    const eCambioAdminPro = (e) => {
        setCambioAdminPro(e.target.checked)
        console.log(e.target.checked)
    }

    const eCambioRedundancia = (e) => {
        setCambioRedundancia(e.target.checked)
        console.log(e.target.checked)
    }

    const eCambioRespaldo = (e) => {
        setCambioRespaldo(e.target.checked)
        console.log(e.target.checked)
    }

    const cambioBaseDatos= (value) => { 
        if(value !== "" && value !== null){
            setBD({"label": value.value, "value": value.value});            
            filtrarVersiones(value.value);            
        } else {
            setBD(""); 
            setVersionBD("");           
        }
    }

    const cambioVersionBD= (value) => { 
        if(value !== "" && value !== null){
            setVersionBD({"label": value.value, "value": value.value});
        } else {
            setVersionBD("");
        }
    }

    function filtrarVersiones(basedato)
    {
        if(basedato !== "")
        {
            const filtrotipoArray = valores.filtroversionesbd;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.basedatos === basedato) {
                        array.push({"label": p.version, "value": p.version});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.versionesbasesdatos = array; 
            setVersionBD("");           
            setValores(valoresNuevo);
        }
    }

    async function actualizarServidores()
    {
        await obtenerServidores(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setServidores(response.servidores);
            }
        });
    }

    async function actualizarEquipos()
    {
        await obtenerEquipos(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setEquipos(response.equipos);
            }
        });
    }

    async function actualizarIntegraciones()
    {
        await obtenerIntegraciones(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setIntegraciones(response.integraciones);
                filtrarIntegraciones(response.integraciones, valores.integraciones);
            }
        });
    }

    function filtrarIntegraciones(relacionados, maestros)
    {
        relacionados.forEach((r) => {
            maestros = maestros.filter((m) => {
                if(m.nombre === r.nombre) return false;
                else return true;
            })
        })

        setMaestroIntegraciones(maestros)
    }

    function mapResponseToRegistro(response){
        setRegistro({
            nombre: {"value": response.aplicacion.nombre, "label": response.aplicacion.nombre}, 
            tiposoftware: {"label": response.aplicacion.tiposoftware, "value": response.aplicacion.tiposoftware}, 
            localidad: response.aplicacion.localidad,
            software: response.aplicacion.software,
            version: response.aplicacion.version,
            comentario: response.aplicacion.comentario,
            estado: {"label": response.aplicacion.estado, "value": response.aplicacion.estado},
            negocio: {"label": response.aplicacion.negocio, "value": response.aplicacion.negocio},
            ambiente: {"label": response.aplicacion.ambiente, "value": response.aplicacion.ambiente},
            gerencia: response.aplicacion.gerencia,
            observaciones: response.aplicacion.observaciones,
            link: response.aplicacion.link,
            waf:(response.aplicacion.waf !== null) ? (response.aplicacion.waf === "YES") ? true : false : false,
            observacionwaf: response.aplicacion.observacionwaf,
            numerocontrato: response.aplicacion.numerocontrato,
            tipolicencia: (response.aplicacion.tipolicencia !== null && response.aplicacion.tipolicencia !== "") ? {"label": response.aplicacion.tipolicencia, "value": response.aplicacion.tipolicencia} : null,
            numerofactura: response.aplicacion.numerofactura,
            costoanual: response.aplicacion.costoanual,               
            numerolicencia: response.aplicacion.numerolicencia,
            fabricante: {"label": response.aplicacion.fabricante, "value": response.aplicacion.fabricante},
            proveedor: (response.aplicacion.proveedor !== null && response.aplicacion.proveedor !== "") ? {"label": response.aplicacion.proveedor, "value": response.aplicacion.proveedor} : null,
            modificadopor: response.aplicacion.modificadopor,
            basedatos:  (response.aplicacion.basedatos !== "" && response.aplicacion.basedatos !== null) ? {"label": response.aplicacion.basedatos, "value": response.aplicacion.basedatos} : null,
            versionbd:  (response.aplicacion.versionbd !== "" && response.aplicacion.versionbd !== null) ? {"label": response.aplicacion.versionbd, "value": response.aplicacion.versionbd} : null,
            pais: (response.aplicacion.pais !== null && response.aplicacion.pais !== "") ? {"label": response.aplicacion.pais, "value": response.aplicacion.pais} : null,
            lenguaje: (response.aplicacion.lenguaje !== null && response.aplicacion.lenguaje !== "") ? {"label": response.aplicacion.lenguaje, "value": response.aplicacion.lenguaje} : null,

            criticidad: (response.aplicacion.criticidad !== "") ? {"label": response.aplicacion.criticidad, "value": response.aplicacion.criticidad} : null,
            plataformapublicacion: (response.aplicacion.plataformapublicacion !== "") ? {"label": response.aplicacion.plataformapublicacion, "value": response.aplicacion.plataformapublicacion} : null,
            plataforma: (response.aplicacion.plataforma !== "") ? {"label": response.aplicacion.plataforma, "value": response.aplicacion.plataforma} : null,
            responsable: (response.aplicacion.responsable !== "") ? {"label": response.aplicacion.responsable, "value": response.aplicacion.responsable} : null,
            sistemaoperativo: (response.aplicacion.sistemaoperativo !== "") ? {"label": response.aplicacion.sistemaoperativo, "value": response.aplicacion.sistemaoperativo} : null,
            autenticacion: (response.aplicacion.autenticacion !== "") ? {"label": response.aplicacion.autenticacion, "value": response.aplicacion.autenticacion} : null,

            adminacceso: response.aplicacion.adminacceso,
            centralizada: response.aplicacion.centralizada,

            redundancia:(response.aplicacion.redundancia !== null) ? (response.aplicacion.redundancia === "YES") ? true : false : false,
            procedimientoadmin:(response.aplicacion.procedimientoadmin !== null) ? (response.aplicacion.procedimientoadmin === "YES") ? true : false : false,
            respaldos:(response.aplicacion.respaldos !== null) ? (response.aplicacion.respaldos === "YES") ? true : false : false,
            drp:(response.aplicacion.drp !== null) ? (response.aplicacion.drp === "YES") ? true : false : false,

            fechadrp: (response.aplicacion.fechadrp !== null) ? new Date(response.aplicacion.fechadrp) : null,
            fecharespaldos: (response.aplicacion.fecharespaldos !== null) ? new Date(response.aplicacion.fecharespaldos) : null,

            fechaupdate: (response.aplicacion.fechaupdate !== null) ? new Date(response.aplicacion.fechaupdate) : null,
            fechafactura: (response.aplicacion.fechafactura !== null) ? new Date(response.aplicacion.fechafactura) : null,
            vencimientocontrato: (response.aplicacion.vencimientocontrato !== null) ? new Date(response.aplicacion.vencimientocontrato) : null,
            informara: response.aplicacion.informara,
            cantidadservidores: response.aplicacion.cantidadservidores
        });
   
    }


    async function obtenerDatosBD()
    {
        var paisRegistro = "";
        var bdRegistro = "";
        var relacionIntegraciones = null;

        await obtenerServidores(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setServidores(response.servidores);
            }
        });

        await obtenerEquipos(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setEquipos(response.equipos);
            }
        });

        await obtenerCuentas(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setCuentas(response.cuentas);
            }
        });

        await obtenerCertificados(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setCertificados(response.certificados);
            }
        });

        await obtenerIntegraciones(id, token)
        .then((response) =>{
            if(response.cod === 1){
                relacionIntegraciones = response.integraciones;
                setIntegraciones(response.integraciones);
            }
        });

        await obtenerAplicacion(id, token)
        .then((response) => {

            if(response.cod === 1)
            {
                paisRegistro = response.aplicacion.pais;
                validarPrivilegioModificacion(privEscribir, paisUser, "", "", response.aplicacion, setPrivModificar);

                bdRegistro = response.aplicacion.basedatos;
                setBD((response.aplicacion.basedatos !== "" && response.aplicacion.basedatos !== null) ? {"label": response.aplicacion.basedatos, "value": response.aplicacion.basedatos} : null);
                setVersionBD((response.aplicacion.versionbd !== "" && response.aplicacion.versionbd !== null) ? {"label": response.aplicacion.versionbd, "value": response.aplicacion.versionbd} : null);

                mapResponseToRegistro(response);

                console.log(response.aplicacion.cantidadservidores)
                console.log(response.aplicacion.tipolicencia)

                if(response.aplicacion.cantidadservidores === "0" && response.aplicacion.tipolicencia !== "SAAS"){
                    //swal("Please remember that at least one server must be associated with the application");
                    console.log("entre")
                    swal("Information", "Please remember that at least one server must be associated with the application", "info")
                }
            }
        });

        await obtenerValores("APPLICATION", idiomaUser, token)
        .then((response) => {

            if(response.cod === 1)
            {
                const val = {
                    "negocios": response.negocios,
                    "fabricantes": response.fabricantes,
                    "ambientes": response.ambientes,
                    "paises": response.paises,
                    "lenguajes": response.lenguajes,
                    "estados": response.estados,
                    "tiposw": response.tiposaplicacion,
                    "tipolicencias": response.tiposlicencia,
                    "proveedores": response.proveedores,
                    "criticidades": response.criticidades,
                    "plataformas": response.plataformas,
                    "autenticaciones": response.autenticaciones,
                    "sistemasoperativos": response.sistemasoperativos,
                    "plataformaspublicacion": response.plataformaspublicacion,
                    "responsables": response.responsables,
                    "maestroapp": response.maestroapp,
                    "basesdatos": response.basesdatos,
                    "versionesbasesdatos": response.versionesbasesdatos,
                    "filtroversionesbd": response.filtroversionesbd,
                    "integraciones": response.integraciones,
                    "traducciones": response.traducciones   
                };

                let array = [];

                const listas = ["paises", "lenguajes", "ambientes", "tiposw", "negocios", "estados", "fabricantes", "proveedores", "tipolicencias"
                , "criticidades", "plataformas", "autenticaciones", "sistemasoperativos", "responsables", "basesdatos", "versionesbasesdatos", "plataformaspublicacion"];

                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                //Integraciones
                filtrarIntegraciones(relacionIntegraciones, val.integraciones);  

                //Versiones
                array = [];
                val.filtroversionesbd.map((u) => {
                    if(u.basedatos === bdRegistro) array.push({"label": u.version, "value": u.version});
                })
                val.versionesbasesdatos = array;
                
                if(privEscribir === "2" && privilegioLeer === "2")
                {
                    array = [];
                    val["paises"].map((u) => {                    
                        if(u.value === paisUser) array.push({"label": u.value, "value": u.value});
                    })
                    val["paises"] = array;
                }
                
                if(privEscribir === "2" && privilegioLeer === "3")
                {
                    array = [];
                    val["paises"].map((u) => {                    
                        if(u.value === paisUser && paisRegistro === paisUser) array.push({"label": u.value, "value": u.value});
                    })
                    val["paises"] = array;
                }

                setValores(val);
            }
        });
        
    }

    async function obtenerAplicaciopnBD()
    {
        await obtenerAplicacion(id, token)
        .then((response) => {

            if(response.cod === 1)
            {
                setBD({"label": response.aplicacion.basedatos, "value": response.aplicacion.basedatos});
                setVersionBD({"label": response.aplicacion.versionbd, "value": response.aplicacion.versionbd});

                mapResponseToRegistro(response);
            }
        });
    }

    async function registrarBitacora(datosActuales, dataAnterior)
    {
        let nombreusuario = sessionStorage.getItem('nombre');

        datosActuales.drp = cambioDrp;
        datosActuales.procedimientoadmin = cambioAdminPro;
        datosActuales.redundancia = cambioRedundancia;
        datosActuales.respaldos = cambioRespaldo;
        datosActuales.basedatos = bd;

        registrarBitacoraAplicacion(datosActuales, dataAnterior, id, nombreusuario, token)
        .then((response) => {
            console.log(response)
        })
    }

    async function actualizar(datosActuales, dataAnterior)
    {
        const datosform = datosActuales;

        let nombreusuario = sessionStorage.getItem('nombre');
        datosform.modificadopor = nombreusuario;
        datosform.fechaupdate = new Date();
        
        if(datosform.software !== null) datosform.software = datosform.software.toUpperCase();
        if(datosform.version !== null) datosform.version = datosform.version.toUpperCase();
        if(datosform.gerencia !== null) datosform.gerencia = datosform.gerencia.toUpperCase();
        if(datosform.numerocontrato !== null) datosform.numerocontrato = datosform.numerocontrato.toUpperCase();
        if(datosform.numerofactura !== null) datosform.numerofactura = datosform.numerofactura.toUpperCase();
        if(datosform.costoanual !== null) datosform.costoanual = datosform.costoanual.toUpperCase();
        if(datosform.numerolicencia !== null) datosform.numerolicencia = datosform.numerolicencia.toUpperCase();
        if(datosform.observaciones !== null) datosform.observaciones = datosform.observaciones.toUpperCase();

        datosform.software = datosform.nombre.value + " - " + datosform.version + " - " + datosform.ambiente.value;
        datosActuales.software = datosActuales.nombre.value + " - " + datosform.version + " - " + datosform.ambiente.value;
        
        if(datosform.numerofactura === null){
            if(datosform.tiposoftware === "PRD" && datosform.ambiente === "EXTERNAL") datosform.numerofactura = "0";
        }
        if(datosform.costoanual === null) datosform.costoanual = "0";

        await actualizarAplicacion(datosform, dataAnterior, id, token).then((response) => {
            if(response !== null)
            {
                
                if(response.cod === 1)
                {
                    registrarBitacora(datosActuales, dataAnterior);
                    obtenerAplicaciopnBD();                    

                    swal("Successful update", "", "success")
                    .then(() => {
                                                         
                    });
                }
                else if(response.cod === 0){
                    swal("Update error", response.mensaje, "error")
                    .then(() => {
                        //datosActuales.software = dataAnterior.nombre.value + " - " + dataAnterior.version + " - " + dataAnterior.ambiente;
                        //setRegistro(datosActuales);                                    
                    });
                }
                else {
                    swal("Update error", "An error occurred during the update. Please contact the administrator", "error")
                    .then(() => {
                        //datosActuales.nombre = dataAnterior.nombre;
                        //setRegistro(datosActuales);                                    
                    });
                }
                
            }
            else 
                swal ("Update error" ,
                    "An error occurred during the update. Please contact the administrator" ,
                    "error")
        });
    }

    function validarAplicacion(data)
    {
        let errors = {
            "error_type_01": "Mandatory data must be entered",
            "error_type_02": "Invalid email",
        }
        let error = "";

        if(data.nombre === null || data.nombre === "" || data.nombre === undefined) 
        {   
            setErrorNombre(true);
            error = errors["error_type_01"];
        }
        else setErrorNombre(false);

        if(data.tiposoftware === null || data.tiposoftware === "" || data.tiposoftware === undefined) 
        {   
            setErrorTipoSW(true);
            error = errors["error_type_01"];
        }
        else {

            setErrorTipoSW(false);

            if(data.tiposoftware.value === "INTERNAL"){

                if(data.lenguaje === null || data.lenguaje === "" || data.lenguaje === undefined) {
    
                    setErrorLenguaje(true);
                    error = errors["error_type_01"];
                }
                else setErrorLenguaje(false);
            }
            else setErrorLenguaje(false);

            if(data.tiposoftware.value === "INTERNAL" || data.tiposoftware.value === "EXTERNAL"){

                if(data.criticidad === null || data.criticidad === "" || data.criticidad === undefined) {
    
                    setErrorCriticidad(true);
                    error = errors["error_type_01"];
                }
                else setErrorCriticidad(false);

                if(data.gerencia === null || data.gerencia === "" || data.gerencia === undefined) {
    
                    serErrorGerencia(true);
                    error = errors["error_type_01"];
                }
                else serErrorGerencia(false);
            }
            else {
                setErrorCriticidad(false);
                serErrorGerencia(false);
            }
        }

        if(data.version === null || data.version === "") 
        {   
            setErrorVersion(true);
            error = errors["error_type_01"];
        }
        else setErrorVersion(false);

        if(data.estado === null || data.estado === "" || data.estado === undefined) 
        {   
            setErrorEstado(true);
            error = errors["error_type_01"];
        }
        else setErrorEstado(false);

        if(data.negocio === null || data.negocio === "" || data.negocio === undefined) 
        {   
            setErrorNegocio(true);
            error = errors["error_type_01"];
        }
        else setErrorNegocio(false);

        if(data.ambiente === null || data.ambiente === "" || data.ambiente === undefined) 
        {   
            setErrorAmbiente(true);
            error = errors["error_type_01"];
        }
        else setErrorAmbiente(false);

        if(data.pais === null || data.pais === "" || data.pais === undefined) 
        {   
            setErrorPais(true);
            error = errors["error_type_01"];
        }
        else setErrorPais(false);

        if(data.ambiente !== null && data.ambiente !== "" && data.ambiente !== undefined 
            && data.tiposoftware !== null && data.tiposoftware !== "" && data.tiposoftware !== undefined)
        {
            if(data.tiposoftware.value  !== "EXTERNAL" && data.tiposoftware.value  !== "INTERNAL")
            {
                if(data.numerofactura === null || data.numerofactura === "") 
                {
                    setErrorFactura(true);
                    error = errors["error_type_01"];
                }
                else setErrorFactura(false);
            }
            else setErrorFactura(false);
        }

        if(data.tipolicencia === null || data.tipolicencia === "" ) 
        {   
            setErrorTipoLicencia(true);
            error = errors["error_type_01"];
        }
        else setErrorTipoLicencia(false);

        if(bd === null || bd === "" || bd === undefined) 
        {   
            setErrorBaseDatos(true);
            error = errors["error_type_01"];
        }
        else setErrorBaseDatos(false);

        if(versionbd=== null || versionbd === "" || versionbd === undefined) 
        {   
            setErrorVersionBD(true);
            error = errors["error_type_01"];
        }
        else setErrorVersionBD(false);

        if (data.vencimientocontrato != null){
            if (data.informara === null || data.informara === "") {
                setErrorInformara(true);
                error = errors["error_type_01"];
            } else setErrorInformara(false);
        } else setErrorInformara(false);

        if(data.informara.length > 0)
        {
            if(!validarEmail(data.informara))
            {
                setErrorInformara(true);
                error = errors["error_type_02"];
            }
            else setErrorInformara(false);
        }

        return error;
    }

    const onSubmit = (data) => {

        let errorMessage = validarAplicacion(data);

        if(errorMessage.length > 0)
        {
            swal("Form data", errorMessage, "warning");     
        }
        else{
            swal({
                title: "Update confirmation",
                text: "Do you want to update the registry?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if (afirmacion) {
                    await actualizar(data, registro); 
                }
                else setRegistro(data);      
            });
        }
    }

    React.useEffect(() => {
        if (registro !== null) {
            Opciones.campos.forEach(c => {
                setValue(c, registro[c], {shouldValidate: true,shouldDirty: true})
            });
        }
        else obtenerDatosBD();
    }, [registro, valores])

    if(registro === null || valores === null) return (<Loading />)
    else
    return (
        <>    
            <form 
                style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                autoComplete="off" onSubmit={handleSubmit(onSubmit)}
            >       
                <div className="divizq-app">
                    <div className="container">
                                                
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}} htmlFor="i-nombre">Application<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.aplicacion === "" || valores.traducciones.aplicacion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.aplicacion}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<Select/>}
                                        name="nombre"
                                        options={valores.maestroapp}
                                        isClearable={true}
                                        isDisabled={true}
                                        placeholder=""
                                        defaultValue={registro ? registro.nombre : ''}
                                        control={control}
                                        styles={styleDisable}
                                    />  
                                </div>
                            </div>
                            
                            
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}} htmlFor="i-version">Version<label style={{color: 'red'}}>{"*"}</label>   
                                        {
                                            (valores.traducciones.version === "" || valores.traducciones.version === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.version}</span>
                                                </div>
                                            )
                                        }  
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}
                                        style={(errorVersion) ? { borderColor: '#f90000'} : {}}                                      
                                        control={control}
                                        id="i-version"
                                        key="k-version"
                                        defaultValue={registro ? registro.version : ''}
                                        name='version'
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-comentario">Comments
                                    {
                                        (valores.traducciones.comentario === "" || valores.traducciones.comentario === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.comentario}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<textarea disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} rows="2" cols="50"></textarea>}
                                        control={control}
                                        id="i-comentario"
                                        key="k-comentario"                                
                                        name='comentario'
                                        defaultValue={registro ? registro.comentario : ''}
                                        
                                    />                             
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}} htmlFor="i-ambiente">Environment<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.ambiente === "" || valores.traducciones.ambiente === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ambiente}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                            options={valores.ambientes}
                                            onChange={(value) => {
                                                props.onChange(value);                                                        
                                            }}
                                            placeholder=""
                                            isClearable={true} 
                                            isDisabled={(privModificar) ? false : true}
                                            defaultValue={(registro) ? registro.ambiente : ""}
                                            styles={(errorAmbiente) ? style : (privModificar) ? styleNormal : styleDisable}
                                            control={control}
                                        />} 
                                        control={control} 
                                        id="i-ambiente"
                                        key="k-ambiente"                                                
                                        name='ambiente'
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}} htmlFor="i-tiposoftware">Type of Application<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.tiposoftware === "" || valores.traducciones.tiposoftware === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.tiposoftware}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                            options={valores.tiposw}
                                            onChange={(value) => {
                                                props.onChange(value);                                                        
                                            }}
                                            placeholder=""
                                            isClearable={true} 
                                            isDisabled={(privModificar) ? false : true}
                                            defaultValue={(registro) ? registro.tiposoftware : ""}
                                            styles={(errorTipoSW) ? style : (privModificar) ? styleNormal : styleDisable}
                                            control={control}
                                        />} 
                                        control={control} 
                                        id="i-tiposoftware"
                                        key="k-tiposoftware"                                                
                                        name='tiposoftware'
                                    /> 
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}}  htmlFor="i-estado">State<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.estado === "" || valores.traducciones.estado === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.estado}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                            options={valores.estados}
                                            onChange={(value) => {
                                                props.onChange(value);                                                        
                                            }}
                                            placeholder=""
                                            isClearable={true} 
                                            isDisabled={(privModificar) ? false : true}
                                            defaultValue={(registro) ? registro.estado : ""}
                                            styles={(errorEstado) ? style : (privModificar) ? styleNormal : styleDisable}
                                            control={control}
                                        />} 
                                        control={control} 
                                        id="i-estado"
                                        key="k-estado"                                                
                                        name='estado'
                                    /> 
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}} htmlFor="i-pais">Country<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.pais === "" || valores.traducciones.pais === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.pais}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                            options={valores.paises}
                                            onChange={(value) => {
                                                props.onChange(value);                                                        
                                            }}
                                            placeholder=""
                                            isClearable={true} 
                                            isDisabled={(privModificar) ? false : true}
                                            defaultValue={(registro) ? registro.pais : ""}
                                            styles={(errorPais) ? style : (privModificar) ? styleNormal : styleDisable}
                                            control={control}
                                        />} 
                                        control={control} 
                                        id="i-pais"
                                        key="k-pais"                                                
                                        name='pais'
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}}  htmlFor="i-negocio">Business<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.negocio === "" || valores.traducciones.negocio === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.negocio}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                            options={valores.negocios}
                                            onChange={(value) => {
                                                props.onChange(value);                                                        
                                            }}
                                            placeholder=""
                                            isClearable={true} 
                                            isDisabled={(privModificar) ? false : true}
                                            defaultValue={(registro) ? registro.negocio : ""}
                                            styles={(errorNegocio) ? style : (privModificar) ? styleNormal : styleDisable}
                                            control={control}
                                        />} 
                                        control={control} 
                                        id="i-negocio"
                                        key="k-negocio"                                                
                                        name='negocio'
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-responsable">Responsable
                                        {
                                            (valores.traducciones.responsable === "" || valores.traducciones.responsable === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.responsable}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                        options={valores.responsables}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            
                                        }}  
                                        isClearable={true}
                                        isDisabled={(privModificar) ? false : true}
                                        defaultValue={(registro) ? registro.responsable : ""}
                                        styles={(privModificar) ? styleNormal : styleDisable} 
                                        />} 
                                        control={control}
                                        id="i-responsable"
                                        key="k-responsable"                                    
                                        name='responsable'
                                    />                              
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-gerencia">Owner
                                        {
                                            (valores.traducciones.gerencia === "" || valores.traducciones.gerencia === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.gerencia}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input className="bootstrap-input" type='text'  />}                                      
                                        control={control}
                                        id="i-gerencia"
                                        key="k-gerencia"
                                        style={(errorGerencia) ? { borderColor: '#f90000'} : {}} 
                                        defaultValue={registro ? registro.gerencia : ''}
                                        name='gerencia'
                                    /> 
                                </div>
                            </div>  
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-sistemaoperativo">Operating System
                                        {
                                            (valores.traducciones.sistemaoperativo === "" || valores.traducciones.sistemaoperativo === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.sistemaoperativo}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                        options={valores.sistemasoperativos}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            
                                        }}  
                                        isClearable={true}
                                        isDisabled={(privModificar) ? false : true}
                                        defaultValue={(registro) ? registro.sistemaoperativo : ""}
                                        styles={(privModificar) ? styleNormal : styleDisable}
                                        />} 
                                        control={control}
                                        id="i-sistemaoperativo"
                                        key="k-sistemaoperativo"                                    
                                        name='sistemaoperativo'
                                    />                              
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-plataforma">Client Platform
                                        {
                                            (valores.traducciones.plataforma === "" || valores.traducciones.plataforma === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.plataforma}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                        options={valores.plataformas}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            
                                        }}  
                                        isClearable={true}
                                        isDisabled={(privModificar) ? false : true}
                                        defaultValue={(registro) ? registro.plataforma : ""}
                                        styles={(privModificar) ? styleNormal : styleDisable} 
                                        />} 
                                        control={control}
                                        id="i-plataforma"
                                        key="k-plataforma"                                    
                                        name='plataforma'
                                    />                              
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-plataformapublicacion">Publishing Platform
                                        {
                                            (valores.traducciones.plataformapublicacion === "" || valores.traducciones.plataformapublicacion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.plataformapublicacion}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                        options={valores.plataformaspublicacion}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            
                                        }}  
                                        isClearable={true}
                                        isDisabled={(privModificar) ? false : true}
                                        defaultValue={(registro) ? registro.plataformapublicacion : ""}
                                        styles={(privModificar) ? styleNormal : styleDisable} 
                                        />} 
                                        control={control}
                                        id="i-plataformapublicacion"
                                        key="k-plataformapublicacion"                                    
                                        name='plataformapublicacion'
                                    />                              
                                </div>
                            </div>                        
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-criticidad">Criticality
                                        {
                                            (valores.traducciones.criticidad === "" || valores.traducciones.criticidad === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.criticidad}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                        options={valores.criticidades}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            
                                        }}  
                                        isClearable={true}
                                        isDisabled={(privModificar) ? false : true}
                                        defaultValue={(registro) ? registro.criticidad : ""}
                                        styles={(errorCriticidad) ? style : (privModificar) ? styleNormal : styleDisable}
                                        />} 
                                        control={control}
                                        id="i-criticidad"
                                        key="k-criticidad"                                    
                                        name='criticidad'
                                    />                              
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-fabricante">Fabricator
                                        {
                                            (valores.traducciones.fabricante === "" || valores.traducciones.fabricante === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.fabricante}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                            options={valores.fabricantes}
                                            onChange={(value) => {
                                                props.onChange(value);                                                        
                                            }}
                                            placeholder=""
                                            isClearable={true} 
                                            isDisabled={true}
                                            defaultValue={(registro) ? registro.fabricante : ""}
                                            styles={styleDisable}
                                            control={control}
                                        />} 
                                        control={control} 
                                        id="i-fabricante"
                                        key="k-fabricante"                                                
                                        name='fabricante'
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-proveedor">Provider
                                        {
                                            (valores.traducciones.proveedor === "" || valores.traducciones.proveedor === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.proveedor}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                            options={valores.proveedores}
                                            onChange={(value) => {
                                                props.onChange(value);                                                        
                                            }}
                                            placeholder=""
                                            isClearable={true} 
                                            isDisabled={(privModificar) ? false : true}
                                            defaultValue={(registro) ? registro.proveedor : ""}
                                            styles={(privModificar) ? styleNormal : styleDisable}
                                            control={control}
                                        />} 
                                        control={control} 
                                        id="i-proveedor"
                                        key="k-proveedor"                                                
                                        name='proveedor'
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-lenguaje">Language
                                        {
                                            (valores.traducciones.lenguaje === "" || valores.traducciones.lenguaje === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.lenguaje}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                            options={valores.lenguajes}
                                            onChange={(value) => {
                                                props.onChange(value);                                                        
                                            }}
                                            placeholder=""
                                            isClearable={true} 
                                            isDisabled={(privModificar) ? false : true}
                                            defaultValue={(registro) ? registro.lenguaje : ""}
                                            styles={(errorLenguaje) ? style : (privModificar) ? styleNormal : styleDisable}
                                            control={control}
                                        />} 
                                        control={control} 
                                        id="i-lenguaje"
                                        key="k-lenguaje"                                                
                                        name='lenguaje'
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}} htmlFor="i-basedatos">Database<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.basedatos === "" || valores.traducciones.basedatos === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.basedatos}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                            options={valores.basesdatos}
                                            onChange={(value) => {
                                                props.onChange(value); 
                                                cambioBaseDatos(value);                                                         
                                            }}
                                            placeholder=""
                                            value={bd}
                                            defaultValue={bd}
                                            isClearable={true} 
                                            isDisabled={(privModificar) ? false : true}
                                            styles={(errorBaseDatos) ? style : (privModificar) ? styleNormal : styleDisable}
                                            control={control}
                                        />} 
                                        control={control} 
                                        id="i-basedatos"
                                        key="k-basedatos"                                                
                                        name='basedatos'
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}} htmlFor="i-versionbd">DB Version<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.versionbd === "" || valores.traducciones.versionbd === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.versionbd}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                            options={valores.versionesbasesdatos}
                                            onChange={(value) => {
                                                props.onChange(value);
                                                cambioVersionBD(value);                                                        
                                            }}
                                            placeholder=""
                                            isClearable={true} 
                                            isDisabled={(bd === "" || bd === null) ? true : (privModificar) ? false : true}
                                            value={versionbd}
                                            defaultValue={versionbd}
                                            styles={(errorVersionBD) ? style : (privModificar) ? styleNormal : styleDisable}
                                            control={control}
                                        />} 
                                        control={control} 
                                        id="i-versionbd"
                                        key="k-versionbd"                                                
                                        name='versionbd'
                                        defaultValue={registro ? registro.versionbd : ''}
                                    />
                                </div>
                            </div>                            
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-link">Link
                                        {
                                            (valores.traducciones.link === "" || valores.traducciones.link === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.link}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                        control={control}
                                        id="i-link"
                                        key="k-link"
                                        defaultValue={registro ? registro.link : ''}
                                        name='link'
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-waf">WAF
                                    {
                                        (valores.traducciones.waf === "" || valores.traducciones.waf === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.waf}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <input
                                        disabled={(!privModificar) ? 'disabled' : ''}
                                        type="checkbox"
                                        id="i-waf"
                                        key="k-waf" 
                                        name="waf"
                                        placeholder="luo"
                                        value={true}
                                        ref={register}
                                        onChange={eCambioWaf}
                                    />
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-observacionwaf">Observations WAF
                                        {
                                            (valores.traducciones.observacionwaf === "" || valores.traducciones.observacionwaf === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.observacionwaf}</span>
                                                </div>
                                            )
                                        }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<textarea disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} rows="2" cols="50"></textarea>}
                                        control={control}
                                        id="i-observacionwaf"
                                        key="k-observacionwaf"
                                        defaultValue={registro ? registro.observacionwaf : ''}
                                        name='observacionwaf'
                                    />                             
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-numerocontrato">Contract number
                                        {
                                            (valores.traducciones.numerocontrato === "" || valores.traducciones.numerocontrato === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.numerocontrato}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                        control={control}
                                        id="i-numerocontrato"
                                        key="k-numerocontrato"
                                        defaultValue={registro ? registro.numerocontrato : ''}
                                        name='numerocontrato'
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}} htmlFor="i-tipolicencia">Type of license<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.tipolicencia === "" || valores.traducciones.tipolicencia === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.tipolicencia}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                            options={valores.tipolicencias}
                                            onChange={(value) => {
                                                props.onChange(value);                                                        
                                            }}
                                            placeholder=""
                                            isClearable={true} 
                                            isDisabled={(privModificar) ? false : true}
                                            defaultValue={(registro) ? registro.tipolicencia : ""}
                                            styles={(errorTipoLicencia) ? style : (privModificar) ? styleNormal : styleDisable}
                                            control={control}
                                        />} 
                                        control={control} 
                                        id="i-tipolicencia"
                                        key="k-tipolicencia"                                                
                                        name='tipolicencia'
                                    />  
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-fechafactura">Invoice date (YYYY/MM/DD)
                                        {
                                            (valores.traducciones.fechafactura === "" || valores.traducciones.fechafactura === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.fechafactura}</span>
                                                </div>
                                            )
                                        }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        name="fechafactura"
                                        id="i-fechafactura"
                                        className="bootstrap-input"
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <DatePicker
                                                disabled={(!privModificar) ? 'disabled' : ''}
                                                selected={value}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                onChange={onChange}
                                                className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}                                            
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-vencimientocontrato">Expiration date (YYYY/MM/DD)
                                        {
                                            (valores.traducciones.vencimientocontrato === "" || valores.traducciones.vencimientocontrato === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.vencimientocontrato}</span>
                                                </div>
                                            )
                                        }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        name="vencimientocontrato"
                                        id="i-vencimientocontrato"
                                        className="bootstrap-input"
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <DatePicker
                                                disabled={(!privModificar) ? 'disabled' : ''}
                                                selected={value}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                onChange={onChange}
                                                className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")}                                         
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-informara">
                                        Inform To<label style={{ color: "red" }}>{"*"}</label>
                                        {valores.traducciones.informara === "" ||
                                        valores.traducciones.informara === null ? (<></>) : (
                                            <div class="tooltip">
                                                &nbsp;&#x1F6C8;
                                                <span class="tooltiptext">
                                                {valores.traducciones.informara}
                                                </span>
                                            </div>
                                        )}
                                    </label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input className="bootstrap-input" type="text" />}
                                        control={control}
                                        id="i-informara"
                                        key="k-informara"
                                        style={errorInformara ? { borderColor: "#f90000" } : {}}
                                        defaultValue={registro ? registro.informara : ""}
                                        name="informara"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-numerofactura">Purchase Invoice Number
                                        {
                                            (valores.traducciones.numerofactura === "" || valores.traducciones.numerofactura === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.numerofactura}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                        control={control}
                                        style={(errorFactura) ? { borderColor: '#f90000'} : {}}
                                        id="i-numerofactura"
                                        key="k-numerofactura"
                                        defaultValue={registro ? registro.numerofactura : ''}
                                        name='numerofactura'
                                    /> 
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-costoanual">Annual Cost US$
                                        {
                                            (valores.traducciones.costoanual === "" || valores.traducciones.costoanual === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.costoanual}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                        control={control}
                                        style={(errorFactura) ? { borderColor: '#f90000'} : {}}
                                        id="i-costoanual"
                                        key="k-costoanual"
                                        defaultValue={registro ? registro.costoanual : ''}
                                        name='costoanual'
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-numerolicencia">Number of Licenses
                                        {
                                            (valores.traducciones.numerolicencia === "" || valores.traducciones.numerolicencia === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.numerolicencia}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='number'  
                                        
                                        />}                                      
                                        control={control}
                                        id="i-numerolicencia"
                                        key="k-numerolicencia"
                                        defaultValue={registro ? registro.numerolicencia : ''}
                                        name='numerolicencia'
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-autenticacion">Authentication Mechanism
                                        {
                                            (valores.traducciones.autenticacion === "" || valores.traducciones.autenticacion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.autenticacion}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                            options={valores.autenticaciones}
                                            onChange={(value) => {
                                                props.onChange(value);                                                        
                                            }}
                                            placeholder=""
                                            isClearable={true} 
                                            isDisabled={(privModificar) ? false : true}
                                            defaultValue={(registro) ? registro.autenticacion : null}
                                            styles={(privModificar) ? styleNormal : styleDisable}
                                            control={control}
                                        />} 
                                        control={control} 
                                        id="i-autenticacion"
                                        key="k-autenticacion"                                                
                                        name='autenticacion'
                                    />  
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-centralizada">Centralized / Distributed
                                        {
                                            (valores.traducciones.centralizada === "" || valores.traducciones.centralizada === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.centralizada}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                        control={control}
                                        id="i-centralizada"
                                        key="k-centralizada"
                                        defaultValue={registro ? registro.centralizada : ''}
                                        name='centralizada'                                    
                                    />  
                                </div>
                            </div>
                            <div className="row" style={{display: "none"}}>
                                <div className="col-25">
                                    <label htmlFor="i-software">Software</label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input disabled='disabled' className="bootstrap-input" type='text'  />}                                      
                                        control={control}
                                        id="i-software"
                                        key="k-software"
                                        defaultValue={registro ? registro.software : ''}
                                        name='software'
                                    /> 
                                </div>
                            </div> 
                    </div>
                    
                    <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px', float: 'left'}}>  
                        <div style={{width: '40%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px'}}>                
                            <button className="boton-from">Save</button>   
                            <br></br>
                            <br></br>
                            <br></br>              
                        </div>
                    </div>

                </div>
                <div className="divder-app">  

                    <div className="containerappder"> 
                        <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-observaciones">Prerequisites and Observations
                                        {
                                            (valores.traducciones.observaciones === "" || valores.traducciones.observaciones === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.observaciones}</span>
                                                </div>
                                            )
                                        }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<textarea disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} rows="2" cols="50"></textarea>}
                                        control={control}
                                        id="i-observaciones"
                                        key="k-observaciones"
                                        defaultValue={registro ? registro.observaciones : ''}
                                        name='observaciones'
                                    />                             
                                </div>
                            </div>
                        <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-procedimientoadmin">Administration Procedure
                                        {
                                            (valores.traducciones.procedimientoadmin === "" || valores.traducciones.procedimientoadmin === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.procedimientoadmin}</span>
                                                </div>
                                            )
                                        }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <input
                                        disabled={(!privModificar) ? 'disabled' : ''}
                                        type="checkbox"
                                        id="i-procedimientoadmin"
                                        key="k-procedimientoadmin" 
                                        name="procedimientoadmin"
                                        placeholder="luo"
                                        value={true}
                                        onChange={eCambioAdminPro}
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-redundancia">Redundancy
                                        {
                                            (valores.traducciones.redundancia === "" || valores.traducciones.redundancia === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.redundancia}</span>
                                                </div>
                                            )
                                        }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <input
                                        disabled={(!privModificar) ? 'disabled' : ''}
                                        type="checkbox"
                                        id="i-redundancia"
                                        key="k-redundancia" 
                                        name="redundancia"
                                        placeholder="luo"
                                        value={true}
                                        onChange={eCambioRedundancia}
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-adminacceso">Access Manager
                                        {
                                            (valores.traducciones.adminacceso === "" || valores.traducciones.adminacceso === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.adminacceso}</span>
                                                </div>
                                            )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                        control={control}
                                        id="i-adminacceso"
                                        key="k-adminacceso"
                                        defaultValue={registro ? registro.adminacceso : ''}
                                        name='adminacceso'                                    
                                    />                              
                                </div>
                            </div>
                            <div className="row">
                                <div className="rowdoble-izq">
                                    <div className="col-75">
                                        <label htmlFor="i-drp">DRP
                                        {
                                            (valores.traducciones.drp === "" || valores.traducciones.drp === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.drp}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-25">
                                        <input
                                            disabled={(!privModificar) ? 'disabled' : ''}
                                            type="checkbox"
                                            id="i-drp"
                                            key="k-drp" 
                                            name="drp"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                            onChange={eCambioDrp}
                                            style={{float: 'right'}}
                                        />
                                    </div>
                                </div>
                                <div className="rowdoble-der">
                                    <div className="fechaapp-izq">
                                        <label htmlFor="i-fechadrp">DRP Test Date (YYYY/MM/DD)
                                        {
                                            (valores.traducciones.fechadrp === "" || valores.traducciones.fechadrp === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.fechadrp}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="fechaapp-der">
                                        <Controller
                                            name="fechadrp"
                                            id="i-fechadrp"
                                            className="bootstrap-input"
                                            control={control}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    disabled={(!privModificar) ? 'disabled' : ''}
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className="bootstrap-input"                                            
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="rowdoble-izq">
                                    <div className="col-75">
                                        <label htmlFor="i-respaldos">Backrests
                                        {
                                            (valores.traducciones.respaldos === "" || valores.traducciones.respaldos === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.respaldos}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-25">
                                        <input
                                            disabled={(!privModificar) ? 'disabled' : ''}
                                            type="checkbox"
                                            id="i-respaldos"
                                            key="k-respaldos" 
                                            name="respaldos"
                                            placeholder="luo"
                                            value={true}
                                            onChange={eCambioRespaldo}
                                            ref={register}
                                            style={{float: 'right'}}
                                        />
                                    </div>
                                </div>
                                <div className="rowdoble-der">
                                    <div className="fechaapp-izq">
                                        <label htmlFor="i-fecharespaldos">Backrests Test Date (YYYY/MM/DD)
                                        {
                                            (valores.traducciones.fecharespaldos === "" || valores.traducciones.fecharespaldos === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.fecharespaldos}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="fechaapp-der">
                                        <Controller
                                            name="fecharespaldos"
                                            id="i-fecharespaldos"
                                            className="bootstrap-input"
                                            control={control}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    disabled={(!privModificar) ? 'disabled' : ''}
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className="bootstrap-input" 
                                                                                            
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                            </div>                            
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-modificadopor">Modified by</label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                        control={control}
                                        id="i-modificadopor"
                                        key="k-modificadopor"
                                        defaultValue={registro ? registro.modificadopor : ''}
                                        name='modificadopor'
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-fechaupdate">Modification date (YYYY/MM/DD)</label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        name="fechaupdate"
                                        id="i-fechaupdate"
                                        className="bootstrap-input"
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <DatePicker
                                                disabled="disabled"
                                                selected={value}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                onChange={onChange}
                                                className="bootstrap-input-disable"
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>
                    </div>
                    <br></br>
                    <div className="containerappder"> 
                        <div className='subtitulo-box'>
                            <div className='subtitulo'>
                                <label >Servers</label>
                            </div>                    
                        </div>
                        <TablaRelacionServidor rows={servidores} id={id} criticidad={registro.criticidad} camposExcel={Opciones.camposExcelServidores} privModificar={privModificar} actualizarServidores={actualizarServidores} nombreApp={registro.nombre.value} token={token}/>
                    </div>
                    <br></br>
                    <div className="containerappder"> 
                        <div className='subtitulo-box'>
                            <div className='subtitulo'>
                                <label >Desktops</label>
                            </div>                    
                        </div>
                        <TablaRelacionEquipo rows={equipos} id={id} camposExcel={Opciones.camposExcelEquipos} privModificar={privModificar} actualizarEquipos={actualizarEquipos} nombreApp={registro.nombre.value} token={token}/>
                    </div>
                    <div className="containerappder"> 
                        <div className='subtitulo-box'>
                            <div className='subtitulo'>
                                <label >Accounts Control</label>
                            </div>                    
                        </div>                        
                        <TablaCuentas rows={cuentas}/>
                    </div>
                    <div className="containerappder"> 
                        <div className='subtitulo-box'>
                            <div className='subtitulo'>
                                <label >Certificates</label>
                            </div>                    
                        </div>                                            
                        <TablaCertificados rows={certificados}/>
                    </div>
                    <div className="containerappder"> 
                        <div className='subtitulo-box'>
                            <div className='subtitulo'>
                                <label >Integrations</label>
                            </div>                    
                        </div>   
                        {
                            (integraciones === null || valores === null) ? (<></>)
                            : (<TablaRelacionIntegracion rows={integraciones} maestro={maestroIntegraciones} id={id} camposExcel={Opciones.camposExcelServidores} privModificar={privModificar} actualizarIntegraciones={actualizarIntegraciones} nombreApp={registro.nombre.value} token={token}/>)
                        }
                    </div>
                </div>
            </form>   
        </>
    )
}

export default FormularioAplicacion
