import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import swal from 'sweetalert';
import { crearRegistroGeneral } from '../../functions/FuncMaestroGeneral'


const MaestroGeneralCreacion = ({maestro, token, valorNombre}) => {

    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});
    const ref = React.useRef();
    const [nuevo, setNuevo] = useState("");

    const onSubmit = (data) => {

        if(data.nombre === "" || data.nombre === null || data.nombre === undefined)
        {
            swal("Creation Error", "Please enter a value for the Master", "warning");
        }
        else {
            swal({
                title: "Creation confirmation",
                text: "Do you want to create the master?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }). then(async (afirmacion) => {
                if(afirmacion)
                {
                    await crearRegistroGeneral(maestro, data.nombre, token)
                    .then((response) => {  
                        
                        if(response.cod === 1)
                        {
                            setValue("nombre", "");
                            swal("Successful creation", "", "success");
                        }
                        else {
                            swal ("Creation Error" , response.mensaje , "error");
                        }
                    });
                    
                }
            })
        } 
    }

    React.useEffect(() => {
        setValue("nombre", valorNombre);
    }, [maestro]);

    return (
        <>
            <form
                style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                autoComplete="off" onSubmit={handleSubmit(onSubmit)}
            >
                <div className="container-general">
                    <div className="row">
                        <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-nombre">{maestro.indice}</label> 
                        </div>
                        <div className="col-75">
                            <Controller
                                as={<input className="bootstrap-input" type='text'  />}                                      
                                control={control}
                                id="i-nombre"
                                key="k-nombre"
                                defaultValue=""                                
                                name='nombre'                                
                            />                              
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div style={{width: '60%', marginRight:'auto', marginLeft: 'auto'}}>
                            <button className="boton-from">Save</button>
                        </div>                   
                    </div>
                </div>
            </form>
            
        </>
    )
}

export default MaestroGeneralCreacion
