import React, { useState } from 'react'
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const FiltroBase = ({opciones, cargado, value, nombre, cambio, classes}) => {

    function cambiavalores(valorCampo)
    {
        cambio(valorCampo);
    }

    const comprobacion = {};
    return (
        <>
        {(cargado)
        ?
        (
            <FormControl variant="outlined" className={classes.formControl} style={{verticalAlign: 'middle'}}>
                <InputLabel >{nombre}</InputLabel>
                <Select
                    defaultValue = ""
                    valor={value}
                    onChange={(e) => cambiavalores(e.target.value)}
                    label="Localidad"
                    >
                        <MenuItem value="">
                            <em>UNFILTERED</em>
                        </MenuItem>
                        {
                            opciones.map((o) => (
                                <MenuItem id={"item-"+o.label} key={o.label + "-" + o.index} value={o.value || ''}>{o.label}</MenuItem>
                            ))                           
                        }                       
                    </Select>
            </FormControl>
        )
        :
        (
            <FormControl variant="outlined" className={classes.formControl} style={{verticalAlign: 'middle'}}>
                <InputLabel >{nombre}</InputLabel>
                <Select
                    defaultValue = ""
                >
                    <MenuItem value="">
                        <em>UNFILTERED</em>
                    </MenuItem>                        
                </Select>
            </FormControl> 
        )
        }
        
        </>
    )
}

export default FiltroBase
