import styled from 'styled-components';

export const style = {
    control: base => ({
      ...base,
      borderColor: "#f90000",
      '&:hover': {
        border:"1px solid #56b4ef !important",
        boxShadow: "0px 0px 0px 1px #56b4ef !important"
      },
      '&:focus': {
        border:"1px solid #56b4ef !important",
        boxShadow: "0px 0px 0px 1px #56b4ef !important"
      }
    })
    
  };

export const styleNormal = {
      control: base => ({
        ...base,
        '&:hover': {
          border:"1px solid #56b4ef !important",
          boxShadow: "0px 0px 0px 1px #56b4ef !important"
        },
        '&:focus': {
          border:"1px solid #56b4ef !important",
          boxShadow: "0px 0px 0px 1px #56b4ef !important"
        }
      })
  };

export const styleDisable = {
      control: base => ({
        ...base,      
        fontWeight: "bold",
        border: "1px solid #ccc",
        outline: "none",
        backgroundColor: "#F1F1F1",
        '&:hover': {
          border:"1px solid #2684FF !important",
          boxShadow: "0px 0px 0px 1px #56b4ef !important"
        },
        '&:focus': {
          border:"1px solid #2684FF !important",
          boxShadow: "0px 0px 0px 1px #2684FF !important"
        }
      })
  };


export const ContainerFiltros= styled.div`
    width: 100%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    display: inline-flex;
`;

export const FilaContainerFiltros= styled.div`
  width: 80%;  
  margin-left: auto;
  margin-right: auto;
`;

/*export const Button = styled.button`
    margin-right: auto;
    background-color: #EA7600;
    color: white;
    font-size: 20px;    
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    width: 100%;
    height: 50px;  
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    vertical-align: middle;
    color: white;
`;*/

export const Button = styled.button`
    margin-left: 10px;
    margin-right: auto;
    background-color: #EA7600;
    color: white;
    font-size: 20px;    
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 1.5px 70px;  
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    vertical-align: middle;
    color: white;
    width: 100%;
    height: 50px;   
`;

export const ContainerFormBot = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
`;

export const ContainerFormTop = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
`;

export const ButtonVolver = styled.button`
    float:left;
    background-color: #EA7600; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400; 
    border-radius: 5px;  
    border-color: #D35400;
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
    color: white;    

    :hover {
        background: #ffa549;
    }
`;

export const ContainerForm = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
`;

export const ContainerFormSmall = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
`;

export const ButtonEliminacion = styled.button`
    float:right;
    background-color: #C70039;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 15px; 
    border-radius: 5px;  
    border-color: #900C3F;
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
    color: white;    
    height: 40px;   

    :hover {
        background: #FF5733;
    }
`;

export const ContainerBusqueda = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
`;

export const ContainerTable = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
`;

export const ButtonExcel = styled.button`
    width: 50px;
    margin-left: auto;
    height: 40px;
    background-color: #EA7600;
    color: white;
    font-size: 20px;
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    float: right;

    :hover {
        background: #ffa549;
    }
`;

export const ButtonConfirmar = styled.button`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #EA7600; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #D35400;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    color: white;
    float:right;
`;

export const ButtonCancelar = styled.button`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    background-color: #85929E;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #99A3A4;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    color: white;
    float:right;
    
`;

export const ButtonRelacion = styled.button`
    float:right;
    background-color: #6294D8;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 15px; 
    border-radius: 5px;  
    border-color: #2E70C8;
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
    color: white;
    margin-right: 10px;
    height: 40px;

    :hover {
        background: #97b5dd;
    }
`;

export const ButtonCarga = styled.button`
    float:right;
    background-color: #EA7600;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 15px; 
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
    color: white;    
    height: 40px;

    :hover {
        background: #ffa549;
    }
`;

/*

import { ContainerFiltros, FilaContainerFiltros, Button } from '../Commons/Styles'
*/