import React from 'react'
import Lottie from "react-lottie";
import animationData from "./img/loadingLinea.json"

const buttonStyle = {
    display: "inline-block",
    margin: "10px auto",
    marginRight: "10px",
    border: "none",
    color: "white",
    backgroundColor: "#647DFF",
    borderRadius: "2px",
    fontSize: "15px"
};

const Loading = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div>
            <Lottie
                options={defaultOptions}
                height={300}
                width={300}                
            />
        </div>
    )
}

export default Loading
