import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import * as Opciones from './OpcionesComunicacion'
import { obtenerValores, crearAplicacion} from '../functions/FuncComunicacion'

import "../css/formularioAplicacion.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import Loading from '../Loading';
import Select from 'react-select'


import { style, styleNormal } from '../Commons/Styles'

const CreacionEquipoComunicacion = ({token}) => {

    const history = useHistory();
    const [registro, setRegistro] = useState(null);    
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const [tipoform, setTipoform] = useState("");
    const [usabilidad, setUsabilidad] = useState("");
    const [tipoequipo, setTipoequipo] = useState("");

    const [empresa, setEmpresa] = useState("");
    const [planta, setPlanta] = useState(""); 

    const [marca, setMarca] = useState("");
    const [modelo, setModelo] = useState("");

    const [errorFamilia, setErrorFamilia] = useState(false);
    const [errorUsabilidad, setErrorUsabilidad] = useState(false);
    const [errorTipoEquipo, setErrorTipoEquipo] = useState(false);
    const [errorNumeroSerie, setErrorNumeroSerie] = useState(false);    
    const [errorEstado, setErrorEstado] = useState(false);    
    const [errorEmpresa, setErrorEmpresa] = useState(false);    
    const [errorPlanta, setErrorPlanta] = useState(false); 
    const [errorCondicion, setErrorCondicion] = useState(false); 

    let privilegio = sessionStorage.getItem('com_crear');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');
    let idiomaUser = sessionStorage.getItem('idioma');

    async function obtenerDatosBD()
    {
        await obtenerValores(privilegio, paisUser, localidadUser, "COMUNICATION", idiomaUser, token)
        .then((response) => {
            
            if(response.cod === 1)
            {
                const val = {
                    "usabilidades": response.usabilidades,
                    "filtrousabilidad": response.filtrousabilidad,
                    "tiposequipos": response.tiposequipos,
                    "filtrotipoequipo": response.filtrotipoequipo,
                    "marcas": response.marcas,
                    "modelos": response.modelos,
                    "filtromodelos": response.filtromodelos,
                    "empresas": response.empresas,
                    "negocios": response.negocios,
                    "plantas": response.plantas,
                    "filtroplantas": response.filtroplantas,
                    "localidades": response.localidades,
                    "proveedores": response.proveedores  ,
                    "familias": response.familias,
                    "estados": response.estados,
                    "condiciones": response.condiciones,
                    "servicios": response.servicios,
                    "tiposenlace": response.tiposenlace,
                    "traducciones": response.traducciones  
                };

                let array = [];
                const listas = ["usabilidades", "tiposequipos", "marcas", "modelos", "empresas", "negocios",
                 "plantas", "localidades", "proveedores", "familias", "estados", "tiposenlace", "servicios", "condiciones"];

                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                                
                
                setValores(val);
            }
        });
    }

    React.useEffect(() => {
        if (valores !== null) {

        }
        else obtenerDatosBD();
    }, []);

    const cambioFormulario = (value) => {  
        
        if(value !== "" && value !== null){
            
            filtrarPorFamilia(value.value);            
            setTipoform(value.value);            
        } else {
            setTipoform("");
            setUsabilidad("");
            setTipoequipo("");
        }
    }

    const cambioUsabilidad = (value) => { 
        
        if(value !== "" && value !== null){
            
            const valorUsabiliad = value.value;
            filtrarTipoEquipo(value.value)
            setUsabilidad({"label": valorUsabiliad, "value": valorUsabiliad});
        } else {
            setUsabilidad("");
            setTipoequipo("");
        }
    }

    const cambioTipoEquipo = (value) => { 
        
        if(value !== "" && value !== null){
            setTipoequipo({"label": value.value, "value": value.value});
        } else {
            setTipoequipo("");
        }
    }


    function filtrarPorFamilia(familia)
    {
        
        if(familia !== "")
        {
            const usabilidadesArray = valores.filtrousabilidad;

            var array = [];
            if(usabilidadesArray.length > 0){
                usabilidadesArray.map((p) => {
                    if(p.familia === familia) {
                        array.push({"label": p.usabilidad, "value": p.usabilidad});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.usabilidades = array;
            setUsabilidad("");
            setTipoequipo("");
            setValores(valoresNuevo);
        }
    }

    function filtrarTipoEquipo(usabilidad)
    {
        if(usabilidad !== "")
        {
            const filtrotipoArray = valores.filtrotipoequipo;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.usabilidad === usabilidad) {
                        array.push({"label": p.tipoequipo, "value": p.tipoequipo});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.tiposequipos = array; 
            setTipoequipo("");           
            setValores(valoresNuevo);
        }
    }

    const cambioEmpresa= (value) => { 
        if(value !== "" && value !== null){
            setEmpresa(value.value);            
            filtrarPlantas(value.value);            
        } else {
            setEmpresa(""); 
            setPlanta("");           
        }
    }

    const cambioPlanta= (value) => { 
        if(value !== "" && value !== null){
            setPlanta({"label": value.value, "value": value.value});
        } else {
            setPlanta("");
        }
    }

    function filtrarPlantas(empresa)
    {
        if(empresa !== "")
        {
            const filtrotipoArray = valores.filtroplantas;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.empresa === empresa) {
                        array.push({"label": p.planta, "value": p.planta});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.plantas = array; 
            setPlanta("");           
            setValores(valoresNuevo);
        }
    }

    const cambioMarca = (value) => { 
        if(value !== "" && value !== null){
            setMarca(value.value);            
            filtrarModelos(value.value);            
        } else {
            setMarca(""); 
            setModelo("");           
        }
    }

    const cambioModelo= (value) => { 
        if(value !== "" && value !== null){
            setModelo({"label": value.value, "value": value.value});
        } else {
            setModelo("");
        }
    }

    function filtrarModelos(marca)
    {
        if(marca !== "")
        {
            const filtrotipoArray = valores.filtromodelos;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.marca === marca) {
                        array.push({"label": p.modelo, "value": p.modelo});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.modelos = array; 
            setModelo("");           
            setValores(valoresNuevo);
        }
    }

    const onSubmit = (data) => {
        const validacion = validarDatos(data);
        if(validacion)
        {
            swal("Form data", "Mandatory data must be entered", "warning");
        }
        else {
            swal({
                title: "Creation confirmation",
                text: "Do you want to create the record?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion)
                {
                    const datosform = data;

                    let nombreusuario = sessionStorage.getItem('nombre');
                    datosform.modificadopor = nombreusuario;
                    datosform.fechaupdate = new Date();
                    datosform.familia = tipoform;
                    datosform.usabilidad = usabilidad.value;
                    datosform.tipoequipo = tipoequipo.value;
                    
                    await crearAplicacion(datosform, token).then((response) => {
                        
                        if(response.cod === 1)
                        {
                            swal("Successful creation", "", "success").then(() => {
                                history.push("/comunicaciones/"+response.id);
                            });
                        }
                        else swal("Creation Error", response.mensaje, "error");                        
                    });
                }
            })
        }
    }

    function validarDatos(data)
    {
        var errores = false;
        if(tipoform === "") 
        {   
            setErrorFamilia(true);
            errores = true;
        }
        else setErrorFamilia(false);

        if(usabilidad === "") 
        {   
            setErrorUsabilidad(true);
            errores = true;
        }
        else setErrorUsabilidad(false);

        if(tipoequipo === "") 
        {   
            setErrorTipoEquipo(true);
            errores = true;
        }
        else setErrorTipoEquipo(false);

        if(data.numeroserie === null || data.numeroserie === "" || data.numeroserie === undefined) 
        {
            setErrorNumeroSerie(true);
            errores = true;
        }
        else setErrorNumeroSerie(false);

        if(data.empresa === null || data.empresa === "" || data.empresa === undefined) 
        {
            setErrorEmpresa(true);
            errores = true;
        }
        else setErrorEmpresa(false);

        if(data.planta === null || data.planta === "" || data.planta === undefined) 
        {
            setErrorPlanta(true);
            errores = true;
        }
        else setErrorPlanta(false);

        if(data.estado === null || data.estado === "" || data.estado === undefined) 
        {
            setErrorEstado(true);
            errores = true;
        }
        else setErrorEstado(false);

        if(data.condicion === null || data.condicion === "" || data.condicion === undefined) 
        {
            setErrorCondicion(true);
            errores = true;
        }
        else setErrorCondicion(false);

        return errores;
    }


    if(valores === null) return (<Loading />)
    else
    return (
        <>
            <div className="divizq">
                <div className="container">
                    <form 
                        style={{width: '70%', marginRight:'auto', marginLeft: 'auto'}} 
                        autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-familia">Family<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.familia === "" || valores.traducciones.familia === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.familia}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.familias}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value);
                                            cambioFormulario(value);
                                        }}  
                                        isClearable={true}
                                        styles={(errorFamilia) ? style : styleNormal}                                        
                                    />} 
                                    control={control}
                                    id="i-familia"
                                    key="k-familia"                                    
                                    name='familia'
                                />                              
                            </div>
                        </div>
                        {
                            (tipoform === "") ? (<br></br>)
                            :
                            (
                                <>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-usabilidad">Usability<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.usabilidad === "" || valores.traducciones.usabilidad === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.usabilidad}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.usabilidades}
                                                    placeholder=""
                                                    onChange={(value) => {
                                                        props.onChange(value);
                                                        cambioUsabilidad(value);
                                                    }}
                                                    isClearable={true} 
                                                    value={usabilidad}
                                                    styles={(errorUsabilidad) ? style : styleNormal}
                                                />} 
                                                                                                                                                                                 
                                                control={control}                                            
                                                id="i-usabilidad"
                                                key="k-usabilidad"
                                                defaultValue={registro ? registro.usabilidad : ''}
                                                name='usabilidad'
                                                
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-tipoequipo">Equipment Type<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.tipoequipo === "" || valores.traducciones.tipoequipo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.tipoequipo}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.tiposequipos}
                                                    onChange={(value) => {
                                                        props.onChange(value);
                                                        cambioTipoEquipo(value);
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    value={tipoequipo}
                                                    isDisabled={(usabilidad !== "") ? false : true}
                                                    styles={(errorTipoEquipo) ? style : styleNormal}
                                                />}                                                                                                                                   
                                                control={control}                                            
                                                id="i-tipoequipo"
                                                key="k-tipoequipo"
                                                defaultValue={registro ? registro.tipoequipo : ''}
                                                name='tipoequipo'                                                
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-numeroserie">Serial / Service Number<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.numeroserie === "" || valores.traducciones.numeroserie === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.numeroserie}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input className="bootstrap-input" type='text'  />}                                      
                                                control={control}
                                                id="i-numeroserie"
                                                key="k-numeroserie"
                                                defaultValue={registro ? registro.numeroserie : ''}
                                                name='numeroserie'
                                                style={(errorNumeroSerie) ? { borderColor: '#f90000'} : {}} 
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-estado">State<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.estado === "" || valores.traducciones.estado === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.estado}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.estados}
                                                    onChange={(value) => {
                                                        props.onChange(value);                                                        
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    styles={(errorEstado) ? style : styleNormal}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-estado"
                                                key="k-estado"                                                
                                                name='estado'
                                            /> 
                                        </div>
                                    </div> 
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-condicion">Condition<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.condicion === "" || valores.traducciones.condicion === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.condicion}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.condiciones}
                                                    onChange={(value) => {
                                                        props.onChange(value);                                                        
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    styles={(errorCondicion) ? style : styleNormal}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-condicion"
                                                key="k-condicion"                                                
                                                name='condicion'
                                            /> 
                                        </div>
                                    </div>  
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-empresa">Enterprise<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.empresa === "" || valores.traducciones.empresa === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.empresa}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.empresas}
                                                    onChange={(value) => {
                                                        props.onChange(value);   
                                                        cambioEmpresa(value);                                                      
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    styles={(errorEmpresa) ? style : styleNormal}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-empresa"
                                                key="k-empresa"                                                
                                                name='empresa'
                                            /> 
                                        </div>
                                    </div>                                  
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-planta">Plant/Office<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.planta === "" || valores.traducciones.planta === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.planta}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.plantas}
                                                    onChange={(value) => {
                                                        props.onChange(value);
                                                        cambioPlanta(value);                                                        
                                                    }}
                                                    placeholder=""
                                                    value={planta}
                                                    isDisabled={(empresa !== "") ? false : true}
                                                    isClearable={true} 
                                                    styles={(errorPlanta) ? style : styleNormal}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-planta"
                                                key="k-planta"                                                
                                                name='planta'
                                            /> 
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        {
                        (tipoform === "") ? (<br></br>)
                        :
                        (
                            (tipoform === "DATA LINK")
                            ?
                            (
                                <>
                                
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-direccion">Address
                                        {
                                                (valores.traducciones.direccion === "" || valores.traducciones.direccion === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.direccion}</span>
                                                    </div>
                                                )
                                            }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                                                                  
                                            control={control}                                            
                                            id="i-direccion"
                                            key="k-direccion"
                                            defaultValue={registro ? registro.direccion : ''}
                                            name='direccion'
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-servicio">Service
                                        {
                                            (valores.traducciones.servicio === "" || valores.traducciones.servicio === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.servicio}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.servicios}
                                                onChange={(value) => {
                                                    props.onChange(value);                                                        
                                                }}
                                                placeholder=""
                                                isClearable={true}
                                                styles={styleNormal}  
                                                control={control}
                                            />} 
                                            control={control} 
                                            id="i-servicio"
                                            key="k-servicio"                                                
                                            name='servicio'
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-proveedor">Provider
                                        {
                                            (valores.traducciones.proveedor === "" || valores.traducciones.proveedor === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.proveedor}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.proveedores}
                                                onChange={(value) => {
                                                    props.onChange(value);                                                        
                                                }}
                                                placeholder=""
                                                isClearable={true} 
                                                styles={styleNormal} 
                                                control={control}
                                            />} 
                                             control={control} 
                                            id="i-proveedor"
                                            key="k-proveedor"                                                
                                            name='proveedor'
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-tipoenlace">Link Type
                                        {
                                            (valores.traducciones.tipoenlace === "" || valores.traducciones.tipoenlace === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.tipoenlace}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.tiposenlace}
                                                onChange={(value) => {
                                                    props.onChange(value);                                                        
                                                }}
                                                placeholder=""
                                                isClearable={true} 
                                                styles={styleNormal} 
                                                control={control}
                                            />} 
                                            control={control} 
                                            id="i-tipoenlace"
                                            key="k-tipoenlace"                                                
                                            name='tipoenlace'
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-anchobanda">Bandwidth (Mbps)
                                        {
                                            (valores.traducciones.anchobanda === "" || valores.traducciones.anchobanda === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.anchobanda}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                                                                  
                                            control={control}
                                            id="i-anchobanda"
                                            key="k-anchobanda"
                                            defaultValue={registro ? registro.anchobanda : ''}
                                            name='anchobanda'
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ipequipo">IP
                                        {
                                            (valores.traducciones.ipequipo === "" || valores.traducciones.ipequipo === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ipequipo}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                                                                  
                                            control={control}
                                            id="i-ipequipo"
                                            key="k-ipequipo"
                                            defaultValue={registro ? registro.ipequipo : ''}
                                            name='ipequipo'
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-descripcion">Description
                                            {
                                                (valores.traducciones.descripcion === "" || valores.traducciones.descripcion === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.descripcion}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<textarea className="bootstrap-input" rows="3" cols="50"></textarea>}
                                                control={control}
                                                id="i-descripcion"
                                                key="k-descripcion"
                                                defaultValue={registro ? registro.descripcion : ''}
                                                name='descripcion'
                                            />                             
                                        </div>
                                    </div>
                                </>
                            )
                            :
                            (
                                <>                                    
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-hostname">Hostname
                                            {
                                                (valores.traducciones.hostname === "" || valores.traducciones.hostname === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.hostname}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input className="bootstrap-input" type='text'  />}                                      
                                                control={control}
                                                id="i-hostname"
                                                key="k-hostname"
                                                defaultValue={registro ? registro.hostname : ''}
                                                name='hostname'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-ordencompra">Purchase order
                                            {
                                                (valores.traducciones.ordencompra === "" || valores.traducciones.ordencompra === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.ordencompra}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input className="bootstrap-input" type='text'  />}                                      
                                                control={control}
                                                id="i-ordencompra"
                                                key="k-ordencompra"
                                                defaultValue={registro ? registro.ordencompra : ''}
                                                name='ordencompra'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-marca">Brand
                                            {
                                                (valores.traducciones.marca === "" || valores.traducciones.marca === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.marca}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.marcas}
                                                    placeholder=""
                                                    onChange={(value) => {
                                                        props.onChange(value); 
                                                        cambioMarca(value);                                                        
                                                    }}
                                                    isClearable={true} 
                                                    styles={styleNormal} 
                                                    control={control}
                                                />} 
                                                control={control} 
                                                id="i-marca"
                                                key="k-marca"                                                
                                                name='marca'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-modelo">Model
                                            {
                                                (valores.traducciones.modelo === "" || valores.traducciones.modelo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.modelo}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.modelos}
                                                    onChange={(value) => {
                                                        props.onChange(value); 
                                                        cambioModelo(value);                                                        
                                                    }}
                                                    value={modelo}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    isDisabled={(marca !== "") ? false : true}
                                                    styles={styleNormal} 
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-modelo"
                                                key="k-modelo"                                                
                                                name='modelo'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-ipequipo">IP
                                            {
                                                (valores.traducciones.ipequipo === "" || valores.traducciones.ipequipo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.ipequipo}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input className="bootstrap-input" type='text'  />}                                                                                  
                                                control={control}
                                                id="i-ipequipo"
                                                key="k-ipequipo"
                                                defaultValue={registro ? registro.ipequipo : ''}
                                                name='ipequipo'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-fechaentrega">Delivery Date (YYYY/MM/DD)
                                            {
                                                (valores.traducciones.fechaentrega === "" || valores.traducciones.fechaentrega === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.fechaentrega}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                name="fechaentrega"
                                                id="i-fechaentrega"
                                                className="bootstrap-input"
                                                control={control}
                                                render={({ onChange, value }) => (
                                                    <DatePicker                                                        
                                                        selected={value}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        onChange={onChange}
                                                        className="bootstrap-input"                                                        
                                                    />
                                                )}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-descripcion">Description
                                            {
                                                (valores.traducciones.descripcion === "" || valores.traducciones.descripcion === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.descripcion}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<textarea className="bootstrap-input" rows="3" cols="50"></textarea>}
                                                control={control}
                                                id="i-descripcion"
                                                key="k-descripcion"
                                                defaultValue={registro ? registro.descripcion : ''}
                                                name='descripcion'
                                            />                             
                                        </div>
                                    </div>
                                </>
                            )
                        )
                        }
                        <br></br>
                        <div className="row">
                            <div style={{width: '60%', marginRight:'auto', marginLeft: 'auto'}}>
                                <button className="boton-from">Save</button>
                            </div>                   
                        </div> 
                        
                    </form>
                </div>
            </div> 
            <br></br>
            <br></br> 
            <div className="divder">  
                
            </div>         
        </>
    )
}

export default CreacionEquipoComunicacion
