import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import { obtenerValores, obtenerSala, actualizarSala, obtenerServidores } from '../functions/FuncSalaServidor'
import * as Opciones from './OpcionesSalaServidor'

import "../css/formularioAplicacion.css";
import "../css/formulariosala.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';

import Loading from '../Loading';
import Select from 'react-select'
import TablaRelacionServidorSala from './TablaRelacionServidorSala';

import { validarPrivilegioModificacion } from '../Commons/Functions'
import { style, styleNormal, styleDisable } from '../Commons/Styles'

const FormularioSalaServidor = ({id, token}) => {

    const [registro, setRegistro] = useState(null);    
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const [empresa, setEmpresa] = useState("");
    const [planta, setPlanta] = useState("");

    let privEscribir = sessionStorage.getItem('sala_escribir');
    let privLeer = sessionStorage.getItem('sala_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');
    let idiomaUser = sessionStorage.getItem('idioma');
    const [privModificar, setPrivModificar] = React.useState(false);
    
    const [servidores, setServidores] = useState(null);

    const [errorNombre, setErrorNombre] = useState(false);
    const [errorEmpresa, setErrorEmpresa] = useState(false);
    const [errorPlanta, setErrorPlanta] = useState(false);
    const [errorExtincionIndencios, setErrorExtincionIndencios] = useState(false);
    const [errorEquiposAire, setErrorEquiposAire] = useState(false);
    const [errorCapacidadAreaBlanca, setErrorCapacidadAreaBlanca] = useState(false);

    const onSubmit = (data) => {
        if(validarDatos(data)){
            swal("Form data", "Mandatory data must be entered", "warning");
        }
        else{
            swal({
                title: "Update confirmation",
                text: "Do you want to update the record?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion)
                {
                    const datosform = data;
                    let nombreusuario = sessionStorage.getItem('nombre');
                    datosform.modificadopor = nombreusuario;
                    datosform.fechaupdate = new Date();

                    await actualizarSala(datosform, registro, id, token).then((response) => {
                        if(response.cod === 1)
                        {
                            obtenerRegistro();
                            swal("Successful update", "", "success");
                        }
                        else if(response.cod === 0){
                            swal("Update error", response.mensaje, "error");
                        }
                        else {
                            swal("Update error", "An error occurred during the update. Please contact the administrator.", "error");
                        }
                    });     
                }
            })
        }

    }

    function mapResponseToRegistro(response){
        setEmpresa({"label": response.sala.empresa, "value": response.sala.empresa});
        setPlanta({"label": response.sala.planta, "value": response.sala.planta})
        
        setRegistro({
            "id_sala": response.sala.id_sala,
            "nombre": response.sala.nombre,
            "empresa": {"label": response.sala.empresa, "value": response.sala.empresa},
            "planta": {"label": response.sala.planta, "value": response.sala.planta},
            "localidad": response.sala.localidad,
            "pais": response.sala.pais,
            "negocio": response.sala.negocio,
            "extincionincendios": response.sala.extincionincendios,
            "equiposaire": response.sala.equiposaire,
            "capacidadareablanca": response.sala.capacidadareablanca,
            "observacion": response.sala.observacion,

            "grupoelectrogeno": (response.sala.grupoelectrogeno !== null) ? (response.sala.grupoelectrogeno === "YES") ? true : false : false,
            "deteccionincendio": (response.sala.deteccionincendio !== null) ? (response.sala.deteccionincendio === "YES") ? true : false : false,
            "alarmacentral": (response.sala.alarmacentral !== null) ? (response.sala.alarmacentral === "YES") ? true : false : false,                 
            "controlacceso": response.sala.controlacceso,                    
            "sensortemperatura": (response.sala.sensortemperatura !== null) ? (response.sala.sensortemperatura === "YES") ? true : false : false,

            "ups1_uso": response.sala.ups1_uso,
            "ups1_capacidad": response.sala.ups1_capacidad,
            "ups1_autonomia": response.sala.ups1_autonomia,
            "ups1_direccionip": response.sala.ups1_direccionip,
            "ups1_marca": (response.sala.ups1_marca !== null && response.sala.ups1_marca !== "") ? {"label": response.sala.ups1_marca, "value": response.sala.ups1_marca} : null,                 
            "ups1_tarjetared": (response.sala.ups1_tarjetared !== null) ? (response.sala.ups1_tarjetared === "YES") ? true : false : false,                   
            "ups1_monitoreo": (response.sala.ups1_monitoreo !== null) ? (response.sala.ups1_monitoreo === "YES") ? true : false : false,
            "ups1_fechapruebaups": (response.sala.ups1_fechapruebaups !== null && response.sala.ups1_fechapruebaups !== "") ? new Date(response.sala.ups1_fechapruebaups) : null,
            "ups1_fechaultimamantencion": (response.sala.ups1_fechaultimamantencion !== null && response.sala.ups1_fechaultimamantencion !== "") ? new Date(response.sala.ups1_fechaultimamantencion) : null,

            "ups2_uso": response.sala.ups2_uso,
            "ups2_capacidad": response.sala.ups2_capacidad,
            "ups2_autonomia": response.sala.ups2_autonomia,
            "ups2_direccionip": response.sala.ups2_direccionip,
            "ups2_marca": (response.sala.ups2_marca !== null && response.sala.ups2_marca !== "") ? {"label": response.sala.ups2_marca, "value": response.sala.ups2_marca} : null,                 
            "ups2_tarjetared": (response.sala.ups2_tarjetared !== null) ? (response.sala.ups2_tarjetared === "YES") ? true : false : false,                   
            "ups2_monitoreo": (response.sala.ups2_monitoreo !== null) ? (response.sala.ups2_monitoreo === "YES") ? true : false : false,
            "ups2_fechapruebaups": (response.sala.ups2_fechapruebaups !== null && response.sala.ups2_fechapruebaups !== "") ? new Date(response.sala.ups2_fechapruebaups) : null,
            "ups2_fechaultimamantencion": (response.sala.ups2_fechaultimamantencion !== null && response.sala.ups2_fechaultimamantencion !== "") ? new Date(response.sala.ups2_fechaultimamantencion) : null,

            "modificadopor": response.sala.modificadopor,
            "fechaupdate": (response.sala.fechaupdate !== null && response.sala.fechaupdate !== "") ? new Date(response.sala.fechaupdate) : null,
        })
    }

    async function obtenerRegistro(){
        await obtenerSala(id, token)
        .then((response) => {
            if(response.cod === 1){
                mapResponseToRegistro(response);
            }
        })
    }

    function validarDatos(data)
    {
        var errores = false;       
        
        if(data.nombre === null || data.nombre === "" || data.nombre === undefined) 
        {
            setErrorNombre(true);
            errores = true;
        }
        else setErrorNombre(false);

        if(data.empresa === null || data.empresa === "" || data.empresa === undefined) 
        {
            setErrorEmpresa(true);
            errores = true;
        }
        else setErrorEmpresa(false);

        if(data.planta === null || data.planta === "" || data.planta === undefined) 
        {
            setErrorPlanta(true);
            errores = true;
        }
        else setErrorPlanta(false);

        if(data.extincionincendios === null || data.extincionincendios === "" || data.extincionincendios === undefined) 
        {
            setErrorExtincionIndencios(true);
            errores = true;
        }
        else setErrorExtincionIndencios(false);

        if(data.equiposaire === null || data.equiposaire === "" || data.equiposaire === undefined) 
        {
            setErrorEquiposAire(true);
            errores = true;
        }
        else setErrorEquiposAire(false);

        if(data.capacidadareablanca === null || data.capacidadareablanca === "" || data.capacidadareablanca === undefined) 
        {
            setErrorCapacidadAreaBlanca(true);
            errores = true;
        }
        else setErrorCapacidadAreaBlanca(false);

        return errores;
    }

    const cambioEmpresa= (value) => { 
        if(value !== "" && value !== null){
            setEmpresa(value.value);            
            filtrarPlantas(value.value);            
        } else {
            setEmpresa(""); 
            setPlanta("");           
        }
    }

    const cambioPlanta= (value) => { 
        if(value !== "" && value !== null){
            setPlanta({"label": value.value, "value": value.value});
        } else {
            setPlanta("");
        }
    }

    function filtrarPlantas(empresa)
    {
        if(empresa !== "")
        {
            const filtrotipoArray = valores.filtroplantas;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.empresa === empresa) {
                        array.push({"label": p.planta, "value": p.planta});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.plantas = array; 
            setPlanta("");           
            setValores(valoresNuevo);
        }
    }

    async function actualizarServidores()
    {
        await obtenerServidores(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setServidores(response.servidores);
            }
        });
    }
    
    async function obtenerDatosBD()
    {
        var empresaSala = "";
        var paisSala = "";
        var localidadSala = "";

        await obtenerServidores(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setServidores(response.servidores);
            }
        });

        await obtenerSala(id, token)
        .then((response) => {
            if(response.cod === 1){

                validarPrivilegioModificacion(privEscribir, paisUser, localidadUser, "", response.sala, setPrivModificar );
                

                empresaSala = response.sala.empresa;
                paisSala = response.sala.pais;
                localidadSala = response.sala.localidad;
                
                mapResponseToRegistro(response);

            }
        })

        await obtenerValores(privEscribir, paisUser, localidadUser, "SERVER ROOM", idiomaUser, token)
        .then((response) => {
            
            console.log(response.traducciones)

            if(response.cod === 1)
            {
                const val = {
                    
                    "empresas": response.empresas,
                    "plantas": response.plantas,
                    "filtroplantas": response.filtroplantas,
                    "todasempresas": response.todasempresas,
                    "marcas": response.marcas,
                    "traducciones": response.traducciones
                }; 

                let array = [];

                const listas = ["empresas","plantas","marcas"];

                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                //PLANTAS
                array = [];
                val.filtroplantas.map((u) => {
                    if(u.empresa === empresaSala) array.push({"label": u.planta, "value": u.planta});
                })
                val.plantas = array;

                if(privEscribir === "1" && privLeer == "2")
                {
                    if(localidadSala !== localidadUser){
                        array = [];
                        val["todasempresas"].map((u) => {
                            array.push({"label": u, "value": u});
                        })
                        val["empresas"] = array;
                    } 
                }

                if(privEscribir === "2" && privLeer == "3")
                {
                    if(paisSala !== paisUser){
                        array = [];
                        val["todasempresas"].map((u) => {
                            array.push({"label": u, "value": u});
                        })
                        val["empresas"] = array;
                    } 
                }

                setValores(val);
            }
        });
    }

    React.useEffect(() => {
        if (registro !== null) {            

            Opciones.campos.forEach(c => {
                setValue(c, registro[c], {shouldValidate: true,shouldDirty: true})
            });
            
        }
        else obtenerDatosBD();
    }, [registro, valores]);

    if(registro === null || valores === null) return (<Loading />)
    else
    return (
        <div>
            <form 
                style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                autoComplete="off" onSubmit={handleSubmit(onSubmit)}
            >
                <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}}>
                    
                <div className="divinfosala">
                        <div className="containersala-up">
                            <div className="divinfosala-izq">
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-nombre">Room<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.g_nombre === "" || valores.traducciones.g_nombre === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_nombre}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                            control={control}
                                            id="i-nombre"
                                            key="k-nombre"
                                            defaultValue={registro ? registro.nombre : ''}
                                            name='nombre'
                                            style={(errorNombre) ? { borderColor: '#f90000'} : {}} 
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-empresa">Enterprise<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.g_empresa === "" || valores.traducciones.g_empresa === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_empresa}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                            options={valores.empresas}
                                            placeholder=""
                                            onChange={(value) => {
                                                props.onChange(value);
                                                cambioEmpresa(value);
                                                
                                            }}  
                                            isClearable={true}
                                            isDisabled={(privModificar) ? false : true}
                                            defaultValue={(registro) ? registro.empresa : null}
                                            styles={(errorEmpresa) ? style : (privModificar) ? styleNormal : styleDisable}     
                                            />} 
                                            control={control}
                                            id="i-empresa"
                                            key="k-empresa"                                    
                                            name='empresa'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-planta">Plant<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.g_planta === "" || valores.traducciones.g_planta === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_planta}</span>
                                                </div>
                                            )
                                        }                                        
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.plantas}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value)
                                                    cambioPlanta(value);  
                                                    
                                                }}  
                                                isClearable={true}
                                                value={planta}
                                                defaultValue={(planta) ? planta : ""}
                                                isDisabled={(empresa !== "") ? (privModificar) ? false : true : true}
                                                styles={(errorPlanta) ? style : (privModificar) ? styleNormal : styleDisable}
                                            />} 
                                            control={control}
                                            id="i-planta"
                                            key="k-planta"                                    
                                            name='planta'
                                        />                                
                                    </div>
                                </div>
                                <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-negocio">Business
                                            {
                                                (valores.traducciones.g_negocio === "" || valores.traducciones.g_negocio === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.g_negocio}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-negocio"
                                                key="k-negocio"
                                                defaultValue={registro ? registro.negocio : ''}
                                                name='negocio'                                                
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-localidad">Location
                                            {
                                                (valores.traducciones.g_localidad === "" || valores.traducciones.g_localidad === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.g_localidad}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-localidad"
                                                key="k-localidad"
                                                defaultValue={registro ? registro.localidad : ''}
                                                name='localidad'                                                
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-pais">Country
                                            {
                                                (valores.traducciones.g_pais === "" || valores.traducciones.g_pais === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.g_pais}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                                control={control}
                                                id="i-pais"
                                                key="k-pais"
                                                defaultValue={registro ? registro.pais : ''}
                                                name='pais'                                                
                                            /> 
                                        </div>
                                    </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-extincionincendios">Fire Extinguishing System<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.g_extincionincendios === "" || valores.traducciones.g_extincionincendios === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_extincionincendios}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                            control={control}
                                            id="i-extincionincendios"
                                            key="k-extincionincendios"
                                            defaultValue={registro ? registro.extincionincendios : ''}
                                            name='extincionincendios'
                                            style={(errorExtincionIndencios) ? { borderColor: '#f90000'} : {}} 
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-equiposaire">Air Equipments<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.g_equiposaire === "" || valores.traducciones.g_equiposaire === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_equiposaire}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                            control={control}
                                            id="i-equiposaire"
                                            key="k-equiposaire"
                                            defaultValue={registro ? registro.nombre : ''}
                                            name='equiposaire'
                                            style={(errorEquiposAire) ? { borderColor: '#f90000'} : {}} 
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-capacidadareablanca">Available Area Capacity<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.g_capacidadareablanca === "" || valores.traducciones.g_capacidadareablanca === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_capacidadareablanca}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                            control={control}
                                            id="i-capacidadareablanca"
                                            key="k-capacidadareablanca"
                                            defaultValue={registro ? registro.capacidadareablanca : ''}
                                            name='capacidadareablanca'
                                            style={(errorCapacidadAreaBlanca) ? { borderColor: '#f90000'} : {}} 
                                        />                              
                                    </div>
                                </div>
                            </div> 
                            <div className="divinfosala-der">                        
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-grupoelectrogeno">Generator Group
                                        {
                                            (valores.traducciones.g_grupoelectrogeno === "" || valores.traducciones.g_grupoelectrogeno === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_grupoelectrogeno}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            disabled={(!privModificar) ? 'disabled' : ''}
                                            type="checkbox"
                                            id="i-grupoelectrogeno"
                                            key="k-grupoelectrogeno" 
                                            name="grupoelectrogeno"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                              
                                    </div>
                                </div>   
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-deteccionincendio">Fire Detection System
                                        {
                                            (valores.traducciones.g_deteccionincendio === "" || valores.traducciones.g_deteccionincendio === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_deteccionincendio}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            disabled={(!privModificar) ? 'disabled' : ''}
                                            type="checkbox"
                                            id="i-deteccionincendio"
                                            key="k-deteccionincendio" 
                                            name="deteccionincendio"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                              
                                    </div>
                                </div>   
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-sensortemperatura">Sensor Temperature
                                        {
                                            (valores.traducciones.g_sensortemperatura === "" || valores.traducciones.g_sensortemperatura === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_sensortemperatura}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            disabled={(!privModificar) ? 'disabled' : ''}
                                            type="checkbox"
                                            id="i-sensortemperatura"
                                            key="k-sensortemperatura" 
                                            name="sensortemperatura"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                              
                                    </div>
                                </div> 
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-alarmacentral">&nbsp; &nbsp; &nbsp; Connected to Central Alarm
                                        {
                                            (valores.traducciones.g_alarmacentral === "" || valores.traducciones.g_alarmacentral === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_alarmacentral}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            disabled={(!privModificar) ? 'disabled' : ''}
                                            type="checkbox"
                                            id="i-alarmacentral"
                                            key="k-alarmacentral" 
                                            name="alarmacentral"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                              
                                    </div>
                                </div>   
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-controlacceso">Access Control
                                        {
                                            (valores.traducciones.g_controlacceso === "" || valores.traducciones.g_controlacceso === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_controlacceso}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                            control={control}
                                            id="i-controlacceso"
                                            key="k-controlacceso"
                                            defaultValue={registro ? registro.controlacceso : ''}
                                            name='controlacceso'
                                        />                                 
                                    </div>
                                </div> 
                                
                                <br></br>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-observacion">Observation
                                        {
                                            (valores.traducciones.g_observacion === "" || valores.traducciones.g_observacion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_observacion}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<textarea disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} rows="3" cols="50"></textarea>}
                                            control={control}
                                            id="i-observacion"
                                            key="k-observacion"
                                            defaultValue={registro ? registro.observacion : ''}
                                            name='observacion'
                                        />                             
                                    </div>                                
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-25">
                                        <label  htmlFor="i-modificadopor">Modified by</label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={'disabled'} className="bootstrap-input-disable" type='text'  />}                                          
                                            control={control}
                                            id="i-modificadopor"
                                            key="k-modificadopor"
                                            defaultValue={registro ? registro.modificadopor : ''}
                                            name='modificadopor'                                                        
                                        />                              
                                    </div>
                                </div>    
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-fechaupdate">Modified On (YYYY/MM/DD)</label>
                                    </div>
                                    <div className="col-75">
                                         <Controller
                                            name="fechaupdate"
                                            id="i-fechaupdate"
                                            className="bootstrap-input-disable"
                                            control={control}
                                            defaultValue={registro ? registro.fechaupdate : ''}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    disabled={'disabled'}
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className="bootstrap-input-disable"
                                                    locale="es"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                            </div>               
                        </div>
                    </div>
                    <div className="divups">
                        <div className="containerups-up-l">
                            <div className="divups-izq">
                                <div className='subtitulo-box'>
                                    <div className='subtitulo'>
                                        <label >UPS 1</label>
                                    </div>                    
                                </div>
                                <br></br>

                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_uso">Use
                                        {
                                            (valores.traducciones.ups_uso === "" || valores.traducciones.ups_uso === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_uso}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                            control={control}
                                            id="i-ups1_uso"
                                            key="k-ups1_uso"
                                            defaultValue={registro ? registro.ups1_uso : ''}
                                            name='ups1_uso'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_marca">Brand
                                        {
                                            (valores.traducciones.ups_marca === "" || valores.traducciones.ups_marca === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_marca}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">                                        
                                        <Controller
                                            render={(props) => <Select 
                                            options={valores.marcas}
                                            placeholder=""
                                            onChange={(value) => {
                                                props.onChange(value);                                                
                                            }}  
                                            isClearable={true}
                                            defaultValue={(registro) ? registro.ups1_marca : null}
                                            styles={(privModificar) ? styleNormal : styleDisable}
                                            isDisabled={(privModificar) ? false : true}
                                            />} 
                                            control={control}
                                            id="i-ups1_marca"
                                            key="k-ups1_marca"                                    
                                            name='ups1_marca'
                                        />                             
                                    </div>
                                </div>                                
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_capacidad">Capacity (KVA)
                                        {
                                            (valores.traducciones.ups_capacidad === "" || valores.traducciones.ups_capacidad === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_capacidad}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='number'  />}                                      
                                            control={control}
                                            id="i-ups1_capacidad"
                                            key="k-ups1_capacidad"
                                            defaultValue={registro ? registro.ups1_capacidad : ''}
                                            name='ups1_capacidad'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_autonomia">Autonomy (min)
                                        {
                                            (valores.traducciones.ups_autonomia === "" || valores.traducciones.ups_autonomia === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_autonomia}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='number'  />}                                      
                                            control={control}
                                            id="i-ups1_autonomia"
                                            key="k-ups1_autonomia"
                                            defaultValue={registro ? registro.ups1_autonomia : ''}
                                            name='ups1_autonomia'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_monitoreo">Monitoring
                                        {
                                            (valores.traducciones.ups_monitoreo === "" || valores.traducciones.ups_monitoreo === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_monitoreo}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            disabled={(!privModificar) ? 'disabled' : ''}
                                            type="checkbox"
                                            id="i-ups1_monitoreo"
                                            key="k-ups1_monitoreo" 
                                            name="ups1_monitoreo"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_tarjetared">Network Card
                                        {
                                            (valores.traducciones.ups_tarjetared === "" || valores.traducciones.ups_tarjetared === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_tarjetared}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            disabled={(!privModificar) ? 'disabled' : ''}
                                            type="checkbox"
                                            id="i-ups1_tarjetared"
                                            key="k-ups1_tarjetared" 
                                            name="ups1_tarjetared"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_direccionip">IP
                                        {
                                            (valores.traducciones.ups_direccionip === "" || valores.traducciones.ups_direccionip === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_direccionip}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                            control={control}
                                            id="i-ups1_direccionip"
                                            key="k-ups1_direccionip"
                                            defaultValue={registro ? registro.ups1_direccionip : ''}
                                            name='ups1_direccionip'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_fechapruebaups">Test Date
                                        {
                                            (valores.traducciones.ups_fechapruebaups === "" || valores.traducciones.ups_fechapruebaups === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_fechapruebaups}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            name="ups1_fechapruebaups"
                                            id="i-ups1_fechapruebaups"
                                            className="bootstrap-input"
                                            defaultValue={registro ? registro.ups1_fechapruebaups : ''}
                                            control={control}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className="bootstrap-input"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_fechaultimamantencion">Last Maintenance Date
                                        {
                                            (valores.traducciones.ups_fechaultimamantencion === "" || valores.traducciones.ups_fechaultimamantencion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_fechaultimamantencion}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            name="ups1_fechaultimamantencion"
                                            id="i-ups1_fechaultimamantencion"
                                            className="bootstrap-input"
                                            defaultValue={registro ? registro.ups1_fechaultimamantencion : ''}
                                            control={control}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className="bootstrap-input"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                            </div>                            
                            <div className="divups-der">
                                <div className='subtitulo-box'>
                                    <div className='subtitulo'>
                                        <label >UPS 2</label>
                                    </div>                    
                                </div>
                                <br></br>

                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_uso">Use
                                        {
                                            (valores.traducciones.ups_uso === "" || valores.traducciones.ups_uso === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_uso}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                            control={control}
                                            id="i-ups2_uso"
                                            key="k-ups2_uso"
                                            defaultValue={registro ? registro.ups2_uso : ''}
                                            name='ups2_uso'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_marca">Brand
                                        {
                                            (valores.traducciones.ups_marca === "" || valores.traducciones.ups_marca === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_marca}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                            options={valores.marcas}
                                            placeholder=""
                                            onChange={(value) => {
                                                props.onChange(value);                                                
                                            }}  
                                            isClearable={true}
                                            defaultValue={(registro) ? registro.ups2_marca : null}
                                            styles={(privModificar) ? styleNormal : styleDisable}
                                            isDisabled={(privModificar) ? false : true}
                                            />} 
                                            control={control}
                                            id="i-ups2_marca"
                                            key="k-ups2_marca"                                    
                                            name='ups2_marca'
                                        />                              
                                    </div>
                                </div>                                
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_capacidad">Capacity (KVA)
                                        {
                                            (valores.traducciones.ups_capacidad === "" || valores.traducciones.ups_capacidad === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_capacidad}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='number'  />}                                      
                                            control={control}
                                            id="i-ups2_capacidad"
                                            key="k-ups2_capacidad"
                                            defaultValue={registro ? registro.ups2_capacidad : ''}
                                            name='ups2_capacidad'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_autonomia">Autonomy (min)
                                        {
                                            (valores.traducciones.ups_autonomia === "" || valores.traducciones.ups_autonomia === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_autonomia}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='number'  />}                                      
                                            control={control}
                                            id="i-ups2_autonomia"
                                            key="k-ups2_autonomia"
                                            defaultValue={registro ? registro.ups2_autonomia : ''}
                                            name='ups2_autonomia'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_monitoreo">Monitoring
                                        {
                                            (valores.traducciones.ups_monitoreo === "" || valores.traducciones.ups_monitoreo === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_monitoreo}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            disabled={(!privModificar) ? 'disabled' : ''}
                                            type="checkbox"
                                            id="i-ups2_monitoreo"
                                            key="k-ups2_monitoreo" 
                                            name="ups2_monitoreo"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_tarjetared">Network Card
                                        {
                                            (valores.traducciones.ups_tarjetared === "" || valores.traducciones.ups_tarjetared === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_tarjetared}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            disabled={(!privModificar) ? 'disabled' : ''}
                                            type="checkbox"
                                            id="i-ups2_tarjetared"
                                            key="k-ups2_tarjetared" 
                                            name="ups2_tarjetared"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_direccionip">IP
                                        {
                                            (valores.traducciones.ups_direccionip === "" || valores.traducciones.ups_direccionip === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_direccionip}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}                                      
                                            control={control}
                                            id="i-ups2_direccionip"
                                            key="k-ups2_direccionip"
                                            defaultValue={registro ? registro.ups2_direccionip : ''}
                                            name='ups2_direccionip'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_fechapruebaups">Test Date
                                        {
                                            (valores.traducciones.ups_fechapruebaups === "" || valores.traducciones.ups_fechapruebaups === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_fechapruebaups}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            name="ups2_fechapruebaups"
                                            id="i-ups2_fechapruebaups"
                                            className="bootstrap-input"
                                            defaultValue={registro ? registro.ups2_fechapruebaups : ''}
                                            control={control}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className="bootstrap-input"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_fechaultimamantencion">Last Maintenance Date
                                        {
                                            (valores.traducciones.ups_fechaultimamantencion === "" || valores.traducciones.ups_fechaultimamantencion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_fechaultimamantencion}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            name="ups2_fechaultimamantencion"
                                            id="i-ups2_fechaultimamantencion"
                                            className="bootstrap-input"
                                            defaultValue={registro ? registro.ups2_fechaultimamantencion : ''}
                                            control={control}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className="bootstrap-input"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                            </div>            
                        </div>
                        <div className="containerups-up-r">
                            <TablaRelacionServidorSala rows={servidores} id={id} privModificar={privEscribir} actualizarServidores={actualizarServidores} nombreSala={registro.nombre} paisSala={registro.pais} localidadSala={registro.localidad} token={token}/>
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto', marginTop: '20px', float: 'left'}}>                     
                    <div className="row">
                        <div style={{width: '20%', marginRight:'auto', marginLeft: 'auto'}}>
                        {(privModificar) ? (<button className="boton-from">Save</button> ) : (<></>)}
                        </div>           
                    </div>
                    <br></br>                     
                </div>
            </form>            
        </div>
    )
}

export default FormularioSalaServidor
