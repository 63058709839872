import './App.css';
import * as Config from './Configuration';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Menu from './components/Menu';
import Servidor from './components/Servidor/Servidor';
import Login from './components/Login';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import FormServidor from './components/Servidor/FormServidor';
import CargaServidor from './components/Servidor/CargaServidor';
import Aplicacion from './components/Aplicacion/Aplicacion';
import FormCreateServidor from './components/Servidor/FormCreateServidor';
import FormDispositivoOT from './components/DispositivoOT/FormDispositivoOT';
import CargaDispositivoOT from './components/DispositivoOT/CargaDispositivoOT';
import DispositivoOT from './components/DispositivoOT/DispositivoOT';
import FormCreateDispositivoOT from './components/DispositivoOT/FormCreateDispositivoOT';
import FormAplicacion from './components/Aplicacion/FormAplicacion';
import FormCreateAplicacion from './components/Aplicacion/FormCreateAplicacion';
import AplicacionSccm from './components/Aplicacion/AplicacionSccm';
import CargaAplicacion from './components/Aplicacion/CargaAplicacion';
import Maestroaplicacion from './components/Maestros/MaestroAplicacion/Maestroaplicacion';
import EquiposComunicacion from './components/Comunicacion/EquiposComunicacion';
import FormEquipoComunicacion from './components/Comunicacion/FormEquipoComunicacion';
import FormCreacionEquipoComunicacion from './components/Comunicacion/FormCreacionEquipoComunicacion';
import CargaEquipoComunicacion from './components/Comunicacion/CargaEquipoComunicacion';
import OtrosDispositivos from './components/OtrosDispositivos/OtrosDispositivos';
import FormCreacionOtrosDispositivos from './components/OtrosDispositivos/FormCreacionOtrosDispositivos';
import FormOtroDispositivo from './components/OtrosDispositivos/FormOtroDispositivo';
import CargaOtroDispositivo from './components/OtrosDispositivos/CargaOtroDispositivo';
import EquiposEscritorio from './components/Equipos/EquiposEscritorio';
import FormCreacionEquiposEscritorio from './components/Equipos/FormCreacionEquiposEscritorio';
import CargaUsuarios from './components/Maestros/Usuario/CargaUsuarios';
import CargaPersonal from './components/Maestros/Personal/CargaPersonal';
import FormEquipoEscritorio from './components/Equipos/FormEquipoEscritorio';
import CargaEquiposEscritorio from './components/Equipos/CargaEquiposEscritorio';
import CargaAntivirusEquipo from './components/Maestros/AntivirusEquipo/CargaAntivirusEquipo';
import CargaUmbrella from './components/Maestros/MaestroUmbrella.js/CargaUmbrella';
import AplicacionSccmEquipo from './components/Aplicacion/AplicacionSccmEquipo';
import Usuario from './components/Maestros/Usuario/Usuario';
import MaestroGeneral from './components/Maestros/MaestrosGenerales/MaestroGeneral';
import EquiposEscritorioSccm from './components/Equipos/EquiposEscritorioSccm';
import ReporteEquipoEscritorio from './components/Reportes/ReporteEquipoEscritorio';
import ReporteServidor from './components/Reportes/Servidor/ReporteServidor';
import SalaSevidor from './components/SalaServidor/SalaSevidor';
import FormCreacionSalaServidor from './components/SalaServidor/FormCreacionSalaServidor';
import FormSalaServidor from './components/SalaServidor/FormSalaServidor';
import ReporteAplicacion from './components/Reportes/Aplicacion/ReporteAplicacion';
import Reemplazado from './components/Equipos/Reemplazado';
import CargaEquipoExcritorioSccm from './components/Equipos/CargaEquipoExcritorioSccm';
import ReporteQSenseServidores from './components/Reportes/Qsense/ReporteQSenseServidores';
import TablaCargasSccmEquipos from './components/Equipos/TablaCargasSccmEquipos';
import FormUsuario from './components/Maestros/Usuario/FormUsuario';
import Traduccion from './components/Maestros/Traduccion/Traduccion';
import DashboardQSense from './components/Reportes/Qsense/DashboardQSense';
import FormCreateCuentaSer from './components/CuentaServicio/FormCreateCuentaSer';
import CuentaServicio from './components/CuentaServicio/CuentaServicio';
import FormCuentaServicio from './components/CuentaServicio/FormCuentaServicio';
import CargaCuenta from './components/CuentaServicio/CargaCuenta';
import FormCreateCertificado from './components/Certificado/FormCreateCertificado';
import FormCertificado from './components/Certificado/FormCertificado';
import Certificado from './components/Certificado/Certificado';
import AplicacionBack from './components/AplicacionBack/AplicacionBack';
import FormAplicacionBack from './components/AplicacionBack/FormAplicacionBack';
import FormCreateAplicacionBack from './components/AplicacionBack/FormCreateAplicacionBack';
import CargaAplicacionBack from './components/AplicacionBack/CargaAplicacionBack';
import CorreoNotificaciones from './components/Maestros/CorreosNotificaciones/CorreoNotificaciones';
import AplicacionPermitida from './components/Maestros/AplicacionPermitida/AplicacionPermitida';

function App() {

  const pca = new PublicClientApplication(Config.configuration);

  return (
    <Router>
      <Switch>
        <Route path="/menu">
          <Menu instance={pca}/>
        </Route>
        {/*Aplicaciones Back*/}
        <Route path="/aplicacionesback/:id">          
          <FormAplicacionBack instance={pca}  t={Config.t}/>          
        </Route>
        <Route path="/aplicacionesback">          
          <AplicacionBack instance={pca}  t={Config.t}/>
        </Route>
        <Route path="/crearaplicacionback">          
          <FormCreateAplicacionBack instance={pca}  t={Config.t}/>
        </Route>
        <Route path="/cargaaplicacionesback">          
          <CargaAplicacionBack instance={pca} t={Config.t}/>          
        </Route>

        {/*Aplicaciones*/}
        <Route path="/aplicaciones/:id">
          <FormAplicacion instance={pca}  t={Config.t}/>          
        </Route>
        <Route path="/aplicaciones">
          <Aplicacion instance={pca}  t={Config.t}/>
        </Route>
        <Route path="/aplicacionessccm">
          <AplicacionSccm instance={pca}  t={Config.t}/>
        </Route>
        <Route path="/aplicacionessccmequipo">
          <AplicacionSccmEquipo instance={pca}  t={Config.t}/>
        </Route>
        <Route path="/crearaplicacion">
          <FormCreateAplicacion instance={pca}  t={Config.t}/>
        </Route>
        <Route path="/cargaaplicaciones">
          <CargaAplicacion instance={pca} t={Config.t}/>          
        </Route>
        {/*Servidores*/}
        <Route path="/servidores/:id">
          <FormServidor instance={pca} t={Config.t}/>
        </Route> 
        <Route path="/crearservidor">
          <FormCreateServidor instance={pca} t={Config.t}/>
        </Route>
        <Route path="/servidores">
          <Servidor instance={pca} t={Config.t}/>
        </Route> 
        <Route path="/cargaservidores">
          <CargaServidor instance={pca} t={Config.t}/>
        </Route>

        {/*Dispositivos OT*/}
        <Route path="/dispositivosot/:id">
          <FormDispositivoOT instance={pca} t={Config.t}/>
        </Route> 
        <Route path="/creardispositivoot">
          <FormCreateDispositivoOT instance={pca} t={Config.t}/>
        </Route>
        <Route path="/dispositivosot">
          <DispositivoOT instance={pca} t={Config.t}/>
        </Route> 
        <Route path="/cargadispositivosot">
          <CargaDispositivoOT instance={pca} t={Config.t}/>
        </Route>

        {/*Cuentas Servicio*/}
        <Route path="/cuentasservicio/:id">          
          <FormCuentaServicio instance={pca} t={Config.t}/>
        </Route>
        <Route path="/cuentasservicio">
          <CuentaServicio instance={pca} t={Config.t}/>
        </Route>  
        <Route path="/crearcuentaservicio">
          <FormCreateCuentaSer instance={pca} t={Config.t}/>
        </Route>
        <Route path="/cargacuentaservicio">
          <CargaCuenta instance={pca} t={Config.t}/>
        </Route>

         {/*Cuentas Servicio*/}
         <Route path="/certificado/:id"> 
          <FormCertificado instance={pca} t={Config.t}/>
        </Route>
        <Route path="/certificado">
          <Certificado instance={pca} t={Config.t}/>
        </Route>  
        <Route path="/crearcertificado">          
          <FormCreateCertificado instance={pca} t={Config.t}/>
        </Route>
        

        {/*EQUIPOS*/}
        <Route path="/equipos/:id">
          <FormEquipoEscritorio instance={pca}  t={Config.t}/>                 
        </Route>
        <Route path="/redireccion/:id">
          <Reemplazado instance={pca}  t={Config.t}/>                         
        </Route>
        <Route path="/equipos">
          <EquiposEscritorio instance={pca} t={Config.t}/>          
        </Route> 
        <Route path="/equipossccm">          
          <EquiposEscritorioSccm instance={pca} t={Config.t}/>          
        </Route> 
        <Route path="/crearequipo">
          <FormCreacionEquiposEscritorio instance={pca} t={Config.t}/>          
        </Route> 
        <Route path="/cargaequipos">
          <CargaEquiposEscritorio instance={pca} t={Config.t}/>         
        </Route> 
        <Route path="/cargaequipossccm">
          <CargaEquipoExcritorioSccm instance={pca} t={Config.t}/>         
        </Route> 

        {/*Maestro Usuario*/}
        <Route path="/users/:id">         
          <FormUsuario instance={pca}  t={Config.t}/>                 
        </Route>
        <Route path="/users">
          <Usuario instance={pca} t={Config.t}/>        
        </Route>
        <Route path="/maestrousuario">
          <CargaUsuarios instance={pca} t={Config.t}/>          
        </Route>

        {/*Maestros*/}
        <Route path="/maestroapp">
          <Maestroaplicacion instance={pca} t={Config.t}/>
        </Route>
        <Route path="/maestrogeneral">
          <MaestroGeneral instance={pca} t={Config.t}/>
        </Route>
        <Route path="/traducciones">          
          <Traduccion instance={pca} t={Config.t}/>
        </Route>
        <Route path="/aplicacionespermitidas">
          <AplicacionPermitida instance={pca} t={Config.t}/>          
        </Route>

        {/*Maestro Administrador*/}
        <Route path="/cargassccmdesktop">
          <TablaCargasSccmEquipos instance={pca} t={Config.t}/>          
        </Route>
        <Route path="/cargamaestroper">
          <CargaPersonal instance={pca} t={Config.t}/>          
        </Route>
        <Route path="/cargaantivirusequipo">
          <CargaAntivirusEquipo instance={pca} t={Config.t}/>         
        </Route>
        <Route path="/cargaumbrella">
          <CargaUmbrella instance={pca} t={Config.t}/>        
        </Route>
        <Route path="/correoscc">          
          <CorreoNotificaciones instance={pca} t={Config.t}/>
        </Route>
       

        {/*Comunicaciones*/}
        <Route path="/comunicaciones/:id">
          <FormEquipoComunicacion instance={pca} t={Config.t}/>
        </Route>
        <Route path="/comunicaciones">
          <EquiposComunicacion instance={pca} t={Config.t}/>
        </Route>
        <Route path="/crearequipocomunicacion">
          <FormCreacionEquipoComunicacion instance={pca} t={Config.t}/>          
        </Route>
        <Route path="/cargaequipocomunicacion">          
          <CargaEquipoComunicacion instance={pca} t={Config.t}/>
        </Route>

        {/*Otros*/}
        <Route path="/dispositivos/:id">
          <FormOtroDispositivo instance={pca} t={Config.t}/>         
        </Route>
        <Route path="/dispositivos">
          <OtrosDispositivos instance={pca} t={Config.t}/>          
        </Route>
        <Route path="/creardispositivo">
          <FormCreacionOtrosDispositivos instance={pca} t={Config.t} />                  
        </Route>
        <Route path="/cargadispositivos"> 
          <CargaOtroDispositivo instance={pca} t={Config.t}/>
        </Route>

        {/*SALAS*/}
        <Route path="/salaservidor/:id">          
          <FormSalaServidor instance={pca} t={Config.t}/>         
        </Route>
        <Route path="/salasservidores">          
          <SalaSevidor instance={pca} t={Config.t}/>
        </Route>
        <Route path="/crearsalaservidor">   
          <FormCreacionSalaServidor instance={pca} t={Config.t}/>
        </Route>
        

        {/*REPORTES*/}
        <Route path="/reporteequipoescritorio">
          <ReporteEquipoEscritorio instance={pca} t={Config.t}/>
        </Route>
        <Route path="/reporteservidor">          
          <ReporteServidor instance={pca} t={Config.t}/>
        </Route>
        {/*<Route path="/reporteDispositivoOT">          
          <ReporteDispositivoOT instance={pca} t={Config.t}/>
        </Route>*/}
        <Route path="/reporteaplicacion">
          <ReporteAplicacion instance={pca} t={Config.t}/>
        </Route>
        <Route path="/reporteqsenseservidor">
          <ReporteQSenseServidores instance={pca} />          
        </Route>
        <Route path="/qsense">          
          <DashboardQSense instance={pca} />          
        </Route>

        <Route path="/">
          <MsalProvider instance={pca} t={Config.t}>
            <Login t={Config.t}/>
          </MsalProvider>
        </Route>      
      </Switch>    
    </Router>
  );
}

export default App;
