import * as Rutas from '../RutasServicios'

async function tabla(token)
{ 
    const url = Rutas.URL_Maestros + "api/aplicacionpermitida/tabla";    
    //const url = "https://localhost:44329/api/comunicacion/valores";    
    
    const request= {
        
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

export async function obtenerTabla(token) {
    try {
        var respuesta = await tabla(token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function tablaTotales(token)
{ 
    const url = Rutas.URL_Maestros + "api/aplicacionpermitida/tablaTotales";    
    //const url = "https://localhost:44329/api/aplicacionpermitida/tablaTotales";    
    
    const request= {
        
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

export async function obtenerTablaTotales(token) {
    try {
        var respuesta = await tablaTotales(token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function valores(token)
{ 
    const url = Rutas.URL_Maestros + "api/aplicacionpermitida/valores";    
    //const url = "https://localhost:44329/api/comunicacion/valores";    
    
    const request= {
        
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

export async function obtenerValores(token) {
    try {
        var respuesta = await valores(token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function crear(datos, token)
{ 
    const url = Rutas.URL_Maestros + "api/aplicacionpermitida/crear";    
    //const url = "https://localhost:44367/api/aplicacionpermitida/crear";    
    
    const request= {
        "nombre": datos.nombre,
        "familia": datos.familia.value,
        "condicion": datos.condicion.value
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

export async function crearAplicacionPermitida(datos, token) {
    try {
        var respuesta = await crear(datos, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function actualizar(datos, id, token)
{ 
    const url = Rutas.URL_Maestros + "api/aplicacionpermitida/actualizar";    
    //const url = "https://localhost:44367/api/aplicacionpermitida/actualizar";    
    
    const request= {
        "id_aplicacionpermitida": id,
        "nombre": datos.nombre,
        "familia": datos.familia.value,
        "condicion": datos.condicion.value
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

export async function actualizarAplicacionPermitida(datos, id, token) {
    try {
        var respuesta = await actualizar(datos, id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function eliminar(id, token)
{ 
    const url = Rutas.URL_Maestros + "api/aplicacionpermitida/eliminar";    
    //const url = "https://localhost:44367/api/aplicacionpermitida/eliminar";    
    
    const request= {
        "id_aplicacionpermitida": id        
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

export async function eliminarAplicacionPermitida(id, token) {
    try {
        var respuesta = await eliminar(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}