import * as Rutas from '../RutasServicios'

async function filtrosOtrosDispositivos(token)
{ 
    const url = Rutas.URL_OtrosDispositivos + "filtros"; 
    //const url = "https://localhost:44385/api/otrodispo/filtros";    
    
    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function valoresOtrosDispositivos(nivel, pais, localidad, modulo, idioma, token)
{ 
    const url = Rutas.URL_OtrosDispositivos + "valores";    
    //const url = "https://localhost:44385/api/otrodispo/valores"; 

    const request= {        
        "nivel": nivel,        
        "pais": pais,
        "localidad": localidad,
        "modulo": modulo,
        "idioma": idioma   
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function registrosTabla(tipo, estado, condicion, pais, localidad, planta, numeroserie, nivel, paisuser, localidaduser,  token)
{ 
    const url = Rutas.URL_OtrosDispositivos + "tabla";    
    //const url = "https://localhost:44385/api/otrodispo/tabla"; 

    const request= {
        "tipo": tipo,
        "estado": estado,
        "condicion": condicion,
        "pais": pais,
        "localidad": localidad,
        "planta": planta,
        "numeroserie": numeroserie,
        "nivel": nivel,
        "paisuser": paisuser,
        "localidaduser": localidaduser
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

async function crear(data, token)
{
    const url = Rutas.URL_OtrosDispositivos + "crear";    
    //const url = "https://localhost:44385/api/otrodispo/crear";  

    const reg = { 
        "tipoequipo": data.tipoequipo.toString().toUpperCase(),
        "numeroserie": data.numeroserie.toString().toUpperCase(),
        "estado": data.estado.value.toString().toUpperCase(),
        "condicion": data.condicion.value.toString().toUpperCase(),
        "propiedadde": data.propiedadde.value.toString().toUpperCase(),
        "empresa": data.empresa.value.toString().toUpperCase(),
        "planta": data.planta.value.toString().toUpperCase(),
        "marca": data.marca.value.toString().toUpperCase(),
        "modelo": data.modelo.value.toString().toUpperCase(),
        "descripcion": data.descripcion.toString().toUpperCase()        
    };

    reg.modificadopor = data.modificadopor.toString().toUpperCase();
    reg.fechaupdate = (data.fechaupdate !== null && data.fechaupdate !== "") ? new Date(data.fechaupdate).toISOString().substring(0, 10) : null;

    reg.fechacompra = (data.fechacompra !== null && data.fechacompra !== undefined && data.fechacompra !== "") ? new Date(data.fechacompra).toISOString().substring(0, 10) : null;
    reg.fechaarriendo = (data.fechaarriendo !== null && data.fechaarriendo !== undefined && data.fechaarriendo !== "") ? new Date(data.fechaarriendo).toISOString().substring(0, 10) : null;
    reg.fecharevision = (data.fecharevision !== null && data.fecharevision !== undefined && data.fecharevision !== "") ? new Date(data.fecharevision).toISOString().substring(0, 10) : null;

    if(data.sistemaoperativo !== null && data.sistemaoperativo !== undefined && data.sistemaoperativo !== "") reg.sistemaoperativo = data.sistemaoperativo.value.toString().toUpperCase();
    if(data.version !== null && data.version !== undefined && data.version !== "") reg.version = data.version.value.toString().toUpperCase();

    reg.mac = data.mac.toString().toUpperCase();
    reg.ipequipo = data.ipequipo.toString().toUpperCase();
    reg.condicionrevision = data.condicionrevision.toString().toUpperCase();
    reg.observaciones = data.observaciones.toString().toUpperCase();
    reg.hostname = data.hostname.toString().toUpperCase();
    reg.arrendadoa = data.arrendadoa.toString().toUpperCase();

    var bRequest = JSON.stringify(reg);
 
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function dispositivo(id, token)
{ 
    const url = Rutas.URL_OtrosDispositivos + "dispositivo";    
    //const url = "https://localhost:44385/api/otrodispo/dispositivo"; 

    const request= {
        "id": id           
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function actualizar(data, dataAnterior, id, token)
{ 
    const url = Rutas.URL_OtrosDispositivos + "actualizar";    
    //const url = "https://localhost:44385/api/otrodispo/actualizar";  

    const request = {
        "id_otrodispositivo": id,
        "tipoequipo": data.tipoequipo.value.toString().toUpperCase(),
        "numeroserie": data.numeroserie.toString().toUpperCase()
    };   
    
    if(data.estado !== undefined) request.estado = (data.estado !== dataAnterior.estado) ? data.estado.value.toString().toUpperCase() : "";
    if(data.condicion !== undefined) request.condicion = (data.condicion !== dataAnterior.condicion) ? data.condicion.value.toString().toUpperCase() : "";
    if(data.propiedadde !== undefined) request.propiedadde = (data.propiedadde !== dataAnterior.propiedadde) ? data.propiedadde.value.toString().toUpperCase() : "";
    if(data.planta !== undefined) request.planta = (data.planta !== dataAnterior.planta) ? data.planta.value.toString().toUpperCase() : "";
    if(data.empresa !== undefined) request.empresa = (data.empresa !== dataAnterior.empresa) ? data.empresa.value.toString().toUpperCase() : "";
    if(data.marca !== undefined) request.marca = (data.marca !== dataAnterior.marca) ? data.marca.value.toString().toUpperCase() : "";
    if(data.modelo !== undefined) request.modelo = (data.modelo !== dataAnterior.modelo) ? data.modelo.value.toString().toUpperCase() : "";
        
    request.modificadopor = data.modificadopor.toString().toUpperCase();
    request.fechaupdate = new Date().toISOString().substring(0, 10);
    request.descripcion = (data.descripcion !== dataAnterior.descripcion) ? data.descripcion.toString().toUpperCase() : "";
    
    if(data.sistemaoperativo !== undefined) request.sistemaoperativo = (data.sistemaoperativo !== dataAnterior.sistemaoperativo) ? (data.sistemaoperativo !== null && data.sistemaoperativo !== "") ? data.sistemaoperativo.value.toString().toUpperCase() : "esnull" : "";
    if(data.version !== undefined) request.version = (data.version !== dataAnterior.version) ? (data.version !== null && data.version !== "") ? data.version.value.toString().toUpperCase() : "esnull" : "";
    
    request.fechacompra = (data.fechacompra !== dataAnterior.fechacompra) ? (data.fechacompra !== null && dataAnterior.fechacompra !== null) ? new Date(data.fechacompra).toISOString().substring(0, 10): "esnull" : "";
    request.fechaarriendo = (data.fechaarriendo !== dataAnterior.fechaarriendo) ? (data.fechaarriendo !== null && dataAnterior.fechaarriendo !== null) ? new Date(data.fechaarriendo).toISOString().substring(0, 10): "esnull" : "";
    request.fecharevision = (data.fecharevision !== dataAnterior.fecharevision) ? (data.fecharevision !== null && dataAnterior.fecharevision !== null) ? new Date(data.fecharevision).toISOString().substring(0, 10): "esnull" : "";

    request.mac = (data.mac !== dataAnterior.mac) ? (data.mac !== "") ? data.mac.toString().toUpperCase() : "esnull" : "";
    request.ipequipo = (data.ipequipo !== dataAnterior.ipequipo) ? (data.ipequipo !== "") ? data.ipequipo.toString().toUpperCase() : "esnull" : "";
    request.condicionrevision = (data.condicionrevision !== dataAnterior.condicionrevision) ? (data.condicionrevision !== "") ? data.condicionrevision.toString().toUpperCase() : "esnull" : "";
    request.observaciones = (data.observaciones !== dataAnterior.observaciones) ? (data.observaciones !== "") ? data.observaciones.toString().toUpperCase() : "esnull" : "";
    request.hostname = (data.hostname !== dataAnterior.hostname) ? (data.hostname !== "") ? data.hostname.toString().toUpperCase() : "esnull" : "";
    request.arrendadoa = (data.arrendadoa !== dataAnterior.arrendadoa) ? (data.arrendadoa !== "") ? data.arrendadoa.toString().toUpperCase() : "esnull" : "";    
    

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};     
}

async function subirArchivoExcel(file64, nombre, usuario, token)
{ 
    const url = Rutas.URL_OtrosDispositivos + "subirexcel";      
    //const url = "https://localhost:44385/api/otrodispo/subirexcel";

    const data = {
        "title": nombre,
        "file": file64,
        "desc": "archivo de carga excel",
        "usuario": usuario
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function obtenerCargasExcel(usuario, token)
{ 
    const url = Rutas.URL_OtrosDispositivos + "cargas";      
    //const url = "https://localhost:44385/api/otrodispo/cargas";    

    const data = {        
        "usuario": usuario
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function eliminarDispositivo(id, token)
{ 
    const url = Rutas.URL_OtrosDispositivos + "eliminar";    
    //const url = "https://localhost:44385/api/otrodispo/eliminar";       
    
    const data = {        
        "id": id
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function eliminarDispositivoRequest(id, token) {
    try {
        var respuesta = await eliminarDispositivo(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function cargasExcel(usuario, token) {
    try {
        var respuesta = await obtenerCargasExcel(usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function uploadExcel(file64, nombre, usuario, token) {
    try {
        var respuesta = await subirArchivoExcel(file64, nombre, usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function actualizarDispositivo(data, dataAnterior, id, token) {
    try {
        var respuesta = await actualizar(data, dataAnterior, id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerDispositivo(id, token) {
    try {
        var respuesta = await dispositivo(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearDispositivo(data, token) {
    try {
        var respuesta = await crear(data, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerValores(nivel, pais, localidad, modulo, idioma, token) {
    try {
        var respuesta = await valoresOtrosDispositivos(nivel, pais, localidad, modulo, idioma, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerTabla(tipo, estado, condicion, pais, localidad, planta, numeroserie, nivel, paisuser, localidaduser, token) {
    try {
        var respuesta = await registrosTabla(tipo, estado, condicion, pais, localidad, planta, numeroserie, nivel, paisuser, localidaduser, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerFiltros(token) {
    try {
        var respuesta = await filtrosOtrosDispositivos(token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function tablaBitacoras(tipo, estado, condicion, pais, localidad, planta, numeroserie, nivel, paisuser, localidaduser,  token)
{ 
    const url = Rutas.URL_OtrosDispositivos + "bitacoras";    
    //const url = "https://localhost:44385/api/otrodispo/tabla"; 

    const request= {
        "tipo": tipo,
        "estado": estado,
        "condicion": condicion,
        "pais": pais,
        "localidad": localidad,
        "planta": planta,
        "numeroserie": numeroserie,
        "nivel": nivel,
        "paisuser": paisuser,
        "localidaduser": localidaduser
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

export async function obtenerTablaBitacoras(tipo, estado, condicion, pais, localidad, planta, numeroserie, nivel, paisuser, localidaduser, token) {
    try {
        var respuesta = await tablaBitacoras(tipo, estado, condicion, pais, localidad, planta, numeroserie, nivel, paisuser, localidaduser, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}