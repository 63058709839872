import React from 'react'
import { useParams } from "react-router-dom";
import Sidebar from '../Sidebar'
import * as TiIcons from 'react-icons/ti';
import * as AiIcons from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import FormularioEquipoComunicacion from './FormularioEquipoComunicacion';
import swal from 'sweetalert';
import { eliminarEquipoRequest } from '../functions/FuncComunicacion'
import { eliminarBitacorasRegistroRequest } from '../functions/FuncBitacora'
import ModalDocumentos from '../ModalDocumentos';
import Bitacora from '../Bitacora/Bitacora';


import { ContainerForm, ButtonVolver, ContainerFormSmall, ButtonEliminacion } from '../Commons/Styles'

const FormEquipoComunicacion = ({instance, t}) => {
    let { id } = useParams();
    const history = useHistory();
    let priv = sessionStorage.getItem('com_leer');
    let privEliminacion = sessionStorage.getItem('com_eliminar');

    function volver()
    {
        history.push("/comunicaciones");
    }

    async function eliminar()
    {
        await eliminarEquipoRequest(id, t).then((response) => {
            if(response.cod === 1)
            {
                eliminarBitacorasRegistroRequest(id, "Comunicacion", t);
                swal("Delete success", "", "success").then(() => {history.push("/comunicaciones")});
            }
            else {
                swal("Delete Error", response.mensaje, "error");
            }
        });
    }

    function eliminarRegistro()
    {
        swal({
            title: "Delete Confirmation",
            text: "You want delete this equipment?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((afirmacion) => {
            if(afirmacion)
            {
                eliminar();       
            }
        })
    }

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });
    
    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <>        
            <Sidebar instance={instance} despliegue={false}/>
            <ContainerForm>
                <div className="headerformulario">
                    <div className="headerform-izq">
                        <ButtonVolver onClick={volver} className="tooltipboton">
                        <TiIcons.TiArrowBack/>             
                        <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>
                    </div>
                    <div className="headerform-mid">
                        <div className="divtituloform">
                            <label className="tituloformulario">Equipment Form</label>
                        </div>                        
                    </div>
                    <div className="headerform-der">
                    {
                        (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>)
                        : (
                        <ButtonEliminacion onClick={eliminarRegistro} className="tooltipboton">
                            <AiIcons.AiFillDelete/>
                            <span className="tooltiptextboton">{"Delete"}</span> 
                        </ButtonEliminacion>)
                    }
                    {
                        (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>)
                        : (<Bitacora tipo={"Comunicacion"} id={id} token={t} />)
                    }
                    <ModalDocumentos tipo={"Comunicacion"} id={id} token={t}/>
                    </div>
                </div>
                <ContainerFormSmall>
                    <FormularioEquipoComunicacion id={id} token={t}/>
                </ContainerFormSmall>
                
                
            </ContainerForm>            
        </>    
    )
}

export default FormEquipoComunicacion
