import React from 'react'
import Sidebar from '../Sidebar'
import TablaEquiposEscritorio from './TablaEquiposEscritorio';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import TablaCargaSccmEquiposDetalle from './TablaCargaSccmEquiposDetalle';

const TablaCargasSccmEquipos = ({instance, t}) => {
    
    
    let email = sessionStorage.getItem('email');
    const history = useHistory();
    let priv = sessionStorage.getItem('mas_leer');
    
    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });

    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <>
            {(email !== null)
            ?
            (<>
                <Sidebar instance={instance} despliegue={false}/>                
                <div className="headerformulario">
                    <div className="headerform-izq">
                        
                    </div>
                    <div className="headerform-mid">
                        <div className="divtituloform">
                            <label className="tituloformulario">Loads Desktops Sccm List</label>
                        </div>                        
                    </div>
                    <div className="headerform-der">                        
                    </div>
                </div>
                
                <TablaCargaSccmEquiposDetalle token={t}/>
            </>)
            :
            (<>
                <Sidebar instance={instance}/>
            </>)
            }
        </>
    )
}

export default TablaCargasSccmEquipos
