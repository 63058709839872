import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";

import { obtenerValores, crearDispositivo} from '../functions/FuncOtrosDispositivos'

import "../css/formularioAplicacion.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import Loading from '../Loading';
import Select from 'react-select'
import { validarTextoVacio } from '../functions/MetodosComunes'

import { style, styleNormal } from '../Commons/Styles'

const CreacionOtroDispositivo = ({token}) => {

    const history = useHistory();
    const [registro, setRegistro] = useState(null);    
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const [tipoform, setTipoform] = useState("");
    const [marca, setMarca] = useState("");
    const [modelo, setModelo] = useState("");

    const [empresa, setEmpresa] = useState("");
    const [planta, setPlanta] = useState(""); 

    const [sistema, setSistema] = useState("");
    const [version, setVersion] = useState("");    
    const [estado, setEstado] = useState("");
    const [condicion, setCondicion] = useState("");

    const [errorTipo, setErrorTipo] = useState(false);
    const [errorNumeroSerie, setErrorNumeroSerie] = useState(false);
    const [errorEstado, setErrorEstado] = useState(false); 
    const [errorCondicion, setErrorCondicion] = useState(false);
    const [errorPropiedad, setErrorPropiedad] = useState(false);
    const [errorEmpresa, setErrorEmpresa] = useState(false);
    const [errorPlanta, setErrorPlanta] = useState(false);     
    const [errorMarca, setErrorMarca] = useState(false);
    const [errorModelo, setErrorModelo] = useState(false);
    const [errorDescripcion, setErrorDescripcion] = useState(false); 

    let privilegio = sessionStorage.getItem('dis_crear');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');
    let idiomaUser = sessionStorage.getItem('idioma');

    async function obtenerDatosBD()
    {
        await obtenerValores(privilegio, paisUser, localidadUser, "OTHER DEVICE", idiomaUser, token)
        .then((response) => {
            
            if(response.cod === 1)
            {
                const val = {
                    "estados": response.estados,
                    "condiciones": response.condiciones,
                    "filtrocondiciones": response.filtrocondiciones,
                    "propiedades": response.propiedades,
                    "paises": response.paises,
                    "localidades": response.localidades,
                    "filtrolocalidad": response.filtrolocalidad,
                    "empresas": response.empresas,
                    "plantas": response.plantas,
                    "filtroplanta": response.filtroplanta,
                    "tipos": response.tipos,
                    "sistemasoperativos": response.sistemasoperativos,
                    "versiones": response.versiones,
                    "filtroversion": response.filtroversion,
                    "marcas": response.marcas,
                    "modelos": response.modelos,
                    "filtromodelo": response.filtromodelo,  
                    "traducciones": response.traducciones                  
                }; 

                let array = [];

                const listas = ["estados", "condiciones","propiedades",
                "paises","localidades","empresas",
                "plantas","tipos","sistemasoperativos",
                "versiones","marcas","modelos"];

                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                setValores(val);
            }
        });
    }    

    const cambioEstado = (value) => {
        if(value !== "" && value !== null){
            setEstado(value.value);            
            filtrarCondiciones(value.value);            
        } else {
            setEstado(""); 
            setCondicion("");           
        }
    }

    const cambioCondicion= (value) => { 
        if(value !== "" && value !== null){
            setCondicion({"label": value.value, "value": value.value});
        } else {
            setCondicion("");
        }
    }

    function filtrarCondiciones(estado)
    {
        if(validarTextoVacio(estado))
        {
            console.log(estado)
            const filtrotipoArray = valores.filtrocondiciones;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.estado === estado) {
                        array.push({"label": p.condicion, "value": p.condicion});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.condiciones = array; 
            console.log(valoresNuevo.condiciones)
            setCondicion("");           
            setValores(valoresNuevo);
        }
    }

    const cambioFormulario = (value) => { 
        if(value !== "" && value !== null){
            setTipoform(value.value);            
        } else {
            setTipoform("");            
        }
    }

    const cambioMarca = (value) => { 
        if(value !== "" && value !== null){
            setMarca(value.value);            
            filtrarModelos(value.value);            
        } else {
            setMarca(""); 
            setModelo("");           
        }
    }

    const cambioModelo= (value) => { 
        if(value !== "" && value !== null){
            setModelo({"label": value.value, "value": value.value});
        } else {
            setModelo("");
        }
    }

    function filtrarModelos(marca)
    {
        if(marca !== "")
        {
            const filtrotipoArray = valores.filtromodelo;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.marca === marca) {
                        array.push({"label": p.modelo, "value": p.modelo});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.modelos = array; 
            setModelo("");           
            setValores(valoresNuevo);
        }
    }

    const cambioSistema= (value) => { 
        if(value !== "" && value !== null){
            setSistema(value.value);            
            filtrarVersiones(value.value);            
        } else {
            setSistema(""); 
            setVersion("");           
        }
    }

    const cambioVersion= (value) => { 
        if(value !== "" && value !== null){
            setVersion({"label": value.value, "value": value.value});
        } else {
            setVersion("");
        }
    }

    function filtrarVersiones(sistemaoperativo)
    {
        if(sistemaoperativo !== "")
        {
            const filtrotipoArray = valores.filtroversion;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.sistemaoperativo === sistemaoperativo) {
                        array.push({"label": p.version, "value": p.version});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.versiones = array; 
            setVersion("");           
            setValores(valoresNuevo);
        }
    }

    const cambioEmpresa= (value) => { 
        if(value !== "" && value !== null){
            setEmpresa(value.value);            
            filtrarPlantas(value.value);            
        } else {
            setEmpresa(""); 
            setPlanta("");           
        }
    }

    const cambioPlanta= (value) => { 
        if(value !== "" && value !== null){
            setPlanta({"label": value.value, "value": value.value});
        } else {
            setPlanta("");
        }
    }

    function filtrarPlantas(empresa)
    {
        if(empresa !== "")
        {
            const filtrotipoArray = valores.filtroplanta;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.empresa === empresa) {
                        array.push({"label": p.planta, "value": p.planta});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.plantas = array; 
            setPlanta("");           
            setValores(valoresNuevo);
        }
    }

    React.useEffect(() => {
        if (valores !== null) {

        }
        else obtenerDatosBD();
    }, []);

    function validarDatos(data)
    {
        var errores = false;
        if(tipoform === "") 
        {   
            setErrorTipo(true);
            errores = true;
        }
        else setErrorTipo(false);

        if(!validarTextoVacio(data.numeroserie)) 
        {
            setErrorNumeroSerie(true);
            errores = true;
        }
        else setErrorNumeroSerie(false);

        if(!validarTextoVacio(estado)) 
        {
            setErrorEstado(true);
            errores = true;
        }
        else setErrorEstado(false);

        if(!validarTextoVacio(condicion)) 
        {
            setErrorCondicion(true);
            errores = true;
        }
        else setErrorCondicion(false);

        if(!validarTextoVacio(data.propiedadde)) 
        {
            setErrorPropiedad(true);
            errores = true;
        }
        else setErrorPropiedad(false);

        if(!validarTextoVacio(data.empresa))
        {
            setErrorEmpresa(true);
            errores = true;
        }
        else setErrorEmpresa(false);

        if(!validarTextoVacio(data.planta))
        {
            setErrorPlanta(true);
            errores = true;
        }
        else setErrorPlanta(false);

        if(!validarTextoVacio(data.marca))
        {
            setErrorMarca(true);
            errores = true;
        }
        else setErrorMarca(false);

        if(!validarTextoVacio(data.modelo))
        {
            setErrorModelo(true);
            errores = true;
        }
        else setErrorModelo(false);

        if(!validarTextoVacio(data.descripcion))
        {
            setErrorDescripcion(true);
            errores = true;
        }
        else setErrorDescripcion(false);

        return errores;
    }

    const onSubmit = (data) => {        
        const validacion = validarDatos(data);
        if(validacion)
        {
            swal("Form data", "Mandatory data must be entered", "warning");
        }
        else{
            swal({
                title: "Creation confirmation",
                text: "Do you want to create the record?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion)
                {
                    const datosform = data;

                    let nombreusuario = sessionStorage.getItem('nombre');
                    datosform.modificadopor = nombreusuario;
                    datosform.fechaupdate = new Date();
                    datosform.tipoequipo = tipoform;                   
                    
                    await crearDispositivo(datosform, token).then((response) => {
                        
                        if(response.cod === 1)
                        {
                            swal("Successful creation", "", "success").then(() => {
                                history.push("/dispositivos/"+response.id);
                            });
                        }
                        else swal("Creation Error", response.mensaje, "error");                        
                    });
                }
            })
        }

    }

    if(valores === null) return (<Loading />)
    else
    return (
        <>
            <div className="divizq">
                <div className="container">
                    <form 
                        style={{width: '70%', marginRight:'auto', marginLeft: 'auto'}} 
                        autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-tipoequipo">Device Type<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.tipoequipo === "" || valores.traducciones.tipoequipo === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.tipoequipo}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.tipos}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value);
                                            cambioFormulario(value);
                                        }}  
                                        isClearable={true}
                                        styles={(errorTipo) ? style : styleNormal}                                        
                                    />} 
                                    control={control}
                                    id="i-tipoequipo"
                                    key="k-tipoequipo"                                    
                                    name='tipoequipo'
                                />                              
                            </div>
                        </div>
                        {
                            (tipoform === "") ? <></>
                            :
                            (
                                <>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-numeroserie">Serial Number<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.numeroserie === "" || valores.traducciones.numeroserie === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.numeroserie}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input className="bootstrap-input" type='text'  />}                                      
                                                control={control}
                                                id="i-numeroserie"
                                                key="k-numeroserie"
                                                defaultValue={registro ? registro.numeroserie : ''}
                                                name='numeroserie'
                                                style={(errorNumeroSerie) ? { borderColor: '#f90000'} : {}} 
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-estado">State<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.estado === "" || valores.traducciones.estado === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.estado}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.estados}
                                                    onChange={(value) => {
                                                        props.onChange(value);   
                                                        cambioEstado(value);                                                     
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    styles={(errorEstado) ? style : styleNormal}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-estado"
                                                key="k-estado"                                                
                                                name='estado'
                                            /> 
                                        </div>
                                    </div> 
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-condicion">Condition<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.condicion === "" || valores.traducciones.condicion === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.condicion}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.condiciones}
                                                    onChange={(value) => {
                                                        props.onChange(value); 
                                                        cambioCondicion(value);                                                       
                                                    }}
                                                    placeholder=""
                                                    value={condicion}
                                                    isClearable={true} 
                                                    isDisabled={(validarTextoVacio(estado)) ? false : true}
                                                    styles={(errorCondicion) ? style : styleNormal}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-condicion"
                                                key="k-condicion"                                                
                                                name='condicion'
                                            /> 
                                        </div>
                                    </div> 
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-propiedadde">Property of<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.propiedadde === "" || valores.traducciones.propiedadde === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.propiedadde}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.propiedades}
                                                    onChange={(value) => {
                                                        props.onChange(value);                                                        
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    styles={(errorPropiedad) ? style : styleNormal}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-propiedadde"
                                                key="k-propiedadde"                                                
                                                name='propiedadde'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-planta">Enterprise<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.empresa === "" || valores.traducciones.empresa === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.empresa}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.empresas}
                                                    onChange={(value) => {
                                                        props.onChange(value); 
                                                        cambioEmpresa(value);                                                         
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    styles={(errorEmpresa) ? style : styleNormal}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-empresa"
                                                key="k-empresa"                                                
                                                name='empresa'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-planta">Plant/Office<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.planta === "" || valores.traducciones.planta === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.planta}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.plantas}
                                                    onChange={(value) => {
                                                        props.onChange(value); 
                                                        cambioPlanta(value);                                                                                                             
                                                    }}
                                                    placeholder=""
                                                    value={planta}
                                                    isClearable={true} 
                                                    isDisabled={(empresa !== "") ? false : true}
                                                    styles={(errorPlanta) ? style : styleNormal}
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-planta"
                                                key="k-planta"                                                
                                                name='planta'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-marca">Brand<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.marca === "" || valores.traducciones.marca === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.marca}</span>
                                                    </div>
                                                )
                                            }  
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.marcas}
                                                    placeholder=""
                                                    onChange={(value) => {
                                                        props.onChange(value);
                                                        cambioMarca(value);                                                        
                                                    }}
                                                    isClearable={true} 
                                                    styles={(errorMarca) ? style : styleNormal}
                                                    control={control}
                                                />} 
                                                control={control} 
                                                id="i-marca"
                                                key="k-marca"                                                
                                                name='marca'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-modelo">Model<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.modelo === "" || valores.traducciones.modelo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.modelo}</span>
                                                    </div>
                                                )
                                            }  
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.modelos}
                                                    onChange={(value) => {
                                                        props.onChange(value);
                                                        cambioModelo(value);                                                         
                                                    }}
                                                    placeholder=""
                                                    value={modelo}
                                                    isClearable={true} 
                                                    isDisabled={(marca !== "") ? false : true}
                                                    styles={(errorModelo) ? style : styleNormal} 
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-modelo"
                                                key="k-modelo"                                                
                                                name='modelo'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label style={{fontWeight: 'bold'}} htmlFor="i-descripcion">Description / User<label style={{color: 'red'}}>{"*"}</label>
                                            {
                                                (valores.traducciones.descripcion === "" || valores.traducciones.descripcion === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.descripcion}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input className="bootstrap-input" type='text'  />}                                      
                                                control={control}
                                                id="i-descripcion"
                                                key="k-descripcion"
                                                defaultValue={registro ? registro.descripcion : ''}
                                                name='descripcion'
                                                style={(errorDescripcion) ? { borderColor: '#f90000'} : {}} 
                                            /> 
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            (tipoform === "") ? <></>
                            :
                            (
                                <>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-hostname">Hostname
                                            {
                                                (valores.traducciones.hostname === "" || valores.traducciones.hostname === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.hostname}</span>
                                                    </div>
                                                )
                                            } 
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input className="bootstrap-input" type='text'  />}                                      
                                                control={control}
                                                id="i-hostname"
                                                key="k-hostname"
                                                defaultValue={registro ? registro.hostname : ''}
                                                name='hostname'                                                 
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-sistemaoperativo">Operating System
                                            {
                                                (valores.traducciones.sistemaoperativo === "" || valores.traducciones.sistemaoperativo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.sistemaoperativo}</span>
                                                    </div>
                                                )
                                            } 
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.sistemasoperativos}
                                                    onChange={(value) => {
                                                        props.onChange(value);
                                                        cambioSistema(value);                                                        
                                                    }}
                                                    placeholder=""
                                                    isClearable={true} 
                                                    styles={styleNormal} 
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-sistemaoperativo"
                                                key="k-sistemaoperativo"                                                
                                                name='sistemaoperativo'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-version">Operating System Version
                                            {
                                                (valores.traducciones.version === "" || valores.traducciones.version === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.version}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                render={(props) => <Select 
                                                    options={valores.versiones}
                                                    onChange={(value) => {
                                                        props.onChange(value); 
                                                        cambioVersion(value);                                                         
                                                    }}
                                                    placeholder=""
                                                    value={version}
                                                    isDisabled={(sistema !== "") ? false : true}
                                                    isClearable={true} 
                                                    styles={styleNormal} 
                                                    control={control}
                                                    />} 
                                                control={control} 
                                                id="i-version"
                                                key="k-version"                                                
                                                name='version'
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-mac">MAC Address
                                            {
                                                (valores.traducciones.mac === "" || valores.traducciones.mac === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.mac}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input className="bootstrap-input" type='text'  />}                                      
                                                control={control}
                                                id="i-mac"
                                                key="k-mac"
                                                defaultValue={registro ? registro.mac : ''}
                                                name='mac'                                                 
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-ipequipo">IP / Number
                                            {
                                                (valores.traducciones.ipequipo === "" || valores.traducciones.ipequipo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.ipequipo}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input className="bootstrap-input" type='text'  />}                                      
                                                control={control}
                                                id="i-ipequipo"
                                                key="k-ipequipo"
                                                defaultValue={registro ? registro.ipequipo : ''}
                                                name='ipequipo'                                                 
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-fechacompra">Purchase Date (YYYY/MM/DD)
                                            {
                                                (valores.traducciones.fechacompra === "" || valores.traducciones.fechacompra === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.fechacompra}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                name="fechacompra"
                                                id="i-fechacompra"
                                                className="bootstrap-input"
                                                control={control}
                                                render={({ onChange, value }) => (
                                                    <DatePicker                                                        
                                                        selected={value}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        onChange={onChange}
                                                        className="bootstrap-input"                                                        
                                                    />
                                                )}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-fechaarriendo">Lease Date (YYYY/MM/DD)
                                            {
                                                (valores.traducciones.fechaarriendo === "" || valores.traducciones.fechaarriendo === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.fechaarriendo}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                name="fechaarriendo"
                                                id="i-fechaarriendo"
                                                className="bootstrap-input"
                                                control={control}
                                                render={({ onChange, value }) => (
                                                    <DatePicker                                                        
                                                        selected={value}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        onChange={onChange}
                                                        className="bootstrap-input"                                                        
                                                    />
                                                )}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-fecharevision">Review Date (YYYY/MM/DD)
                                            {
                                                (valores.traducciones.fecharevision === "" || valores.traducciones.fecharevision === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.fecharevision}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                name="fecharevision"
                                                id="i-fecharevision"
                                                className="bootstrap-input"
                                                control={control}
                                                render={({ onChange, value }) => (
                                                    <DatePicker                                                        
                                                        selected={value}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        onChange={onChange}
                                                        className="bootstrap-input"                                                        
                                                    />
                                                )}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-condicionrevision">Review Condition
                                            {
                                                (valores.traducciones.condicionrevision === "" || valores.traducciones.condicionrevision === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.condicionrevision}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input className="bootstrap-input" type='text'  />}                                      
                                                control={control}
                                                id="i-condicionrevision"
                                                key="k-condicionrevision"
                                                defaultValue={registro ? registro.condicionrevision : ''}
                                                name='condicionrevision'                                                 
                                            /> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-observaciones">Observation
                                            {
                                                (valores.traducciones.observaciones === "" || valores.traducciones.observaciones === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.observaciones}</span>
                                                    </div>
                                                )
                                            }
                                            </label>
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<textarea className="bootstrap-input" rows="3" cols="50"></textarea>}
                                                control={control}
                                                id="i-observaciones"
                                                key="k-observaciones"
                                                defaultValue={registro ? registro.observaciones : ''}
                                                name='observaciones'
                                            />                             
                                        </div>
                                    </div>                                    
                                    <div className="row">
                                        <div className="col-25">
                                            <label htmlFor="i-arrendadoa">Leased to
                                            {
                                                (valores.traducciones.arrendadoa === "" || valores.traducciones.arrendadoa === null) ? (<></>)
                                                : (
                                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                                        <span class="tooltiptext">{valores.traducciones.arrendadoa}</span>
                                                    </div>
                                                )
                                            }
                                            </label> 
                                        </div>
                                        <div className="col-75">
                                            <Controller
                                                as={<input className="bootstrap-input" type='text'  />}                                      
                                                control={control}
                                                id="i-arrendadoa"
                                                key="k-arrendadoa"
                                                defaultValue={registro ? registro.arrendadoa : ''}
                                                name='arrendadoa'                                                 
                                            /> 
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            (tipoform !== "") ? (
                                <>
                                    <br></br>
                                    <div className="row">
                                        <div style={{width: '60%', marginRight:'auto', marginLeft: 'auto'}}>
                                            <button className="boton-from">Save</button>
                                        </div>     
                                    </div>  
                                </>
                            )
                            : (<></>)
                        }
                    </form>
                </div>
            </div>
        </>
    )
}

export default CreacionOtroDispositivo
