import React from 'react'
import ReactExport from "react-export-excel";
import styled from 'styled-components';
import * as RiIcons from 'react-icons/ri';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ButtonExcel = styled.button`
    width: 50px;
    margin-left: auto;
    margin-right: 10px;
    height: 40px;
    background-color: #234e81;
    color: white;
    font-size: 20px;
    border-radius: 5px;  
    border-color: #0d385b;
    cursor: pointer;
    float: right;

    :hover {
        background: #7DAACC;
    }

`;

const ExportarExcelAplicacionesRel = ({rows, columnas, nombreArchivo, nombreSheet, subtitulo}) => {
    return(
        <ExcelFile 
            element={ 
                <ButtonExcel>
                    
                    <div className="tooltipboton">
                        <RiIcons.RiFileExcel2Fill>                                                
                        </RiIcons.RiFileExcel2Fill>
                        <span className="tooltiptextboton">{subtitulo}</span>
                    </div>
                </ButtonExcel>
            } 
            filename={nombreArchivo} 
            fileExtension="xlsx">
            <ExcelSheet data={rows} name={nombreSheet}>
                {
                    columnas.map((c) => {
                        return (<ExcelColumn key={"key-" + c.nombre} label={c.nombre} value={c.valor} style={{font: {bold: true}}} />)
                    })
                }                            
            </ExcelSheet>         
        </ExcelFile>
    )
}

export default ExportarExcelAplicacionesRel
