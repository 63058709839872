import * as Rutas from '../RutasServicios'

async function notificacionEquipo(usuario, destinatario, equipo, modificaciones, token)
{     
    const url = Rutas.URL_Maestros + "api/notificacion/enviar"; 
    //const url = "https://localhost:44367/api/notificacion/enviar";

    var mensaje = "Transfer of equipment: <br/>"
    mensaje = mensaje + "Send By: " + usuario + " <br/><br/>";
    mensaje = mensaje + "User: " + equipo.nombreusuario + " <br/>";
    mensaje = mensaje + "Type: " + equipo.tipoequipo + " <br/>";
    mensaje = mensaje + "Serial number: " + equipo.numeroserie + " <br/>";
    mensaje = mensaje + "Brand: " + equipo.marca + " <br/>";
    mensaje = mensaje + "Model: " + equipo.modelo + " <br/>";
    mensaje = mensaje + "From " + equipo.planta + ", " + equipo.localidad + ", " + equipo.pais + " <br/>";
    mensaje = mensaje + "To " + modificaciones.planta + ", " + modificaciones.localidad + ", " + modificaciones.pais + " <br/> <br/>";
    mensaje = mensaje + "Cordially, <br/> ITAM";

    const data = {        
        "mensaje": mensaje,
        "asunto": "ITAM - Equipment transfer",
        "destinatarios": destinatario
    }

    console.log(data)

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

export async function enviarNotificacionEquipos(usuario, destinatario, equipo, modificaciones, token) {
    try {
        var respuesta = await notificacionEquipo(usuario, destinatario, equipo, modificaciones, token);
        return respuesta;        
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

async function tabla(token)
{     
    const url = Rutas.URL_Maestros + "api/correonotificacioncc/tabla"; 
    //const url = "https://localhost:44367/api/notificacion/enviar";
    
    var respuesta = await fetch(url, {
        method: "POST",        
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

export async function obtenerTabla(token) {
    try {
        var respuesta = await tabla(token);
        return respuesta;        
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

async function actualizar(id, data, token)
{     
    const url = Rutas.URL_Maestros + "api/correonotificacioncc/actualizar"; 
    //const url = "https://localhost:44367/api/notificacion/enviar";


    const update = { 
        "id_correonofiticacion": id.toString()
    };

    update.cc1 = (data.cc1 !== "") ? data.cc1.replace( /\s/g, '') : null;
    update.cc2 = (data.cc2 !== "") ? data.cc2.replace( /\s/g, '') : "esnull";

    var bRequest = JSON.stringify(update, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,        
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

export async function actualizarNotificacion(id, data, token) {
    try {
        var respuesta = await actualizar(id, data, token);
        return respuesta;        
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}