import React, { useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import Modal from 'react-modal'
import "../css/modalservidores.css";

import swal from 'sweetalert';
import Loading from '../Loading';
import Select from 'react-select'
import * as FcIcons from 'react-icons/fc';
import * as BsIcons from 'react-icons/bs';

import { obtenerValoresModal, validarEquipoModal, reemplazarEquipoModal } from '../functions/FuncEquipoEscritorio'

import { style, styleNormal, styleDisable, ButtonRelacion } from '../Commons/Styles'

const ButtonConfirmar = styled.button`
    margin-left: auto;
    margin-right: auto;
    background-color: #EA7600; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #D35400;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    color: white;
    float:right;
`;

const ButtonCancelar = styled.button`
    margin-left: auto;
    margin-right: auto;
    background-color: #EA7600; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #D35400;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    color: white;
    
`;

const ModalReemplazo = ({id, redireccion, token}) => {
    
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});
    const history = useHistory();

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validado, setValidado] = useState(false);
    const [valores, setValores] = useState(null);
    const [numeroSerie, setNumeroSerie] = useState("");
    const [nombreUsuario, setNombreUsuario] = useState("");
    const [tipoEquipo, setTipoEquipo] = useState("");
    const [condicion, setCondicion] = useState(null);
    const [nombreEquipo, setNombreEquipo] = useState("");

    const [errorCondicion, setErrorCondicion] = useState(false);
    const [errorNumeroSerie, setErrorNumeroSerie] = useState(false);
    const [errorNombreEquipo, setErrorNombreEquipo] = useState(false);

    let privilegio = sessionStorage.getItem('esc_escribir');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');

    function abrirModal()
    {
        setCondicion(null);
        setNombreUsuario("");
        setNombreEquipo("");
        setTipoEquipo("");
        setValores(null);
        setModalOpen(true);
    }

    async function obtenerValores() {

        await obtenerValoresModal(privilegio, paisUser, localidadUser, token)
        .then((response) => {
            if(response.cod === 1)
            {
                const val = {                    
                    "condiciones": response.condiciones,
                    "tiposequipo": response.tiposequipo,
                    "filtrocondiciones": response.filtrocondiciones,                          
                }; 

                let array = [];

                const listas = ["condiciones", "tiposequipo"];

                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                //CONDICIONES
                array = [];
                val.filtrocondiciones.map((u) => {
                    if(u.estado === "INACTIVE") array.push({"label": u.condicion, "value": u.condicion});
                })
                val.condiciones = array;

                setValores(val);
            }
        });
    }

    function cancelar(){
        swal({
            title: "Cancellation confirmation",
            text: "Do you want to cancel the changes?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((afirmacion) => {
            if (afirmacion) {
                setValores(null);
                setCondicion(null);
                setNombreUsuario("");
                setTipoEquipo("");
                setNombreEquipo("");
                setModalOpen(false);
            }                   
        });
    }

    async function validacion(){
        await validarEquipoModal(numeroSerie, privilegio, paisUser, localidadUser, token)
        .then((response) => {
            if(response.cod === 1)
            {
                setValidado(true);
                setNombreUsuario(response.equipo.nombreusuario)
                setTipoEquipo(response.equipo.tipoequipo)
            }
            else{
                setCondicion(null);
                setNombreUsuario("");
                setNombreEquipo("");
                swal("Validation", response.mensaje, "warning");
            } 
        });
    }

    function validarNumeroSerie() {
        if(numeroSerie === "" || numeroSerie === null) {
            swal("Validation", "A value must be entered for Serial Number", "warning");
        }
        else validacion();
    }

    const OnChangeNumeroSerie = (event) => {
        setNumeroSerie(event.target.value);
        if(validado)
        {
            setValidado(false);
            setCondicion(null);
            setNombreUsuario("");
            setNombreEquipo("");
        } 
    }

    const OnChangeCondicion = (value) => {
        if(value === null || value === undefined){
            setCondicion(null);
        }
        else setCondicion({"label": value.value, "value": value.value})
    }

    const OnChangeNombreEquipo = (event) => {
        setNombreEquipo(event.target.value);
    }

    const onSubmit = (data) => {
        if(!validado) {
            swal("Validation", "Please validate the serial number first", "warning");
        }
        else if(validarDatos()){
            swal("Form data", "Mandatory data must be entered", "warning");
        }
        else{
            swal({
                title: "Replace confirmation",
                text: "Do you want replace this Desktop? ",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion)
                {
                    
                    let modificadopor = sessionStorage.getItem('nombre');                    
                    let fechaupdate = new Date();

                    await reemplazarEquipoModal(id, numeroSerie, nombreEquipo, condicion, modificadopor, fechaupdate, token).then((response) => {

                        console.log(response)

                        if(response.cod === 1)
                        {
                            swal("Successful replace", "", "success")
                            .then(() => {
                                setModalOpen(false);
                                redireccion(response.id);
                            });
                        }
                        else if(response.cod === 0){
                            swal("Replace error", response.mensaje, "error");
                        }
                        else {
                            swal("Replace error", "An error occurred during the update. Please contact the administrator.", "error");
                        }
                    });  

                }
            })
        }
    }

    function validarDatos()
    {
        var errores = false; 

        if(condicion=== null || condicion === "" || condicion === undefined) 
        {
            setErrorCondicion(true);
            errores = true;
        }
        else setErrorCondicion(false);

        if(nombreEquipo === null || nombreEquipo === "" || nombreEquipo === undefined) 
        {
            setErrorNombreEquipo(true);
            errores = true;
        }
        else setErrorNombreEquipo(false);

        return errores;
    }


    React.useEffect(() => {        
        console.log(errorCondicion)
       if(valores === null) obtenerValores();
    }, [valores])
    
    return (
        <>
            <ButtonRelacion type="button" onClick={abrirModal} className="tooltipboton">
                <BsIcons.BsArrowLeftRight />
                <span className="tooltiptextboton">{"Equipment replacement"}</span>             
            </ButtonRelacion>
            <Modal 
                isOpen={modalOpen}
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.75)'
                    },
                    content: {
                        width: '40%',
                        height: '70%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        position: 'absolute',                        
                        border: '1px solid #ccc',
                        background: '#fff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '20px'
                    }
                  }}
            >
                {
                    (valores === null) ? (<Loading />)
                    : (
                        <form 
                            style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                            autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                        >
                            <br></br>
                            <br></br>
                            <div className="headermodal">
                                <div className="titulo-modalser">
                                    <label className="textotitulo-modal">Replacement Form</label>
                                </div>
                            </div> 
                            <div className="bodymodal">
                                <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-25">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-numeroserie">New Serial Number*</label> 
                                            </div>
                                            <div className="col-75">
                                                <>
                                                <Controller
                                                    render={(props) =>
                                                        <input
                                                            className="bootstrap-input-busqueda"
                                                            type='text'  
                                                            onChange={(value) => {
                                                                props.onChange(value); 
                                                                OnChangeNumeroSerie(value);                                                                                                           
                                                            }}
                                                            style={(errorNumeroSerie) ? { borderColor: '#f90000'} : {}} 
                                                        />                                                                           
                                                    }                                                                          
                                                    control={control}
                                                    id="i-numeroserie"
                                                    key="k-numeroserie"                                    
                                                    name='numeroserie'                 
                                                />
                                                <button type="button" className="boton-buscarusuario tooltipboton" onClick={validarNumeroSerie}>
                                                    <FcIcons.FcSearch />
                                                    <span className="tooltiptextboton">{"Search"}</span> 
                                                </button>
                                                </>                      
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-25">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-nombreusuario">Current User*</label> 
                                            </div>
                                            <div className="col-75">
                                                <Controller
                                                    render={(props) =>
                                                        <input 
                                                            disabled={'disabled'} 
                                                            className={"bootstrap-input-disable"}
                                                            type='text'
                                                            onChange={(value) => {
                                                                props.onChange(value);                                                        
                                                            }}                                                            
                                                            value={nombreUsuario}                                            
                                                        />                                                                           
                                                    }                                       
                                                    control={control}
                                                    id="i-nombreusuario"
                                                    key="k-nombreusuario"                                    
                                                    name='nombreusuario'
                                                />                           
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-25">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-tipoequipo">New Dektop Type*</label> 
                                            </div>
                                            <div className="col-75">
                                                <Controller
                                                    render={(props) =>
                                                        <input 
                                                            disabled={'disabled'} 
                                                            className={"bootstrap-input-disable"}
                                                            type='text'
                                                            onChange={(value) => {
                                                                props.onChange(value);                                                        
                                                            }}                                                            
                                                            value={tipoEquipo}                                            
                                                        />                                                                           
                                                    }                                       
                                                    control={control}
                                                    id="i-tipoequipo"
                                                    key="k-tipoequipo"                                    
                                                    name='tipoequipo'
                                                />                           
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-25">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-nombreequipo">New Desktop Name*</label> 
                                            </div>
                                            <div className="col-75">
                                                <Controller
                                                    render={(props) =>
                                                        <input 
                                                            disabled={(validado) ? '' : 'disabled'} 
                                                            className={(validado) ? "bootstrap-input": "bootstrap-input-disable"}
                                                            type='text'
                                                            onChange={(value) => {
                                                                props.onChange(value); 
                                                                OnChangeNombreEquipo(value);                                                                                      
                                                            }}                                                            
                                                            value={nombreEquipo} 
                                                            style={(errorNombreEquipo) ? { borderColor: '#f90000'} : {}}                                           
                                                        />                                                                           
                                                    }                                       
                                                    control={control}
                                                    id="i-nombreequipo"
                                                    key="k-nombreequipo"                                    
                                                    name='nombreequipo'
                                                />                           
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-25">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-condicion">Condition of Replaced Equipment*</label> 
                                            </div>
                                            <div className="col-75">
                                                <Controller
                                                    render={(props) => <Select 
                                                        options={valores.condiciones}
                                                        placeholder=""
                                                        onChange={(value) => {
                                                            props.onChange(value);
                                                            OnChangeCondicion(value);  
                                                        }}  
                                                        isClearable={true}
                                                        isDisabled={(validado) ? false : true}
                                                        styles={(errorCondicion) ? style : (validado) ? styleNormal : styleDisable} 
                                                        value={condicion}                                         
                                                    />} 
                                                    control={control}
                                                    id="i-condicion"
                                                    key="k-condicion"                                    
                                                    name='condicion'
                                                />                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footermodal">                        
                                    <div className="divbotonmodalreemplazo">
                                        <ButtonCancelar type="button"  onClick={cancelar}>Return</ButtonCancelar>
                                        <ButtonConfirmar>Confirm</ButtonConfirmar>
                                    </div>
                            </div>
                        </form>
                    )
                }
                            
            </Modal>
        </>
    )
}

export default ModalReemplazo
