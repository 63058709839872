export const estadosFiltroTabla = [    
    "OPERATIVO",
    "DE BAJA"  
]

export const camposExcelCarga = [   
    {nombre:"FILA", valor:"fila"},
    {nombre:"DETALLE", valor:"detalle"}    
]

export const camposExcel = [
    {nombre:"SERIAL NUMBER", valor:"numeroserie"},
    {nombre:"STATE", valor:"estado"},
    {nombre:"CONDITION", valor:"condicion"},
    {nombre:"ENTERPRISE", valor:"empresa"},
    {nombre:"PLANT", valor:"planta"},
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"LOCATION", valor:"localidad"},
    {nombre:"DESKTOP NAME", valor:"nombreequipo"},
    {nombre:"DESKTOP TYPE", valor:"tipoequipo"},
    {nombre:"BRAND", valor:"marca"},
    {nombre:"MODEL", valor:"modelo"},
    {nombre:"NETWORK CONDITION", valor:"condicionred"},

    {nombre:"PURCHASE ORDER", valor:"ordencompra"},
    {nombre:"IP ADDRESS", valor:"direccionip"},
    {nombre:"HDD", valor:"discoduro"},
    {nombre:"RAM", valor:"memoriaram"},
    {nombre:"PROCESSOR TYPE", valor:"tipoprocesador"},
    {nombre:"PROCESSOR SPEED", valor:"velocidadprocesador"},
    {nombre:"DOCKING", valor:"docking"},
    {nombre:"MONITOR", valor:"monitor"},
    {nombre:"RECEPTION DATE", valor:"fecharecepcion"},
    {nombre:"DELIVERY DATE", valor:"fechaentrega"},
    {nombre:"WARRANTY EXPIRATION DATE", valor:"fechaexpiracion"},
    {nombre:"MAINTENANCE DATE", valor:"fechamantencion"},
    {nombre:"OFFLINE DATE", valor:"fechafueralinea"},
    {nombre:"OBSERVATIONS", valor:"observaciones"},
    
    {nombre:"USER ID", valor:"idusuario"},
    {nombre:"USER NAME", valor:"nombreusuario"},
    {nombre:"EMAIL", valor:"correo"},
    {nombre:"DIVISION", valor:"division"},
    {nombre:"DIVISION PLANT", valor:"divisionplanta"},
    {nombre:"COPY DATE", valor:"divisionfecha"},
    {nombre:"POSITION", valor:"cargo"},
    {nombre:"POSITION ROLE", valor:"cargorol"},
    {nombre:"COST CENTER", valor:"centrocosto"},
    {nombre:"PRINCIPAL MANAGEMENT", valor:"gerenciaprincipal"},
    {nombre:"DIRECT MANAGEMENT", valor:"gerenciadirecta"},
    {nombre:"AREA SUB MANAGEMENT", valor:"subgerenciaarea"},
    {nombre:"VIP", valor:"vip"},
    {nombre:"ADMINISTRATOR", valor:"administrador"},
    {nombre:"ADMINISTRATOR JUSTIFICATION", valor:"justificacionadmin"},    
    {nombre:"EXCLUDED FROM PATCHES", valor:"excluidoparches"},
    {nombre:"CAUSE EXCLUDED FROM PATCHES", valor:"motivoexcluidoparches"},
    {nombre:"UNLOCKED USB", valor:"usbdesbloqueado"},
    {nombre:"CAUSE UNLOCKED USB", valor:"motivousbdesbloqueado"},
    {nombre:"UBICATION", valor:"ubicacion"},


    //Siguientes son solo info
    {nombre:"BUSINESS", valor:"negocio"},
    {nombre:"LAST USER", valor:"ultimousuario"},
    {nombre:"OPERATING SYSTEM", valor:"sistemaoperativo"},
    {nombre:"COMPILATION", valor:"compilacion"},
    {nombre:"INVENTORY DATE", valor:"fechainvenratiosccm"},
    {nombre:"LAST INVETORY DAYS", valor:"diasultimoinventario"},
    {nombre:"DESKTOP NAME SCCM", valor:"nombreequiposccm"},
    {nombre:"RAM SCCM", valor:"memoriaramsccm"},
    {nombre:"FREE SPACE", valor:"espaciolibresccm"},
    {nombre:"TOTAL SPACE", valor:"espaciototalsccm"},
    {nombre:"DISK SPACE", valor:"espaciodiscossccm"},
    {nombre:"MAC", valor:"mac"},
    {nombre:"IP SCCM", valor:"ipsccm"},
    {nombre:"LAST RESET", valor:"ultimoreinicio"},

    {nombre:"AD STATE", valor:"avestado"},
    {nombre:"AD DATE", valor:"avfechacontacto"},
    {nombre:"AD OU", valor:"avpolitica"},
    {nombre:"AD OS", valor:"avversionsensor"},
    {nombre:"UMB STATE", valor:"ubestado"},
    {nombre:"UMB DATE", valor:"ubfecha"},
    {nombre:"ID DESKTOP", valor:"id_equipoescritorio"},
    
    {nombre:"MODIFIED BY", valor:"modificadopor"},
    {nombre:"MODIFICATION DATE", valor:"fechaupdate"},
    {nombre:"ASSIGMENT DOCUMENT", valor:"documentoentrega"},
    {nombre:"EMPLOYEE MASTER USER", valor:"usuariomaestropersonal"}
]

export const camposExcelSecundario = [
    {nombre:"SERIAL NUMBER", valor:"numeroserie"},
    {nombre:"STATE", valor:"estado"},
    {nombre:"CONDITION", valor:"condicion"},
    {nombre:"ENTERPRISE", valor:"empresa"},
    {nombre:"PLANT", valor:"planta"},
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"LOCATION", valor:"localidad"},
    {nombre:"DESKTOP NAME", valor:"nombreequipo"},
    {nombre:"DESKTOP NAME SCCM", valor:"nombreequiposccm"},
    {nombre:"IP SCCM", valor:"ipsccm"},
    {nombre:"DESKTOP TYPE", valor:"tipoequipo"},
    {nombre:"MODEL", valor:"modelo"},
    {nombre:"NETWORK CONDITION", valor:"condicionred"},
    {nombre:"VIP", valor:"vip"},
    {nombre:"USER ID", valor:"idusuario"},
    {nombre:"USER NAME", valor:"nombreusuario"},
    {nombre:"LAST USER", valor:"ultimousuario"},
    {nombre:"OPERATING SYSTEM", valor:"sistemaoperativo"},
    {nombre:"COMPILATION", valor:"compilacion"},
    {nombre:"INVENTORY DATE", valor:"fechainvenratiosccm"},
    {nombre:"LAST INVETORY DAYS", valor:"diasultimoinventario"},
    {nombre:"MODEL SCCM", valor:"modelosccm"},
    {nombre:"ID DESKTOP", valor:"id_equipoescritorio"},
    {nombre:"MODIFIED BY", valor:"modificadopor"},
    {nombre:"MODIFICATION DATE", valor:"fechaupdate"},
    {nombre:"ASSIGMENT DOCUMENT", valor:"documentoentrega"},
    {nombre:"EMPLOYEE MASTER USER", valor:"usuariomaestropersonal"}
]

export const camposExcelAplicaciones = [
    {nombre:"DESKTOP NAME", valor:"nombreequipo"},
    {nombre:"USER", valor:"nombreusuario"},
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"LOCATION", valor:"localidad"},
    {nombre:"BUSINESS", valor:"negocio"},
    {nombre:"ENTERPRISE", valor:"empresa"},
    {nombre:"PLANT", valor:"planta"},
    {nombre:"NETWORK CONDITION", valor:"condicionred"},
    {nombre:"STATE", valor:"estado"},
    {nombre:"CONDITION", valor:"condicion"},
    {nombre:"DESKTOP TYPE", valor:"tipoequipo"},
    {nombre:"IP", valor:"direccionip"},
    {nombre:"OPERATING SYSTEM", valor:"sistemaoperativo"},
    {nombre:"SERIAL NUMBER", valor:"numeroserie"},
    {nombre:"APPLICATION", valor:"aplicacion"},
    {nombre:"VERSION", valor:"version"},
    {nombre:"ENVIRONMENT", valor:"ambienteapp"},
    {nombre:"BUSINESS APP", valor:"negocioapp"}
]

export const camposExcelSccm = [
    {nombre:"ID", valor:"id"},
    {nombre:"Name0", valor:"name0"},
    {nombre:"User_Name0", valor:"username0"},
    {nombre:"Operating_System_Name_and0", valor:"operatingsystemname"},
    {nombre:"CSDVersion00", valor:"csdversion00"},
    {nombre:"SerialNumber00", valor:"serialnumber00"},
    {nombre:"IP", valor:"ip"},
    {nombre:"MAC", valor:"mac"},
    {nombre:"LastHWScan", valor:"lasthwscan"},
    {nombre:"Caption00", valor:"caption00"},
    {nombre:"SystemType", valor:"systemtype"},
    {nombre:"Model00", valor:"model00"},
    {nombre:"BuildNumber00", valor:"buildnumber00"}  
]

export const camposExcelSccmCarga = [
    {nombre:"SERIAL NUMBER", valor:"serialnumber00"},    
    {nombre:"ENTERPRISE", valor:""},
    {nombre:"PLANT", valor:""},    
    {nombre:"DESKTOP NAME", valor:"name0"},
    {nombre:"DESKTOP TYPE", valor:""},
    {nombre:"BRAND", valor:""},
    {nombre:"MODEL", valor:"model00"},    
    {nombre:"USER ID", valor:""},
    {nombre:"USER NAME", valor:"username0"},
    {nombre:"IP", valor:"ip"},
    {nombre:"Caption", valor:"caption00"},
    {nombre:"LastHWScan", valor:"lasthwscan"}
]

export const camposExcelBitacora = [
    {nombre:"ID DESKTOP", valor:"id_registro"},
    {nombre:"ID LOG", valor:"id_bitacora"},
    {nombre:"IOD MODIFICATION", valor:"id_modificacion"},
    {nombre:"DATE", valor:"fecha"},
    {nombre:"RESPONSABLE", valor:"responsable"},
    {nombre:"FIELD", valor:"campo"},
    {nombre:"AFTER VALUE", valor:"valor_anterior"},
    {nombre:"BEFORE VALUE", valor:"valor_actual"}    
]

export const campos = [    
    "id_equipoescritorio",
    "numeroserie",
    "estado",
    "condicion",
    "empresa",
    "planta",
    "negocio",
    "localidad",
    "pais",
    "nombreequipo",
    "tipoequipo",
    "marca",
    "modelo",     
    "condicionred",

    "idusuario",
    "nombreusuario",
    "correo",
    "division",    
    "divisionplanta",    
    "divisionfecha",    
    "cargo",
    "cargorol",
    "centrocosto",
    "gerenciaprincipal",
    "gerenciadirecta",
    "subgerenciaarea",
    "vip",
    "administrador",
    "justificacionadmin",
    "usuariomaestropersonal",

    "ordencompra",
    "direccionip",
    "discoduro",
    "memoriaram",
    "tipoprocesador",
    "velocidadprocesador",
    "docking",
    "monitor",
    "fecharecepcion",
    "fechaentrega",
    "fechaexpiracion",
    "fechafueralinea",
    "ubicacion", 
    "observaciones", 

    "ultimousuario",
    "sistemaoperativo",
    "compilacion",
    "fechainvenratiosccm",
    "diasultimoinventario",
    "documentoentrega",

    "modificadopor",
    "fechaupdate",
    "fechaupdatesccm",

    "excluidoparches",
    "motivoexcluidoparches",
    "usbdesbloqueado",
    "motivousbdesbloqueado",
]