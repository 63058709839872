import React, { useState, useRef } from 'react'

import styled from 'styled-components';
import Modal from 'react-modal'
import swal from 'sweetalert';

import * as TiIcons from 'react-icons/ti';

import * as BsIcons from 'react-icons/bs';

import { obtenerEquipoEscritorio } from '../functions/FuncEquipoEscritorio'

import FormularioImpresion from './FormularioImpresion';
import "../css/modalimpresion.css";
import { useReactToPrint } from 'react-to-print';

import { ButtonVolver } from '../Commons/Styles'


const ButtonModal = styled.button`
    float:right;
    background-color: #696158;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 15px; 
    border-radius: 5px;  
    border-color: #453F38;
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
    color: white;    
    margin-right: 10px;
    height: 40px;

    :hover {
        background: #AB9883;
    }
`;


const ButtonPrint = styled.button`
    float:right;
    background-color: #368b3b;     
    border-radius: 5px;  
    border-color: #137026;
    cursor: pointer;
    font-size: 28px;
    color: white;   
    
    :hover {
        background: #42b149;
    }
`;

const ModalImpresion = ({id, token}) => {

    const [registro, setRegistro] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const componentRef = useRef();

    async function obtenerDatosBD()
    {
        await obtenerEquipoEscritorio(id, token)
        .then((response) => {
            if(response.cod === 1)
            {
                setRegistro({
                    "idregistro": id,
                    "localidad": response.equipo.localidad,
                    "planta": response.equipo.planta,
                    "nombreusuario": response.equipo.nombreusuario,
                    "idusuario": response.equipo.idusuario,
                    "correo": response.equipo.correo,
                    "numeroserie": response.equipo.numeroserie,

                    "fechaentrega": response.equipo.fechaentrega,
                    "nombreequipo": response.equipo.nombreequipo,
                    "modelo": response.equipo.modelo,
                    "marca": response.equipo.marca,
                    "tipoequipo": response.equipo.tipoequipo,
                    "tipoprocesador": response.equipo.tipoprocesador,
                    "discoduro": response.equipo.discoduro,
                    "memoriaram": response.equipo.memoriaram
                })
            }
            else  {
                swal("Error Data", response.mensaje, "error");
            }
        });
    }

    function abrirModal()
    {   
        if(registro !== null) obtenerDatosBD();
        setModalOpen(true);
    }

    function cerrarModal()
    {      
        setModalOpen(false);
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    React.useEffect(() => {
        if(registro === null) obtenerDatosBD();
    });

    if(registro === null) return (<></>)
    else return (
        <>
            <ButtonModal type="button" onClick={abrirModal} className="tooltipboton">
                <BsIcons.BsFillPrinterFill/>
                <span className="tooltiptextboton">{"Delivery Form"}</span>               
            </ButtonModal>
            <Modal 
                isOpen={modalOpen}
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.75)'
                    },
                    content: {
                        width: '60%',
                        height: 'auto',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        position: 'relative',                        
                        border: '1px solid #ccc',
                        background: '#c9b197',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '10px'
                    }
                  }}
            >
                <div className='modalimpresion-header'>
                    <div className='modalimpresion-header-izq'>
                        <ButtonVolver onClick={cerrarModal} className="tooltipboton">
                            <TiIcons.TiArrowBack/>             
                            <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>
                    </div>
                    <div className='modalimpresion-header-der'>
                        <ButtonPrint onClick={handlePrint}><BsIcons.BsFillPrinterFill style={{marginTop: '4px'}}/></ButtonPrint> 
                    </div>
                     
                    
                </div>
                <div className='modalimpresion-body'>
                  <FormularioImpresion registro={registro} ref={componentRef}/>
                </div>
                <div className='modalimpresion-footer'>

                </div>
            </Modal>
        </>
    )
}

export default ModalImpresion