import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import '../css/aplicaciones.css'
import swal from 'sweetalert';

import ExportarExcel from '../ExportarExcel';

import Loading from '../Loading';
import FiltrosCuentaServicio from './FiltrosCuentaServicio';
import * as Opciones from './OpcionesCuentaServicio';

import { TablaRegistros } from '../Commons/TablaRegistros';

import { ContainerBusqueda, ContainerTable } from '../Commons/Styles'


const headCells = [    
    { id: 'nombre', ordertype: 'text', numeric: false, disablePadding: true, label: 'Account Name', size: '10%' },
    { id: 'tipocuenta', ordertype: 'text', numeric: false, disablePadding: true, label: 'Type', size: '10%' },
    { id: 'estado', ordertype: 'text', numeric: false, disablePadding: true, label: 'State', size: '10%' },
    { id: 'pais', ordertype: 'text', numeric: false, disablePadding: true, label: 'Country', size: '10%' },
    { id: 'negocio', ordertype: 'text', numeric: false, disablePadding: true, label: 'Business', size: '10%' },
    { id: 'responsable', ordertype: 'text', numeric: false, disablePadding: true, label: 'Responsable', size: '10%' },
    { id: 'fechacreacion', ordertype: 'text', numeric: false, disablePadding: true, label: 'Creation Date', size: '10%' },
    { id: 'solicitadapor', ordertype: 'text', numeric: false, disablePadding: true, label: 'Requested by', size: '10%' },
    { id: 'servidores', ordertype: 'int', numeric: false, disablePadding: true, label: 'Servers', size: '5%' },
    { id: 'aplicaciones', ordertype: 'int', numeric: false, disablePadding: true, label: 'Applications', size: '5%' },
    { id: 'info', ordertype: 'text', numeric: false, disablePadding: true, label: '', size: '5%' }
];

const TablaCuentaServicio = ({token}) => {
  
    const [loading, setLoading] = React.useState(false);
    const [rows, setRows] = useState(null);
    const [filter, setFilter] = useState("");

    function aplicarFiltro(registros)
    {
        if(registros == null) setRows(registros); 
        else if(registros.length === 0)
        {
            setRows(registros)            
            swal ("There are no records", "No information is recorded with the filters used", "info");
        }
        else setRows(registros);
    }
  
    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.nombre !== "") ? (row.nombre.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
            
            r = (row.tipocuenta !== "") ? (row.tipocuenta.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;    
            
            r = (row.estado !== "") ? (row.estado.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;      

            r = (row.pais !== "") ? (row.pais.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;      

            r = (row.negocio !== "") ? (row.negocio.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;      

            r = (row.responsable !== "") ? (row.responsable.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;      

            r = (row.solicitadapor !== "") ? (row.solicitadapor.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;      

            r = (row.fechacreacion !== "") ? (row.fechacreacion.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true; 
        })
    }
    
    return (
        <>
            <FiltrosCuentaServicio aplicarFiltro={aplicarFiltro} load={setLoading} token={token}/>
            {
                (rows !== null)
                ? 
                (
                    <>
                        <ContainerBusqueda> 
                        <br></br> 
                        
                            <TextField id="outlined-search" label="Busqueda" size="small" type="search" variant="outlined" 
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                
                            />
                            <ExportarExcel rows={rows} columnas={Opciones.camposExcel} nombreSheet="AccountService" nombreArchivo="AccountServiceExport"/>
                        <br></br>                  
                        </ContainerBusqueda>
                        <ContainerTable>
                            <TablaRegistros
                                headCells = { headCells }
                                rows = { buscar(rows) }
                                url = { '/cuentasservicio/' }
                                idFieldName = { 'id_cuentaservicio' }
                                initialSortByField = { 'nombre' }
                                initialSortDirection = { 'desc' }
                                />
                        </ContainerTable>                    
                </>)
                :
                (
                    (loading) ? (
                        <>
                            <br></br>
                            <br></br>
                            <ContainerTable>
                                <Loading />
                            </ContainerTable>
                        </>
                    )  : null 
                )
            }
        </>
    )
}

export default TablaCuentaServicio