import React from 'react'
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';
import FiltroBase from '../FiltroBase';
import { obtenerTablaSccm } from '../functions/FuncEquipoEscritorio'


const ContainerFiltros= styled.div`
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
`;

const Button = styled.button`
    margin-left: 10px;
    margin-right: auto;
    background-color: #EA7600;
    width: 15%;
    color: white;
    font-size: 20px;    
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    vertical-align: middle;
    color: white;
`;

const useStyles = makeStyles((theme) => ({
    formControl: {        
      marginLeft: '10px',
      marginRight: 'auto',
      width: '68%'     
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const useStylesButton = makeStyles((theme) => ({
    formControl: {        
      marginLeft: '10px',
      marginRight: 'auto',
      width: '28%'     
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const FiltrosEquiposEscritorioSccm = ({aplicarFiltro, load, token}) => {

    const classes = useStyles();
    const classesButton = useStylesButton();
    const [numeroserie, setNumeroSerie] = React.useState("");

    async function obtenerRegistros()
    {
        load(true);
        aplicarFiltro(null);
        await obtenerTablaSccm(numeroserie, token)
        .then((response) => { 

            if (response.cod === 1)
            {
                aplicarFiltro(response.equipos);
            }
            else {
                aplicarFiltro(null);
                swal ("Search Error" , response.mensaje , "warning");
            }
                
            load(false);
        });  
        
    }

    return (
        <>          
            <ContainerFiltros> 
                <FormControl variant="outlined" className={classes.formControl}>  
                    {
                        <TextField 
                            id="outlined-basic" 
                            value={numeroserie} 
                            onChange={(e) => setNumeroSerie(e.target.value)} 
                            label="Desktop Name" 
                            variant="outlined" 
                        />  
                    }                 
                </FormControl>

                <Button onClick={obtenerRegistros} className={classesButton.formControl} style={{verticalAlign: 'middle'}}>Display</Button>
                <br></br>
                <br></br>
                <hr style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
            </ContainerFiltros>
        </>
    )
}

export default FiltrosEquiposEscritorioSccm
