import * as Rutas from '../RutasServicios'
import * as Excel from './MaestrosExcel'

async function filtrosAplicacion(token)
{ 
    const url = Rutas.URL_Aplicacion + "filtros";    
    //const url = "https://localhost:44367/api/aplicacion/filtros";    
    
    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function maestro(nombre, fabricante, token)
{     
    const url = Rutas.URL_Maestros + "api/maestroapp/tabla"; 
    //const url = "https://localhost:44367/api/maestroapp/tabla"; 

    const request= {
        "nombre": nombre,
        "fabricante": fabricante              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }  
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

async function crear(data, token)
{ 

    const url = Rutas.URL_Maestros + "api/maestroapp/crear"; 
    //const url = "https://localhost:44367/api/maestroapp/crear"; 

    const request= {
        "nombre": data.nombre,
        "fabricante": data.fabricante,
        "modificadopor": data.modificadopor,
        "fechaupdate": new Date(data.fechaupdate).toISOString().substring(0, 10)
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

async function subirArchivoExcel(file64, nombre, usuario, token)
{ 
    const url = Rutas.URL_Maestros + "api/excel/subirexcel";      
    //const url = "https://localhost:44367/api/maestroapp/subirexcel";  

    const data = {
        "title": nombre,
        "file": file64,
        "desc": "archivo de carga excel",
        "usuario": usuario,
        "container": Excel.Aplicacion.container,
        "cabeceras": Excel.Aplicacion.cabeceras
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }  
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function eliminarMaestro(id, nombre, token)
{ 
    const url = Rutas.URL_Maestros + "api/maestroapp/eliminar";      
    //const url = "https://localhost:44367/api/maestroapp/eliminar";  

    const data = {
        "id_maestroaplicaciones": id,
        "nombre": nombre
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }  
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function actualizarMaestro(app, token)
{ 
    const url = Rutas.URL_Maestros + "api/maestroapp/actualizarMaestro";      
    //const url = "https://localhost:44367/api/maestroapp/actualizarMaestro";  

    const data = {
        "id_maestroaplicaciones": app.id_maestroaplicaciones,
        "nombre": app.nombre,
        "nombreanterior": app.nombreanterior,
        "fabricante": app.fabricante,
        "modificadopor": app.modificadopor,
        "fechaupdate": new Date(app.fechaupdate).toISOString().substring(0, 10)    
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }  
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

export async function actualizarMaestroApp(app, token) {
    try {
        var respuesta = await actualizarMaestro(app, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function eliminarMaestroApp(id, nombre, token) {
    try {
        var respuesta = await eliminarMaestro(id, nombre, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function uploadExcel(file64, nombre, usuario, token) {
    try {
        var respuesta = await subirArchivoExcel(file64, nombre, usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function obtenerFiltros(token) {
    try {
        var respuesta = await filtrosAplicacion(token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearMaestro(data, token) {
    try {
        var respuesta = await crear(data, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerMaestro(nombre, fabricante, token) {
    try {
        var respuesta = await maestro(nombre, fabricante, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}