import * as Rutas from '../RutasServicios'

async function tabla(modulo, nombre, token)
{ 
    const url = Rutas.URL_Maestros + "api/traducciones/tabla";    
    //const url = "https://localhost:44367/api/traducciones/tabla";

    const request= {
        "modulo": modulo.value,
        "nombre": nombre           
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

async function crear(modulo, nombre, spanish, english, portuguese, token)
{ 
    const url = Rutas.URL_Maestros + "api/traducciones/crear";    
    //const url = "https://localhost:44367/api/traducciones/crear";

    const request= {
        "modulo": modulo.value,
        "nombre": nombre,
        "spanish": spanish,
        "english": english,
        "portuguese": portuguese          
    };

    var bRequest = JSON.stringify(request, null, 2);  
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

async function eliminar(id, token)
{ 
    const url = Rutas.URL_Maestros + "api/traducciones/eliminar";    
    //const url = "https://localhost:44367/api/traducciones/eliminar";

    const request= {
        "id_traduccion": id                
    };

    var bRequest = JSON.stringify(request, null, 2);

    console.log(bRequest)
    console.log(token)
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

async function actualizar(id, spanish, english, portuguese, modulo, nombre, token)
{ 
    const url = Rutas.URL_Maestros + "api/traducciones/actualizar";    
    //const url = "https://localhost:44367/api/traducciones/actualizar";

    const request= {
        "id_traduccion": id,
        "spanish": spanish,
        "english": english,
        "modulo": modulo.value,
        "nombre": nombre,  
        "portuguese": portuguese             
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

export async function actualizarTraduccion(id, spanish, english, portuguese, modulo, nombre, token) {
    try {
        var respuesta = await actualizar(id, spanish, english, portuguese, modulo, nombre, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

export async function eliminarTraduccion(id, token) {
    try {
        var respuesta = await eliminar(id, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearTraduccion(modulo, nombre, spanish, english, portuguese, token) {
    try {
        var respuesta = await crear(modulo, nombre, spanish, english, portuguese, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerTabla(modulo, nombre, token) {
    try {
        var respuesta = await tabla(modulo, nombre, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}