import React, { useState } from 'react';
import { makeStyles, useTheme, withStyles  } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';
import Modal from 'react-modal'

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as AiIcons from 'react-icons/ai';
import * as MdIcons from 'react-icons/md';
import Loading from '../../Loading';
import FiltrosTraducciones from './FiltrosTraducciones';
const useStyles = makeStyles({
    table: {
      minWidth: 650
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5)
    },
}));

const headCells = [
    { id: 'nombre', numeric: false, disablePadding: true, label: 'Name', size: '20%' },
    { id: 'spanish', numeric: false, disablePadding: true, label: 'Spanish', size: '30%' },
    { id: 'english', numeric: false, disablePadding: true, label: 'English', size: '30%' },
    { id: 'portuguese', numeric: false, disablePadding: true, label: 'Portuguese', size: '30%' },
    { id: 'modificar', numeric: false, disablePadding: true, label: '', size: '10%' },
    { id: 'eliminar', numeric: false, disablePadding: true, label: '', size: '10%' },
];

const ContainerBusqueda = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
`;

const ContainerTable = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
`;

const ButtonCancelar = styled.button`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    background-color: #85929E;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #99A3A4;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    color: white;
    float:right;
    
`;

const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.white
    },
    body: {
        textDecoration: 'none',
        fontSize: 12,
    },
}))(TableCell);

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const TablaTraducciones = ({modulo, rows, aplicarFiltro, filtro, 
    cambioFiltro, eliminar, token, actualizar, modalOpen, abrirModal, 
    cerrarModal, errorSpanish, errorEnglish, errorPortuguese, spanishModal, englishModal, portugueseModal, idModal}) => {
    
    const classes = useStyles();   
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'}); 
    const [loading, setLoading] = React.useState(false);

    const [filter, setFilter] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('nombre');

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.nombre !== "") ? (row.nombre.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function stableSort(array) {
        if(order === 'desc')
        {
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] > b[orderBy]) ? 1 : (b[orderBy] > a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
        else{
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] < b[orderBy]) ? 1 : (b[orderBy] < a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
    }

    const actualizarmodal = (data) => {
        actualizar(data, idModal);
    }
    
    return (
        <>
            {
                (!loading)
                ?
                (
                    <>         
                        <FiltrosTraducciones  modulo={modulo} aplicarFiltro={aplicarFiltro} load={setLoading} token={token} filtro={filtro} cambioFiltro={cambioFiltro}/>
                        <ContainerBusqueda> 
                        <br></br>                         
                            <TextField 
                                id="outlined-search" 
                                label="Search" 
                                size="small" 
                                type="search" 
                                variant="outlined" 
                                style={{"backgroundColor": "white"}}
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />                  
                        <br></br>                  
                        </ContainerBusqueda>
                        <ContainerTable>
                            <TableContainer component={Paper}>
                                <Table 
                                    className={classes.table} 
                                    size="small" 
                                    aria-label="enhanced table">
                                <TableHead style={{ background: '#696158', color: '#fff' }}>
                                    <TableRow>
                                    {headCells.map((headCell) => (
                                        <StyledTableCell 
                                            key={headCell.id}
                                            align={'center'}   
                                            sortDirection={orderBy === headCell.id ? order : false}
                                            style={{ width: headCell.size, color: '#fff !important' }}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id)}
                                                style={{ background: '#696158', color: '#fff' }}
                                            >
                                                {headCell.label}
                                            </TableSortLabel>                                        
                                        </StyledTableCell>
                                    ))}                                
                                    </TableRow>
                                </TableHead>
                                {
                                    (rows === null || rows === undefined) 
                                    ? (
                                        <TableBody> 
                                        </TableBody> 
                                    ) : (
                                        <TableBody>  
                                        {   stableSort(buscar(rows))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)                                    
                                            .map((row) => (                                            
                                                <TableRow hover key={row.id_traduccion}  style={{ textDecoration: 'none', fontSize: '10px !important' }}>
                                                    <StyledTableCell align="left">{row.nombre}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.spanish}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.english}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.portuguese}</StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <button 
                                                            onClick={() => {                                                                
                                                                abrirModal(row.spanish, row.english, row.portuguese, row.id_traduccion, row.nombre);
                                                            }}
                                                            className="boton-observacion">
                                                            <AiIcons.AiFillEdit />
                                                        </button>     
                                                    </StyledTableCell>                                                     
                                                    <StyledTableCell align="center">
                                                        <button 
                                                            onClick={() => {                                                                
                                                                eliminar(row.id_traduccion, row.nombre);
                                                            }}
                                                            className="boton-eliminar tooltipboton">
                                                            <AiIcons.AiFillDelete/>
                                                            <span className="tooltiptextboton">{"Delete"}</span> 
                                                        </button>     
                                                    </StyledTableCell>                                            
                                                </TableRow>
                                            ))
                                        }        
                                        </TableBody>
                                    )
                                }
                                <TableFooter>
                                </TableFooter>                           
                                </Table>
                                <Table>
                                    <TableBody> 
                                    <TableRow>
                                    <TablePagination  style={{ display: 'flex'}}
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            colSpan={3}
                                            count={(rows === null || rows === undefined) ? 0 : rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}                                    
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            />
                                    </TableRow>
                                    </TableBody> 
                                </Table>
                            </TableContainer>
                        </ContainerTable> 

                        <Modal
                            isOpen={modalOpen}
                            style={{
                                overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(255, 255, 255, 0.75)'
                                },
                                content: {
                                    width: '50%',
                                    height: 'auto',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    position: 'relative',                        
                                    border: '1px solid #ccc',
                                    background: '#fff',
                                    overflow: 'auto',
                                    WebkitOverflowScrolling: 'touch',
                                    borderRadius: '4px',
                                    outline: 'none',
                                    padding: '20px'
                                }
                            }}
                        >

                            <div className="modal-modificacion-traducciones">                               
                                <form 
                                    style={{width: '100%', height: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                                    autoComplete="off" onSubmit={handleSubmit(actualizarmodal)}
                                >
                                    <div className="container-traducciones">
                                        <div className="row">
                                            <div className="col-20">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-spanish">Spanish</label> 
                                            </div>
                                            <div className="col-80">
                                                <Controller
                                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                                    control={control}
                                                    id="i-spanish"
                                                    key="k-spanish"
                                                    defaultValue={spanishModal}                                
                                                    name='spanish'     
                                                    style={(errorSpanish) ? { borderColor: '#f90000'} : {}}
                                                />                              
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-20">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-english">English</label> 
                                            </div>
                                            <div className="col-80">
                                                <Controller
                                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                                    control={control}
                                                    id="i-english"
                                                    key="k-english"
                                                    defaultValue={englishModal}                                
                                                    name='english'
                                                    style={(errorEnglish) ? { borderColor: '#f90000'} : {}}                                
                                                />                              
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-20">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-portuguese">Portuguese</label> 
                                            </div>
                                            <div className="col-80">
                                                <Controller
                                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                                    control={control}
                                                    id="i-portuguese"
                                                    key="k-portuguese"
                                                    defaultValue={portugueseModal}                                
                                                    name='portuguese'
                                                    style={(errorPortuguese) ? { borderColor: '#f90000'} : {}}                                
                                                />                              
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="row">
                                            <div style={{width: '50%', marginRight:'auto', marginLeft: 'auto', marginBottom: '10px'}}>
                                                <button className="boton-form-traduccion">Save</button>
                                            </div>                   
                                        </div>
                                        <div className="row">
                                            <div style={{width: '50%', marginRight:'auto', marginLeft: 'auto'}}>
                                                <button type="button" onClick={() => cerrarModal()} className="boton-salir-traduccion">Return</button>
                                            </div>                   
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </Modal>
                    </>
                )
                :
                (
                    <>                        
                        <FiltrosTraducciones modulo={modulo} aplicarFiltro={aplicarFiltro} load={setLoading} token={token} filtro={filtro} cambioFiltro={cambioFiltro}/>
                        <br></br>
                        <br></br>
                        <ContainerTable>
                            <Loading />
                        </ContainerTable>
                    </> 
                )
            }
        </>
    )
}

export default TablaTraducciones
