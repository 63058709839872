import { useState, useEffect } from 'react'
import { obtenerdatosTabla, obtenerdatosFiltros, obtenerTablaBitacoras } from '../functions/FuncDispositivoOT'

import swal from 'sweetalert';
import FiltroBaseNuevo from '../FiltroBaseNuevo';
import  * as Metodos from '../functions/MetodosComunes'

import { ContainerFiltros, FilaContainerFiltros, Button } from '../Commons/Styles'

const FiltrosDispositivoOT = ({aplicarFiltro, obtenerBitacoras, load, token}) => {

    let priv = sessionStorage.getItem('ser_ot_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');    
    let plantaUser = sessionStorage.getItem('planta');
    const [valores, setValores] = useState(null);

    const [tipoactivo, setTipoActivo] = useState("");
    const [tiposactivo, setTiposActivo] = useState(null);
    const [estado, setEstado] = useState("");
    const [estados, setEstados] = useState(null);
    const [planta, setPlanta] = useState("");    
    const [plantas, setPlantas] = useState(null);    
    const [localidad, setLocalidad] = useState("");    
    const [localidades, setLocalidades] = useState(null);    
    const [pais, setPais] = useState("");    
    const [paises, setPaises] = useState(null);
    const [negocio, setNegocio] = useState("");
    const [negocios, setNegocios] = useState(null);
    const [dispositivoot, setDispositivoOT] = useState("");

    async function registrosTabla(){
        load(true);
        aplicarFiltro(null);

        var paisApi = (pais !== null && pais !== undefined && pais !== "") ? pais.value : null;
        var localidadApi = (localidad !== null && localidad !== undefined && localidad !== "") ? localidad.value : null;
        var plantaApi = (planta !== null && planta !== undefined && planta !== "") ? planta.value : null;
        var tipoactivoApi = (tipoactivo !== null && tipoactivo !== undefined && tipoactivo !== "") ? tipoactivo.value : null;
        var negocioApi = (negocio !== null && negocio !== undefined && negocio !== "") ? negocio.value : null;
        var estadoApi = (estado !== null && estado !== undefined && estado !== "") ? estado.value : null;        
        var dispositivootApi = (dispositivoot !== null && dispositivoot !== undefined && dispositivoot !== "") ? dispositivoot : null;

        (paisApi !== null) ? sessionStorage.setItem('filtrodispositivoot_pais', paisApi) : sessionStorage.setItem('filtrodispositivoot_pais', null);
        (localidadApi !== null) ? sessionStorage.setItem('filtrodispositivoot_localidad', localidadApi) : sessionStorage.setItem('filtrodispositivoot_localidad', null);
        (plantaApi !== null) ? sessionStorage.setItem('filtrodispositivoot_planta', plantaApi) : sessionStorage.setItem('filtrodispositivoot_planta', null);
        (tipoactivoApi !== null) ? sessionStorage.setItem('filtrodispositivoot_tipoactivo', tipoactivoApi) : sessionStorage.setItem('filtrodispositivoot_tipoactivo', null);
        (negocioApi !== null) ? sessionStorage.setItem('filtrodispositivoot_negocio', negocioApi) : sessionStorage.setItem('filtrodispositivoot_negocio', null);
        (estadoApi !== null) ? sessionStorage.setItem('filtrodispositivoot_estado', estadoApi) : sessionStorage.setItem('filtrodispositivoot_estado', null);
        (dispositivootApi !== null) ? sessionStorage.setItem('filtrodispositivoot_dispositivoot', dispositivootApi) : sessionStorage.setItem('filtrodispositivoot_dispositivoot', null);

        await obtenerTablaBitacoras(estadoApi, localidadApi, plantaApi, negocioApi, paisApi, dispositivootApi, tipoactivoApi, token)
        .then((response) => {
            if(response.cod === 1) {                
                obtenerBitacoras(response.bitacoras);
            }
            else obtenerBitacoras(null);
        })

        await obtenerdatosTabla(estadoApi, localidadApi, plantaApi, negocioApi, paisApi, dispositivootApi, tipoactivoApi, token)
        .then((response) => {
            if(response.cod === 1) {
                
                aplicarFiltro(response.dispositivosot);
            }
            else {
                aplicarFiltro(null);
                swal ("Search error" , response.mensaje , "warning");
            }
            
            load(false);
        });
    }

    async function obtenerFiltros(){

        if(priv !== "0" && priv !== null && priv !== undefined)
        {
            await obtenerdatosFiltros(token)
            .then((response) => {

                const val = {          
                    "paises": response.paises,
                    "filtrolocalidad": response.filtrolocalidad,
                    "localidades": response.localidades,
                    "filtroplanta": response.filtroplanta,
                    "plantas": response.plantas,
                    "estados": response.estados,
                    "negocios": response.negocios,
                    "tiposactivo": response.tiposactivo
                };

                if(priv === "1"){
                    setPlantas(Metodos.opcionesFiltrosPlantaLocalidad(val.filtroplanta, localidadUser));  
                    setLocalidades(Metodos.opcionesFiltrosValor(val.localidades, localidadUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));                           
                }

                if(priv === "2"){
                    setPlantas(Metodos.opcionesFiltrosPlantaPais(val.filtroplanta, paisUser));
                    setLocalidades(Metodos.opcionesFiltrosLocalidad(val.filtrolocalidad, paisUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));                    
                }

                if(priv === "3"){
                    setPlantas(Metodos.opcionesFiltros(val.plantas));
                    setLocalidades(Metodos.opcionesFiltros(val.localidades));
                    setPaises(Metodos.opcionesFiltros(val.paises));                    
                }

                if(priv === "4"){
                    if(plantaUser === ""){
                        setPlantas(Metodos.opcionesFiltrosPlantaLocalidad(val.filtroplanta, localidadUser));  
                    }else{
                        setPlantas(Metodos.opcionesFiltrosValor(val.plantas, plantaUser));
                    }
                    setLocalidades(Metodos.opcionesFiltrosValor(val.localidades, localidadUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));               
                }

                setNegocios(Metodos.opcionesFiltros(val.negocios));
                setEstados(Metodos.opcionesFiltros(val.estados));
                setTiposActivo(Metodos.opcionesFiltros(val.tiposactivo));  

                setValores(val);
            }); 
        }
    }

    function filtrosAnteriores()
    {
        let filtrodispositivoot_planta = sessionStorage.getItem('filtrodispositivoot_planta');       
        if(filtrodispositivoot_planta !== "null") setPlanta({label: filtrodispositivoot_planta, value: filtrodispositivoot_planta})

        let filtrodispositivoot_localidad = sessionStorage.getItem('filtrodispositivoot_localidad');       
        if(filtrodispositivoot_localidad !== "null") setLocalidad({label: filtrodispositivoot_localidad, value: filtrodispositivoot_localidad})

        let filtrodispositivoot_pais = sessionStorage.getItem('filtrodispositivoot_pais');     
        if(filtrodispositivoot_pais !== "null") setPais({label: filtrodispositivoot_pais, value: filtrodispositivoot_pais})

        let filtrodispositivoot_negocio = sessionStorage.getItem('filtrodispositivoot_negocio');        
        if(filtrodispositivoot_negocio !== "null") setNegocio({label: filtrodispositivoot_negocio, value: filtrodispositivoot_negocio})

        let filtrodispositivoot_estado = sessionStorage.getItem('filtrodispositivoot_estado');        
        if(filtrodispositivoot_estado !== "null") setEstado({label: filtrodispositivoot_estado, value: filtrodispositivoot_estado})

        let filtrodispositivoot_dispositivoot = sessionStorage.getItem('filtrodispositivoot_dispositivoot');        
        if(filtrodispositivoot_dispositivoot !== "null") setDispositivoOT(filtrodispositivoot_dispositivoot)

        let filtrodispositivoot_tipoactivo = sessionStorage.getItem('filtrodispositivoot_tipoactivo');        
        if(filtrodispositivoot_tipoactivo !== "null") setTipoActivo({label: filtrodispositivoot_tipoactivo, value: filtrodispositivoot_tipoactivo})
    }

    useEffect(() =>{

        if(valores === null) obtenerFiltros();
            
        filtrosAnteriores(); 
    }, []);

    const cambioPlanta = (valor) => {
        setPlanta(valor);
    };

    const cambioLocalidad = (valor) => {

        if(valor !== null && valor !== undefined && valor !== ""){
            setLocalidad(valor);

            if (priv == 3 || priv == 2){

                filtrarPlantaLocalidad(valor.value);

            }

        } 
        else{      
            setLocalidad(null);
            if (priv == 3 || priv == 2){   
                if(pais !== null && pais !== undefined && pais !== ""){
                    filtrarPlantaPais(pais.value);
                }
                else{
                    var array = [];
                    if(valores.filtroplanta.length > 0){

                        valores.filtroplanta.map((p) => {
                            array.push({label: p.planta, value: p.planta});
                        });  
                        setPlantas(array);  
                    }
                } 
            }
        }
    };

    const cambioNegocio = (valor) => {
        setNegocio(valor);
    };

    const cambioTipoActivo = (valor) => {
        setTipoActivo(valor);
    };

    const cambioEstado = (valor) => {
        setEstado(valor);
    };

    const cambioPais = (valor) => {

        if(valor !== null && valor !== undefined && valor !== ""){
            setPais(valor);

            if (priv == 3){
                filtrarLocalidad(valor.value);
                filtrarPlantaPais(valor.value);
            }
        } 
        else{      
            setPais(null);   
            if (priv == 3){   
                var array = [];
                if(valores.filtrolocalidad.length > 0){

                    valores.filtrolocalidad.map((p) => {
                        array.push({label: p.localidad, value: p.localidad});
                    });  
                    setLocalidades(array);  
                }
                array = [];
                if(valores.filtroplanta.length > 0){

                    valores.filtroplanta.map((p) => {
                        array.push({label: p.planta, value: p.planta});
                    });  
                    setPlantas(array);  
                }
            }
        }
    };

    function filtrarLocalidad(filtro)
    {
        var array = [];
        if(valores.filtrolocalidad.length > 0){
            valores.filtrolocalidad.map((p) => {
                if(p.pais === filtro) array.push({label: p.localidad, value: p.localidad});
            });  

            setLocalidad(null);
            setLocalidades(array); 
        }
    }

    function filtrarPlantaLocalidad(filtro)
    {

        var array = [];
        if(valores.filtroplanta.length > 0){
            valores.filtroplanta.map((p) => {
                if(p.localidad === filtro) array.push({label: p.planta, value: p.planta});
            });  

            setPlanta(null);
            setPlantas(array); 
        }
    }

    function filtrarPlantaPais(filtro)
    {
        var array = [];
        if(valores.filtroplanta.length > 0){
            valores.filtroplanta.map((p) => {
                if(p.pais === filtro) array.push({label: p.planta, value: p.planta});
            });  

            setPlanta(null);
            setPlantas(array); 
        }
    }

    if(valores === null) return (<></>)
    else return (
        <>
        <FilaContainerFiltros>
        <ContainerFiltros>  
            <FiltroBaseNuevo
                opciones={paises}
                valor={pais}
                nombre={"Country"}
                namecampo="pais"
                cambio={cambioPais}
                proporcion={"25%"}
                error={false}
            />

            <FiltroBaseNuevo
                opciones={localidades}
                valor={localidad}
                nombre={"Location"}
                namecampo="localidad"
                cambio={cambioLocalidad}
                proporcion={"25%"}
                error={false}
            />

            <FiltroBaseNuevo
                opciones={plantas}
                valor={planta}
                nombre={"Plant"}
                namecampo="planta"
                cambio={cambioPlanta}
                proporcion={"25%"}
                error={false}
            />

            <FiltroBaseNuevo
                opciones={tiposactivo}
                valor={tipoactivo}
                nombre={"Asset Type"}
                namecampo="tipoactivo"
                cambio={cambioTipoActivo}
                proporcion={"25%"}
                error={false}
            />

        </ContainerFiltros>
        <ContainerFiltros>

            <FiltroBaseNuevo
                opciones={estados}
                valor={estado}
                nombre={"State"}
                namecampo="estado"
                cambio={cambioEstado}
                proporcion={"30%"}
                error={false}
            />

            <FiltroBaseNuevo
                opciones={negocios}
                valor={negocio}
                nombre={"Business"}
                namecampo="negocio"
                cambio={cambioNegocio}
                proporcion={"30%"}
                error={false}
            />

            <div style={{width: "28%", marginLeft: '10px', marginRight: 'auto',}}>
                <input className="bootstrap-input-filtro" value={dispositivoot} type='text' onChange={(e) => setDispositivoOT(e.target.value)} placeholder={"Device Name"}  />
            </div>
            
            <div style={{width: "12%", marginLeft: '10px', marginRight: 'auto',}}>
                <Button onClick={registrosTabla}>Display</Button>
            </div>
        </ContainerFiltros>        
        </FilaContainerFiltros>
        <br></br>
        <hr style={{width: '85%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
        </>
    )
}

export default FiltrosDispositivoOT
