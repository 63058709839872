import React from 'react'
import Sidebar from './Sidebar'
import styled from 'styled-components';

const ContainerReporte = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    height: 90vh;
`;

const Menu = ({instance}) => {
  
    return (
        <>
        <Sidebar instance={instance} despliegue={true}/>
        <ContainerReporte>
            
        </ContainerReporte>
        </>
    )
}

export default Menu
