import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from 'react-icons/bs';
import * as FcIcons from 'react-icons/fc';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as TiIcons from 'react-icons/ti';
import * as HiIcons from 'react-icons/hi';
import * as ImIcons from 'react-icons/im';


export const SidebarData = [
  {
    title: 'Main Menu',
    path: '/menu',
    //icon: <AiIcons.AiFillHome />,
    icon: <FcIcons.FcHome />,
    privilegio: null,
    maestro: false,
    global: true,  
  },
  {
    title: 'Summary',
    path: '/reporteqsenseservidor',
    icon: <HiIcons.HiOutlineDocumentReport />,
    privilegio: null,
    maestro: false,
    global: true,  
  },
  {
    title: 'QSense',
    path: '/qsense',
    icon: <MdIcons.MdDashboard />,
    privilegio: null,
    maestro: false,
    global: true,  
  },
  {
    title: 'Master Admin Tables',
    path: '',
    icon: <MdIcons.MdBusinessCenter />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    privilegio: '',
    maestro: true,
    global: false,

    subNav: [
      {
        title: 'Generals',
        path: '/maestrogeneral',
        icon: <BsIcons.BsFillCollectionFill />,
        cName: 'sub-nav',
        privilegio: 'mas_escribir'
      },
      {
        title: 'Translate',
        path: '/traducciones',
        icon: <AiIcons.AiOutlineTranslation />,
        cName: 'sub-nav',
        privilegio: 'mas_escribir'
      },
      {
        title: 'Loads Sccm Desktop',
        path: '/cargassccmdesktop',
        icon: <RiIcons.RiFileExcel2Fill />,
        cName: 'sub-nav',
        privilegio: 'mas_escribir'
      },
      {
        title: 'Employee Master Table',
        path: '/cargamaestroper',
        icon: <BsIcons.BsFillCollectionFill />,
        cName: 'sub-nav',
        privilegio: 'admin_personal'
      },
      {
        title: 'Antivirus Load',
        path: '/cargaantivirusequipo',
        icon: <BsIcons.BsFillCollectionFill />,
        cName: 'sub-nav',
        privilegio: 'admin_otrasfuentes'
      },
      {
        title: 'Umbrella Load',
        path: '/cargaumbrella',
        icon: <BsIcons.BsFillCollectionFill />,
        cName: 'sub-nav',
        privilegio: 'admin_otrasfuentes'
      },
      {
        title: 'Notifications CC',
        path: '/correoscc',
        icon: <RiIcons.RiMailSettingsLine />,
        cName: 'sub-nav',
        privilegio: 'mas_escribir'
      },
      {
        title: 'Application Control',
        path: '/aplicacionespermitidas',
        icon: <ImIcons.ImBlocked />,
        cName: 'sub-nav',
        privilegio: 'mas_escribir'
      }            
    ]
  },
  {
    title: 'Server',
    path: '',
    icon: <FaIcons.FaServer />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    privilegio: 'ser_leer',
    maestro: false,
    global: false, 

    subNav: [
      {
        title: 'Servers',
        path: '/servidores',
        icon: <FaIcons.FaServer />,
        cName: 'sub-nav',
        privilegio: 'ser_leer',        
      },
      {
        title: 'Create Server',
        path: '/crearservidor',
        icon: <RiIcons.RiAddCircleFill />,
        cName: 'sub-nav',
        privilegio: 'ser_crear',        
      },
      {
        title: 'Server Load',
        path: '/cargaservidores',
        icon: <RiIcons.RiFileExcel2Fill />,
        cName: 'sub-nav',
        privilegio: 'ser_cargamasiva',        
      },
      {
        title: 'Report',
        path: '/reporteservidor',
        icon: <HiIcons.HiDocumentReport />,
        cName: 'sub-nav',
        privilegio: 'ser_leer'
      }
    ]
  },
  {
    title: 'Device OT',
    path: '',
    icon: <FaIcons.FaServer />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    privilegio: 'ser_ot_leer',
    maestro: false,
    global: false, 

    subNav: [
      {
        title: 'Devices OT',
        path: '/dispositivosot',
        icon: <FaIcons.FaServer />,
        cName: 'sub-nav',
        privilegio: 'ser_ot_leer',        
      },
      {
        title: 'Create Device OT',
        path: '/creardispositivoOT',
        icon: <RiIcons.RiAddCircleFill />,
        cName: 'sub-nav',
        privilegio: 'ser_ot_crear',        
      },
      {
        title: 'Device OT Load',
        path: '/cargadispositivosot',
        icon: <RiIcons.RiFileExcel2Fill />,
        cName: 'sub-nav',
        privilegio: 'ser_ot_cargamasiva',        
      },/*
      {
        title: 'Report',
        path: '/reporteDispositivoOT',
        icon: <HiIcons.HiDocumentReport />,
        cName: 'sub-nav',
        privilegio: 'ser_ot_leer'
      }*/
    ]
  },
  {
    title: 'Server Room',
    path: '',
    icon: <FaIcons.FaServer />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    privilegio: 'sala_leer',
    maestro: false,
    global: false, 

    subNav: [
      {
        title: 'Server Rooms',
        path: '/salasservidores',
        icon: <FaIcons.FaServer />,
        cName: 'sub-nav',
        privilegio: 'sala_leer',        
      },
      {
        title: 'Create Server Room',
        path: '/crearsalaservidor',
        icon: <RiIcons.RiAddCircleFill />,
        cName: 'sub-nav',
        privilegio: 'sala_crear',        
      },
    ]
  },  
  {
    title: 'Application',
    path: '',
    icon: <BsIcons.BsFillCollectionFill />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    privilegio: 'app_leer',
    maestro: false,
    global: false, 

    subNav: [
      {
        title: 'Applications',
        path: '/aplicaciones',
        icon: <BsIcons.BsFillCollectionFill />,
        cName: 'sub-nav',
        privilegio: 'app_leer'
      },
      {
        title: 'Servers SCCM',
        path: '/aplicacionessccm',
        icon: <BsIcons.BsFillCollectionFill />,
        cName: 'sub-nav',
        privilegio: 'app_leer'
      },
      {
        title: 'Desktops SCCM',
        path: '/aplicacionessccmequipo',
        icon: <BsIcons.BsFillCollectionFill />,
        cName: 'sub-nav',
        privilegio: 'app_leer'
      },
      {
        title: 'Create App',
        path: '/crearaplicacion',
        icon: <RiIcons.RiAddCircleFill />,
        cName: 'sub-nav',
        privilegio: 'app_crear'
        
      },
      {
        title: 'App Loading',
        path: '/cargaaplicaciones',
        icon: <RiIcons.RiFileExcel2Fill />,
        cName: 'sub-nav',
        privilegio: 'app_cargamasiva'
      },
      {
        title: 'Report',
        path: '/reporteaplicacion',
        icon: <HiIcons.HiDocumentReport />,
        cName: 'sub-nav',
        privilegio: 'app_leer'
      },
      {
        title: 'Master of Applications',
        path: '/maestroapp',
        icon: <BsIcons.BsFillCollectionFill />,
        cName: 'sub-nav',
        privilegio: 'app_maestro'
      }
    ]
  },
  {
    title: 'Application BO',
    path: '',
    icon: <BsIcons.BsFillCollectionFill />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    privilegio: 'appback_leer',
    maestro: false,
    global: false, 

    subNav: [
      {
        title: 'Applications BO',
        path: '/aplicacionesback',
        icon: <BsIcons.BsFillCollectionFill />,
        cName: 'sub-nav',
        privilegio: 'appback_leer'
      },      
      {
        title: 'Create App',
        path: '/crearaplicacionback',
        icon: <RiIcons.RiAddCircleFill />,
        cName: 'sub-nav',
        privilegio: 'appback_crear'
        
      },
      {
        title: 'App Loading',
        path: '/cargaaplicacionesback',
        icon: <RiIcons.RiFileExcel2Fill />,
        cName: 'sub-nav',
        privilegio: 'appback_cargamasiva'
      },
      {
        title: 'Applications SCCM',
        path: '/aplicacionessccmequipo',
        icon: <BsIcons.BsFillCollectionFill />,
        cName: 'sub-nav',
        privilegio: 'appback_leer'
      } 
    ]
  },
  {
    title: 'Account Control',
    path: '',
    icon: <MdIcons.MdManageAccounts />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    privilegio: 'cuentaser_leer',
    maestro: false,
    global: false,

    subNav: [
      {
        title: 'Accounts Control',
        path: '/cuentasservicio',
        icon: <MdIcons.MdManageAccounts />,
        cName: 'sub-nav',
        privilegio: 'cuentaser_leer'
      },
      {
        title: 'Create Account Control',
        path: '/crearcuentaservicio',
        icon: <RiIcons.RiAddCircleFill />,
        cName: 'sub-nav',
        privilegio: 'cuentaser_crear'
      },
      {
        title: 'Account Control Load',
        path: '/cargacuentaservicio',
        icon: <RiIcons.RiFileExcel2Fill />,
        cName: 'sub-nav',
        privilegio: 'cuentaser_cargamasiva'
      }
    ]
  },
  {
    title: 'Desktop Computers',
    path: '',
    icon: <TiIcons.TiDeviceDesktop />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    privilegio: 'esc_leer',
    maestro: false,
    global: false, 

    subNav: [
      {
        title: 'Desktops',
        path: '/equipos',
        icon: <TiIcons.TiDeviceDesktop />,
        cName: 'sub-nav',
        privilegio: 'esc_leer'
      },
      {
        title: 'Desktops Sccm',
        path: '/equipossccm',
        icon: <TiIcons.TiDeviceDesktop />,
        cName: 'sub-nav',
        privilegio: 'esc_leer'
      },
      {
        title: 'Create Desktop',
        path: '/crearequipo',
        icon: <RiIcons.RiAddCircleFill />,
        cName: 'sub-nav',
        privilegio: 'esc_crear'
      },
      {
        title: 'Desktops Load',
        path: '/cargaequipos',
        icon: <RiIcons.RiFileExcel2Fill />,
        cName: 'sub-nav',
        privilegio: 'esc_cargamasiva'
      },
      {
        title: 'Desktops Sccm Load',
        path: '/cargaequipossccm',
        icon: <RiIcons.RiFileExcel2Fill />,
        cName: 'sub-nav',
        privilegio: 'esc_cargamasiva'
      },
      {
        title: 'Report',
        path: '/reporteequipoescritorio',
        icon: <HiIcons.HiDocumentReport />,
        cName: 'sub-nav',
        privilegio: 'esc_leer'
      }
    ]
  },
  {
    title: 'Certificate',
    path: '',
    icon: <AiIcons.AiOutlineSafetyCertificate />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    privilegio: 'cert_leer',
    maestro: false,
    global: false,

    subNav: [
      {
        title: 'Certificates',
        path: '/certificado',
        icon: <AiIcons.AiOutlineSafetyCertificate />,
        cName: 'sub-nav',
        privilegio: 'cert_leer'
      },
      {
        title: 'Create Certificate',
        path: '/crearcertificado',
        icon: <RiIcons.RiAddCircleFill />,
        cName: 'sub-nav',
        privilegio: 'cert_crear'
      }
    ]
  },
  {
    title: 'Comunication',
    path: '',
    icon: <FaIcons.FaNetworkWired />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    privilegio: 'com_leer',
    maestro: false,
    global: false,

    subNav: [
      {
        title: 'Equipment',
        path: '/comunicaciones',
        icon: <FaIcons.FaNetworkWired />,
        cName: 'sub-nav',
        privilegio: 'com_leer'
      },
      {
        title: 'Create Equipment',
        path: '/crearequipocomunicacion',
        icon: <RiIcons.RiAddCircleFill />,
        cName: 'sub-nav',
        privilegio: 'com_crear'
      },
      {
        title: 'Equipment Load',
        path: '/cargaequipocomunicacion',
        icon: <RiIcons.RiFileExcel2Fill />,
        cName: 'sub-nav',
        privilegio: 'com_cargamasiva'        
      }
    ]
  },
  {
    title: 'Other Devices',
    path: '',
    icon: <MdIcons.MdDevicesOther />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    privilegio: 'dis_leer',
    maestro: false,
    global: false,

    subNav: [
      {
        title: 'Devices',
        path: '/dispositivos',
        icon: <MdIcons.MdDevicesOther />,
        cName: 'sub-nav',
        privilegio: 'dis_leer'
      },
      {
        title: 'Create Device',
        path: '/creardispositivo',
        icon: <RiIcons.RiAddCircleFill />,
        cName: 'sub-nav',
        privilegio: 'dis_crear'
      },
      {
        title: 'Device Load',
        path: '/cargadispositivos',
        icon: <RiIcons.RiFileExcel2Fill />,
        cName: 'sub-nav',
        privilegio: 'dis_cargamasiva'
      }
    ]
  },  
  {
    title: 'Master Users',
    path: '',
    icon: <MdIcons.MdBusinessCenter />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    privilegio: 'admin_usuarios',
    maestro: false,
    global: false, 

    subNav: [
      {
        title: 'Users',
        path: '/users',
        icon: <BsIcons.BsFillCollectionFill />,
        cName: 'sub-nav',
        privilegio: 'admin_usuarios'        
      },
      {
        title: 'User Load',
        path: '/maestrousuario',
        icon: <BsIcons.BsFillCollectionFill />,
        cName: 'sub-nav',
        privilegio: 'admin_usuarios'        
      }       
    ]
  }
];