import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import swal from 'sweetalert';
import Select from 'react-select';
import { crearRegMaestro } from '../../functions/FuncMaestroCompuesto'

import { styleNormal } from '../../Commons/Styles'

const MaestroCompuestoCreacion = ({maestro, valores, token, valorNombre, filtrarOpciones, cambioValor}) => {

    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const onSubmit = (data) => {
        if(!validardatos(data))
        {
            swal("Creation Error", "Please enter a values for the Master", "warning");
        }
        else {
            swal({
                title: "Creation confirmation",
                text: "Do you want to create the master?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }). then(async (afirmacion) => {
                if(afirmacion)
                {
                    
                    var maestroCreacion = {
                        "indice": "",
                        "tabla": "",
                        "id": "",
                        "texto": "",
                        "campos": [
                        ]
                    };

                    maestroCreacion.tabla = maestro.tabla;
                    maestroCreacion.id = maestro.id;
                    maestroCreacion.texto = maestro.texto;
                    maestroCreacion.campos = maestro.campos;

                    let array = [];
                    maestroCreacion.campos.forEach(c => {
                        var cmc = {"nombre": c.nombre, "valor": data[c.nombre].value};
                        array.push(cmc); 
                    });

                    maestroCreacion.campos = array;                    

                    await crearRegMaestro(maestroCreacion, data.maestronuevo, token)
                    .then((response) => {  
                        
                        if(response.cod === 1)
                        {
                            setValue("maestronuevo", "");

                            maestroCreacion.campos.forEach(c => {
                                setValue(c.nombre, null);
                            });

                            swal("Successful creation", "", "success");                            
                        }
                        else {
                            swal ("Creation Error" , response.mensaje , "error");
                        }
                    });
                }
            })
        } 
    }

    function validardatos(data)
    {
        if(data.maestronuevo === "" ||
         data.maestronuevo === null || 
         data.maestronuevo === undefined) return false;
        
        var val = true;

        maestro.campos.forEach(c => {
            if (data[c.nombre] === "" ||
                data[c.nombre] === null || 
                data[c.nombre] === undefined) val = false;
        });

        return val;
    }

    function filtrar(campo, valor)
    {
        if(maestro.filtros === null) return;

        maestro.filtros.forEach((f) => {
            if(f.filtrado === campo) {

                if(valor === null || valor === undefined || valor === "") setValue(f.campo, null);
                else {

                    const filtrotipoArray = valores[f.valores];
                    var array = [];

                    if(filtrotipoArray.length > 0){
                        filtrotipoArray.map((p) => {
                            if(p[f.filtrado] === valor.value) {
                                array.push({"label": p[f.campo], "value": p[f.campo]});
                            }                
                        });        
                    }

                    valores[f.opciones] = array;
                    filtrarOpciones(valores)
                    cambioValor(valor.value)
                }
            }
        });
    }

    

    React.useEffect(() => {
        setValue("maestronuevo", valorNombre);        
    }, [maestro]);

    if(maestro === null) return (<></>)
    else return (
        <>
            <form
                style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                autoComplete="off" onSubmit={handleSubmit(onSubmit)}
            >
                <div className="container-general">
                    {
                        maestro.campos.map(c => {
                            return (<div key={"k-" + c.nombre} className="row">
                                        <div className="col-25">
                                            <label htmlFor={"i-" + c.nombre}>{c.label}</label>
                                        </div>
                                        <div className="col-75"> 
                                            <Controller
                                                render={(props) => 
                                                <Select 
                                                    options={valores[c.opciones]} 
                                                    placeholder=""
                                                    onChange={(value) => {
                                                        props.onChange(value)
                                                        filtrar(c.nombre, value)
                                                    }}
                                                    value={props.value} 
                                                    isClearable={true}
                                                    styles={styleNormal}
                                                    name={c.nombre}                                        
                                                />}
                                                control={control}                                                                                 
                                                name={c.nombre}
                                            />     
                                        </div>
                                    </div>
                            )
                        })
                    }

                    <div className="row">
                        <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor={"i-maestronuevo"}>{maestro.indice}</label> 
                        </div>
                        <div className="col-75">
                            <Controller
                                as={<input className="bootstrap-input" type='text'  />}                                      
                                control={control}
                                id={"i-maestronuevo"}
                                key={"k-maestronuevo"}
                                defaultValue=""                                
                                name="maestronuevo"            
                            />                              
                        </div>
                    </div>

                    <br></br>
                    <div className="row">
                        <div style={{width: '60%', marginRight:'auto', marginLeft: 'auto'}}>
                            <button className="boton-from">Save</button>
                        </div>                   
                    </div>
                </div>
            </form>
        </>
    )
}

export default MaestroCompuestoCreacion
