import React, {useState} from 'react'
import swal from 'sweetalert';
import * as General from './Generales'
import Loading from '../../Loading';
import Select from 'react-select'
import MaestroGeneralCreacion from './MaestroGeneralCreacion';
import MaestroGeneralTabla from './MaestroGeneralTabla';

import MaestroPlanta from './MaestroPlanta';
import { valoresMaestros } from '../../functions/FuncMaestroCompuesto'
import MaestroCompuesto from './MaestroCompuesto';

import { styleNormal } from '../../Commons/Styles'

const SelectorMaestro = ({token}) => {

    const [opciones, setOpciones] = useState(null);    
    const [valores, setValores] = useState(null);
    const [valor, setValor] = useState(null);
    const [maestro, setMaestro] = useState(null);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(null);
    const [nombreNuevo, setNombreNuevo] = useState("");
    const [filtro, setFiltro] = useState("");
    
    const [camposExcel, setCamposExcel] = useState(null);    

    function obtenerValores()
    {
        let options = [];
        
        General.valores.sort(compare).forEach(maestro => {
            options.push({"label": maestro.indice, "value": maestro.indice});
        })

        setOpciones(options);  
    }

    function compare( a, b ) {
        if ( a.indice < b.indice ){
          return -1;
        }

        if ( a.indice > b.indice ){
          return 1;
        }

        return 0;
    }

    function armarCamposExcel(campos)
    {
        var array = [];
        array.push({nombre:"Name", valor:"texto"});

        campos.forEach(c => {
            array.push({nombre:c.label, valor:c.nombre});
        });
        return array;
    }

    function escogerMaestro(value)
    {
        setNombreNuevo("");
        setFiltro("");
        setRows(null);
        obtenerValoresComp();

        if(value === null) setMaestro(null);
        else {
            General.valores.forEach(maestro => {
                if(maestro.indice === value.value) 
                {
                    console.log("ps")
                    setCamposExcel(armarCamposExcel(maestro.campos));
                    setMaestro(maestro);
                }
            })
        }
    }
    
    function aplicarFiltro(registros)
    {        
        if(registros == null) setRows(registros); 
        else if(registros.length === 0)
        {
            setRows(registros)            
            swal ("There are no records" ,
                    "No information is recorded with the filters used" ,
                    "info");
        }
        else setRows(registros);
    }

    function busquedaEliminacion(registros)
    {        
        setRows(registros);
    }

    function cambioFiltro(value)
    {        
        setFiltro(value);
    }

    function cambioValor(value)
    {        
        setValor(value);
    }

    function cambioValores(nuevos)
    {
        setValores(nuevos);
    }

    async function obtenerValoresComp()
    {
        await valoresMaestros(token)
        .then((response) => {
            if(response.cod === 1)
            {
                const val = {
                    "localidades": response.localidades,
                    "estados": response.estados,
                    "estadosequipos": response.estadosequipos,
                    "paises": response.paises,
                    "negocios": response.negocios,
                    "empresas": response.empresas,
                    "marcas": response.marcas,
                    "familias": response.familias,
                    "usabilidades": response.usabilidades,
                    "sistemasoperativosotros": response.sistemasoperativosotros,
                    "usabilidadesfiltro": response.usabilidadesfiltro,  
                    "filtrolocalidad": response.filtrolocalidad,
                    "basesdatos": response.basesdatos,                              
                }; 

                let array = [];

                const listas = ["localidades", "paises", "negocios", "empresas", "familias", "usabilidades", "marcas", "sistemasoperativosotros", "basesdatos", "estados", "estadosequipos"];

                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                setValores(val);
            }
        });
    }
    
    React.useEffect(() => {
        if(opciones === null) obtenerValores();
        if(valores === null) obtenerValoresComp();
    }, [maestro]);

    if(opciones === null || valores === null) return (<Loading></Loading>)
    else return (
        <>
            <div className="div-selector">
                <div className="div-selector-options">
                    <Select
                        options={opciones}
                        onChange={(value) => {
                            escogerMaestro(value);
                        }}
                        placeholder="Select Master"
                        isClearable={true}                         
                        defaultValue={null}
                        styles={styleNormal}
                    />
                </div>
            </div>
            <br></br>
            <br></br>
            <div className="div-general-base">
            {
                (loading) ? (<Loading />)
                : (
                    (maestro === null) ? (<></>)
                    : (
                        
                        (maestro.indice === "Location" || maestro.indice === "Condition" ||
                        maestro.indice === "Model" || 
                        maestro.indice === "Database Version App" || 
                        maestro.indice === "Usability" || 
                        maestro.indice === "Operating System Version" ||
                        maestro.indice === "Equipment Type") ? (
                            <MaestroCompuesto 
                                valores={valores} 
                                maestro={maestro} 
                                token={token} 
                                rows={rows} 
                                aplicarFiltro={aplicarFiltro} 
                                filtro={filtro} 
                                cambioFiltro={cambioFiltro}
                                busquedaEliminacion={busquedaEliminacion}
                                valorNombre={nombreNuevo}
                                filtrarOpciones={cambioValores}
                                cambioValor={cambioValor}
                                camposExcel={camposExcel}
                            />                            
                        ) : 
                        (maestro.indice === "Plant") ? (
                            <MaestroPlanta 
                                valores={valores} 
                                maestro={maestro} 
                                token={token} 
                                rows={rows} 
                                aplicarFiltro={aplicarFiltro} 
                                filtro={filtro} 
                                cambioFiltro={cambioFiltro}
                                busquedaEliminacion={busquedaEliminacion}                                
                            />
                        ) :
                        (
                            <>
                                <div className="divizq-general">
                                    <MaestroGeneralCreacion maestro={maestro} 
                                    token={token} valorNombre={nombreNuevo}/>
                                </div>
                                <div className="divder-general">
                                    <div className="container-general">
                                        <MaestroGeneralTabla 
                                            maestro={maestro} 
                                            token={token} 
                                            rows={rows} 
                                            aplicarFiltro={aplicarFiltro} 
                                            filtro={filtro} 
                                            cambioFiltro={cambioFiltro}
                                            busquedaEliminacion={busquedaEliminacion}
                                            camposExcel={camposExcel}
                                        /> 
                                    </div>               
                                </div>
                            </>
                        )
                    )
                )
            }
            
            </div>
        </>
    )
}

export default SelectorMaestro
