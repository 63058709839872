import React, { useState } from 'react'
import Sidebar from '../Sidebar'
import swal from 'sweetalert';
import '../css/cargaFile.css'
import MyExcel from '../formatos/DesktopBaseImport.xlsx';
import { uploadExcel, cargasExcel } from '../functions/FuncEquipoEscritorio'
import TablaCargas from '../Commons/TablaCargas';
import { useHistory } from "react-router-dom";

const CargaEquiposEscritorio = ({instance, t}) => {

    const [selectedFile, setSelectedFile] = useState( {file:null, fileField: "", file64: null});
    const ref = React.useRef();

    const history = useHistory();
    let priv = sessionStorage.getItem('esc_cargamasiva');

    function cargarArchivo()
    {
        
        let Base64 = '';

        if(selectedFile.file === null)
        {
            swal ("You have not selected a file" ,
                    "",
                    "warning")
        }
        else {
            swal({
                title: "Upload confirmation",
                text: "Do you want to upload the selected file?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((afirmacion) => {
                if(afirmacion)
                {
                    getBase64(selectedFile.file, (result) => {
                        Base64 = result;
                        subirArchivo(Base64);   
                   });
                }
            });
        }
    }

    async function subirArchivo(Base64)
    {
        await uploadExcel(Base64, selectedFile.file.name, sessionStorage.getItem('nombre'), "archivosequipo", "", t).then((response) => {
            
            if(response.cod === 1) 
            {
                swal("Upload request has been sent", "", "success")
                .then(() => {                               
                    setSelectedFile({file:null, fileField: ""});
                    ref.current.value = "";
                });
            }
            else {
                swal("Failed to load", response.mensaje, "error")
                .then(() => {                               
                    setSelectedFile({file:null, fileField: ""});
                    ref.current.value = "";
                });
            }
        }); 
        
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            
        };
    }

    const handleFileInput = (e) => {
        // handle validations
        const f = e.target.files[0];
        const ff = e.target.files;       

        setSelectedFile({file: f, fileField: ff, file64: null});
    }

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });

    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <>
            <Sidebar instance={instance} despliegue={false}/>

            <div className="containerfile">
                <div>
                    <input type="file" ref={ref} className="upload-box" onChange={handleFileInput}></input>
                    <a href={MyExcel} download="DesktopBaseImport.xlsx">
                        {/*<button className="boton-formato" href={MyExcel} download="DesktopBaseImport.xlsx">
                            <RiIcons.RiFileExcel2Fill/>
                        </button>*/}
                    </a>
                </div>
                <div>
                    <button className="boton-upload" onClick={cargarArchivo}>Upload</button>
                </div> 
                <div className="containerTablaExcel">
                    <TablaCargas
                        cargasExcel = { cargasExcel }
                        token = { t }
                        exportErrorsFileName = { 'DetallesEquiposEscritorioExport' }
                    />
                </div>  
                <br></br>
                <br></br>             
            </div>

            
        </>
    )
}

export default CargaEquiposEscritorio
