import React, { useState } from 'react'
import { useForm, Controller } from "react-hook-form";

import styled from 'styled-components';
import Modal from 'react-modal'
import "../css/modalservidores.css";

import swal from 'sweetalert';
import Loading from '../Loading';
import Select from 'react-select'

import * as BiIcons from 'react-icons/bi';

import { obtenerValoresModal, actualizarLocalidadEquipoModal, obtenerEquipoEscritorio } from '../functions/FuncEquipoEscritorio'
import { enviarNotificacionEquipos } from '../functions/FuncNotificacion'

import { style, styleNormal, ButtonRelacion } from '../Commons/Styles'

const ButtonConfirmar = styled.button`
    margin-left: auto;
    margin-right: auto;
    background-color: #EA7600; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #D35400;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    color: white;
    float:right;
`;

const ButtonCancelar = styled.button`
    margin-left: auto;
    margin-right: auto;
    background-color: #EA7600; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #D35400;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    color: white;
    
`;


const ModalActualizarPlanta = ({id, redireccion, token}) => {
    
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    
    const [modalOpen, setModalOpen] = useState(false);

    const [valores, setValores] = useState(null);

    const [equipo, setEquipo] = useState(null);
    const [empresa, setEmpresa] = useState(null);
    const [planta, setPlanta] = useState(null);
    const [pais, setPais] = useState(null);
    const [localidad, setLocalidad] = useState(null);
    const [negocio, setNegocio] = useState(null);
    const [usuario_1, setUsuario1] = useState(null);
    const [usuario_2, setUsuario2] = useState(null);

    const [errorPlanta, setErrorPlanta] = useState(false);
    const [errorUsuario, setErrorUsuario] = useState(false);

    let privilegio = sessionStorage.getItem('esc_escribir');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');

    function abrirModal()
    {
        setEmpresa(null);
        setPlanta(null);        
        setValores(null);
        setModalOpen(true);
    }

    function cancelar(){
        swal({
            title: "Cancellation confirmation",
            text: "Do you want to cancel the changes?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((afirmacion) => {
            if (afirmacion) {
                setEmpresa(null);
                setPlanta(null);        
                setValores(null);
                setModalOpen(false);
            }                   
        });
    }

    const cambioPlanta= (value) => { 
        if(value !== "" && value !== null){
            setPlanta({"label": value.value, "value": value.value});

            const filtrotipoArray = valores.filtroplantas;            
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.planta === value.value) {
                        setPais(p.pais);
                        setLocalidad(p.localidad);
                        setNegocio(p.negocio);
                        setEmpresa(p.empresa);
                    }                
                });        
            }

        } else {
            setPlanta(null);
            setPais("");
            setLocalidad("");
            setNegocio("");
            setEmpresa("");
        }
    }

    const cambioUsuario_1 = (value) => {
        if(value !== "" && value !== null) setUsuario1({"label": value.value, "value": value.value});
        else setUsuario1(null);
    }   
    
    const cambioUsuario_2 = (value) => {
        if(value !== "" && value !== null) setUsuario2({"label": value.value, "value": value.value});
        else setUsuario2(null);
    }   

    async function obtenerValores()
    {
        var privEscribir = sessionStorage.getItem('esc_escribir');
        var nivel = null;

        if(privEscribir === "1") nivel = "2";
        else nivel = "3";

        await obtenerValoresModal(nivel, paisUser, localidadUser, token)
        .then((response) => {
            if(response.cod === 1)
            {
                const val = {                    
                    "empresas": response.empresas,
                    "todasempresas": response.todasempresas,
                    "plantas": response.plantas,
                    "usuarios": response.usuariosmodal,
                    "usuariosmodal": response.usuariosmodal,
                    "filtroplantas": response.filtroplantas,                          
                }; 

                let array = [];

                const listas = ["empresas", "plantas"];

                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                array = [];
                val["usuarios"].map((u) => { array.push({"label": u.nombre, "value": u.nombre})});
                val["usuarios"] = array;

                setValores(val);
            }
        });

        await obtenerEquipoEscritorio(id, token).then((response) => {
            if(response.cod === 1)
            {
                setEquipo({
                    "numeroserie": response.equipo.numeroserie,                    
                    "planta":response.equipo.planta,                    
                    "localidad": response.equipo.localidad,
                    "pais": response.equipo.pais,
                    "nombreequipo": response.equipo.nombreequipo,
                    "tipoequipo": response.equipo.tipoequipo,
                    "marca": response.equipo.marca,
                    "modelo": response.equipo.modelo, 
                    "nombreusuario": response.equipo.nombreusuario,                    
                    "correo": response.equipo.correo
                });
            }
            else {
                swal("Error", response.mensaje, "error");
            }
        });
    }

    const onSubmit = (data) => {
        if(validarDatos()){
            swal("Form data", "Mandatory data must be entered", "warning");
        }
        else {
            swal({
                title: "Update confirmation",
                text: "Do you want update this Desktop? ",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion)
                {
                    let modificadopor = sessionStorage.getItem('nombre');
                    let correo = sessionStorage.getItem('email');                    
                    let fechaupdate = new Date();

                    var correo_usuario_1 = "";
                    var correo_usuario_2 = "";

                    
                    valores.usuariosmodal.forEach((um) => {
                        if(usuario_1.value === um.nombre) correo_usuario_1 = um.correo;

                        if(usuario_2 !== null){
                            if(usuario_2.value  === um.nombre) correo_usuario_2 = um.correo;
                        }
                    });
                    
                    await actualizarLocalidadEquipoModal(id, planta, modificadopor, fechaupdate, correo, correo_usuario_1, correo_usuario_2, token).then((response) => {
                        if(response.cod === 1)
                        {
                            var destinatarios = correo_usuario_1;
                            if(correo_usuario_2 !== "") destinatarios = destinatarios + ";" + correo_usuario_2;

                            var modificaciones = {
                                "planta": planta.value.toString().toUpperCase(),
                                "localidad": localidad,
                                "pais": pais
                            }

                            enviarNotificacionEquipos(modificadopor, destinatarios, equipo, modificaciones, token);

                            swal("Successful update", "", "success")
                            .then(() => {
                                setModalOpen(false);
                                redireccion(response.id);
                            });
                        }
                        else if(response.cod === 0){
                            swal("Update error", response.mensaje, "error");
                        }
                        else {
                            swal("Update error", "An error occurred during the update. Please contact the administrator.", "error");
                        }
                    });
                }
            })
        }
    }

    function validarDatos()
    {
        var errores = false; 

        if(planta=== null || planta === "" || planta === undefined) 
        {
            setErrorPlanta(true);
            errores = true;
        }
        else setErrorPlanta(false);

        
        if(usuario_1 === null || usuario_1 === "" || usuario_1 === undefined) 
        {
            setErrorUsuario(true);
            errores = true;
        }
        else setErrorUsuario(false);
        

        return errores;
    }

    React.useEffect(() => {       
       if(valores === null) obtenerValores();
    }, [valores])

    return (
        <>
            <ButtonRelacion type="button" onClick={abrirModal} className="tooltipboton">
                <BiIcons.BiArrowFromLeft />
                <span className="tooltiptextboton">{"Move location"}</span> 
            </ButtonRelacion>
            <Modal 
                isOpen={modalOpen}
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.75)'
                    },
                    content: {
                        width: '55%',
                        height: '80%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        position: 'absolute',                        
                        border: '1px solid #ccc',
                        background: '#fff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '20px'
                    }
                  }}
            >
                {
                    (valores === null) ? (<Loading />)
                    : (
                        <form
                            style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                            autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                        >                            
                            <br></br>
                            <div className="headermodal">
                                <div className="titulo-modalser">
                                    <label className="textotitulo-modal">Update Location Form</label>
                                </div>
                            </div> 
                            <div className="bodymodal">
                                <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-25-equipo">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-planta">Plant / Office*</label> 
                                            </div>
                                            <div className="col-75-equipo">
                                                <Controller
                                                    render={(props) => <Select 
                                                        options={valores.plantas}
                                                        placeholder=""
                                                        onChange={(value) => {
                                                            props.onChange(value)
                                                            cambioPlanta(value); 
                                                        }}  
                                                        isClearable={true}
                                                        value={planta}
                                                        styles={(errorPlanta) ? style : styleNormal}
                                                    />} 
                                                    control={control}
                                                    id="i-planta"
                                                    key="k-planta"                                    
                                                    name='planta'
                                                />                              
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-25-equipo">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-empresa">Enterprise</label> 
                                            </div>
                                            <div className="col-75-equipo">
                                                <Controller
                                                    render={(props) =>
                                                        <input 
                                                            disabled={'disabled'} 
                                                            className={"bootstrap-input-disable"}
                                                            type='text'
                                                            onChange={(value) => {
                                                                props.onChange(value);                                                        
                                                            }}                                                            
                                                            value={empresa}                                            
                                                        />                                                                           
                                                    }                                       
                                                    control={control}
                                                    id="i-empresa"
                                                    key="k-empresa"                                    
                                                    name='empresa'
                                                />                           
                                            </div>
                                        </div>                                   
                                        <div className="row">
                                            <div className="col-25-equipo">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-negocio">Business</label> 
                                            </div>
                                            <div className="col-75-equipo">
                                                <Controller
                                                    render={(props) =>
                                                        <input 
                                                            disabled={'disabled'} 
                                                            className={"bootstrap-input-disable"}
                                                            type='text'
                                                            onChange={(value) => {
                                                                props.onChange(value);                                                        
                                                            }}                                                            
                                                            value={negocio}                                            
                                                        />                                                                           
                                                    }                                       
                                                    control={control}
                                                    id="i-negocio"
                                                    key="k-negocio"                                    
                                                    name='negocio'
                                                />                           
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-25-equipo">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-localidad">Location</label> 
                                            </div>
                                            <div className="col-75-equipo">
                                                <Controller
                                                    render={(props) =>
                                                        <input 
                                                            disabled={'disabled'} 
                                                            className={"bootstrap-input-disable"}
                                                            type='text'
                                                            onChange={(value) => {
                                                                props.onChange(value);                                                        
                                                            }}                                                            
                                                            value={localidad}                                            
                                                        />                                                                           
                                                    }                                       
                                                    control={control}
                                                    id="i-localidad"
                                                    key="k-localidad"                                    
                                                    name='localidad'
                                                />                           
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-25-equipo">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-pais">Country</label> 
                                            </div>
                                            <div className="col-75-equipo">
                                                <Controller
                                                    render={(props) =>
                                                        <input 
                                                            disabled={'disabled'} 
                                                            className={"bootstrap-input-disable"}
                                                            type='text'
                                                            onChange={(value) => {
                                                                props.onChange(value);                                                        
                                                            }}                                                            
                                                            value={pais}                                            
                                                        />                                                                           
                                                    }                                       
                                                    control={control}
                                                    id="i-pais"
                                                    key="k-pais"                                    
                                                    name='pais'
                                                />                           
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-25-equipo">
                                                <label style={{fontWeight: 'bold'}} htmlFor="i-usuario_1">Notification 1*</label> 
                                            </div>
                                            <div className="col-75-equipo">
                                                <Controller
                                                    render={(props) => <Select 
                                                        options={valores.usuarios}
                                                        placeholder=""
                                                        onChange={(value) => {
                                                            props.onChange(value)
                                                            cambioUsuario_1(value); 
                                                        }}  
                                                        isClearable={true}
                                                        value={usuario_1}
                                                        styles={(errorUsuario) ? style : styleNormal}
                                                    />} 
                                                    control={control}
                                                    id="i-usuario_1"
                                                    key="k-usuario_1"                                    
                                                    name='usuario_1'
                                                />                              
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-25-equipo">
                                                <label htmlFor="i-usuario_2">Notification 2</label> 
                                            </div>
                                            <div className="col-75-equipo">
                                                <Controller
                                                    render={(props) => <Select 
                                                        options={valores.usuarios}
                                                        placeholder=""
                                                        onChange={(value) => {
                                                            props.onChange(value)
                                                            cambioUsuario_2(value); 
                                                        }}  
                                                        isClearable={true}
                                                        value={usuario_2}
                                                        styles={styleNormal}
                                                    />} 
                                                    control={control}
                                                    id="i-usuario_2"
                                                    key="k-usuario_2"                                    
                                                    name='usuario_2'
                                                />                              
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>                            
                            <div className="footermodal">                        
                                    <div className="divbotonmodalreemplazo">
                                        <ButtonCancelar type="button"  onClick={cancelar}>Return</ButtonCancelar>
                                        <ButtonConfirmar>Confirm</ButtonConfirmar>
                                    </div>
                            </div>
                        </form>
                    )
                }
            </Modal>
        </>
    )
}

export default ModalActualizarPlanta
