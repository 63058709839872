import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import '../css/aplicaciones.css'
import swal from 'sweetalert';

import ExportarExcel from '../ExportarExcel';

import Loading from '../Loading';
import * as Opciones from './OpcionesSalaServidor';
import FiltrosSalaServidor from './FiltrosSalaServidor';

import { TablaRegistros } from '../Commons/TablaRegistros';

import { ContainerBusqueda, ContainerTable } from '../Commons/Styles'


const headCells = [
    { id: 'nombre', numeric: false, disablePadding: true, label: 'Room', size: '10%' },
    { id: 'pais', numeric: false, disablePadding: true, label: 'Country', size: '10%' },
    { id: 'localidad', numeric: false, disablePadding: true, label: 'Location', size: '10%' },
    { id: 'planta', numeric: false, disablePadding: true, label: 'Plant', size: '25%' },
    { id: 'servidores', numeric: false, disablePadding: true, label: 'Servers', size: '5%' }
];

const TablaSalaServidor = ({token}) => {

    const [loading, setLoading] = React.useState(false); 

    const [rows, setRows] = useState(null);
    const [filter, setFilter] = useState("");

    function aplicarFiltro(registros)
    {
        if(registros == null) setRows(registros); 
        else if(registros.length === 0)
        {
            setRows(registros)            
            swal ("There are no records" ,
                    "No information is recorded with the filters used" ,
                    "info");
        }
        else setRows(registros);
    }

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.nombre !== "") ? (row.nombre.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.pais !== "") ? (row.pais.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.localidad !== "") ? (row.localidad.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.planta !== "") ? (row.planta.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.servidores !== "") ? (row.servidores.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
           
        })
    }

    return (
        <>
            <FiltrosSalaServidor aplicarFiltro={aplicarFiltro} load={setLoading} token={token}/>
            {
                (rows !== null)
                ?
                (
                    <>                     
                        
                        <ContainerBusqueda> 
                        <br></br> 
                        
                            <TextField id="outlined-search" label="Busqueda" size="small" type="search" variant="outlined" 
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                
                            />
                            <ExportarExcel rows={rows} columnas={Opciones.camposExcel} nombreSheet="SeverRoom" nombreArchivo="SeverRoomExport"/>
                        <br></br>                  
                        </ContainerBusqueda>
                        <ContainerTable>
                            <TablaRegistros
                                headCells = { headCells }
                                rows = { buscar(rows) }
                                url = { '/salaservidor/' }
                                idFieldName = { 'id_sala' }
                                initialSortByField = { 'nombre' }
                                initialSortDirection = { 'desc' }
                                />
                        </ContainerTable>                    
                </>)
                :
                (
                    (loading) ? (
                        <>
                            <br></br>
                            <br></br>
                            <ContainerTable>
                                <Loading />
                            </ContainerTable>
                        </>
                    )  : null 
                )
            }
        </>
    )
}

export default TablaSalaServidor
