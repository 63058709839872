import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';

import FiltrosServidor from './FiltrosServidor';
import ExportarExcel from '../ExportarExcel';

import ExportarExcelAplicacionesRel from '../ExportarExcelAplicacionesRel';
import * as Opciones from './OpcionesServidor'
import Loading from '../Loading';

import ExportarExcelBitacoras from '../ExportarExcelBitacoras';

import { TablaRegistros } from '../Commons/TablaRegistros';

import { ContainerBusqueda, ContainerTable } from '../Commons/Styles'

const headCells = [
    { id: 'servidor', numeric: false, disablePadding: true, label: 'Server', size: '10%' },
    { id: 'plataforma', numeric: false, disablePadding: true, label: 'Network', size: '10%' },
    { id: 'negocio', numeric: false, disablePadding: true, label: 'Business', size: '8%' },
    { id: 'estado', numeric: false, disablePadding: true, label: 'State', size: '8%' },
    { id: 'ambiente', numeric: false, disablePadding: true, label: 'Environment', size: '10%' },   
    { id: 'tipo', numeric: false, disablePadding: true, label: 'Server Type', size: '10%' },
    { id: 'sistema', numeric: false, disablePadding: true, label: 'Operating System', size: '25%' },
    { id: 'localidad', numeric: false, disablePadding: true, label: 'Location', size: '10%' },
    { id: 'pais', numeric: false, disablePadding: true, label: 'Country', size: '5%' },
    { id: 'cantidadapps', numeric: false, disablePadding: true, label: 'Applications', size: '4%' },
    { id: 'fechaupdate', numeric: false, disablePadding: true, label: 'Update Date', size: '5%' },
];

const TablaServidor = ({token}) => {

    const [loading, setLoading] = React.useState(false); 

    const [rows, setRows] = useState(null);
    const [relaciones, setRelaciones] = useState(null);
    const [bitacoras, setBitacoras] = useState(null);
    const [filter, setFilter] = useState("");

    let privEliminacion = sessionStorage.getItem('ser_eliminar');

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.servidor !== "") ? (row.servidor.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.plataforma !== "") ? (row.plataforma.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.negocio !== "") ? (row.negocio.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.estado !== "") ? (row.estado.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.tiposervidor !== "") ? (row.tiposervidor.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.soservidor !== "") ? (row.soservidor.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.localidad !== "") ? (row.localidad.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.ambiente !== "") ? (row.ambiente.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.descripcion !== "") ? (row.descripcion.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.fechaupdate !== "") ? (row.fechaupdate.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
        })
    }

    function obtenerRelaciones(registros)
    {
        setRelaciones(registros);
    }

    function obtenerBitacoras(registros)
    {
        setBitacoras(registros);
    }

    function aplicarFiltro(registros)
    {
        if(registros == null) setRows(registros); 
        else if(registros.length === 0)
        {
            swal ("There are no records" ,
                    "No information is recorded with the filters used" ,
                    "info");
        }
        else setRows(registros);
    }

    return (
        <>
            <FiltrosServidor aplicarFiltro={aplicarFiltro} obtenerRelaciones={obtenerRelaciones} obtenerBitacoras={obtenerBitacoras} load={setLoading} token={token}/>
            {
                (rows !== null)
                ? 
                (<>

                    <ContainerBusqueda> 
                        <br></br> 
                        
                            <TextField id="outlined-search" label="Search" size="small" type="search" variant="outlined" 
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                
                            />
                            <>
                                <ExportarExcel subtitulo={"Servers"} rows={rows} columnas={Opciones.camposExcel} nombreSheet="Servers" nombreArchivo="ServersExport"/>
                                {
                                    (relaciones === null) ? (<></>)
                                    : (
                                        <ExportarExcelAplicacionesRel subtitulo={"Applications"} rows={relaciones} columnas={Opciones.camposExcelAplicaciones} nombreSheet="ServersSecundary" nombreArchivo="ServersApplicationsExport"/>
                                    )                                    
                                }
                                {
                                    (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>) :
                                    (bitacoras === null) ? (<></>)
                                    : (                                        
                                        <ExportarExcelBitacoras subtitulo={"Logs"} rows={bitacoras} columnas={Opciones.camposExcelBitacora} nombreSheet="LogsServer" nombreArchivo="LogsServersExport"/>
                                    )
                                }
                            </>
                        <br></br>                  
                    </ContainerBusqueda>
                    <ContainerTable>
                        <TablaRegistros
                            headCells = { headCells }
                            rows = { buscar(rows) }
                            url = { '/servidores/' }
                            idFieldName = { 'id_servidor' }
                            initialSortByField = { 'servidor' }
                            initialSortDirection = { 'desc' }
                        />
                    </ContainerTable>                    
                </>)
                :
                (
                    (loading) ? (
                        <>
                            <br></br>
                            <br></br>
                            <ContainerTable>
                                <Loading />
                            </ContainerTable>
                        </>
                    )  : null
                    
                )
            }
        </>
    )
}

export default TablaServidor
