import React, { useState } from 'react';
import { makeStyles, useTheme, withStyles  } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import '../../css/aplicaciones.css'
import swal from 'sweetalert';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ExportarExcel from '../../ExportarExcel';
import * as MdIcons from 'react-icons/md';
import Loading from '../../Loading';
import * as Opciones from './OpcionesAplicacionPermitida';
import { construirFecha } from '../../functions/MetodosComunes'
import * as AiIcons from 'react-icons/ai';
import * as RiIcons from 'react-icons/ri';
import * as FaIcons from 'react-icons/fa';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
}));

const ButtonRelacion = styled.button`
    width: 50px;    
    height: 40px;
    background-color: #6294D8;
    color: white;
    font-size: 20px;
    border-radius: 5px;  
    border-color: #2E70C8;
    cursor: pointer;   
    float:right; 
    margin-left: auto;
    margin-right: 10px;

    :hover {
      background: #7DAACC;
  }
`;

const ButtonTotales = styled.button`
    width: 50px;    
    height: 40px;
    background-color: #234e81;
    color: white;
    font-size: 20px;
    border-radius: 5px;  
    border-color: #0d385b;
    cursor: pointer;   
    float:right; 
    margin-left: auto;
    margin-right: 10px;

    :hover {
      background: #7DAACC;
  }
`;

const headCells = [    
    { id: 'id_aplicacionpermitida', ordertype: 'int', numeric: false, disablePadding: true, label: 'ID', size: '10%' },
    { id: 'nombre', ordertype: 'text', numeric: false, disablePadding: true, label: 'Name', size: '35%' },
    { id: 'familia', ordertype: 'text', numeric: false, disablePadding: true, label: 'Family', size: '20%' },
    { id: 'condicion', ordertype: 'text', numeric: false, disablePadding: true, label: 'Condition', size: '15%' },
    { id: 'total', ordertype: 'text', numeric: false, disablePadding: true, label: 'Total', size: '10%' },
    { id: 'actualizar', ordertype: 'text', numeric: false, disablePadding: true, label: '', size: '5%' },
    { id: 'eliminar', ordertype: 'text', numeric: false, disablePadding: true, label: '', size: '5%' }
];

const ContainerBusqueda = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
`;

const ContainerTable = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
`;

const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.white,
      
    },
    body: {
        textDecoration: 'none',
      fontSize: 12,
    },
}))(TableCell);

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const TablaAplicacionPermitida = ({rows, seleccionar, crear, eliminar, obtenerRegistrosTotales}) => {

    const [loading, setLoading] = React.useState(false);
    const [filter, setFilter] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('nombre');
    const [orderType, setOrderType] = useState('text');
    const classes = useStyles();


    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.id_aplicacionpermitida !== "") ? (row.id_aplicacionpermitida.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true; 
            
            r = (row.nombre !== "") ? (row.nombre.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true; 

            r = (row.familia !== "") ? (row.familia.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true; 

            r = (row.condicion !== "") ? (row.condicion.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true; 
            
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = (property, ordertype) => (event) => {
        handleRequestSort(event, property, ordertype);
    };

    const handleRequestSort = (event, property, ordertype) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setOrderType(ordertype);
    };

    function stableSort(array) {

        if(orderType === "text")
        {
            if(order === 'desc')
            {
                var arrayOrdenado = array.sort((a,b) => (a[orderBy] > b[orderBy]) ? 1 : (b[orderBy] > a[orderBy]) ? -1 : 0);
                return arrayOrdenado;
            }
            else{
                var arrayOrdenado = array.sort((a,b) => (a[orderBy] < b[orderBy]) ? 1 : (b[orderBy] < a[orderBy]) ? -1 : 0);
                return arrayOrdenado;
            }
        }
        else {
            if(order === 'desc'){
                var arrayOrdenado = array.sort(function (a, b) {
                    return a[orderBy] - b[orderBy];
                });
                return arrayOrdenado;
            }
            else {
                var arrayOrdenado = array.sort(function (a, b) {
                    return b[orderBy] - a[orderBy];
                });
                return arrayOrdenado;
            }
        }
    }


  return (
    <>
        {
            (rows === null) ? (
                <ContainerTable>
                    <Loading />
                </ContainerTable>
            ) : (
                <>                    
                    <ContainerBusqueda> 
                        <br></br>                         
                        <TextField id="outlined-search" 
                            label="Busqueda" 
                            size="small" 
                            type="search" 
                            variant="outlined" 
                            value={filter}
                            style={{background: "#FFFFFF"}}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                          <ExportarExcel rows={rows} columnas={Opciones.camposExcel} nombreSheet="AllowedApplications" nombreArchivo="AllowedApplicationsExport"/>                          

                          <ButtonRelacion type="button" onClick={crear}>
                              <RiIcons.RiAddCircleFill />
                          </ButtonRelacion>

                          <ButtonTotales type="button" onClick={obtenerRegistrosTotales}>
                              <FaIcons.FaSearchPlus />
                          </ButtonTotales>   
                          
                        <br></br>                  
                    </ContainerBusqueda>
                    <ContainerTable>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table} 
                          size="small" 
                          aria-label="enhanced table"
                        >
                          <TableHead style={{ background: '#696158', color: '#fff' }}>
                            <TableRow>
                              {headCells.map((headCell) => (
                                <StyledTableCell 
                                  key={headCell.id}
                                  align={'center'}   
                                  sortDirection={orderBy === headCell.id ? order : false}
                                  style={{ width: headCell.size, color: '#fff !important' }}
                                >
                                  <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id, headCell.ordertype)}
                                    style={{ background: '#696158', color: '#fff' }}
                                  >
                                    {headCell.label}
                                  </TableSortLabel>                                        
                                </StyledTableCell>
                              ))}                                
                            </TableRow>
                          </TableHead>
                          <TableBody> 
                            {   
                              stableSort(buscar(rows))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)                                    
                              .map((row) => (                                                
                                <TableRow hover key={row.id_aplicacionpermitida}  style={{ textDecoration: 'none', fontSize: '10px !important', backgroundColor: (row.estado !== "INACTIVE") ? '' : '#efbbbb'  }}>
                                  <StyledTableCell align="right">{row.id_aplicacionpermitida}</StyledTableCell>                                                    
                                  <StyledTableCell align="left">{row.nombre}</StyledTableCell>                                                    
                                  <StyledTableCell align="left">{row.familia}</StyledTableCell>
                                  <StyledTableCell align="left">{row.condicion}</StyledTableCell>
                                  <StyledTableCell align="right">{row.total}</StyledTableCell>
                                  <StyledTableCell align="left">{
                                        <button 
                                            onClick={() => {
                                              seleccionar(row)                                                       
                                            }}
                                            className="boton-observacion">
                                            <AiIcons.AiFillEdit />
                                        </button> 
                                    }</StyledTableCell> 
                                  <StyledTableCell align="left">{
                                        <button 
                                            onClick={() => {
                                              eliminar(row.id_aplicacionpermitida)                                                       
                                            }}
                                            className="boton-eliminar tooltipboton">
                                            <AiIcons.AiFillDelete/>
                                            <span className="tooltiptextboton">{"Delete"}</span> 
                                        </button> 
                                  }</StyledTableCell>  
                                </TableRow>
                              ))
                            }     
                          </TableBody>
                        </Table>
                        <Table>
                          <TableBody> 
                            <TableRow>
                              <TablePagination  style={{ display: 'flex'}}
                                rowsPerPageOptions={[7]}
                                colSpan={3}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                  inputProps: { 'aria-label': 'Registros por pagina' },
                                  native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                              />
                            </TableRow>
                          </TableBody> 
                          </Table>
                      </TableContainer>
                    </ContainerTable>
                </>
            )
        }
    
    </>
  )
}

export default TablaAplicacionPermitida