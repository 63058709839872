import React, { useState } from 'react';
import { makeStyles, useTheme, withStyles  } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';

import * as AiIcons from 'react-icons/ai';
import * as MdIcons from 'react-icons/md';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import swal from 'sweetalert';
import { eliminarRelacionesEquipo } from '../functions/FuncAplicacion'
import { registrarBitacoraBase } from "../functions/FuncBitacora"
import ModalRelacionEquipo from './ModalRelacionEquipo';
import ExportarExcel from '../ExportarExcel';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
}));

const headCells = [  
    { id: 'id_equipoescritorio', numeric: false, disablePadding: true, label: 'Desktop ID', size: '10%' },
    { id: 'estado', numeric: false, disablePadding: true, label: 'State', size: '15%' },
    { id: 'condicion', numeric: false, disablePadding: true, label: 'Condition', size: '15%' },   
    { id: 'nombreequipo', numeric: false, disablePadding: true, label: 'Desktop Name', size: '25%' },
    { id: 'nombreusuario', numeric: false, disablePadding: true, label: 'User Name', size: '25%' },    
    { id: 'observaciones', numeric: false, disablePadding: true, label: '', size: '5%' },
    { id: 'boton', numeric: false, disablePadding: true, label: '', size: '5%' }           
];

const ContainerBusqueda = styled.div`
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
`;

const ContainerTable = styled.div`
    width: 98%;
    margin-left: auto;
    margin-right: auto;
`;

const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.white,
    },
    body: {
        textDecoration: 'none',
        fontSize: 12
    },
}))(TableCell);

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const TablaRelacionEquipo = ({rows, id, privModificar, actualizarEquipos, nombreApp, camposExcel, token}) => {

    const [filter, setFilter] = useState("");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(4);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('numeroserie');

    const classes = useStyles();

    let privilegio = sessionStorage.getItem('app_escribir');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function buscar(rows)
    {
        if(rows === null) return rows;

        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.id_equipoescritorio !== "") ? (row.id_equipoescritorio.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
            
            r = (row.estado !== "") ? (row.estado.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.condicion !== "") ? (row.condicion.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.nombreequipo !== "") ? (row.nombreequipo.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.nombreusuario !== "") ? (row.nombreusuario.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.planta !== "") ? (row.planta.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.empresa !== "") ? (row.empresa.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
        })
    }

    function stableSort(array) {
        if(order === 'desc')
        {
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] > b[orderBy]) ? 1 : (b[orderBy] > a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
        else{
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] < b[orderBy]) ? 1 : (b[orderBy] < a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
    }

    async function registrarBitacora(equipo)
    {
        let nombreusuario = sessionStorage.getItem('nombre');

        registrarBitacoraBase(
            id, "Aplicacion", 
            nombreusuario, 
            "Desktops", equipo, "DELETED",
            token
        ).then((response) => {
            console.log(response)
        });
    }

    async function desvincular(equipos)
    {
        await eliminarRelacionesEquipo(id, equipos, token)
        .then((response) => {
            if(response.cod === 1){

                registrarBitacora(equipos[0].nombreequipo)
                actualizarEquipos();

                swal ("Successful operation" ,
                    "The Desktop has been unlinked successfully" ,
                    "success")
                .then(() => {
                    
                });
            }
            else {
                swal ("Operation error",
                    response.mensaje,
                    "warning");
            }
        });
    }

    function eliminarRelacion(equipos)
    {
        swal({
            title: "Desvinculation confirmation",
            text: "Do you want to unlink Desktop " + equipos[0].nombreequipo + " from the application?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((afirmacion) => {
            if (afirmacion) {
                desvincular(equipos);
            }                   
        });
    }
    

    return (
        <>
            <ContainerBusqueda>               
                                         
                <TextField 
                    style={{backgroundColor: '#fff'}}
                    id="outlined-search" label="Search" size="small" type="search" variant="outlined" 
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
                <div className="divbotones-relacion">
                    <div className="col-30-right">
                    {
                        (!privModificar) ? (<></>)
                        : (<ModalRelacionEquipo id={id} actualizarEquipos={actualizarEquipos} nombreApp={nombreApp} token={token}/>)
                    }
                    </div>
                    <div className="col-30-right">
                    <ExportarExcel rows={rows} columnas={camposExcel} nombreSheet="Desktops" nombreArchivo="DesktopsExport"/>
                    </div>
                </div>                                    
            </ContainerBusqueda>
            <ContainerTable>
                <TableContainer component={Paper}>
                    <Table 
                        className={classes.table} 
                        size="small" 
                        aria-label="enhanced table"
                    >
                        <TableHead style={{ background: '#696158', color: '#fff' }}>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <StyledTableCell 
                                        key={headCell.id}
                                        align={'center'}   
                                        sortDirection={orderBy === headCell.id ? order : false}
                                        style={{ width: headCell.size, color: '#fff !important' }}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                            style={{ background: '#696158', color: '#fff' }}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>                                        
                                    </StyledTableCell>
                                ))}                                
                            </TableRow>
                        </TableHead>
                        <TableBody>  
                            {   
                                (rows !== null)
                                ?                            
                                (    
                                    stableSort(buscar(rows))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)                                    
                                    .map((row) => (                                        
                                        <TableRow hover key={row.id_equipoescritorio} style={{ textDecoration: 'none', fontSize: '10px !important', backgroundColor: (row.estado !== "INACTIVE") ? '' : '#efbbbb' }}>
                                            <StyledTableCell component={Link} to={`/equipos/${row.id_equipoescritorio}`} align="left">{row.id_equipoescritorio}</StyledTableCell>
                                            <StyledTableCell component={Link} to={`/equipos/${row.id_equipoescritorio}`} align="left">{row.estado}</StyledTableCell>
                                            <StyledTableCell component={Link} to={`/equipos/${row.id_equipoescritorio}`} align="left">{row.condicion}</StyledTableCell>
                                            <StyledTableCell component={Link} to={`/equipos/${row.id_equipoescritorio}`} align="left">{row.nombreequipo}</StyledTableCell>
                                            <StyledTableCell component={Link} to={`/equipos/${row.id_equipoescritorio}`} align="left">{row.nombreusuario}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <button 
                                                    type="button"
                                                    onClick={() => {
                                                        swal({
                                                            text: row.observaciones,
                                                            className: 'sweetalert-observacion'
                                                        });
                                                        }}
                                                    className="boton-observacion">
                                                    <MdIcons.MdDescription/>
                                                </button>    
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (!privModificar) ? (
                                                        <></>
                                                    ) : 
                                                    (
                                                        <button 
                                                            type="button"
                                                            onClick={() => {
                                                                const equipo = [{"numeroserie": row.numeroserie, "id_equipoescritorio": row.id_equipoescritorio, "nombreequipo": row.nombreequipo}];
                                                                eliminarRelacion(equipo);                                                       
                                                            }}
                                                            className="boton-eliminar tooltipboton">
                                                            <AiIcons.AiFillDelete/>
                                                            <span className="tooltiptextboton">{"Delete"}</span> 
                                                        </button>
                                                    )
                                                }
                                                 
                                            </StyledTableCell>
                                           
                                        </TableRow>
                                    ))
                                )
                                :
                                (
                                    <TableRow></TableRow>
                                )
                            }        
                        </TableBody>            
                    </Table>
                
                    <Table>
                        <TableBody> 
                        <TableRow>
                            <TablePagination  
                                style={{ display: 'flex'}}
                                rowsPerPageOptions={[4]}
                                colSpan={3}
                                count={(rows !== null) ? rows.length : 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'Registros por pagina' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </ContainerTable>
        </>
    )
}

export default TablaRelacionEquipo
