import { Controller } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../css/formularioServidor.css";

import { style, styleNormal, styleDisable } from './Styles'

import es from "date-fns/locale/es";
import Select from 'react-select'
registerLocale("es", es);



export const Formulario  = ({ fields, registro, valores, privModificar, onSubmit, register, handleSubmit, control }) => {

  const renderInputForm = ( field ) => {

    let disabledValue = ''

    if (field.disabled === true || privModificar === false) {
      disabledValue = 'disabled'
    }

    if (field.type === 'text') {

        return (
            <Controller
            as={<input disabled = { disabledValue } className={((disabledValue === '') ? "bootstrap-input" : "bootstrap-input-disable")} type='text'  />}  
            style={(field.hasErrorValidation) ? { borderColor: '#f90000'} : {}}
            control={control}
            id= { "i-" +  field.name }
            key= { "k-" + field.name }
            defaultValue={registro ? registro[field.name] : ''}
            name= { field.name }/> 
        );

    } else if ( field.type === 'select') {

        return (
            <Controller
            as={<Select/>}
            name = { field.name }
            options={ valores[field.valuesNameField] } 
            isClearable={ true }
            isDisabled={(disabledValue === '') ? false : true}
            placeholder=""
            defaultValue={registro ? registro[field.name] : ''}
            control={control}
            styles={(field.hasErrorValidation) ? style : (disabledValue === '') ? styleNormal : styleDisable}/> 
        );

    } else if ( field.type === 'textarea') {

        return (
            <Controller
            as={<textarea disabled={ disabledValue } className={((disabledValue === '') ? "bootstrap-input" : "bootstrap-input-disable")} rows="2" cols="50"></textarea>}
            control={control}
            id= { "i-" +  field.name }
            key= { "k-" + field.name }
            defaultValue={registro ? registro[field.name] : ''}
            name= { field.name }/> 
        );

    } else if ( field.type === 'checkbox') {
        return (
            <input
                disabled={ disabledValue }
                type="checkbox"
                id= { "i-" +  field.name }
                key= { "k-" + field.name }
                name= { field.name }
                value={true}
                placeholder="luo"
                onChange={field.setFunction}
                ref={register}/>
        );

      } else if ( field.type === 'datepicker') {
        return (
          <Controller
            name = { field.name }
            id = { "i-" + field.name }
            className="bootstrap-input"
            control={control}
            render={({ onChange, value }) => (
              <DatePicker
                  disabled= { disabledValue }
                  selected={value}
                  dateFormat="yyyy/MM/dd"
                  autoComplete="off"
                  onChange={onChange}
                  className={((disabledValue === '') ? "bootstrap-input" : "bootstrap-input-disable")}
                  locale="es"
              />
            )}
          /> 
        );

    }

    return 'Error en el campo';
  }

  let group = 1;

  const renderSeparator = ( fieldGroup ) => {

      if (group === fieldGroup ) {

          return null;
      }

      group = fieldGroup;
      
      return (
          <>
              <br></br>
              <hr style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
          </>
      );       
  }



  return (
    <form 
    style={{width: '90%', marginRight:'auto', marginLeft: 'auto'}} 
    autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {   
          fields.map((field, index) => (
              <>
              { renderSeparator(field.group) }

                  <div key = { index } className = "row">
                  
                      <div className = "col-25">
                      <label
                          style={{fontWeight: ((field.hasErrorValidation !== null) ? 'bold' : 'light') }}
                          htmlFor={ "i-"+field.name }>
                              { field.label }
                              { (field.hasErrorValidation !== null) ? <label style={{color: 'red'}}>{"*"}</label> : null }
                              {
                                  (valores.traducciones[field.name] === "" || valores.traducciones[field.name] === null || valores.traducciones[field.name] === undefined) ? (<></>)
                                  : (
                                      <div className="tooltip">&nbsp;&#x1F6C8;
                                          <span className="tooltiptext">{valores.traducciones[field.name]}</span>
                                      </div>
                                  )
                              }
                      </label> 
                      </div> 
                      <div className="col-75">
                          {   
                              renderInputForm(field) 
                          }
                          
                      </div>
                  </div>
                  
              </>

          ))
      }   
      <br></br>
      <div className="row">
          {                            
              (privModificar)
              ? 
              (
                  
                  <div style={{width: '60%', marginRight:'auto', marginLeft: 'auto'}}>
                      <button 
                      className="boton-from">Save</button>
                  </div>
              ) 
              :
              (
                  <></>
              )
          }                        
      </div>                                       
    </form>                            
  )
}