import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';

import { TablaRegistros } from '../Commons/TablaRegistros';

import { ContainerBusqueda, ContainerTable } from '../Commons/Styles'

const headCells = [
    { id: 'nombre', numeric: false, disablePadding: true, label: 'Name', size: '50%' },
    { id: 'version', numeric: false, disablePadding: true, label: 'Version', size: '30%' },
    { id: 'fechaupdate', numeric: false, disablePadding: true, label: 'SCCM Date', size: '20%' }     
];

const TablaAplicacionesSccm = ({rows}) => {

    const [filter, setFilter] = useState("");

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.nombre !== "") ? (row.nombre.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;          
        })
    }

    return (
        <>
          <ContainerBusqueda>                                    
              <TextField 
                  style={{backgroundColor: '#fff'}}
                  id="outlined-search" 
                  label="Search" 
                  size="small" 
                  type="search" 
                  variant="outlined" 
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
              />                            
          </ContainerBusqueda>
          <ContainerTable>
            <TablaRegistros
                headCells = { headCells }
                rows = { buscar(rows) }
                url = { null }
                idFieldName = { null }
                initialSortByField = { 'nombre' }
                initialSortDirection = { 'desc' }
                />
          </ContainerTable>  
        </>
    )
}

export default TablaAplicacionesSccm
