export const camposExcel = [
    {nombre:"NAME", valor:"nombre"},
    {nombre:"CERTIFICATE TYPE", valor:"tipo"},
    {nombre:"PROVIDER", valor:"proveedor"},
    {nombre:"CERTIFYING ENTITY", valor:"entidadcertificadora"},
    {nombre:"STATE", valor:"estado"},
    {nombre:"DESCRIPTION", valor:"descripcion"},
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"BUSINESS", valor:"negocio"},
    {nombre:"RESPONSABLE", valor:"responsable"},
    {nombre:"CREATION DATE", valor:"fechacreacion"},
    {nombre:"EXPIRATION DATE", valor:"fechaexpiracion"},
    {nombre:"INFORM TO", valor:"informara"},
    {nombre:"OBSERVATIONS", valor:"observaciones"},
       
    {nombre:"MODIFIED BY", valor:"modificadopor"},
    {nombre:"MODIFICATION DATE", valor:"fechaupdate"}
]

export const camposExcelServidores = [
    {nombre:"SERVER", valor:"servidor"},
    {nombre:"CONDITION", valor:"estado"},    
    {nombre:"ENVIRONMENT", valor:"ambiente"},
    {nombre:"SERVER TYPE", valor:"tiposervidor"},
    {nombre:"DESCRIPTION", valor:"descripcion"}
]

export const camposExcelAplicaciones = [
    {nombre:"APPLICATION", valor:"nombre"},
    {nombre:"VERSION", valor:"version"},   
    {nombre:"ENVIRONMENT", valor:"ambiente"},
    {nombre:"TYPE APPLICATION", valor:"tiposoftware"},    
    {nombre:"OBSERVATIONS", valor:"observaciones"}
]

export const campos = [
    "id_certificado",
    "nombre",
    "tipo",
    "proveedor",
    "entidadcertificadora",
    "estado",
    "descripcion",
    "pais",
    "negocio",
    "responsable",
    "informara",
    "fechacreacion",
    "fechaexpiracion",
    "observaciones",
    "modificadopor",
    "fechaupdate"    
]