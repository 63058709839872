export function getVal(response) {
    return {
        "tiposactivo" : response.tiposactivo,
        "ambientes" : response.ambientes,
        "criticidades" : response.criticidades,
        "propiedades" : response.propiedades,
        "negocios" : response.negocios,
        "plantasindustriales" : response.plantasindustriales,
        "sistemasoperativos" : response.sistemasoperativos,
        "tiposservidor" : response.tiposservidor,
        "antivirus" : response.antivirus,
        "arearesponsables" : response.arearesponsables,
        "estados" : response.estados,
        "tiposparchado" : response.tiposparchado,
        "tiposbd" : response.tiposbd,
        "tiposrespaldofrecuencia" : response.tiposrespaldofrecuencia,
        "tipospuerto" : response.tipospuerto,
        "tiposaccesoremoto" : response.tiposaccesoremoto,
        "accesoremotoaplicaciones" : response.accesoremotoaplicaciones,
        "protocolos" : response.protocolos,
        "traducciones" : response.traducciones,
        "filtroplanta" : response.filtroplanta,
        "filtrolocalidad" : response.filtrolocalidad
    };
};

export const listas = [ "tiposactivo", "ambientes", "criticidades", "propiedades", "negocios", "plantasindustriales", "sistemasoperativos", "tiposservidor", "antivirus", "arearesponsables", "estados", "tiposparchado", "tiposbd", "tiposrespaldofrecuencia", "tipospuerto", "tiposaccesoremoto", "accesoremotoaplicaciones", "protocolos"];
    
export const campos = [
    "tipoactivo",
    "dispositivoot",
    "numeroserie",
    "descripcion",
    "ambiente",
    "criticidad",

    "ubicacion",
    "zona",
    "piso",
    "gabinete",
    "propiedadde",
    "negocio",
    "plantaindustrial",

    "proveedor",
    "modelo",
    "sodispositivoot",
    "firmware",
    "tiposervidor",
    "antivirus",
    "arearesponsable",
    "contactoresponsable",
    "estado",
    "tipoparchado",
    "observacion",
    "tipobd",

    "tiporespaldo",
    "tiporespaldofrecuencia",

    "tipopuerto",
    "nombrered",
    "idvlan",
    "direccionip",
    "direccionred",
    "direccionmac",
    "tipoaccesoremoto",
    "accesoremotoaplicacion",
    "protocolo",

    "modificadopor",
    "fechaactualizacion"
]

export const camposExcel = [
    { nombre: 'ASSET TYPE', valor: 'tipoactivo' },
    { nombre: 'SERIAL NUMBER' , valor: 'numeroserie' },
    { nombre: 'DEVICE NAME', valor: 'dispositivoot' },
    { nombre: 'DESCRIPTION', valor: 'descripcion' },
    { nombre: 'ENVIRONMENT', valor: 'ambiente' },
    { nombre: 'CRITICALITY', valor: 'criticidad' },

    { nombre: 'UBICATION', valor: 'ubicacion' },
    { nombre: 'ZONE', valor: 'zona' },
    { nombre: 'FLOOR', valor: 'piso' },
    { nombre: 'CABINET OR RACK', valor: 'gabinete' },
    { nombre: 'PROPERTY OF', valor: 'propiedadde' },
    { nombre: 'INDUSTRIAL PLANT', valor: 'plantaindustrial' },
    { nombre: 'BUSINESS', valor: 'negocio' },
    { nombre: 'LOCATION', valor: 'localidad' },
    { nombre: 'COUNTRY', valor: 'pais' },

    { nombre: 'VENDOR', valor: 'proveedor' },
    { nombre: 'MODEL', valor: 'modelo' },
    { nombre: 'OPERATING SYSTEM', valor: 'sodispositivoot' },
    { nombre: 'FIRMWARE VERSION', valor: 'firmware' },
    { nombre: 'SERVER TYPE', valor: 'tiposervidor' },
    { nombre: 'ANTIVIRUS', valor: 'antivirus' },
    { nombre: 'RESPONSABLE AREA', valor: 'arearesponsable' },
    { nombre: 'RESPONSABLE CONTACT', valor: 'contactoresponsable' },
    { nombre: 'STATE', valor: 'estado' },
    { nombre: 'PATCHING TYPE', valor: 'tipoparchado' },
    { nombre: 'OBSERVATION', valor: 'observacion' },
    { nombre: 'TYPE DB', valor: 'tipobd' },

    { nombre: 'BACKUP TYPE', valor: 'tiporespaldo' },
    { nombre: 'BACKUP FREQUENCY TYPE', valor: 'tiporespaldofrecuencia' },

    { nombre: 'PORT TYPE', valor: 'tipopuerto' },
    { nombre: 'NETWORK NAME', valor: 'nombrered' },
    { nombre: 'ID VLAN', valor: 'idvlan' },
    { nombre: 'IP ADDRESS', valor: 'direccionip' },
    { nombre: 'NETWORK ADDRESS', valor: 'direccionred' },
    { nombre: 'MAC ADDRESS', valor: 'direccionmac' },
    { nombre: 'REMOTE ACCESS TYPE', valor: 'tipoaccesoremoto' },
    { nombre: 'REMOTE ACCESS APPLICATION', valor: 'accesoremotoaplicacion' },
    { nombre: 'PROTOCOL', valor: 'protocolo' },

    { nombre: 'MODIFICATION DATE', valor: 'fechaactualizacion' },
    { nombre: 'MODIFIED BY', valor: 'modificadopor' },
]

export const camposExcelBitacora = [
    {nombre:"ID SERVER", valor:"id_registro"},
    {nombre:"ID LOG", valor:"id_bitacora"},
    {nombre:"IOD MODIFICATION", valor:"id_modificacion"},
    {nombre:"DATE", valor:"fecha"},
    {nombre:"RESPONSABLE AREA", valor:"arearesponsable"},
    {nombre:"FIELD", valor:"campo"},
    {nombre:"AFTER VALUE", valor:"valor_anterior"},
    {nombre:"BEFORE VALUE", valor:"valor_actual"}    
]
