export const camposExcel = [
    {nombre:"ID", valor:"id_aplicacionpermitida"},
    {nombre:"NAME", valor:"nombre"},
    {nombre:"FAMILY", valor:"familia"},
    {nombre:"CONDITION", valor:"condicion"},
    {nombre:"TOTAL", valor:"total"}
]

export const campos = [ 
    "nombre",
    "familia",
    "condicion",
    "total"
]