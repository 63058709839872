
export const URL_Servicio = 'https://activos-api-servidores-desa.arauco.com/api/servidor/'
export const URL_Usuario = 'https://activos-api-usuarios-desa.arauco.com/api/login/'
export const URL_Aplicacion = 'https://activos-api-aplicaciones-desa.arauco.com/api/aplicacion/'
export const URL_Carga_Servidor = 'https://carga-api-servidores-desa.arauco.com/api/cargaservidor/'
export const URL_Carga_Aplicacion = 'https://carga-api-aplicaciones-desa.arauco.com/api/cargaaplicacion/'
export const URL_Maestros = 'https://maestros-api-desa.arauco.com/'
export const URL_Comunicaciones = 'https://activos-api-comunicaciones-desa.arauco.com/api/comunicacion/'
export const URL_OtrosDispositivos = 'https://activos-api-otrosdispositivos-desa.arauco.com/api/otrodispo/'
export const URL_EQUIPOS = 'https://activos-api-equipoesc-desa.arauco.com/api/equipo/'
export const URL_Sala = 'https://activos-api-salaservidor-desa.arauco.com/api/sala/'
export const URL_Sharepoint = 'https://sharepoint-api-desa.arauco.com/api/sharepoint/'
export const URL_CuentaServicio = 'https://itam-cuentaservicio-api-desa.arauco.com/api/cuentausuario/'
export const URL_Certificado = 'https://itam-certificado-api-desa.arauco.com/api/certificado/'
export const URL_Bitacora = 'https://bitacora-api-desa.arauco.com/api/bitacora/'
export const URL_AplicacionBack = 'https://aplicacionback-api-dsr.arauco.com/api/aplicacionesback/'

/*
export const URL_Servicio = 'https://activos-api-servidores-desa.arauco.com/api/servidor/'
export const URL_Usuario = 'https://activos-api-usuarios-desa.arauco.com/api/login/'
export const URL_Aplicacion = 'https://activos-api-aplicaciones-desa.arauco.com/api/aplicacion/'
export const URL_Carga_Servidor = 'https://carga-api-servidores-desa.arauco.com/api/cargaservidor/'
export const URL_Carga_Aplicacion = 'https://carga-api-aplicaciones-desa.arauco.com/api/cargaaplicacion/'
export const URL_Maestros = 'https://maestros-api-desa.arauco.com/';
export const URL_Comunicaciones = 'https://activos-api-comunicaciones-desa.arauco.com/api/comunicacion/';
export const URL_OtrosDispositivos = 'https://activos-api-otrosdispositivos-desa.arauco.com/api/otrodispo/';
export const URL_EQUIPOS = 'https://activos-api-equipoesc-desa.arauco.com/api/equipo/';
export const URL_Sala = 'https://activos-api-salaservidor-desa.arauco.com/api/sala/'
export const URL_Sharepoint = 'https://sharepoint-api-desa.arauco.com/api/sharepoint/'
export const URL_CuentaServicio = 'https://itam-cuentaservicio-api-desa.arauco.com/api/cuentausuario/'
export const URL_Certificado = 'https://itam-certificado-api-desa.arauco.com/api/certificado/'
export const URL_Bitacora = 'https://bitacora-api-desa.arauco.com/api/bitacora/'
export const URL_AplicacionBack = 'https://aplicacionback-api-dsr.arauco.com/api/aplicacionesback/'
*/
