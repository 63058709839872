import React from 'react'
import MaestroCompuestoCreacion from './MaestroCompuestoCreacion'
import MaestroCompuestoTabla from './MaestroCompuestoTabla'

const MaestroCompuesto = ({valores, maestro, token, rows, 
    aplicarFiltro, filtro, cambioFiltro, busquedaEliminacion,
     valorNombre, filtrarOpciones, cambioValor, camposExcel}) => {

    
    return (
        <>
            <div className="divizq-general">
                <MaestroCompuestoCreacion 
                maestro={maestro} 
                valores={valores} 
                token={token} 
                valorNombre={valorNombre} 
                filtrarOpciones={filtrarOpciones} cambioValor={cambioValor}/>
            </div>
            <div className="divder-general">
                <div className="container-general">                    
                   <MaestroCompuestoTabla
                        maestro={maestro} 
                        token={token} 
                        rows={rows} 
                        aplicarFiltro={aplicarFiltro} 
                        filtro={filtro} 
                        cambioFiltro={cambioFiltro}
                        busquedaEliminacion={busquedaEliminacion}
                        camposExcel={camposExcel}
                   />
                </div>               
            </div>
        </>
    )
}

export default MaestroCompuesto
