import * as Rutas from '../RutasServicios'

async function valores(modulo, idioma, token)
{ 
    const url = Rutas.URL_AplicacionBack + "valores";    
    //const url = "https://localhost:44329/api/comunicacion/valores";    
    
    const request= {
        "modulo": modulo,
        "idioma": idioma   
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

export async function obtenerValores(modulo, idioma, token) {
    try {
        var respuesta = await valores(modulo, idioma,token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function tabla(aplicacion, pais, negocio, estado, id_filtro, privilegio, user_pais, token)
{ 
    const url = Rutas.URL_AplicacionBack + "tabla";    
    //const url = "https://localhost:44357/api/aplicacionesback/tabla"; 

    const request= {
        "aplicacion": aplicacion,
        "pais": pais,        
        "negocio": negocio,        
        "estado": estado,
        "id": id_filtro,
        "privilegio": privilegio,
        "user_pais": user_pais     
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

export async function obtenerTabla(aplicacion, pais, negocio, estado, id_filtro, privilegio, user_pais, token) {
    try {
        var respuesta = await tabla(aplicacion, pais, negocio, estado, id_filtro, privilegio, user_pais, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function crear(data, token){
    const url = Rutas.URL_AplicacionBack + "crear";    
    //const url = "https://localhost:44357/api/aplicacionesback/crear";  

    const reg = { 
        "software": data.software.value.toString().toUpperCase(),
        "version": data.version.toString().toUpperCase(),        
        "estado": data.estado.value.toString().toUpperCase(),
        "pais": data.pais.value.toString().toUpperCase(),
        "negocio": data.negocio.value.toString().toUpperCase(),
        "tipolicencia": data.tipolicencia.value.toString().toUpperCase() 
    };

    reg.gerencia = (data.gerencia !== "" && data.gerencia !== null) ? data.gerencia.toString().toUpperCase() : null;
    reg.informara = (data.informara !== "" && data.informara !== null) ? data.informara.toString().toUpperCase() : null;
    reg.observacion = (data.observacion !== "" && data.observacion !== null) ? data.observacion.toString().toUpperCase() : null;
    reg.numerofactura = (data.numerofactura !== "" && data.numerofactura !== null) ? data.numerofactura.toString().toUpperCase() : null;
    reg.numerolicencia = (data.numerolicencia !== "" && data.numerolicencia !== null) ? data.numerolicencia.toString().toUpperCase() : null;
    reg.fechafactura = (data.fechafactura !== null && data.fechafactura !== "" && data.fechafactura !== undefined) ? new Date(data.fechafactura).toISOString().substring(0, 10) : null;
    reg.vencimientocontrato = (data.vencimientocontrato !== null && data.vencimientocontrato !== "" && data.vencimientocontrato !== undefined) ? new Date(data.vencimientocontrato).toISOString().substring(0, 10) : null;

    reg.modificadopor = data.modificadopor.toString().toUpperCase();
    reg.fechaupdate = (data.fechaupdate !== null && data.fechaupdate !== "") ? new Date(data.fechaupdate).toISOString().substring(0, 10) : null;
    
    var bRequest = JSON.stringify(reg);
 
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};
}

export async function crearAplicacionBack(data, token) {
    try {
        var respuesta = await crear(data, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function obtener(id, token){
    const url = Rutas.URL_AplicacionBack + "aplicacionback";    
    //const url = "https://localhost:44357/api/aplicacionesback/aplicacionback";  

    const reg = { 
        "id_aplicacionback": id
    };
   
    var bRequest = JSON.stringify(reg);
 
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};
}

export async function obtenerAplicacionBack(id, token) {
    try {
        var respuesta = await obtener(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}


async function actualizar(data, dataAnterior, id, token){
    const url = Rutas.URL_AplicacionBack + "actualizar";    
    //const url = "https://localhost:44357/api/aplicacionesback/actualizar";  

    const update = { 
        "id_aplicacionback": id.toString()
    };

    if(data.version !== undefined) update.version = (data.version !== dataAnterior.version) ? data.version.toString().toUpperCase() : "";
    if(data.informara !== undefined) update.informara = (data.informara !== dataAnterior.informara) ? data.informara.toString().toUpperCase() : "";
    if(data.negocio !== undefined) update.negocio = (data.negocio !== dataAnterior.negocio) ? data.negocio.value.toString().toUpperCase() : "";
    if(data.estado !== undefined) update.estado = (data.estado !== dataAnterior.estado) ? data.estado.value.toString().toUpperCase() : "";
    if(data.pais !== undefined) update.pais = (data.pais !== dataAnterior.pais) ? data.pais.value.toString().toUpperCase() : "";
    if(data.tipolicencia !== undefined) update.tipolicencia = (data.tipolicencia !== dataAnterior.tipolicencia) ? data.tipolicencia.value.toString().toUpperCase() : "";
    
    update.gerencia = (data.gerencia !== dataAnterior.gerencia) ? (data.gerencia !== "") ? data.gerencia.toString().toUpperCase() : "esnull" : null;
    update.observacion = (data.observacion !== dataAnterior.observacion) ? (data.observacion !== "") ? data.observacion.toString().toUpperCase() : "esnull" : null;
    update.numerocontrato = (data.numerocontrato !== dataAnterior.numerocontrato) ? (data.numerocontrato !== "") ? data.numerocontrato.toString().toUpperCase() : "esnull" : null;    
    update.numerolicencia = (data.numerolicencia !== dataAnterior.numerolicencia) ? (data.numerolicencia !== "") ? data.numerolicencia.toString().toUpperCase() : "esnull" : null;    

    update.fechafactura = (data.fechafactura !== dataAnterior.fechafactura) ? (data.fechafactura !== null) ? new Date(data.fechafactura).toISOString().substring(0, 10) : "esnull" : null;
    update.vencimientocontrato = (data.vencimientocontrato !== dataAnterior.vencimientocontrato) ? (data.vencimientocontrato !== null) ? new Date(data.vencimientocontrato).toISOString().substring(0, 10) : "esnull" : null;

    update.modificadopor = data.modificadopor;
    update.fechaupdate = new Date(data.fechaupdate).toISOString().substring(0, 10);

    var bRequest = JSON.stringify(update);
 
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};
}

export async function actualizarAplicacionBack(data, dataAnterior, id, token) {
    try {
        var respuesta = await actualizar(data, dataAnterior, id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function servidoresTablaModal(id, localidad, pais, negocio, servidor, token)
{ 
    const url = Rutas.URL_AplicacionBack + "tablamodalservidor";    
    //const url = "https://localhost:44367/api/aplicacion/tablamodal"; 

    const request= {
        "id_aplicacionback": id,
        "filtrolocalidad": localidad,
        "filtropais": pais,
        "filtronegocio": negocio,
        "filtroservidor": servidor              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function obtenerServidoresModal(id, localidad, pais, negocio, servidor, token) {
    try {
        var respuesta = await servidoresTablaModal(id, localidad, pais, negocio, servidor, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function crearRelacionServidor(id, servidores, token)
{ 
    const url = Rutas.URL_AplicacionBack + "crearrelacionservidor";    
    //const url = "https://localhost:44367/api/aplicacion/crearrelacionservidor"; 

    const request= {
        "id_aplicacionback": id,        
        "servidores": servidores            
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function crearRelacionesServidor(id, servidores, token) {
    try {
        var respuesta = await crearRelacionServidor(id, servidores, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function eliminarRelacionServidor(id, servidores, token)
{ 
    const url = Rutas.URL_AplicacionBack + "eliminarrelacionservidor";    
    //const url = "https://localhost:44367/api/aplicacion/eliminarrelacionservidor"; 

    const request= {
        "id_aplicacionback": id,
        "servidores": servidores            
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function eliminarRelacionesServidor(id, servidores, token) {
    try {
        var respuesta = await eliminarRelacionServidor(id, servidores, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function servidoresTabla(id, token)
{ 
    const url = Rutas.URL_AplicacionBack + "relacionservidor";    
    //const url = "https://localhost:44367/api/aplicacion/relacionservidor"; 

    const request= {
        "id_aplicacionback": id              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function obtenerServidores(id, token) {
    try {
        var respuesta = await servidoresTabla(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function equiposTablaModal(estado, condicion, pais, localidad, planta, numeroserie, id_aplicacion, token)
{ 
    const url = Rutas.URL_AplicacionBack + "tablamodalequipo";    
    //const url = "https://localhost:44367/api/aplicacion/tablamodalequipo";

    const request= {   
        "id_aplicacionback": id_aplicacion,     
        "estado": estado,
        "condicion": condicion,
        "pais": pais,
        "localidad": localidad,
        "planta": planta,
        "numeroserie": numeroserie      
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

export async function obtenerEquiposTablaModal(estado, condicion, pais, localidad, planta, numeroserie, id_aplicacion, token) {
    try {
        var respuesta = await equiposTablaModal(estado, condicion, pais, localidad, planta, numeroserie, id_aplicacion, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function crearRelacionEquipo(id, equipos, token)
{ 
    const url = Rutas.URL_AplicacionBack + "crearrelacionequipo";    
    //const url = "https://localhost:44367/api/aplicacion/crearrelacionequipo"; 

    const request= {
        "id_aplicacionback": id,
        "equipos": equipos            
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function crearRelacionesEquipo(id, equipos, token) {
    try {
        var respuesta = await crearRelacionEquipo(id, equipos, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function eliminarRelacionEquipo(id, equipos, token)
{ 
    const url = Rutas.URL_AplicacionBack + "eliminarrelacionequipo";    
    //const url = "https://localhost:44367/api/aplicacion/eliminarrelacionequipo"; 

    const request= {
        "id_aplicacionback": id,
        "equipos": equipos            
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function eliminarRelacionesEquipo(id, equipos, token) {
    try {
        var respuesta = await eliminarRelacionEquipo(id, equipos, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function equiposTabla(id, token)
{ 
    const url = Rutas.URL_AplicacionBack + "relacionequipo";    
    //const url = "https://localhost:44367/api/aplicacion/relacionequipo"; 

    const request= {
        "id_aplicacionback": id              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function obtenerEquipos(id, token) {
    try {
        var respuesta = await equiposTabla(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function eliminar(id, token)
{ 
    const url = Rutas.URL_AplicacionBack + "eliminar";    
    //const url = "https://localhost:44367/api/aplicacion/eliminar"; 
    
    const data = {        
        "id_aplicacionback": id
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function eliminarAplicacionBack(id, token) {
    try {
        var respuesta = await eliminar(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function subirArchivo(file64, nombre, usuario, token)
{ 
    const url = Rutas.URL_AplicacionBack + "subirexcel";      
    //const url = "https://localhost:44370/api/cuentausuario/subirexcel"; 
    

    const data = {
        "title": nombre,
        "file": file64,
        "desc": "archivo de carga excel",
        "usuario": usuario
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

export async function uploadExcel(file64, nombre, usuario, token) {
    try {
        var respuesta = await subirArchivo(file64, nombre, usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

async function cargas(usuario, token)
{ 
    const url = Rutas.URL_AplicacionBack + "cargas";    
    //const url = "https://localhost:44370/api/cuentausuario/cargas"; 

    const data = {        
        "usuario": usuario
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

export async function obtenerCargasExcel(usuario, token) {
    try {
        var respuesta = await cargas(usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

async function tablaBitacoras(aplicacion, pais, negocio, estado, id_filtro, privilegio, user_pais, token)
{ 
    const url = Rutas.URL_AplicacionBack + "bitacoras";    
    //const url = "https://localhost:44329/api/comunicacion/tabla"; 

    const request= {
        "aplicacion": aplicacion,
        "pais": pais,        
        "negocio": negocio,        
        "estado": estado,
        "id": id_filtro,
        "privilegio": privilegio,
        "user_pais": user_pais     
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

export async function obtenerTablaBitacoras(aplicacion, pais, negocio, estado, id_filtro, privilegio, user_pais, token) {
    try {
        var respuesta = await tablaBitacoras(aplicacion, pais, negocio, estado, id_filtro, privilegio, user_pais, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}