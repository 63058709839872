import * as Rutas from '../RutasServicios'

async function subirArchivoExcel(file64, nombre, usuario, token)
{ 
    const url = Rutas.URL_Usuario  + "subirexcel";      
    //const url = "https://localhost:44358/api/login/subirexcel";  

    const data = {
        "title": nombre,
        "file": file64,
        "desc": "archivo de carga excel",
        "usuario": usuario
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }  
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function obtenerCargasExcel(usuario, token)
{ 
    const url = Rutas.URL_Maestros + "api/excel/cargas";      
    //const url = "https://localhost:44358/api/login/cargas";    

    const data = {        
        "usuario": usuario,
        "activo": "usuario"
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function usuariosTabla(pais, localidad, rol, area, usuario, token)
{ 
    const url = Rutas.URL_Usuario + "usuarios";      
    //const url = "https://localhost:44358/api/login/usuarios";    

    const request= {
        "pais": pais,
        "localidad": localidad,
        "rol": rol,
        "area": area,
        "usuario": usuario
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

async function obtenerUsuarioForm(id, token)
{ 
    const url = Rutas.URL_Usuario + "formulario";      
    //const url = "https://localhost:44358/api/login/formulario";    

    const request= {
        "UsuarioId": id       
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

async function obtenerValores(token)
{ 
    const url = Rutas.URL_Usuario + "valores"; 
    //const url = "https://localhost:44329/api/comunicacion/filtros";    
    
    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function actualizar(data, dataAnterior, idpriv, username, token)
{
    const url = Rutas.URL_Usuario + "actualizar";      
    //const url = "https://localhost:44358/api/login/actualizar";    

    const request= {        
        "Username": username,      
        "Estado": (data.estado !== dataAnterior.estado) ? (data.estado) ? "1" : "0" : null,
        "Notificacion": (data.notificacion !== dataAnterior.notificacion) ? (data.notificacion) ? "1" : "0" : null
    };

    request.area = (data.area !== dataAnterior.area) ? data.area.toString().toUpperCase() : "";
    request.rol = (data.rol !== dataAnterior.rol) ? data.rol.toString().toUpperCase() : "";

    if(data.pais !== undefined) request.pais = (data.pais !== dataAnterior.pais) ? data.pais.value.toString().toUpperCase() : "";
    if(data.localidad !== undefined) request.localidad = (data.localidad !== dataAnterior.localidad) ? data.localidad.value.toString().toUpperCase() : "";
    if(data.planta !== undefined) request.planta = (data.planta !== dataAnterior.planta) ? (data.planta !== "" && data.planta !== null) ? data.planta.value.toString().toUpperCase() : "esnull" : "";
    if(data.idioma !== undefined) request.idioma = (data.idioma !== dataAnterior.idioma) ? data.idioma.value.toString().toUpperCase() : "";

    const privilegio = {
        "privilegio_id": idpriv
    }

    if(data.ser_leer !== undefined) privilegio.ser_leer = (data.ser_leer !== dataAnterior.ser_leer) ? data.ser_leer.value.toString().toUpperCase() : "";
    if(data.ser_escribir !== undefined) privilegio.ser_escribir = (data.ser_escribir !== dataAnterior.ser_escribir) ? data.ser_escribir.value.toString().toUpperCase() : "";
    if(data.ser_crear !== undefined) privilegio.ser_crear = (data.ser_crear !== dataAnterior.ser_crear) ? data.ser_crear.value.toString().toUpperCase() : "";
    if(data.ser_cargamasiva !== undefined) privilegio.ser_cargamasiva = (data.ser_cargamasiva !== dataAnterior.ser_cargamasiva) ? data.ser_cargamasiva.value.toString().toUpperCase() : "";
    if(data.ser_eliminar !== undefined) privilegio.ser_eliminar = (data.ser_eliminar !== dataAnterior.ser_eliminar) ? data.ser_eliminar.value.toString().toUpperCase() : "";

    if(data.ser_ot_leer !== undefined) privilegio.ser_ot_leer = (data.ser_ot_leer !== dataAnterior.ser_ot_leer) ? data.ser_ot_leer.value.toString().toUpperCase() : "";
    if(data.ser_ot_escribir !== undefined) privilegio.ser_ot_escribir = (data.ser_ot_escribir !== dataAnterior.ser_ot_escribir) ? data.ser_ot_escribir.value.toString().toUpperCase() : "";
    if(data.ser_ot_crear !== undefined) privilegio.ser_ot_crear = (data.ser_ot_crear !== dataAnterior.ser_ot_crear) ? data.ser_ot_crear.value.toString().toUpperCase() : "";
    if(data.ser_ot_cargamasiva !== undefined) privilegio.ser_ot_cargamasiva = (data.ser_ot_cargamasiva !== dataAnterior.ser_ot_cargamasiva) ? data.ser_ot_cargamasiva.value.toString().toUpperCase() : "";
    if(data.ser_ot_eliminar !== undefined) privilegio.ser_ot_eliminar = (data.ser_ot_eliminar !== dataAnterior.ser_ot_eliminar) ? data.ser_ot_eliminar.value.toString().toUpperCase() : "";
    
    if(data.esc_leer !== undefined) privilegio.esc_leer = (data.esc_leer !== dataAnterior.esc_leer) ? data.esc_leer.value.toString().toUpperCase() : "";
    if(data.esc_escribir !== undefined) privilegio.esc_escribir = (data.esc_escribir !== dataAnterior.esc_escribir) ? data.esc_escribir.value.toString().toUpperCase() : "";
    if(data.esc_crear !== undefined) privilegio.esc_crear = (data.esc_crear !== dataAnterior.esc_crear) ? data.esc_crear.value.toString().toUpperCase() : "";
    if(data.esc_cargamasiva !== undefined) privilegio.esc_cargamasiva = (data.esc_cargamasiva !== dataAnterior.esc_cargamasiva) ? data.esc_cargamasiva.value.toString().toUpperCase() : "";
    if(data.esc_eliminar !== undefined) privilegio.esc_eliminar = (data.esc_eliminar !== dataAnterior.esc_eliminar) ? data.esc_eliminar.value.toString().toUpperCase() : "";

    if(data.app_leer !== undefined) privilegio.app_leer = (data.app_leer !== dataAnterior.app_leer) ? data.app_leer.value.toString().toUpperCase() : "";
    if(data.app_escribir !== undefined) privilegio.app_escribir = (data.app_escribir !== dataAnterior.app_escribir) ? data.app_escribir.value.toString().toUpperCase() : "";
    if(data.app_crear !== undefined) privilegio.app_crear = (data.app_crear !== dataAnterior.app_crear) ? data.app_crear.value.toString().toUpperCase() : "";
    if(data.app_cargamasiva !== undefined) privilegio.app_cargamasiva = (data.app_cargamasiva !== dataAnterior.app_cargamasiva) ? data.app_cargamasiva.value.toString().toUpperCase() : "";
    if(data.app_eliminar !== undefined) privilegio.app_eliminar = (data.app_eliminar !== dataAnterior.app_eliminar) ? data.app_eliminar.value.toString().toUpperCase() : "";
    if(data.app_maestro !== undefined) privilegio.app_maestro = (data.app_maestro !== dataAnterior.app_maestro) ? data.app_maestro.value.toString().toUpperCase() : "";

    if(data.com_leer !== undefined) privilegio.com_leer = (data.com_leer !== dataAnterior.com_leer) ? data.com_leer.value.toString().toUpperCase() : "";
    if(data.com_escribir !== undefined) privilegio.com_escribir = (data.com_escribir !== dataAnterior.com_escribir) ? data.com_escribir.value.toString().toUpperCase() : "";
    if(data.com_crear !== undefined) privilegio.com_crear = (data.com_crear !== dataAnterior.com_crear) ? data.com_crear.value.toString().toUpperCase() : "";
    if(data.com_cargamasiva !== undefined) privilegio.com_cargamasiva = (data.com_cargamasiva !== dataAnterior.com_cargamasiva) ? data.com_cargamasiva.value.toString().toUpperCase() : "";
    if(data.com_eliminar !== undefined) privilegio.com_eliminar = (data.com_eliminar !== dataAnterior.com_eliminar) ? data.com_eliminar.value.toString().toUpperCase() : "";

    if(data.dis_leer !== undefined) privilegio.dis_leer = (data.dis_leer !== dataAnterior.dis_leer) ? data.dis_leer.value.toString().toUpperCase() : "";
    if(data.dis_escribir !== undefined) privilegio.dis_escribir = (data.dis_escribir !== dataAnterior.dis_escribir) ? data.dis_escribir.value.toString().toUpperCase() : "";
    if(data.dis_crear !== undefined) privilegio.dis_crear = (data.dis_crear !== dataAnterior.dis_crear) ? data.dis_crear.value.toString().toUpperCase() : "";
    if(data.dis_cargamasiva !== undefined) privilegio.dis_cargamasiva = (data.dis_cargamasiva !== dataAnterior.dis_cargamasiva) ? data.dis_cargamasiva.value.toString().toUpperCase() : "";
    if(data.dis_eliminar !== undefined) privilegio.dis_eliminar = (data.dis_eliminar !== dataAnterior.dis_eliminar) ? data.dis_eliminar.value.toString().toUpperCase() : "";

    if(data.sala_leer !== undefined) privilegio.sala_leer = (data.sala_leer !== dataAnterior.sala_leer) ? data.sala_leer.value.toString().toUpperCase() : "";
    if(data.sala_escribir !== undefined) privilegio.sala_escribir = (data.sala_escribir !== dataAnterior.sala_escribir) ? data.sala_escribir.value.toString().toUpperCase() : "";
    if(data.sala_crear !== undefined) privilegio.sala_crear = (data.sala_crear !== dataAnterior.sala_crear) ? data.sala_crear.value.toString().toUpperCase() : "";
    if(data.sala_cargamasiva !== undefined) privilegio.sala_cargamasiva = (data.sala_cargamasiva !== dataAnterior.sala_cargamasiva) ? data.sala_cargamasiva.value.toString().toUpperCase() : "";
    if(data.sala_eliminar !== undefined) privilegio.sala_eliminar = (data.sala_eliminar !== dataAnterior.sala_eliminar) ? data.sala_eliminar.value.toString().toUpperCase() : "";

    if(data.cuentaser_leer !== undefined) privilegio.cuentaser_leer = (data.cuentaser_leer !== dataAnterior.cuentaser_leer) ? data.cuentaser_leer.value.toString().toUpperCase() : "";
    if(data.cuentaser_escribir !== undefined) privilegio.cuentaser_escribir = (data.cuentaser_escribir !== dataAnterior.cuentaser_escribir) ? data.cuentaser_escribir.value.toString().toUpperCase() : "";
    if(data.cuentaser_crear !== undefined) privilegio.cuentaser_crear = (data.cuentaser_crear !== dataAnterior.cuentaser_crear) ? data.cuentaser_crear.value.toString().toUpperCase() : "";
    if(data.cuentaser_cargamasiva !== undefined) privilegio.cuentaser_cargamasiva = (data.cuentaser_cargamasiva !== dataAnterior.cuentaser_cargamasiva) ? data.cuentaser_cargamasiva.value.toString().toUpperCase() : "";
    if(data.cuentaser_eliminar !== undefined) privilegio.cuentaser_eliminar = (data.cuentaser_eliminar !== dataAnterior.cuentaser_eliminar) ? data.cuentaser_eliminar.value.toString().toUpperCase() : "";

    if(data.cert_leer !== undefined) privilegio.cert_leer = (data.cert_leer !== dataAnterior.cert_leer) ? data.cert_leer.value.toString().toUpperCase() : "";
    if(data.cert_escribir !== undefined) privilegio.cert_escribir = (data.cert_escribir !== dataAnterior.cert_escribir) ? data.cert_escribir.value.toString().toUpperCase() : "";
    if(data.cert_crear !== undefined) privilegio.cert_crear = (data.cert_crear !== dataAnterior.cert_crear) ? data.cert_crear.value.toString().toUpperCase() : "";
    if(data.cert_cargamasiva !== undefined) privilegio.cert_cargamasiva = (data.cert_cargamasiva !== dataAnterior.cert_cargamasiva) ? data.cert_cargamasiva.value.toString().toUpperCase() : "";
    if(data.cert_eliminar !== undefined) privilegio.cert_eliminar = (data.cert_eliminar !== dataAnterior.cert_eliminar) ? data.cert_eliminar.value.toString().toUpperCase() : "";

    if(data.appback_leer !== undefined) privilegio.appback_leer = (data.appback_leer !== dataAnterior.appback_leer) ? data.appback_leer.value.toString().toUpperCase() : "";
    if(data.appback_escribir !== undefined) privilegio.appback_escribir = (data.appback_escribir !== dataAnterior.appback_escribir) ? data.appback_escribir.value.toString().toUpperCase() : "";
    if(data.appback_crear !== undefined) privilegio.appback_crear = (data.appback_crear !== dataAnterior.appback_crear) ? data.appback_crear.value.toString().toUpperCase() : "";
    if(data.appback_cargamasiva !== undefined) privilegio.appback_cargamasiva = (data.appback_cargamasiva !== dataAnterior.appback_cargamasiva) ? data.appback_cargamasiva.value.toString().toUpperCase() : "";
    if(data.appback_eliminar !== undefined) privilegio.appback_eliminar = (data.appback_eliminar !== dataAnterior.appback_eliminar) ? data.appback_eliminar.value.toString().toUpperCase() : "";

    if(data.mas_leer !== undefined) privilegio.mas_leer = (data.mas_leer !== dataAnterior.mas_leer) ? data.mas_leer.value.toString().toUpperCase() : "";
    if(data.mas_escribir !== undefined) privilegio.mas_escribir = (data.mas_escribir !== dataAnterior.mas_escribir) ? data.mas_escribir.value.toString().toUpperCase() : "";
    if(data.admin_otrasfuentes !== undefined) privilegio.admin_otrasfuentes = (data.admin_otrasfuentes !== dataAnterior.admin_otrasfuentes) ? data.admin_otrasfuentes.value.toString().toUpperCase() : "";
    if(data.admin_personal !== undefined) privilegio.admin_personal = (data.admin_personal !== dataAnterior.admin_personal) ? data.admin_personal.value.toString().toUpperCase() : "";
    if(data.admin_usuarios !== undefined) privilegio.admin_usuarios = (data.admin_usuarios !== dataAnterior.admin_usuarios) ? data.admin_usuarios.value.toString().toUpperCase() : "";

    request.privilegios = privilegio;

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

export async function actualizarForm(data, dataAnterior, idpriv, username, token) {
    try {
        var respuesta = await actualizar(data, dataAnterior, idpriv, username, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function obtenerValoresUser(token) {
    try {
        var respuesta = await obtenerValores(token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function obtenerUserForm(id, token) {
    try {
        var respuesta = await obtenerUsuarioForm(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function obtenerTabla(pais, localidad, rol, area, usuario, token) {
    try {
        var respuesta = await usuariosTabla(pais, localidad, rol, area, usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function cargasExcel(usuario, token) {
    try {
        var respuesta = await obtenerCargasExcel(usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}

export async function uploadExcel(file64, nombre, usuario, token) {
    try {
        var respuesta = await subirArchivoExcel(file64, nombre, usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}