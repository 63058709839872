import React from 'react'

import styled from 'styled-components';

import FiltroBaseNuevo from '../FiltroBaseNuevo';
import * as Valores from './ValoresFiltro'
import * as TiIcons from 'react-icons/ti';

import { ContainerFiltros, FilaContainerFiltros, Button, ButtonVolver } from '../Commons/Styles'

const FiltrosTablaBitacora = ({aplicarFiltro, load, cerrarModal, token}) => {
  
    const [campo, setCampo] = React.useState("");
    const [year, setYear] = React.useState("");
    const [month, setMonth] = React.useState("");
    const [day, setDay] = React.useState("");

    async function obtenerRegistros()
    {
        var campoApi = (campo !== null && campo !== undefined && campo !== "") ? campo : "";
        var yearApi = (year !== null && year !== undefined && year !== "") ? year : "";
        var monthApi = (month !== null && month !== undefined && month !== "") ? month.value : "";
        var dayApi = (day !== null && day !== undefined && day !== "") ? day : "";

        load(true);
        await aplicarFiltro(yearApi, monthApi, dayApi, campoApi)
        load(false);
    }
  
    const cambioMonth= (valor) => {
        setMonth(valor);
    };
  
  
    return (
        <>
            <ButtonVolver onClick={cerrarModal} className="tooltipboton">
                            <TiIcons.TiArrowBack/>             
                            <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>
            <FilaContainerFiltros>
                <ContainerFiltros>
                    <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>
                        <input className="bootstrap-input-filtro" value={year} type='text' onChange={(e) => setYear(e.target.value)} placeholder={"Year"}  />
                    </div>

                    <FiltroBaseNuevo 
                        opciones={Valores.months}
                        valor={month}
                        nombre={"Month"}
                        namecampo="month"
                        cambio={cambioMonth}
                        proporcion={"20%"}
                        error={false}
                    />                    
                    <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>
                        <input className="bootstrap-input-filtro" value={day} type='text' onChange={(e) => setDay(e.target.value)} placeholder={"Day"}  />
                    </div>
                    <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>
                        <input className="bootstrap-input-filtro" value={campo} type='text' onChange={(e) => setCampo(e.target.value)} placeholder={"Field"}  />
                    </div>
                    <div style={{width: "19%", marginLeft: '10px', marginRight: 'auto',}}>
                        <Button onClick={obtenerRegistros}>Display</Button>
                    </div>
                </ContainerFiltros>
            </FilaContainerFiltros>
        </>
    )
}

export default FiltrosTablaBitacora