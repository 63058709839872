import React, { useState, useEffect } from 'react'
import { eliminarAplicacionPermitida, obtenerTabla, obtenerValores, obtenerTablaTotales } from '../../functions/FuncAplicacionPermitida';
import  * as Metodos from '../../functions/MetodosComunes'
import '../../css/aplicacionpermitida.css'
import swal from 'sweetalert';
import TablaAplicacionPermitida from './TablaAplicacionPermitida';
import FormAplicacionPermitida from './FormAplicacionPermitida';
import Loading from '../../Loading';

const ConjuntoAppPermitidas = ({token}) => {

    const [registros, setRegistros] = useState(null);
    const [total, setTotal] = useState(null);
    const [registro, setRegistro] = useState(null);
    const [loadTabla, setLoadTabla] = useState(false);
    const [formCreate, setFormCreate] = useState(false);
    const [valores, setValores] = useState(null);

    const obtenerRegistros = async () => {

        setLoadTabla(true);        

        await obtenerValores().then((response) => {
            if(response.cod === 1)
            {
                const val = {          
                    "condiciones": response.condiciones,                    
                    "familias": response.familias
                };

                setValores({
                    "condiciones": Metodos.opcionesFiltros(val.condiciones),
                    "familias": Metodos.opcionesFiltros(val.familias)
                })
            }
            else swal ("Search Error" , response.mensaje , "warning");
        });

        await obtenerTabla().then((response) => {
            if(response.cod === 1)
            {
                setRegistros(response.aplicacionespermitidas);
            }
            else swal("Search Error" , response.mensaje , "warning");

            setLoadTabla(false);
        });
    }

    const obtenerRegistrosTotales = async () => {

        setLoadTabla(true);

        await obtenerTablaTotales().then((response) => {
            if(response.cod === 1)
            {
                setRegistros(response.aplicacionespermitidas);
            }
            else swal("Search Error" , response.mensaje , "warning");

            setLoadTabla(false);
        });
    }

    const select = (data) => {
        setRegistro(data);
    }

    const create = () => {
        setRegistro({id_aplicacionpermitida: 0});
    }

    const eliminar = async (id) => {
        swal({
            title: "Delete confirmation",
            text: "Do you want to delete the registry?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (afirmacion) => {
            if (afirmacion) {
                await eliminarAplicacionPermitida(id, token).then((response) => {
                    if(response.cod === 1) {
                        actualizarTabla();
                        swal("Successful create", "", "success");
                    }
                    else swal("Delete Error" , response.mensaje , "warning");
                });
            }
        });
    }

    const actualizarTabla = async () => {

        setLoadTabla(true);

        await obtenerTabla().then((response) => {
            if(response.cod === 1)
            {
                setRegistros(response.aplicacionespermitidas);
            }
            else swal("Search Error" , response.mensaje , "warning");

            setLoadTabla(false);
        });
    }

    useEffect(() => {
        obtenerRegistros();
    }, [])


  return (
    <>
        <div className="headerformulario">                        
            
                <div className="divtituloform" style={{"textAlign": "center"}}>
                    <label className="tituloformulario">Application Control</label>
                </div>                        
           
        </div>
        <div className='divizqappper'>
            {
                (loadTabla) ? (<Loading />)
                : (
                    (registros === null) ? (<></>)
                    : (
                        <div className='containerappper'>                
                            <TablaAplicacionPermitida rows={registros} seleccionar={select} crear={create} eliminar={eliminar} obtenerRegistrosTotales={obtenerRegistrosTotales}/>
                        </div> 
                    )
                )
            }                       
        </div>

        <div className='divderappper'>
            {
                (registro === null) ? (<></>)
                : (                        
                    <FormAplicacionPermitida registro={registro} valores={valores} id={registro.id_aplicacionpermitida} actualizarTabla={actualizarTabla} token={token}/>                          
                )
            }
        </div>
    
    
    </>
  )
}

export default ConjuntoAppPermitidas