import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  obtenerValores,
  crearAplicacionBack,
} from "../functions/FuncAplicacionBack";
import "../css/formularioAplicacion.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import Loading from "../Loading";
import Select from "react-select";
import { validarEmail } from "../functions/MetodosComunes";

import { style, styleNormal } from '../Commons/Styles'

const CreacionAplicacionBack = ({ token }) => {
  const history = useHistory();
  const [registro, setRegistro] = useState(null);
  const [valores, setValores] = useState(null);
  const { handleSubmit, control, register, setValue } = useForm({
    mode: "onBlur",
  });

  let privilegio = sessionStorage.getItem("appback_crear");
  let paisUser = sessionStorage.getItem("pais");
  let idiomaUser = sessionStorage.getItem("idioma");

  const [errorSoftware, setErrorSoftware] = useState(false);
  const [errorVersion, setErrorVersion] = useState(false);
  const [errorEstado, setErrorEstado] = useState(false);
  const [errorNegocio, setErrorNegocio] = useState(false);
  const [errorPais, setErrorPais] = useState(false);
  const [errorGerencia, setErrorGerencia] = useState(false);
  const [errorInformara, setErrorInformara] = useState(false);
  const [errorTipoLicencia, setErrorTipoLicencia] = useState(false);

  async function obtenerDatosBD() {
    await obtenerValores("APPLICATION BO", idiomaUser, token).then(
      (response) => {
        if (response.cod === 1) {
          const val = {
            negocios: response.negocios,
            paises: response.paises,
            tiposlicencia: response.tiposlicencia,
            estados: response.estados,
            maestroapp: response.maestroapp,
            traducciones: response.traducciones,
          };

          let array = [];
          const listas = [
            "negocios",
            "paises",
            "tiposlicencia",
            "estados",
            "maestroapp",
          ];

          listas.forEach((lista) => {
            array = [];
            val[lista].map((u) => {
              array.push({ label: u, value: u });
            });
            val[lista] = array;
          });

          if (privilegio === "2") {
            array = [];
            val["paises"].map((u) => {
              if (u.value === paisUser)
                array.push({ label: u.value, value: u.value });
            });
            val["paises"] = array;
          }

          setValores(val);
        }
      }
    );
  }

  const onSubmit = (data) => {
    let errorMessage = validarDatos(data);;

    if(errorMessage.length > 0)
    {
      swal("Form data", errorMessage, "warning");     
    } else {
      swal({
        title: "Creation confirmation",
        text: "Do you want to create the record?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (afirmacion) => {
        if (afirmacion) {
          const datosform = data;
          let nombreusuario = sessionStorage.getItem("nombre");
          datosform.modificadopor = nombreusuario;
          datosform.fechaupdate = new Date();

          await crearAplicacionBack(datosform, token).then((response) => {
            if (response.cod === 1) {
              swal("Successful creation", "", "success").then(() => {
                history.push("/aplicacionesback/" + response.id);
              });
            } else swal("Creation Error", response.mensaje, "error");
          });
        }
      });
    }
  };

  function validarDatos(data) {
    let errors = {
        "error_type_01": "Mandatory data must be entered",
        "error_type_02": "Invalid email",
    }
    let error = "";

    if (data.version === null || data.version === "") {
      setErrorVersion(true);
      error = errors["error_type_01"];
    } else setErrorVersion(false);

    if (data.informara === null || data.informara === "") {
      setErrorInformara(true);
      error = errors["error_type_01"];
    } else setErrorInformara(false);

    if (
      data.software === null ||
      data.software === "" ||
      data.software === undefined
    ) {
      setErrorSoftware(true);
      error = errors["error_type_01"];
    } else setErrorSoftware(false);

    if (
      data.estado === null ||
      data.estado === "" ||
      data.estado === undefined
    ) {
      setErrorEstado(true);
      error = errors["error_type_01"];
    } else setErrorEstado(false);

    if (
      data.negocio === null ||
      data.negocio === "" ||
      data.negocio === undefined
    ) {
      setErrorNegocio(true);
      error = errors["error_type_01"];
    } else setErrorNegocio(false);

    if (data.pais === null || data.pais === "" || data.pais === undefined) {
      setErrorPais(true);
      error = errors["error_type_01"];
    } else setErrorPais(false);

    if (
      data.tipolicencia === null ||
      data.tipolicencia === "" ||
      data.tipolicencia === undefined
    ) {
      setErrorTipoLicencia(true);
      error = errors["error_type_01"];
    } else setErrorTipoLicencia(false);

    if(data.informara.length > 0)
    {
        if(!validarEmail(data.informara))
        {
            setErrorInformara(true);
            error = errors["error_type_02"];
        }
        else setErrorInformara(false);
    }

    return error;
  }

  React.useEffect(() => {
    if (valores !== null) {
    } else obtenerDatosBD();
  }, []);

  if (valores === null) return <Loading />;
  else
    return (
      <div className="divizq">
        <form
          style={{ width: "100%", marginRight: "auto", marginLeft: "auto" }}
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="container">
            <div className="row">
              <div className="col-25">
                <label style={{ fontWeight: "bold" }} htmlFor="i-nombre">
                  Application<label style={{ color: "red" }}>{"*"}</label>
                  {valores.traducciones.software === "" ||
                  valores.traducciones.software === null ? (
                    <></>
                  ) : (
                    <div class="tooltip">
                      &nbsp;&#x1F6C8;
                      <span class="tooltiptext">
                        {valores.traducciones.software}
                      </span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-75">
                <Controller
                  as={<Select />}
                  name="software"
                  options={valores.maestroapp}
                  isClearable={true}
                  placeholder=""
                  defaultValue={registro ? registro.software : ""}
                  control={control}
                  styles={errorSoftware ? style : styleNormal}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label style={{ fontWeight: "bold" }} htmlFor="i-version">
                  Version<label style={{ color: "red" }}>{"*"}</label>
                  {valores.traducciones.version === "" ||
                  valores.traducciones.version === null ? (
                    <></>
                  ) : (
                    <div class="tooltip">
                      &nbsp;&#x1F6C8;
                      <span class="tooltiptext">
                        {valores.traducciones.version}
                      </span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-75">
                <Controller
                  as={<input className="bootstrap-input" type="text" />}
                  control={control}
                  id="i-version"
                  key="k-version"
                  defaultValue={registro ? registro.version : ""}
                  name="version"
                  style={errorVersion ? { borderColor: "#f90000" } : {}}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label style={{ fontWeight: "bold" }} htmlFor="i-estado">
                  State<label style={{ color: "red" }}>{"*"}</label>
                  {valores.traducciones.estado === "" ||
                  valores.traducciones.estado === null ? (
                    <></>
                  ) : (
                    <div class="tooltip">
                      &nbsp;&#x1F6C8;
                      <span class="tooltiptext">
                        {valores.traducciones.estado}
                      </span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-75">
                <Controller
                  render={(props) => (
                    <Select
                      options={valores.estados}
                      onChange={(value) => {
                        props.onChange(value);
                      }}
                      placeholder=""
                      isClearable={true}
                      styles={errorEstado ? style : styleNormal}
                      control={control}
                    />
                  )}
                  control={control}
                  id="i-estado"
                  key="k-estado"
                  name="estado"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label style={{ fontWeight: "bold" }} htmlFor="i-pais">
                  Country<label style={{ color: "red" }}>{"*"}</label>
                  {valores.traducciones.pais === "" ||
                  valores.traducciones.pais === null ? (
                    <></>
                  ) : (
                    <div class="tooltip">
                      &nbsp;&#x1F6C8;
                      <span class="tooltiptext">
                        {valores.traducciones.pais}
                      </span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-75">
                <Controller
                  render={(props) => (
                    <Select
                      options={valores.paises}
                      onChange={(value) => {
                        props.onChange(value);
                      }}
                      placeholder=""
                      isClearable={true}
                      styles={errorPais ? style : styleNormal}
                      control={control}
                    />
                  )}
                  control={control}
                  id="i-pais"
                  key="k-pais"
                  name="pais"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label style={{ fontWeight: "bold" }} htmlFor="i-negocio">
                  Business<label style={{ color: "red" }}>{"*"}</label>
                  {valores.traducciones.negocio === "" ||
                  valores.traducciones.negocio === null ? (
                    <></>
                  ) : (
                    <div class="tooltip">
                      &nbsp;&#x1F6C8;
                      <span class="tooltiptext">
                        {valores.traducciones.negocio}
                      </span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-75">
                <Controller
                  render={(props) => (
                    <Select
                      options={valores.negocios}
                      onChange={(value) => {
                        props.onChange(value);
                      }}
                      placeholder=""
                      isClearable={true}
                      styles={errorNegocio ? style : styleNormal}
                      control={control}
                    />
                  )}
                  control={control}
                  id="i-negocio"
                  key="k-negocio"
                  name="negocio"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label htmlFor="i-gerencia">
                  Owner
                  {valores.traducciones.gerencia === "" ||
                  valores.traducciones.gerencia === null ? (
                    <></>
                  ) : (
                    <div class="tooltip">
                      &nbsp;&#x1F6C8;
                      <span class="tooltiptext">
                        {valores.traducciones.gerencia}
                      </span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-75">
                <Controller
                  as={<input className="bootstrap-input" type="text" />}
                  control={control}
                  id="i-gerencia"
                  key="k-gerencia"
                  defaultValue={registro ? registro.gerencia : ""}
                  style={errorGerencia ? { borderColor: "#f90000" } : {}}
                  name="gerencia"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label style={{ fontWeight: "bold" }} htmlFor="i-tipolicencia">
                  Type of license<label style={{ color: "red" }}>{"*"}</label>
                  {valores.traducciones.tipolicencia === "" ||
                  valores.traducciones.tipolicencia === null ? (
                    <></>
                  ) : (
                    <div class="tooltip">
                      &nbsp;&#x1F6C8;
                      <span class="tooltiptext">
                        {valores.traducciones.tipolicencia}
                      </span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-75">
                <Controller
                  render={(props) => (
                    <Select
                      options={valores.tiposlicencia}
                      onChange={(value) => {
                        props.onChange(value);
                      }}
                      placeholder=""
                      isClearable={true}
                      styles={errorTipoLicencia ? style : styleNormal}
                      control={control}
                    />
                  )}
                  control={control}
                  id="i-tipolicencia"
                  key="k-tipolicencia"
                  name="tipolicencia"
                />
              </div>
            </div>            
            <div className="row">
              <div className="col-25">
                <label htmlFor="i-numerofactura">
                  Purchase invoice number
                  {valores.traducciones.numerofactura === "" ||
                  valores.traducciones.numerofactura === null ? (
                    <></>
                  ) : (
                    <div class="tooltip">
                      &nbsp;&#x1F6C8;
                      <span class="tooltiptext">
                        {valores.traducciones.numerofactura}
                      </span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-75">
                <Controller
                  as={<input className="bootstrap-input" type="text" />}
                  control={control}
                  id="i-numerofactura"
                  key="k-numerofactura"
                  defaultValue={registro ? registro.numerofactura : ""}
                  name="numerofactura"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label htmlFor="i-fechafactura">
                  Invoice date (YYYY/MM/DD)
                  {valores.traducciones.fechafactura === "" ||
                  valores.traducciones.fechafactura === null ? (
                    <></>
                  ) : (
                    <div class="tooltip">
                      &nbsp;&#x1F6C8;
                      <span class="tooltiptext">
                        {valores.traducciones.fechafactura}
                      </span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-75">
                <Controller
                  name="fechafactura"
                  id="i-fechafactura"
                  className="bootstrap-input"
                  control={control}
                  render={({ onChange, value }) => (
                    <DatePicker
                      disabled={privilegio < 2 ? "disabled" : ""}
                      selected={value}
                      dateFormat="yyyy/MM/dd"
                      autoComplete="off"
                      onChange={onChange}
                      className="bootstrap-input"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label htmlFor="i-numerolicencia">
                  Number of Licenses
                  {valores.traducciones.numerolicencia === "" ||
                  valores.traducciones.numerolicencia === null ? (
                    <></>
                  ) : (
                    <div class="tooltip">
                      &nbsp;&#x1F6C8;
                      <span class="tooltiptext">
                        {valores.traducciones.numerolicencia}
                      </span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-75">
                <Controller
                  as={
                    <input
                      disabled={privilegio < 2 ? "disabled" : ""}
                      className="bootstrap-input"
                      type="number"
                    />
                  }
                  control={control}
                  id="i-numerolicencia"
                  key="k-numerolicencia"
                  defaultValue={registro ? registro.numerolicencia : ""}
                  name="numerolicencia"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label htmlFor="i-vencimientocontrato">
                  Expiration date (YYYY/MM/DD)
                  {valores.traducciones.vencimientocontrato === "" ||
                  valores.traducciones.vencimientocontrato === null ? (
                    <></>
                  ) : (
                    <div class="tooltip">
                      &nbsp;&#x1F6C8;
                      <span class="tooltiptext">
                        {valores.traducciones.vencimientocontrato}
                      </span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-75">
                <Controller
                  name="vencimientocontrato"
                  id="i-vencimientocontrato"
                  className="bootstrap-input"
                  control={control}
                  render={({ onChange, value }) => (
                    <DatePicker
                      disabled={privilegio < 2 ? "disabled" : ""}
                      selected={value}
                      dateFormat="yyyy/MM/dd"
                      autoComplete="off"
                      onChange={onChange}
                      className="bootstrap-input"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">                
                <label style={{ fontWeight: "bold" }} htmlFor="i-informara">
                  Inform To<label style={{ color: "red" }}>{"*"}</label>
                  {valores.traducciones.informara === "" ||
                  valores.traducciones.informara === null ? (
                    <></>
                  ) : (
                    <div class="tooltip">
                      &nbsp;&#x1F6C8;
                      <span class="tooltiptext">
                        {valores.traducciones.informara}
                      </span>
                    </div>
                  )}
                </label>
              </div>
              <div className="col-75">
                <Controller
                  as={<input className="bootstrap-input" type="text" />}
                  control={control}
                  id="i-informara"
                  key="k-informara"
                  defaultValue={registro ? registro.informara : ""}
                  style={errorInformara ? { borderColor: "#f90000" } : {}}
                  name="informara"
                />
              </div>
            </div>

            <div className="row">
                <div className="col-25">
                    <label htmlFor="i-observacion">Observation</label>
                </div>
                <div className="col-75">
                    <Controller
                        as={<textarea className="bootstrap-input" rows="3" cols="50"></textarea>}
                        control={control}
                        id="i-observacion"
                        key="k-observacion"
                        defaultValue={registro ? registro.observacion : ''}
                        name='observacion'
                    />                             
                </div>
            </div> 

          </div>
          <div
            style={{
              width: "100%",
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: "10px",
              float: "left",
            }}
          >
            <div
              style={{
                width: "20%",
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: "10px",
              }}
            >
              <button className="boton-from">Save</button>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </form>
      </div>
    );
};

export default CreacionAplicacionBack;
