import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grow from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
}));


const SeccionesEE = ({valores, pais}) => {

    const classes = useStyles();
    const [resumen, setResumen] = useState(null);



    React.useEffect(() => {
        valores.forEach((v) => {
            if(v.pais === pais){
                setResumen(v)
            }
        })
    })

    if(resumen === null) return (<></>)
    else return (

        <>
            <div className="divizq-secreporte">
                <div className="divseccion">
                    <label className="reporte-titulosec">Locations</label>
                </div>

                <div className="divseccion-total">
                    <label className="reporte-titulosec-total">{resumen.cantidadtotallocalidad}</label>
                </div>
                <div className="divseccion">
                    <List className={classes.root}>
                        {
                            resumen.localidades.map((l) => ( <>
                                <ListItem>                    
                                    <ListItemText primary={l.seccion} secondary={l.total} />
                                    
                                </ListItem>
                                <Divider component="li"/>
                                </>
                            ))
                        }
                    </List>
                </div>                
            </div>

            <div className="divmid-secreporte">                
                <div className="divseccion">
                    <label className="reporte-titulosec">Business</label>
                </div>

                <div className="divseccion-total">
                    <label className="reporte-titulosec-total">{resumen.cantidadtotalnegocio}</label>
                </div>
                <div className="divseccion">
                    <List className={classes.root}>
                        {
                            resumen.negocios.map((l) => (
                                <>
                                <ListItem>                    
                                    <ListItemText primary={l.seccion} secondary={l.total} />
                                </ListItem>
                                <Divider component="li"/>
                                </>
                            ))
                        }
                    </List>
                </div>          
            </div>
            <div className="divder-secreporte">                
                <div className="divseccion">
                    <label className="reporte-titulosec">Desktop Types</label>
                </div>

                <div className="divseccion-total">
                    <label className="reporte-titulosec-total">{resumen.cantidadtotaltipos}</label>
                </div>
                <div className="divseccion">
                    <List className={classes.root}>
                        {
                            resumen.tipos.map((l) => (
                                <>
                                <ListItem>                    
                                    <ListItemText primary={l.seccion} secondary={l.total} />
                                </ListItem>
                                <Divider component="li"/>
                                </>
                            ))
                        }
                    </List>
                </div>    
            </div>
        </>
    )
}

export default SeccionesEE
