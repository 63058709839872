import React from 'react'

import Sidebar from '../Sidebar'
import * as TiIcons from 'react-icons/ti';
import { useHistory } from "react-router-dom";
import CreacionEquipoEscritorio from './CreacionEquipoEscritorio';
import swal from 'sweetalert';

import { ContainerForm, ButtonVolver } from '../Commons/Styles'


const FormCreacionEquiposEscritorio = ({instance, t}) => {

    let priv = sessionStorage.getItem('esc_crear');
    const history = useHistory();

    function volver()
    {
        history.push("/equipos");
    }

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });
    
    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <>        
            <Sidebar instance={instance} despliegue={false}/>
            <ContainerForm>
                <div className="headerformulario">
                    <div className="headerform-izq">
                        <ButtonVolver onClick={volver} className="tooltipboton">
                        <TiIcons.TiArrowBack/>             
                        <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>
                    </div>
                    <div className="headerform-mid">
                        <div className="divtituloform">
                            <label className="tituloformulario">Desktop Creation Form</label>
                        </div>                        
                    </div>
                </div>     
                <CreacionEquipoEscritorio token={t}/>          
            </ContainerForm> 
            <br></br>           
            <br></br>           
        </>    
    )
}

export default FormCreacionEquiposEscritorio
