import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import '../css/aplicaciones.css'
import swal from 'sweetalert';

import ExportarExcel from '../ExportarExcel';
import * as Opciones from './OpcionesAplicacion';
import FiltroAplicacionSccm from './FiltroAplicacionSccm';
import Loading from '../Loading';


import { TablaRegistros } from '../Commons/TablaRegistros';

import { ContainerBusqueda, ContainerTable } from '../Commons/Styles'

const headCells = [
    { id: 'nombre', numeric: false, disablePadding: true, label: 'Server', size: '10%' },    
    { id: 'pais', numeric: false, disablePadding: true, label: 'Country', size: '10%' },
    { id: 'localidad', numeric: false, disablePadding: true, label: 'Location', size: '10%' },
    { id: 'negocio', numeric: false, disablePadding: true, label: 'Business', size: '5%' },
    { id: 'ambiente', numeric: false, disablePadding: true, label: 'Environment', size: '5%' },
    { id: 'soservidor', numeric: false, disablePadding: true, label: 'Operating System', size: '25%' },
    { id: 'nombreaplicacion', numeric: false, disablePadding: true, label: 'Application', size: '20%' },
    { id: 'version', numeric: false, disablePadding: true, label: 'Version', size: '10%' },
    { id: 'fechaupdate', numeric: false, disablePadding: true, label: 'SCCM Date', size: '5%' }        
];

const TablaAplicacionesSccm = ({token}) => {

    const [loading, setLoading] = React.useState(false); 

    const [rows, setRows] = useState(null);
    const [filter, setFilter] = useState("");

    function aplicarFiltro(registros)
    {
        if(registros == null) setRows(registros); 
        else if(registros.length === 0)
        {
            setRows(registros)            
            swal ("There are no records" ,
                    "No information is recorded with the filters used" ,
                    "info");
        }
        else setRows(registros);
         
    }

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.nombre !== "") ? (row.nombre.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.version !== "") ? (row.version.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.nombreaplicacion !== "") ? (row.nombreaplicacion.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.fechaupdate !== "") ? (row.fechaupdate.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.pais !== "") ? (row.pais.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.localidad !== "") ? (row.localidad.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.ambiente !== "") ? (row.ambiente.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.negocio !== "") ? (row.negocio.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.soservidor !== "") ? (row.soservidor.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
        })
    }

    return (
        <>
            <FiltroAplicacionSccm aplicarFiltro={aplicarFiltro} load={setLoading} token={token}/>
            {
                (rows !== null)
                ? 
                (<>                    
                    
                    
                    <ContainerBusqueda> 
                        <br></br> 
                        
                            <TextField id="outlined-search" label="Search" size="small" type="search" variant="outlined" 
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                
                            />
                            <ExportarExcel rows={rows} columnas={Opciones.camposExcelSccm} nombreSheet="Aplicaciones SCCM" nombreArchivo="AplicacionesSccmExport"/>
                        <br></br>                  
                    </ContainerBusqueda>
                    <ContainerTable>
                        <TablaRegistros
                            headCells = { headCells }
                            rows = { buscar(rows) }
                            url = { null }
                            idFieldName = { null }
                            initialSortByField = { 'nombre' }
                            initialSortDirection = { 'desc' }
                            />
                    </ContainerTable>                    
                </>)
                :
                (
                    (loading) ? (
                        <>
                            <br></br>
                            <br></br>
                            <ContainerTable>
                                <Loading />
                            </ContainerTable>
                        </>
                    )  : null 
                )
            }
        </>
    )
}

export default TablaAplicacionesSccm
