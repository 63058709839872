import React from 'react'
import  Sidebar  from '../Sidebar'
import CreacionServidor from './CreacionServidor';
import * as TiIcons from 'react-icons/ti';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';

import { ContainerFormBot, ContainerFormTop, ButtonVolver } from '../Commons/Styles'

const FormCreateServidor = ({instance, t}) => {

    let priv = sessionStorage.getItem('ser_crear');
    const history = useHistory();

    function volver()
    {
        history.push("/servidores");
    }

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });
    
    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <div>
            <Sidebar instance={instance} despliegue={false}/>
            <ContainerFormTop>
                <div className="headerformulario">
                    <div className="headerform-izq">
                        <ButtonVolver onClick={volver} className="tooltipboton">
                        <TiIcons.TiArrowBack/>             
                        <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>
                    </div>
                    <div className="headerform-mid">
                        <div className="divtituloform">
                            <label className="tituloformulario">Server Creation Form</label>
                        </div>                        
                    </div>
                </div>
                <ContainerFormBot>
                    <CreacionServidor token={t}/>
                </ContainerFormBot>
                <br></br>
            </ContainerFormTop>
        </div>
    )
}

export default FormCreateServidor
