import React from 'react'
import Sidebar from '../../Sidebar'
import styled from 'styled-components';

const ContainerReporte = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    height: 90vh;
`;

const DashboardQSense = ({instance}) => {
    return (
        <>
        <Sidebar instance={instance} despliegue={true}/>
        <ContainerReporte>
            <iframe src="https://qs.arauco.cl/sense/app/a6162623-15a5-48ed-89a9-d852f8394fc6/overview" 
            style={{border: 'none', width: '100%', height: '100%'}}></iframe>
        </ContainerReporte>
        </>
    )
}

export default DashboardQSense
