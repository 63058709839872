import React, { useState } from 'react';

import '../css/aplicaciones.css'
import '../css/bitacora.css'

import Loading from '../Loading';
import FiltrosTablaBitacora from './FiltrosTablaBitacora';

import { TablaRegistros } from '../Commons/TablaRegistros';

import { ContainerBusqueda, ContainerTable } from '../Commons/Styles'


const headCells = [
    { id: 'id_modificacion', ordertype: 'int', numeric: false, disablePadding: true, label: 'Modification', size: '10%' },
    { id: 'fecha', ordertype: 'text', numeric: false, disablePadding: true, label: 'Date', size: '10%' },
    { id: 'responsable', ordertype: 'text', numeric: false, disablePadding: true, label: 'Responsable', size: '20%' },
    { id: 'campo', ordertype: 'text', numeric: false, disablePadding: true, label: 'Field', size: '10%' },
    { id: 'valor_anterior', ordertype: 'text', numeric: false, disablePadding: true, label: 'Before', size: '25%' },
    { id: 'valor_actual', ordertype: 'text', numeric: false, disablePadding: true, label: 'After', size: '25%' }   
];

const TablaBitacora = ({rows, buscarBitacoras, cerrarModal, token}) => {

    const [loading, setLoading] = React.useState(false);
    const [filter, setFilter] = useState("");

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.id_modificacion !== "") ? (row.id_modificacion.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.fecha !== "") ? (row.fecha.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.responsable !== "") ? (row.responsable.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.campo !== "") ? (row.campo.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.valor_anterior !== "") ? (row.valor_anterior.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.valor_actual !== "") ? (row.valor_actual.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
           
        })
    }

  return (
    <>
      <FiltrosTablaBitacora aplicarFiltro={buscarBitacoras} load={setLoading} cerrarModal={cerrarModal} token={token}/>    
        {
            (rows !== null) ? 
            (   
              <>
                  <br></br>
                  <br></br>
                  <ContainerBusqueda> 
                    <br></br>                     
                      <div style={{width: "20%", marginRight: 'auto',}}>
                        <input className="bootstrap-input-filtro"type='text'
                         value={filter}
                         onChange={(e) => setFilter(e.target.value)} placeholder={"Search"}  />
                      </div>                                                                       
                    <br></br>                  
                  </ContainerBusqueda>
                  <ContainerTable>
                        <TablaRegistros
                            headCells = { headCells }
                            rows = { buscar(rows) }
                            url = { null }
                            idFieldName = { null }
                            initialSortByField = { 'id_modificacion' }
                            initialSortDirection = { 'asc' }
                            />
                  </ContainerTable>                    
            </>)
            :
            (
                (loading) ? (
                    <>
                        <br></br>
                        <br></br>
                        <ContainerTable>
                            <Loading />
                        </ContainerTable>
                    </>
                )  : null 
            )
        }
    </>
  )
}

export default TablaBitacora