import * as Rutas from '../RutasServicios'

async function tablaMaestroGeneral(maestro, filtro, token)
{     
    const url = Rutas.URL_Maestros + "api/maestrogeneral/tabla"; 
    //const url = "https://localhost:44367/api/maestrogeneral/tabla";

    const dataMaestro = {        
        "tabla": maestro.tabla,
        "id": maestro.id,
        "texto": maestro.texto
    }

    const data = {        
        "filtromaestro": filtro,
        "maestro": dataMaestro
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

async function crearMaestroGeneral(maestro, nuevo, token)
{     
    const url = Rutas.URL_Maestros + "api/maestrogeneral/crear"; 
    //const url = "https://localhost:44367/api/maestrogeneral/crear";

    const dataMaestro = {        
        "tabla": maestro.tabla,
        "id": maestro.id,
        "texto": maestro.texto
    }

    const data = {        
        "maestronuevo": nuevo.toString().toUpperCase(),
        "maestro": dataMaestro
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

async function eliminarMaestroGeneral(maestro, eliminado, token)
{     
    const url = Rutas.URL_Maestros + "api/maestrogeneral/eliminar"; 
    //const url = "https://localhost:44367/api/maestrogeneral/eliminar";

    const data = {        
        "eliminado": eliminado,
        "maestro": maestro
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

export async function eliminarRegistroGeneral(maestro, eliminado, token) {
    try {
        var respuesta = await eliminarMaestroGeneral(maestro, eliminado, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearRegistroGeneral(maestro, nuevo, token) {
    try {
        var respuesta = await crearMaestroGeneral(maestro, nuevo, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerRegistroTablaGeneral(maestro, filtro, token) {
    try {
        var respuesta = await tablaMaestroGeneral(maestro, filtro, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}