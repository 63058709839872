import React from 'react'
import ReactExport from "react-export-excel";

import * as RiIcons from 'react-icons/ri';

import { ButtonExcel } from './Commons/Styles'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportarExcel = ({rows, columnas, nombreArchivo, nombreSheet, subtitulo}) => {
    return(
        <ExcelFile 
            element={ 
                <ButtonExcel type="button">
                    {
                        (subtitulo === "" || subtitulo === null || subtitulo === undefined) ? (<RiIcons.RiFileExcel2Fill></RiIcons.RiFileExcel2Fill>)
                        : (
                            <div className="tooltipboton">
                                <RiIcons.RiFileExcel2Fill>                                                
                                </RiIcons.RiFileExcel2Fill>
                                <span className="tooltiptextboton">{subtitulo}</span>
                            </div>
                        )
                    }
                </ButtonExcel>
            } 
            filename={nombreArchivo} 
            fileExtension="xlsx">
            <ExcelSheet data={rows} name={nombreSheet}>
                {
                    columnas.map((c) => {
                        return (<ExcelColumn key={"key-" + c.nombre} label={c.nombre} value={c.valor} style={{font: {bold: true}}} />)
                    })
                }                            
            </ExcelSheet>         
        </ExcelFile>
    )
}

export default ExportarExcel
