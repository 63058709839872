import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';

import { TablaRegistros } from '../Commons/TablaRegistros';

import { ContainerBusqueda, ContainerTable } from '../Commons/Styles'

const headCells = [
    { id: 'software', numeric: false, disablePadding: true, label: 'Application', size: '100%' }
         
];

const TablaAplicaciones = ({rows}) => {

    const [filter, setFilter] = useState("");

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.software !== "") ? (row.software.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
          
        })
    }

    return (
        <>
          <ContainerBusqueda>                                    
              <TextField 
                  style={{backgroundColor: '#fff'}}
                  id="outlined-search" 
                  label="Search" 
                  size="small" 
                  type="search" 
                  variant="outlined" 
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
              />                            
          </ContainerBusqueda>
          <ContainerTable>
            <TablaRegistros
                headCells = { headCells }
                rows = { buscar(rows) }
                url = { '/aplicaciones/' }
                idFieldName = { 'id_aplicacion' }
                initialSortByField = { 'software' }
                initialSortDirection = { 'desc' }
                />
          </ContainerTable>     
        </>
    )
}

export default TablaAplicaciones
