import React from 'react'
import Sidebar from '../Sidebar'
import TablaOtrosDispositivos from './TablaOtrosDispositivos';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';

const OtrosDispositivos = ({instance, t}) => {

    let email = sessionStorage.getItem('email');
    const history = useHistory();
    let priv = sessionStorage.getItem('dis_leer');

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });

    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <>
            {(email !== null)
            ?
            (<>
                <Sidebar instance={instance} despliegue={false}/>
                <br></br>
                <br></br>   
                <TablaOtrosDispositivos token={t}/>
            </>)
            :
            (<>
                <Sidebar instance={instance}/>
                
            </>)
            }
            
        </>
    )
}

export default OtrosDispositivos
