export const camposExcel = [
    {nombre:"ID", valor:"id_aplicacionback"},
    {nombre:"APPLICATION", valor:"software"},
    {nombre:"VERSION", valor:"version"},
    {nombre:"STATE", valor:"estado"},
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"BUSINESS", valor:"negocio"},
    {nombre:"OWNER", valor:"gerencia"},
    {nombre:"TYPE OF LICENSE", valor:"tipolicencia"},
    {nombre:"NUMBER OF LICENSES", valor:"numerolicencia"},
    {nombre:"PURCHASE INVOICE NUMBER", valor:"numerofactura"},
    {nombre:"INVOICE DATE", valor:"fechafactura"},
    {nombre:"EXPIRATION DATE", valor:"vencimientocontrato"},
    {nombre:"INFORM TO", valor:"informara"},
    {nombre:"OBSERVATION", valor:"observacion"},
    {nombre:"MODIFIED BY", valor:"modificadopor"},
    {nombre:"MODIFICATION DATE", valor:"fechaupdate"}
]

export const campos = [    
    "software",
    "version",
    "estado",
    "negocio",
    "pais",
    "gerencia",  
    "tipolicencia",
    "fechafactura",
    "vencimientocontrato",
    "numerofactura",
    "numerolicencia",
    "informara",
    "observacion",
    "modificadopor",
    "fechaupdate"
]

export const camposExcelServidores = [
    {nombre:"SERVER", valor:"servidor"},
    {nombre:"CONDTITION", valor:"estado"},    
    {nombre:"ENVIERONMENT", valor:"ambiente"},
    {nombre:"SERVER TYPE", valor:"tiposervidor"},
    {nombre:"DESCRIPTION", valor:"descripcion"}
]

export const camposExcelEquipos = [
    {nombre:"Desktop ID", valor:"id_equipoescritorio"},
    {nombre:"STATE", valor:"estado"},    
    {nombre:"CONDITION", valor:"condicion"},
    {nombre:"DESKTOP NAME", valor:"nombreequipo"},
    {nombre:"USER NAME", valor:"nombreusuario"},
    {nombre:"OBSERVATIONS", valor:"observaciones"}
]

export const camposExcelCarga = [   
    {nombre:"FILA", valor:"fila"},
    {nombre:"DETALLE", valor:"detalle"}    
]

export const camposExcelBitacora = [
    {nombre:"ID APPBO", valor:"id_registro"},
    {nombre:"ID LOG", valor:"id_bitacora"},
    {nombre:"IOD MODIFICATION", valor:"id_modificacion"},
    {nombre:"DATE", valor:"fecha"},
    {nombre:"RESPONSABLE", valor:"responsable"},
    {nombre:"FIELD", valor:"campo"},
    {nombre:"AFTER VALUE", valor:"valor_anterior"},
    {nombre:"BEFORE VALUE", valor:"valor_actual"}    
]