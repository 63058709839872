import React, {Fragment, useState} from 'react'
import { useForm } from "react-hook-form";

import { obtenerValoresForm, crearForm } from '../functions/FuncDispositivoOT'

import * as Opciones from './OpcionesDispositivoOT'

import "react-datepicker/dist/react-datepicker.css";
import "../css/formularioServidor.css";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import Loading from '../Loading';

import { Formulario } from '../Commons/Formulario';

const CreacionDispositivoOT = ({token}) => {

    let privilegio = sessionStorage.getItem('ser_ot_crear');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');
    let plantaUser = sessionStorage.getItem('planta');
    let idiomaUser = sessionStorage.getItem('idioma');

    const history = useHistory();
    const [registro, setRegistro] = useState(null);
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const [errorDispositivoOT, setErrorDispositivoOT] = useState(false);
    const [errorTipoActivo, setErrorTipoActivo] = useState(false);
    const [errorNumeroSerie, setErrorNumeroSerie] = useState(false);
    const [errorEstado, setErrorEstado] = useState(false);
    const [errorCriticidad, setErrorCriticidad] = useState(false);
    const [errorPlantaIndustrial, setErrorPlantaIndustrial] = useState(false);
    const [errorAreaResponsable, setErrorAreaResponsable] = useState(false);
    const [errorContactoResponsable, setErrorContactoResponsable] = useState(false);


    async function obtenerDatosBD()
    {
        await obtenerValoresForm("DEVICE OT", idiomaUser,token).then((response) => {

            const val = Opciones.getVal(response);

            let array = [];
            Opciones.listas.forEach(lista => {
                array = [];
                val[lista].map((u) => {
                    array.push({"label": u, "value": u});
                })
                val[lista] = array;
            });
            if(privilegio === "1"  || (privilegio === "4" && plantaUser === ""))
            {
                array = [];
                val["filtroplanta"].map((u) => {                    
                    if(u.localidad === localidadUser) array.push({"label": u.planta, "value": u.planta});
                })
                val["plantasindustriales"] = array;
            }
            else if(privilegio === "2")
            {
                array = [];
                val["filtroplanta"].map((u) => {                    
                    if(u.pais === paisUser) array.push({"label": u.planta, "value": u.planta});
                })
                val["plantasindustriales"] = array;
            }
            else if(privilegio === "4"){
                array = [];
                val["plantasindustriales"].map((u) => {                    
                    if(u.value === plantaUser) array.push({"label": u.label, "value": u.value});
                })
                val["plantasindustriales"] = array;
            }

            setValores(val);
        });
    }

    React.useEffect(() => {
        if (valores !== null) {
            Opciones.campos.forEach(c => {
                setValue(c, (registro == null) ? "" : registro[c], {shouldValidate: true,shouldDirty: true})
            });
        }
        else obtenerDatosBD();
    })

    function validarDispositivoOt(reg)
    {
        let errors = {
            "error_type_01": "Mandatory data must be entered",
        }

        let error = "";

        if(reg.dispositivoot === null || reg.dispositivoot === "") 
        {   
            setErrorDispositivoOT(true);
            error = errors["error_type_01"];
        }
        else setErrorDispositivoOT(false);

        if(reg.tipoactivo === null || reg.tipoactivo === "") 
        {   
            setErrorTipoActivo(true);
            error = errors["error_type_01"];
        }
        else setErrorTipoActivo(false);

        if(reg.numeroserie === null || reg.numeroserie === "") 
        {   
            setErrorNumeroSerie(true);
            error = errors["error_type_01"];
        }
        else setErrorNumeroSerie(false);

        if(reg.plantaindustrial === null || reg.plantaindustrial === "") 
        {   
            setErrorPlantaIndustrial(true);
            error = errors["error_type_01"];
        }
        else setErrorPlantaIndustrial(false);

        if(reg.arearesponsable === null || reg.arearesponsable === "") 
        {   
            setErrorAreaResponsable(true);
            error = errors["error_type_01"];
        }
        else setErrorAreaResponsable(false);

        if(reg.contactoresponsable === null || reg.contactoresponsable === "") 
        {   
            setErrorContactoResponsable(true);
            error = errors["error_type_01"];
        }
        else setErrorContactoResponsable(false);

        if(reg.estado === null || reg.estado === "") 
        {   
            setErrorEstado(true);
            error = errors["error_type_01"];
        }
        else setErrorEstado(false);

        if(reg.criticidad === null || reg.criticidad === "") 
        {   
            setErrorCriticidad(true);
            error = errors["error_type_01"];
        }
        else setErrorCriticidad(false);

        return error;
    }

    const onSubmit = (data) => {

        setRegistro(data);
        const datosform = data;

        let nombreusuario = sessionStorage.getItem('nombre');
        datosform.modificadopor = nombreusuario;
        datosform.fechaactualizacion = new Date();


        let errorMessage = validarDispositivoOt(datosform);
        
        if(errorMessage.length > 0)
        {
            swal("Form data", errorMessage, "warning");     
        }
        else{
            swal({
                title: "Creation confirmation",
                text: "Do you want to create the record?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion){
                    
                    await crearForm(datosform, token)
                    .then((response) => {
                        if(response !== null){    
                      
                            if(response.cod === 1){
                                swal("Successful creation", "", "success").then(() => {
                                    history.push("/dispositivosot/"+response.id);
                                });
                            }
                            else{
                                swal("Creation error", response.mensaje, "error");
                            }
                        }
                        else{
                            swal("Creation error", "An error occurred during the update. Please contact the administrator.", "error");
                        }
                    })
                }
            })
        }
    }

    let fields = [
        { group: 1, name: 'tipoactivo', label: 'Asset Type', disabled: false, hasErrorValidation: errorTipoActivo, type: 'select', valuesNameField: 'tiposactivo' }, 
        { group: 1, name: 'numeroserie', label: 'Serial Number / Tag', disabled: false, hasErrorValidation: errorNumeroSerie, type: 'text' }, 
        { group: 1, name: 'dispositivoot', label: 'Device Name', disabled: false, hasErrorValidation: errorDispositivoOT, type: 'text' },  
        { group: 1, name: 'descripcion', label: 'Description', disabled: false, hasErrorValidation: null, type: 'textarea' },
        { group: 1, name: 'ambiente', label: 'Environment', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'ambientes' },
        { group: 1, name: 'criticidad', label: 'Criticality', disabled: false, hasErrorValidation: errorCriticidad, type: 'select', valuesNameField: 'criticidades' },

        { group: 2, name: 'ubicacion', label: 'Ubication', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 2, name: 'zona', label: 'Zone', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 2, name: 'piso', label: 'Floor', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 2, name: 'gabinete', label: 'Cabinet or Rack', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 2, name: 'propiedadde', label: 'Property of', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'propiedades' },
        { group: 2, name: 'plantaindustrial', label: 'Industrial Plant', disabled: false, hasErrorValidation: errorPlantaIndustrial, type: 'select' , valuesNameField: 'plantasindustriales' },

        { group: 3, name: 'proveedor', label: 'Vendor', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 3, name: 'modelo', label: 'Model', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 3, name: 'sodispositivoot', label: 'Operating System', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'sistemasoperativos' },
        { group: 3, name: 'firmware', label: 'Firmware Version', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 3, name: 'tiposervidor', label: 'Server Type', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposservidor' },  
        { group: 3, name: 'antivirus', label: 'Antivirus', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'antivirus' },
        { group: 3, name: 'arearesponsable', label: 'Responsable Area', disabled: false, hasErrorValidation: errorAreaResponsable, type: 'select', valuesNameField: 'arearesponsables' },
        { group: 3, name: 'contactoresponsable', label: 'Responsable Contact', disabled: false, hasErrorValidation: errorContactoResponsable, type: 'text' },
        { group: 3, name: 'estado', label: 'State', disabled: false, hasErrorValidation: errorEstado, type: 'select', valuesNameField: 'estados' },
        { group: 3, name: 'tipoparchado', label: 'Patching Type', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposparchado' },
        { group: 3, name: 'observacion', label: 'Observation', disabled: false, hasErrorValidation: null, type: 'textarea' },  
        { group: 3, name: 'tipobd', label: 'Type DB', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposbd' },

        { group: 4, name: 'tiporespaldo', label: 'Backup Type', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 4, name: 'tiporespaldofrecuencia', label: 'Backup Frequency Type', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposrespaldofrecuencia' },

        { group: 5, name: 'tipopuerto', label: 'Port Type', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tipospuerto' },
        { group: 5, name: 'nombrered', label: 'Network Name', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 5, name: 'idvlan', label: 'ID VLAN', disabled: false, hasErrorValidation: null, type: 'text' },  
        { group: 5, name: 'direccionip', label: 'IP Address', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 5, name: 'direccionred', label: 'Network Address', disabled: false, hasErrorValidation: null, type: 'text' },
        { group: 5, name: 'direccionmac', label: 'MAC Address', disabled: false, hasErrorValidation: null, type: 'text' }, 
        { group: 5, name: 'tipoaccesoremoto', label: 'Remote Access Type', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'tiposaccesoremoto'  }, 
        { group: 5, name: 'accesoremotoaplicacion', label: 'Remote Access Application', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'accesoremotoaplicaciones'  }, 
        { group: 5, name: 'protocolo', label: 'Protocol', disabled: false, hasErrorValidation: null, type: 'select', valuesNameField: 'protocolos' }, 
    ];


    if(valores === null) return (<Loading />)
    else
    return (
        <Fragment>
            <div className="container-servidor">
                <Formulario
                    fields = { fields }
                    registro = { registro }
                    valores = { valores }
                    privModificar = { true }
                    onSubmit = { onSubmit }
                    register = { register }
                    handleSubmit = { handleSubmit }
                    control = { control }
                    /> 
            </div>
        </Fragment>
    )
}

export default CreacionDispositivoOT
