import * as Rutas from '../RutasServicios'

// por revisar
async function tablaDatosDispositivosOT(estado, localidad, planta, negocio, pais, dispositivoot, tipoactivo, token, endpoint)
{
    let nivelUser = sessionStorage.getItem('ser_ot_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');
    let plantaUser = sessionStorage.getItem('planta');

    const request= {
        "nivel": nivelUser,
        "pais": paisUser,
        "localidad": localidadUser,
        "planta": plantaUser,
        "filtroplanta": planta,
        "filtrolocalidad": localidad,
        "filtropais": pais,
        "filtronegocio": negocio,
        "filtrodispositivoot": dispositivoot,
        "filtroestado": estado,
        "filtrotipoactivo": tipoactivo
    }
    console.log(request)
    var bRequest = JSON.stringify(request, null, 2);

    const url = Rutas.URL_Servicio + endpoint;    
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }        
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function filtroDispositivosOT(token)
{ 
    const url = Rutas.URL_Servicio + "filtrosdispot";  
    //const url = "https://localhost:44325/api/dispositivoot/filtros";   

    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function obtenerDispositivoOT(id, token)
{ 
    const url = Rutas.URL_Servicio + "dispositivoot";    
    //const url = "https://localhost:44325/api/dispositivoot/dispositivoot"; 

    const request= {
        "id_dispositivoot": id.toString()        
    };
    
    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function subirArchivoExcel(file64, nombre, usuario, token)
{ 
    const url = Rutas.URL_Carga_Servidor + "subirexceldispot";
    //const url = "https://localhost:44343/api/cargadispositivoot/subirexcel"; 

    const data = {
        "title": nombre,
        "file": file64,
        "desc": "archivo de carga excel",
        "usuario": usuario
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    if(res.cod === 1) {
        res = await cargarExcelData(res.mensaje, token)
    }

    return res;
}

async function cargarExcelData(archivo, token)
{ 
    const url = Rutas.URL_Carga_Servidor + "cargarexceldispot";
    //const url = "https://localhost:44343/api/cargadispositivoot/cargarexceldispot"; 

    const data = {
        "archivo": archivo
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function obtenerCargasExcel(usuario, token)
{ 
    const url = Rutas.URL_Carga_Servidor + "cargasdispot";    
    //const url = "https://localhost:44343/api/cargadispositivoot/cargas"; 

    const data = {        
        "usuario": usuario
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var res = null;
    var convertido = await respuesta.json();

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function actualizarDispositivoOT(data, dataAnterior, id, token)
{ 
    const url = Rutas.URL_Servicio + "actualizardispot"; 
    //const url = "https://localhost:44325/api/dispositivoot/actualizar"; 

    const update = { 
        "id_dispositivoot": id.toString()      
    };

    if(data.tipoactivo !== undefined) update.tipoactivo = (data.tipoactivo !== dataAnterior.tipoactivo) ? data.tipoactivo.value.toString().toUpperCase() : "";
    if(data.dispositivoot !== dataAnterior.dispositivoot) update.dispositivoot = data.dispositivoot.toString().toUpperCase();
    if(data.numeroserie !== dataAnterior.numeroserie) update.numeroserie = data.numeroserie.toString().toUpperCase();
    update.descripcion = (data.descripcion !== dataAnterior.descripcion) ? (data.descripcion !== "") ? update.descripcion = data.descripcion.toString().toUpperCase() : "esnull" : null;
    if(data.ambiente !== undefined) update.ambiente = (data.ambiente !== dataAnterior.ambiente) ? (data.ambiente !== "" && data.ambiente !== null) ? data.ambiente.value.toString().toUpperCase() : "esnull" : "";
    if(data.criticidad !== undefined) update.criticidad = (data.criticidad !== dataAnterior.criticidad) ? data.criticidad.value.toString().toUpperCase() : "";

    update.ubicacion = (data.ubicacion !== dataAnterior.ubicacion) ? (data.ubicacion !== "") ? update.ubicacion = data.ubicacion.toString().toUpperCase() : "esnull" : null;
    update.zona = (data.zona !== dataAnterior.zona) ? (data.zona !== "") ? update.zona = data.zona.toString().toUpperCase() : "esnull" : null;
    update.piso = (data.piso !== dataAnterior.piso) ? (data.piso !== "") ? update.piso = data.piso.toString().toUpperCase() : "esnull" : null;
    update.gabinete = (data.gabinete !== dataAnterior.gabinete) ? (data.gabinete !== "") ? update.gabinete = data.gabinete.toString().toUpperCase() : "esnull" : null;
    if(data.propiedadde !== undefined) update.propiedadde = (data.propiedadde !== dataAnterior.propiedadde) ? (data.propiedadde !== "" && data.propiedadde !== null) ? data.propiedadde.value.toString().toUpperCase() : "esnull" : "";    
    if(data.plantaindustrial !== undefined) update.plantaindustrial = (data.plantaindustrial !== dataAnterior.plantaindustrial) ? data.plantaindustrial.value.toString().toUpperCase() : "";   
    
    update.proveedor = (data.proveedor !== dataAnterior.proveedor) ? (data.proveedor !== "") ? update.proveedor = data.proveedor.toString().toUpperCase() : "esnull" : null;
    update.modelo = (data.modelo !== dataAnterior.modelo) ? (data.modelo !== "") ? update.modelo = data.modelo.toString().toUpperCase() : "esnull" : null;
    if(data.sodispositivoot !== undefined) update.sodispositivoot = (data.sodispositivoot !== dataAnterior.sodispositivoot) ? (data.sodispositivoot !== "" && data.sodispositivoot !== null) ? data.sodispositivoot.value.toString().toUpperCase() : "esnull" : "";
    update.firmware = (data.firmware !== dataAnterior.firmware) ? (data.firmware !== "") ? update.firmware = data.firmware.toString().toUpperCase() : "esnull" : null;
    if(data.tiposervidor !== undefined) update.tiposervidor = (data.tiposervidor !== dataAnterior.tiposervidor) ? (data.tiposervidor !== "" && data.tiposervidor !== null) ? data.tiposervidor.value.toString().toUpperCase() : "esnull" : "";
    if(data.antivirus !== undefined) update.antivirus = (data.antivirus !== dataAnterior.antivirus) ? (data.antivirus !== "" && data.antivirus !== null) ? data.antivirus.value.toString().toUpperCase() : "esnull" : "";
    if(data.arearesponsable !== undefined) update.arearesponsable = (data.arearesponsable !== dataAnterior.arearesponsable) ? data.arearesponsable.value.toString().toUpperCase() : "";   
    if(data.contactoresponsable !== dataAnterior.contactoresponsable) update.contactoresponsable = data.contactoresponsable.toString().toUpperCase();
    if(data.estado !== undefined) update.estado = (data.estado !== dataAnterior.estado) ? data.estado.value.toString().toUpperCase() : "";
    if(data.tipoparchado !== undefined) update.tipoparchado = (data.tipoparchado !== dataAnterior.tipoparchado) ? (data.tipoparchado !== "" && data.tipoparchado !== null) ? data.tipoparchado.value.toString().toUpperCase() : "esnull" : "";
    update.observacion = (data.observacion !== dataAnterior.observacion) ? (data.observacion !== "") ? update.observacion = data.observacion.toString().toUpperCase() : "esnull" : null;
    if(data.tipobd !== undefined) update.tipobd = (data.tipobd !== dataAnterior.tipobd) ? (data.tipobd !== "" && data.tipobd !== null) ? data.tipobd.value.toString().toUpperCase() : "esnull" : "";
    
    update.tiporespaldo = (data.tiporespaldo !== dataAnterior.tiporespaldo) ? (data.tiporespaldo !== "") ? update.tiporespaldo = data.tiporespaldo.toString().toUpperCase() : "esnull" : null; 
    if(data.tiporespaldofrecuencia !== undefined) update.tiporespaldofrecuencia = (data.tiporespaldofrecuencia !== dataAnterior.tiporespaldofrecuencia) ? (data.tiporespaldofrecuencia !== "" && data.tiporespaldofrecuencia !== null) ? data.tiporespaldofrecuencia.value.toString().toUpperCase() : "esnull" : "";

    if(data.tipopuerto !== undefined) update.tipopuerto = (data.tipopuerto !== dataAnterior.tipopuerto) ? (data.tipopuerto !== "" && data.tipopuerto !== null) ? data.tipopuerto.value.toString().toUpperCase() : "esnull" : "";
    update.nombrered = (data.nombrered !== dataAnterior.nombrered) ? (data.nombrered !== "") ? update.nombrered = data.nombrered.toString().toUpperCase() : "esnull" : null;
    update.idvlan = (data.idvlan !== dataAnterior.idvlan) ? (data.idvlan !== "") ? update.idvlan = data.idvlan.toString().toUpperCase() : "esnull" : null;
    update.direccionip = (data.direccionip !== dataAnterior.direccionip) ? (data.direccionip !== "") ? update.direccionip = data.direccionip.toString().toUpperCase() : "esnull" : null;
    update.direccionred = (data.direccionred !== dataAnterior.direccionred) ? (data.direccionred !== "") ? update.direccionred = data.direccionred.toString().toUpperCase() : "esnull" : null;       
    update.direccionmac = (data.direccionmac !== dataAnterior.direccionmac) ? (data.direccionmac !== "") ? update.direccionmac = data.direccionmac.toString().toUpperCase() : "esnull" : null;       
    if(data.tipoaccesoremoto !== undefined) update.tipoaccesoremoto = (data.tipoaccesoremoto !== dataAnterior.tipoaccesoremoto) ? (data.tipoaccesoremoto !== "" && data.tipoaccesoremoto !== null) ? data.tipoaccesoremoto.value.toString().toUpperCase() : "esnull" : "";
    if(data.accesoremotoaplicacion !== undefined) update.accesoremotoaplicacion = (data.accesoremotoaplicacion !== dataAnterior.accesoremotoaplicacion) ? (data.accesoremotoaplicacion !== "" && data.accesoremotoaplicacion !== null) ? data.accesoremotoaplicacion.value.toString().toUpperCase() : "esnull" : "";
    if(data.protocolo !== undefined) update.protocolo = (data.protocolo !== dataAnterior.protocolo) ? (data.protocolo !== "" && data.protocolo !== null) ? data.protocolo.value.toString().toUpperCase() : "esnull" : "";
    update.modificadopor = data.modificadopor;
    update.fechaactualizacion = new Date(data.fechaactualizacion).toISOString().substring(0, 10);
    console.log(update)
    var bRequest = JSON.stringify(update);

    var respuesta = await fetch(url, {
        method: "PUT",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }
    console.log(res)
    return res;    
}

async function crearDispositivoOT(data, token){

    const url = Rutas.URL_Servicio + "crearformdispot";    
    //const url = "https://localhost:44325/api/dispositivoot/crearform";

    const create = { 
        "dispositivoot": data.dispositivoot.toString().toUpperCase(),
        "tipoactivo": data.tipoactivo.value.toString(),        
        "criticidad": data.criticidad.value.toString(),
        "estado": data.estado.value.toString()
    };

    create.numeroserie = (data.numeroserie !== "" && data.numeroserie !== null && data.numeroserie !== undefined) ? data.numeroserie.toString().toUpperCase() : null;
    create.descripcion = (data.descripcion !== "" && data.descripcion !== null && data.descripcion !== undefined) ? data.descripcion.toString().toUpperCase() : null;
    create.ambiente = (data.ambiente !== "" && data.ambiente !== null && data.ambiente !== undefined) ? data.ambiente.value.toString().toUpperCase() : null;

    create.ubicacion = (data.ubicacion !== "" && data.ubicacion !== null && data.ubicacion !== undefined) ? data.ubicacion.toString().toUpperCase() : null;
    create.zona = (data.zona !== "" && data.zona !== null && data.zona !== undefined) ? data.zona.toString().toUpperCase() : null;
    create.piso = (data.piso !== "" && data.piso !== null && data.piso !== undefined) ? data.piso.toString().toUpperCase() : null;
    create.gabinete = (data.gabinete !== "" && data.gabinete !== null && data.gabinete !== undefined) ? data.gabinete.toString().toUpperCase() : null;
    create.propiedadde = (data.propiedadde !== "" && data.propiedadde !== null && data.propiedadde !== undefined) ? data.propiedadde.value.toString().toUpperCase() : null;
    create.plantaindustrial = (data.plantaindustrial !== "" && data.plantaindustrial !== null && data.plantaindustrial !== undefined) ? data.plantaindustrial.value.toString().toUpperCase() : null;

    create.proveedor = (data.proveedor !== "" && data.proveedor !== null && data.proveedor !== undefined) ? data.proveedor.toString().toUpperCase() : null;
    create.modelo = (data.modelo !== "" && data.modelo !== null && data.modelo !== undefined) ? data.modelo.toString().toUpperCase() : null;
    create.sodispositivoot = (data.sodispositivoot !== "" && data.sodispositivoot !== null && data.sodispositivoot !== undefined) ? data.sodispositivoot.value.toString().toUpperCase() : null;
    create.firmware = (data.firmware !== "" && data.firmware !== null && data.firmware !== undefined) ? data.firmware.toString().toUpperCase() : null;
    create.tiposervidor = (data.tiposervidor !== "" && data.tiposervidor !== null && data.tiposervidor !== undefined) ? data.tiposervidor.value.toString().toUpperCase() : null;
    create.antivirus = (data.antivirus !== "" && data.antivirus !== null && data.antivirus !== undefined) ? data.antivirus.value.toString().toUpperCase() : null;
    create.arearesponsable = (data.arearesponsable !== "" && data.arearesponsable !== null && data.arearesponsable !== undefined) ? data.arearesponsable.value.toString().toUpperCase() : null;
    create.contactoresponsable = (data.contactoresponsable !== "" && data.contactoresponsable !== null && data.contactoresponsable !== undefined) ? data.contactoresponsable.toString().toUpperCase() : null;
    create.tipoparchado = (data.tipoparchado !== "" && data.tipoparchado !== null && data.tipoparchado !== undefined) ? data.tipoparchado.value.toString().toUpperCase() : null;
    create.observacion = (data.observacion !== "" && data.observacion !== null && data.observacion !== undefined) ? data.observacion.toString().toUpperCase() : null;
    create.tipobd = (data.tipobd !== "" && data.tipobd !== null && data.tipobd !== undefined) ? data.tipobd.value.toString().toUpperCase() : null;

    create.tiporespaldo = (data.tiporespaldo !== "" && data.tiporespaldo !== null && data.tiporespaldo !== undefined) ? data.tiporespaldo.toString().toUpperCase() : null;
    create.tiporespaldofrecuencia = (data.tiporespaldofrecuencia !== "" && data.tiporespaldofrecuencia !== null && data.tiporespaldofrecuencia !== undefined) ? data.tiporespaldofrecuencia.value.toString().toUpperCase() : null;
    
    create.tipopuerto = (data.tipopuerto !== "" && data.tipopuerto !== null && data.tipopuerto !== undefined) ? data.tipopuerto.value.toString().toUpperCase() : null;
    create.nombrered = (data.nombrered !== "" && data.nombrered !== null && data.nombrered !== undefined) ? data.nombrered.toString().toUpperCase() : null;
    create.idvlan = (data.idvlan !== "" && data.idvlan !== null && data.idvlan !== undefined) ? data.idvlan.toString().toUpperCase() : null;
    create.direccionip = (data.direccionip !== "" && data.direccionip !== null && data.direccionip !== undefined) ? data.direccionip.toString().toUpperCase() : null;
    create.direccionred = (data.direccionred !== "" && data.direccionred !== null && data.direccionred !== undefined) ? data.direccionred.toString().toUpperCase() : null;
    create.direccionmac = (data.direccionmac !== "" && data.direccionmac !== null && data.direccionmac !== undefined) ? data.direccionmac.toString().toUpperCase() : null;
    create.tipoaccesoremoto = (data.tipoaccesoremoto !== "" && data.tipoaccesoremoto !== null && data.tipoaccesoremoto !== undefined) ? data.tipoaccesoremoto.value.toString().toUpperCase() : null;
    create.accesoremotoaplicacion = (data.accesoremotoaplicacion !== "" && data.accesoremotoaplicacion !== null && data.accesoremotoaplicacion !== undefined) ? data.accesoremotoaplicacion.value.toString().toUpperCase() : null;
    create.protocolo = (data.protocolo !== "" && data.protocolo !== null && data.protocolo !== undefined) ? data.protocolo.value.toString().toUpperCase() : null;

    create.modificadopor = (data.modificadopor !== "" && data.modificadopor !== null && data.modificadopor !== undefined) ? data.modificadopor.toString().toUpperCase() : null;
    create.fechaactualizacion = (data.fechaactualizacion !== "" && data.fechaactualizacion !== null && data.fechaactualizacion !== undefined) ? new Date(data.fechaactualizacion).toISOString().substring(0, 10) : null;

    var bRequest = JSON.stringify(create);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido !== null){        
        res = convertido;
    }

    return res;    
}

async function eliminarDispositivoOT(id, token)
{ 
    const url = Rutas.URL_Servicio + "eliminardispot";    
    //const url = "https://localhost:44325/api/dispositivoot/eliminardispositivoot";
    
    const data = {        
        "id": id
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function valoresDispositivosOT(modulo, idioma, token)
{ 
    const url = Rutas.URL_Servicio + "valoresdispot"; 
    //const url = "https://localhost:44325/api/dispositivoot/valores";   
    
    const request= { 
        "modulo": modulo,
        "idioma": idioma 
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};
}

export async function eliminarDispositivoOTRequest(id, token) {
    try {
        var respuesta = await eliminarDispositivoOT(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function obtenerdatosFiltros(token) {
    try {
        var respuesta = await filtroDispositivosOT(token);
        return respuesta;
    } catch (error) {
        return null;
    }
}

export async function obtenerdatosTabla(estado, localidad, planta, negocio, pais, dispositivoot, tipoactivo, token) {
    try {
        var endpoint = "tabladispositivosot"
        var respuesta = await tablaDatosDispositivosOT(estado, localidad, planta, negocio, pais, dispositivoot, tipoactivo, token, endpoint);
        return respuesta;
    } catch (error) {        
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function obtenerDispositivoOTForm(id, token) {
    try {
        var respuesta = await obtenerDispositivoOT(id, token);
        return respuesta;
    } catch (error) {
        return null;
    }
}

export async function obtenerValoresForm(modulo, idioma, token) {
    try {
        var respuesta = await valoresDispositivosOT(modulo, idioma, token);
        return respuesta;
    } catch (error) {
        return null;
    }
}

export async function actualizarForm(data, dataAnterior, id, token) {
    try {
        var respuesta = await actualizarDispositivoOT(data, dataAnterior, id, token);
        return respuesta;
    } catch (error) {        
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function crearForm(data, token) {
    try {
        var respuesta = await crearDispositivoOT(data, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function uploadExcel(file64, nombre, usuario, token) {
    try {
        var respuesta = await subirArchivoExcel(file64, nombre, usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento", "cod": 3};
    }
}

export async function cargasExcel(usuario, token) {
    try {
        var respuesta = await obtenerCargasExcel(usuario, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}


export async function obtenerTablaBitacoras(estado, localidad, planta, negocio, pais, sistemaOperativo, dispositivoot, token) {
    try {
        var endpoint = "bitacorasdispot"
        var respuesta = await tablaDatosDispositivosOT(estado, localidad, planta, negocio, pais, sistemaOperativo, dispositivoot, token, endpoint);
        return respuesta;
    } catch (error) {
        return {"mensaje": "Error de procesamiento (3)", "cod": 3};
    }
}


