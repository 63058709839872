import React from 'react'
import Lottie from "react-lottie";
import logo from './img/cargaArchivo.gif'


const LoadingCarga = () => {
    
    return (
        <div>
            <img src={logo} alt="loading..." />
        </div>
    )
}

export default LoadingCarga
