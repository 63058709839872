import React from 'react'
import { obtenerFiltros, obtenerMaestro } from '../../functions/FuncMaestroApp'
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FiltroBase from '../../FiltroBase';
import swal from 'sweetalert';

const ContainerFiltros= styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
`;

const Button = styled.button`
    margin-left: auto;
    margin-right: auto;
    background-color: #EA7600;
    color: white;
    font-size: 20px;    
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    vertical-align: middle;
    color: white;
`;

const useStyles = makeStyles((theme) => ({
    formControl: {
      marginLeft: '10px',
      marginRight: 'auto',
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const opcionesFiltros = (arreglo, setConjunto, setCarga) => {
    var array = [];
    if(arreglo.length > 0){
        arreglo.map((p) => array.push({label: p, value: p}));    
        setConjunto(array);
        setCarga(true); 
    }
}

const FiltrosMaestrosApp = ({aplicarFiltro, load, token}) => {

    const classes = useStyles();

    const [fabricantes, setFabricantes] = React.useState(null);
    const [fabricante, setFabricante] = React.useState("");
    const [cfabricante, setCFabricante] = React.useState(false);

    const [aplicacion, setAplicacion] = React.useState("");

    const cambioFabricante =  (valor) => {
        setFabricante(valor);
    };

    async function obtenerRegistros()
    {
        load(true);
        aplicarFiltro(null);
        await obtenerMaestro(aplicacion, fabricante, token)
        .then((response) => {  
            
            if(response.cod === 1)
            {
                aplicarFiltro(response.maestro);
            }
            else {
                aplicarFiltro(null);
                swal ("Search Error" , response.mensaje , "warning");
            }
            
            load(false);
        });
    }

    async function obtenerFiltrosApp(){
        await obtenerFiltros(token)
        .then((response) => {
            if(response.cod == 1)
            {               
                opcionesFiltros(response.fabricantes, setFabricantes, setCFabricante);                
            }
        }); 
    }

    React.useEffect(() =>{
        obtenerFiltrosApp();
    }, []);

    return (
        <>
            <ContainerFiltros>
                <FormControl variant="outlined" className={classes.formControl}>  
                    {
                        <TextField 
                            id="outlined-basic" 
                            value={aplicacion} 
                            onChange={(e) => setAplicacion(e.target.value)} 
                            label="Application" 
                            variant="outlined" 
                        />  
                    }                 
                </FormControl>
                <FiltroBase 
                    opciones={fabricantes} 
                    cargado={cfabricante}
                    value={fabricante}
                    nombre="Producer"
                    cambio={cambioFabricante}
                    classes={classes}
                />

                <Button onClick={obtenerRegistros} className={classes.formControl} style={{verticalAlign: 'middle'}}>Display</Button>
                
            </ContainerFiltros>
        </>
    )
}

export default FiltrosMaestrosApp
