import * as Rutas from '../RutasServicios'

async function reporte(gerencia, token)
{ 
    const url = Rutas.URL_EQUIPOS + "reporte"; 
    //const url = "https://localhost:44347/api/equipo/reporte";
    
    const request= {
        "gerencia": gerencia                  
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }  
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function filtrosEquipos(token)
{ 
    const url = Rutas.URL_EQUIPOS + "filtrosreporte"; 
    //const url = "https://localhost:44347/api/equipo/filtrosreporte";    
    
    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function filtroServidores(token)
{ 
    const url = Rutas.URL_Servicio + "filtros";  
    //const url = "https://localhost:44325/api/servidor/filtros";   

    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function reporteServidor(red, token)
{ 
    const url = Rutas.URL_Servicio + "reporte"; 
    //const url = "https://localhost:44325/api/servidor/reporte"; 
    
    const request= {
        "plataforma": red                  
    };
    
    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }  
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function reporteAplicacion(token)
{ 
    const url = Rutas.URL_Aplicacion + "reporte"; 
    //const url = "https://localhost:44367/api/aplicacion/reporte";    
    
    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

export async function obtenerValoresAplicacion(token) {
    try {
        var respuesta = await reporteAplicacion(token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}


export async function obtenerValoresServidor(red, token) {
    try {
        var respuesta = await reporteServidor(red, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerValores(gerencia, token) {
    try {
        var respuesta = await reporte(gerencia, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerFiltrosEquipos(token) {
    try {
        var respuesta = await filtrosEquipos(token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerFiltrosServidor(token) {
    try {
        var respuesta = await filtroServidores(token);
        return respuesta;
    } catch (error) {
        return null;
    }
}