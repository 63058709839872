import React from 'react'
import { obtenerdatosFiltros } from '../functions/FuncServidor'
import { obtenerServidoresModal } from '../functions/FuncSalaServidor'
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FiltroBase from '../FiltroBase';
import swal from 'sweetalert';

import { ContainerFiltros, Button } from '../Commons/Styles'

const useStyles = makeStyles((theme) => ({
    formControl: {
      marginLeft: '10px',
      marginRight: 'auto',
      minWidth: '10px',
      width: '30%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const opcionesFiltros = (arreglo, setConjunto, setCarga) => {
    var array = [];
    if(arreglo.length > 0){
        arreglo.map((p) => array.push({label: p, value: p}));    
        setConjunto(array);
        setCarga(true); 
    }
}

const FiltrosTablaServidorModalSala = ({aplicarFiltro, load, paisSala, localidadSala, id, token}) => {
    
    const [localidadesFiltro, setLocalidadesFiltro] = React.useState(null);

    const [localidad, setLocalidad] = React.useState("");
    const [clocalidad, setCLocalidad] = React.useState(false);
    const [localidades, setLocalidades] = React.useState(null);

    const [cpais, setCPais] = React.useState(false);
    const [paises, setPaises] = React.useState(null);

    const [negocio, setNegocio] = React.useState("");
    const [cnegocio, setCNegocio] = React.useState(false);
    const [negocios, setNegocios] = React.useState(null);    
    const [servidor, setServidor] = React.useState("");

    const classes = useStyles();

    async function registrosTabla(){

        load(true);
        aplicarFiltro(null);
        await obtenerServidoresModal(id, localidadSala, paisSala, negocio, servidor, token)
        .then((r) => {   
            if(r.cod === 1) {
                aplicarFiltro(r.servidores); 
            }
            else {
                aplicarFiltro(null);
                swal ("Search error" , r.mensaje , "warning");
            }       
              
            load(false);         
        });
    }

    async function obtenerFiltros(){
        await obtenerdatosFiltros(token)
        .then((r) => {
            opcionesFiltros(r.localidades, setLocalidades, setCLocalidad);
            opcionesFiltros(r.paises, setPaises, setCPais);
            opcionesFiltros(r.negocios, setNegocios, setCNegocio);
            setLocalidadesFiltro(r.filtrolocalidad);

        }); 
    }

    const cambioNegocio = (valor) => {
        setNegocio(valor);
    };

    React.useEffect(() =>{
        obtenerFiltros();        
    }, []);
    
    return (
        <>
            <ContainerFiltros>

                <FiltroBase 
                    opciones={negocios} 
                    cargado={cnegocio}
                    value={negocio}
                    nombre="Business"
                    cambio={cambioNegocio}
                    classes={classes}
                />

                <FormControl variant="outlined" className={classes.formControl}>  
                    {
                        <TextField 
                            id="outlined-basic" 
                            value={servidor} 
                            style={{minWidth: '100px'}}
                            onChange={(e) => setServidor(e.target.value)} 
                            label="Server" 
                            variant="outlined" 
                        />  
                    }                 
                </FormControl>
                
                <Button onClick={registrosTabla} className={classes.formControl} style={{verticalAlign: 'middle'}}>Display</Button>
            
            </ContainerFiltros>
        </>
    )
}

export default FiltrosTablaServidorModalSala
