import React from 'react'

import * as TiIcons from 'react-icons/ti';

import { ContainerFiltros, FilaContainerFiltros, Button, ButtonVolver } from '../../Commons/Styles'

const FiltrosTablaUsuarios = ({aplicarFiltro, load, cerrarModal, token}) => {

    const [usuario, setUsuario] = React.useState("");
    const [correo, setCorreo] = React.useState("");

    async function obtenerRegistros()
    {
        var usuarioApi = (usuario !== null && usuario !== undefined && usuario !== "") ? usuario : "";
        var correoApi = (correo !== null && correo !== undefined && correo !== "") ? correo : "";       

        load(true);
        await aplicarFiltro(usuarioApi, correoApi)
        load(false);
    }

    return (
        <>
            <ButtonVolver onClick={cerrarModal} className="tooltipboton">
                            <TiIcons.TiArrowBack/>             
                            <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>
            <FilaContainerFiltros>
                <ContainerFiltros>
                    <div style={{width: "40%", marginLeft: '10px', marginRight: 'auto',}}>
                        <input className="bootstrap-input-filtro" value={usuario} type='text' onChange={(e) => setUsuario(e.target.value)} placeholder={"User"}  />
                    </div>
                                     
                    <div style={{width: "40%", marginLeft: '10px', marginRight: 'auto',}}>
                        <input className="bootstrap-input-filtro" value={correo} type='text' onChange={(e) => setCorreo(e.target.value)} placeholder={"Email"}  />
                    </div>
                   
                    <div style={{width: "19%", marginLeft: '10px', marginRight: 'auto',}}>
                        <Button onClick={obtenerRegistros}>Display</Button>
                    </div>
                </ContainerFiltros>
            </FilaContainerFiltros>
            <br></br>
            <hr style={{width: '90%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
        </>
    )
}

export default FiltrosTablaUsuarios