import React from 'react'

import swal from 'sweetalert';


import FiltroBaseUser from '../../FiltroBaseUser';
import { obtenerFiltros } from '../../functions/FuncOtrosDispositivos'
import { obtenerTabla } from '../../functions/FuncUsuario'

import  * as Metodos from '../../functions/MetodosComunes'

import { ContainerFiltros, FilaContainerFiltros, Button } from '../../Commons/Styles'


const FiltrosTablaUsuario = ({aplicarFiltro, load, token}) => {

    let priv = sessionStorage.getItem('admin_usuarios');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');
    const [valores, setValores] = React.useState(null);

    const [usuario, setUsuario] = React.useState(""); 
    const [rol, setRol] = React.useState(""); 
    const [area, setArea] = React.useState(""); 

    const [paises, setPaises] = React.useState(null);
    const [pais, setPais] = React.useState("");
    const [localidades, setLocalidades] = React.useState(null);
    const [localidad, setLocalidad]  = React.useState("");

    const [lpais, setLPais] = React.useState(true);
    const [llocalidad, setLLocalidad] = React.useState(true);

    const cambioLocalidad = (valor) => {
        setLocalidad(valor);
    };

    const cambioPais = (valor) => {

        if(valor !== null && valor !== undefined && valor !== ""){
            setPais(valor);
            filtrarLocalidad(valor.value);
        } 
        else{      
            setPais(null);      
            var array = [];
            if(valores.filtrolocalidad.length > 0){

                valores.filtrolocalidad.map((p) => {
                    array.push({label: p.localidad, value: p.localidad});
                });  
                setLocalidades(array);  
            }
        }
    };

    function filtrarLocalidad(filtro)
    {
        var array = [];
        if(valores.filtrolocalidad.length > 0){
            valores.filtrolocalidad.map((p) => {
                if(p.pais === filtro) array.push({label: p.localidad, value: p.localidad});
            });  

            setLocalidad(null);
            setLocalidades(array); 
        }
    }

    async function obtenerFiltrosCom(){
        await obtenerFiltros(token)
        .then((response) => {
            if(response.cod === 1)
            {
                const val = {          
                    "paises": response.paises,
                    "filtrolocalidad": response.filtrolocalidad,
                    "localidades": response.localidades, 
                };

                console.log(val.filtrolocalidad)

                if(priv === "1"){
                    setLocalidades(Metodos.opcionesFiltrosValor(val.localidades, localidadUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser)); 

                    setPais({label: paisUser, value: paisUser});
                    setLocalidad({label: localidadUser, value: localidadUser});

                    setLPais(false);
                    setLLocalidad(false);
                }

                if(priv === "2"){
                    setLocalidades(Metodos.opcionesFiltrosLocalidad(val.filtrolocalidad, paisUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));  

                    setPais({label: paisUser, value: paisUser});
                    setLPais(false);
                }

                if(priv === "3"){
                    setLocalidades(val.localidades = Metodos.opcionesFiltros(val.localidades));
                    setPaises(Metodos.opcionesFiltros(val.paises));
                }

                setValores(val);  
            }
        }); 
    }

    React.useEffect(() =>{
        obtenerFiltrosCom();
    }, []);

    async function obtenerRegistros()
    {
        load(true);
        aplicarFiltro(null);  

        var paisApi = (pais !== null && pais !== undefined && pais !== "") ? pais.value : null;
        var localidadApi = (localidad !== null && localidad !== undefined && localidad !== "") ? localidad.value : null;

        await obtenerTabla(paisApi, localidadApi, rol, area, usuario, token)
        .then((response) => { 

            console.log(response.usuarios)

            if (response.cod === 1)
            {
                var usuarios = [];
                response.usuarios.forEach(u => {
                    var user = {
                        "usuarioId": u.usuarioId,
                        "nombre": u.nombre,
                        "correo": u.correo,
                        "username": u.username,
                        "estado": u.estado,
                        "area": u.area,
                        "rol": u.rol,
                        "idioma": u.idioma,
                        "pais": u.pais,
                        "localidad": u.localidad,
                        "planta": u.planta,
                        "fechaingreso": u.fechaIngreso,
                        "notificacion": u.notificacion,

                        "ser_leer": u.privilegios.ser_leer,
                        "ser_escribir": u.privilegios.ser_escribir,
                        "ser_crear": u.privilegios.ser_crear,
                        "ser_cargamasiva": u.privilegios.ser_cargamasiva,
                        "ser_eliminar": u.privilegios.ser_eliminar,

                        "ser_ot_leer": u.privilegios.ser_ot_leer,
                        "ser_ot_escribir": u.privilegios.ser_ot_escribir,
                        "ser_ot_crear": u.privilegios.ser_ot_crear,
                        "ser_ot_cargamasiva": u.privilegios.ser_ot_cargamasiva,
                        "ser_ot_eliminar": u.privilegios.ser_ot_eliminar,
                        
                        "app_leer": u.privilegios.app_leer,
                        "app_escribir": u.privilegios.app_escribir,
                        "app_crear": u.privilegios.app_crear,
                        "app_cargamasiva": u.privilegios.app_cargamasiva,
                        "app_eliminar": u.privilegios.app_eliminar,
                        "app_maestro": u.privilegios.app_maestro,

                        "esc_leer": u.privilegios.esc_leer,
                        "esc_escribir": u.privilegios.esc_escribir,
                        "esc_crear": u.privilegios.esc_crear,
                        "esc_cargamasiva": u.privilegios.esc_cargamasiva,
                        "esc_eliminar": u.privilegios.esc_eliminar,

                        "com_leer": u.privilegios.com_leer,
                        "com_escribir": u.privilegios.com_escribir,
                        "com_crear": u.privilegios.com_crear,
                        "com_cargamasiva": u.privilegios.com_cargamasiva,
                        "com_eliminar": u.privilegios.com_eliminar,

                        "dis_leer": u.privilegios.dis_leer,
                        "dis_escribir": u.privilegios.dis_escribir,
                        "dis_crear": u.privilegios.dis_crear,
                        "dis_cargamasiva": u.privilegios.dis_cargamasiva,
                        "dis_eliminar": u.privilegios.dis_eliminar,

                        "sala_leer": u.privilegios.sala_leer,
                        "sala_escribir": u.privilegios.sala_escribir,
                        "sala_crear": u.privilegios.sala_crear,
                        "sala_cargamasiva": u.privilegios.sala_cargamasiva,
                        "sala_eliminar": u.privilegios.sala_eliminar,

                        "cuentaser_leer": u.privilegios.cuentaser_leer,
                        "cuentaser_escribir": u.privilegios.cuentaser_escribir,
                        "cuentaser_crear": u.privilegios.cuentaser_crear,
                        "cuentaser_cargamasiva": u.privilegios.cuentaser_cargamasiva,
                        "cuentaser_eliminar": u.privilegios.cuentaser_eliminar,

                        "cert_leer": u.privilegios.cert_leer,
                        "cert_escribir": u.privilegios.cert_escribir,
                        "cert_crear": u.privilegios.cert_crear,
                        "cert_cargamasiva": u.privilegios.cert_cargamasiva,
                        "cert_eliminar": u.privilegios.cert_eliminar,

                        "appback_leer": u.privilegios.appback_leer,
                        "appback_escribir": u.privilegios.appback_escribir,
                        "appback_crear": u.privilegios.appback_crear,
                        "appback_cargamasiva": u.privilegios.appback_cargamasiva,
                        "appback_eliminar": u.privilegios.appback_eliminar,

                        "mas_leer": u.privilegios.mas_leer,
                        "mas_escribir": u.privilegios.mas_escribir,
                        "admin_personal": u.privilegios.admin_personal,
                        "admin_otrasfuentes": u.privilegios.admin_otrasfuentes,
                        "admin_usuarios": u.privilegios.admin_usuarios
                    }  

                    usuarios.push(user);
                });

                aplicarFiltro(usuarios);
            }
            else {
                aplicarFiltro(null);
                swal ("Search Error" , response.mensaje , "warning");
            }
                
            load(false);
        });  
    }

    return (
        <>
        <FilaContainerFiltros>
            <ContainerFiltros>

                <FiltroBaseUser
                    opciones={paises}
                    valor={pais}
                    nombre={"Country"}
                    namecampo="pais"
                    cambio={cambioPais}
                    proporcion={"20%"}
                    error={false}
                    limpieza={lpais}
                />

                <FiltroBaseUser
                    opciones={localidades}
                    valor={localidad}
                    nombre={"Location"}
                    namecampo="localidad"
                    cambio={cambioLocalidad}
                    proporcion={"20%"}
                    error={false}
                    limpieza={llocalidad}
                />

                <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>
                    <input className="bootstrap-input-filtro" value={rol} type='text' onChange={(e) => setRol(e.target.value)} placeholder={"Rol"}  />
                </div>

                <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>
                    <input className="bootstrap-input-filtro" value={area} type='text' onChange={(e) => setArea(e.target.value)} placeholder={"Area"}  />
                </div>

                <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>
                    <input className="bootstrap-input-filtro" value={usuario} type='text' onChange={(e) => setUsuario(e.target.value)} placeholder={"User"}  />
                </div>

                <div style={{width: "25%", marginLeft: '10px', marginRight: 'auto',}}>
                    <Button onClick={obtenerRegistros}>Display</Button>
                </div>                

                              
            </ContainerFiltros>            
        </FilaContainerFiltros>   
        <br></br>
        <hr style={{width: '85%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
            
        </>
    )
}

export default FiltrosTablaUsuario
