import * as Rutas from '../RutasServicios'

async function valores(nivel, pais, modulo, idioma, token)
{ 
    const url = Rutas.URL_Certificado + "valores";    
    //const url = "https://localhost:44329/api/comunicacion/valores";    
    
    const request= {        
        "nivel": nivel,        
        "pais": pais,        
        "modulo": modulo,
        "idioma": idioma   
    };

    var bRequest = JSON.stringify(request, null, 2);

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function crear(data, token)
{
    const url = Rutas.URL_Certificado + "crear";    
    //const url = "https://localhost:44329/api/comunicacion/crear";  

    const reg = { 
        "nombre": data.nombre.toString().toUpperCase(),
        "tipo": data.tipo.value.toString().toUpperCase(),
        "proveedor": data.proveedor.value.toString().toUpperCase(),
        "entidadcertificadora": data.entidadcertificadora.value.toString().toUpperCase(),
        "estado": data.estado.value.toString().toUpperCase(),
        "pais": data.pais.value.toString().toUpperCase(),
        "negocio": data.negocio.value.toString().toUpperCase(),
        "responsable": data.responsable.value.toString().toUpperCase(),
        "informara": data.informara.toString().toUpperCase(),
        "fechacreacion": new Date(data.fechacreacion).toISOString().substring(0, 10),
        "fechaexpiracion": new Date(data.fechaexpiracion).toISOString().substring(0, 10),
        "descripcion": data.descripcion.toString().toUpperCase(), 
        "observaciones": data.observaciones.toString().toUpperCase()     
    };

    reg.modificadopor = data.modificadopor.toString().toUpperCase();
    reg.fechaupdate = (data.fechaupdate !== null && data.fechaupdate !== "") ? new Date(data.fechaupdate).toISOString().substring(0, 10) : null;
    
    var bRequest = JSON.stringify(reg);
 
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function tabla(nombre, pais, proveedor, expiracion, entidad, estado, nivel, paisuser, token)
{ 
    const url = Rutas.URL_Certificado + "tabla";    
    //const url = "https://localhost:44329/api/comunicacion/tabla"; 

    const request= {
        "nombre": nombre,
        "pais": pais,
        "proveedor": proveedor,
        "entidad": entidad,
        "expiracion": expiracion,        
        "estado": estado,
        "nivel": nivel,
        "paisuser": paisuser     
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};   
}

async function obtener(id, token)
{ 
    const url = Rutas.URL_Certificado + "certificado";    
    //const url = "https://localhost:44370/api/cuentausuario/cuenta"; 

    const request= {
        "id_certificado": id           
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

async function actualizar(data, dataAnterior, id, token)
{ 
    const url = Rutas.URL_Certificado + "actualizar";    
    //const url = "https://localhost:44370/api/cuentausuario/actualizar"; 

    const request = {
        "id_certificado": id,
        "nombre": dataAnterior.nombre
    };

    request.tipo = (data.tipo != null && data.tipo != undefined) ? (data.tipo.value !== dataAnterior.tipo.value) ? data.tipo.value.toString().toUpperCase() : "" : "";
    request.proveedor = (data.proveedor != null && data.proveedor != undefined) ? (data.proveedor.value !== dataAnterior.proveedor.value) ? data.proveedor.value.toString().toUpperCase() : "" : "";
    request.entidadcertificadora = (data.entidadcertificadora != null && data.entidadcertificadora != undefined) ? (data.entidadcertificadora.value !== dataAnterior.entidadcertificadora.value) ? data.entidadcertificadora.value.toString().toUpperCase() : "" : "";
    request.estado = (data.estado != null && data.estado != undefined) ? (data.estado.value !== dataAnterior.estado.value) ? data.estado.value.toString().toUpperCase() : "" : "";
    request.pais = (data.pais != null && data.pais != undefined) ? (data.pais.value !== dataAnterior.pais.value) ? data.pais.value.toString().toUpperCase() : "" : "";
    request.negocio = (data.negocio != null && data.negocio != undefined) ? (data.negocio.value !== dataAnterior.negocio.value) ? data.negocio.value.toString().toUpperCase() : "" : "";
    request.responsable = (data.responsable != null && data.responsable != undefined) ? (data.responsable.value !== dataAnterior.responsable.value) ? data.responsable.value.toString().toUpperCase() : "" : "";

    if(data.informara !== null) request.informara = data.informara.toString().toUpperCase();    
    if(data.descripcion !== null) request.descripcion = data.descripcion.toString().toUpperCase();

    request.fechacreacion = (data.fechacreacion !== dataAnterior.fechacreacion) ? (data.fechacreacion !== null && dataAnterior.fechacreacion !== null) ? new Date(data.fechacreacion).toISOString().substring(0, 10): "esnull" : "";
    request.fechaexpiracion = (data.fechaexpiracion !== dataAnterior.fechaexpiracion) ? (data.fechaexpiracion !== null && dataAnterior.fechaexpiracion !== null) ? new Date(data.fechaexpiracion).toISOString().substring(0, 10): "esnull" : "";
    request.observaciones = (data.observaciones !== dataAnterior.observaciones) ? (data.observaciones !== "") ? data.observaciones.toString().toUpperCase() : "esnull" : "";
        
    request.modificadopor = data.modificadopor.toString().toUpperCase();
    request.fechaupdate = new Date().toISOString().substring(0, 10);

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};     
}

async function servidoresTabla(id, token)
{ 
    const url = Rutas.URL_Certificado + "relacionservidor";    
    //const url = "https://localhost:44370/api/cuentausuario/relacionservidor"; 

    const request= {
        "id_certificado": id              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function aplicacionesTabla(id, token)
{ 
    const url = Rutas.URL_Certificado + "relacionaplicacion";    
    //const url = "https://localhost:44370/api/cuentausuario/relacionaplicacion"; 

    const request= {
        "id_certificado": id              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function servidoresTablaModal(id, localidad, pais, negocio, servidor, token)
{ 
    const url = Rutas.URL_Certificado + "tablaservidores";    
    //const url = "https://localhost:44370/api/cuentausuario/tablaservidores"; 

    const request= {
        "id_certificado": id,
        "filtrolocalidad": localidad,
        "filtropais": pais,
        "filtronegocio": negocio,
        "filtroservidor": servidor              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function aplicacionesTablaModal(id, negocio, ambiente, aplicacion, pais, tipo, paisUser, privApp, token)
{
    const url = Rutas.URL_Certificado + "tablaaplicaciones";    
    //const url = "https://localhost:44370/api/cuentausuario/tablaaplicaciones"; 

    const request= {
        "id_certificado": id,
        "tabla_neg": negocio,
        "tabla_amb": ambiente,
        "tabla_app": aplicacion,
        "tabla_pais": pais,
        "tabla_tipo": tipo,              
        "privilegio": privApp,
        "pais": paisUser
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function crearRelacionServidor(id, servidores, token)
{ 
    let nombreusuario = sessionStorage.getItem('nombre');
    let fechaupdate = new Date().toISOString().substring(0, 10);    

    const url = Rutas.URL_Certificado + "crearrelacionservidor";    
    //const url = "https://localhost:44370/api/cuentausuario/crearrelacionservidor"; 

    const request= {
        "id_certificado": id,
        "servidores": servidores,
        "modificadopor": nombreusuario,
        "fechaupdate": fechaupdate         
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function crearRelacionAplicacion(id, aplicaciones, token)
{ 
    let nombreusuario = sessionStorage.getItem('nombre');
    let fechaupdate = new Date().toISOString().substring(0, 10);

    const url = Rutas.URL_Certificado + "crearrelacionaplicacion";    
    //const url = "https://localhost:44370/api/cuentausuario/crearrelacionaplicacion"; 

    const request= {
        "id_certificado": id,
        "aplicaciones": aplicaciones,
        "modificadopor": nombreusuario,
        "fechaupdate": fechaupdate              
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function eliminarRelacionServidor(id, servidores, token)
{ 
    let nombreusuario = sessionStorage.getItem('nombre');
    let fechaupdate = new Date().toISOString().substring(0, 10);

    const url = Rutas.URL_Certificado + "eliminarrelacionservidor";    
    //const url = "https://localhost:44370/api/cuentausuario/eliminarrelacionservidor"; 

    const request= {
        "id_certificado": id,
        "servidores": servidores,
        "modificadopor": nombreusuario,
        "fechaupdate": fechaupdate          
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function eliminarRelacionAplicacion(id, aplicaciones, token)
{
    let nombreusuario = sessionStorage.getItem('nombre');
    let fechaupdate = new Date().toISOString().substring(0, 10);
    
    const url = Rutas.URL_Certificado + "eliminarrelacionaplicacion";    
    //const url = "https://localhost:44370/api/cuentausuario/eliminarrelacionaplicacion"; 

    const request= {
        "id_certificado": id,
        "aplicaciones": aplicaciones,
        "modificadopor": nombreusuario,
        "fechaupdate": fechaupdate               
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

async function eliminar(id, token)
{ 
    const url = Rutas.URL_Certificado + "eliminar";    
    //const url = "https://localhost:44370/api/cuentausuario/eliminar";   
    
    const data = {        
        "id_certificado": id
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;    
}

export async function eliminarCertificado(id, token) {
    try {
        var respuesta = await eliminar(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function eliminarRelacionesServidor(id, servidores, token) {
    try {
        var respuesta = await eliminarRelacionServidor(id, servidores, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function eliminarRelacionesAplicacion(id, aplicaciones, token) {
    try {
        var respuesta = await eliminarRelacionAplicacion(id, aplicaciones, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}


export async function crearRelacionesServidor(id, servidores, token) {
    try {
        var respuesta = await crearRelacionServidor(id, servidores, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearRelacionesAplicacion(id, aplicaciones, token) {
    try {
        var respuesta = await crearRelacionAplicacion(id, aplicaciones, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerServidores(id, token) {
    try {
        var respuesta = await servidoresTabla(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerAplicaciones(id, token) {
    try {
        var respuesta = await aplicacionesTabla(id, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}


export async function obtenerServidoresModal(id, localidad, pais, negocio, servidor, token) {
    try {
        var respuesta = await servidoresTablaModal(id, localidad, pais, negocio, servidor, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerAplicacionesModal(id, negocio, ambiente, aplicacion, pais, tipo, paisUser, privApp, token) {
    try {
        console.log("llegue 2")
        var respuesta = await aplicacionesTablaModal(id, negocio, ambiente, aplicacion, pais, tipo, paisUser, privApp, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

export async function actualizarCertificado(data, dataAnterior, id, token) {
    try {
        var respuesta = await actualizar(data, dataAnterior, id, token);
        return respuesta;
    } catch (error) {
        console.log(error)      
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerCertificado(id, token) {
    try {
        var respuesta = await obtener(id, token);
        return respuesta;
    } catch (error) { 
        return {"mensaje": "error", "cod": 3};
    }
}

export async function tablaCertificado(nombre, pais, proveedor, expiracion, entidad, estado, nivel, paisuser, token) {
    try {
        var respuesta = await tabla(nombre, pais, proveedor, expiracion, entidad, estado, nivel, paisuser, token);
        return respuesta;
    } catch (error) { 
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearCertificado(data, token) {
    try {
        var respuesta = await crear(data, token);
        return respuesta;
    } catch (error) { 
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerValores(nivel, pais, modulo, idioma, token) {
    try {
        var respuesta = await valores(nivel, pais, modulo, idioma,token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}
