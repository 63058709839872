import * as Rutas from '../RutasServicios'

async function tablaMaestro(maestro, filtro, token)
{     
    const url = Rutas.URL_Maestros + "api/maestrocompuesto/tabla"; 
    //const url = "https://localhost:44367/api/maestrocompuesto/tabla";

    const data = {        
        "filtro": filtro,
        "maestro": maestro       
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

async function crearMaestro(maestro, maestronuevo, token)
{     
    const url = Rutas.URL_Maestros + "api/maestrocompuesto/crear"; 
    //const url = "https://localhost:44367/api/maestrocompuesto/crear";

    const data = {        
        "maestro": maestro,
        "maestronuevo": maestronuevo.toString().toUpperCase()            
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

async function eliminarMaestro(maestro, nombre, token)
{     
    const url = Rutas.URL_Maestros + "api/maestrocompuesto/eliminar"; 
    //const url = "https://localhost:44367/api/maestrocompuesto/eliminar";

    const data = {        
        "maestro": maestro,
        "eliminado": nombre       
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

async function tablaMaestroPlanta(filtro, token)
{     
    const url = Rutas.URL_Maestros + "api/maestrocompuesto/tablaplanta"; 
    //const url = "https://localhost:44367/api/maestrocompuesto/tablaplanta";

    const data = {        
        "filtromaestro": filtro        
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

async function crearPlanta(datos, token)
{     
    const url = Rutas.URL_Maestros + "api/maestrocompuesto/crearplanta"; 
    //const url = "https://localhost:44367/api/maestrocompuesto/crearplanta";

    const data = {        
        "planta": datos.planta.toString().toUpperCase(),
        "empresa": datos.empresa.value,
        "negocio": datos.negocio.value,
        "pais": datos.pais.value,
        "localidad": datos.localidad.value      
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

async function eliminarPlanta(planta, id, token)
{     
    const url = Rutas.URL_Maestros + "api/maestrocompuesto/eliminarplanta"; 
    //const url = "https://localhost:44367/api/maestrocompuesto/eliminarplanta";

    const data = {        
        "planta": planta,
        "id_planta": id
    }

    var bRequest = JSON.stringify(data, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;     
}

async function valores(token)
{ 
    const url = Rutas.URL_Maestros + "api/maestrocompuesto/valores"; 
    //const url = "https://localhost:44367/api/maestrocompuesto/valores"; 
    
    var respuesta = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}


export async function valoresMaestros(token) {
    try {
        var respuesta = await valores(token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerRegistroTablaPlanta(filtro, token) {
    try {
        var respuesta = await tablaMaestroPlanta(filtro, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearRegPlanta(datos, token) {
    try {
        var respuesta = await crearPlanta(datos, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

export async function eliminarRegPlanta(planta, id, token) {
    try {
        var respuesta = await eliminarPlanta(planta, id, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

export async function obtenerRegistroTabla(maestro, filtro, token) {
    try {
        var respuesta = await tablaMaestro(maestro, filtro, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

export async function crearRegMaestro(maestro, maestronuevo, token) {
    try {
        var respuesta = await crearMaestro(maestro, maestronuevo, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}

export async function eliminarRegMaestro(maestro, nombre, token) {
    try {
        var respuesta = await eliminarMaestro(maestro, nombre, token);
        return respuesta;
    } catch (error) {
        console.log(error)
        return {"mensaje": "error", "cod": 3};
    }
}