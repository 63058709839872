import React from 'react'

import swal from 'sweetalert';

import { obtenerFiltros, obtenerTabla , obtenerTablaBitacoras} from '../functions/FuncComunicacion'
import FiltroBaseNuevo from '../FiltroBaseNuevo';
import  * as Metodos from '../functions/MetodosComunes'

import { ContainerFiltros, FilaContainerFiltros, Button } from '../Commons/Styles'

const FiltrosTablaComunicaciones = ({aplicarFiltro, obtenerBitacoras, load, token}) => {
    
    let priv = sessionStorage.getItem('com_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');

    const [valores, setValores] = React.useState(null);

    const [errorFamilia, setErrorFamilia] = React.useState(false);

    const [familias, setFamilias] = React.useState(null);
    const [familia, setFamilia] = React.useState("");
    const [tipoEquipos, setTipoEquipos] = React.useState(null);
    const [tipoEquipo, setTipoEquipo] = React.useState("");
    const [condiciones, setCondiciones] = React.useState(null);
    const [condicion, setCondicion] = React.useState("");
    const [estados, setEstados] = React.useState(null);
    const [estado, setEstado] = React.useState("");
    const [plantas, setPlantas] = React.useState(null);
    const [planta, setPlanta] = React.useState("");
    const [paises, setPaises] = React.useState(null);
    const [pais, setPais] = React.useState("");
    const [localidades, setLocalidades] = React.useState(null);
    const [localidad, setLocalidad]  = React.useState("");
    const [numeroserie, setNumeroSerie] = React.useState("");

    const cambioTipoEquipo = (valor) => {
        setTipoEquipo(valor);
    };

    const cambioLocalidad = (valor) => {
        setLocalidad(valor);
    };

    const cambioPlanta = (valor) => {
        setPlanta(valor);
    };

    const cambioEstado = (valor) => {
        setEstado(valor);
    };

    const cambioCondicion = (valor) => {
        setCondicion(valor);
    };

    async function obtenerRegistros()
    {
        if(familia === null || familia === undefined || familia === "")
        {
            setErrorFamilia(true);
            swal("Filter Error","A value must be entered in Family", "warning")
        }
        else {
            setErrorFamilia(false);
            load(true);
            aplicarFiltro(null);

            var paisApi = (pais !== null && pais !== undefined && pais !== "") ? pais.value : null;
            var localidadApi = (localidad !== null && localidad !== undefined && localidad !== "") ? localidad.value : null;
            var estadoApi = (estado !== null && estado !== undefined && estado !== "") ? estado.value : null;
            var condicionApi = (condicion !== null && condicion !== undefined && condicion !== "") ? condicion.value : null;            
            var plantaApi = (planta !== null && planta !== undefined && planta !== "") ? planta.value : null;
            var tipoEquipoApi = (tipoEquipo !== null && tipoEquipo !== undefined && tipoEquipo !== "") ? tipoEquipo.value : null;
            var familiaApi = (familia !== null && familia !== undefined && familia !== "") ? familia.value : null;
            var numeroserieApi = (numeroserie !== null && numeroserie !== undefined && numeroserie !== "") ? numeroserie : null;

            (paisApi !== null) ? sessionStorage.setItem('filtroscomunicacion_pais', paisApi) : sessionStorage.setItem('filtroscomunicacion_pais', null);
            (localidadApi !== null) ? sessionStorage.setItem('filtroscomunicacion_localidad', localidadApi) : sessionStorage.setItem('filtroscomunicacion_localidad', null);
            (estadoApi !== null) ? sessionStorage.setItem('filtroscomunicacion_estado', estadoApi) : sessionStorage.setItem('filtroscomunicacion_estado', null);
            (condicionApi !== null) ? sessionStorage.setItem('filtroscomunicacion_condicion', condicionApi) : sessionStorage.setItem('filtroscomunicacion_condicion', null);
            (plantaApi !== null) ? sessionStorage.setItem('filtroscomunicacion_planta', plantaApi) : sessionStorage.setItem('filtroscomunicacion_planta', null);
            (tipoEquipoApi !== null) ? sessionStorage.setItem('filtroscomunicacion_tipo', tipoEquipoApi) : sessionStorage.setItem('filtroscomunicacion_tipo', null);
            (familiaApi !== null) ? sessionStorage.setItem('filtroscomunicacion_familia', familiaApi) : sessionStorage.setItem('filtroscomunicacion_familia', null);
            (numeroserieApi !== null) ? sessionStorage.setItem('filtroscomunicacion_comunicacion', numeroserieApi) : sessionStorage.setItem('filtroscomunicacion_comunicacion', null);

            await obtenerTablaBitacoras(familiaApi, tipoEquipoApi, paisApi, localidadApi, plantaApi, numeroserieApi, estadoApi, condicionApi, priv, paisUser, localidadUser, token)
            .then((response) => {                    
                if(response.cod === 1) obtenerBitacoras(response.bitacoras);    
                else obtenerBitacoras(null);
            });

            await obtenerTabla(familiaApi, tipoEquipoApi, paisApi, localidadApi, plantaApi, numeroserieApi, estadoApi, condicionApi, priv, paisUser, localidadUser, token)
            .then((response) => {    
                
                if(response.cod === 1)
                {
                    aplicarFiltro(response.equipos);
                }
                else {
                    aplicarFiltro(null);
                    swal ("Search Error" , response.mensaje , "warning");
                }
                
                load(false);
            });
        }
    }

    const cambioFamilia = (valor) => {

        setFamilia(valor);

        if(valor !== null && valor !== undefined && valor !== ""){
            setFamilia(valor);
            filtrarTipoEquipo(valor.value);
        } 
        else{      
            setPais(null);      
            var array = [];
            if(valores.filtrotipoequipo.length > 0){
                valores.filtrolocalidad.map((p) => {
                    array.push({label: p.localidad, value: p.localidad});
                });  
                setLocalidades(array);  
            }
        }
    };

    function filtrarTipoEquipo(filtro)
    {
        var array = [];
        if(valores.filtrotipoequipo.length > 0){
            valores.filtrotipoequipo.map((p) => {
                if(p.familia === filtro) array.push({label: p.tipoequipo, value: p.tipoequipo});
            });   
            setTipoEquipo(null); 
            setTipoEquipos(array);           
        }
    }

    const cambioPais = (valor) => {

        if(valor !== null && valor !== undefined && valor !== ""){
            setPais(valor);
            filtrarLocalidad(valor.value);
        } 
        else{      
            setPais(null);      
            var array = [];
            if(valores.filtrolocalidad.length > 0){

                valores.filtrolocalidad.map((p) => {
                    array.push({label: p.localidad, value: p.localidad});
                });  
                setLocalidades(array);  
            }
        }
    };

    function filtrarLocalidad(filtro)
    {
        var array = [];
        if(valores.filtrolocalidad.length > 0){
            valores.filtrolocalidad.map((p) => {
                if(p.pais === filtro) array.push({label: p.localidad, value: p.localidad});
            });  

            setLocalidad(null);
            setLocalidades(array); 
        }
    }

    async function obtenerFiltrosCom(){
        await obtenerFiltros(token)
        .then((response) => {
            if(response.cod === 1)
            {
                const val = {          
                    "paises": response.paises,
                    "filtrolocalidad": response.filtrolocalidad,
                    "localidades": response.localidades,                    
                    "plantas": response.plantas,
                    "filtroplantas": response.filtroplantas,
                    "tiposequipos": response.tiposequipos,
                    "filtrotipoequipo": response.filtrotipoequipo,
                    "familias": response.familias,
                    "estados": response.estados,
                    "condiciones": response.condiciones
                }; 


                setEstados(Metodos.opcionesFiltros(val.estados));               
                setCondiciones(Metodos.opcionesFiltros(val.condiciones)); 
                setTipoEquipos(Metodos.opcionesFiltros(val.tiposequipos));
                setFamilias(Metodos.opcionesFiltros(val.familias));

                if(priv === "1"){
                    setLocalidades(Metodos.opcionesFiltrosValor(val.localidades, localidadUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser)); 
                    setPlantas(Metodos.opcionesFiltrosPlantaLocalidad(val.filtroplantas, localidadUser));                   
                }

                if(priv === "2"){
                    setLocalidades(Metodos.opcionesFiltrosLocalidad(val.filtroplantas, paisUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));  
                    setPlantas(Metodos.opcionesFiltrosPlantaPais(val.filtroplantas, paisUser));                   
                }

                if(priv === "3"){
                    setLocalidades(val.localidades = Metodos.opcionesFiltros(val.localidades));
                    setPaises(Metodos.opcionesFiltros(val.paises));     
                    setPlantas(Metodos.opcionesFiltros(val.plantas));  
                }

                setValores(val);
            }
        }); 
    }

    function filtrosAnteriores()
    {
        let filtroscomunicacion_familia = sessionStorage.getItem('filtroscomunicacion_familia');       
        if(filtroscomunicacion_familia !== "null") setFamilia({label: filtroscomunicacion_familia, value: filtroscomunicacion_familia})

        let filtroscomunicacion_tipo = sessionStorage.getItem('filtroscomunicacion_tipo');        
        if(filtroscomunicacion_tipo !== "null") setTipoEquipo({label: filtroscomunicacion_tipo, value: filtroscomunicacion_tipo})       

        let filtroscomunicacion_estado = sessionStorage.getItem('filtroscomunicacion_estado');        
        if(filtroscomunicacion_estado !== "null") setEstado({label: filtroscomunicacion_estado, value: filtroscomunicacion_estado})

        let filtroscomunicacion_condicion = sessionStorage.getItem('filtroscomunicacion_condicion');        
        if(filtroscomunicacion_condicion !== "null") setCondicion({label: filtroscomunicacion_condicion, value: filtroscomunicacion_condicion})

        let filtroscomunicacion_pais = sessionStorage.getItem('filtroscomunicacion_pais');        
        if(filtroscomunicacion_pais !== "null") setPais({label: filtroscomunicacion_pais, value: filtroscomunicacion_pais})

        let filtroscomunicacion_localidad = sessionStorage.getItem('filtroscomunicacion_localidad');        
        if(filtroscomunicacion_localidad !== "null") setLocalidad({label: filtroscomunicacion_localidad, value: filtroscomunicacion_localidad})        
        
        let filtroscomunicacion_planta = sessionStorage.getItem('filtroscomunicacion_planta');        
        if(filtroscomunicacion_planta !== "null") setPlanta({label: filtroscomunicacion_planta, value: filtroscomunicacion_planta})        
        
        let filtroscomunicacion_comunicacion = sessionStorage.getItem('filtroscomunicacion_comunicacion');        
        if(filtroscomunicacion_comunicacion !== "null") setNumeroSerie(filtroscomunicacion_comunicacion)
    }

    React.useEffect(() =>{
        if(valores === null) obtenerFiltrosCom();

        filtrosAnteriores()
    }, []);

    if(valores === null) return (<></>)
    else return (
        <>
            <FilaContainerFiltros>
            <ContainerFiltros>
                
                <FiltroBaseNuevo
                    opciones={familias}
                    valor={familia}
                    nombre={"Family"}
                    namecampo="familia"
                    cambio={cambioFamilia}
                    proporcion={"20%"}
                    error={errorFamilia}
                />

                <FiltroBaseNuevo
                    opciones={tipoEquipos}
                    valor={tipoEquipo}
                    nombre={"Equipment Type"}
                    namecampo="tipoequipo"
                    cambio={cambioTipoEquipo}
                    proporcion={"20%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={estados}
                    valor={estado}
                    nombre={"State"}
                    namecampo="estado"
                    cambio={cambioEstado}
                    proporcion={"20%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={condiciones}
                    valor={condicion}
                    nombre={"Condition"}
                    namecampo="condicion"
                    cambio={cambioCondicion}
                    proporcion={"20%"}
                    error={false}
                />
                
                <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>
                    <input className="bootstrap-input-filtro" value={numeroserie} type='text' onChange={(e) => setNumeroSerie(e.target.value)} placeholder={"Serial / Service / Hostname / IP"}  />
                </div>   
            </ContainerFiltros>             
            <ContainerFiltros>            
                <FiltroBaseNuevo
                    opciones={paises}
                    valor={pais}
                    nombre={"Country"}
                    namecampo="pais"
                    cambio={cambioPais}
                    proporcion={"20%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={localidades}
                    valor={localidad}
                    nombre={"Location"}
                    namecampo="localidad"
                    cambio={cambioLocalidad}
                    proporcion={"20%"}
                    error={false}
                />

                <FiltroBaseNuevo
                    opciones={plantas}
                    valor={planta}
                    nombre={"Plant"}
                    namecampo="planta"
                    cambio={cambioPlanta}
                    proporcion={"40.4%"}
                    error={false}
                />

                <div style={{width: "20%", marginLeft: '10px', marginRight: 'auto',}}>
                    <Button onClick={obtenerRegistros}>Display</Button>
                </div>
                
            </ContainerFiltros>
            </FilaContainerFiltros>
            <br></br>            
            <hr style={{width: '90%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
        </>
    )
}

export default FiltrosTablaComunicaciones
