import React, {useState} from 'react'
import swal from 'sweetalert';
import * as Opciones from './OpcionesTraduccion'
import Loading from '../../Loading';
import Select from 'react-select'
import '../../css/traducciones.css'
import { obtenerTabla, eliminarTraduccion, actualizarTraduccion } from '../../functions/FuncTraduccion'
import TablaTraducciones from './TablaTraducciones';
import CreacionTraduccion from './CreacionTraduccion';

import { styleNormal } from '../../Commons/Styles'

const SelectorModulo = ({token}) => {

    const [modulo, setModulo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [filtro, setFiltro] = useState("");

    const [rows, setRows] = useState(null);
    const [id, setId] = useState("");
    const [nombre, setNombre] = useState("");
    const [spanish, setSpanish] = useState("");
    const [english, setEnglish] = useState("");
    const [portuguese, setPortuguese] = useState("");
    const [errorNombre, setErrorNombre] = useState(false);
    const [errorSpanish, setErrorSpanish] = useState(false);
    const [errorEnglish, setErrorEnglish] = useState(false);
    const [errorPortuguese, setErrorPortuguese] = useState(false);

    const [errorSpanishModal, setErrorSpanishModal] = useState(false);
    const [errorEnglishModal, setErrorEnglishModal] = useState(false);
    

    function cambiarModulo(value){
        setRows(null);
        setErrorNombre(false);
        setErrorSpanish(false);
        setErrorEnglish(false);
        setModulo(value);
    }

    function cambioFiltro(value)
    {        
        setFiltro(value);
    }

    function abrirModal(val_spanish, val_english, val_por, val_id, val_nombre){
        setSpanish(val_spanish);
        setEnglish(val_english);
        setPortuguese(val_por);
        setNombre(val_nombre);
        setId(val_id);
        setModalOpen(true);
    }  
    
    function cerrarModal(){
        setSpanish("");
        setEnglish("");
        setPortuguese("");
        setId("");
        setNombre("");
        setModalOpen(false);
    }

    async function eliminar(id, nombre){
        swal({
            title: "Delete confirmation",
            text: "Do you want to delete the Translate " + nombre + " ? ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async(afirmacion) => {
            if(afirmacion){
                await eliminarTraduccion(id, token).
                then((response) => {
                    if(response.cod === 1){
                        obtenerTablaModulo();
                        swal("Successful delete", "", "success");
                    }
                    else if(response.cod === 0){
                        swal("Delete error", response.mensaje, "error");
                    }
                    else {
                        swal("Delete error", "An error occurred during the update. Please contact the administrator.", "error");
                    }
                    
                })
            }
        })
    }

    async function actualizar(data, id){
        if(validarModal(data)){
            swal("Update Error", "Mandatory fields were submitted within the form with no value", "warning");
        }
        else {
            swal({
                title: "Update confirmation",
                text: "Do you want to update the registry? ",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion)
                {
                    await actualizarTraduccion(id, data.spanish, data.english, data.portuguese, modulo, nombre, token).then((response) => {

                        console.log(response)

                        if(response.cod === 1)
                        {
                            creacionFinalizada();                                                  
                            swal("Successful update", "", "success")
                            .then(() => {
                                cerrarModal();
                            });
                        }
                        else if(response.cod === 0){
                            swal("Update error", response.mensaje, "error");
                        }
                        else {
                            swal("Update error", "An error occurred during the update. Please contact the administrator.", "error");
                        }
                    });  

                }
            })
        }
    }

    function validarModal(data){
        var error = false;

        if(data.spanish === "" || data.spanish === null || data.spanish === undefined){
            setErrorSpanishModal(true);
            error = true;
        } else setErrorSpanishModal(false);

        if(data.english === "" || data.english === null || data.english === undefined){
            setErrorEnglishModal(true);
            error = true;
        } else setErrorEnglishModal(false);

        if(data.portuguese === "" || data.portuguese === null || data.portuguese === undefined){
            setErrorPortuguese(true);
            error = true;
        } else setErrorPortuguese(false);

        return error;
    }

    function aplicarFiltro(registros)
    {        
        if(registros == null) setRows(registros); 
        else if(registros.length === 0)
        {
            setRows(registros)            
            swal ("There are no records" ,
                    "No information is recorded with the filters used" ,
                    "info");
        }
        else setRows(registros);
    }

    async function obtenerTablaModulo(){

        await obtenerTabla(modulo, filtro, token)
        .then((response) => {
            if(response.cod === 1){
                if(response.traducciones === null) setRows(null);
                else if (response.traducciones.lenght === 0) setRows(null);
                else setRows(response.traducciones);
            }
            else console.log(response.mensaje)
        })

    }

    function creacionFinalizada(){        
        obtenerTablaModulo();
    }

    function validarDatos(data){

        var error = false;

        if(data.nombre === "" || data.nombre === null || data.nombre === undefined){
            setErrorNombre(true);
            error = true;
        } else setErrorNombre(false);

        if(data.spanish === "" || data.spanish === null || data.spanish === undefined){
            setErrorSpanish(true);
            error = true;
        } else setErrorSpanish(false);

        if(data.english === "" || data.english === null || data.english === undefined){
            setErrorEnglish(true);
            error = true;
        } else setErrorEnglish(false);

        if(data.portuguese === "" || data.portuguese === null || data.portuguese === undefined){
            setErrorPortuguese(true);
            error = true;
        } else setErrorPortuguese(false);

        return error;
    }

    React.useEffect(() => {

        if(modulo !== null) {
            setLoading(true);
            obtenerTablaModulo();
            setLoading(false);
        }

    }, [modulo])

    return (
        <>
            <div className="div-selector">
                <div className="div-selector-options">
                    <Select
                        options={Opciones.modulos}
                        onChange={(value) => {
                            cambiarModulo(value);
                        }}
                        placeholder="Select Module"
                        isClearable={false}                         
                        defaultValue={null}
                        styles={styleNormal}
                    />
                </div>
            </div>
            <br></br>
            <br></br>
            <div className="div-traducciones-base">
            {
                (loading) ? (<Loading />)
                : (
                    (modulo === null) ? (<></>) :
                    (
                        <>
                            <div className="divizq-traducciones">
                                <CreacionTraduccion  
                                    modulo={modulo} 
                                    errorNombre={errorNombre}
                                    errorSpanish={errorSpanish}
                                    errorEnglish={errorEnglish}
                                    errorPortuguese={errorPortuguese}
                                    token={token}
                                    validarDatos={validarDatos}
                                    creacionFinalizada={creacionFinalizada}
                                />
                            </div>
                            <div className="divder-traducciones">
                                <div className="container-traducciones">
                                    <TablaTraducciones 
                                        modulo={modulo} 
                                        token={token} 
                                        rows={rows} 
                                        aplicarFiltro={aplicarFiltro} 
                                        filtro={filtro} 
                                        cambioFiltro={cambioFiltro}
                                        eliminar={eliminar}
                                        actualizar={actualizar}
                                        modalOpen={modalOpen}
                                        abrirModal={abrirModal}
                                        cerrarModal={cerrarModal}
                                        errorSpanish={errorSpanishModal}
                                        errorEnglish={errorEnglishModal}
                                        errorPortuguese={errorPortuguese}
                                        spanishModal={spanish}
                                        englishModal={english}
                                        portugueseModal={portuguese}
                                        idModal={id}
                                    /> 
                                </div>
                            </div>
                        </>
                    )
                )
            }
            </div>
            
        </>
    )
}

export default SelectorModulo
