import React from 'react'
import swal from 'sweetalert';
import { obtenerValores, obtenerTabla, obtenerTablaBitacoras } from '../functions/FuncAplicacionBack'
import FiltroBaseNuevo from '../FiltroBaseNuevo';
import  * as Metodos from '../functions/MetodosComunes'

import { ContainerFiltros, FilaContainerFiltros, Button } from '../Commons/Styles'

const FiltroTablaAplicacionBack = ({aplicarFiltro, obtenerBitacoras, load, token}) => {
  
    let priv = sessionStorage.getItem('appback_leer');
    let paisUser = sessionStorage.getItem('pais');
    let idiomaUser = sessionStorage.getItem('idioma');

    const [valores, setValores] = React.useState(null);

    const [estados, setEstados] = React.useState(null);
    const [estado, setEstado] = React.useState(null);

    const [paises, setPaises] = React.useState(null);
    const [pais, setPais] = React.useState(null);

    const [negocios, setNegocios] = React.useState(null);
    const [negocio, setNegocio] = React.useState(null);

    const [aplicacionback, setAplicacionback] = React.useState("");
    const [id_filtro, setidfiltro] = React.useState("");


    const cambioEstado = (valor) => {
        setEstado(valor);
    };

    const cambioPais = (valor) => {
        setPais(valor);
    };

    const cambioNegocio = (valor) => {
        setNegocio(valor);
    };

    async function obtenerValoresBd()
    {
        await obtenerValores("APPLICATIONBACK", idiomaUser, token)
        .then((response) => {
            if(response.cod === 1)
            {
                const val = {          
                    "paises": response.paises,                    
                    "estados": response.estados,
                    "negocios": response.negocios
                };

                setEstados(Metodos.opcionesFiltros(val.estados));
                setNegocios(Metodos.opcionesFiltros(val.negocios));

                if(priv === "2") setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));
                else setPaises(Metodos.opcionesFiltros(val.paises));

                setValores(val);
            }
            else {
                console.log(response);
            }
        });
    }

    async function obtenerRegistros()
    {
        load(true);
        aplicarFiltro(null);

        var paisApi = (pais !== null && pais !== undefined && pais !== "") ? pais.value : null;       
        var estadoApi = (estado !== null && estado !== undefined && estado !== "") ? estado.value : null;
        var negocioApi = (negocio !== null && negocio !== undefined && negocio !== "") ? negocio.value : null;       
        var aplicacionApi = (aplicacionback !== null && aplicacionback !== undefined && aplicacionback !== "") ? aplicacionback : null;
        var idfiltroApi = (id_filtro !== null && id_filtro !== undefined && id_filtro !== "") ? id_filtro : null;

        await obtenerTablaBitacoras(aplicacionApi, paisApi, negocioApi, estadoApi, idfiltroApi, priv, paisUser, token)
        .then((response) => {            
            if (response.cod === 1) obtenerBitacoras(response.bitacoras);            
            else obtenerBitacoras(null);    
        });

        await obtenerTabla(aplicacionApi, paisApi, negocioApi, estadoApi, idfiltroApi, priv, paisUser, token)
        .then((response) => {
            if(response.cod === 1){
                aplicarFiltro(response.aplicaciones);
            }
            else {
                aplicarFiltro(null);
                swal ("Search Error" , response.mensaje , "warning");
                console.log(response);
            }

            load(false);
        });
    }

    React.useEffect(() =>{
        if(valores === null) obtenerValoresBd();       
    }, []);

    if(valores === null) return (<></>)
    else return (
        <>
            <FilaContainerFiltros>
                <ContainerFiltros>
                    <FiltroBaseNuevo
                        opciones={estados}
                        valor={estado}
                        nombre={"State"}
                        namecampo="estado"
                        cambio={cambioEstado}
                        proporcion={"18%"}
                        error={false}
                    />

                    <FiltroBaseNuevo
                        opciones={paises}
                        valor={pais}
                        nombre={"Country"}
                        namecampo="pais"
                        cambio={cambioPais}
                        proporcion={"18%"}
                        error={false}
                    />

                    <FiltroBaseNuevo
                        opciones={negocios}
                        valor={negocio}
                        nombre={"Business"}
                        namecampo="negocio"
                        cambio={cambioNegocio}
                        proporcion={"18%"}
                        error={false}
                    />

                    <div style={{width: "18%", marginLeft: '10px', marginRight: 'auto',}}>
                        <input className="bootstrap-input-filtro" value={aplicacionback} type='text' onChange={(e) => setAplicacionback(e.target.value)} placeholder={"Application BO"}  />
                    </div>

                    <div style={{width: "10%", marginLeft: '10px', marginRight: 'auto',}}>
                        <input className="bootstrap-input-filtro" value={id_filtro} type='text' onChange={(e) => setidfiltro(e.target.value)} placeholder={"ID"}  />
                    </div>

                    <div style={{width: "18%", marginLeft: '10px', marginRight: 'auto',}}>
                        <Button onClick={obtenerRegistros}>Display</Button>
                    </div>
                </ContainerFiltros>
            </FilaContainerFiltros>
            <br></br>            
            <hr style={{width: '90%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
        </>
    )
}

export default FiltroTablaAplicacionBack