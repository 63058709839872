export const opcionesFiltrosLocalidad = (arreglo, pais) => {
    var array = [];

    if(arreglo.length > 0){
        arreglo.map((p) => {            
            if(!array.some(e => e.value === p.localidad)){
                if(p.pais === pais) array.push({label: p.localidad, value: p.localidad});
            }            
        });
    }

    return array;
}

export const opcionesFiltrosPlantaPais = (arreglo, pais) => {
    var array = [];

    if(arreglo.length > 0){
        arreglo.map((p) => {
            if(p.pais === pais) array.push({label: p.planta, value: p.planta});
        });
    }

    return array;
}

export const opcionesFiltrosPlantaLocalidad = (arreglo, localidad) => {
    var array = [];

    if(arreglo.length > 0){
        arreglo.map((p) => {
            if(p.localidad === localidad) array.push({label: p.planta, value: p.planta});
        });  
    }

    return array;
}

export const opcionesFiltrosValor = (arreglo, valor) => {
    var array = [];

    if(arreglo.length > 0){
        arreglo.map((p) => {
            if(p === valor) array.push({label: p, value: p});
        });   
    }

    return array;
}

export const opcionesFiltros = (arreglo) => {
    var array = [];
    if(arreglo.length > 0){
        arreglo.map((p) => array.push({label: p, value: p}));
    }
    return array;
}

export const construirFecha = (fecha) => {
    if (fecha === null || fecha === undefined || fecha === "") return null
    const fechaInicial = new Date(fecha);

    const day = ((fechaInicial.getDate()) < 10) ? "0" + (fechaInicial.getDate()) : (fechaInicial.getDate());
    const month = ((fechaInicial.getMonth() + 1) < 10) ? "0" + (fechaInicial.getMonth() + 1) : (fechaInicial.getMonth() + 1);
    const year = fechaInicial.getFullYear();

    return year + "/" + month + "/" + day;
}

export const validarTextoVacio = (valor) => {

    if(valor === "") return false;
    if(valor === null) return false;
    if(valor === undefined) return false;

    return true;
}

export const validarEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };