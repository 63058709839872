import * as Rutas from '../RutasServicios'

async function bitacoraServidor(data, dataAnterior, id_registro, responsable, token){
    let camposValores = [];
    let cambios = null;

    if(data.fileserver !== null) if(data.fileserver !== dataAnterior.fileserver) camposValores.push({campo: "File Server", valor_actual: (data.fileserver === true) ? "YES": "NO", valor_anterior: (dataAnterior.fileserver === true) ? "YES": "NO"});
    if(data.printserver !== null) if(data.printserver !== dataAnterior.printserver) camposValores.push({campo: "Print Server", valor_actual: (data.printserver === true) ? "YES": "NO", valor_anterior: (dataAnterior.printserver === true) ? "YES": "NO"});
    if(data.webserver !== null) if(data.webserver !== dataAnterior.webserver) camposValores.push({campo: "Web Server", valor_actual: (data.webserver === true) ? "YES": "NO", valor_anterior: (dataAnterior.webserver === true) ? "YES": "NO"});
    if(data.aplicaciones !== null) if(data.aplicaciones !== dataAnterior.aplicaciones) camposValores.push({campo: "Applications", valor_actual: (data.aplicaciones === true) ? "YES": "NO", valor_anterior: (dataAnterior.aplicaciones === true) ? "YES": "NO"});
    if(data.servidorbd !== null) if(data.servidorbd !== dataAnterior.servidorbd) camposValores.push({campo: "DB Server", valor_actual: (data.servidorbd === true) ? "YES": "NO", valor_anterior: (dataAnterior.servidorbd === true) ? "YES": "NO"});
    if(data.respaldoserver !== null) if(data.respaldoserver !== dataAnterior.respaldoserver) camposValores.push({campo: "Server Backup", valor_actual: (data.respaldoserver === true) ? "YES": "NO", valor_anterior: (dataAnterior.respaldoserver === true) ? "YES": "NO"});
    if(data.respaldodata !== null) if(data.respaldodata !== dataAnterior.respaldodata) camposValores.push({campo: "Data Backup", valor_actual: (data.respaldodata === true) ? "YES": "NO", valor_anterior: (dataAnterior.respaldodata === true) ? "YES": "NO"});
    if(data.pachadosccm !== null) if(data.pachadosccm !== dataAnterior.pachadosccm) camposValores.push({campo: "SCCM Patching", valor_actual: (data.pachadosccm === true) ? "YES": "NO", valor_anterior: (dataAnterior.pachadosccm === true) ? "YES": "NO"});
    if(data.drp !== null) if(data.drp !== dataAnterior.drp) camposValores.push({campo: "DRP", valor_actual: (data.drp === true) ? "YES": "NO", valor_anterior: (dataAnterior.drp === true) ? "YES": "NO"});
    
    cambios = validaCambioSelect(data.plataforma, dataAnterior.plataforma, "Network");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.servidor, dataAnterior.servidor, "Server");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.descripcion, dataAnterior.descripcion, "Description");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.localidad, dataAnterior.localidad, "Location");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.pais, dataAnterior.pais, "Country");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.propiedadde, dataAnterior.propiedadde, "Property of");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.negocio, dataAnterior.negocio, "Business");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.estado, dataAnterior.estado, "State");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.criticidad, dataAnterior.criticidad, "Criticality");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.ambiente, dataAnterior.ambiente, "Environment");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.administradortexto, dataAnterior.administradortexto, "Administrator");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.ipservidor, dataAnterior.ipservidor, "Server IP");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.tiposervidor, dataAnterior.tiposervidor, "Server Type");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.soservidor, dataAnterior.soservidor, "Operating System");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.antivirus, dataAnterior.antivirus, "Antivirus");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.versionservidor, dataAnterior.versionservidor, "Version WebServer");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.tipobd, dataAnterior.tipobd, "Type DB");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.ubicacionrespaldoserver, dataAnterior.ubicacionrespaldoserver, "Ubication Server Backup");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.ubicacionrespaldodata, dataAnterior.ubicacionrespaldodata, "Ubication data Backup");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.marca, dataAnterior.marca, "Brand");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.vlan, dataAnterior.vlan, "VLAN");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.numeroserie, dataAnterior.numeroserie, "Serial Number");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioFecha(data.fechahabilitacion, dataAnterior.fechahabilitacion, "Enable Date");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioFecha(data.fechabaja, dataAnterior.fechabaja, "Down Date");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioFecha(data.fechaexpiracion, dataAnterior.fechaexpiracion, "Warranty Expiration Date");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioFecha(data.ultimoreinicio, dataAnterior.ultimoreinicio, "Last Restart Date");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.comentario, dataAnterior.comentario, "Observation");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.informara, dataAnterior.informara, "Inform To");
    if(cambios !== null) camposValores.push(cambios);
    
    cambios = validaCambioSelect(data.tiporeinicio, dataAnterior.tiporeinicio, "Type Reset");
    if(cambios !== null) camposValores.push(cambios);

    //cambios = validaCambioText(data.umbral, dataAnterior.umbral, "Threshold");
    //if(cambios !== null) camposValores.push(cambios); 

    //cambios = validaCambioFecha(data.fechadrp, dataAnterior.fechadrp, "DRP Date");
    //if(cambios !== null) camposValores.push(cambios);

    const request = {
        id_registro: id_registro,
        tipo_registro: "Servidor",
        responsable: responsable,
        valoresCampos: camposValores
    }

    if(camposValores.length === 0) return {"mensaje": "Sin cambios", "cod": 0}

    const response = await enviarRequest(request, token);

    return response;   

}

async function bitacoraDispositivoOT(data, dataAnterior, id_registro, responsable, token){
    let camposValores = [];
    let cambios = null;

    if(cambios !== null) validaCambioSelect.push(cambios);
    cambios = validaCambioSelect(data.tipoactivo, dataAnterior.tipoactivo, "Asset Type");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.dispositivoot, dataAnterior.dispositivoot, "Device Name");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.numeroserie, dataAnterior.numeroserie, "Serial Number / Tag");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.descripcion, dataAnterior.descripcion, "Description");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.ambiente, dataAnterior.ambiente, "Environment");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.criticidad, dataAnterior.criticidad, "Criticality");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.ubicacion, dataAnterior.ubicacion, "Ubication");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.zona, dataAnterior.zona, "Zone");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.piso, dataAnterior.piso, "Floor");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.gabinete, dataAnterior.gabinete, "Cabinet or Rack");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.propiedadde, dataAnterior.propiedadde, "Property of");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.plantaindustrial, dataAnterior.plantaindustrial, "Industrial Plant");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.proveedor, dataAnterior.proveedor, "Vendor");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.modelo, dataAnterior.modelo, "Model");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.sodispositivoot, dataAnterior.sodispositivoot, "Operating System");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.firmware, dataAnterior.firmware, "Firmware Version");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.tiposervidor, dataAnterior.tiposervidor, "Server Type");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.antivirus, dataAnterior.antivirus, "Antivirus");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.arearesponsable, dataAnterior.arearesponsable, "Responsable Area");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.contactoresponsable, dataAnterior.contactoresponsable, "Responsable Contact");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.estado, dataAnterior.estado, "State");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.tipoparchado, dataAnterior.tipoparchado, "Patching Type");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.observacion, dataAnterior.observacion, "Observation");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.tipobd, dataAnterior.tipobd, "Type DB");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.tiporespaldo, dataAnterior.tiporespaldo, "Backup Type");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.tiporespaldofrecuencia, dataAnterior.tiporespaldofrecuencia, "Backup Frequency Type");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.tipopuerto, dataAnterior.tipopuerto, "Port Type");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.nombrered, dataAnterior.nombrered, "Network Name");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.idvlan, dataAnterior.idvlan, "ID VLAN");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.direccionip, dataAnterior.direccionip, "IP Address");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.direccionred, dataAnterior.direccionred, "Network Address");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioText(data.direccionmac, dataAnterior.direccionmac, "MAC Address");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.tipoaccesoremoto, dataAnterior.tipoaccesoremoto, "Remote Access Type");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.accesoremotoaplicacion, dataAnterior.accesoremotoaplicacion, "Remote Access Application");
    if(cambios !== null) camposValores.push(cambios);
    cambios = validaCambioSelect(data.protocolo, dataAnterior.protocolo, "Protocol");
    if(cambios !== null) camposValores.push(cambios);
    
    const request = {
        id_registro: id_registro,
        tipo_registro: "Dispositivo OT",
        responsable: responsable,
        valoresCampos: camposValores
    }

    if(camposValores.length === 0) return {"mensaje": "Sin cambios", "cod": 0}

    const response = await enviarRequest(request, token);

    return response;   

}

async function bitacoraAplicacion(data, dataAnterior, id_registro, responsable, token) {
    
    let camposValores = [];
    
    if(data.drp !== null) if(data.drp !== dataAnterior.drp) camposValores.push({campo: "DRP", valor_actual: (data.drp === true) ? "YES": "NO", valor_anterior: (dataAnterior.drp === true) ? "YES": "NO"});
    if(data.redundancia !== null) if(data.redundancia !== dataAnterior.redundancia) camposValores.push({campo: "Redundancy", valor_actual: (data.redundancia === true) ? "YES": "NO", valor_anterior: (dataAnterior.redundancia === true) ? "YES": "NO"});
    if(data.procedimientoadmin !== null) if(data.procedimientoadmin !== dataAnterior.procedimientoadmin) camposValores.push({campo: "Administration Procedure", valor_actual: (data.procedimientoadmin === true) ? "YES": "NO", valor_anterior: (dataAnterior.procedimientoadmin === true) ? "YES": "NO"});
    if(data.respaldos !== null) if(data.respaldos !== dataAnterior.respaldos) camposValores.push({campo: "Backrests", valor_actual: (data.respaldos === true) ? "YES": "NO", valor_anterior: (dataAnterior.respaldos === true) ? "YES": "NO"});

    let cambios = null;

    cambios = validaCambioSelect(data.ambiente, dataAnterior.ambiente, "Environment");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.version, dataAnterior.version, "Version");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.tiposoftware, dataAnterior.tiposoftware, "Type of Application");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.estado, dataAnterior.estado, "State");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.pais, dataAnterior.pais, "Country");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.negocio, dataAnterior.negocio, "Business");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.responsable, dataAnterior.responsable, "Responsable");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.gerencia, dataAnterior.gerencia, "Owner");
    if(cambios !== null) camposValores.push(cambios);
    
    cambios = validaCambioSelect(data.sistemaoperativo, dataAnterior.sistemaoperativo, "Operating System");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.plataforma, dataAnterior.plataforma, "Client Platform");
    if(cambios !== null) camposValores.push(cambios);    
    
    cambios = validaCambioSelect(data.plataformapublicacion, dataAnterior.plataformapublicacion, "Publishing Platform");
    if(cambios !== null) camposValores.push(cambios);
    
    cambios = validaCambioSelect(data.criticidad, dataAnterior.criticidad, "Criticality");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.proveedor, dataAnterior.proveedor, "Provider");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.fabricante, dataAnterior.fabricante, "Fabricator");
    if(cambios !== null) camposValores.push(cambios)    
    
    cambios = validaCambioSelect(data.lenguaje, dataAnterior.lenguaje, "Language");
    if(cambios !== null) camposValores.push(cambios) 

    cambios = validaCambioSelect(data.basedatos, dataAnterior.basedatos, "Database");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.versionbd, dataAnterior.versionbd, "DB Version");
    if(cambios !== null) camposValores.push(cambios)
    
    cambios = validaCambioText(data.link, dataAnterior.link, "Link");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioText(data.numerocontrato, dataAnterior.numerocontrato, "Contract number");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.tipolicencia, dataAnterior.tipolicencia, "Type of license");
    if(cambios !== null) camposValores.push(cambios)
    
    cambios = validaCambioFecha(data.fechafactura, dataAnterior.fechafactura, "Invoice date");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioFecha(data.vencimientocontrato, dataAnterior.vencimientocontrato, "Expiration date");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioText(data.numerofactura, dataAnterior.numerofactura, "Purchase invoice number");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioText(data.numerolicencia, dataAnterior.numerolicencia, "Number of Licenses");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.autenticacion, dataAnterior.autenticacion, "Authentication Mechanism");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioText(data.centralizada, dataAnterior.centralizada, "Centralized / Distributed");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioText(data.observaciones, dataAnterior.observaciones, "Prerequisites and Observations");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioText(data.adminacceso, dataAnterior.adminacceso, "Access Manager");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioFecha(data.fechadrp, dataAnterior.fechadrp, "DRP Test Date");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioFecha(data.fecharespaldos, dataAnterior.fecharespaldos, "Backrests Test Date");
    if(cambios !== null) camposValores.push(cambios)

    const request = {
        id_registro: id_registro,
        tipo_registro: "Aplicacion",
        responsable: responsable,
        valoresCampos: camposValores
    }

    if(camposValores.length === 0) return {"mensaje": "Sin cambios", "cod": 0}

    const response = await enviarRequest(request, token);

    return response;   
}

async function bitacoraAplicacionBack(data, dataAnterior, id_registro, responsable, token) {
    
    let camposValores = [];    
   
    let cambios = null;    

    cambios = validaCambioText(data.version, dataAnterior.version, "Version");
    if(cambios !== null) camposValores.push(cambios);   

    cambios = validaCambioSelect(data.estado, dataAnterior.estado, "State");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.pais, dataAnterior.pais, "Country");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.negocio, dataAnterior.negocio, "Business");
    if(cambios !== null) camposValores.push(cambios);    

    cambios = validaCambioText(data.gerencia, dataAnterior.gerencia, "Owner");
    if(cambios !== null) camposValores.push(cambios);  

    cambios = validaCambioSelect(data.tipolicencia, dataAnterior.tipolicencia, "Type of license");
    if(cambios !== null) camposValores.push(cambios)
    
    cambios = validaCambioFecha(data.fechafactura, dataAnterior.fechafactura, "Invoice date");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioFecha(data.vencimientocontrato, dataAnterior.vencimientocontrato, "Expiration date");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioText(data.numerofactura, dataAnterior.numerofactura, "Purchase invoice number");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioText(data.numerolicencia, dataAnterior.numerolicencia, "Number of Licenses");
    if(cambios !== null) camposValores.push(cambios)    

    cambios = validaCambioText(data.informara, dataAnterior.informara, "Inform To");
    if(cambios !== null) camposValores.push(cambios);   

    cambios = validaCambioText(data.observacion, dataAnterior.observacion, "Observation");
    if(cambios !== null) camposValores.push(cambios);  

    const request = {
        id_registro: id_registro,
        tipo_registro: "AplicacionBack",
        responsable: responsable,
        valoresCampos: camposValores
    }

    if(camposValores.length === 0) return {"mensaje": "Sin cambios", "cod": 0}

    const response = await enviarRequest(request, token);

    return response;   
}

async function bitacoraEquiposEscritorio(data, dataAnterior, id_registro, responsable, token) {
    
    let camposValores = [];    
   
    let cambios = null;  

    console.log(data.division)
    console.log(dataAnterior.division)
    
    if(data.excluidoparches !== null) if(data.excluidoparches !== dataAnterior.excluidoparches) camposValores.push({campo: "Excluded patches", valor_actual: (data.excluidoparches === true) ? "YES": "NO", valor_anterior: (dataAnterior.excluidoparches === true) ? "YES": "NO"});
    if(data.usbdesbloqueado !== null) if(data.usbdesbloqueado !== dataAnterior.usbdesbloqueado) camposValores.push({campo: "Unlocked USB", valor_actual: (data.usbdesbloqueado === true) ? "YES": "NO", valor_anterior: (dataAnterior.usbdesbloqueado === true) ? "YES": "NO"});
    if(data.docking !== null) if(data.docking !== dataAnterior.docking) camposValores.push({campo: "Docking", valor_actual: (data.docking === true) ? "YES": "NO", valor_anterior: (dataAnterior.docking === true) ? "YES": "NO"});
    if(data.monitor !== null) if(data.monitor !== dataAnterior.monitor) camposValores.push({campo: "Monitor", valor_actual: (data.monitor === true) ? "YES": "NO", valor_anterior: (dataAnterior.monitor === true) ? "YES": "NO"});
    if(data.vip !== null) if(data.vip !== dataAnterior.vip) camposValores.push({campo: "VIP", valor_actual: (data.vip === true) ? "YES": "NO", valor_anterior: (dataAnterior.vip === true) ? "YES": "NO"});
    if(data.administrador !== null) if(data.administrador !== dataAnterior.administrador) camposValores.push({campo: "Administrator", valor_actual: (data.administrador === true) ? "YES": "NO", valor_anterior: (dataAnterior.administrador === true) ? "YES": "NO"});

    cambios = validaCambioText(data.motivoexcluidoparches, dataAnterior.motivoexcluidoparches, "Cause Patches");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioText(data.motivousbdesbloqueado, dataAnterior.motivousbdesbloqueado, "Cause USB");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.estado, dataAnterior.estado, "State");
    if(cambios !== null) camposValores.push(cambios);   

    cambios = validaCambioSelect(data.condicion, dataAnterior.condicion, "Condition");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.empresa, dataAnterior.empresa, "Enterprise");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.planta, dataAnterior.planta, "Plant");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.tipoequipo, dataAnterior.tipoequipo, "Desktop Type");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.marca, dataAnterior.marca, "Brand");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.modelo, dataAnterior.modelo, "Model");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioText(data.nombreequipo, dataAnterior.nombreequipo, "Desktop Name");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.condicionred, dataAnterior.condicionred, "Network Condition");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.ordencompra, dataAnterior.ordencompra, "Purchase order");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioText(data.direccionip, dataAnterior.direccionip, "IP Address");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.discoduro, dataAnterior.discoduro, "HDD");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.memoriaram, dataAnterior.memoriaram, "Ram");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelect(data.tipoprocesador, dataAnterior.tipoprocesador, "Processor Type");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioFecha(data.fecharecepcion, dataAnterior.fecharecepcion, "Reception Date");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioFecha(data.fechaentrega, dataAnterior.fechaentrega, "Delivery Date");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioFecha(data.fechaentrega, dataAnterior.fechaentrega, "Warranty Expiration Date");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioFecha(data.fechamantencion, dataAnterior.fechamantencion, "Maintenance Date");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioFecha(data.fechafueralinea, dataAnterior.fechafueralinea, "Offline Date");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioText(data.ubicacion, dataAnterior.ubicacion, "Ubication");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioTextDatosUser(data.idusuario, dataAnterior.idusuario, "User ID");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioTextDatosUser(data.nombreusuario, dataAnterior.nombreusuario, "User Name");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioTextDatosUser(data.correo, dataAnterior.correo, "Email");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelectDatosUser(data.division, dataAnterior.division, "Division");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioTextDatosUser(data.divisionplanta, dataAnterior.divisionplanta, "Division Plant");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioTextDatosUser(data.divisionfecha, dataAnterior.divisionfecha, "Copy Date");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelectDatosUser(data.gerenciaprincipal, dataAnterior.gerenciaprincipal, "Principal Management");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelectDatosUser(data.gerenciadirecta, dataAnterior.gerenciadirecta, "Direct Management");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelectDatosUser(data.subgerenciasarea, dataAnterior.subgerenciasarea, "Area Sub Management");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelectDatosUser(data.cargo, dataAnterior.cargo, "Position");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioTextDatosUser(data.cargorol, dataAnterior.cargorol, "Position Role");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioSelectDatosUser(data.centrocosto, dataAnterior.centrocosto, "Cost Center");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioTextDatosUser(data.licenciaprincipal, dataAnterior.licenciaprincipal, "O365 License");
    if(cambios !== null) camposValores.push(cambios)

    cambios = validaCambioText(data.justificacionadmin, dataAnterior.justificacionadmin, "Administrator Justification");
    if(cambios !== null) camposValores.push(cambios)



    const request = {
        id_registro: id_registro,
        tipo_registro: "EquipoEscritorio",
        responsable: responsable,
        valoresCampos: camposValores
    }

    if(camposValores.length === 0) return {"mensaje": "Sin cambios", "cod": 0}

    const response = await enviarRequest(request, token);

    return response;   
}

async function bitacoraOtroDispositivo(data, dataAnterior, id_registro, responsable, token) {
    
    let camposValores = [];    
   
    let cambios = null; 

    cambios = validaCambioSelect(data.estado, dataAnterior.estado, "State");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.condicion, dataAnterior.condicion, "Condition");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.propiedadde, dataAnterior.propiedadde, "Property of");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.empresa, dataAnterior.empresa, "Enterprise");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.planta, dataAnterior.planta, "Plant/Office");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.marca, dataAnterior.marca, "Brand");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.modelo, dataAnterior.modelo, "Model");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.descripcion, dataAnterior.descripcion, "Description / User");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.mac, dataAnterior.mac, "MAC Address");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.ipequipo, dataAnterior.ipequipo, "IP / Number");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioFecha(data.fechacompra, dataAnterior.fechacompra, "Purchase Date");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioFecha(data.fechaarriendo, dataAnterior.fechaarriendo, "Lease Date");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioFecha(data.fecharevision, dataAnterior.fecharevision, "Review Date");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.condicionrevision, dataAnterior.condicionrevision, "Review Condition");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.observaciones, dataAnterior.observaciones, "Observations");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.hostname, dataAnterior.hostname, "Hostname");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.arrendadoa, dataAnterior.arrendadoa, "Leased to");
    if(cambios !== null) camposValores.push(cambios);      
    
    cambios = validaCambioSelect(data.sistemaoperativo, dataAnterior.sistemaoperativo, "Operating System");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.version, dataAnterior.version, "Operating System Version");
    if(cambios !== null) camposValores.push(cambios);

    const request = {
        id_registro: id_registro,
        tipo_registro: "OtroDispositivo",
        responsable: responsable,
        valoresCampos: camposValores
    }

    if(camposValores.length === 0) return {"mensaje": "Sin cambios", "cod": 0}

    const response = await enviarRequest(request, token);

    return response;   
}

async function bitacoraComunicacion(data, dataAnterior, id_registro, responsable, token) {
    
    let camposValores = [];    
   
    let cambios = null; 

    console.log(data.fechaentrega)
    console.log(dataAnterior.fechaentrega)

    cambios = validaCambioSelect(data.usabilidad, dataAnterior.usabilidad, "Usability");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.tipoequipo, dataAnterior.tipoequipo, "Equipment Type");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.numeroserie, dataAnterior.numeroserie, "Serial / Service Number");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.estado, dataAnterior.estado, "State");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.condicion, dataAnterior.condicion, "Condition");
    if(cambios !== null) camposValores.push(cambios); 
    
    cambios = validaCambioSelect(data.empresa, dataAnterior.empresa, "Enterprise");
    if(cambios !== null) camposValores.push(cambios);  

    cambios = validaCambioSelect(data.planta, dataAnterior.planta, "Plant/Office");
    if(cambios !== null) camposValores.push(cambios); 

    cambios = validaCambioText(data.direccion, dataAnterior.direccion, "Address");
    if(cambios !== null) camposValores.push(cambios);
   
    cambios = validaCambioSelect(data.servicio, dataAnterior.servicio, "Service");
    if(cambios !== null) camposValores.push(cambios); 

    cambios = validaCambioSelect(data.proveedor, dataAnterior.proveedor, "Provider");
    if(cambios !== null) camposValores.push(cambios); 

    cambios = validaCambioSelect(data.tipoenlace, dataAnterior.tipoenlace, "Link Type");
    if(cambios !== null) camposValores.push(cambios); 
    
    cambios = validaCambioText(data.anchobanda, dataAnterior.anchobanda, "Bandwidth");
    if(cambios !== null) camposValores.push(cambios);
 
    cambios = validaCambioText(data.ipequipo, dataAnterior.ipequipo, "IP");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.descripcion, dataAnterior.descripcion, "Description");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.hostname, dataAnterior.hostname, "Hostname");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioText(data.ordencompra, dataAnterior.ordencompra, "Purchase order");
    if(cambios !== null) camposValores.push(cambios);

    cambios = validaCambioSelect(data.marca, dataAnterior.marca, "Brand");
    if(cambios !== null) camposValores.push(cambios); 

    cambios = validaCambioSelect(data.modelo, dataAnterior.modelo, "Model");
    if(cambios !== null) camposValores.push(cambios); 

    cambios = validaCambioFecha(data.fechaentrega, dataAnterior.fechaentrega, "Delivery Date");
    if(cambios !== null) camposValores.push(cambios);       
    
    const request = {
        id_registro: id_registro,
        tipo_registro: "Comunicacion",
        responsable: responsable,
        valoresCampos: camposValores
    }

    if(camposValores.length === 0) return {"mensaje": "Sin cambios", "cod": 0}

    const response = await enviarRequest(request, token);
    console.log(response)
    return response;   
}


function validaCambioSelect(actual, anterior, campo)
{
    if(actual === null || actual === undefined) actual = null;
    if(anterior === null || anterior === undefined) anterior = null;

    if(actual === null && anterior === null) return null;
    if(actual === null && anterior !== null) return {campo: campo, valor_actual: "", valor_anterior: anterior.value}
    if(actual !== null && anterior === null) return {campo: campo, valor_actual: actual.value, valor_anterior: ""}

    if(actual.value.toString().toUpperCase() !== anterior.value.toString().toUpperCase()) 
        return {campo: campo, valor_actual: actual.value, valor_anterior: anterior.value}
    else return null;
}

function validaCambioSelectDatosUser(actual, anterior, campo)
{

    if(actual === "" && anterior !== null && anterior !== undefined) 
    {
        if(anterior.value === "") return null;
    }

    if(actual === null && anterior !== null && anterior !== undefined) 
    {
        if(anterior.value === "") return null;
    }

    if(actual === null || actual === undefined) actual = null;
    if(anterior === null || anterior === undefined) anterior = null;

    if(actual === null && anterior === null) return null;
    if(actual === null && anterior !== null) return {campo: campo, valor_actual: "", valor_anterior: anterior.value}
    if(actual !== null && anterior === null) return {campo: campo, valor_actual: actual.value, valor_anterior: ""}

    if(actual.value.toString().toUpperCase() !== anterior.value.toString().toUpperCase()) 
        return {campo: campo, valor_actual: actual.value, valor_anterior: anterior.value}
    else return null;
}

function validaCambioText(actual, anterior, campo)
{
    if(actual === null || actual === undefined) actual = null;
    if(anterior === null || anterior === undefined) anterior = null;

    if(actual === null && anterior === null) return null;
    if(actual === null && anterior !== null) return {campo: campo, valor_actual: "", valor_anterior: anterior}
    if(actual !== null && anterior === null) return {campo: campo, valor_actual: actual, valor_anterior: ""}

    if(actual.toString().toUpperCase() !== anterior.toString().toUpperCase()) 
        return {campo: campo, valor_actual: actual, valor_anterior: anterior}
    else return null;
}

function validaCambioTextDatosUser(actual, anterior, campo)
{
    
    if(actual === "" && anterior === undefined) return null;

    if(actual === null || actual === undefined) actual = null;
    if(anterior === null || anterior === undefined) anterior = null;

    if(actual === null && anterior === null) return null;
    if(actual === null && anterior !== null) return {campo: campo, valor_actual: "", valor_anterior: anterior}
    if(actual !== null && anterior === null) return {campo: campo, valor_actual: actual, valor_anterior: ""}

    if(actual.toString().toUpperCase() !== anterior.toString().toUpperCase()) 
        return {campo: campo, valor_actual: actual, valor_anterior: anterior}
    else return null;
}

function validaCambioFecha(actual, anterior, campo)
{
    if(actual === null || actual === undefined) actual = null;
    if(anterior === null || anterior === undefined) anterior = null;

    if(actual === null && anterior === null) return null;
    if(actual === null && anterior !== null) return {campo: campo, valor_actual: "", valor_anterior: new Date(anterior).toISOString().substring(0, 10).replace("-", "/").replace("-", "/")}
    if(actual !== null && anterior === null) return {campo: campo, valor_actual: new Date(actual).toISOString().substring(0, 10).replace("-", "/").replace("-", "/"), valor_anterior: ""}

    if(actual.toString().toUpperCase() !== anterior.toString().toUpperCase()) 
        return {campo: campo, valor_actual: new Date(actual).toISOString().substring(0, 10).replace("-", "/").replace("-", "/"), valor_anterior: new Date(anterior).toISOString().substring(0, 10).replace("-", "/").replace("-", "/")}
    else return null;
}

async function bitacoraBase(id_registro, tipo_registro, responsable, campo, valor_anterior, valor_actual, token)
{
    const request = {
        id_registro: id_registro,
        tipo_registro: tipo_registro,
        responsable: responsable,
        valoresCampos: [{
            campo: campo, 
            valor_actual: valor_actual, 
            valor_anterior: valor_anterior
        }]
    }

    const response = await enviarRequest(request, token);
    return response;
}

async function enviarRequest(request, token) {

    const url = Rutas.URL_Bitacora + "crear"; 

    var bRequest = JSON.stringify(request); 

    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

async function eliminarBitacorasRegistro(id, tipo, token) {

    const url = Rutas.URL_Bitacora + "eliminacionporregistro";
    //const url = "https://localhost:44377/api/bitacora/eliminacionporregistro";

    const request = {
        id_registro: id,
        tipo_registro: tipo
    }

    var brequest = JSON.stringify(request);

    var respuesta = await fetch(url, {
        method: "POST",
        body: brequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    var convertido = await respuesta.json();
    var res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

export async function eliminarBitacorasRegistroRequest(id, tipo, token) {
    try {
        var respuesta = await eliminarBitacorasRegistro(id, tipo, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

export async function registrarBitacoraBase(id_registro, tipo_registro, responsable, campo, valor_anterior, valor_actual, token) {
    try {
        var respuesta = await bitacoraBase(id_registro, tipo_registro, responsable, campo, valor_anterior, valor_actual, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

export async function registrarBitacoraServidor(data, dataAnterior, id_registro, responsable, token) {
    try {
        var respuesta = await bitacoraServidor(data, dataAnterior, id_registro, responsable, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

export async function registrarBitacoraDispositivoOT(data, dataAnterior, id_registro, responsable, token) {
    try {
        var respuesta = await bitacoraDispositivoOT(data, dataAnterior, id_registro, responsable, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

export async function registrarBitacoraComunicacion(data, dataAnterior, id_registro, responsable, token) {
    try {
        var respuesta = await bitacoraComunicacion(data, dataAnterior, id_registro, responsable, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}


export async function registrarBitacoraAplicacion(data, dataAnterior, id_registro, responsable, token) {
    try {
        var respuesta = await bitacoraAplicacion(data, dataAnterior, id_registro, responsable, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

export async function registrarBitacoraAplicacionBack(data, dataAnterior, id_registro, responsable, token) {
    try {
        var respuesta = await bitacoraAplicacionBack(data, dataAnterior, id_registro, responsable, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

export async function registrarBitacoraEquipoEscritorio(data, dataAnterior, id_registro, responsable, token) {
    try {
        var respuesta = await bitacoraEquiposEscritorio(data, dataAnterior, id_registro, responsable, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

export async function registrarBitacoraOtroDispositivo(data, dataAnterior, id_registro, responsable, token) {
    try {
        var respuesta = await bitacoraOtroDispositivo(data, dataAnterior, id_registro, responsable, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}

async function bitacoras(id_registro, tipo_registro, year, month, day, campo, token) {

    const url = Rutas.URL_Bitacora + "tabla"; 

    const request = {
        "id_registro": id_registro,
        "tipo_registro": tipo_registro,
        "year": (year === null || year === undefined) ? "" : year,
        "month": (month === null || month === undefined) ? "" : month,
        "day": (day === null || day === undefined) ? "" : day,
        "campo": (campo === null || campo === undefined) ? "" : campo
    };

    const bRequest = JSON.stringify(request); 

    const respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    const convertido = await respuesta.json();
    let res = null;

    if(convertido != null){        
        res = convertido;
    }

    return res;
}

export async function obtenerBitacoras(id_registro, tipo_registro, year, month, day, campo, token) {
    try {
        var respuesta = await bitacoras(id_registro, tipo_registro, year, month, day, campo, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": error, "cod": 3};
    }
}