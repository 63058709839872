export const modulos = [ 
    {label: "APPLICATION", value: "APPLICATION"},
    {label: "APPLICATION BO", value: "APPLICATION BO"},
    {label: "COMUNICATION", value: "COMUNICATION"},
    {label: "DESKTOP", value: "DESKTOP"},
    {label: "OTHER DEVICE", value: "OTHER DEVICE"}, 
    {label: "SERVER", value: "SERVER"},   
    {label: "SERVER ROOM", value: "SERVER ROOM"},
    {label: "ACCOUNT SERVICE", value: "ACCOUNT SERVICE"},
    {label: "CERTIFICATE", value: "CERTIFICATE"} 
]