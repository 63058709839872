import React, {useState, useEffect} from 'react'
import { obtenerTabla } from '../../functions/FuncAplicacionPermitida';
import * as TiIcons from 'react-icons/ti';
import styled from 'styled-components';
import Modal from 'react-modal'

import * as BsIcons from 'react-icons/bs';

import TablaModalAplicacionPermitida from './TablaModalAplicacionPermitida';

import { ButtonVolver } from '../../Commons/Styles'

Modal.setAppElement('#root');

const ButtonRelacion = styled.button`
    width: 60px;    
    height: 55px;
    background-color: #6294D8;
    color: white;
    font-size: 20px;
    border-radius: 5px;  
    border-color: #2E70C8;
    cursor: pointer;   
    float:right; 

    :hover {
        background: #7DAACC;
    }
`;

const ModalAplicacionPermitida = ({elegirAplicacion, token}) => {

    const [registros, setRegistros] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const elegirApp = (valor) => {
        elegirAplicacion(valor);
        cerrarModal();
    }

    const obtenerDatos = async () => {
        await obtenerTabla(token).then((response) => {
            if(response.cod === 1) setRegistros(response.aplicacionespermitidas);
        })
    }

    const abrirModal = () => setModalOpen(true);
    const cerrarModal = () => setModalOpen(false);

    useEffect(() => {
        obtenerDatos()
    }, []);

  return (
    <>
        {
            (registros === null) ? (<></>)
            : (
                <>
                    <ButtonRelacion type="button" onClick={abrirModal}>
                        <BsIcons.BsFillCollectionFill />
                    </ButtonRelacion>            
                    <Modal 
                        isOpen={modalOpen}
                        style={{
                            overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.75)'
                            },
                            content: {
                                width: '80%',
                                height: '60vh',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                position: 'absolute',                        
                                border: '1px solid #ccc',
                                background: '#fff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '5px'
                            }
                        }}
                    >
                        <div style={{width: '100%'}}> 
                            <div className="bodymodal">   
                                <ButtonVolver onClick={cerrarModal} className="tooltipboton">
                            <TiIcons.TiArrowBack/>             
                            <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver> 
                                <div className='containerappper'>                               
                                    <TablaModalAplicacionPermitida rows={registros} elegirApp={elegirApp}/>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
            )
        }
    
    
    
    </>
  )
}

export default ModalAplicacionPermitida