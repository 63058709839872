export const campos = [
    //"propietariored",
    "servidor",
    "pais",
    "localidad",
    "ambiente",
    "estado",
    "administrador",
    "responsable",
    "negocio",
    "plataforma",
    "soservidor",
    "tiposervidor",
    "ipservidor",
    "ippublica",
    
    "fileserver",
    "printserver",
    "webserver",
    "aplicaciones",
    "servidorbd",
    "respaldodata",
    "respaldoserver",
    "ubicacionrespaldodata",
    "ubicacionrespaldoserver",
    "pachadosccm",
    
    "versionservidor",
    "criticidad",
    "antivirus",
    "tipobd",
    "marca",
    "modelo",
    "vlan",
    "numeroserie",
    "fechahabilitacion",
    "fechabaja",
    "fechaexpiracion",
    "descripcion",
    "informara",
    "tiporeinicio",
    "ultimoreinicio",
    //"umbral",
    //"drp",
    //"fechadrp",

    "modificadopor",
    "fechaupdate",
    "comentario",
    "administradortexto",
    "propiedadde",
    "fechasccm"
]

export const camposExcel = [
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"LOCATION", valor:"localidad"},
    {nombre:"SERVER", valor:"servidor"},
    {nombre:"BUSINESS", valor:"negocio"},
    {nombre:"DESCRIPTION", valor:"descripcion"},
    {nombre:"NETWORK", valor:"plataforma"},

    {nombre:"STATE", valor:"estado"},
    {nombre:"ENVIRONMENT", valor:"ambiente"},
    {nombre:"SERVER TYPE", valor:"tiposervidor"},

    {nombre:"IP", valor:"ipservidor"},
    {nombre:"PUBLIC IP", valor:"ippublica"},
    {nombre:"OPERATING SYSTEM", valor:"soservidor"},
    {nombre:"RESPONSABLE", valor:"responsable"},

    {nombre:"CRITICALITY", valor:"criticidad"},
    {nombre:"ANTIVIRUS", valor:"antivirus"},
    {nombre:"FILESERVER", valor:"fileserver"},
    {nombre:"PRINTSERVER", valor:"printserver"},
    {nombre:"WEB", valor:"webserver"},
    {nombre:"APPLICATIONS", valor:"aplicaciones"},
    {nombre:"DB SERVER", valor:"servidorbd"},
    {nombre:"OBSERVATION", valor:"comentario"},
    {nombre:"DATA BACKUP", valor:"respaldodata"},
    {nombre:"UBICATION DATA BACKUP", valor:"ubicacionrespaldodata"},
    {nombre:"SERVER BACKUP", valor:"respaldoserver"},
    {nombre:"UBICATION SERVER BACKUP", valor:"ubicacionrespaldoserver"},

    {nombre:"SCCM PATCHING", valor:"pachadosccm"},
    {nombre:"PROPERTY OF", valor:"propiedadde"},
    //{nombre:"Administrador", valor:"administrador"},
    {nombre:"ADMINISTRATOR", valor:"administradortexto"},
    {nombre:"VERSION WEB SERVICE", valor:"versionservidor"},
    {nombre:"TYPE DB", valor:"tipobd"},
    {nombre:"BRAND", valor:"marca"},
    {nombre:"VLAN", valor:"vlan"},
    {nombre:"SERIAL NUMBER", valor:"numeroserie"},
    {nombre:"ENABLE DATE", valor:"fechahabilitacion"},
    {nombre:"DOWN DATE", valor:"fechabaja"},
    {nombre:"WARRANTY EXPIRATION DATE", valor:"fechaexpiracion"},
    {nombre:"INFORM TO", valor:"informara"},
    {nombre:"TYPE RESET THRESHOLD", valor:"tiporeinicio"},
    {nombre:"LAST RESTART DATE", valor:"ultimoreinicio"},
    //{nombre:"THRESHOLD", valor:"umbral"},
    //{nombre:"DRP", valor:"drp"},
    //{nombre:"DRP DATE", valor:"fechadrp"},
    {nombre:"MEMORY RAM", valor:"memoriaramsccm"},
    {nombre:"FREE SPACE", valor:"espaciolibresccm"},
    {nombre:"TOTAL SPACE", valor:"espaciototalsccm"},
    {nombre:"DISK SPACE", valor:"espaciodiscossccm"},
    {nombre:"ID SERVER", valor:"id_servidor"},
    {nombre:"MODIFIED BY", valor:"modificadopor"},
    {nombre:"MODIFICATION DATE", valor:"fechaupdate"},
    {nombre:"SCCM DATE", valor:"fechasccm"},
    //{nombre:"NET OWNER", valor:"propietariored"}
]

export const camposExcelAplicaciones = [
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"LOCATION", valor:"localidad"},
    {nombre:"SERVER", valor:"servidor"},
    {nombre:"BUSINESS", valor:"negocio"},
    {nombre:"DESCRIPTION", valor:"descripcion"},
    {nombre:"NETWORK", valor:"plataforma"},
    {nombre:"STATE", valor:"estado"},
    {nombre:"ENVIRONMENT", valor:"ambiente"},
    {nombre:"SERVER TYPE", valor:"tiposervidor"},
    {nombre:"IP", valor:"ipservidor"},
    {nombre:"OPERATING SYSTEM", valor:"soservidor"},
    {nombre:"SERIAL NUMBER", valor:"numeroserie"},
    {nombre:"APPLICATION", valor:"aplicacion"},
    {nombre:"VERSION", valor:"version"},
    {nombre:"ENVIRONMENT APP", valor:"ambienteapp"},
    {nombre:"BUSINESS APP", valor:"negocioapp"}
]

export const camposExcelBitacora = [
    {nombre:"ID SERVER", valor:"id_registro"},
    {nombre:"ID LOG", valor:"id_bitacora"},
    {nombre:"IOD MODIFICATION", valor:"id_modificacion"},
    {nombre:"DATE", valor:"fecha"},
    {nombre:"RESPONSABLE", valor:"responsable"},
    {nombre:"FIELD", valor:"campo"},
    {nombre:"AFTER VALUE", valor:"valor_anterior"},
    {nombre:"BEFORE VALUE", valor:"valor_actual"}    
]
