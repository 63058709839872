import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';


import styled from 'styled-components';
import { makeStyles, useTheme, withStyles  } from '@material-ui/core/styles';

const headCells = [
    { id: 'nombre', numeric: false, disablePadding: true, label: 'Account', size: '40%' },
    { id: 'negocio', numeric: false, disablePadding: true, label: 'Business', size: '30%' },
    { id: 'responsable', numeric: false, disablePadding: true, label: 'Responsable', size: '30%' }
];

const ContainerTabla = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
`;

const ContainerBusqueda = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
`;

const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.white,
    },
    body: {
      textDecoration: 'none',
      fontSize: 12,
    },
}))(TableCell);

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const TablaCuentas = ({rows}) => {

    const [filter, setFilter] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('nombre');
    const classes = useStyles();

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.nombre !== "") ? (row.nombre.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.negocio !== "") ? (row.negocio.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.responsable !== "") ? (row.responsable.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
          
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function stableSort(array) {
        if(order === 'desc')
        {
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] > b[orderBy]) ? 1 : (b[orderBy] > a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
        else{
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] < b[orderBy]) ? 1 : (b[orderBy] < a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
    }

    return (
        <>
        <ContainerBusqueda>                                    
            <TextField 
                style={{backgroundColor: '#fff'}}
                id="outlined-search" 
                label="Search" 
                size="small" 
                type="search" 
                variant="outlined" 
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
            />                            
        </ContainerBusqueda>
        <ContainerTabla>        
        <TableContainer component={Paper}>
            <Table                
                size="small" 
                aria-label="enhanced table"
            >
                <TableHead style={{ background: '#696158', color: '#fff' }}>
                    <TableRow>
                        {headCells.map((headCell) => (
                             <StyledTableCell 
                                key={headCell.id}
                                align={'center'}   
                                sortDirection={orderBy === headCell.id ? order : false}
                                style={{ width: headCell.size, color: '#fff !important' }}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                    style={{ background: '#696158', color: '#fff' }}
                                >
                                    {headCell.label}
                                </TableSortLabel>                                        
                            </StyledTableCell>
                                ))}                                
                    </TableRow>
                </TableHead>
                <TableBody>
                {  stableSort(buscar(rows))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)                                    
                    .map((row) => (
                                        
                        <TableRow hover key={row.id_cuentaservicio} style={{ textDecoration: 'none', fontSize: '10px !important' }}>
                            <StyledTableCell component={Link} to={`/cuentasservicio/${row.id_cuentaservicio}`} align="left">{row.nombre}</StyledTableCell> 
                            <StyledTableCell component={Link} to={`/cuentasservicio/${row.id_cuentaservicio}`} align="left">{row.negocio}</StyledTableCell> 
                            <StyledTableCell component={Link} to={`/cuentasservicio/${row.id_cuentaservicio}`} align="left">{row.responsable}</StyledTableCell>  
                        </TableRow>
                    ))
                    
                } 
                </TableBody>
            </Table>
            <TablePagination  style={{ display: 'flex'}}
                rowsPerPageOptions={[5]}                
                colSpan={3}
                count={rows.length}
                rowsPerPage={5}
                page={page}                                
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </TableContainer>
        </ContainerTabla>
        </>
    )
}

export default TablaCuentas