import React, {Fragment, useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import { obtenerValores, crearSala } from '../functions/FuncSalaServidor'

import "../css/formularioAplicacion.css";
import "../css/formulariosala.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import Loading from '../Loading';
import Select from 'react-select'

import { style, styleNormal } from '../Commons/Styles'

const CreacionSalaServidor = ({token}) => {

    const history = useHistory();
    const [registro, setRegistro] = useState(null);    
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const [empresa, setEmpresa] = useState("");
    const [planta, setPlanta] = useState(""); 

    let privilegio = sessionStorage.getItem('sala_crear');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');
    let idiomaUser = sessionStorage.getItem('idioma');

    const [errorNombre, setErrorNombre] = useState(false);
    const [errorEmpresa, setErrorEmpresa] = useState(false);
    const [errorPlanta, setErrorPlanta] = useState(false);
    const [errorExtincionIndencios, setErrorExtincionIndencios] = useState(false);
    const [errorEquiposAire, setErrorEquiposAire] = useState(false);
    const [errorCapacidadAreaBlanca, setErrorCapacidadAreaBlanca] = useState(false);

    async function obtenerDatosBD()
    {
        await obtenerValores(privilegio, paisUser, localidadUser, "SERVER ROOM", idiomaUser, token)
        .then((response) => {
            
            if(response.cod === 1)
            {
                const val = {
                    
                    "empresas": response.empresas,
                    "plantas": response.plantas,
                    "filtroplantas": response.filtroplantas,
                    "marcas": response.marcas,
                    "traducciones": response.traducciones
                }; 

                let array = [];

                const listas = ["empresas","plantas","marcas"];

                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });

                setValores(val);
            }
        });
    }

    const cambioEmpresa= (value) => { 
        if(value !== "" && value !== null){
            setEmpresa(value.value);            
            filtrarPlantas(value.value);            
        } else {
            setEmpresa(""); 
            setPlanta("");           
        }
    }

    const cambioPlanta= (value) => { 
        if(value !== "" && value !== null){
            setPlanta({"label": value.value, "value": value.value});
        } else {
            setPlanta("");
        }
    }

    function filtrarPlantas(empresa)
    {
        if(empresa !== "")
        {
            const filtrotipoArray = valores.filtroplantas;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.empresa === empresa) {
                        array.push({"label": p.planta, "value": p.planta});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.plantas = array; 
            setPlanta("");           
            setValores(valoresNuevo);
        }
    }

    const onSubmit = (data) => {        
        if(validarDatos(data)){
            swal("Form data", "Mandatory data must be entered", "warning");
        }
        else{
            swal({
                title: "Creation confirmation",
                text: "Do you want to create the record?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion)
                {
                    const datosform = data;
                    let nombreusuario = sessionStorage.getItem('nombre');
                    datosform.modificadopor = nombreusuario;
                    datosform.fechaupdate = new Date();

                    await crearSala(datosform, token).then((response) => {

                        console.log(response);

                        if(response.cod === 1)
                        {
                            swal("Successful creation", "", "success").then(() => {
                                history.push("/salaservidor/"+response.id);
                            });
                        }
                        else swal("Creation error", "" + response.mensaje, "warning");
                    });   
                }
            })
        }
    }

    function validarDatos(data)
    {
        var errores = false;       
        
        if(data.nombre === null || data.nombre === "" || data.nombre === undefined) 
        {
            setErrorNombre(true);
            errores = true;
        }
        else setErrorNombre(false);

        if(data.empresa === null || data.empresa === "" || data.empresa === undefined) 
        {
            setErrorEmpresa(true);
            errores = true;
        }
        else setErrorEmpresa(false);

        if(data.planta === null || data.planta === "" || data.planta === undefined) 
        {
            setErrorPlanta(true);
            errores = true;
        }
        else setErrorPlanta(false);

        if(data.extincionincendios === null || data.extincionincendios === "" || data.extincionincendios === undefined) 
        {
            setErrorExtincionIndencios(true);
            errores = true;
        }
        else setErrorExtincionIndencios(false);

        if(data.equiposaire === null || data.equiposaire === "" || data.equiposaire === undefined) 
        {
            setErrorEquiposAire(true);
            errores = true;
        }
        else setErrorEquiposAire(false);

        if(data.capacidadareablanca === null || data.capacidadareablanca === "" || data.capacidadareablanca === undefined) 
        {
            setErrorCapacidadAreaBlanca(true);
            errores = true;
        }
        else setErrorCapacidadAreaBlanca(false);

        return errores;
    }

    React.useEffect(() => {
        if (valores !== null) {

        }
        else obtenerDatosBD();
    }, []);


    if(valores === null) return (<Loading />)
    else return (
        <div>
            <form 
                style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                autoComplete="off" onSubmit={handleSubmit(onSubmit)}
            >
                <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}}>
                    <div className="divinfosala">
                        <div className="containersala">
                            <div className="divinfosala-izq">
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-tipoequipo">Room<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.g_nombre === "" || valores.traducciones.g_nombre === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_nombre}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                      
                                            control={control}
                                            id="i-nombre"
                                            key="k-nombre"
                                            defaultValue={registro ? registro.nombre : ''}
                                            name='nombre'
                                            style={(errorNombre) ? { borderColor: '#f90000'} : {}} 
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-empresa">Enterprise<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.g_empresa === "" || valores.traducciones.g_empresa === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_empresa}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                            options={valores.empresas}
                                            placeholder=""
                                            onChange={(value) => {
                                                props.onChange(value);
                                                cambioEmpresa(value);
                                                
                                            }}  
                                            isClearable={true}
                                            styles={(errorEmpresa) ? style : styleNormal}
                                            />} 
                                            control={control}
                                            id="i-empresa"
                                            key="k-empresa"                                    
                                            name='empresa'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-planta">Plant<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.g_planta === "" || valores.traducciones.g_planta === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_planta}</span>
                                                </div>
                                            )
                                        } 
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                                options={valores.plantas}
                                                placeholder=""
                                                onChange={(value) => {
                                                    props.onChange(value)
                                                    cambioPlanta(value);  
                                                    
                                                }}  
                                                isClearable={true}
                                                value={planta}
                                                isDisabled={(empresa !== "") ? false : true}
                                                styles={(errorPlanta) ? style : styleNormal}
                                            />} 
                                            control={control}
                                            id="i-planta"
                                            key="k-planta"                                    
                                            name='planta'
                                        />                                 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-extincionincendios">Fire Extinguishing System<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.g_extincionincendios === "" || valores.traducciones.g_extincionincendios === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_extincionincendios}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                      
                                            control={control}
                                            id="i-extincionincendios"
                                            key="k-extincionincendios"
                                            defaultValue={registro ? registro.extincionincendios : ''}
                                            name='extincionincendios'
                                            style={(errorExtincionIndencios) ? { borderColor: '#f90000'} : {}} 
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-equiposaire">Air Equipments<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.g_equiposaire === "" || valores.traducciones.g_equiposaire === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_equiposaire}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                      
                                            control={control}
                                            id="i-equiposaire"
                                            key="k-equiposaire"
                                            defaultValue={registro ? registro.nombre : ''}
                                            name='equiposaire'
                                            style={(errorEquiposAire) ? { borderColor: '#f90000'} : {}} 
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label style={{fontWeight: 'bold'}} htmlFor="i-capacidadareablanca">Available Area Capacity<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                            (valores.traducciones.g_capacidadareablanca === "" || valores.traducciones.g_capacidadareablanca === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_capacidadareablanca}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                      
                                            control={control}
                                            id="i-capacidadareablanca"
                                            key="k-capacidadareablanca"
                                            defaultValue={registro ? registro.capacidadareablanca : ''}
                                            name='capacidadareablanca'
                                            style={(errorCapacidadAreaBlanca) ? { borderColor: '#f90000'} : {}} 
                                        />                              
                                    </div>
                                </div>
                            </div> 
                            <div className="divinfosala-der">                        
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-grupoelectrogeno">Generator Group
                                        {
                                            (valores.traducciones.g_grupoelectrogeno === "" || valores.traducciones.g_grupoelectrogeno === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_grupoelectrogeno}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            type="checkbox"
                                            id="i-grupoelectrogeno"
                                            key="k-grupoelectrogeno" 
                                            name="grupoelectrogeno"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                              
                                    </div>
                                </div>   
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-deteccionincendio">Fire Detection System
                                        {
                                            (valores.traducciones.g_deteccionincendio === "" || valores.traducciones.g_deteccionincendio === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_deteccionincendio}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            type="checkbox"
                                            id="i-deteccionincendio"
                                            key="k-deteccionincendio" 
                                            name="deteccionincendio"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-alarmacentral">&nbsp; &nbsp; &nbsp; Connected to Central Alarm
                                        {
                                            (valores.traducciones.g_alarmacentral === "" || valores.traducciones.g_alarmacentral === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_alarmacentral}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            type="checkbox"
                                            id="i-alarmacentral"
                                            key="k-alarmacentral" 
                                            name="alarmacentral"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                              
                                    </div>
                                </div>      
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-sensortemperatura">Sensor Temperature
                                        {
                                            (valores.traducciones.g_sensortemperatura === "" || valores.traducciones.g_sensortemperatura === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_sensortemperatura}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            type="checkbox"
                                            id="i-sensortemperatura"
                                            key="k-sensortemperatura" 
                                            name="sensortemperatura"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                              
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-controlacceso">Access Control
                                        {
                                            (valores.traducciones.g_controlacceso === "" || valores.traducciones.g_controlacceso === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_controlacceso}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                      
                                            control={control}
                                            id="i-controlacceso"
                                            key="k-controlacceso"
                                            defaultValue={registro ? registro.controlacceso : ''}
                                            name='controlacceso'
                                        />                              
                                    </div>
                                </div> 
                                <br></br>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-observacion">Observation
                                        {
                                            (valores.traducciones.g_observacion === "" || valores.traducciones.g_observacion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.g_observacion}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<textarea className="bootstrap-input" rows="3" cols="50"></textarea>}
                                            control={control}
                                            id="i-observacion"
                                            key="k-observacion"
                                            defaultValue={registro ? registro.observacion : ''}
                                            name='observacion'
                                        />                             
                                    </div>                                
                                </div>
                            </div>               
                        </div>
                    </div>
                    <div className="divups">
                        <div className="containerups-up-l-c">
                            <div className="divups-izq">
                                <div className='subtitulo-box'>
                                    <div className='subtitulo'>
                                        <label >UPS 1</label>
                                    </div>                    
                                </div>
                                <br></br>

                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_uso">Use
                                        {
                                            (valores.traducciones.ups_uso === "" || valores.traducciones.ups_uso === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_uso}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                      
                                            control={control}
                                            id="i-ups1_uso"
                                            key="k-ups1_uso"
                                            defaultValue={registro ? registro.ups1_uso : ''}
                                            name='ups1_uso'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_marca">Brand
                                        {
                                            (valores.traducciones.ups_marca === "" || valores.traducciones.ups_marca === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_marca}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">                                        
                                        <Controller
                                            render={(props) => <Select 
                                            options={valores.marcas}
                                            placeholder=""
                                            onChange={(value) => {
                                                props.onChange(value);                                                
                                            }}  
                                            isClearable={true}
                                            />} 
                                            control={control}
                                            id="i-ups1_marca"
                                            key="k-ups1_marca"                                    
                                            name='ups1_marca'
                                        />                            
                                    </div>
                                </div>                                
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_capacidad">Capacity (KVA)
                                        {
                                            (valores.traducciones.ups_capacidad === "" || valores.traducciones.ups_capacidad === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_capacidad}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='number'  />}                                      
                                            control={control}
                                            id="i-ups1_capacidad"
                                            key="k-ups1_capacidad"
                                            defaultValue={registro ? registro.ups1_capacidad : ''}
                                            name='ups1_capacidad'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_autonomia">Autonomy (min)
                                        {
                                            (valores.traducciones.ups_autonomia === "" || valores.traducciones.ups_autonomia === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_autonomia}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='number'  />}                                      
                                            control={control}
                                            id="i-ups1_autonomia"
                                            key="k-ups1_autonomia"
                                            defaultValue={registro ? registro.ups1_autonomia : ''}
                                            name='ups1_autonomia'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_monitoreo">Monitoring
                                        {
                                            (valores.traducciones.ups_monitoreo === "" || valores.traducciones.ups_monitoreo === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_monitoreo}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            type="checkbox"
                                            id="i-ups1_monitoreo"
                                            key="k-ups1_monitoreo" 
                                            name="ups1_monitoreo"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_tarjetared">Network Card
                                        {
                                            (valores.traducciones.ups_tarjetared === "" || valores.traducciones.ups_tarjetared === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_tarjetared}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            type="checkbox"
                                            id="i-ups1_tarjetared"
                                            key="k-ups1_tarjetared" 
                                            name="ups1_tarjetared"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_direccionip">IP
                                        {
                                            (valores.traducciones.ups_direccionip === "" || valores.traducciones.ups_direccionip === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_direccionip}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                      
                                            control={control}
                                            id="i-ups1_direccionip"
                                            key="k-ups1_direccionip"
                                            defaultValue={registro ? registro.ups1_direccionip : ''}
                                            name='ups1_direccionip'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_fechapruebaups">Test Date
                                        {
                                            (valores.traducciones.ups_fechapruebaups === "" || valores.traducciones.ups_fechapruebaups === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_fechapruebaups}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            name="ups1_fechapruebaups"
                                            id="i-ups1_fechapruebaups"
                                            className="bootstrap-input"
                                            defaultValue={registro ? registro.ups1_fechapruebaups : ''}
                                            control={control}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className="bootstrap-input"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_fechaultimamantencion">Last Maintenance Date
                                        {
                                            (valores.traducciones.ups_fechaultimamantencion === "" || valores.traducciones.ups_fechaultimamantencion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_fechaultimamantencion}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            name="ups1_fechaultimamantencion"
                                            id="i-ups1_fechaultimamantencion"
                                            className="bootstrap-input"
                                            defaultValue={registro ? registro.ups1_fechaultimamantencion : ''}
                                            control={control}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className="bootstrap-input"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                            </div>
                            
                            <div className="divups-der">
                                <div className='subtitulo-box'>
                                    <div className='subtitulo'>
                                        <label >UPS 2</label>
                                    </div>                    
                                </div>
                                <br></br>

                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_uso">Use
                                        {
                                            (valores.traducciones.ups_uso === "" || valores.traducciones.ups_uso === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_uso}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                      
                                            control={control}
                                            id="i-ups2_uso"
                                            key="k-ups2_uso"
                                            defaultValue={registro ? registro.ups2_uso : ''}
                                            name='ups2_uso'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_marca">Brand
                                        {
                                            (valores.traducciones.ups_marca === "" || valores.traducciones.ups_marca === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_marca}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                            options={valores.marcas}
                                            placeholder=""
                                            onChange={(value) => {
                                                props.onChange(value);                                                
                                            }}  
                                            isClearable={true}
                                            />} 
                                            control={control}
                                            id="i-ups2_marca"
                                            key="k-ups2_marca"                                    
                                            name='ups2_marca'
                                        />                              
                                    </div>
                                </div>                                
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_capacidad">Capacity (KVA)
                                        {
                                            (valores.traducciones.ups_capacidad === "" || valores.traducciones.ups_capacidad === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_capacidad}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='number'  />}                                      
                                            control={control}
                                            id="i-ups2_capacidad"
                                            key="k-ups2_capacidad"
                                            defaultValue={registro ? registro.ups2_capacidad : ''}
                                            name='ups2_capacidad'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_autonomia">Autonomy (min)
                                        {
                                            (valores.traducciones.ups_autonomia === "" || valores.traducciones.ups_autonomia === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_autonomia}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='number'  />}                                      
                                            control={control}
                                            id="i-ups2_autonomia"
                                            key="k-ups2_autonomia"
                                            defaultValue={registro ? registro.ups2_autonomia : ''}
                                            name='ups2_autonomia'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_monitoreo">Monitoring
                                        {
                                            (valores.traducciones.ups_monitoreo === "" || valores.traducciones.ups_monitoreo === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_monitoreo}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            type="checkbox"
                                            id="i-ups2_monitoreo"
                                            key="k-ups2_monitoreo" 
                                            name="ups2_monitoreo"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_tarjetared">Network Card
                                        {
                                            (valores.traducciones.ups_tarjetared === "" || valores.traducciones.ups_tarjetared === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_tarjetared}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            type="checkbox"
                                            id="i-ups2_tarjetared"
                                            key="k-ups2_tarjetared" 
                                            name="ups2_tarjetared"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_direccionip">IP
                                        {
                                            (valores.traducciones.ups_direccionip === "" || valores.traducciones.ups_direccionip === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_direccionip}</span>
                                                </div>
                                            )
                                        }
                                        </label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                      
                                            control={control}
                                            id="i-ups2_direccionip"
                                            key="k-ups2_direccionip"
                                            defaultValue={registro ? registro.ups2_direccionip : ''}
                                            name='ups2_direccionip'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_fechapruebaups">Test Date
                                        {
                                            (valores.traducciones.ups_fechapruebaups === "" || valores.traducciones.ups_fechapruebaups === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_fechapruebaups}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            name="ups2_fechapruebaups"
                                            id="i-ups2_fechapruebaups"
                                            className="bootstrap-input"
                                            defaultValue={registro ? registro.ups2_fechapruebaups : ''}
                                            control={control}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className="bootstrap-input"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups2_fechaultimamantencion">Last Maintenance Date
                                        {
                                            (valores.traducciones.ups_fechaultimamantencion === "" || valores.traducciones.ups_fechaultimamantencion === null) ? (<></>)
                                            : (
                                                <div class="tooltip">&nbsp;&#x1F6C8;
                                                    <span class="tooltiptext">{valores.traducciones.ups_fechaultimamantencion}</span>
                                                </div>
                                            )
                                        }
                                        </label>
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            name="ups2_fechaultimamantencion"
                                            id="i-ups2_fechaultimamantencion"
                                            className="bootstrap-input"
                                            defaultValue={registro ? registro.ups2_fechaultimamantencion : ''}
                                            control={control}
                                            render={({ onChange, value }) => (
                                                <DatePicker
                                                    selected={value}
                                                    dateFormat="yyyy/MM/dd"
                                                    autoComplete="off"
                                                    onChange={onChange}
                                                    className="bootstrap-input"
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div>
                            </div>
                            
                            
                            {/* 
                            <div className="divups-der">
                                <div className='subtitulo-box'>
                                    <div className='subtitulo'>
                                        <label >UPS 3</label>
                                    </div>                    
                                </div>
                                <br></br>

                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups3_uso">Use</label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                      
                                            control={control}
                                            id="i-ups3_uso"
                                            key="k-ups3_uso"
                                            defaultValue={registro ? registro.ups3_uso : ''}
                                            name='ups3_uso'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups1_marca">Brand</label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            render={(props) => <Select 
                                            options={valores.marcas}
                                            placeholder=""
                                            onChange={(value) => {
                                                props.onChange(value);                                                
                                            }}  
                                            isClearable={true}
                                            />} 
                                            control={control}
                                            id="i-ups3_marca"
                                            key="k-ups3_marca"                                    
                                            name='ups3_marca'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups3_direccionip">IP</label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='text'  />}                                      
                                            control={control}
                                            id="i-ups3_direccionip"
                                            key="k-ups3_direccionip"
                                            defaultValue={registro ? registro.ups3_direccionip : ''}
                                            name='ups3_direccionip'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups3_capacidad">Capacity (KVA)</label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='number'  />}                                      
                                            control={control}
                                            id="i-ups3_capacidad"
                                            key="k-ups3_capacidad"
                                            defaultValue={registro ? registro.ups3_capacidad : ''}
                                            name='ups3_capacidad'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups3_autonomia">Autonomy (min)</label> 
                                    </div>
                                    <div className="col-75">
                                        <Controller
                                            as={<input className="bootstrap-input" type='number'  />}                                      
                                            control={control}
                                            id="i-ups3_autonomia"
                                            key="k-ups3_autonomia"
                                            defaultValue={registro ? registro.ups3_autonomia : ''}
                                            name='ups3_autonomia'
                                        />                              
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups3_monitoreo">Monitoring</label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            type="checkbox"
                                            id="i-ups3_monitoreo"
                                            key="k-ups3_monitoreo" 
                                            name="ups3_monitoreo"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                           
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-25">
                                        <label htmlFor="i-ups3_tarjetared">Network Card</label> 
                                    </div>
                                    <div className="col-75">
                                        <input
                                            type="checkbox"
                                            id="i-ups3_tarjetared"
                                            key="k-ups3_tarjetared" 
                                            name="ups3_tarjetared"
                                            placeholder="luo"
                                            value={true}
                                            ref={register}
                                        />                           
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>                    
                </div>
                <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto', marginTop: '20px', float: 'left'}}>                     
                    <div className="row">
                        <div style={{width: '20%', marginRight:'auto', marginLeft: 'auto'}}>
                            <button className="boton-from">Save</button>
                        </div>           
                    </div>
                    <br></br>                     
                </div>

            </form>            
        </div>
    )
}

export default CreacionSalaServidor
