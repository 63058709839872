import React from 'react'
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';
import { obtenerRegistroTablaPlanta } from '../../functions/FuncMaestroCompuesto'

const ContainerFiltros= styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
`;

const Button = styled.button`
    margin-left: auto;
    margin-right: auto;
    background-color: #EA7600;
    color: white;
    font-size: 20px;    
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    vertical-align: middle;
    color: white;
`;

const useStyles = makeStyles((theme) => ({
    formControl: {        
      marginLeft: '10px',
      marginRight: 'auto',
      minWidth: 50,
      width: '75%'
    },
    formControlBoton: {        
        marginLeft: '10px',
        marginRight: 'auto',
        minWidth: 50,
        width: '20%'
      },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));


const MaestroPlantaFiltro = ({maestro, aplicarFiltro, load, token, filtro, cambioFiltro}) => {
    
    const classes = useStyles();
    const [nombre, setNombre] = React.useState("");

    async function obtenerRegistros()
    {
        load(true);
        aplicarFiltro(null);
        await obtenerRegistroTablaPlanta(filtro, token)
        .then((response) => {  
            
            if(response.cod === 1)
            {
                aplicarFiltro(response.maestros);
            }
            else {
                aplicarFiltro(null);
                swal ("Search Error" , response.mensaje , "warning");
            }
            
            load(false);
        });
    }
    
    return (
        <>
           <ContainerFiltros>                
                <FormControl variant="outlined" className={classes.formControl}>  
                    {
                        <TextField 
                            id="outlined-basic" 
                            value={filtro} 
                            onChange={(e) => cambioFiltro(e.target.value)} 
                            label="Master" 
                            variant="outlined" 
                            style={{"backgroundColor": "white"}}
                        />  
                    } 
                </FormControl>
                <Button onClick={obtenerRegistros} className={classes.formControlBoton} style={{verticalAlign: 'middle'}}>Display</Button>
                <br></br>
                <br></br>
                <hr style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
            </ContainerFiltros> 
        </>
    )
}

export default MaestroPlantaFiltro
