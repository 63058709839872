import React, { useState } from 'react';
import { makeStyles, useTheme, withStyles  } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FiltrosMaestrosApp from './FiltrosMaestrosApp';
import ExportarExcel from '../../ExportarExcel';
import Loading from '../../Loading';
import swal from 'sweetalert';
import * as Opciones from './OpcionesMaestroApp';
import * as AiIcons from 'react-icons/ai';
import { useForm, Controller } from "react-hook-form";

import { obtenerFiltros, eliminarMaestroApp, actualizarMaestroApp } from '../../functions/FuncMaestroApp'
import Modal from 'react-modal'

import Select from 'react-select'

import { style, styleNormal, ButtonConfirmar, ButtonCancelar, ContainerBusqueda, ContainerTable } from '../../Commons/Styles'

Modal.setAppElement('#root');



const useStyles = makeStyles({
    table: {
      minWidth: 650,
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
}));

const headCells = [
    { id: 'nombre', numeric: false, disablePadding: true, label: 'Name', size: '25%' },
    { id: 'fabricante', numeric: false, disablePadding: true, label: 'Producer', size: '15%' },
    { id: 'modificadopor', numeric: false, disablePadding: true, label: 'Modified by', size: '20%' },
    { id: 'fechaupdate', numeric: false, disablePadding: true, label: 'Modification Date', size: '20%' },
    { id: 'cantidadapps', numeric: false, disablePadding: true, label: 'Applications', size: '10%' },
    { id: 'actualizacion', numeric: false, disablePadding: true, label: '', size: '5%' },
    { id: 'eliminacion', numeric: false, disablePadding: true, label: '', size: '5%' },
];

const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 12,
    },
}))(TableCell);

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const TablaMaestroAplicacion = ({token}) => {

    const [loading, setLoading] = React.useState(false); 
    const [modalOpen, setModalOpen] = useState(false);

    const [maestroUpdate, setMaestroUpdate] = useState(null);    

    const [rows, setRows] = useState(null);
    const [filter, setFilter] = useState("");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('nombre');

    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});
    const [registro, setRegistro] = useState(null);
    const [opcionesFabricante, setOpcionesFabricante] = useState(null);
    const [errorNombre, setErrorNombre] = useState(false);
    const [errorFabricante, setErrorFabricante] = useState(false);

    const classes = useStyles();

    function cerrarModal()
    {
        setModalOpen(false);             
    }

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.nombre !== "") ? (row.nombre.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
            
            r = (row.fabricante !== "") ? (row.fabricante.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;   
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function stableSort(array) {
        if(order === 'desc')
        {
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] > b[orderBy]) ? 1 : (b[orderBy] > a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
        else{
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] < b[orderBy]) ? 1 : (b[orderBy] < a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
    }

    function aplicarFiltro(registros)
    {
        if(registros == null) setRows(registros); 
        else if(registros.length === 0)
        {
            setRows(registros)            
            swal ("There are no records" ,
                    "No information is recorded with the filters used" ,
                    "info");
        }
        else setRows(registros);
    }

    async function eliminar(id, nombre){
        
        swal({
            title: "Delete Confirmation",
            text: "Do you want delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (afirmacion) => {
            if(afirmacion){  
                await eliminarMaestroApp(id, nombre, token)
                .then((response) => {            

                    if(response.cod === 1){

                        const nuevosregistros = rows.filter((row) => row.nombre !== nombre );
                        setRows(nuevosregistros);

                        swal ("Delete Success" ,
                            "" ,
                            "success");
                    }
                    else {
                        swal ("Delete Error" ,
                            response.mensaje ,
                            "warning");
                    }            
                });
            }
        });
    }

    function validarServidor(data)
    {
        var errores = false;
        if(data.nombre === null || data.nombre === "" || data.nombre === undefined) 
        {   
            setErrorNombre(true);
            errores = true;
        }
        else setErrorNombre(false);

        if(data.fabricante === null || data.fabricante === "" || data.fabricante === undefined) 
        {   
            setErrorFabricante(true);
            errores = true;
        }
        else setErrorFabricante(false);

        return errores;
    }

    const onSubmit = (data) => {
        //setRegistro(data);
        let nombreusuario = sessionStorage.getItem('nombre');
        
        if(data.nombre === maestroUpdate.nombre && data.fabricante.value === maestroUpdate.fabricante){
            swal("Dont exist changes", "", "info");
        } 
        else if(validarServidor(data)){
            swal("Form data", "Mandatory data must be entered", "warning");
        }
        else{
            swal({
                title: "Update Confirmation",
                text: "Do you want update record " + maestroUpdate.nombre + "?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion){  

                    const datosform = data;
                    datosform.id_maestroaplicaciones = maestroUpdate.id_maestroaplicaciones;                    
                    datosform.modificadopor = nombreusuario;
                    datosform.fechaupdate = new Date();                   
                    
                    datosform.fabricante = (data.fabricante.value !== maestroUpdate.fabricante) ? datosform.fabricante.value : maestroUpdate.fabricante; 
                    datosform.nombre = data.nombre; 
                    datosform.nombreanterior = (data.nombre !== maestroUpdate.nombre) ? maestroUpdate.nombre : "";                    
                    
                    await actualizarMaestroApp(datosform, token).then((response) => {
                        
                        if(response.cod === 1) {
                            swal("Update Success", "", "success")
                            .then(() => {

                                const registrosTabla = [];

                                const fecha = new Date();
                                const dia = fecha.getDate();
                                var mesInt = fecha.getMonth()+1;
                                const mes = (mesInt < 10) ? "0" + mesInt : mesInt;
                                const year = fecha.getFullYear();

                                rows.map((r) => {
                                    if(r.id_maestroaplicaciones === maestroUpdate.id_maestroaplicaciones){
                                        const nuevo = {
                                            "id_maestroaplicaciones": r.id_maestroaplicaciones,
                                            "nombre": datosform.nombre.toString().toUpperCase(),
                                            "fabricante": datosform.fabricante,
                                            "modificadopor": datosform.modificadopor,
                                            "fechaupdate": dia + "-"+ mes + "-" + year,
                                            "cantidadapps": r.cantidadapps
                                        }

                                        registrosTabla.push(nuevo);
                                    }
                                    else registrosTabla.push(r);
                                });

                                setRows(registrosTabla);
                                cerrarModal();
                            });                            
                        }
                        else swal("Update Error", response.mensaje, "error");                        
                    });                    
                }
            });
        }
    }

    async function obtenerFiltrosApp(){
        await obtenerFiltros(token)
        .then((response) => {
            if(response.cod == 1)
            {
                const opciones = [];
                response.fabricantes.map((p) => opciones.push({value: p, label: p}));
                setOpcionesFabricante(opciones);
            }
        }); 
    }

    React.useEffect(() =>{
        obtenerFiltrosApp();        
    }, []);

    return (
        <> 
            <FiltrosMaestrosApp aplicarFiltro={aplicarFiltro} load={setLoading} token={token}/>
                    
            <ContainerBusqueda> 
                <br></br>                         
                <TextField id="outlined-search" 
                    label="Search" 
                    size="small" 
                    type="search" 
                    variant="outlined" 
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />                           
                <ExportarExcel rows={rows} columnas={Opciones.camposExcel} nombreSheet="Aplicaciones" nombreArchivo="MaestroAplicacionesExport"/>            
                <br></br>                  
            </ContainerBusqueda>

            {
                (loading) 
                ?
                (
                    <>
                        <ContainerTable>
                            <Loading />
                        </ContainerTable>
                    </>
                )
                :
                (
                    <>
                    <ContainerTable>
                        <TableContainer component={Paper}>
                            <Table 
                                className={classes.table} 
                                size="small" 
                                aria-label="enhanced table"
                            >
                                <TableHead style={{ background: '#696158', color: '#fff' }}>
                                    <TableRow>
                                        {headCells.map((headCell) => (
                                            <StyledTableCell 
                                                key={headCell.id}
                                                align={'center'}   
                                                sortDirection={orderBy === headCell.id ? order : false}
                                                style={{ width: headCell.size, color: '#fff !important' }}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={createSortHandler(headCell.id)}
                                                    style={{ background: '#696158', color: '#fff' }}
                                                >
                                                {headCell.label}
                                                </TableSortLabel>                                        
                                            </StyledTableCell>
                                        ))}                                
                                    </TableRow>
                                </TableHead>
                                <TableBody>  
                                    {   
                                        (rows !== null)
                                        ?
                                        (
                                            stableSort(buscar(rows))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)                                    
                                            .map((row) => (
                                                    
                                                <TableRow hover key={row.id_maestroaplicaciones}  style={{ textDecoration: 'none', fontSize: '10px !important' }}>
                                                    <StyledTableCell align="left">{row.nombre}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.fabricante}</StyledTableCell>
                                                    <StyledTableCell align="center">{row.modificadopor}</StyledTableCell>
                                                    <StyledTableCell align="center">{row.fechaupdate}</StyledTableCell> 
                                                    <StyledTableCell align="center">{row.cantidadapps}</StyledTableCell> 
                                                    <StyledTableCell align="center">
                                                        <button 
                                                            onClick={() => {
                                                                setMaestroUpdate(row);                                                                
                                                                setModalOpen(true);                                                       
                                                            }}
                                                            className="boton-observacion">
                                                            <AiIcons.AiFillEdit />
                                                        </button>    
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <button 
                                                            onClick={() => {
                                                                //deseleccionarServidor(row.servidor);
                                                                eliminar(row.id_maestroaplicaciones, row.nombre);
                                                            }}
                                                            className="boton-eliminar tooltipboton">
                                                            <AiIcons.AiFillDelete/>
                                                            <span className="tooltiptextboton">{"Delete"}</span> 
                                                        </button>    
                                                    </StyledTableCell>                                          
                                                </TableRow>
                                            ))
                                        )
                                        :
                                        (
                                            <TableRow />
                                        )
                                    }        
                                </TableBody>                                                      
                            </Table>
                        </TableContainer>
                        <Table>
                            <TableBody> 
                                <TableRow>
                                    <TablePagination  
                                        style={{ display: 'flex'}}
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        colSpan={3}
                                        count={(rows !== null) ? rows.length : 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'Registros por pagina' },
                                            native: true,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableBody> 
                        </Table>
                    </ContainerTable>
                    <Modal 
                        isOpen={modalOpen}
                        style={{
                            overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.75)'
                            },
                            content: {
                                width: '50%',
                                height: '40%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                position: 'absolute',                        
                                border: '1px solid #ccc',
                                background: '#fff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '20px'
                            }
                        }}
                    >
                        {
                            (maestroUpdate === null)
                            ?
                            (
                                <h1>Sin seleccion</h1>
                            )
                            :
                            (
                                <>
                                <br></br>
                                    <div className="modal-modificacion">
                                        <div className="">
                                            <div className='subtitulo-box'>
                                                <div className='subtitulo'>
                                                    <label>Master Application Update</label>
                                                </div>                    
                                            </div> 
                                            <br></br>
                                            <form 
                                                style={{width: '90%', marginRight:'auto', marginLeft: 'auto'}} 
                                                autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                                            >
                                                <div className="row">
                                                    <div className="col-25">
                                                        <label style={{fontWeight: 'bold'}} className="label-form" htmlFor="i-nombre">Name</label> 
                                                    </div>
                                                    <div className="col-75">
                                                        <Controller
                                                            as={<input className="bootstrap-input" type='text'  />}   
                                                            style={(errorNombre) ? { borderColor: '#f90000'} : {}}                                  
                                                            control={control}
                                                            id="i-nombre"
                                                            key="k-nombre"
                                                            defaultValue={maestroUpdate ? maestroUpdate.nombre : ''}
                                                            name='nombre'
                                                        />                                 
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-25">
                                                        <label style={{fontWeight: 'bold'}}  className="label-form" htmlFor="i-fabricante">Producer</label> 
                                                    </div>
                                                    <div className="col-75">
                                                            
                                                        <Controller
                                                            as={<Select/>}
                                                            name="fabricante"                                                            
                                                            isDisabled={false}
                                                            options={opcionesFabricante} 
                                                            isClearable={true}
                                                            defaultValue={maestroUpdate ? {"value": maestroUpdate.fabricante, "label": maestroUpdate.fabricante} : ''}
                                                            placeholder=""                                    
                                                            control={control}
                                                            styles={(errorFabricante) ? style : styleNormal}
                                                        /> 
                                                    </div>
                                                </div>
                                                    
                                                <br></br>
                                                <br></br>
                                                <div className="row">   
                                                    <div className="">
                                                        <ButtonConfirmar>Save</ButtonConfirmar>
                                                        
                                                    </div>
                                                    <div className="">
                                                        <ButtonCancelar onClick={cerrarModal}>Return</ButtonCancelar>
                                                    </div>     
                                                </div>
                                                
                                            </form>
                                        </div>
                                    </div>                                    
                                </>
                            )
                        }                        
                    </Modal>
                    </>
                )
            }
        </>
    )
}

export default TablaMaestroAplicacion
