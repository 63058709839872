import React from 'react'
import { obtenerTablaSccmEquipos } from '../functions/FuncAplicacion'
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';
import ModalAplicacionPermitida from '../Maestros/AplicacionPermitida/ModalAplicacionPermitida';

const ContainerFiltros= styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
`;

const Button = styled.button`
    margin-left: 10px;
    margin-right: auto;
    width: 90%;
    background-color: #EA7600;
    color: white;       
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;  
    padding: 11.5px 14px;  
    border-radius: 5px;  
    border-color: #EA7600;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    vertical-align: middle;
    color: white;
`;

const useStyles = makeStyles((theme) => ({
    formControl: {
      marginLeft: '10px',
      marginRight: 'auto'      
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const FiltroAplicacionSccmEquipos = ({aplicarFiltro, load, token}) => {

    const classes = useStyles();

    const [aplicacion, setAplicacion] = React.useState("");
    const [equipo, setEquipo] = React.useState("");

    async function obtenerRegistros()
    {
        if(aplicacion === "" && equipo === "")
        {
            swal ("Search error" , "A value must be entered in Equipment and / or Application" , "warning");
        }
        else {
            load(true);
            aplicarFiltro(null);
            await obtenerTablaSccmEquipos(aplicacion, equipo, token).then((response) => {            
                if(response.cod === 1) {
                    aplicarFiltro(response.aplicaciones);
                }
                else {
                    aplicarFiltro(null);
                    swal ("Search error" , response.mensaje , "warning");
                }
                load(false);
            });
        }
    }

    const elegirAplicacion = (valor) => {
        setAplicacion(valor);
    }

    function handleSubmit(e){
        
        //e.target.reset();
        obtenerRegistros();        
        e.preventDefault();
    }

    return (
        <>
            <ContainerFiltros>
                <form onSubmit={handleSubmit}>
                    <FormControl variant="outlined" style={{verticalAlign: 'middle', width: '30%'}} className={classes.formControl}>                                   
                        <TextField 
                            id="outlined-basic" 
                            value={aplicacion} 
                            onChange={(e) => setAplicacion(e.target.value)} 
                            label="Application" 
                            variant="outlined" 
                        />                      
                    </FormControl>
                    <FormControl variant="outlined" style={{verticalAlign: 'middle', width: '30%'}} className={classes.formControl}>                                   
                        <TextField 
                            
                            id="outlined-basic" 
                            value={equipo} 
                            onChange={(e) => setEquipo(e.target.value)} 
                            label="Desktop" 
                            variant="outlined" 
                        />                      
                    </FormControl> 

                    <FormControl variant="outlined" style={{verticalAlign: 'middle', width: '20%'}} className={classes.formControl}>
                        <Button className={classes.formControl}>Display</Button>
                    </FormControl>

                    <FormControl variant="outlined" style={{verticalAlign: 'middle', width: '5%'}} className={classes.formControl}>
                        <ModalAplicacionPermitida token={token} elegirAplicacion={elegirAplicacion}/>
                    </FormControl>
                    
                </form>
            </ContainerFiltros>
            <br></br>
            <hr style={{width: '80%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
        </>
    )
}

export default FiltroAplicacionSccmEquipos
