import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import { obtenerValores, valoresUsuario, crearEquipo} from '../functions/FuncEquipoEscritorio'
import "../css/formularioEquipo.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import Loading from '../Loading';
import Select from 'react-select'
import { validarTextoVacio } from '../functions/MetodosComunes'

import * as FcIcons from 'react-icons/fc';

import { style, styleNormal, styleDisable } from '../Commons/Styles'

const CreacionEquipoEscritorio = ({token}) => {

    const history = useHistory();
    const [registro, setRegistro] = useState(null);    
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const [busqueda, setBusqueda] = useState(false);
    const [generico, setGenerico] = useState(false);
    const [idUsuario, setIdUsuario] = useState("");
    const [nombreUsuario, setNombreUsuario] = useState("");
    const [division, setDivision] = useState("");
    const [divisionplanta, setDivisionPlanta] = useState("");
    const [divisionfecha, setDivisionFecha] = useState("");
    const [cargo, setCargo] = useState("");
    const [cargorol, setCargoRol] = useState("");
    const [correo, setCorreo] = useState("");
    const [centrocosto, setCentroCosto] = useState("");
    const [gerenciaprincipal, setGerenciaPrincipal] = useState("");
    const [gerenciadirecta, setGerenciaDirecta] = useState("");
    const [subGerenciaArea, setSubGerenciaArea] = useState("");
    const [licenciaprincipal, setLicenciaprincipal] = useState("");
    const [usuarioMaestroPersonal, setUsuarioMaestroPersonal] = useState(false);

    const [empresa, setEmpresa] = useState("");
    const [planta, setPlanta] = useState(""); 
    const [marca, setMarca] = useState("");
    const [modelo, setModelo] = useState("");
    const [estado, setEstado] = useState("");
    const [condicion, setCondicion] = useState("");

    const [errorNumeroSerie, setErrorNumeroSerie] = useState(false);
    const [errorEstado, setErrorEstado] = useState(false);
    const [errorEmpresa, setErrorEmpresa] = useState(false);
    const [errorCondicion, setErrorCondicion] = useState(false);
    const [errorPlanta, setErrorPlanta] = useState(false);
    const [errorNombreEquipo, setErrorNombreEquipo] = useState(false);
    const [errorTipoEquipo, setErrorTipoEquipo] = useState(false);
    const [errorMarca, setErrorMarca] = useState(false);
    const [errorModelo, setErrorModelo] = useState(false);
    const [errorCondicionRed, setErrorCondicionRed] = useState(false);
    const [errorUsuario, setErrorUsuario] = useState(false);
    const [errorIdUsuario, setErrorIdUsuario] = useState(false);

    let privilegio = sessionStorage.getItem('esc_crear');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');
    let idiomaUser = sessionStorage.getItem('idioma');

    async function obtenerDatosBD()
    {
        await obtenerValores(privilegio, paisUser, localidadUser, "DESKTOP", idiomaUser, token)
        .then((response) => {
            if(response.cod === 1)
            {
                const val = {
                    "estados": response.estados,
                    "condiciones": response.condiciones,
                    "filtrocondiciones": response.filtrocondiciones,
                    "tiposequipo": response.tiposequipo,
                    "condicionesred": response.condicionesred,  
                    "empresas": response.empresas,
                    "plantas": response.plantas,
                    "filtroplantas": response.filtroplantas,  
                    "marcas": response.marcas,
                    "modelos": response.modelos,
                    "filtromodelos": response.filtromodelos, 

                    "ordenescompra": response.ordenescompra,
                    "discosduros": response.discosduros,
                    "memoriasram": response.memoriasram,
                    "tiposprocesador": response.tiposprocesador,
                    "velocidadesprocesador": response.velocidadesprocesador,

                    "divisiones": response.divisiones,
                    "gerenciasprincipales": response.gerenciasprincipales,
                    "gerenciasdirectas": response.gerenciasdirectas,
                    "subgerenciasarea": response.subgerenciasarea,
                    "cargos": response.cargos,
                    "centroscosto": response.centroscosto,
                    "traducciones": response.traducciones                   
                }; 

                let array = [];

                const listas = ["estados", "condiciones", "tiposequipo", "condicionesred", "empresas", "plantas", "marcas", "modelos", "ordenescompra",
                 "discosduros", "memoriasram", "tiposprocesador", "velocidadesprocesador", "divisiones", "gerenciasprincipales", "gerenciasdirectas", "subgerenciasarea",
                  "cargos", "centroscosto"];

                listas.forEach(lista => {
                    array = [];
                    val[lista].map((u) => {
                        array.push({"label": u, "value": u});
                    })
                    val[lista] = array;
                });
                
                setValores(val);
            }
        });
    }

    async function buscarUsuario()
    {
        setGenerico(false);
        setBusqueda(true);

        await valoresUsuario("", idUsuario, token).then((response) => {
            if(response.cod == 1)
            {
                const usuario = response.personal;
                setCorreo(usuario.correo)
                setNombreUsuario(usuario.nombre)
                setLicenciaprincipal(usuario.licenciaprincipal)
                setDivision({"label": usuario.division, "value": usuario.division})
                setDivisionPlanta(usuario.divisionplanta)
                setDivisionFecha(usuario.divisionfecha)
                setCargo({"label": usuario.cargo, "value": usuario.cargo})
                setCargoRol(usuario.cargorol)
                setCentroCosto({"label": usuario.centrocosto, "value": usuario.centrocosto})
                setGerenciaPrincipal({"label": usuario.gerenciaprincipal, "value": usuario.gerenciaprincipal})
                setGerenciaDirecta({"label": usuario.gerenciadirecta, "value": usuario.gerenciadirecta})
                setSubGerenciaArea({"label": usuario.subgerenciaarea, "value": usuario.subgerenciaarea})
                setUsuarioMaestroPersonal(true)                
            }
            else swal("Search", response.mensaje, "warning");
        });
    }

    const busquedaUsuario = () => {
        if(idUsuario === "")
        {
            swal("Search Error", "No value has been entered to perform the search", "warning");
        }
        else if(idUsuario.toUpperCase() === "GENERIC" || idUsuario.toUpperCase() === "EXTERN"){
            setNombreUsuario("AVAILABLE");
            setGenerico(true);
            setBusqueda(true);
            swal("", "A Generic user has been selected", "info");
        }
        else buscarUsuario();        
    }

    const OnChangeIdUsuario = (event) => {
        setIdUsuario(event.target.value);        
    }

    const onSubmit = (data) => {
        
        if(validarDatos(data)){
            swal("Form data", "Mandatory data must be entered", "warning");
        }
        else{
            swal({
                title: "Creation confirmation",
                text: "Do you want to create the record?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {

                console.log(afirmacion)

                if(afirmacion)
                {
                    const datosform = data;

                    let nombreusuario = sessionStorage.getItem('nombre');
                    datosform.modificadopor = nombreusuario;
                    datosform.fechaupdate = new Date();

                    datosform.idusuario = idUsuario;
                    datosform.nombreusuario = nombreUsuario;

                    datosform.correo = correo;
                    datosform.division = division;
                    datosform.divisionplanta = divisionplanta;
                    datosform.divisionfecha = divisionfecha;
                    datosform.cargo = cargo;
                    datosform.cargorol = cargorol;
                    datosform.centrocosto = centrocosto;
                    datosform.gerenciaprincipal = gerenciaprincipal;
                    datosform.gerenciadirecta = gerenciadirecta;
                    datosform.subgerenciaarea = subGerenciaArea;
                    datosform.licenciaprincipal = licenciaprincipal;
                    datosform.usuariomaestropersonal = usuarioMaestroPersonal;                    

                    console.log(datosform)

                    await crearEquipo(datosform, token).then((response) => {

                        console.log(response)

                        if(response.cod === 1)
                        {
                            swal("Successful creation", "", "success").then(() => {
                                history.push("/equipos/"+response.id);
                            });
                        }
                        else swal("Creation error", "" + response.mensaje, "warning");
                    });                    
                }
            })
        }
    }

    function validarDatos(data)
    {
        var errores = false;       
        
        if(idUsuario === "")
        {
            setErrorIdUsuario(true);
            errores = true;
        }
        else setErrorIdUsuario(false);

        if(nombreUsuario === "")
        {
            setErrorUsuario(true);
            errores = true;
        }
        else setErrorUsuario(false);

        if(data.numeroserie === null || data.numeroserie === "" || data.numeroserie === undefined) 
        {
            setErrorNumeroSerie(true);
            errores = true;
        }
        else setErrorNumeroSerie(false);

        if(data.empresa === null || data.empresa === "" || data.empresa === undefined) 
        {
            setErrorEmpresa(true);
            errores = true;
        }
        else setErrorEmpresa(false);

        if(data.planta === null || data.planta === "" || data.planta === undefined) 
        {
            setErrorPlanta(true);
            errores = true;
        }
        else setErrorPlanta(false);

        if(data.estado === null || data.estado === "" || data.estado === undefined) 
        {
            setErrorEstado(true);
            errores = true;
        }
        else setErrorEstado(false);

        if(data.condicion === null || data.condicion === "" || data.condicion === undefined) 
        {
            setErrorCondicion(true);
            errores = true;
        }
        else setErrorCondicion(false);

        if(data.nombreequipo === null || data.nombreequipo === "" || data.nombreequipo === undefined) 
        {
            setErrorNombreEquipo(true);
            errores = true;
        }
        else setErrorNombreEquipo(false);

        if(data.tipoequipo === null || data.tipoequipo === "" || data.tipoequipo === undefined) 
        {
            setErrorTipoEquipo(true);
            errores = true;
        }
        else setErrorTipoEquipo(false);

        if(data.marca === null || data.marca === "" || data.marca === undefined) 
        {
            setErrorMarca(true);
            errores = true;
        }
        else setErrorMarca(false);

        if(data.modelo === null || data.modelo === "" || data.modelo === undefined) 
        {
            setErrorModelo(true);
            errores = true;
        }
        else setErrorModelo(false);

        if(data.condicionred === null || data.condicionred === "" || data.condicionred === undefined) 
        {
            setErrorCondicionRed(true);
            errores = true;
        }
        else setErrorCondicionRed(false);

        return errores;
    }

    const cambioEmpresa= (value) => { 
        if(value !== "" && value !== null){
            setEmpresa(value.value);            
            filtrarPlantas(value.value);            
        } else {
            setEmpresa(""); 
            setPlanta("");           
        }
    }

    const cambioPlanta= (value) => { 
        if(value !== "" && value !== null){
            setPlanta({"label": value.value, "value": value.value});
        } else {
            setPlanta("");
        }
    }

    function filtrarPlantas(empresa)
    {
        if(empresa !== "")
        {
            const filtrotipoArray = valores.filtroplantas;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.empresa === empresa) {
                        array.push({"label": p.planta, "value": p.planta});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.plantas = array; 
            setPlanta("");           
            setValores(valoresNuevo);
        }
    }

    const cambioEstado = (value) => {
        if(value !== "" && value !== null){
            setEstado(value.value);            
            filtrarCondiciones(value.value);            
        } else {
            setEstado(""); 
            setCondicion("");           
        }
    }

    const cambioCondicion= (value) => { 
        if(value !== "" && value !== null){
            setCondicion({"label": value.value, "value": value.value});
        } else {
            setCondicion("");
        }
    }

    function filtrarCondiciones(estado)
    {
        if(validarTextoVacio(estado))
        {
            console.log(estado)
            const filtrotipoArray = valores.filtrocondiciones;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.estado === estado) {
                        array.push({"label": p.condicion, "value": p.condicion});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.condiciones = array; 
            console.log(valoresNuevo.condiciones)
            setCondicion("");           
            setValores(valoresNuevo);
        }
    }

    const cambioMarca = (value) => { 
        if(value !== "" && value !== null){
            setMarca(value.value);            
            filtrarModelos(value.value);            
        } else {
            setMarca(""); 
            setModelo("");           
        }
    }

    const cambioModelo= (value) => { 
        if(value !== "" && value !== null){
            setModelo({"label": value.value, "value": value.value});
        } else {
            setModelo("");
        }
    }

    function filtrarModelos(marca)
    {
        if(marca !== "")
        {
            const filtrotipoArray = valores.filtromodelos;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.marca === marca) {
                        array.push({"label": p.modelo, "value": p.modelo});
                    }                
                });        
            }

            const valoresNuevo = valores;
            valoresNuevo.modelos = array; 
            setModelo("");           
            setValores(valoresNuevo);
        }
    }

    const cambioNombreUsuario = (event) => {
        setNombreUsuario(event.target.value);
    }

    const cambioLicenciaPrincipal = (event) => {
        setLicenciaprincipal(event.target.value);
    }

    const cambioCorreo = (event) => {
        setCorreo(event.target.value);
    }

    const cambioDivision = (value) => {
        if(value === "" || value === null || value === undefined) setDivision(null);
        else setDivision({"label": value.value, "value": value.value});        
    }

    const cambioDivisionPlanta = (event) => {
        setDivisionPlanta(event.target.value);
    }

    const cambioDivisionFecha = (event) => {
        setDivisionFecha(event.target.value);
    }

    const cambioCargo = (value) => {        
        if(value === "" || value === null || value === undefined) setCargo(null);
        else setCargo({"label": value.value, "value": value.value});       
    }

    const cambioCargoRol = (event) => {
        setCargoRol(event.target.value);
    }

    const cambioCentroCosto = (value) => {
        if(value === "" || value === null || value === undefined) setCentroCosto(null);
        else setCentroCosto({"label": value.value, "value": value.value});        
    }

    const cambioGerenciaPrincipal = (value) => {
        if(value === "" || value === null || value === undefined) setGerenciaPrincipal(null);
        else setGerenciaPrincipal({"label": value.value, "value": value.value});         
    }

    const cambioGerenciaDirecta = (value) => {
        if(value === "" || value === null || value === undefined) setGerenciaDirecta(null);
        else setGerenciaDirecta({"label": value.value, "value": value.value});         
    }

    const cambioSubGerencia = (value) => {
        if(value === "" || value === null || value === undefined) setSubGerenciaArea(null);
        else setSubGerenciaArea({"label": value.value, "value": value.value});  
    }

    React.useEffect(() => {
        if (valores !== null) {

        }
        else obtenerDatosBD();
    }, []);

    if(valores === null) return (<Loading />)
    else
    return (
        <>
        <div>

            <form 
                style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                autoComplete="off" onSubmit={handleSubmit(onSubmit)}
            > 
                <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}}>
                
                    <div className="divizq-eq">
                        <div className="container">
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-numeroserie">Serial Number<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.numeroserie === "" || valores.traducciones.numeroserie === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.numeroserie}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                    control={control}
                                    id="i-numeroserie"
                                    key="k-numeroserie"
                                    defaultValue={registro ? registro.numeroserie : ''}
                                    name='numeroserie'
                                    style={(errorNumeroSerie) ? { borderColor: '#f90000'} : {}} 
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-estado">State<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.estado === "" || valores.traducciones.estado === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.estado}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.estados}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value)
                                        cambioEstado(value);
                                    }}  
                                    isClearable={true}
                                    styles={(errorEstado) ? style : styleNormal}
                                    />} 
                                    control={control}
                                    id="i-estado"
                                    key="k-estado"                                    
                                    name='estado'
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-condicion">Condition<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.condicion === "" || valores.traducciones.condicion === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.condicion}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.condiciones}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value);
                                        cambioCondicion(value);
                                    }}  
                                    isClearable={true}
                                    value={condicion}
                                    isDisabled={(validarTextoVacio(estado)) ? false : true}
                                    styles={(errorCondicion) ? style : styleNormal}                                          
                                    />} 
                                    control={control}
                                    id="i-condicion"
                                    key="k-condicion"                                    
                                    name='condicion'
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-empresa">Enterprise<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.empresa === "" || valores.traducciones.empresa === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.empresa}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.empresas}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value);
                                        cambioEmpresa(value);
                                        
                                    }}  
                                    isClearable={true}
                                    styles={(errorEmpresa) ? style : styleNormal}
                                    />} 
                                    control={control}
                                    id="i-empresa"
                                    key="k-empresa"                                    
                                    name='empresa'
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-planta">Plant<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.planta === "" || valores.traducciones.planta === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.planta}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.plantas}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            cambioPlanta(value);  
                                            
                                        }}  
                                        isClearable={true}
                                        value={planta}
                                        isDisabled={(empresa !== "") ? false : true}
                                        styles={(errorPlanta) ? style : styleNormal}
                                    />} 
                                    control={control}
                                    id="i-planta"
                                    key="k-planta"                                    
                                    name='planta'
                                />                              
                            </div>
                        </div>                        
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-tipoequipo">Desktop Type<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.tipoequipo === "" || valores.traducciones.tipoequipo === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.tipoequipo}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.tiposequipo}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)                                        
                                        }}  
                                        isClearable={true}
                                        styles={(errorTipoEquipo) ? style : styleNormal}
                                    />} 
                                    control={control}
                                    id="i-tipoequipo"
                                    key="k-tipoequipo"                                    
                                    name='tipoequipo'
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-marca">Brand<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.marca === "" || valores.traducciones.marca === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.marca}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.marcas}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            cambioMarca(value); 
                                            
                                        }}  
                                        isClearable={true}
                                        styles={(errorMarca) ? style : styleNormal}
                                    />} 
                                    control={control}
                                    id="i-marca"
                                    key="k-marca"                                    
                                    name='marca'
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-modelo">Model<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.modelo === "" || valores.traducciones.modelo === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.modelo}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.modelos}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            cambioModelo(value);                                            
                                        }}  
                                        value={modelo}
                                        isDisabled={(marca !== "") ? false : true}
                                        isClearable={true}
                                        styles={(errorModelo) ? style : styleNormal}
                                    />} 
                                    control={control}
                                    id="i-modelo"
                                    key="k-modelo"                                    
                                    name='modelo'
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-nombreequipo">Desktop Name<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.nombreequipo === "" || valores.traducciones.nombreequipo === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.nombreequipo}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                    control={control}
                                    id="i-nombreequipo"
                                    key="k-nombreequipo"
                                    defaultValue={registro ? registro.nombreequipo : ''}
                                    name='nombreequipo'
                                    style={(errorNombreEquipo) ? { borderColor: '#f90000'} : {}} 
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-condicionred">Network Condition<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.condicionred === "" || valores.traducciones.condicionred === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.condicionred}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.condicionesred}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value)
                                        
                                    }}  
                                    isClearable={true}
                                    styles={(errorCondicionRed) ? style : styleNormal}
                                    />} 
                                    control={control}
                                    id="i-condicionred"
                                    key="k-condicionred"                                    
                                    name='condicionred'
                                />                              
                            </div>
                        </div>
                        
                        <br></br>                        
                        <hr style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}}></hr>

                        <div className="row">
                            <div className="col-der-servidor-doble">
                                <div className="col-doble-servidor-nom">
                                    <label htmlFor="i-excluidoparches">Excluded patches
                                    {
                                        (valores.traducciones.excluidoparches === "" || valores.traducciones.excluidoparches === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.excluidoparches}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="col-doble-servidor-campo">
                                    <input
                                        type="checkbox"
                                        id="i-excluidoparches"
                                        key="k-excluidoparches" 
                                        name="excluidoparches"
                                        placeholder="luo"
                                        value={true}                                    
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="col-izq-servidor-doble">
                                <div className="col-25-servidor">
                                    <label htmlFor="i-motivoexcluidoparches">Cause
                                    </label>
                                </div>
                                <div className="col-75-servidor">
                                    <Controller
                                        as={<input className="bootstrap-input" type='text' autoComplete="off"  />}                                
                                        control={control}
                                        id="i-motivoexcluidoparches"
                                        key="k-motivoexcluidoparches"
                                        defaultValue={registro ? registro.motivoexcluidoparches : ''}
                                        name='motivoexcluidoparches'                                        
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-der-servidor-doble">
                                <div className="col-doble-servidor-nom">
                                    <label htmlFor="i-usbdesbloqueado">Unlocked USB
                                    {
                                        (valores.traducciones.usbdesbloqueado === "" || valores.traducciones.usbdesbloqueado === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.usbdesbloqueado}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="col-doble-servidor-campo">
                                    <input
                                        type="checkbox"
                                        id="i-usbdesbloqueado"
                                        key="k-usbdesbloqueado" 
                                        name="usbdesbloqueado"
                                        placeholder="luo"
                                        value={true}                                    
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="col-izq-servidor-doble">
                                <div className="col-25-servidor">
                                    <label htmlFor="i-motivousbdesbloqueado">Cause
                                    </label>
                                </div>
                                <div className="col-75-servidor">
                                    <Controller
                                        as={<input className="bootstrap-input" type='text' autoComplete="off"  />}                                
                                        control={control}
                                        id="i-motivousbdesbloqueado"
                                        key="k-motivousbdesbloqueado"
                                        defaultValue={registro ? registro.motivousbdesbloqueado : ''}
                                        name='motivousbdesbloqueado'                                        
                                    />
                                </div>
                            </div>
                        </div>
                                          
                        <hr style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}}></hr>                        
                        <br></br>

                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-ordencompra">Purchase order
                                {
                                    (valores.traducciones.ordencompra === "" || valores.traducciones.ordencompra === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.ordencompra}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.ordenescompra}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value)
                                        
                                    }}  
                                    isClearable={true}
                                    styles={styleNormal}                                        
                                    />} 
                                    control={control}
                                    id="i-ordencompra"
                                    key="k-ordencompra"                                    
                                    name='ordencompra'
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-direccionip">IP Address
                                {
                                    (valores.traducciones.direccionip === "" || valores.traducciones.direccionip === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.direccionip}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                    control={control}
                                    id="i-direccionip"
                                    key="k-direccionip"
                                    defaultValue={registro ? registro.direccionip : ''}
                                    name='direccionip'                                
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-discoduro">HDD
                                {
                                    (valores.traducciones.discoduro === "" || valores.traducciones.discoduro === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.discoduro}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.discosduros}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value)
                                        
                                    }}  
                                    isClearable={true}
                                    styles={styleNormal}                                        
                                    />} 
                                    control={control}
                                    id="i-discoduro"
                                    key="k-discoduro"                                    
                                    name='discoduro'
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-memoriaram">Ram
                                {
                                    (valores.traducciones.memoriaram === "" || valores.traducciones.memoriaram === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.memoriaram}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.memoriasram}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value)
                                        
                                    }}  
                                    isClearable={true}
                                    styles={styleNormal}                                        
                                    />} 
                                    control={control}
                                    id="i-memoriaram"
                                    key="k-memoriaram"                                    
                                    name='memoriaram'
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-tipoprocesador">Processor Type
                                {
                                    (valores.traducciones.tipoprocesador === "" || valores.traducciones.tipoprocesador === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.tipoprocesador}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.tiposprocesador}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value)
                                        
                                    }}  
                                    isClearable={true}
                                    styles={styleNormal}                                        
                                    />} 
                                    control={control}
                                    id="i-tipoprocesador"
                                    key="k-tipoprocesador"                                    
                                    name='tipoprocesador'
                                />                              
                            </div>
                        </div>                        
                        <div className="row" style={{display: 'none'}}>
                            <div className="col-25">
                                <label htmlFor="i-velocidadprocesador">Processor Speed</label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.velocidadesprocesador}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value)
                                        
                                    }}  
                                    isClearable={true}
                                    styles={styleNormal}                                        
                                    />} 
                                    control={control}
                                    id="i-velocidadprocesador"
                                    key="k-velocidadprocesador"                                    
                                    name='velocidadprocesador'
                                />                              
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-docking">Doking
                                {
                                    (valores.traducciones.docking === "" || valores.traducciones.docking === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.docking}</span>
                                        </div>
                                    )
                                }
                                </label>
                            </div>
                            <div className="col-75">
                                <input
                                    type="checkbox"
                                    id="i-docking"
                                    key="k-docking" 
                                    name="docking"
                                    placeholder="luo"
                                    value={true}
                                    ref={register}
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-monitor">Monitor
                                {
                                    (valores.traducciones.monitor === "" || valores.traducciones.monitor === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.monitor}</span>
                                        </div>
                                    )
                                }
                                </label>
                            </div>
                            <div className="col-75">
                                <input
                                    type="checkbox"
                                    id="i-monitor"
                                    key="k-monitor" 
                                    name="monitor"
                                    placeholder="luo"
                                    value={true}
                                    ref={register}
                                />
                            </div>
                        </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-fecharecepcion">Reception Date (YYYY/MM/DD)
                                    {
                                        (valores.traducciones.fecharecepcion === "" || valores.traducciones.fecharecepcion === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.fecharecepcion}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        name="fecharecepcion"
                                        id="i-fecharecepcion"
                                        className="bootstrap-input"
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <DatePicker                                                
                                                selected={value}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                onChange={onChange}
                                                className="bootstrap-input"
                                                locale="es"
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-fechaentrega">Delivery Date (YYYY/MM/DD)
                                    {
                                        (valores.traducciones.fechaentrega === "" || valores.traducciones.fechaentrega === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.fechaentrega}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        name="fechaentrega"
                                        id="i-fechaentrega"
                                        className="bootstrap-input"
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <DatePicker                                                
                                                selected={value}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                onChange={onChange}
                                                className="bootstrap-input"
                                                locale="es"
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-fechaentrega">Warranty Expiration Date (YYYY/MM/DD)
                                    {
                                        (valores.traducciones.fechaexpiracion === "" || valores.traducciones.fechaexpiracion === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.fechaexpiracion}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        name="fechaexpiracion"
                                        id="i-fechaexpiracion"
                                        className="bootstrap-input"
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <DatePicker                                                
                                                selected={value}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                onChange={onChange}
                                                className="bootstrap-input"
                                                locale="es"
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-fechamantencion">Maintenance Date (YYYY/MM/DD)
                                    {
                                        (valores.traducciones.fechamantencion === "" || valores.traducciones.fechamantencion === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.fechamantencion}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        name="fechamantencion"
                                        id="i-fechamantencion"
                                        className="bootstrap-input"
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <DatePicker                                                
                                                selected={value}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                onChange={onChange}
                                                className="bootstrap-input"
                                                locale="es"
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-fechafueralinea">Offline Date (YYYY/MM/DD)
                                    {
                                        (valores.traducciones.fechafueralinea === "" || valores.traducciones.fechafueralinea === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.fechafueralinea}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        name="fechafueralinea"
                                        id="i-fechafueralinea"
                                        className="bootstrap-input"
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <DatePicker                                                
                                                selected={value}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                onChange={onChange}
                                                className="bootstrap-input"
                                                locale="es"
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-ubicacion">Ubication
                                    {
                                        (valores.traducciones.ubicacion === "" || valores.traducciones.ubicacion === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.ubicacion}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input className="bootstrap-input" type='text'  />}                                      
                                        control={control}
                                        id="i-ubicacion"
                                        key="k-ubicacion"
                                        defaultValue={registro ? registro.ubicacion : ''}
                                        name='ubicacion'  
                                    />                             
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-observaciones">Observations
                                    {
                                        (valores.traducciones.observaciones === "" || valores.traducciones.observaciones === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                                <span class="tooltiptext">{valores.traducciones.observaciones}</span>
                                            </div>
                                        )
                                    }
                                    </label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<textarea className="bootstrap-input" rows="3" cols="50"></textarea>}
                                        control={control}
                                        id="i-observaciones"
                                        key="k-observaciones"
                                        defaultValue={registro ? registro.observaciones : ''}
                                        name='observaciones'
                                    />                             
                                </div>
                            </div> 
                        </div>
                        </div>
                    </div>                   
            
                    <div className="divder-eq">
                    <div className="container">
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-idusuario">User ID<label style={{color: 'red'}}>{"*"}</label> (GENERIC / EXTERN)
                                {
                                    (valores.traducciones.idusuario === "" || valores.traducciones.idusuario === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.idusuario}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <>
                                <Controller
                                    render={(props) =>
                                        <input
                                            className="bootstrap-input-busqueda"
                                            type='text'  
                                            onChange={(value) => {
                                                props.onChange(value);
                                                OnChangeIdUsuario(value);                                             
                                            }}
                                            style={(errorIdUsuario) ? { borderColor: '#f90000'} : {}} 
                                        />                                                                           
                                    }                                                                          
                                    control={control}
                                    id="i-idusuario"
                                    key="k-idusuario"                                    
                                    name='idusuario'                 
                                />
                                <button type="button" className="boton-buscarusuario tooltipboton" onClick={busquedaUsuario}>
                                    <FcIcons.FcSearch />
                                    <span className="tooltiptextboton">{"Search"}</span> 
                                </button>
                                </>                      
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-nombreusuario">User Name<label style={{color: 'red'}}>{"*"}</label>
                                {
                                    (valores.traducciones.nombreusuario === "" || valores.traducciones.nombreusuario === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.nombreusuario}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) =>
                                        <input 
                                            disabled={(generico) ? '' : 'disabled'} 
                                            className={(generico) ? "bootstrap-input" : "bootstrap-input-disable"}
                                            type='text'  
                                            onChange={(value) => {
                                                props.onChange(value);  
                                                cambioNombreUsuario(value);                                                                                          
                                            }}
                                            style={(errorUsuario) ? { borderColor: '#f90000'} : {}} 
                                            value={nombreUsuario}                                            
                                        />                                                                           
                                    }                                       
                                    control={control}
                                    id="i-nombreusuario"
                                    key="k-nombreusuario"                                    
                                    name='nombreusuario'
                                />                           
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-correo">Email
                                {
                                    (valores.traducciones.correo === "" || valores.traducciones.correo === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.correo}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) =>
                                        <input 
                                            disabled={(generico) ? '' : 'disabled'} 
                                            className={(generico) ? "bootstrap-input" : "bootstrap-input-disable"}
                                            type='text'  
                                            onChange={(value) => {
                                                props.onChange(value);  
                                                cambioCorreo(value)                                                                                          
                                            }}
                                            value={correo}                                            
                                        />                                                                           
                                    }                                                                          
                                    control={control}
                                    id="i-correo"
                                    key="k-correo"                                    
                                    name='correo'
                                />                           
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-division">Division
                                {
                                    (valores.traducciones.division === "" || valores.traducciones.division === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.division}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.divisiones}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            cambioDivision(value)
                                        }}  
                                        isClearable={true}
                                        isDisabled={(generico) ? false : true}
                                        styles={(generico) ? styleNormal : styleDisable}  
                                        value={division}                                        
                                    />} 
                                    control={control}
                                    id="i-division"
                                    key="k-division"                                    
                                    name='division'
                                />                           
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-divisionplanta">Division Plant
                                {
                                    (valores.traducciones.divisionplanta === "" || valores.traducciones.divisionplanta === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.divisionplanta}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) =>
                                        <input 
                                            disabled={(generico) ? '' : 'disabled'} 
                                            className={(generico) ? "bootstrap-input" : "bootstrap-input-disable"}
                                            type='text'  
                                            onChange={(value) => {
                                                props.onChange(value);  
                                                cambioDivisionPlanta(value)                                                                                          
                                            }}
                                            value={divisionplanta}                                            
                                        />                                                                           
                                    }                                                                          
                                    control={control}
                                    id="i-divisionplanta"
                                    key="k-divisionplanta"                                    
                                    name='divisionplanta'
                                />                           
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-divisionfecha">Copy Date
                                {
                                    (valores.traducciones.divisionfecha === "" || valores.traducciones.divisionfecha === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.divisionfecha}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) =>
                                        <input 
                                            disabled={(generico) ? '' : 'disabled'} 
                                            className={(generico) ? "bootstrap-input" : "bootstrap-input-disable"}
                                            type='text'  
                                            onChange={(value) => {
                                                props.onChange(value);  
                                                cambioDivisionFecha(value)                                                                                          
                                            }}
                                            value={divisionfecha}                                            
                                        />                                                                           
                                    }                                                                          
                                    control={control}
                                    id="i-divisionfecha"
                                    key="k-divisionfecha"                                    
                                    name='divisionfecha'
                                />                           
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-gerenciaprincipal">Principal Management
                                {
                                    (valores.traducciones.gerenciaprincipal === "" || valores.traducciones.gerenciaprincipal === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.gerenciaprincipal}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.gerenciasprincipales}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            cambioGerenciaPrincipal(value)
                                        }}   
                                        isClearable={true}
                                        isDisabled={(generico) ? false : true}
                                        styles={(generico) ? styleNormal : styleDisable}   
                                        value={gerenciaprincipal}                                      
                                    />} 
                                    control={control}
                                    id="i-gerenciaprincipal"
                                    key="k-gerenciaprincipal"                                    
                                    name='gerenciaprincipal'
                                />                           
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-gerenciadirecta">Direct Management
                                {
                                    (valores.traducciones.gerenciadirecta === "" || valores.traducciones.gerenciadirecta === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.gerenciadirecta}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.gerenciasdirectas}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            cambioGerenciaDirecta(value)
                                        }}  
                                        isClearable={true}
                                        isDisabled={(generico) ? false : true}
                                        styles={(generico) ? styleNormal : styleDisable}         
                                        value={gerenciadirecta}                                
                                    />} 
                                    control={control}
                                    id="i-gerenciadirecta"
                                    key="k-gerenciadirecta"                                    
                                    name='gerenciadirecta'
                                />                           
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-subgerenciaarea">Area Sub Management
                                {
                                    (valores.traducciones.subgerenciaarea === "" || valores.traducciones.subgerenciaarea === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.subgerenciaarea}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.subgerenciasarea}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            cambioSubGerencia(value)
                                        }}  
                                        isClearable={true}
                                        isDisabled={(generico) ? false : true}
                                        styles={(generico) ? styleNormal : styleDisable}   
                                        value={subGerenciaArea}                                       
                                    />} 
                                    control={control}
                                    id="i-subgerenciaarea"
                                    key="k-subgerenciaarea"                                    
                                    name='subgerenciaarea'
                                />                           
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-cargorol">Position Role
                                {
                                    (valores.traducciones.cargorol === "" || valores.traducciones.cargorol === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.cargorol}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) =>
                                        <input 
                                            disabled={(generico) ? '' : 'disabled'} 
                                            className={(generico) ? "bootstrap-input" : "bootstrap-input-disable"}
                                            type='text'  
                                            onChange={(value) => {
                                                props.onChange(value);  
                                                cambioCargoRol(value)                                                                                          
                                            }}
                                            value={cargorol}                                            
                                        />                                                                           
                                    }                                                                          
                                    control={control}
                                    id="i-cargorol"
                                    key="k-cargorol"                                    
                                    name='cargorol'
                                />                           
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-cargo">Position
                                {
                                    (valores.traducciones.cargo === "" || valores.traducciones.cargo === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.cargo}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.cargos}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            cambioCargo(value)
                                        }}  
                                        isClearable={true}
                                        isDisabled={(generico) ? false : true}
                                        styles={(generico) ? styleNormal : styleDisable}  
                                        value={cargo}                                        
                                    />} 
                                    control={control}
                                    id="i-cargo"
                                    key="k-cargo"                                    
                                    name='cargo'
                                />                           
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-centrocosto">Cost Center
                                {
                                    (valores.traducciones.centrocosto === "" || valores.traducciones.centrocosto === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.centrocosto}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                        options={valores.centroscosto}
                                        placeholder=""
                                        onChange={(value) => {
                                            props.onChange(value)
                                            cambioCentroCosto(value)
                                        }}  
                                        isClearable={true}
                                        isDisabled={(generico) ? false : true}
                                        styles={(generico) ? styleNormal : styleDisable}  
                                        value={centrocosto}                                        
                                    />} 
                                    control={control}
                                    id="i-centrocosto"
                                    key="k-centrocosto"                                    
                                    name='centrocosto'
                                />                           
                            </div>
                        </div>
                        {/*<div className="row">
                            <div className="col-25">
                                <label htmlFor="i-licenciaprincipal">O365 License
                                {
                                    (valores.traducciones.licenciaprincipal === "" || valores.traducciones.licenciaprincipal === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.licenciaprincipal}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) =>
                                        <input 
                                            disabled={(generico) ? '' : 'disabled'} 
                                            className={(generico) ? "bootstrap-input" : "bootstrap-input-disable"}
                                            type='text'  
                                            onChange={(value) => {
                                                props.onChange(value);  
                                                cambioLicenciaPrincipal(value)                                                                                          
                                            }}
                                            value={licenciaprincipal}                                            
                                        />                                                                           
                                    }                                                                          
                                    control={control}
                                    id="i-licenciaprincipal"
                                    key="k-licenciaprincipal"                                    
                                    name='licenciaprincipal'
                                />                           
                            </div>
                        </div>*/}
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-vip">VIP
                                {
                                    (valores.traducciones.vip === "" || valores.traducciones.vip === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.vip}</span>
                                        </div>
                                    )
                                }
                                </label>
                            </div>
                            <div className="col-75">
                                <input
                                    type="checkbox"
                                    id="i-vip"
                                    key="k-vip" 
                                    name="vip"
                                    placeholder="luo"
                                    value={true}
                                    ref={register}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-administrador">Administrator
                                {
                                    (valores.traducciones.administrador === "" || valores.traducciones.administrador === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.administrador}</span>
                                        </div>
                                    )
                                }
                                </label>
                            </div>
                            <div className="col-75">
                                <input
                                    type="checkbox"
                                    id="i-administrador"
                                    key="k-administrador" 
                                    name="administrador"
                                    placeholder="luo"
                                    value={true}
                                    ref={register}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-justificacionadmin">Administrator Justification
                                {
                                    (valores.traducciones.justificacionadmin === "" || valores.traducciones.justificacionadmin === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.justificacionadmin}</span>
                                        </div>
                                    )
                                }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<textarea className="bootstrap-input" rows="3" cols="50"></textarea>}
                                    control={control}
                                    id="i-justificacionadmin"
                                    key="k-justificacionadmin"
                                    defaultValue={registro ? registro.justificacionadmin : ''}
                                    name='justificacionadmin'                                    
                                />                           
                            </div>
                        </div>   
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-usuariomaestropersonal">Employee Master User
                                {
                                    (valores.traducciones.usuariomaestropersonal === "" || valores.traducciones.usuariomaestropersonal === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.usuariomaestropersonal}</span>
                                        </div>
                                    )
                                }
                                </label>
                             </div>
                            <div className="col-75">
                                <input
                                    disabled={'disabled'}
                                    type="checkbox"
                                    id="i-usuariomaestropersonal"
                                    key="k-usuariomaestropersonal" 
                                    name="usuariomaestropersonal"
                                    placeholder="luo"
                                    value={true}
                                    ref={register}
                                />
                            </div>
                        </div>                     
                    </div>
                </div>           
                
                <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px', float: 'left'}}>  
                    <div style={{width: '30%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px'}}>                
                        <button className="boton-from">Save</button>
                        <br></br>
                        <br></br>
                        <br></br>              
                    </div>
                </div>
            
            </form>
        </div>
        
        </>
    )
}

export default CreacionEquipoEscritorio
