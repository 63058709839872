import { useEffect } from 'react'
import { useParams } from "react-router-dom";
import Sidebar from '../Sidebar'

import FormularioDispositivoOT from './FormularioDispositivoOT';
import * as TiIcons from 'react-icons/ti';
import * as AiIcons from 'react-icons/ai';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import { eliminarDispositivoOTRequest } from '../functions/FuncDispositivoOT'
import { eliminarBitacorasRegistroRequest } from '../functions/FuncBitacora'

import Bitacora from '../Bitacora/Bitacora';

import { ContainerForm, ButtonVolver, ButtonEliminacion } from '../Commons/Styles'

import { rolInvalido } from '../Commons/Functions'

const FormDispositivoOT = ({instance, t}) => {

    let { id } = useParams();
    const history = useHistory();
    let priv = sessionStorage.getItem('ser_ot_leer');
    let privEliminacion = sessionStorage.getItem('ser_ot_eliminar');

    function volver()
    {
        history.push("/dispositivosot");
    }

    async function eliminar()
    {
        await eliminarDispositivoOTRequest(id, t).then((response) => {
            if(response.cod === 1)
            {
                eliminarBitacorasRegistroRequest(id, "Dispositivo OT", t);
                swal("Delete success", "", "success").then(() => {history.push("/dispositivosot")});
            }
            else {
                swal("Delete Error", response.mensaje, "error");
            }
        });
    }

    function eliminarRegistro()
    {
        swal({
            title: "Delete Confirmation",
            text: "You want delete this device ot?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((afirmacion) => {
            if(afirmacion)
            {
                eliminar();       
            }
        })
    }


    useEffect(() => {        
        if(priv === "0" || priv  === null || priv === undefined) rolInvalido(history);
    });

    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <div>
            <Sidebar instance={instance} despliegue={false}/>            
            <ContainerForm>
                <div className="headerformulario">
                        <div className="headerform-izq">

                            
                            <ButtonVolver onClick={volver} className="tooltipboton">                    
                                <TiIcons.TiArrowBack/>
                                <span className="tooltiptextboton">{"Back"}</span> 
                            </ButtonVolver>
                        </div>
                        <div className="headerform-mid">
                            <div className="divtituloform">
                                <label className="tituloformulario">Device OT Form</label>
                            </div>                        
                        </div>
                        
                        <div className="headerform-der">
                        {
                            (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>)
                            : (
                                <ButtonEliminacion onClick={eliminarRegistro} className="tooltipboton">
                                    <AiIcons.AiFillDelete/>
                                    <span className="tooltiptextboton">{"Delete"}</span> 
                                </ButtonEliminacion>)
                        }                    
                        <Bitacora tipo={"Dispositivo OT"} id={id} token={t} />
                        </div>
                </div>
                <FormularioDispositivoOT id={id} token={t}/>
                <br></br>
            </ContainerForm>
            
        </div>
    )
}

export default FormDispositivoOT
