import React from 'react';
import { makeStyles, useTheme, withStyles  } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import * as AiIcons from 'react-icons/ai';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
}));

const useStyles = makeStyles({
    table: {
      minWidth: 100,
    }
});

const headCells = [
    { id: 'servidor', numeric: false, disablePadding: true, label: 'Server', size: '80%' },
    { id: 'eliminar', numeric: false, disablePadding: true, label: '', size: '20%' }     
];

const ContainerBusqueda = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
`;

const ContainerTable = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 12,
    },
}))(TableCell);

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const TablaSeleccionadosServidorSala = ({rows, seleccionar}) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(8);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('servidor');

    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function stableSort(array) {
        if(order === 'desc')
        {
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] > b[orderBy]) ? 1 : (b[orderBy] > a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
        else{
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] < b[orderBy]) ? 1 : (b[orderBy] < a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
    }

    function deseleccionarServidor(servidor){
        let nuevosSeleccionados = [];

        rows.map((r) => {
            if(r.servidor !== servidor) nuevosSeleccionados.push(r);
        });

        seleccionar(nuevosSeleccionados);
    }

    return (
        <>
            <ContainerBusqueda>
                <TextField 
                    id="outlined-search" label="Search" size="small" type="search" variant="outlined"                     
                />        
            </ContainerBusqueda>
            <ContainerTable>
                <TableContainer component={Paper}>
                    <Table 
                        className={classes.table} 
                        size="small" 
                        aria-label="enhanced table"
                    >
                        <TableHead style={{ background: '#696158', color: '#fff' }}>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <StyledTableCell 
                                        key={headCell.id}
                                        align={'center'}   
                                        sortDirection={orderBy === headCell.id ? order : false}
                                        style={{ width: headCell.size, color: '#fff !important' }}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                            style={{ background: '#696158', color: '#fff' }}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>                                        
                                    </StyledTableCell>
                                ))}                                
                            </TableRow>
                        </TableHead>
                        <TableBody>  
                            {   
                                (rows !== null)
                                ?                            
                                (    
                                    stableSort(rows)
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)                                    
                                    .map((row) => (                                        
                                        <TableRow hover key={row.servidor} style={{ textDecoration: 'none', fontSize: '10px !important' }}>
                                            <StyledTableCell align="left">{row.servidor}</StyledTableCell>                                            
                                            <StyledTableCell align="center">
                                                <button 
                                                    onClick={() => {
                                                        deseleccionarServidor(row.servidor);
                                                    }}
                                                    className="boton-eliminar tooltipboton">
                                            <AiIcons.AiFillDelete/>
                                            <span className="tooltiptextboton">{"Delete"}</span> 
                                                </button>    
                                            </StyledTableCell>
                                           
                                        </TableRow>
                                    ))
                                )
                                :
                                (
                                    <TableRow></TableRow>
                                )
                            }        
                        </TableBody>            
                    </Table>
                </TableContainer>
                <Table>
                    <TableBody> 
                    <TableRow>
                        <TablePagination  
                            style={{ display: 'flex'}}
                            rowsPerPageOptions={[8]}
                            colSpan={3}
                            count={(rows !== null) ? rows.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'Registros por pagina' },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                    </TableBody>
                </Table>
            </ContainerTable>
        </>
    )
}

export default TablaSeleccionadosServidorSala
