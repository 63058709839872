import * as Rutas from '../RutasServicios'

async function documentos(id, tipo, token)
{ 
    const url = Rutas.URL_Sharepoint + "archivos";    
    //const url = "https://localhost:44345/api/sharepoint/archivos"; 

    const request= {
        "id": id,
        "tipo": tipo,  
        "biblioteca": "ArchivosRegistros"         
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

export async function obtenerDocumentos(id, tipo, token) {
    try {
        var respuesta = await documentos(id, tipo, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function eliminar(idfile, id, tipo, token)
{ 
    const url = Rutas.URL_Sharepoint + "eliminar";    
    //const url = "https://localhost:44345/api/sharepoint/eliminar"; 

    const request= {
        "id": id,
        "tipo": tipo,
        "idfile": "" + idfile,  
        "biblioteca": "ArchivosRegistros"           
    };

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

export async function eliminarDocumento(idfile, id, tipo, token) {
    try {
        var respuesta = await eliminar(idfile, id, tipo, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function crear(archivo, nombre, usuario, id, tipo, token)
{ 
    const url = Rutas.URL_Sharepoint + "upload";    
    //const url = "https://localhost:44345/api/sharepoint/upload"; 

    const request= {
        "id": id,
        "tipo": tipo,
        "archivo": archivo,
        "nombre": nombre,
        "usuario": usuario,  
        "biblioteca": "ArchivosRegistros"          
    };

    console.log(request)

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

export async function crearDocumento(archivo, nombre, usuario, id, tipo, token) {
    try {
        var respuesta = await crear(archivo, nombre, usuario, id, tipo, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}

async function actualizarMarca(id, documento, token)
{ 
    const url = Rutas.URL_EQUIPOS + "documentoentrega";    
    //const url = "https://localhost:44345/api/sharepoint/upload"; 

    const request= {
        "id_equipoescritorio": id,
        "documentoentrega": documento              
    };

    console.log(request)

    var bRequest = JSON.stringify(request, null, 2);
    
    var respuesta = await fetch(url, {
        method: "POST",
        body: bRequest,
        headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json', 
            "Authorization": "Bearer " + token
        }   
    });

    if(respuesta.status === 200)
    {
        var convertido = await respuesta.json();
        var res = null;

        if(convertido != null){        
            res = convertido;
        }

        return res; 
    }
    else return {"mensaje": "Error " + respuesta.status, "cod": 3};    
}

export async function actualizarMarcaDocumento(id, documento, token) {
    try {
        var respuesta = await actualizarMarca(id, documento, token);
        return respuesta;
    } catch (error) {
        return {"mensaje": "error", "cod": 3};
    }
}