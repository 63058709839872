import React from 'react'
import Sidebar from '../Sidebar'
import TablaCuentaServicio from './TablaCuentaServicio';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";

const CuentaServicio = ({instance, t}) => {
  
    let email = sessionStorage.getItem('email');
    let priv = sessionStorage.getItem('cuentaser_leer');
    const history = useHistory();

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });
  
    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <>
            {(email !== null)
            ?
            (<>
                <Sidebar instance={instance} despliegue={false}/>
                <br></br>
                <br></br>    
                <TablaCuentaServicio token={t}/>     
            </>)
            :
            (<>
                <Sidebar instance={instance}/>
                
            </>)
            }
            
        </>
    )
}

export default CuentaServicio