import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import swal from 'sweetalert';
import Select from 'react-select';
import { crearRegPlanta } from '../../functions/FuncMaestroCompuesto'


import { style, styleNormal, styleDisable } from '../../Commons/Styles'


const MaestroPlantaCreacion = ({valores, maestro, token}) => {

    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});
    const [pais, setPais] = React.useState("");
    const [localidad, setLocalidad] = React.useState("");
    const [localidades, setLocalidades] = React.useState(null);

    const [errorPlanta, setErrorPlanta] = useState(false);
    const [errorEmpresa, setErrorEmpresa] = useState(false);
    const [errorNegocio, setErrorNegocio] = useState(false);
    const [errorPais, setErrorPais] = useState(false);
    const [errorLocalidad, setErrorLocalidad] = useState(false);

    const onSubmit = (data) => {

        if(validarDatos(data)){
            swal("Form data", "Mandatory data must be entered", "warning");
        }
        else {
            swal({
                title: "Creation confirmation",
                text: "Do you want to create the record?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (afirmacion) => {
                if(afirmacion)
                {
                    var dataForm = data;

                    await crearRegPlanta(dataForm, token)
                    .then((response) => {  
                        
                        if(response.cod === 1){
                            swal("Creation Success" , "" ,"success");
                        } 
                        else swal ("Creation Error" , response.mensaje , "warning");
                    });
                }
            })
        }

    }

    function validarDatos(data){
        var errores = false;     
        
        if(data.planta === "")
        {
            setErrorPlanta(true);
            errores = true;
        }
        else setErrorPlanta(false);

        if(data.empresa === null || data.empresa === "" || data.empresa === undefined) 
        {
            setErrorEmpresa(true);
            errores = true;
        }
        else setErrorEmpresa(false);

        if(data.negocio === null || data.negocio === "" || data.negocio === undefined) 
        {
            setErrorNegocio(true);
            errores = true;
        }
        else setErrorNegocio(false);

        if(data.pais === null || data.pais === "" || data.pais === undefined) 
        {
            setErrorPais(true);
            errores = true;
        }
        else setErrorPais(false);

        if(data.localidad === null || data.localidad === "" || data.localidad === undefined) 
        {
            setErrorLocalidad(true);
            errores = true;
        }
        else setErrorLocalidad(false);

        return errores;
    }

    const cambioPais = (value) => {
        if(value !== "" && value !== null){
            setPais(value.value);            
            filtrarLocalidad(value.value);            
        } else {
            setPais(""); 
            setLocalidad("");           
        }
    }

    const cambioLocalidad = (value) => { 
        if(value !== "" && value !== null){
            setLocalidad({"label": value.value, "value": value.value});
        } else {
            setLocalidad("");
        }
    }

    function filtrarLocalidad(pais)
    {
        if(pais !== "")
        {
            const filtrotipoArray = valores.filtrolocalidad;
            var array = [];
            if(filtrotipoArray.length > 0){
                filtrotipoArray.map((p) => {
                    if(p.pais === pais) {
                        array.push({"label": p.localidad, "value": p.localidad});
                    }                
                });        
            }

            setLocalidades(array);
        }
    }

    React.useEffect(() => {
        if(localidades === null) setLocalidades(valores.localidades);
    }, []);

    return (
        <>
            <form
                style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                autoComplete="off" onSubmit={handleSubmit(onSubmit)}
            >
                <div className="container-general">                    
                    <div className="row">
                        <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-empresa">Enterprise</label>
                        </div>
                        <div className="col-75">                            
                            <Controller
                                as={<Select/>}
                                name="empresa"
                                options={valores.empresas} 
                                isClearable={true}
                                placeholder=""                                
                                control={control}
                                styles={(errorEmpresa) ? style : styleNormal}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-negocio">Business</label>
                        </div>
                        <div className="col-75">                            
                            <Controller
                                as={<Select/>}
                                name="negocio"
                                options={valores.negocios} 
                                isClearable={true}
                                placeholder=""                                
                                control={control}
                                styles={(errorNegocio) ? style : styleNormal}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-pais">Country</label>
                        </div>
                        <div className="col-75">                            
                            <Controller
                                render={(props) => <Select 
                                    options={valores.paises}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value);
                                        cambioPais(value);
                                    }}  
                                    isClearable={true} 
                                    styles={(errorPais) ? style : styleNormal}                               
                                />} 
                                control={control}
                                id="i-pais"
                                key="k-pais"                                    
                                name='pais'
                            /> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-localidad">Locations</label>
                        </div>
                        <div className="col-75">                            
                            <Controller
                                render={(props) => <Select 
                                    options={localidades}
                                    placeholder=""
                                    onChange={(value) => {
                                        props.onChange(value)
                                        cambioLocalidad(value); 
                                    }}  
                                        isClearable={true}
                                        value={localidad}
                                        isDisabled={(pais !== "") ? false : true}
                                        styles={(pais !== "") ? (errorLocalidad) ? style : styleNormal : styleDisable}                                        
                                    />} 
                                    control={control}
                                    id="i-localidad"
                                    key="k-localidad"                                    
                                    name='localidad'
                                />        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-planta">{maestro.indice}</label> 
                        </div>
                        <div className="col-75">
                            <Controller
                                as={<input className="bootstrap-input" type='text'  />}                                      
                                control={control}
                                id="i-planta"
                                key="k-planta"
                                defaultValue=""                                
                                name='planta'   
                                style={(errorPlanta) ? { borderColor: '#f90000'} : {}}                             
                            />                              
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div style={{width: '60%', marginRight:'auto', marginLeft: 'auto'}}>
                            <button className="boton-from">Save</button>
                        </div>                   
                    </div>
                </div>
            </form>
        </>
    )
}

export default MaestroPlantaCreacion
