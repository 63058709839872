import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import * as Opciones from './OpcionesCertificado'
import { obtenerValores, obtenerCertificado, actualizarCertificado, obtenerServidores, obtenerAplicaciones } from '../functions/FuncCertificado'

import "../css/formularioAplicacion.css";
import "../css/formularioCuentas.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import Loading from '../Loading';
import Select from 'react-select'

import TablaServidoresCertificado from './TablaServidoresCertificado';
import TablaAplicacionesCertificado from './TablaAplicacionesCertificado'
import { validarEmail } from '../functions/MetodosComunes';

import { validarPrivilegioModificacion } from '../Commons/Functions'
import { style, styleNormal, styleDisable } from '../Commons/Styles'

const FormularioCertificado = ({id, token}) => {

    let privEscribir = sessionStorage.getItem('cert_escribir');
    let privLeer = sessionStorage.getItem('cert_leer');
    let paisUser = sessionStorage.getItem('pais');
    let idiomaUser = sessionStorage.getItem('idioma');
    const [privModificar, setPrivModificar] = React.useState(false);

    const [registro, setRegistro] = useState(null);    
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const [tipo, setTipo] = useState("");
    const [proveedor, setProveedor] = useState("");
    const [entidadcertificadora, setEntidad] = useState("");
    const [estado, setEstado] = useState("");
    const [negocio, setNegocio] = useState("");
    const [pais, setPais] = useState("");
    const [responsable, setResponsable] = useState("");

    const [errorNombre, setErrorNombre] = useState(false);
    const [errorFechaCreacion, setErrorFechaCreacion] = useState(false);
    const [errorFechaExpiracion, setErrorFechaExpiracion] = useState(false);
    const [errorDescripcion, setErrorDescripcion] = useState(false);
    const [errorInformara, setErrorInformara] = useState(false);

    const [servidores, setServidores] = useState(null);
    const [aplicaciones, setAplicaciones] = useState(null);

    function mapResponseToRegistro(response){

        setTipo({"label": response.certificado.tipo, "value": response.certificado.tipo});
        setProveedor({"label": response.certificado.proveedor, "value": response.certificado.proveedor});
        setEntidad({"label": response.certificado.entidadcertificadora, "value": response.certificado.entidadcertificadora});
        setEstado({"label": response.certificado.estado, "value": response.certificado.estado});
        setPais({"label": response.certificado.pais, "value": response.certificado.pais});
        setNegocio({"label": response.certificado.negocio, "value": response.certificado.negocio});
        setResponsable({"label": response.certificado.responsable, "value": response.certificado.responsable});

        setRegistro({
            "id_certificado": response.certificado.id_certificado,
            "nombre": response.certificado.nombre,
            "descripcion": response.certificado.descripcion,
            "observaciones": response.certificado.observaciones,
            "informara": response.certificado.informara,
            "fechacreacion": (response.certificado.fechacreacion !== null) ? new Date(response.certificado.fechacreacion) : null,
            "fechaexpiracion": (response.certificado.fechaexpiracion !== null) ? new Date(response.certificado.fechaexpiracion) : null,
            "modificadopor": response.certificado.modificadopor,
            "fechaupdate": (response.certificado.fechaupdate !== null) ? new Date(response.certificado.fechaupdate) : null,
            "tipo": {"label": response.certificado.tipo, "value": response.certificado.tipo},
            "proveedor": {"label": response.certificado.proveedor, "value": response.certificado.proveedor},
            "entidadcertificadora": {"label": response.certificado.entidadcertificadora, "value": response.certificado.entidadcertificadora},
            "estado": {"label": response.certificado.estado, "value": response.certificado.estado},
            "pais": {"label": response.certificado.pais, "value": response.certificado.pais},
            "negocio": {"label": response.certificado.negocio, "value": response.certificado.negocio},
            "responsable": {"label": response.certificado.responsable, "value": response.certificado.responsable},
        });
    }

    async function obtenerDatosBD()
    {
        await obtenerServidores(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setServidores(response.servidores);
            }
        });

        await obtenerAplicaciones(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setAplicaciones(response.aplicaciones);
            }
        });

        await obtenerCertificado(id, token)
        .then((response) => {
            if(response.cod === 1)
            {
                validarPrivilegioModificacion(privEscribir, paisUser, "", "", response.certificado, setPrivModificar);

                
                mapResponseToRegistro(response)
                
            }
            else {
                console.log(response)
            }
        });

        await obtenerValores(privLeer, paisUser, "CERTIFICATE", idiomaUser, token)
        .then((response) => {
                
          if(response.cod === 1)
          {
              const val = {
                  "tipos": response.tipos,
                  "proveedores": response.proveedores,
                  "entidades": response.entidades,
                  "paises": response.paises,
                  "estados": response.estados,
                  "negocios": response.negocios,
                  "responsables": response.responsables,
                  "traducciones": response.traducciones
              };

              let array = [];
              const listas = ["tipos", "proveedores", "entidades", "paises", "negocios", "responsables", "estados"];

              listas.forEach(lista => {
              array = [];
              val[lista].map((u) => { array.push({"label": u, "value": u});})
              val[lista] = array;
              });

              setValores(val);
          }
        });
    }

    async function actualizarServidores()
    {
        await obtenerServidores(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setServidores(response.servidores);
            }
        });
    }

    async function actualizarAplicaciones()
    {
        await obtenerAplicaciones(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setAplicaciones(response.aplicaciones);
            }
        });
    }

    async function actualizarCertificadoRelaciones()
    {
        obtenerCertPostActualizacion();
    }

    async function obtenerCertPostActualizacion()
    {
        await obtenerCertificado(id, token)
        .then((response) => {
            if(response.cod === 1)
            {
                mapResponseToRegistro(response);
            }
            else {
                console.log(response)
            }
        });
    }

    async function actualizar(datosActuales, dataAnterior)
    {
        const datosform = datosActuales;

        let nombreusuario = sessionStorage.getItem('nombre');
        datosform.modificadopor = nombreusuario;
        datosform.fechaupdate = new Date();

        await actualizarCertificado(datosform, dataAnterior, id, token)
        .then((response) => {
            if(response.cod === 1)
            {
              obtenerCertPostActualizacion();
              swal("Successful update", "", "success")
              .then(() => {
                                                         
                });
            }
            else if(response.cod === 0)
            {
                swal("Update error", response.mensaje, "error")
                .then(() => {
                                                     
                });
            }
            else {
                console.log(response)
                swal("Update error", "An error occurred during the update. Please contact the administrator", "error")
                .then(() => {
                                         
                });
            }
        })

    }

    const onSubmit = (data) => {

        let errorMessage = validarDatos(data);;

        if(errorMessage.length > 0)
        {
            swal("Form data", errorMessage, "warning");     
        }
        else{
            swal({
                title: "Update confirmation",
                text: "Do you want to update the registry?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if (afirmacion) {
                    await actualizar(data, registro); 
                }
                else setRegistro(data);      
            });
        }
    }

    function validarDatos(data)
    {
        let errors = {
            "error_type_01": "Mandatory data must be entered",
            "error_type_02": "Invalid email",
        }
        let error = "";

        if(data.nombre === null || data.nombre === "" || data.nombre === undefined) 
        {
            setErrorNombre(true);
            error = errors["error_type_01"];
        }
        else setErrorNombre(false);        

        if(data.informara === null || data.informara === "" || data.informara === undefined) 
        {
            setErrorInformara(true);
            error = errors["error_type_01"];
        }
        else setErrorInformara(false);

        if(data.fechacreacion === null || data.fechacreacion === "" || data.fechacreacion === undefined) 
        {
            setErrorFechaCreacion(true);
            error = errors["error_type_01"];
        }
        else setErrorFechaCreacion(false);

        if(data.fechaexpiracion === null || data.fechaexpiracion === "" || data.fechaexpiracion === undefined) 
        {
            setErrorFechaExpiracion(true);
            error = errors["error_type_01"];
        }
        else setErrorFechaExpiracion(false);

        if(data.descripcion === null || data.descripcion === "" || data.descripcion === undefined) 
        {
            setErrorDescripcion(true);
            error = errors["error_type_01"];
        }
        else setErrorDescripcion(false);

        if(data.informara.length > 0)
        {
            if(!validarEmail(data.informara))
            {
                setErrorInformara(true);
                error = errors["error_type_02"];
            }
            else setErrorInformara(false);
        }

        return error;
    }

    React.useEffect(() => {
        if (registro !== null) {

            Opciones.campos.forEach(c => {
                setValue(c, registro[c], {shouldValidate: true,shouldDirty: true})
            });
            
        }
        else obtenerDatosBD();
    }, [registro]);

    if(registro === null || valores === null) return (<Loading />)
    else
    return (
        <>
        <div className="divizq-cuenta">
                    <form 
                        style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                        autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="container">
                        <div className="row">
                                <div className="col-25">
                                <label htmlFor="i-id">ID</label> 
                                </div>
                                <div className="col-75">
                                <Controller
                                    as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                    control={control}
                                    id="i-id"
                                    key="k-id"
                                    defaultValue={id}
                                    name='id'
                                /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-nombre">Name<label style={{color: 'red'}}>{"*"}</label>
                                    {
                                    (valores.traducciones.nombre === "" || valores.traducciones.nombre === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                        <span class="tooltiptext">{valores.traducciones.nombre}</span>
                                        </div>
                                    )
                                    }
                                </label> 
                                </div>
                                <div className="col-75">
                                <Controller
                                    as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                    control={control}
                                    id="i-nombre"
                                    key="k-nombre"
                                    defaultValue={registro ? registro.nombre : ''}
                                    name='nombre'
                                    style={(errorNombre) ? { borderColor: '#f90000'} : {}} 
                                /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-estado">State<label style={{color: 'red'}}>{"*"}</label>
                                    {
                                    (valores.traducciones.estado === "" || valores.traducciones.estado === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                        <span class="tooltiptext">{valores.traducciones.estado}</span>
                                        </div>
                                    )
                                    }
                                </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                        options={valores.estados}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={false} 
                                        isDisabled={(privModificar) ? false : true}
                                        styles={(privModificar) ? styleNormal : styleDisable}
                                        control={control}
                                        defaultValue={registro ? registro.estado : ''}
                                    />}                                       
                                    control={control}
                                    id="i-estado"
                                    key="k-estado"
                                    defaultValue={registro ? registro.estado : ''}
                                    name='estado'
                                /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-tipo">Type<label style={{color: 'red'}}>{"*"}</label>
                                    {
                                    (valores.traducciones.tipo === "" || valores.traducciones.tipo === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                        <span class="tooltiptext">{valores.traducciones.tipo}</span>
                                        </div>
                                    )
                                    }
                                </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                        options={valores.tipos}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={false} 
                                        isDisabled={(privModificar) ? false : true}
                                        styles={(privModificar) ? styleNormal : styleDisable}
                                        control={control}
                                        defaultValue={registro ? registro.tipo : ''}
                                    />}                                       
                                    control={control}
                                    id="i-tipo"
                                    key="k-tipo"
                                    defaultValue={registro ? registro.tipo : ''}
                                    name='tipo'
                                /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-proveedor">Provider<label style={{color: 'red'}}>{"*"}</label>
                                    {
                                    (valores.traducciones.proveedor === "" || valores.traducciones.proveedor === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                        <span class="tooltiptext">{valores.traducciones.proveedor}</span>
                                        </div>
                                    )
                                    }
                                </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                        options={valores.proveedores}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={false} 
                                        isDisabled={(privModificar) ? false : true}
                                        styles={(privModificar) ? styleNormal : styleDisable}
                                        control={control}
                                        defaultValue={registro ? registro.proveedor : ''}
                                    />}                                       
                                    control={control}
                                    id="i-proveedor"
                                    key="k-proveedor"
                                    defaultValue={registro ? registro.proveedor : ''}
                                    name='proveedor'
                                /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-entidadcertificadora">Certifying Entity<label style={{color: 'red'}}>{"*"}</label>
                                    {
                                    (valores.traducciones.entidadcertificadora === "" || valores.traducciones.entidadcertificadora === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                        <span class="tooltiptext">{valores.traducciones.entidadcertificadora}</span>
                                        </div>
                                    )
                                    }
                                </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                        options={valores.entidades}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={false} 
                                        isDisabled={(privModificar) ? false : true}
                                        styles={(privModificar) ? styleNormal : styleDisable}
                                        control={control}
                                        defaultValue={registro ? registro.entidadcertificadora : ''}
                                    />}                                       
                                    control={control}
                                    id="i-entidadcertificadora"
                                    key="k-entidadcertificadora"
                                    defaultValue={registro ? registro.entidadcertificadora : ''}
                                    name='entidadcertificadora'
                                /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-pais">Country<label style={{color: 'red'}}>{"*"}</label>
                                    {
                                    (valores.traducciones.pais === "" || valores.traducciones.pais === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                        <span class="tooltiptext">{valores.traducciones.pais}</span>
                                        </div>
                                    )
                                    }
                                </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                        options={valores.paises}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={false} 
                                        isDisabled={(privModificar) ? false : true}
                                        styles={(privModificar) ? styleNormal : styleDisable}
                                        control={control}
                                        defaultValue={registro ? registro.pais : ''}
                                    />}                                       
                                    control={control}
                                    id="i-pais"
                                    key="k-pais"
                                    defaultValue={registro ? registro.pais : ''}
                                    name='pais'
                                /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}} htmlFor="i-negocio">Business<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                        (valores.traducciones.negocio === "" || valores.traducciones.negocio === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.negocio}</span>
                                            </div>
                                        )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                        options={valores.negocios}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={false} 
                                        isDisabled={(privModificar) ? false : true}
                                        styles={(privModificar) ? styleNormal : styleDisable}
                                        control={control}
                                        defaultValue={registro ? registro.negocio : ''}
                                    />}                                       
                                    control={control}
                                    id="i-negocio"
                                    key="k-negocio"
                                    defaultValue={registro ? registro.negocio : ''}
                                    name='negocio'
                                /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-responsable">Responsable<label style={{color: 'red'}}>{"*"}</label>
                                    {
                                    (valores.traducciones.responsable === "" || valores.traducciones.responsable === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                        <span class="tooltiptext">{valores.traducciones.responsable}</span>
                                        </div>
                                    )
                                    }
                                </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        render={(props) => <Select 
                                        options={valores.responsables}
                                        onChange={(value) => {
                                            props.onChange(value);                                                        
                                        }}
                                        placeholder=""
                                        isClearable={false} 
                                        isDisabled={(privModificar) ? false : true}
                                        styles={(privModificar) ? styleNormal : styleDisable}
                                        control={control}
                                        defaultValue={registro ? registro.responsable : ''}
                                    />}                                       
                                    control={control}
                                    id="i-responsable"
                                    key="k-responsable"
                                    defaultValue={registro ? registro.responsable : ''}
                                    name='responsable'
                                /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}} htmlFor="i-fechacreacion">Creation Date (YYYY/MM/DD)<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                        (valores.traducciones.fechacreacion === "" || valores.traducciones.fechacreacion === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.fechacreacion}</span>
                                            </div>
                                        )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        name="fechacreacion"
                                        id="i-fechacreacion"
                                        className="bootstrap-input"
                                        control={control}
                                        defaultValue={registro ? registro.fechacreacion : ''}
                                        render={({ onChange, value }) => (
                                            <DatePicker        
                                                disabled={(!privModificar) ? 'disabled' : ''}                                                
                                                selected={value}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                onChange={onChange}
                                                className={(errorFechaCreacion) ? "bootstrap-input-invalid" : (privModificar) ? "bootstrap-input" : "bootstrap-input-disable"}                                                                                                  
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}} htmlFor="i-fechaexpiracion">Expiration Date (YYYY/MM/DD)<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                        (valores.traducciones.fechaexpiracion === "" || valores.traducciones.fechaexpiracion === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.fechaexpiracion}</span>
                                            </div>
                                        )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        name="fechaexpiracion"
                                        id="i-fechaexpiracion"
                                        className="bootstrap-input"
                                        control={control}
                                        defaultValue={registro ? registro.fechaexpiracion : ''}
                                        render={({ onChange, value }) => (
                                            <DatePicker        
                                                disabled={(!privModificar) ? 'disabled' : ''}                                                
                                                selected={value}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                onChange={onChange}
                                                className={(errorFechaExpiracion) ? "bootstrap-input-invalid" : (privModificar) ? "bootstrap-input" : "bootstrap-input-disable"}
                                                                                                
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}} htmlFor="i-informara">Inform To<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                        (valores.traducciones.informara === "" || valores.traducciones.informara === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.informara}</span>
                                            </div>
                                        )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input disabled={(privModificar) ? "" : 'disabled'} className={(errorInformara) ? "bootstrap-input-invalid" : (privModificar) ? "bootstrap-input" : "bootstrap-input-disable"} type='text'  />}                                      
                                        control={control}
                                        id="i-informara"
                                        key="k-informara"
                                        defaultValue={registro ? registro.informara : ''}
                                        name='informara'
                                        style={(errorInformara) ? { borderColor: '#f90000'} : {}} 
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label style={{fontWeight: 'bold'}} htmlFor="i-descripcion">Description<label style={{color: 'red'}}>{"*"}</label>
                                        {
                                        (valores.traducciones.descripcion === "" || valores.traducciones.descripcion === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.descripcion}</span>
                                            </div>
                                        )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<textarea disabled={(privModificar) ? "" : "disabled"} className={(errorDescripcion) ? "bootstrap-input-invalid" : (privModificar) ? "bootstrap-input" : "bootstrap-input-disable"} rows="3" cols="50"></textarea>}                                     
                                        control={control}
                                        id="i-descripcion"
                                        key="k-descripcion"
                                        defaultValue={registro ? registro.descripcion : ''}
                                        name='descripcion'
                                        style={(errorDescripcion) ? { borderColor: '#f90000'} : {}} 
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-observaciones">Observations
                                        {
                                        (valores.traducciones.observaciones === "" || valores.traducciones.observaciones === null) ? (<></>)
                                        : (
                                            <div class="tooltip">&nbsp;&#x1F6C8;
                                            <span class="tooltiptext">{valores.traducciones.observaciones}</span>
                                            </div>
                                        )
                                        }
                                    </label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<textarea disabled={(privModificar) ? "" : "disabled"} className={(privModificar) ? "bootstrap-input" : "bootstrap-input-disable"} rows="3" cols="50"></textarea>}                                     
                                        control={control}
                                        id="i-observaciones"
                                        key="k-observaciones"
                                        defaultValue={registro ? registro.observaciones : ''}
                                        name='observaciones'
                                        //style={(errorNombre) ? { borderColor: '#f90000'} : {}} 
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-modificadopor">Modified by</label> 
                                </div>
                                <div className="col-75">
                                    <Controller
                                        as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                        control={control}
                                        id="i-modificadopor"
                                        key="k-modificadopor"
                                        defaultValue={registro ? registro.modificadopor : ''}
                                        name='modificadopor'                                
                                    /> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-25">
                                    <label htmlFor="i-fechaupdate">Modification Date (YYYY/MM/DD)</label>
                                </div>
                                <div className="col-75">
                                    <Controller
                                        name="fechaupdate"
                                        id="i-fechaupdate"
                                        className="bootstrap-input"
                                        control={control}
                                        defaultValue={registro ? registro.fechaupdate : ''}
                                        render={({ onChange, value }) => (
                                            <DatePicker
                                                disabled="disabled"
                                                selected={value}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                onChange={onChange}
                                                className="bootstrap-input-disable"
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>
                        </div>
                        <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px', float: 'left'}}>  
                            <div style={{width: '30%', marginRight:'auto', marginLeft: '30%', marginTop: '10px'}}>                
                                <button className="boton-from">Save</button>   
                                <br></br>
                                <br></br>
                                <br></br>              
                            </div>
                        </div>
                    </form>
        </div>
        <div className='divder-cuenta'>                
            <div className="containerappder"> 
                <div className='subtitulo-box'>
                    <div className='subtitulo'>
                        <label >Servers</label>
                    </div>                    
                </div>    
                <TablaServidoresCertificado rows={servidores} id={id} camposExcel={Opciones.camposExcelServidores} privModificar={privModificar} actualizarCertificado={actualizarCertificadoRelaciones} actualizarServidores={actualizarServidores} nombreApp={registro.nombre} token={token}/>
            </div>
            <br></br>
            <div className="containerappder"> 
                <div className='subtitulo-box'>
                    <div className='subtitulo'>
                        <label >Applications</label>
                    </div>                    
                </div>                    
                <TablaAplicacionesCertificado rows={aplicaciones} id={id} camposExcel={Opciones.camposExcelAplicaciones} privModificar={privModificar} actualizarCertificado={actualizarCertificadoRelaciones} actualizarAplicaciones={actualizarAplicaciones} nombreApp={registro.nombre} token={token}/>
            </div>
            <br></br>
        </div>
        </>
    )
}

export default FormularioCertificado