import React, { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { actualizarNotificacion } from "../../functions/FuncNotificacion"
import swal from 'sweetalert';
import * as Opciones from './OpcionesCorreoNotificaciones'

const FormCorreoNotificaciones = ({registro, actualizarTabla, token}) => {

    const { handleSubmit, control, register, setValue, errors } = useForm({
        mode: "onBlur",
    });


    const [errorCC1, setErrorCC1] = useState(false);
    const [errorCC2, setErrorCC2] = useState(false);

    const actualizar = async (data) => {

       await actualizarNotificacion(registro.id, data, token).then((response) => {
            if(response.cod === 1) {
                actualizarTabla();
                swal("Successful update", "", "success");
            }
            else swal("Upload error" , response.mensaje ,"error");
        });
    }

    const onSubmit = (data) => {
        if (validarDatos(data))
          swal("Form data", "Mandatory data must be entered", "warning");
        else {
          swal({
            title: "Update confirmation",
            text: "Do you want to update the registry?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then(async (afirmacion) => {
            if (afirmacion) {
              actualizar(data);
            }
          });
        }
    };

    const validarDatos = (data) => {

        let errores = false;

       if(data.cc1 === "" || data.cc1 === null || data.cc1 === undefined)
       {
            setErrorCC1(true);
            errores = true;
       }
       else setErrorCC1(false);

       return errores;
    }

    useEffect(() => {

        Opciones.campos.forEach(c => {
          setValue(c, registro[c], {shouldValidate: true,shouldDirty: true})
        });
    }, [registro])

  return (
    <>
        <form
          style={{ width: "80%", marginRight: "auto", marginLeft: "auto" }}
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >

            <div className="container">
                <div className='titulonot'>
                    <h4>{(registro) ? registro.nombre : ""}</h4>
                </div>
                <div className="row">
                    <div className="col-25">
                    <label
                        style={{ fontWeight: "bold" }}
                        htmlFor="i-cc1"
                    > CC1
                    </label>
                    </div>
                    <div className="col-75">
                    <Controller
                        as={
                        <input                            
                            className="bootstrap-input"
                            type="text"
                        />
                        }
                        control={control}
                        id="i-cc1"
                        key="k-cc1"
                        defaultValue={registro ? registro.cc1 : ""}
                        name="cc1"
                        style={errorCC1 ? { borderColor: "#f90000" } : {}}
                    />
                    </div>
                </div>
                <div className="row">
                    <div className="col-25">
                    <label
                        style={{ fontWeight: "bold" }}
                        htmlFor="i-cc2"
                    > CC2
                    </label>
                    </div>
                    <div className="col-75">
                    <Controller
                        as={
                        <input                            
                            className="bootstrap-input"
                            type="text"
                        />
                        }
                        control={control}
                        id="i-cc2"
                        key="k-cc2"
                        defaultValue={registro ? registro.cc2 : ""}
                        name="cc2"
                        style={errorCC2 ? { borderColor: "#f90000" } : {}}
                        
                    />
                    </div>
                </div>
                <br></br>
                <div className="divbotonnot">
                    <button className="boton-from">Save</button>
                    <br></br>
                    <br></br>
                </div>
            </div>

        </form>
    
    </>
  )
}

export default FormCorreoNotificaciones