import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import '../css/aplicaciones.css'
import swal from 'sweetalert';

import ExportarExcel from '../ExportarExcel';

import Loading from '../Loading';
import FiltroTablaAplicacionBack from './FiltroTablaAplicacionBack';
import * as Opciones from './OpcionesAplicacionBack';

import ExportarExcelBitacoras from '../ExportarExcelBitacoras';

import { TablaRegistros } from '../Commons/TablaRegistros';

import { ContainerBusqueda, ContainerTable } from '../Commons/Styles'

const headCells = [    
    { id: 'id_aplicacionback', ordertype: 'text', numeric: false, disablePadding: true, label: 'ID', size: '5%' },  
    { id: 'estado', ordertype: 'text', numeric: false, disablePadding: true, label: 'State', size: '5%' },
    { id: 'software', ordertype: 'text', numeric: false, disablePadding: true, label: 'Application', size: '10%' },    
    { id: 'version', ordertype: 'text', numeric: false, disablePadding: true, label: 'Version', size: '5%' },
    { id: 'pais', ordertype: 'text', numeric: false, disablePadding: true, label: 'Country', size: '10%' },
    { id: 'negocio', ordertype: 'text', numeric: false, disablePadding: true, label: 'Business', size: '10%' },    
    { id: 'tipolicencia', ordertype: 'text', numeric: false, disablePadding: true, label: 'Type of license', size: '10%' },   
    { id: 'numerofactura', ordertype: 'text', numeric: false, disablePadding: true, label: 'Invoice Number', size: '10%' },
    { id: 'fechafactura', ordertype: 'text', numeric: false, disablePadding: true, label: 'Invoice Date', size: '10%' },
    { id: 'numerolicencia', ordertype: 'text', numeric: false, disablePadding: true, label: 'Licenses', size: '5%' },
    { id: 'vencimientocontrato', ordertype: 'int', numeric: false, disablePadding: true, label: 'Expiration Date', size: '10%' },
    { id: 'cantidadequipos', ordertype: 'int', numeric: false, disablePadding: true, label: 'Desktops', size: '5%' },
    { id: 'fechaupdate', ordertype: 'text', numeric: false, disablePadding: true, label: 'Update Date', size: '5%' }
];

const TablaAplicacionBack = ({token}) => {

    const [loading, setLoading] = React.useState(false);
    const [rows, setRows] = useState(null);
    const [filter, setFilter] = useState("");

    let privEliminacion = sessionStorage.getItem('ser_eliminar');
    const [bitacoras, setBitacoras] = useState(null);

    function obtenerBitacoras(registros)
    {
        console.log(registros)
        setBitacoras(registros);
    }

    function aplicarFiltro(registros)
    {
        if(registros == null) setRows(registros); 
        else if(registros.length === 0)
        {
            setRows(registros)            
            swal ("There are no records", "No information is recorded with the filters used", "info");
        }
        else setRows(registros);
    }

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.software !== "") ? (row.software.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true; 
            
            r = (row.version !== "") ? (row.version.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true; 

            r = (row.estado !== "") ? (row.estado.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true; 

            r = (row.pais !== "") ? (row.pais.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true; 

            r = (row.negocio !== "") ? (row.negocio.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true; 

            r = (row.fechafactura !== "") ? (row.software.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true; 

            r = (row.tipolicencia !== "") ? (row.tipolicencia.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true; 

            r = (row.fechaupdate !== "") ? (row.fechaupdate.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true; 
        })
    }

  return (
    <>
        <FiltroTablaAplicacionBack aplicarFiltro={aplicarFiltro} obtenerBitacoras={obtenerBitacoras} load={setLoading} token={token} />
        {
            (rows !== null)
            ?
            (
               
            <>
              <ContainerBusqueda> 
                  <br></br>                         
                  <TextField id="outlined-search" 
                      label="Busqueda" 
                      size="small" 
                      type="search" 
                      variant="outlined" 
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                  />
                  <ExportarExcel rows={rows} columnas={Opciones.camposExcel} nombreSheet="ApplicationsBackOffice" nombreArchivo="ApplicationsBackOfficeExport"/>
                  {
                    (privEliminacion === "0" || privEliminacion  === null || privEliminacion === undefined) ? (<></>) :
                    (bitacoras === null) ? (<></>)
                    : (                                                  
                      <ExportarExcelBitacoras subtitulo={"Logs"} rows={bitacoras} columnas={Opciones.camposExcelBitacora} nombreSheet="LogsApplicationsBackOffice" nombreArchivo="LogsApplicationsBackOfficeExport"/>
                    )
                  }
                  <br></br>                  
              </ContainerBusqueda>
              <ContainerTable>
                  <TablaRegistros
                      headCells = { headCells }
                      rows = { buscar(rows) }
                      url = { '/aplicacionesback/' }
                      idFieldName = { 'id_aplicacionback' }
                      initialSortByField = { 'id_aplicacionback' }
                      initialSortDirection = { 'desc' }
                      />
              </ContainerTable>                    
            </>)
            :
            (
                (loading) ? (
                    <>
                        <br></br>
                        <br></br>
                        <ContainerTable>
                            <Loading />
                        </ContainerTable>
                    </>
                )  : null 
            )
      
        }
    
    </>
  )
}

export default TablaAplicacionBack