export const camposExcelCarga = [   
    {nombre:"ROW", valor:"fila"},
    {nombre:"DETAIL", valor:"detalle"}    
]

export const privilegiosUsuario = [ 
    {label: "DENIED", value: "DENIED"},
    {label: "LOCATION", value: "LOCATION"},
    {label: "COUNTRY", value: "COUNTRY"},
    {label: "GLOBAL", value: "GLOBAL"},
    {label: "PLANT", value: "PLANT"},
]

export const privilegiosUsuario_Nivel1 = [ 
    {label: "DENIED", value: "DENIED"},
    {label: "LOCATION", value: "LOCATION"}    
]


export const privilegiosUsuario_Nivel2 = [ 
    {label: "DENIED", value: "DENIED"},
    {label: "LOCATION", value: "LOCATION"},
    {label: "COUNTRY", value: "COUNTRY"},
    {label: "PLANT", value: "PLANT"}, 
]

export const privilegiosUsuario_Global = [    
    {label: "GLOBAL", value: "GLOBAL"}
]

export const privilegiosUsuario_Country = [    
    {label: "COUNTRY", value: "COUNTRY"}  
]

export const privilegiosUsuario_Error = [    
    {label: "ERROR", value: "ERROR"}  
]


export const idiomas = [    
    {label: "ENGLISH", value: "ENGLISH"},
    {label: "SPANISH", value: "SPANISH"},
    {label: "PORTUGUESE", value: "PORTUGUESE"}  
]

export const camposExcel = [   
    {nombre:"NAME", valor:"nombre"},
    {nombre:"EMAIL", valor:"correo"},
    {nombre:"USERNAME", valor:"username"},
    {nombre:"STATE", valor:"estado"},
    {nombre:"AREA", valor:"area"},
    {nombre:"ROL", valor:"rol"},
    {nombre:"LANGUAGE", valor:"idioma"},
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"LOCATION", valor:"localidad"},
    {nombre:"PLANT", valor:"planta"},

    {nombre:"SERVER READ", valor:"ser_leer"},
    {nombre:"SERVER WRITE", valor:"ser_escribir"},
    {nombre:"SERVER CREATE", valor:"ser_crear"},
    {nombre:"SERVER LOAD", valor:"ser_cargamasiva"},
    {nombre:"SERVER DELETE", valor:"ser_eliminar"},

    {nombre:"APPLICATION READ", valor:"app_leer"},
    {nombre:"APPLICATION WRITE", valor:"app_escribir"},
    {nombre:"APPLICATION CREATE", valor:"app_crear"},
    {nombre:"APPLICATION LOAD", valor:"app_cargamasiva"},
    {nombre:"APPLICATION DELETE", valor:"app_eliminar"},
    {nombre:"APPLICATION MASTER", valor:"app_maestro"},

    {nombre:"DESKTOP READ", valor:"esc_leer"},
    {nombre:"DESKTOP WRITE", valor:"esc_escribir"},
    {nombre:"DESKTOP CREATE", valor:"esc_crear"},
    {nombre:"DESKTOP LOAD", valor:"esc_cargamasiva"},
    {nombre:"DESKTOP DELETE", valor:"esc_eliminar"},

    {nombre:"EQUIPMENT READ", valor:"com_leer"},
    {nombre:"EQUIPMENT WRITE", valor:"com_escribir"},
    {nombre:"EQUIPMENT CREATE", valor:"com_crear"},
    {nombre:"EQUIPMENT LOAD", valor:"com_cargamasiva"},
    {nombre:"EQUIPMENT DELETE", valor:"com_cargamasiva"},

    {nombre:"DEVICE READ", valor:"dis_leer"},
    {nombre:"DEVICE WRITE", valor:"dis_escribir"},
    {nombre:"DEVICE CREATE", valor:"dis_crear"},
    {nombre:"DEVICE LOAD", valor:"dis_cargamasiva"},
    {nombre:"DEVICE DELETE", valor:"dis_eliminar"},

    {nombre:"ROOM READ", valor:"sala_leer"},
    {nombre:"ROOM WRITE", valor:"sala_escribir"},
    {nombre:"ROOM CREATE", valor:"sala_crear"},
    {nombre:"ROOM LOAD", valor:"sala_cargamasiva"},
    {nombre:"ROOM DELETE", valor:"sala_eliminar"},

    {nombre:"ACCOUNT SERVICE READ", valor:"cuentaser_leer"},
    {nombre:"ACCOUNT SERVICE WRITE", valor:"cuentaser_escribir"},
    {nombre:"ACCOUNT SERVICE CREATE", valor:"cuentaser_crear"},
    {nombre:"ACCOUNT SERVICE LOAD", valor:"cuentaser_cargamasiva"},
    {nombre:"ACCOUNT SERVICE DELETE", valor:"cuentaser_eliminar"},

    {nombre:"CERTIFICATE READ", valor:"cert_leer"},
    {nombre:"CERTIFICATE WRITE", valor:"cert_escribir"},
    {nombre:"CERTIFICATE CREATE", valor:"cert_crear"},
    {nombre:"CERTIFICATE LOAD", valor:"cert_cargamasiva"},
    {nombre:"CERTIFICATE DELETE", valor:"cert_eliminar"},

    {nombre:"APPLICATIONBACK READ", valor:"appback_leer"},
    {nombre:"APPLICATIONBACK WRITE", valor:"appback_escribir"},
    {nombre:"APPLICATIONBACK CREATE", valor:"appback_crear"},
    {nombre:"APPLICATIONBACK LOAD", valor:"appback_cargamasiva"},
    {nombre:"APPLICATIONBACK DELETE", valor:"appback_eliminar"},

    {nombre:"MASTER READ", valor:"mas_leer"},
    {nombre:"MASTER WRITE", valor:"mas_escribir"},
    {nombre:"OTHER SOURCES", valor:"admin_otrasfuentes"},
    {nombre:"MASTER EMPLOYEE", valor:"admin_personal"},
    {nombre:"MASTER USERS", valor:"admin_usuarios"},    

    {nombre:"NOTIFICATION", valor:"notificacion"},
    
    {nombre:"DEVICE OT READ", valor:"ser_ot_leer"},
    {nombre:"DEVICE OT WRITE", valor:"ser_ot_escribir"},
    {nombre:"DEVICE OT CREATE", valor:"ser_ot_crear"},
    {nombre:"DEVICE OT LOAD", valor:"ser_ot_cargamasiva"},
    {nombre:"DEVICE OT DELETE", valor:"ser_ot_eliminar"},

    {nombre:"LOGIN DATE", valor:"fechaingreso"},
]

export const campos = [    
    "nombre",
    "correo",
    "area",
    "rol",
    "idioma",
    "pais",
    "localidad",
    "planta",
    "estado",
    "fechaingreso",
    "notificacion",

    "ser_leer",
    "ser_escribir",
    "ser_crear",
    "ser_cargamasiva",
    "ser_eliminar",

    "ser_ot_leer",
    "ser_ot_escribir",
    "ser_ot_crear",
    "ser_ot_cargamasiva",
    "ser_ot_eliminar",

    "app_leer",
    "app_escribir",
    "app_crear",
    "app_cargamasiva",
    "app_eliminar",
    "app_maestro",
    "esc_leer",
    "esc_escribir",
    "esc_crear",
    "esc_cargamasiva",
    "esc_eliminar",
    "com_leer",
    "com_escribir",
    "com_crear",
    "com_cargamasiva",
    "com_eliminar",
    "dis_leer",
    "dis_escribir",
    "dis_crear",
    "dis_cargamasiva",
    "dis_eliminar",
    "sala_leer",
    "sala_escribir",
    "sala_crear",
    "sala_cargamasiva",
    "sala_eliminar",
    "cert_leer",
    "cert_escribir",
    "cert_crear",
    "cert_cargamasiva",
    "cert_eliminar",
    "appback_leer",
    "appback_escribir",
    "appback_crear",
    "appback_cargamasiva",
    "appback_eliminar",
    "mas_leer",
    "mas_escribir",
    "admin_personal",
    "admin_otrasfuentes",
    "admin_usuarios"    
]