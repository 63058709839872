import React, { useState } from 'react';
import { makeStyles, useTheme, withStyles  } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';

import * as AiIcons from 'react-icons/ai';
import * as MdIcons from 'react-icons/md';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import swal from 'sweetalert';
import ModalServidores from './ModalServidores';
import { eliminarRelacionesServidor } from '../functions/FuncCuentaServicio'
import ExportarExcel from '../ExportarExcel';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
}));

const headCells = [
  { id: 'servidor', numeric: false, disablePadding: true, label: 'Server', size: '25%' }, 
  { id: 'estado', numeric: false, disablePadding: true, label: 'Condition', size: '20%' },
  { id: 'ambiente', numeric: false, disablePadding: true, label: 'Environment', size: '25%' },   
  { id: 'tiposervidor', numeric: false, disablePadding: true, label: 'Server Type', size: '20%' },
  { id: 'descripcion', numeric: false, disablePadding: true, label: 'Description', size: '5%' },
  { id: 'boton', numeric: false, disablePadding: true, label: '', size: '5%' }           
];

const ContainerBusqueda = styled.div`
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
`;

const ContainerTable = styled.div`
    width: 98%;
    margin-left: auto;
    margin-right: auto;
`;

const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.white,
    },
    body: {
        textDecoration: 'none',
        fontSize: 12
    },
}))(TableCell);

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const TablaServidoresCuentas = ({rows, id, privModificar, actualizarCuenta, actualizarServidores, nombreApp, camposExcel, token}) => {

    const [filter, setFilter] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(4);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('servidor');
    const classes = useStyles();
    let privilegio = sessionStorage.getItem('cuentaser_escribir');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function buscar(rows)
    {
        if(rows === null) return rows;

        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.servidor !== "") ? (row.servidor.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
            
            r = (row.estado !== "") ? (row.estado.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.ambiente !== "") ? (row.ambiente.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.tiposervidor !== "") ? (row.tiposervidor.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
        })
    }

    function stableSort(array) {
        if(order === 'desc')
        {
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] > b[orderBy]) ? 1 : (b[orderBy] > a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
        else{
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] < b[orderBy]) ? 1 : (b[orderBy] < a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
    }

    async function desvincular(servidores)
    {
        await eliminarRelacionesServidor(id, servidores, token)
        .then((response) => {
            if(response.cod === 1){

                actualizarCuenta();
                actualizarServidores();

                swal ("Successful operation" ,
                    "The server has been unlinked successfully" ,
                    "success")
                .then(() => {
                    
                });
            }
            else {
                swal ("Operation error",
                    response.mensaje,
                    "warning");
            }
        });
    }

    function eliminarRelacion(servidores)
    {
        swal({
            title: "Desvinculation confirmation",
            text: "Do you want to unlink Server " + servidores[0].servidor + " from the Account?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((afirmacion) => {
            if (afirmacion) {
                desvincular(servidores);
            }                   
        });
    }


  return (
    <>
        <ContainerBusqueda>             
            <TextField 
                style={{backgroundColor: '#fff'}}
                id="outlined-search" label="Search" size="small" type="search" variant="outlined" 
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
            />
                         
            <div className="divbotones-relacion">
                <div className="col-30-right">
                    {
                        (!privModificar) ? (<></>)
                        : (<ModalServidores id={id} actualizarServidores={actualizarServidores} actualizarCuenta={actualizarCuenta} nombreApp={nombreApp} token={token}/>)
                    }
                    </div>
                    <div className="col-30-right">
                    <ExportarExcel rows={rows} columnas={camposExcel} nombreSheet="Servers" nombreArchivo="ServersExport"/>
                </div>
            </div>           
        </ContainerBusqueda>
        <ContainerTable>
            <TableContainer component={Paper}>
                <Table 
                    className={classes.table} 
                    size="small" 
                    aria-label="enhanced table"
                >
                    <TableHead style={{ background: '#696158', color: '#fff' }}>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <StyledTableCell 
                                    key={headCell.id}
                                    align={'center'}   
                                    sortDirection={orderBy === headCell.id ? order : false}
                                    style={{ width: headCell.size, color: '#fff !important' }}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                        style={{ background: '#696158', color: '#fff' }}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>                                        
                                </StyledTableCell>
                            ))}                                
                        </TableRow>
                    </TableHead>
                    <TableBody>  
                            {   
                                (rows !== null)
                                ?                            
                                (    
                                    stableSort(buscar(rows))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)                                    
                                    .map((row) => (                                        
                                        <TableRow hover key={row.servidor} style={{ textDecoration: 'none', fontSize: '10px !important', backgroundColor: (row.estado !== "INACTIVE") ? '' : '#efbbbb'}}>
                                            <StyledTableCell component={Link} to={`/servidores/${row.id_servidor}`} align="left">{row.servidor}</StyledTableCell>
                                            <StyledTableCell component={Link} to={`/servidores/${row.id_servidor}`} align="left">{row.estado}</StyledTableCell>
                                            <StyledTableCell component={Link} to={`/servidores/${row.id_servidor}`} align="center">{row.ambiente}</StyledTableCell>
                                            <StyledTableCell component={Link} to={`/servidores/${row.id_servidor}`} align="center">{row.tiposervidor}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <button 
                                                    type="button"
                                                    onClick={() => {
                                                        swal({
                                                            text: row.descripcion,
                                                            className: 'sweetalert-observacion'
                                                        });
                                                        }}
                                                    className="boton-observacion">
                                                    <MdIcons.MdDescription/>
                                                </button>    
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (!privModificar) ? (
                                                        <></>
                                                    ) : 
                                                    (
                                                        <button 
                                                            type="button"
                                                            onClick={() => {
                                                                const servidor = [{"servidor": row.servidor, "id_servidor": row.id_servidor}];
                                                                eliminarRelacion(servidor);                                                       
                                                            }}
                                                            className="boton-eliminar tooltipboton">
                                                            <AiIcons.AiFillDelete/>
                                                            <span className="tooltiptextboton">{"Delete"}</span> 
                                                        </button>
                                                    )
                                                }
                                                 
                                            </StyledTableCell>
                                           
                                        </TableRow>
                                    ))
                                )
                                :
                                (
                                    <TableRow></TableRow>
                                )
                            }        
                    </TableBody>
                </Table>
                <Table>
                    <TableBody> 
                        <TableRow>
                            <TablePagination  
                                style={{ display: 'flex'}}
                                rowsPerPageOptions={[4]}
                                colSpan={3}
                                count={(rows !== null) ? rows.length : 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'Registros por pagina' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </ContainerTable>
    </>
  )
}

export default TablaServidoresCuentas