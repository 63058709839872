import React from 'react'
import { obtenerdatosTabla, obtenerdatosFiltros, obtenerdatosTablaRelApps, obtenerTablaBitacoras } from '../functions/FuncServidor'

import swal from 'sweetalert';
import FiltroBaseNuevo from '../FiltroBaseNuevo';
import  * as Metodos from '../functions/MetodosComunes'

import { ContainerFiltros, FilaContainerFiltros, Button } from '../Commons/Styles'

const FiltrosServidor = ({aplicarFiltro, obtenerRelaciones, obtenerBitacoras, load, token}) => {

    let priv = sessionStorage.getItem('ser_leer');
    let paisUser = sessionStorage.getItem('pais');
    let localidadUser = sessionStorage.getItem('localidad');    
    const [valores, setValores] = React.useState(null);

    const [red, setRed] = React.useState("");
    const [redes, setRedes] = React.useState(null);
    const [estado, setEstado] = React.useState("");
    const [estados, setEstados] = React.useState(null);
    const [localidad, setLocalidad] = React.useState("");    
    const [localidades, setLocalidades] = React.useState(null);    
    const [pais, setPais] = React.useState("");    
    const [paises, setPaises] = React.useState(null);
    const [negocio, setNegocio] = React.useState("");
    const [negocios, setNegocios] = React.useState(null);
    const [sistemaOperativo, setSistemaOperativo] = React.useState("");
    const [servidor, setServidor] = React.useState("");

    async function registrosTabla(){
        load(true);
        aplicarFiltro(null);

        var redApi = (red !== null && red !== undefined && red !== "") ? red.value : null;
        var paisApi = (pais !== null && pais !== undefined && pais !== "") ? pais.value : null;
        var localidadApi = (localidad !== null && localidad !== undefined && localidad !== "") ? localidad.value : null;
        var negocioApi = (negocio !== null && negocio !== undefined && negocio !== "") ? negocio.value : null;
        var estadoApi = (estado !== null && estado !== undefined && estado !== "") ? estado.value : null;        
        var servidorApi = (servidor !== null && servidor !== undefined && servidor !== "") ? servidor : null;
        var sistemaOperativoApi = (sistemaOperativo !== null && sistemaOperativo !== undefined && sistemaOperativo !== "") ? sistemaOperativo : null;

        (redApi !== null) ? sessionStorage.setItem('filtroservidor_red', redApi) : sessionStorage.setItem('filtroservidor_red', null);
        (localidadApi !== null) ? sessionStorage.setItem('filtroservidor_localidad', localidadApi) : sessionStorage.setItem('filtroservidor_localidad', null);
        (paisApi !== null) ? sessionStorage.setItem('filtroservidor_pais', paisApi) : sessionStorage.setItem('filtroservidor_pais', null);
        (negocioApi !== null) ? sessionStorage.setItem('filtroservidor_negocio', negocioApi) : sessionStorage.setItem('filtroservidor_negocio', null);
        (estadoApi !== null) ? sessionStorage.setItem('filtroservidor_estado', estadoApi) : sessionStorage.setItem('filtroservidor_estado', null);
        (servidorApi !== null) ? sessionStorage.setItem('filtroservidor_servidor', servidorApi) : sessionStorage.setItem('filtroservidor_servidor', null);
        (sistemaOperativoApi !== null) ? sessionStorage.setItem('filtroservidor_sistemaoperativo', sistemaOperativoApi) : sessionStorage.setItem('filtroservidor_sistemaoperativo', null);

        await obtenerdatosTablaRelApps(estadoApi, localidadApi, negocioApi, paisApi, sistemaOperativoApi, servidorApi, token)
        .then((response) => {
            if(response.cod === 1) {
                obtenerRelaciones(response.relaciones);
            }
            else obtenerRelaciones(null);
        })

        await obtenerTablaBitacoras(estadoApi, localidadApi, negocioApi, paisApi, sistemaOperativoApi, servidorApi, token)
        .then((response) => {
            if(response.cod === 1) {                
                obtenerBitacoras(response.bitacoras);
            }
            else obtenerBitacoras(null);
        })

        await obtenerdatosTabla(estadoApi, localidadApi, negocioApi, paisApi, sistemaOperativoApi, servidorApi, redApi, token)
        .then((response) => {
            if(response.cod === 1) {
                aplicarFiltro(response.servidores);
            }
            else {
                aplicarFiltro(null);
                swal ("Search error" , response.mensaje , "warning");
            }
            
            load(false);
        });
    }

    async function obtenerFiltros(){

        if(priv !== "0" && priv !== null && priv !== undefined)
        {
            await obtenerdatosFiltros(token)
            .then((response) => {

                const val = {          
                    "paises": response.paises,
                    "filtrolocalidad": response.filtrolocalidad,
                    "localidades": response.localidades,
                    "plantas": response.plantas,
                    "filtroplantas": response.filtroplantas,
                    "estados": response.estados,
                    "negocios": response.negocios,
                    "redes": response.redes
                }; 

                if(priv === "1"){
                    setLocalidades(Metodos.opcionesFiltrosValor(val.localidades, localidadUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));                    
                }


                if(priv === "2"){
                    setLocalidades(Metodos.opcionesFiltrosLocalidad(val.filtrolocalidad, paisUser));
                    setPaises(Metodos.opcionesFiltrosValor(val.paises, paisUser));                    
                }

                if(priv === "3"){
                    setLocalidades(val.localidades = Metodos.opcionesFiltros(val.localidades));
                    setPaises(Metodos.opcionesFiltros(val.paises));                    
                }

                setNegocios(Metodos.opcionesFiltros(val.negocios));
                setEstados(Metodos.opcionesFiltros(val.estados));
                setRedes(Metodos.opcionesFiltros(val.redes));  

                setValores(val);
            }); 
        }
    }

    function filtrosAnteriores()
    {
        let filtroservidor_localidad = sessionStorage.getItem('filtroservidor_localidad');       
        if(filtroservidor_localidad !== "null") setLocalidad({label: filtroservidor_localidad, value: filtroservidor_localidad})

        let filtroservidor_pais = sessionStorage.getItem('filtroservidor_pais');     
        if(filtroservidor_pais !== "null") setPais({label: filtroservidor_pais, value: filtroservidor_pais})

        let filtroservidor_negocio = sessionStorage.getItem('filtroservidor_negocio');        
        if(filtroservidor_negocio !== "null") setNegocio({label: filtroservidor_negocio, value: filtroservidor_negocio})

        let filtroservidor_estado = sessionStorage.getItem('filtroservidor_estado');        
        if(filtroservidor_estado !== "null") setEstado({label: filtroservidor_estado, value: filtroservidor_estado})

        let filtroservidor_servidor = sessionStorage.getItem('filtroservidor_servidor');        
        if(filtroservidor_servidor !== "null") setServidor(filtroservidor_servidor)
        
        let filtroservidor_sistemaoperativo = sessionStorage.getItem('filtroservidor_sistemaoperativo');
        if(filtroservidor_sistemaoperativo !== "null") setSistemaOperativo(filtroservidor_sistemaoperativo)

        let filtroservidor_red = sessionStorage.getItem('filtroservidor_red');        
        if(filtroservidor_red !== "null") setRed({label: filtroservidor_red, value: filtroservidor_red})
    }

    React.useEffect(() =>{

        if(valores === null) obtenerFiltros();
            
        filtrosAnteriores(); 
    }, []);

    const cambioLocalidad = (valor) => {
        setLocalidad(valor);
    };

    const cambioNegocio = (valor) => {
        setNegocio(valor);
    };

    const cambioRed = (valor) => {
        setRed(valor);
    };

    const cambioEstado = (valor) => {
        setEstado(valor);
    };

    const cambioPais = (valor) => {

        if(valor !== null && valor !== undefined && valor !== ""){
            setPais(valor);
            filtrarLocalidad(valor.value);
        } 
        else{      
            setPais(null);      
            var array = [];
            if(valores.filtrolocalidad.length > 0){

                valores.filtrolocalidad.map((p) => {
                    array.push({label: p.localidad, value: p.localidad});
                });  
                setLocalidades(array);  
            }
        }
    };

    function filtrarLocalidad(filtro)
    {
        var array = [];
        if(valores.filtrolocalidad.length > 0){
            valores.filtrolocalidad.map((p) => {
                if(p.pais === filtro) array.push({label: p.localidad, value: p.localidad});
            });  

            setLocalidad(null);
            setLocalidades(array); 
        }
    }

    if(valores === null) return (<></>)
    else return (
        <>
        <FilaContainerFiltros>
        <ContainerFiltros>  
            <FiltroBaseNuevo
                opciones={paises}
                valor={pais}
                nombre={"Country"}
                namecampo="pais"
                cambio={cambioPais}
                proporcion={"25%"}
                error={false}
            />

            <FiltroBaseNuevo
                opciones={localidades}
                valor={localidad}
                nombre={"Location"}
                namecampo="localidad"
                cambio={cambioLocalidad}
                proporcion={"25%"}
                error={false}
            />

            <FiltroBaseNuevo
                opciones={redes}
                valor={red}
                nombre={"Network"}
                namecampo="red"
                cambio={cambioRed}
                proporcion={"20%"}
                error={false}
            />

            <div style={{width: "30.4%", marginLeft: '10px', marginRight: 'auto',}}>
                <input className="bootstrap-input-filtro" value={sistemaOperativo} type='text' onChange={(e) => setSistemaOperativo(e.target.value)} placeholder={"Operating System"}  />
            </div>

        </ContainerFiltros>
        <ContainerFiltros>

            <FiltroBaseNuevo
                opciones={estados}
                valor={estado}
                nombre={"State"}
                namecampo="estado"
                cambio={cambioEstado}
                proporcion={"30%"}
                error={false}
            />

            <FiltroBaseNuevo
                opciones={negocios}
                valor={negocio}
                nombre={"Business"}
                namecampo="negocio"
                cambio={cambioNegocio}
                proporcion={"30%"}
                error={false}
            />

            <div style={{width: "28%", marginLeft: '10px', marginRight: 'auto',}}>
                <input className="bootstrap-input-filtro" value={servidor} type='text' onChange={(e) => setServidor(e.target.value)} placeholder={"Server"}  />
            </div>
            
            <div style={{width: "12%", marginLeft: '10px', marginRight: 'auto',}}>
                <Button onClick={registrosTabla}>Display</Button>
            </div>
        </ContainerFiltros>        
        </FilaContainerFiltros>
        <br></br>
        <hr style={{width: '85%', marginLeft: 'auto', marginRight: 'auto'}}></hr>
        </>
    )
}

export default FiltrosServidor
