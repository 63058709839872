import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import Sidebar from '../Sidebar'
import * as TiIcons from 'react-icons/ti';
import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import * as MdIcons from 'react-icons/md';
import * as RiIcons from 'react-icons/ri';
import * as VsIcons from 'react-icons/vsc';
import { useHistory } from "react-router-dom";
import FormularioEquipoEscritorio from './FormularioEquipoEscritorio';
import swal from 'sweetalert';
import { eliminarEquipoRequest } from '../functions/FuncEquipoEscritorio'
import ModalReemplazo from './ModalReemplazo';
import Loading from '../Loading';

const ContainerForm = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
`;

const Reemplazado = ({instance, t}) => {

    let { id } = useParams();
    const history = useHistory();

    async function redireccion()
    {        
        await new Promise(resolve => setTimeout(resolve, 2000));
        history.push("/equipos/" + id);
    }

    React.useEffect(() => {
        redireccion();
    })

    return (
        <>
            <Sidebar instance={instance} despliegue={false}/>
            <ContainerForm>
                <Loading />
            </ContainerForm>
            
            
        </>
    )
}

export default Reemplazado
