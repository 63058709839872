import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(Link)`
  display: flex;
  border: 1px solid #716960;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 20px;
  text-decoration: none;
  font-size: 18px;
  &:hover {
    background: rgb(126, 123, 123);
    border-left: 4px solid #696158;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #887c7c;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  &:hover {
    background: rgb(126, 123, 123);
    cursor: pointer;
  }
`;

const SubMenu = ({ item }) => {

  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
        {(item.path === '' ) 
        ? (
            <SidebarLink to='#' onClick={item.subNav && showSubnav}>
                <div>
                {item.icon}
                <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>
                {item.subNav && subnav
                    ? item.iconOpened
                    : item.subNav
                    ? item.iconClosed
                    : null}
                </div>
            </SidebarLink>
        ) :
        (
            <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
                <div>
                {item.icon}
                <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>
                {item.subNav && subnav
                    ? item.iconOpened
                    : item.subNav
                    ? item.iconClosed
                    : null}
                </div>
            </SidebarLink>
        )
        }      
        {subnav &&
            item.subNav.map((item, index) => {

              let priv = sessionStorage.getItem(item.privilegio);              

              if(priv !== "0" && priv !== null && priv !== ""){
                return (
                  <DropdownLink to={item.path} key={index}>
                  {item.icon}
                  <SidebarLabel>{item.title}</SidebarLabel>
                  </DropdownLink>
                );
              }            
            })
        }
    </>
  );
};

export default SubMenu;