import React from 'react'
import  Sidebar  from '../Sidebar'
import styled from 'styled-components';
import CreacionAplicacion from './CreacionAplicacion';
import * as TiIcons from 'react-icons/ti';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';

import { ContainerForm, ButtonVolver } from '../Commons/Styles'


const FormCreateAplicacion = ({instance, t}) => {

    const history = useHistory();
    let priv = sessionStorage.getItem('app_leer');

    function volver()
    {
        history.push("/aplicaciones");
    }

    function rolInvalido()
    {
        swal("Access Denied", "You currently do not have sufficient privileges", "error").then( () => {
            history.push("/")
        });
    }

    React.useEffect(() => {        
        if(priv === null) rolInvalido();
        if(priv === undefined) rolInvalido();
        if(priv === "0") rolInvalido();        
    });

    if(priv === "0" || priv  === null || priv === undefined) return (<></>)
    else return (
        <div>
            <Sidebar instance={instance} despliegue={false}/>
            <ContainerForm>
                <div className="headerformulario">
                    <div className="headerform-izq">
                        <ButtonVolver onClick={volver} className="tooltipboton">
                        <TiIcons.TiArrowBack/>             
                        <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>
                    </div>
                    <div className="headerform-mid">
                        <div className="divtituloform">
                            <label className="tituloformulario">Application Creation Form</label>
                        </div>                        
                    </div>
                </div>   
                <CreacionAplicacion token={t}/> 
            </ContainerForm>
            <br></br>
        </div>
    )
}

export default FormCreateAplicacion
