import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import * as Opciones from './OpcionesCuentaServicio'
import { obtenerValores, obtenerCuenta, actualizarCuenta, obtenerServidores, obtenerAplicaciones } from '../functions/FuncCuentaServicio'

import "../css/formularioAplicacion.css";
import "../css/formularioCuentas.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import Loading from '../Loading';
import Select from 'react-select'
import TablaAplicacionesCuenta from './TablaAplicacionesCuenta';
import TablaServidoresCuentas from './TablaServidoresCuentas';

import { validarPrivilegioModificacion } from '../Commons/Functions'
import { style, styleNormal, styleDisable } from '../Commons/Styles'

const FormularioCuentaServicio = ({id, token}) => {

    let privEscribir = sessionStorage.getItem('cuentaser_escribir');
    let privLeer = sessionStorage.getItem('cuentaser_leer');
    let paisUser = sessionStorage.getItem('pais');
    let idiomaUser = sessionStorage.getItem('idioma');
    const [privModificar, setPrivModificar] = React.useState(false);

    const [registro, setRegistro] = useState(null);    
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    const [errorEstado, setErrorEstado] = useState(false);
    const [errorNombre, setErrorNombre] = useState(false);
    const [errorTipoCuenta, setErrorTipoCuenta] = useState(false);
    const [errorPais, setErrorPais] = useState(false);
    const [errorNegocio, setErrorNegocio] = useState(false);
    const [errorFechaCreacion, setErrorFechaCreacion] = useState(false);
    const [errorResponsable, setErrorResponsable] = useState(false);
    const [errorSolicitadapor, setErrorSolicitadapor] = useState(false);
    const [errorDescripcion, setErrorDescripcion] = useState(false);

    const [servidores, setServidores] = useState(null);
    const [aplicaciones, setAplicaciones] = useState(null);
  
    function mapResponseToRegistro(response){
        setRegistro({
            "id_cuentaservicio": response.cuenta.id_cuentaservicio,
            "nombre": response.cuenta.nombre,
            "tipocuenta": (response.cuenta.tipocuenta === null || response.cuenta.tipocuenta === "") ? null : {"label": response.cuenta.tipocuenta, "value": response.cuenta.tipocuenta},
            "descripcion": response.cuenta.descripcion,
            "observaciones": response.cuenta.observaciones,
            "solicitadapor": response.cuenta.solicitadapor,
            "fechacreacion": (response.cuenta.fechacreacion !== null) ? new Date(response.cuenta.fechacreacion) : null,
            "modificadopor": response.cuenta.modificadopor,
            "fechaupdate": (response.cuenta.fechaupdate !== null) ? new Date(response.cuenta.fechaupdate) : null,
            "estado": {"label": response.cuenta.estado, "value": response.cuenta.estado},
            "pais": {"label": response.cuenta.pais, "value": response.cuenta.pais},
            "negocio": {"label": response.cuenta.negocio, "value": response.cuenta.negocio},
            "responsable": {"label": response.cuenta.responsable, "value": response.cuenta.responsable},
        });
    }

    async function obtenerDatosBD()
    {
        await obtenerServidores(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setServidores(response.servidores);
            }
        });

        await obtenerAplicaciones(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setAplicaciones(response.aplicaciones);
            }
        });

        await obtenerCuenta(id, token)
        .then((response) => {
            if(response.cod === 1)
            { 
                validarPrivilegioModificacion(privEscribir, paisUser, "", "", response.cuenta, setPrivModificar);
            
                mapResponseToRegistro(response);
                
            }
            else {
                console.log(response)
            }
        });

        await obtenerValores(privLeer, paisUser, "ACCOUNT SERVICE", idiomaUser, token)
        .then((response) => {                
            if(response.cod === 1)
            {
                const val = {
                "tipos": response.tipos,
                "paises": response.paises,
                "estados": response.estados,
                "negocios": response.negocios,
                "responsables": response.responsables,
                "traducciones": response.traducciones
                };

                let array = [];
                const listas = ["tipos", "paises", "negocios", "responsables", "estados"];

                listas.forEach(lista => {
                array = [];
                val[lista].map((u) => { array.push({"label": u, "value": u});})
                val[lista] = array;
                });

                setValores(val);
            }
            else {
                console.log(response)
            }
        });
    }

    async function obtenerCuentaPostActualizacion()
    {
        await obtenerCuenta(id, token)
        .then((response) => {
            if(response.cod === 1)
            {   
                mapResponseToRegistro(response);
            }
            else {
                console.log(response)
            }
        });
    }

    async function actualizar(datosActuales, dataAnterior)
    {
        const datosform = datosActuales;

        let nombreusuario = sessionStorage.getItem('nombre');
        datosform.modificadopor = nombreusuario;
        datosform.fechaupdate = new Date();

        await actualizarCuenta(datosform, dataAnterior, id, token)
        .then((response) => {
            if(response.cod === 1)
            {
                obtenerCuentaPostActualizacion();
                swal("Successful update", "", "success")
                .then(() => {
                                                         
                });
            }
            else if(response.cod === 0)
            {
                swal("Update error", response.mensaje, "error")
                .then(() => {
                                                     
                });
            }
            else {
                console.log(response)
                swal("Update error", "An error occurred during the update. Please contact the administrator", "error")
                .then(() => {
                                         
                });
            }
        })

    }

    const onSubmit = (data) => {

        var validacion = validarDatos(data);

        if(validacion)
        {
            swal("Form data", "Mandatory data must be entered", "warning");
        }
        else{
            swal({
                title: "Update confirmation",
                text: "Do you want to update the registry?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if (afirmacion) {
                    await actualizar(data, registro); 
                }
                else setRegistro(data);      
            });
        }
    }

    function validarDatos(data)
    {
        var errores = false;

        if(data.nombre === null || data.nombre === "" || data.nombre === undefined) 
        {
            setErrorNombre(true);
            errores = true;
        }
        else setErrorNombre(false);

        if(data.tipocuenta === null || data.tipocuenta === "" || data.tipocuenta === undefined) 
        {
            setErrorTipoCuenta(true);
            errores = true;
        }
        else setErrorTipoCuenta(false); 

        if(data.solicitadapor === null || data.solicitadapor === "" || data.solicitadapor === undefined) 
        {
            setErrorSolicitadapor(true);
            errores = true;
        }
        else setErrorSolicitadapor(false);

        if(data.fechacreacion === null || data.fechacreacion === "" || data.fechacreacion === undefined) 
        {
            setErrorFechaCreacion(true);
            errores = true;
        }
        else setErrorFechaCreacion(false);

        if(data.descripcion === null || data.descripcion === "" || data.descripcion === undefined) 
        {
            setErrorDescripcion(true);
            errores = true;
        }
        else setErrorDescripcion(false);

        return errores;
    }

    async function actualizarServidores()
    {
        await obtenerServidores(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setServidores(response.servidores);
            }
        });
    }

    async function actualizarAplicaciones()
    {
        await obtenerAplicaciones(id, token)
        .then((response) =>{
            if(response.cod === 1){
                setAplicaciones(response.aplicaciones);
            }
        });
    }

    async function actualizarCuentaRelaciones()
    {
        obtenerCuentaPostActualizacion();
    }

    React.useEffect(() => {
        if (registro !== null) {

            Opciones.campos.forEach(c => {
                setValue(c, registro[c], {shouldValidate: true,shouldDirty: true})
            });

        }
        else obtenerDatosBD();
    }, [registro]);

    if(registro === null || valores === null) return (<Loading />)
    else
    return (
        <>
            <div className="divizq-cuenta">
                <form 
                    style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                    autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-nombre">Account Name<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.nombre === "" || valores.traducciones.nombre === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.nombre}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                            <Controller
                                as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                control={control}
                                id="i-nombre"
                                key="k-nombre"
                                defaultValue={registro ? registro.nombre : ''}
                                name='nombre'
                                style={(errorNombre) ? { borderColor: '#f90000'} : {}} 
                            /> 
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-tipocuenta">Account Type<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.tipocuenta === "" || valores.traducciones.tipocuenta === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.tipocuenta}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.tipos}
                                    onChange={(value) => {
                                        props.onChange(value);                                                        
                                    }}
                                    placeholder=""
                                    isClearable={false} 
                                    isDisabled={(privModificar) ? false : true}
                                    styles={(errorTipoCuenta) ? style : (privModificar) ? styleNormal : styleDisable}
                                    control={control}
                                    defaultValue={registro ? registro.tipocuenta : ''}
                                />}                                       
                                control={control}
                                id="i-tipocuenta"
                                key="k-tipocuenta"
                                defaultValue={registro ? registro.tipocuenta : ''}
                                name='tipocuenta'
                            /> 
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-estado">State<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.estado === "" || valores.traducciones.estado === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.estado}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.estados}
                                    onChange={(value) => {
                                        props.onChange(value);                                                        
                                    }}
                                    placeholder=""
                                    isClearable={false} 
                                    isDisabled={(privModificar) ? false : true}
                                    styles={(errorEstado) ? style : (privModificar) ? styleNormal : styleDisable}
                                    control={control}
                                    defaultValue={registro ? registro.estado : ''}
                                />}                                       
                                control={control}
                                id="i-estado"
                                key="k-estado"
                                defaultValue={registro ? registro.estado : ''}
                                name='estado'
                            /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-pais">Country<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.pais === "" || valores.traducciones.pais === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.pais}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.paises}
                                    onChange={(value) => {
                                        props.onChange(value);                                                        
                                    }}
                                    placeholder=""
                                    isClearable={false} 
                                    isDisabled={(privModificar) ? false : true}
                                    styles={(errorPais) ? style : (privModificar) ? styleNormal : styleDisable}
                                    control={control}
                                    defaultValue={registro ? registro.pais : ''}
                                />}                                       
                                control={control}
                                id="i-pais"
                                key="k-pais"
                                defaultValue={registro ? registro.pais : ''}
                                name='pais'
                            /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-negocio">Business<label style={{color: 'red'}}>{"*"}</label>
                                    {
                                    (valores.traducciones.negocio === "" || valores.traducciones.negocio === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                        <span class="tooltiptext">{valores.traducciones.negocio}</span>
                                        </div>
                                    )
                                    }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.negocios}
                                    onChange={(value) => {
                                        props.onChange(value);                                                        
                                    }}
                                    placeholder=""
                                    isClearable={false} 
                                    isDisabled={(privModificar) ? false : true}
                                    styles={(errorNegocio) ? style : (privModificar) ? styleNormal : styleDisable}
                                    control={control}
                                    defaultValue={registro ? registro.negocio : ''}
                                />}                                       
                                control={control}
                                id="i-negocio"
                                key="k-negocio"
                                defaultValue={registro ? registro.negocio : ''}
                                name='negocio'
                            /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-responsable">Responsable<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.responsable === "" || valores.traducciones.responsable === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.responsable}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.responsables}
                                    onChange={(value) => {
                                        props.onChange(value);                                                        
                                    }}
                                    placeholder=""
                                    isClearable={false} 
                                    isDisabled={(privModificar) ? false : true}
                                    styles={(errorResponsable) ? style : (privModificar) ? styleNormal : styleDisable}
                                    control={control}
                                    defaultValue={registro ? registro.responsable : ''}
                                />}                                       
                                control={control}
                                id="i-responsable"
                                key="k-responsable"
                                defaultValue={registro ? registro.responsable : ''}
                                name='responsable'
                            /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-solicitadapor">Requested by<label style={{color: 'red'}}>{"*"}</label>
                                    {
                                    (valores.traducciones.solicitadapor === "" || valores.traducciones.solicitadapor === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                        <span class="tooltiptext">{valores.traducciones.solicitadapor}</span>
                                        </div>
                                    )
                                    }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input className="bootstrap-input" type='text'  />}                                      
                                    control={control}
                                    id="i-solicitadapor"
                                    key="k-solicitadapor"
                                    defaultValue={registro ? registro.solicitadapor : ''}
                                    name='solicitadapor'
                                    style={(errorSolicitadapor) ? { borderColor: '#f90000'} : {}} 
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-fechacreacion">Creation Date (YYYY/MM/DD)<label style={{color: 'red'}}>{"*"}</label>
                                    {
                                    (valores.traducciones.fechacreacion === "" || valores.traducciones.fechacreacion === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                        <span class="tooltiptext">{valores.traducciones.fechacreacion}</span>
                                        </div>
                                    )
                                    }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    name="fechacreacion"
                                    id="i-fechacreacion"
                                    className="bootstrap-input"
                                    control={control}
                                    defaultValue={registro ? registro.fechacreacion : ''}
                                    render={({ onChange, value }) => (
                                        <DatePicker        
                                            disabled={(!privModificar) ? 'disabled' : ''}                                                
                                            selected={value}
                                            dateFormat="yyyy/MM/dd"
                                            autoComplete="off"
                                            onChange={onChange}
                                            className={(errorFechaCreacion) ? "bootstrap-input-invalid" : (privModificar) ? "bootstrap-input" : "bootstrap-input-invalid"}
                                            //style={(errorFechaCreacion) ? { borderColor: '#f90000'} : {}}                                                       
                                        />
                                    )}
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label style={{fontWeight: 'bold'}} htmlFor="i-descripcion">Description<label style={{color: 'red'}}>{"*"}</label>
                                    {
                                    (valores.traducciones.descripcion === "" || valores.traducciones.descripcion === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                        <span class="tooltiptext">{valores.traducciones.descripcion}</span>
                                        </div>
                                    )
                                    }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<textarea className="bootstrap-input" rows="3" cols="50"></textarea>}                                     
                                    control={control}
                                    id="i-descripcion"
                                    key="k-descripcion"
                                    defaultValue={registro ? registro.descripcion : ''}
                                    name='descripcion'
                                    style={(errorDescripcion) ? { borderColor: '#f90000'} : {}} 
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-observaciones">Observations
                                    {
                                    (valores.traducciones.observaciones === "" || valores.traducciones.observaciones === null) ? (<></>)
                                    : (
                                        <div class="tooltip">&nbsp;&#x1F6C8;
                                        <span class="tooltiptext">{valores.traducciones.observaciones}</span>
                                        </div>
                                    )
                                    }
                                </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<textarea className="bootstrap-input" rows="3" cols="50"></textarea>}                                     
                                    control={control}
                                    id="i-observaciones"
                                    key="k-observaciones"
                                    defaultValue={registro ? registro.observaciones : ''}
                                    name='observaciones'
                                    //style={(errorNombre) ? { borderColor: '#f90000'} : {}} 
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-modificadopor">Modified by</label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    as={<input disabled='disabled' className="bootstrap-input-disable" type='text'  />}                                      
                                    control={control}
                                    id="i-modificadopor"
                                    key="k-modificadopor"
                                    defaultValue={registro ? registro.modificadopor : ''}
                                    name='modificadopor'                                
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="i-fechaupdate">Modification Date (YYYY/MM/DD)</label>
                            </div>
                            <div className="col-75">
                                <Controller
                                    name="fechaupdate"
                                    id="i-fechaupdate"
                                    className="bootstrap-input"
                                    control={control}
                                    defaultValue={registro ? registro.fechaupdate : ''}
                                    render={({ onChange, value }) => (
                                        <DatePicker
                                            disabled="disabled"
                                            selected={value}
                                            dateFormat="yyyy/MM/dd"
                                            autoComplete="off"
                                            onChange={onChange}
                                            className="bootstrap-input-disable"
                                        />
                                    )}
                                /> 
                            </div>
                        </div>
                    </div>
                    <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px', float: 'left'}}>  
                        <div style={{width: '30%', marginRight:'auto', marginLeft: '30%', marginTop: '10px'}}>                
                            <button className="boton-from">Save</button>   
                            <br></br>
                            <br></br>
                            <br></br>              
                        </div>
                    </div>
                </form>
            </div>

            <div className='divder-cuenta'>                
                <div className="containerappder"> 
                    <div className='subtitulo-box'>
                        <div className='subtitulo'>
                            <label >Servers</label>
                        </div>                    
                    </div>                    
                    <TablaServidoresCuentas rows={servidores} id={id} camposExcel={Opciones.camposExcelServidores} privModificar={privModificar} actualizarCuenta={actualizarCuentaRelaciones} actualizarServidores={actualizarServidores} nombreApp={registro.nombre} token={token}/>
                </div>
                <br></br>

                <div className="containerappder"> 
                    <div className='subtitulo-box'>
                        <div className='subtitulo'>
                            <label >Applications</label>
                        </div>                    
                    </div>                    
                    <TablaAplicacionesCuenta rows={aplicaciones} id={id} camposExcel={Opciones.camposExcelAplicaciones} privModificar={privModificar} actualizarCuenta={actualizarCuentaRelaciones} actualizarAplicaciones={actualizarAplicaciones} nombreApp={registro.nombre} token={token}/>
                </div>
                <br></br>
            </div>
            
        </>
    )
}

export default FormularioCuentaServicio