export const estadosValores= [ 
    {label: "OPERATIVO", value: "OPERATIVO"},
    {label: "DE BAJA", value: "DE BAJA"} 
]

export const propiedaddeValores= [ 
    {label: "ARAUCO", value: "ARAUCO"},
    {label: "ARRENDADO", value: "ARRENDADO"} 
]

export const estadosFiltroTabla = [    
    "OPERATIVO",
    "DE BAJA"  
]

export const camposExcelBitacora = [
    {nombre:"ID DEVICE", valor:"id_registro"},
    {nombre:"ID LOG", valor:"id_bitacora"},
    {nombre:"IOD MODIFICATION", valor:"id_modificacion"},
    {nombre:"DATE", valor:"fecha"},
    {nombre:"RESPONSABLE", valor:"responsable"},
    {nombre:"FIELD", valor:"campo"},
    {nombre:"AFTER VALUE", valor:"valor_anterior"},
    {nombre:"BEFORE VALUE", valor:"valor_actual"}    
]

export const camposExcel = [   
    {nombre:"TYPE", valor:"tipoequipo"},
    {nombre:"SERIAL NUMBER", valor:"numeroserie"},
    {nombre:"STATE", valor:"estado"},
    {nombre:"CONDITION", valor:"condicion"},
    {nombre:"PROPERTY OF", valor:"propiedadde"},
    {nombre:"ENTERPRISE", valor:"empresa"},
    {nombre:"PLANT", valor:"planta"},
    {nombre:"BRAND", valor:"marca"},
    {nombre:"MODEL", valor:"modelo"},
    {nombre:"DESCRIPTION", valor:"descripcion"},

    {nombre:"OPERATING SYSTEM", valor:"sistemaoperativo"},
    {nombre:"VERSION", valor:"version"},
    {nombre:"PURCHASE DATE", valor:"fechacompra"},
    {nombre:"LEASE DATE", valor:"fechaarriendo"},
    {nombre:"REVIEW DATE", valor:"fecharevision"},

    {nombre:"MAC", valor:"mac"},
    {nombre:"IP", valor:"ipequipo"},
    {nombre:"REVIEW CONDITION", valor:"condicionrevision"},
    {nombre:"OBSERVATIONS", valor:"observaciones"}, 
    {nombre:"HOSTNAME", valor:"hostname"},
    {nombre:"LEASED TO", valor:"arrendadoa"},
    {nombre:"COUNTRY", valor:"pais"},
    {nombre:"LOCATION", valor:"localidad"},
    {nombre:"ID DEVICE", valor:"id_otrodispositivo"}
]

export const camposExcelCarga = [   
    {nombre:"ROW", valor:"fila"},
    {nombre:"DETAIL", valor:"detalle"}    
]

export const campos = [    
    "numeroserie",
    "estado",
    "condicion",
    "propiedadde",
    "empresa",
    "planta",
    "marca",
    "modelo",
    "descripcion",
    "localidad",
    "pais",
    "negocio",

    "sistemaoperativo",
    "version",
    "fechacompra",
    "fechaarriendo",
    "fecharevision",

    "mac",
    "ipequipo",
    "condicionrevision",
    "observaciones",
    "hostname",
    "arrendadoa",

    "modificadopor",
    "fechaupdate"  
]