import React from 'react'
import "../../css/maestroaplicacion.css"
import CargaMaestroApp from './CargaMaestroApp'
import CreacionMaestroApp from './CreacionMaestroApp'
import TablaMaestroAplicacion from './TablaMaestroAplicacion'

const MAplicacion = ({token}) => {
    return (
        <>
        <br></br>
        <div className='divizq-mapp'>
            <CreacionMaestroApp token={token}/>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className="containermaestro">
                <br></br>
                <CargaMaestroApp token={token}/>
                <br></br>
            </div>
        </div>
        <div className='divder-mapp'>
            <TablaMaestroAplicacion token={token}/>
        </div>
        </>
    )
}

export default MAplicacion
