import React, { useState } from 'react';
import { makeStyles, useTheme, withStyles  } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import '../css/aplicaciones.css'
import swal from 'sweetalert';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ExportarExcel from '../ExportarExcel';
import ExportarExcelEE from '../ExportarExcelEE';
import * as Opciones from './OpcionesEquiposEscritorio';
import * as MdIcons from 'react-icons/md';
import Loading from '../Loading';
import FiltrosEquiposEscritorio from './FiltrosEquiposEscritorio';
import FiltrosEquiposEscritorioSccm from './FiltrosEquiposEscritorioSccm';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
}));

const headCells = [    
    { id: 'name0', numeric: false, disablePadding: true, label: 'Name0', size: '10%' },
    { id: 'username0', numeric: false, disablePadding: true, label: 'User_Name0', size: '10%' },
    { id: 'serialnumber00', numeric: false, disablePadding: true, label: 'SerialNumber00', size: '10%' },
    { id: 'ip', numeric: false, disablePadding: true, label: 'IP', size: '10%' },
    { id: 'mac', numeric: false, disablePadding: true, label: 'MAC', size: '10%' },
    { id: 'caption00', numeric: false, disablePadding: true, label: 'Caption00', size: '15%' },
    { id: 'buildnumber00', numeric: false, disablePadding: true, label: 'BuildNumber00', size: '15%' },
    { id: 'model00', numeric: false, disablePadding: true, label: 'Model00', size: '10%' },
    { id: 'lasthwscan', numeric: false, disablePadding: true, label: 'LastHWScan', size: '10%' },
];

const ContainerBusqueda = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
`;

const ContainerTable = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
`;

const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.white,
      
    },
    body: {
        textDecoration: 'none',
      fontSize: 12,
    },
}))(TableCell);

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const TablaEquiposEscritorioSccm = ({token}) => {

    const [loading, setLoading] = React.useState(false); 

    const [rows, setRows] = useState(null);
    const [filter, setFilter] = useState("");

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('nombre');

    const classes = useStyles();

    function aplicarFiltro(registros)
    {        
        if(registros == null) setRows(registros); 
        else if(registros.length === 0)
        {
            setRows(registros)            
            swal ("There are no records" ,
                    "No information is recorded with the filters used" ,
                    "info");
        }
        else setRows(registros);
    }

    function buscar(rows)
    {
        let filtro = filter.toLowerCase();
        return rows.filter((row) => {
            var r = false;

            r = (row.id !== "") ? (row.id.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.name0 !== "") ? (row.name0.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.username0 !== "") ? (row.username0.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.operatingsystemname !== "") ? (row.operatingsystemname.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
            
            r = (row.csdversion00 !== "") ? (row.csdversion00.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.serialnumber00 !== "") ? (row.serialnumber00.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.ip !== "") ? (row.ip.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.mac !== "") ? (row.mac.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.lasthwscan !== "") ? (row.lasthwscan.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.caption00 !== "") ? (row.caption00.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.systemtype !== "") ? (row.systemtype.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.model00 !== "") ? (row.model00.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;

            r = (row.buildnumber00 !== "") ? (row.buildnumber00.toString().toLowerCase().indexOf(filtro) > -1) ?  true : false : false;
            if(r) return true;
           
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function stableSort(array) {
        if(order === 'desc')
        {
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] > b[orderBy]) ? 1 : (b[orderBy] > a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
        else{
            var arrayOrdenado = array.sort((a,b) => (a[orderBy] < b[orderBy]) ? 1 : (b[orderBy] < a[orderBy]) ? -1 : 0);
            return arrayOrdenado;
        }
    }

    return (
        <>
            {
                (rows !== null)
                ?
                (
                    <>                     
                        <FiltrosEquiposEscritorioSccm aplicarFiltro={aplicarFiltro} load={setLoading} token={token}/>
                        <ContainerBusqueda> 
                        <br></br>                         
                            <TextField 
                                id="outlined-search" 
                                label="Search" 
                                size="small" 
                                type="search" 
                                variant="outlined" 
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />    
                           
                            <ExportarExcel subtitulo={"Desktops SCCM"} rows={rows} columnas={Opciones.camposExcelSccm} nombreSheet="DesktopSccm" nombreArchivo="DesktopSccmExport"/> 
                            <ExportarExcelEE subtitulo={"SCCM Load"} rows={rows} columnas={Opciones.camposExcelSccmCarga} nombreSheet="DesktopsSccmLoad" nombreArchivo="DesktopsSccmLoad"/>   

                        <br></br>                  
                        </ContainerBusqueda>
                        <ContainerTable>
                            <TableContainer component={Paper}>
                                <Table 
                                    className={classes.table} 
                                    size="small" 
                                    aria-label="enhanced table">
                                <TableHead style={{ background: '#696158', color: '#fff' }}>
                                    <TableRow>
                                    {headCells.map((headCell) => (
                                        <StyledTableCell 
                                            key={headCell.id}
                                            align={'center'}   
                                            sortDirection={orderBy === headCell.id ? order : false}
                                            style={{ width: headCell.size, color: '#fff !important' }}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id)}
                                                style={{ background: '#696158', color: '#fff' }}
                                            >
                                                {headCell.label}
                                            </TableSortLabel>                                        
                                        </StyledTableCell>
                                    ))}                                
                                    </TableRow>
                                </TableHead>
                                <TableBody>  
                                    {   stableSort(buscar(rows))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)                                    
                                        .map((row) => (                                            
                                            <TableRow hover key={row.id}  style={{ textDecoration: 'none', fontSize: '10px !important' }}>                                                
                                                <StyledTableCell align="left">{row.name0}</StyledTableCell>
                                                <StyledTableCell align="left">{row.username0}</StyledTableCell>
                                                <StyledTableCell align="left">{row.serialnumber00}</StyledTableCell>
                                                <StyledTableCell align="left">{row.ip}</StyledTableCell>
                                                <StyledTableCell align="left">{row.mac}</StyledTableCell> 
                                                <StyledTableCell align="left">{row.caption00}</StyledTableCell>
                                                <StyledTableCell align="left">{row.buildnumber00}</StyledTableCell>
                                                <StyledTableCell align="left">{row.model00}</StyledTableCell>
                                                <StyledTableCell align="left">{row.lasthwscan}</StyledTableCell>
                                            </TableRow>
                                        ))
                                    }        
                                </TableBody>
                                <TableFooter>
                                </TableFooter>                           
                                </Table>
                            </TableContainer>
                            <Table>
                            <TableBody> 
                            <TableRow>
                            <TablePagination  style={{ display: 'flex'}}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    colSpan={3}
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'Registros por pagina' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    />
                            </TableRow>
                            </TableBody> 
                            </Table>
                        </ContainerTable> 
                    </>
                )
                :
                (
                    (!loading) ? (
                        <>                        
                            <FiltrosEquiposEscritorioSccm aplicarFiltro={aplicarFiltro} load={setLoading} token={token}/>
                        </>
                    ) : (
                        <>
                            <FiltrosEquiposEscritorioSccm aplicarFiltro={aplicarFiltro} load={setLoading} token={token}/>
                            <br></br>
                            <br></br>
                            <ContainerTable>
                                <Loading />
                            </ContainerTable>
                        </>
                    )
                )
            }
        </>
    )
}

export default TablaEquiposEscritorioSccm
