import React, { Fragment, useState } from "react";
import * as Opciones from "./OpcionesAplicacionBack";
import { useForm, Controller } from "react-hook-form";
import {
  obtenerAplicacionBack,
  obtenerValores,
  actualizarAplicacionBack,
  obtenerServidores,
  obtenerEquipos,
} from "../functions/FuncAplicacionBack";
import { registrarBitacoraAplicacionBack } from "../functions/FuncBitacora";
import "../css/formularioAplicacion.css";
import "../css/informaciones.css";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import Loading from "../Loading";
import Select from "react-select";
import TablaRelacionPOServidor from "./Servidor/TablaRelacionPOServidor";
import TablaRelacionPOEquipo from "./Equipo/TablaRelacionPOEquipo";
import { validarEmail } from "../functions/MetodosComunes";

import { validarPrivilegioModificacion } from '../Commons/Functions'
import { style, styleNormal, styleDisable } from '../Commons/Styles'

const FormularioAplicacionBack = ({ id, token }) => {
  let privEscribir = sessionStorage.getItem("appback_escribir");
  let privilegioLeer = sessionStorage.getItem("appback_leer");
  let paisUser = sessionStorage.getItem("pais");
  let idiomaUser = sessionStorage.getItem("idioma");

  const [registro, setRegistro] = useState(null);
  const [valores, setValores] = useState(null);
  const [servidores, setServidores] = useState(null);
  const [equipos, setEquipos] = useState(null);
  const [privModificar, setPrivModificar] = React.useState(false);
  const { handleSubmit, control, register, setValue, errors } = useForm({
    mode: "onBlur",
  });

  const [errorSoftware, setErrorSoftware] = useState(false);
  const [errorVersion, setErrorVersion] = useState(false);
  const [errorEstado, setErrorEstado] = useState(false);
  const [errorNegocio, setErrorNegocio] = useState(false);
  const [errorPais, setErrorPais] = useState(false);
  const [errorGerencia, setErrorGerencia] = useState(false);
  const [errorInformara, setErrorInformara] = useState(false);
  const [errorTipoLicencia, setErrorTipoLicencia] = useState(false);

  function mapResponseToRegistro(response){
    setRegistro({
      id_aplicacionback: response.aplicacion.id_aplicacionback,
      software: {
        value: response.aplicacion.software,
        label: response.aplicacion.software,
      },
      version: response.aplicacion.version,
      estado: {
        label: response.aplicacion.estado,
        value: response.aplicacion.estado,
      },
      negocio: {
        label: response.aplicacion.negocio,
        value: response.aplicacion.negocio,
      },
      pais: {
        label: response.aplicacion.pais,
        value: response.aplicacion.pais,
      },
      informara: response.aplicacion.informara,
      gerencia: response.aplicacion.gerencia,
      observacion: response.aplicacion.observacion,
      tipolicencia: {
        label: response.aplicacion.tipolicencia,
        value: response.aplicacion.tipolicencia,
      },
      numerofactura: response.aplicacion.numerofactura,
      numerolicencia: response.aplicacion.numerolicencia,
      fechaupdate:
        response.aplicacion.fechaupdate !== null
          ? new Date(response.aplicacion.fechaupdate)
          : null,
      fechafactura:
        response.aplicacion.fechafactura !== null &&
        response.aplicacion.fechafactura !== ""
          ? new Date(response.aplicacion.fechafactura)
          : null,
      vencimientocontrato:
        response.aplicacion.vencimientocontrato !== null &&
        response.aplicacion.vencimientocontrato !== ""
          ? new Date(response.aplicacion.vencimientocontrato)
          : null,
      modificadopor: response.aplicacion.modificadopor,
    });
  }

  async function obtenerDatosBD() {
    var paisRegistro = "";

    await obtenerServidores(id, token).then((response) => {
      if (response.cod === 1) setServidores(response.servidores);
    });

    await obtenerEquipos(id, token).then((response) => {
      if (response.cod === 1) setEquipos(response.equipos);
    });

    await obtenerAplicacionBack(id, token).then((response) => {
      if (response.cod === 1) {
        paisRegistro = response.aplicacion.pais;

        validarPrivilegioModificacion(privEscribir, paisUser, "", "", response.aplicacion, setPrivModificar);

        mapResponseToRegistro(response);
      }
    });

    await obtenerValores("APPLICATION BO", idiomaUser, token).then(
      (response) => {
        if (response.cod === 1) {
          const val = {
            negocios: response.negocios,
            paises: response.paises,
            tiposlicencia: response.tiposlicencia,
            estados: response.estados,
            maestroapp: response.maestroapp,
            traducciones: response.traducciones,
          };

          let array = [];
          const listas = [
            "negocios",
            "paises",
            "tiposlicencia",
            "estados",
            "maestroapp",
          ];

          listas.forEach((lista) => {
            array = [];
            val[lista].map((u) => array.push({ label: u, value: u }));
            val[lista] = array;
          });

          if (privEscribir === "2" && privilegioLeer === "2") {
            array = [];
            val["paises"].map((u) => {
              if (u.value === paisUser)
                array.push({ label: u.value, value: u.value });
            });
            val["paises"] = array;
          }

          if (privEscribir === "2" && privilegioLeer === "3") {
            array = [];
            val["paises"].map((u) => {
              if (u.value === paisUser && paisRegistro === paisUser)
                array.push({ label: u.value, value: u.value });
            });
            val["paises"] = array;
          }

          console.log(val);
          setValores(val);
        }
      }
    );
  }

  async function actualizarServidores() {
    await obtenerServidores(id, token).then((response) => {
      if (response.cod === 1) {
        setServidores(response.servidores);
      }
    });
  }

  async function actualizarEquipos() {
    await obtenerEquipos(id, token).then((response) => {
      if (response.cod === 1) {
        setEquipos(response.equipos);
      }
    });
  }

  async function obtenerAplicaciopnBD() {
    await obtenerAplicacionBack(id, token).then((response) => {
      if (response.cod === 1) {
        mapResponseToRegistro(response);
      }
    });
  }

  async function registrarBitacora(datosActuales, dataAnterior) {
    let nombreusuario = sessionStorage.getItem("nombre");

    registrarBitacoraAplicacionBack(
      datosActuales,
      dataAnterior,
      id,
      nombreusuario,
      token
    ).then((response) => {
      console.log(response);
    });
  }

  async function actualizar(datosActuales, dataAnterior) {
    const datosform = datosActuales;

    let nombreusuario = sessionStorage.getItem("nombre");
    datosform.modificadopor = nombreusuario;
    datosform.fechaupdate = new Date();

    await actualizarAplicacionBack(datosform, dataAnterior, id, token).then(
      (response) => {
        if (response.cod === 1) {
          registrarBitacora(datosActuales, dataAnterior);
          obtenerAplicaciopnBD();
          swal("Successful update", "", "success");
        } else if (response.cod === 0)
          swal("Update error", response.mensaje, "error");
        else
          swal(
            "Update error",
            "An error occurred during the update. Please contact the administrator",
            "error"
          );
      }
    );
  }

  function validarDatos(data) {
    let errors = {
      "error_type_01": "Mandatory data must be entered",
      "error_type_02": "Invalid email",
    }
    let error = "";

    if (data.version === null || data.version === "") {
      setErrorVersion(true);
      error = errors["error_type_01"];
    } else setErrorVersion(false);

    if (data.informara === null || data.informara === "") {
      setErrorInformara(true);
      error = errors["error_type_01"];
    } else setErrorInformara(false);

    if (
      data.software === null ||
      data.software === "" ||
      data.software === undefined
    ) {
      setErrorSoftware(true);
      error = errors["error_type_01"];
    } else setErrorSoftware(false);

    if (
      data.estado === null ||
      data.estado === "" ||
      data.estado === undefined
    ) {
      setErrorEstado(true);
      error = errors["error_type_01"];
    } else setErrorEstado(false);

    if (
      data.negocio === null ||
      data.negocio === "" ||
      data.negocio === undefined
    ) {
      setErrorNegocio(true);
      error = errors["error_type_01"];
    } else setErrorNegocio(false);

    if (data.pais === null || data.pais === "" || data.pais === undefined) {
      setErrorPais(true);
      error = errors["error_type_01"];
    } else setErrorPais(false);

    if (
      data.tipolicencia === null ||
      data.tipolicencia === "" ||
      data.tipolicencia === undefined
    ) {
      setErrorTipoLicencia(true);
      error = errors["error_type_01"];
    } else setErrorTipoLicencia(false);

    if(data.informara.length > 0)
    {
        if(!validarEmail(data.informara))
        {
            setErrorInformara(true);
            error = errors["error_type_02"];
        }
        else setErrorInformara(false);
    }

    return error;
  }

  const onSubmit = (data) => {
    let errorMessage = validarDatos(data);;

    if(errorMessage.length > 0)
    {
        swal("Form data", errorMessage, "warning");     
    }
    else {
      swal({
        title: "Update confirmation",
        text: "Do you want to update the registry?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (afirmacion) => {
        if (afirmacion) {
          await actualizar(data, registro);
        } else setRegistro(data);
      });
    }
  };

  React.useEffect(() => {
    if (registro !== null) {

      Opciones.campos.forEach(c => {
        setValue(c, registro[c], { shouldValidate: true, shouldDirty: true })
      });
      
    } else obtenerDatosBD();
  }, [registro, valores]);

  if (registro === null || valores === null) return <Loading />;
  else
    return (
      <>
        <form
          style={{ width: "100%", marginRight: "auto", marginLeft: "auto" }}
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="divizq-app">
            <div className="container">
              <div className="row">
                <div className="col-25">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="i-id_aplicacionback"
                  > ID
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    as={
                      <input
                        disabled={"disabled"}
                        className="bootstrap-input-disable"
                        type="text"
                      />
                    }
                    control={control}
                    id="i-id_aplicacionback"
                    key="k-id_aplicacionback"
                    defaultValue={registro ? registro.id_aplicacionback : ""}
                    name="id_aplicacionback"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label style={{ fontWeight: "bold" }} htmlFor="i-software">
                    Application<label style={{ color: "red" }}>{"*"}</label>
                    {valores.traducciones.software === "" ||
                    valores.traducciones.software === null ? (
                      <></>
                    ) : (
                      <div class="tooltip">
                        &nbsp;&#x1F6C8;
                        <span class="tooltiptext">
                          {valores.traducciones.software}
                        </span>
                      </div>
                    )}
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    as={<Select />}
                    name="software"
                    options={valores.maestroapp}
                    isClearable={true}
                    isDisabled={true}
                    placeholder=""
                    defaultValue={registro ? registro.software : ""}
                    control={control}
                    styles={styleDisable}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label style={{ fontWeight: "bold" }} htmlFor="i-version">
                    Version<label style={{ color: "red" }}>{"*"}</label>
                    {valores.traducciones.version === "" ||
                    valores.traducciones.version === null ? (
                      <></>
                    ) : (
                      <div class="tooltip">
                        &nbsp;&#x1F6C8;
                        <span class="tooltiptext">
                          {valores.traducciones.version}
                        </span>
                      </div>
                    )}
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    as={
                      <input
                        disabled={!privModificar ? "disabled" : ""}
                        className={
                          privModificar
                            ? "bootstrap-input"
                            : "bootstrap-input-disable"
                        }
                        type="text"
                      />
                    }
                    style={errorVersion ? { borderColor: "#f90000" } : {}}
                    control={control}
                    id="i-version"
                    key="k-version"
                    defaultValue={registro ? registro.version : ""}
                    name="version"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label style={{ fontWeight: "bold" }} htmlFor="i-estado">
                    State<label style={{ color: "red" }}>{"*"}</label>
                    {valores.traducciones.estado === "" ||
                    valores.traducciones.estado === null ? (
                      <></>
                    ) : (
                      <div class="tooltip">
                        &nbsp;&#x1F6C8;
                        <span class="tooltiptext">
                          {valores.traducciones.estado}
                        </span>
                      </div>
                    )}
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    render={(props) => (
                      <Select
                        options={valores.estados}
                        onChange={(value) => {
                          props.onChange(value);
                        }}
                        placeholder=""
                        isClearable={true}
                        isDisabled={privModificar ? false : true}
                        defaultValue={registro ? registro.estado : ""}
                        styles={
                          errorEstado
                            ? style
                            : privModificar
                            ? styleNormal
                            : styleDisable
                        }
                        control={control}
                      />
                    )}
                    control={control}
                    id="i-estado"
                    key="k-estado"
                    name="estado"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label style={{ fontWeight: "bold" }} htmlFor="i-pais">
                    Country<label style={{ color: "red" }}>{"*"}</label>
                    {valores.traducciones.pais === "" ||
                    valores.traducciones.pais === null ? (
                      <></>
                    ) : (
                      <div class="tooltip">
                        &nbsp;&#x1F6C8;
                        <span class="tooltiptext">
                          {valores.traducciones.pais}
                        </span>
                      </div>
                    )}
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    render={(props) => (
                      <Select
                        options={valores.paises}
                        onChange={(value) => {
                          props.onChange(value);
                        }}
                        placeholder=""
                        isClearable={true}
                        isDisabled={privModificar ? false : true}
                        defaultValue={registro ? registro.pais : ""}
                        styles={
                          errorPais
                            ? style
                            : privModificar
                            ? styleNormal
                            : styleDisable
                        }
                        control={control}
                      />
                    )}
                    control={control}
                    id="i-pais"
                    key="k-pais"
                    name="pais"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label style={{ fontWeight: "bold" }} htmlFor="i-negocio">
                    Business<label style={{ color: "red" }}>{"*"}</label>
                    {valores.traducciones.negocio === "" ||
                    valores.traducciones.negocio === null ? (
                      <></>
                    ) : (
                      <div class="tooltip">
                        &nbsp;&#x1F6C8;
                        <span class="tooltiptext">
                          {valores.traducciones.negocio}
                        </span>
                      </div>
                    )}
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    render={(props) => (
                      <Select
                        options={valores.negocios}
                        onChange={(value) => {
                          props.onChange(value);
                        }}
                        placeholder=""
                        isClearable={true}
                        isDisabled={privModificar ? false : true}
                        defaultValue={registro ? registro.negocio : ""}
                        styles={
                          errorNegocio
                            ? style
                            : privModificar
                            ? styleNormal
                            : styleDisable
                        }
                        control={control}
                      />
                    )}
                    control={control}
                    id="i-negocio"
                    key="k-negocio"
                    name="negocio"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label htmlFor="i-gerencia">
                    Owner
                    {valores.traducciones.gerencia === "" ||
                    valores.traducciones.gerencia === null ? (
                      <></>
                    ) : (
                      <div class="tooltip">
                        &nbsp;&#x1F6C8;
                        <span class="tooltiptext">
                          {valores.traducciones.gerencia}
                        </span>
                      </div>
                    )}
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    as={<input className="bootstrap-input" type="text" />}
                    control={control}
                    id="i-gerencia"
                    key="k-gerencia"
                    style={errorGerencia ? { borderColor: "#f90000" } : {}}
                    defaultValue={registro ? registro.gerencia : ""}
                    name="gerencia"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label
                    style={{ fontWeight: "bold" }}
                    htmlFor="i-tipolicencia"
                  >
                    Type of license<label style={{ color: "red" }}>{"*"}</label>
                    {valores.traducciones.tipolicencia === "" ||
                    valores.traducciones.tipolicencia === null ? (
                      <></>
                    ) : (
                      <div class="tooltip">
                        &nbsp;&#x1F6C8;
                        <span class="tooltiptext">
                          {valores.traducciones.tipolicencia}
                        </span>
                      </div>
                    )}
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    render={(props) => (
                      <Select
                        options={valores.tiposlicencia}
                        onChange={(value) => {
                          props.onChange(value);
                        }}
                        placeholder=""
                        isClearable={true}
                        isDisabled={privModificar ? false : true}
                        defaultValue={registro ? registro.tipolicencia : ""}
                        styles={
                          errorTipoLicencia
                            ? style
                            : privModificar
                            ? styleNormal
                            : styleDisable
                        }
                        control={control}
                      />
                    )}
                    control={control}
                    id="i-tipolicencia"
                    key="k-tipolicencia"
                    name="tipolicencia"
                  />
                </div>
              </div>              
              <div className="row">
                <div className="col-25">
                  <label htmlFor="i-numerofactura">
                    Purchase invoice number
                    {valores.traducciones.numerofactura === "" ||
                    valores.traducciones.numerofactura === null ? (
                      <></>
                    ) : (
                      <div class="tooltip">
                        &nbsp;&#x1F6C8;
                        <span class="tooltiptext">
                          {valores.traducciones.numerofactura}
                        </span>
                      </div>
                    )}
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    as={
                      <input
                        disabled={!privModificar ? "disabled" : ""}
                        className={
                          privModificar
                            ? "bootstrap-input"
                            : "bootstrap-input-disable"
                        }
                        type="text"
                      />
                    }
                    control={control}
                    id="i-numerofactura"
                    key="k-numerofactura"
                    defaultValue={registro ? registro.numerofactura : ""}
                    name="numerofactura"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label htmlFor="i-fechafactura">
                    Invoice date (YYYY/MM/DD)
                    {valores.traducciones.fechafactura === "" ||
                    valores.traducciones.fechafactura === null ? (
                      <></>
                    ) : (
                      <div class="tooltip">
                        &nbsp;&#x1F6C8;
                        <span class="tooltiptext">
                          {valores.traducciones.fechafactura}
                        </span>
                      </div>
                    )}
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    name="fechafactura"
                    id="i-fechafactura"
                    className="bootstrap-input"
                    control={control}
                    render={({ onChange, value }) => (
                      <DatePicker
                        disabled={!privModificar ? "disabled" : ""}
                        selected={value}
                        dateFormat="yyyy/MM/dd"
                        autoComplete="off"
                        onChange={onChange}
                        className={
                          privModificar
                            ? "bootstrap-input"
                            : "bootstrap-input-disable"
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label htmlFor="i-numerolicencia">
                    Number of Licenses
                    {valores.traducciones.numerolicencia === "" ||
                    valores.traducciones.numerolicencia === null ? (
                      <></>
                    ) : (
                      <div class="tooltip">
                        &nbsp;&#x1F6C8;
                        <span class="tooltiptext">
                          {valores.traducciones.numerolicencia}
                        </span>
                      </div>
                    )}
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    as={
                      <input
                        disabled={!privModificar ? "disabled" : ""}
                        className={
                          privModificar
                            ? "bootstrap-input"
                            : "bootstrap-input-disable"
                        }
                        type="number"
                      />
                    }
                    control={control}
                    id="i-numerolicencia"
                    key="k-numerolicencia"
                    defaultValue={registro ? registro.numerolicencia : ""}
                    name="numerolicencia"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label htmlFor="i-vencimientocontrato">
                    Expiration date (YYYY/MM/DD)
                    {valores.traducciones.vencimientocontrato === "" ||
                    valores.traducciones.vencimientocontrato === null ? (
                      <></>
                    ) : (
                      <div class="tooltip">
                        &nbsp;&#x1F6C8;
                        <span class="tooltiptext">
                          {valores.traducciones.vencimientocontrato}
                        </span>
                      </div>
                    )}
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    name="vencimientocontrato"
                    id="i-vencimientocontrato"
                    className="bootstrap-input"
                    control={control}
                    render={({ onChange, value }) => (
                      <DatePicker
                        disabled={!privModificar ? "disabled" : ""}
                        selected={value}
                        dateFormat="yyyy/MM/dd"
                        autoComplete="off"
                        onChange={onChange}
                        className={
                          privModificar
                            ? "bootstrap-input"
                            : "bootstrap-input-disable"
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label style={{ fontWeight: "bold" }} htmlFor="i-informara">
                    Inform To<label style={{ color: "red" }}>{"*"}</label>
                    {valores.traducciones.informara === "" ||
                    valores.traducciones.informara === null ? (
                      <></>
                    ) : (
                      <div class="tooltip">
                        &nbsp;&#x1F6C8;
                        <span class="tooltiptext">
                          {valores.traducciones.informara}
                        </span>
                      </div>
                    )}
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    as={<input className="bootstrap-input" type="text" />}
                    control={control}
                    id="i-informara"
                    key="k-informara"
                    style={errorInformara ? { borderColor: "#f90000" } : {}}
                    defaultValue={registro ? registro.informara : ""}
                    name="informara"
                  />
                </div>
              </div>

              <div className="row">
                  <div className="col-25">
                      <label htmlFor="i-observacion">Observation</label>
                  </div>
                  <div className="col-75">
                      <Controller
                          as={<textarea disabled={(!privModificar) ? 'disabled' : ''} className={((privModificar) ? "bootstrap-input" : "bootstrap-input-disable")} rows="3" cols="50"></textarea>}
                          control={control}
                          id="i-observacion"
                          key="k-observacion"
                          defaultValue={registro ? registro.observacion : ''}
                          name='observacion'
                      />                             
                  </div>
              </div> 

              <div className="row">
                <div className="col-25">
                  <label htmlFor="i-modificadopor">Modified by</label>
                </div>
                <div className="col-75">
                  <Controller
                    as={
                      <input
                        disabled="disabled"
                        className="bootstrap-input-disable"
                        type="text"
                      />
                    }
                    control={control}
                    id="i-modificadopor"
                    key="k-modificadopor"
                    defaultValue={registro ? registro.modificadopor : ""}
                    name="modificadopor"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label htmlFor="i-fechaupdate">
                    Modification date (YYYY/MM/DD)
                  </label>
                </div>
                <div className="col-75">
                  <Controller
                    name="fechaupdate"
                    id="i-fechaupdate"
                    className="bootstrap-input"
                    control={control}
                    render={({ onChange, value }) => (
                      <DatePicker
                        disabled="disabled"
                        selected={value}
                        dateFormat="yyyy/MM/dd"
                        autoComplete="off"
                        onChange={onChange}
                        className="bootstrap-input-disable"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: "10px",
                float: "left",
              }}
            >
              <div
                style={{
                  width: "40%",
                  marginRight: "auto",
                  marginLeft: "auto",
                  marginTop: "10px",
                }}
              >
                <button className="boton-from">Save</button>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
          <div className="divder-app">
            <div className="containerappder">
              <div className="subtitulo-box">
                <div className="subtitulo">
                  <label>Servers</label>
                </div>
              </div>
              <TablaRelacionPOServidor
                rows={servidores}
                id={id}
                criticidad={registro.criticidad}
                camposExcel={Opciones.camposExcelServidores}
                privModificar={privModificar}
                actualizarServidores={actualizarServidores}
                nombreApp={registro.software.value}
                token={token}
              />
            </div>
            <br></br>
            <div className="containerappder">
              <div className="subtitulo-box">
                <div className="subtitulo">
                  <label>Desktops</label>
                </div>
              </div>
              <TablaRelacionPOEquipo
                rows={equipos}
                id={id}
                camposExcel={Opciones.camposExcelEquipos}
                privModificar={privModificar}
                actualizarEquipos={actualizarEquipos}
                nombreApp={registro.software.value}
                token={token}
              />
            </div>
          </div>
        </form>
      </>
    );
};

export default FormularioAplicacionBack;
