import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import Modal from 'react-modal'
import swal from 'sweetalert';
import * as MdIcons from 'react-icons/md';
import * as TiIcons from 'react-icons/ti';
import * as FiIcons from 'react-icons/fi';
import * as AiIcons from 'react-icons/ai';
import LoadingCarga from '../LoadingCarga'
import { crearDocumento, actualizarMarcaDocumento, obtenerDocumentos } from '../functions/FuncDocumentos'
import { obtenerEquipoEscritorio } from '../functions/FuncEquipoEscritorio'
import Loading from '../Loading';


import { ButtonRelacion, ContainerBusqueda, ButtonVolver, ButtonCarga } from '../Commons/Styles'

const ModalArchivoEntrega = ({id, tipo, cambiarDocEntrega, token}) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [modalCarga, setModalCarga] = useState(false);
    const [registro, setRegistro] = useState(null); 

    const [selectedFile, setSelectedFile] = useState( {file:null, fileField: "", file64: null});
    const ref = React.useRef();
    const [nombrefile, setNombreFile] = useState("Select File ..");

    function abrirModal()
    {      
        setModalOpen(true);
    }

    function cerrarModal()
    {      
        setModalOpen(false);
    }

    function abrirModalCarga()
    {      
        setModalCarga(true);
    }

    function cerrarModalCarga()
    {      
        setModalCarga(false);
    }

    const handleFileInput = (e) => {
        // handle validations
        abrirModalCarga();
        const f = e.target.files[0];
        const ff = e.target.files;     
        
        if(e.target.files !== null && e.target.files.length !== 0){
            setNombreFile(e.target.files[0].name)

            var nombreFile = e.target.files[0].name;
            var arrayTipos = [".pdf"]

            var val = false;

            arrayTipos.forEach(t => {
                if(nombreFile.includes(t)) val = true;
            });

            if(val) setSelectedFile({file: f, fileField: ff, file64: null});
            else {
                swal("Type of File", "Only the following document type are supported is .pdf", "warning");
                setNombreFile("Select File ..")
                setSelectedFile({file:null, fileField: ""});
                ref.current.value = "";
            }
        }         
        else
        {
            setNombreFile("Select File ..")
            setSelectedFile({file:null, fileField: ""});
            ref.current.value = "";
        }        
        cerrarModalCarga();
    }

    function cargarArchivo()
    {
        let Base64 = '';

        if(selectedFile.file === null)
        {
            swal ("You have not selected a file" ,
                    "",
                    "warning")
        }
        else {
            swal({
                title: "Upload confirmation",
                text: "Do you want to upload the selected file?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((afirmacion) => {
                if(afirmacion)
                {
                    getBase64(selectedFile.file, async(result) => {
                        Base64 = result;   
                        await updatedocumento(Base64, selectedFile.file.name).then(() => {
                            setSelectedFile({file:null, fileField: ""});
                            ref.current.value = "";
                            setNombreFile("Select File ..");
                        }); 
                    });
                }
                else {
                    setSelectedFile({file:null, fileField: ""});
                    ref.current.value = "";
                    setNombreFile("Select File ..");
                }
            });
        }
    }

    async function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            
        };
    }

    async function updatedocumento(archivo, nombre){
        abrirModalCarga();

        let nombreusuario = sessionStorage.getItem('nombre');

        var numserie = registro.numeroserie;
        var user = registro.nombreusuario;

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '' + mm + '' + dd;

        var nombrearchivo = numserie + " - " + today + " - " + user + ".pdf";

        await crearDocumento(archivo, nombrearchivo, nombreusuario, id, tipo, token)
        .then(async (response) => {
            if(response.cod === 1){   
                
                await actualizarMarcaDocumento(id, "1", token)
                .then(() => {
                    cambiarDocEntrega(true);
                    cerrarModalCarga();
                    swal("Upload success", "", "success")
                })
            }
            else 
            {
                cerrarModalCarga();
                swal("Upload error" , response.mensaje ,"error");
            }
        })
    }

    async function obtenerRegistro(){
        await obtenerEquipoEscritorio(id, token)
        .then((response) => {
            if(response.cod === 1)
            {
                setRegistro({
                    "numeroserie": response.equipo.numeroserie,
                    "nombreusuario": response.equipo.nombreusuario
                });
            }
        })
    }

    async function getdocumentos(){
        await obtenerDocumentos(id, tipo, token)
        .then((response) => {
            if(response.cod === 1){                
                console.log(response.archivos)
            }
        })
    }

    React.useEffect(() => {
        if(registro === null){
            getdocumentos();
            obtenerRegistro();
        } 
    }, [])

    return (
        <>
            <ButtonRelacion type="button" onClick={abrirModal} className="tooltipboton">
                <AiIcons.AiOutlineFilePdf/>
                <span className="tooltiptextboton">{"Delivery document"}</span>              
            </ButtonRelacion>
            <Modal 
                isOpen={modalOpen}
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.75)'
                    },
                    content: {
                        width: '90%',
                        height: 'auto',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        position: 'relative',                        
                        border: '1px solid #ccc',
                        background: '#fff',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '10px'
                    }
                  }}
            >
                {
                    (registro === null) ? (<Loading />)
                    : (
                        <div className="containersharepoint">
                            <div style={{width: "100%", marginBottom: '30px'}}>
                                <ButtonVolver onClick={cerrarModal} className="tooltipboton">
                            <TiIcons.TiArrowBack/>             
                            <span className="tooltiptextboton">{"Back"}</span>
                    </ButtonVolver>                                    
                            </div>
                            <ContainerBusqueda> 
                                <div className="divcompletoinput-pdf">
                                    <div className="divinoputfile-pdf">
                                        <label for="file-upload" class="custom-file-upload">
                                            {nombrefile}
                                        </label>
                                        <input id="file-upload" ref={ref} onChange={handleFileInput} style={{display: 'none'}} type="file"/>
                                    </div>
                                    <div className="divbotonfile-pdf">
                                        <ButtonCarga onClick={() => {cargarArchivo()}}>                            
                                            <FiIcons.FiUpload />                             
                                        </ButtonCarga>  
                                    </div>                                                
                                </div>                           
                                <br></br>                  
                            </ContainerBusqueda>
                        </div>
                    )
                }

                <Modal 
                isOpen={modalCarga}
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0                      
                    },
                    content: {        
                        background: 'none',
                        border: 'none',
                        width: '250px',
                        height: '250px',                
                        marginLeft: 'auto',                        
                        marginRight: 'auto',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        position: 'absolute',                        
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',                        
                        outline: 'none',
                        
                    }
                  }}
                >
                    <LoadingCarga />
                </Modal>



            </Modal>
            
        </>
    )
}

export default ModalArchivoEntrega
