import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import { obtenerValores, crearCertificado } from '../functions/FuncCertificado'
import "../css/formularioAplicacion.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import Loading from '../Loading';
import Select from 'react-select'
import { validarEmail } from '../functions/MetodosComunes';


import { style, styleNormal } from '../Commons/Styles'

const CreacionCertificado = ({token}) => {

    const history = useHistory();
    const [registro, setRegistro] = useState(null);    
    const [valores, setValores] = useState(null);
    const { handleSubmit, control, register, setValue} = useForm({mode: 'onBlur'});

    let privilegio = sessionStorage.getItem('cert_crear');
    let paisUser = sessionStorage.getItem('pais');
    let idiomaUser = sessionStorage.getItem('idioma');

    const [errorNombre, setErrorNombre] = useState(false);
    const [errorTipo, setErrorTipo] = useState(false);
    const [errorProveedor, setErrorProveedor] = useState(false);
    const [errorEntidad, setErrorEntidad] = useState(false);
    const [errorPais, setErrorPais] = useState(false);
    const [errorNegocio, setErrorNegocio] = useState(false);
    const [errorEstado, setErrorEstado] = useState(false);
    const [errorResponsable, setErrorResponsable] = useState(false);
    const [errorFechaCreacion, setErrorFechaCreacion] = useState(false);
    const [errorFechaExpiracion, setErrorFechaExpiracion] = useState(false);
    const [errorDescripcion, setErrorDescripcion] = useState(false);
    const [errorInformara, setErrorInformara] = useState(false);

    async function obtenerDatosBD()
    {
        await obtenerValores(privilegio, paisUser, "CERTIFICATE", idiomaUser, token)
        .then((response) => {
                
        if(response.cod === 1)
        {
            const val = {
                "tipos": response.tipos,
                "proveedores": response.proveedores,
                "entidades": response.entidades,
                "paises": response.paises,
                "estados": response.estados,
                "negocios": response.negocios,
                "responsables": response.responsables,
                "traducciones": response.traducciones
            };

            let array = [];
            const listas = ["tipos", "proveedores", "entidades", "paises", "negocios", "responsables", "estados"];

            listas.forEach(lista => {
            array = [];
            val[lista].map((u) => { array.push({"label": u, "value": u});})
            val[lista] = array;
            });

            setValores(val);
        }
        });
    }

    const onSubmit = (data) => {

        let errorMessage = validarDatos(data);;

        if(errorMessage.length > 0)
        {
            swal("Form data", errorMessage, "warning");     
        }
        else {
            swal({
                title: "Creation confirmation",
                text: "Do you want to create the record?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async (afirmacion) => {
                if(afirmacion)
                {
                    const datosform = data;
                    let nombreusuario = sessionStorage.getItem('nombre');
                    datosform.modificadopor = nombreusuario;
                    datosform.fechaupdate = new Date();

                    await crearCertificado(datosform, token).then((response) => {
                        
                        if(response.cod === 1)
                        {
                            swal("Successful creation", "", "success").then(() => {
                                history.push("/certificado/"+response.id);
                            });
                        }
                        else swal("Creation Error", response.mensaje, "error");                        
                    });
                }
            })
        }
    }

    function validarDatos(data)
    {
        let errors = {
            "error_type_01": "Mandatory data must be entered",
            "error_type_02": "Invalid email",
        }
        let error = "";

        if(data.nombre === null || data.nombre === "" || data.nombre === undefined) 
        {
            setErrorNombre(true);
            error = errors["error_type_01"];
        }
        else setErrorNombre(false);

        if(data.tipo === null || data.tipo === "" || data.tipo === undefined) 
        {
            setErrorTipo(true);
            error = errors["error_type_01"];
        }
        else setErrorTipo(false);

        if(data.proveedor === null || data.proveedor === "" || data.proveedor === undefined) 
        {
            setErrorProveedor(true);
            error = errors["error_type_01"];
        }
        else setErrorProveedor(false);

        if(data.entidadcertificadora === null || data.entidadcertificadora === "" || data.entidadcertificadora === undefined) 
        {
            setErrorEntidad(true);
            error = errors["error_type_01"];
        }
        else setErrorEntidad(false);

        if(data.estado === null || data.estado === "" || data.estado === undefined) 
        {
            setErrorEstado(true);
            error = errors["error_type_01"];
        }
        else setErrorEstado(false);

        if(data.pais === null || data.pais === "" || data.pais === undefined) 
        {
          setErrorPais(true);
            error = errors["error_type_01"];
        }
        else setErrorPais(false);

        if(data.negocio === null || data.negocio === "" || data.negocio === undefined) 
        {
            setErrorNegocio(true);
            error = errors["error_type_01"];
        }
        else setErrorNegocio(false);

        if(data.responsable === null || data.responsable === "" || data.responsable === undefined) 
        {
            setErrorResponsable(true);
            error = errors["error_type_01"];
        }
        else setErrorResponsable(false);

        if(data.informara === null || data.informara === "" || data.informara === undefined) 
        {
            setErrorInformara(true);
            error = errors["error_type_01"];
        }
        else setErrorInformara(false);

        if(data.fechacreacion === null || data.fechacreacion === "" || data.fechacreacion === undefined) 
        {
            setErrorFechaCreacion(true);
            error = errors["error_type_01"];
        }
        else setErrorFechaCreacion(false);

        if(data.fechaexpiracion === null || data.fechaexpiracion === "" || data.fechaexpiracion === undefined) 
        {
            setErrorFechaExpiracion(true);
            error = errors["error_type_01"];
        }
        else setErrorFechaExpiracion(false);

        if(data.descripcion === null || data.descripcion === "" || data.descripcion === undefined) 
        {
            setErrorDescripcion(true);
            error = errors["error_type_01"];
        }
        else setErrorDescripcion(false);

        if(data.informara.length > 0)
        {
            if(!validarEmail(data.informara))
            {
                setErrorInformara(true);
                error = errors["error_type_02"];
            }
            else setErrorInformara(false);
        }

        return error;
    }


    React.useEffect(() => {
        if (valores !== null) {
    
        }
        else obtenerDatosBD();
    }, []);

    if(valores === null) return (<Loading />)
    else
    return (
        <div className="divizq">
            <form 
                style={{width: '100%', marginRight:'auto', marginLeft: 'auto'}} 
                autoComplete="off" onSubmit={handleSubmit(onSubmit)}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-nombre">Name<label style={{color: 'red'}}>{"*"}</label>
                            {
                            (valores.traducciones.nombre === "" || valores.traducciones.nombre === null) ? (<></>)
                            : (
                                <div class="tooltip">&nbsp;&#x1F6C8;
                                <span class="tooltiptext">{valores.traducciones.nombre}</span>
                                </div>
                            )
                            }
                        </label> 
                        </div>
                        <div className="col-75">
                        <Controller
                            as={<input className="bootstrap-input" type='text'  />}                                      
                            control={control}
                            id="i-nombre"
                            key="k-nombre"
                            defaultValue={registro ? registro.nombre : ''}
                            name='nombre'
                            style={(errorNombre) ? { borderColor: '#f90000'} : {}} 
                        /> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                        <label style={{fontWeight: 'bold'}} htmlFor="i-estado">State<label style={{color: 'red'}}>{"*"}</label>
                            {
                            (valores.traducciones.estado === "" || valores.traducciones.estado === null) ? (<></>)
                            : (
                                <div class="tooltip">&nbsp;&#x1F6C8;
                                <span class="tooltiptext">{valores.traducciones.estado}</span>
                                </div>
                            )
                            }
                        </label> 
                        </div>
                        <div className="col-75">
                        <Controller
                            render={(props) => <Select 
                            options={valores.estados}
                            onChange={(value) => {
                                props.onChange(value);                                                        
                            }}
                            placeholder=""
                            isClearable={true} 
                            styles={(errorEstado) ? style : styleNormal}
                            control={control}
                            />}                                       
                            control={control}
                            id="i-estado"
                            key="k-estado"
                            defaultValue={registro ? registro.estado : ''}
                            name='estado'
                        /> 
                        </div>
                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-tipo">Type<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.tipo === "" || valores.traducciones.tipo === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.tipo}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                            <Controller
                                render={(props) => <Select 
                                options={valores.tipos}
                                onChange={(value) => {
                                    props.onChange(value);                                                        
                                }}
                                placeholder=""
                                isClearable={true} 
                                styles={(errorTipo) ? style : styleNormal}
                                control={control}
                                />}                                       
                                control={control}
                                id="i-tipo"
                                key="k-tipo"
                                defaultValue={registro ? registro.tipo : ''}
                                name='tipo'
                            /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-proveedor">Provider<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.proveedor === "" || valores.traducciones.proveedor === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.proveedor}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                            <Controller
                                render={(props) => <Select 
                                options={valores.proveedores}
                                onChange={(value) => {
                                    props.onChange(value);                                                        
                                }}
                                placeholder=""
                                isClearable={true} 
                                styles={(errorProveedor) ? style : styleNormal}
                                control={control}
                                />}                                       
                                control={control}
                                id="i-proveedor"
                                key="k-proveedor"
                                defaultValue={registro ? registro.proveedor : ''}
                                name='proveedor'
                            /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-entidadcertificadora">Certifying Entity<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.entidadcertificadora === "" || valores.traducciones.entidadcertificadora === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.entidadcertificadora}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                            <Controller
                                render={(props) => <Select 
                                options={valores.entidades}
                                onChange={(value) => {
                                    props.onChange(value);                                                        
                                }}
                                placeholder=""
                                isClearable={true} 
                                styles={(errorEntidad) ? style : styleNormal}
                                control={control}
                                />}                                       
                                control={control}
                                id="i-entidadcertificadora"
                                key="k-entidadcertificadora"
                                defaultValue={registro ? registro.entidadcertificadora : ''}
                                name='entidadcertificadora'
                            /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-pais">Country<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.pais === "" || valores.traducciones.pais === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.pais}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.paises}
                                    onChange={(value) => {
                                        props.onChange(value);                                                        
                                    }}
                                    placeholder=""
                                    isClearable={true} 
                                    styles={(errorPais) ? style : styleNormal}
                                    control={control}
                                    />}                                       
                                    control={control}
                                    id="i-pais"
                                    key="k-pais"
                                    defaultValue={registro ? registro.pais : ''}
                                    name='pais'
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-negocio">Business<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.negocio === "" || valores.traducciones.negocio === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.negocio}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.negocios}
                                    onChange={(value) => {
                                        props.onChange(value);                                                        
                                    }}
                                    placeholder=""
                                    isClearable={true} 
                                    styles={(errorNegocio) ? style : styleNormal}
                                    control={control}
                                    />}                                       
                                    control={control}
                                    id="i-negocio"
                                    key="k-negocio"
                                    defaultValue={registro ? registro.negocio : ''}
                                    name='negocio'
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-responsable">Responsable<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.responsable === "" || valores.traducciones.responsable === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.responsable}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                                <Controller
                                    render={(props) => <Select 
                                    options={valores.responsables}
                                    onChange={(value) => {
                                        props.onChange(value);                                                        
                                    }}
                                    placeholder=""
                                    isClearable={true} 
                                    styles={(errorResponsable) ? style : styleNormal}
                                    control={control}
                                    />}                                       
                                    control={control}
                                    id="i-responsable"
                                    key="k-responsable"
                                    defaultValue={registro ? registro.responsable : ''}
                                    name='responsable'
                                /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-fechacreacion">Creation Date (YYYY/MM/DD)<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.fechacreacion === "" || valores.traducciones.fechacreacion === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.fechacreacion}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                            <Controller
                                name="fechacreacion"
                                id="i-fechacreacion"
                                className="bootstrap-input"
                                control={control}
                                render={({ onChange, value }) => (
                                    <DatePicker                                                        
                                        selected={value}
                                        dateFormat="yyyy/MM/dd"
                                        autoComplete="off"
                                        onChange={onChange}
                                        className={(errorFechaCreacion) ? "bootstrap-input-invalid" : "bootstrap-input"}                                                                                            
                                    />
                                )}
                            /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-fechaexpiracion">Expiration Date (YYYY/MM/DD)<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.fechaexpiracion === "" || valores.traducciones.fechaexpiracion === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.fechaexpiracion}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                            <Controller
                                name="fechaexpiracion"
                                id="i-fechaexpiracion"
                                className="bootstrap-input"
                                control={control}
                                render={({ onChange, value }) => (
                                    <DatePicker                                                        
                                        selected={value}
                                        dateFormat="yyyy/MM/dd"
                                        autoComplete="off"
                                        onChange={onChange}
                                        className={(errorFechaExpiracion) ? "bootstrap-input-invalid" : "bootstrap-input"}                                                                                            
                                    />
                                )}
                            /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-informara">Inform To<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.informara === "" || valores.traducciones.informara === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.informara}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                            <Controller
                                as={<input className="bootstrap-input" type='text'  />}                                      
                                control={control}
                                id="i-informara"
                                key="k-informara"
                                defaultValue={registro ? registro.informara : ''}
                                name='informara'
                                style={(errorInformara) ? { borderColor: '#f90000'} : {}} 
                            /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                            <label style={{fontWeight: 'bold'}} htmlFor="i-descripcion">Description<label style={{color: 'red'}}>{"*"}</label>
                                {
                                (valores.traducciones.descripcion === "" || valores.traducciones.descripcion === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.descripcion}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                            <Controller
                                as={<textarea className="bootstrap-input" rows="3" cols="50"></textarea>}                                     
                                control={control}
                                id="i-descripcion"
                                key="k-descripcion"
                                defaultValue={registro ? registro.descripcion : ''}
                                name='descripcion'
                                style={(errorDescripcion) ? { borderColor: '#f90000'} : {}} 
                            /> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                            <label htmlFor="i-observaciones">Observations
                                {
                                (valores.traducciones.observaciones === "" || valores.traducciones.observaciones === null) ? (<></>)
                                : (
                                    <div class="tooltip">&nbsp;&#x1F6C8;
                                    <span class="tooltiptext">{valores.traducciones.observaciones}</span>
                                    </div>
                                )
                                }
                            </label> 
                            </div>
                            <div className="col-75">
                            <Controller
                                as={<textarea className="bootstrap-input" rows="3" cols="50"></textarea>}                                     
                                control={control}
                                id="i-observaciones"
                                key="k-observaciones"
                                defaultValue={registro ? registro.observaciones : ''}
                                name='observaciones'
                                //style={(errorNombre) ? { borderColor: '#f90000'} : {}} 
                            /> 
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px', float: 'left'}}>  
                    <div style={{width: '30%', marginRight:'auto', marginLeft: 'auto', marginTop: '10px'}}>                
                        <button className="boton-from">Save</button>   
                        <br></br>
                        <br></br>
                        <br></br>              
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CreacionCertificado