import React from 'react'
import MaestroPlantaCreacion from './MaestroPlantaCreacion'
import MaestroPlantaTabla from './MaestroPlantaTabla'

const MaestroPlanta = ({valores, maestro, token, rows, aplicarFiltro, filtro, cambioFiltro, busquedaEliminacion}) => {
    return (
        <>
            <div className="divizq-general">
                <MaestroPlantaCreacion 
                    valores={valores} 
                    maestro={maestro} 
                    token={token} 
                />
            </div>
            <div className="divder-general">
                <div className="container-general">
                   <MaestroPlantaTabla 
                        maestro={maestro} 
                        token={token} 
                        rows={rows} 
                        aplicarFiltro={aplicarFiltro} 
                        filtro={filtro} 
                        cambioFiltro={cambioFiltro}
                        busquedaEliminacion={busquedaEliminacion}
                   />
                </div>               
            </div>
        </>
    )
}

export default MaestroPlanta
