import React, {useState, useEffect} from 'react'
import { obtenerTabla, actualizarNotificacion } from '../../functions/FuncNotificacion'
import swal from 'sweetalert';
import "../../css/notificaciones.css";
import Loading from '../../Loading';
import TablaCorreoNotificaciones from './TablaCorreoNotificaciones';
import Sidebar from '../../Sidebar';
import FormCorreoNotificaciones from './FormCorreoNotificaciones';

const CorreoNotificaciones = ({instance, t}) => {

    const [registros, setRegistros] = useState(null);
    const [registro, setRegistro] = useState(null);
    const [loadtabla, setLoadtabla] = useState(false);

    const getData = async () => {

        setLoadtabla(true);
        await obtenerTabla(t).then((response) => {

            if(response.cod === 1){
                setRegistros(response.correosCC)
            }
            else swal("Upload error" , response.mensaje ,"error");

            setLoadtabla(false);
        })
    }

    const select = (data) => setRegistro(data);

    useEffect(() => {
        if(registros === null) getData();
    }, [])

  return (
    <>
        <Sidebar instance={instance} despliegue={false}/>
        <br></br>
        <br></br>
        <div className='titulonot'>
            <h2>Notification Emails Configuration</h2>
        </div>
        <br></br>
        <br></br>
        <div className='divizqnot'>
            <div className="container">
            {
                (loadtabla) ?  <Loading /> 
                : (                    
                    (registros === null) ? <></> : <TablaCorreoNotificaciones rows={registros} seleccionar={select}/>                    
                ) 
            }
            </div>
        </div>

        <div className='divdernot'>
            {                
                (registro === null) ? <></> : (
                    <>                       
                        <br></br>
                        <br></br>
                        <FormCorreoNotificaciones registro={registro} actualizarTabla={getData} token={t}/>
                    </>
                )
            }
        </div>
        <br></br>
    </>
  )
}

export default CorreoNotificaciones